import {
    COLORS,
    IMG_CITY_CAPITAL_DISTRICT,
    IMG_CITY_CAPITAL_REGION,
    IMG_CITY_OTHER_CONNECTED,
    IMG_CITY_OTHER_DISCONNECTED,
    IMG_CITY_OTHER_PERSPECTIVE,
} from '@/shared/constants'

export default function (this: any, type, size = 25) {
    let svgRaw = ''
    // const fill = COLORS[10]
    // const stroke = COLORS[4]
    switch (type) {
        case IMG_CITY_CAPITAL_DISTRICT:
            svgRaw = `<svg
            viewBox="0 0 120 120"
            width="${size}"
            height="${size}"
            xmlns="http://www.w3.org/2000/svg"><circle
                cx="60"
                cy="60"
                r="55"
                fill="${COLORS[9]}"
                stroke="${COLORS['3_opacity1']}"
                style="stroke-width: 1px;vector-effect: non-scaling-stroke;"
            /></svg>
            `
            break
        case IMG_CITY_CAPITAL_REGION:
            svgRaw = `<svg
                viewBox="0 0 120 120"
                width="${size}"
                height="${size}"
                xmlns="http://www.w3.org/2000/svg"
            ><circle
                    cx="60"
                    cy="60"
                    r="55"
                    fill="${COLORS[9]}"
                    stroke="${COLORS['3_opacity1']}"
                    style="stroke-width: 1px;vector-effect: non-scaling-stroke;"
                />
                <circle cx="60" cy="60" r="30" fill="${COLORS['3_opacity1']}"/></svg>
                `
            break
        case IMG_CITY_OTHER_CONNECTED:
            svgRaw = `<svg
            viewBox="0 0 120 120"
            width="${size}"
            height="${size}"
            xmlns="http://www.w3.org/2000/svg"><circle
                cx="60"
                cy="60"
                r="55"
                fill="${COLORS[18]}"
                stroke="${COLORS['3_opacity1']}"
                style="stroke-width: 1px;vector-effect: non-scaling-stroke;"
            /></svg>
            `
            break
        case IMG_CITY_OTHER_DISCONNECTED:
            svgRaw = `<svg
            viewBox="0 0 120 120"
            width="${size}"
            height="${size}"
            xmlns="http://www.w3.org/2000/svg"><circle
                cx="60"
                cy="60"
                r="55"
                fill="${COLORS[20]}"
                stroke="${COLORS['3_opacity1']}"
                style="stroke-width: 1px;vector-effect: non-scaling-stroke;"
            /></svg>
            `
            break
        case IMG_CITY_OTHER_PERSPECTIVE:
            svgRaw = `<svg
            viewBox="0 0 120 120"
            width="${size}"
            height="${size}"
            xmlns="http://www.w3.org/2000/svg"><circle
                cx="60"
                cy="60"
                r="55"
                fill="${COLORS[10]}"
                stroke="${COLORS['3_opacity1']}"
                style="stroke-width: 1px;vector-effect: non-scaling-stroke;"
            /></svg>
            `
            break
    }
    return svgRaw
}
