import { proposalsInfoMarker } from '@/app/providers/store/modules/types/mapTypes'
import { markerTransition } from '@/entities/Map/general/ui/ProposalStatsInfoMarker/constants'
import {
    cityAreaFillEffectLayerId,
    cityAreafillLayers,
    cityAreaOutlineEffectLayerId,
} from '@/entities/Map/general/model/addCityArea'
import {
    fillFocusEffect,
    lineFocusEffect,
} from '@/entities/Map/general/model/addDistrictsOfRegion/methods/focusLayerEffects'

export default function (this: any, layerIds) {
    layerIds.forEach((layerId, index: number) => {
        const clickHandler = event => {
            console.log('districtProposalsStats event', event)

            if (!event.defaultPrevented) {
                console.log(
                    'outside click Gds',
                    event,
                    event.originalEvent.defaultPrevented
                )
            }

            const firstFeature = event?.features?.[0]
            console.log('firstFeature', firstFeature)

            if (!firstFeature) return

            const isMarkerAlreadyExists =
                this.$state.map.proposalsInfoMarkers.find(
                    marker => marker.id === firstFeature.id
                )
            if (isMarkerAlreadyExists) return

            const coordinates = firstFeature?.geometry?.coordinates?.slice()
            if (!coordinates) return

            const firstFeatureProperties = event.features[0].properties
            const proposalsInfoMarker: proposalsInfoMarker = {
                id: firstFeature.id,
                isShow: true,
                lngLat: coordinates,
                properties: firstFeatureProperties,
            }

            this.$state.map.proposalsInfoMarkers = [proposalsInfoMarker]

            highlightCityAreaByProposalClick(event, firstFeature.id)

            // clickObjectActionDefault.call(
            //   this,
            //   event,
            //   sourceId,
            //   sourceLayerId,
            //   addPulsedIconClass.call(
            //     this,
            //     new IconCircle(100, 22),
            //     this.map,
            //     sourceId,
            //     sourceId,
            //     sourceImageName,
            //     '#f7f7f7'
            //   )
            // )
        }
        const mouseenterHandler = () => {
            this.mapCanvasStyle.cursor = 'help'
        }
        const mouseleaveHandler = () => {
            this.mapCanvasStyle.cursor = ''
        }

        const highlightCityAreaByProposalClick = (event, proposalId) => {
            // console.log('moveEvent executed')
            const isLayerExist = cityAreafillLayers.map(layerId =>
                this.map.getLayer(layerId)
            )
            if (!isLayerExist.length) return
            let foundFeature
            const bbox = [
                [event.point.x - 20, event.point.y - 20],
                [event.point.x + 20, event.point.y + 20],
            ]
            for (let i = 0; i < cityAreafillLayers.length; i++) {
                const cityAreaFeatures = this.map.queryRenderedFeatures(bbox, {
                    layers: [cityAreafillLayers[i]],
                })
                console.log('cityAreaFeatures', cityAreaFeatures)

                if (cityAreaFeatures.length) {
                    foundFeature = cityAreaFeatures.find(
                        cityAreaFeature =>
                            cityAreaFeature.properties.id ===
                            proposalId.toString()
                    )
                    if (foundFeature) break
                }
            }
            if (!foundFeature) return

            const cityAreaFeature = foundFeature

            const cityAreaId = cityAreaFeature?.properties.id
            const cityAreaName = cityAreaFeature?.properties.name

            if (!cityAreaId) return

            this.$commit('setPartialState', {
                focusedCityAreaNumber: cityAreaId,
            })
            this.$commit('setPartialState', {
                focusedCityAreaName: cityAreaName,
            })

            lineFocusEffect.call(this, cityAreaOutlineEffectLayerId, cityAreaId)
            fillFocusEffect.call(
                this,
                cityAreaFillEffectLayerId,
                cityAreaId,
                true
            )
        }

        this.map.on('click', layerId, clickHandler)
        this.map.on('mouseenter', layerId, mouseenterHandler)
        this.map.on('mouseleave', layerId, mouseleaveHandler)

        // this.changeFocusedRegionHandler(() => {
        //     this.map.off('click', layerId, clickHandler)
        //     this.map.off('mouseenter', layerId, mouseenterHandler)
        //     this.map.off('mouseleave', layerId, mouseleaveHandler)
        // })
    })
}
