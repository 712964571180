import {
    COLORS,
    CONSUMER_STATUS_OPERATING,
    CONSUMER_STATUS_PROJECTED,
    PIPELINE_STATUS_OPERATING,
    PIPELINE_STATUS_PROJECTED,
} from '@/shared/constants'

import customizeLayersStyle from './customizeLayersStyle'
let style: any = null
// let newStyle: any = null
let stylePromise = null
// let newStylePromise = null
export default async function (this: any) {
    if (stylePromise) return await stylePromise

    if (!style) {
        const url = this.$VUE_APP_BUILD_WITH_DEVELOPMENT_PARTS
            ? 'http://192.168.245.196:8162/styles'
            : 'https://gmap.cdu.ru/tile/styles'
        stylePromise = this.$axios
            .get(url)
            .then(response => {
                const style = response.data
                // customizeLayersStyle.call(this, style)
                return style
            })
            .catch(error => {
                console.log('style ERROR')
                // this.handleAxiosError(error)
            })
            .finally(() => {
                stylePromise = null
            })

        style = await stylePromise
        const infrastructureUrl = this.$VUE_APP_BUILD_WITH_DEVELOPMENT_PARTS
            ? 'http://192.168.245.196:8162/infrastructure/new/{z}/{x}/{y}.pbf'
            : 'https://gmap.cdu.ru/tile/infrastructure/'
        const gaslinetilesUrl = this.$VUE_APP_BUILD_WITH_DEVELOPMENT_PARTS
            ? 'http://192.168.245.196:8162/'
            : 'https://gmap.cdu.ru/tile/'
        style = {
            id: 'gas',
            version: 8,
            layers: [
                // {
                //   id: 'region0',
                //   source: 'infrastructure',
                //   type: 'fill',
                //   layout: {},
                //   paint: {
                //     'fill-outline-color': 'rgb(100,100,100)',
                //     'fill-color': 'rgb(230,230,230)',
                //   },
                //   'source-layer': 'region',
                // },
                // {
                //   id: 'region_lines0',
                //   source: 'infrastructure',
                //   type: 'line',
                //   layout: {},
                //   paint: {
                //     'line-color': 'rgba(211,211,211,0.76)',
                //     'line-opacity': 0.6,
                //     'line-width': 4,
                //   },
                //   'source-layer': 'region',
                // },
                {
                    id: 'rasterTiles',
                    type: 'raster',
                    source: 'raster-tiles',
                    minzoom: 0,
                    maxzoom: 22,
                },
                {
                    id: 'region',
                    source: 'infrastructure',
                    type: 'fill',
                    layout: {},
                    paint: {
                        // 'fill-outline-color': 'rgb(100,100,100)',
                        'fill-color': 'rgb(230,230,230)',
                        // 'fill-opacity': 0.3,
                        'fill-opacity': 0,
                    },
                    'source-layer': 'region',
                },
                // {
                //   id: 'region_lines',
                //   source: 'infrastructure',
                //   type: 'line',
                //   layout: {},
                //   paint: {
                //     'line-color': 'rgba(211,211,211,0.76)',
                //     'line-opacity': 0.6,
                //     'line-width': 4,
                //   },
                //   'source-layer': 'region',
                // },
                {
                    id: 'district',
                    source: 'infrastructure',
                    type: 'fill',
                    layout: {},
                    paint: {
                        'fill-outline-color': 'rgb(150,150,150)',
                        'fill-color': 'rgb(230,230,230)',
                    },
                    'source-layer': 'district',
                },
                {
                    id: 'city_town',
                    type: 'fill',
                    source: 'infrastructure',
                    paint: {
                        // 'fill-color': 'rgb(176,176,176)',
                        'fill-color': 'rgba(176,176,176,0.71)',
                        'fill-opacity': 0.3,
                    },
                    'source-layer': 'city_town',
                    maxzoom: 16,
                    minzoom: 8,
                },
                {
                    id: 'city_area',
                    source: 'infrastructure',
                    type: 'fill',
                    layout: {},
                    paint: {
                        'fill-color': [
                            'case',
                            ['==', ['get', 'status'], 'DISCONNECTED'],
                            'rgb(234,140,112)',
                            ['==', ['get', 'status'], 'CONNECTED'],
                            'rgb(126,209,79)',
                            'rgb(230,230,230)',
                        ],
                    },
                    'source-layer': 'city_area',
                },
                {
                    id: 'building',
                    source: 'infrastructure',
                    type: 'fill',
                    layout: {},
                    paint: { 'fill-color': 'rgb(161,158,157)' },
                    'source-layer': 'building',
                },
                {
                    id: 'gas_pipeline_major',
                    source: 'infrastructure',
                    type: 'line',
                    layout: { 'line-cap': 'round', 'line-join': 'round' },
                    paint: {
                        'line-width': 4,
                        // 'line-color': 'rgb(89,89,89)',
                        'line-color': 'rgb(89,89,89)',
                    },
                    'source-layer': 'gas_pipeline_major',
                    minzoom: this.layerMinZoom1,
                },
                {
                    id: 'gas_pipeline_branch',
                    source: 'infrastructure',
                    type: 'line',
                    layout: { 'line-cap': 'round', 'line-join': 'round' },
                    paint: {
                        'line-width': 3,
                        'line-color': 'rgb(71,97,167)',
                    },
                    'source-layer': 'gas_pipeline_branch',
                    minzoom: this.layerMinZoom1,
                },
                {
                    id: 'gas_pipeline_inter_settlement',
                    source: 'infrastructure',
                    type: 'line',
                    layout: { 'line-cap': 'round', 'line-join': 'round' },
                    paint: {
                        'line-width': 1,
                        'line-color': 'rgb(71,97,167)',
                    },
                    'source-layer': 'gas_pipeline_inter_settlement',
                    minzoom: this.layerMinZoom1,
                },
                {
                    id: 'gas_pipeline_consumer',
                    source: 'infrastructure',
                    type: 'line',
                    layout: { 'line-cap': 'round', 'line-join': 'round' },
                    paint: {
                        'line-width': 1,
                        'line-color': 'rgb(71,97,167)',
                    },
                    'source-layer': 'gas_pipeline_consumer',
                    minzoom: this.layerMinZoom1,
                },
                {
                    id: 'consumer',
                    type: 'circle',
                    source: 'infrastructure',
                    'source-layer': 'consumer',
                    paint: {
                        'circle-radius': 2.5,
                        'circle-color': [
                            'case',
                            [
                                '==',
                                ['get', 'status'],
                                CONSUMER_STATUS_OPERATING,
                            ],
                            this.colors[4],
                            [
                                '==',
                                ['get', 'status'],
                                CONSUMER_STATUS_PROJECTED,
                            ],
                            this.colors[9],
                            // this.colors[3],
                            'transparent',
                        ],
                        'circle-stroke-color': [
                            'case',
                            [
                                '==',
                                ['get', 'status'],
                                CONSUMER_STATUS_OPERATING,
                            ],
                            'transparent',
                            [
                                '==',
                                ['get', 'status'],
                                CONSUMER_STATUS_PROJECTED,
                            ],
                            this.colors[4],
                            // this.colors[3],
                            'transparent',
                        ],
                        'circle-stroke-width': 1,
                        'circle-opacity': 0.9,
                    },
                    maxzoom: 16,
                    minzoom: 10,
                },
                {
                    id: 'consumer_big',
                    type: 'circle',
                    source: 'infrastructure',
                    'source-layer': 'consumer_big',
                    paint: {
                        'circle-radius': 4,
                        'circle-opacity': 0.9,
                        'circle-color': [
                            'case',
                            [
                                '==',
                                ['get', 'status'],
                                CONSUMER_STATUS_OPERATING,
                            ],
                            this.colors[4],
                            [
                                '==',
                                ['get', 'status'],
                                CONSUMER_STATUS_PROJECTED,
                            ],
                            this.colors[9],
                            // this.colors[3],
                            'transparent',
                        ],
                        'circle-stroke-color': [
                            'case',
                            [
                                '==',
                                ['get', 'status'],
                                CONSUMER_STATUS_OPERATING,
                            ],
                            this.colors[9],
                            [
                                '==',
                                ['get', 'status'],
                                CONSUMER_STATUS_PROJECTED,
                            ],
                            this.colors[4],
                            // this.colors[3],
                            'transparent',
                        ],
                        'circle-stroke-width': 1,
                    },
                    maxzoom: 16,
                    minzoom: 10,
                },
                {
                    id: 'consumer_main_big',
                    type: 'circle',
                    source: 'infrastructure',
                    'source-layer': 'consumer_main_big',
                    paint: {
                        'circle-radius': 4,
                        'circle-color': [
                            'case',
                            [
                                '==',
                                ['get', 'status'],
                                CONSUMER_STATUS_OPERATING,
                            ],
                            this.colors[4],
                            [
                                '==',
                                ['get', 'status'],
                                CONSUMER_STATUS_PROJECTED,
                            ],
                            this.colors[9],
                            // this.colors[3],
                            'transparent',
                        ],
                        'circle-stroke-color': [
                            'case',
                            [
                                '==',
                                ['get', 'status'],
                                CONSUMER_STATUS_OPERATING,
                            ],
                            this.colors[9],
                            [
                                '==',
                                ['get', 'status'],
                                CONSUMER_STATUS_PROJECTED,
                            ],
                            this.colors[4],
                            // this.colors[3],
                            'transparent',
                        ],
                        'circle-stroke-width': 1,
                        'circle-opacity': 0.9,
                    },
                    maxzoom: 11,
                    minzoom: 10,
                },
                {
                    id: 'consumer_small',
                    type: 'circle',
                    source: 'infrastructure',
                    'source-layer': 'consumer_small',
                    paint: {
                        'circle-radius': 2,
                        'circle-color': [
                            'case',
                            [
                                '==',
                                ['get', 'status'],
                                CONSUMER_STATUS_OPERATING,
                            ],
                            this.colors[4],
                            [
                                '==',
                                ['get', 'status'],
                                CONSUMER_STATUS_PROJECTED,
                            ],
                            this.colors[9],
                            // this.colors[3],
                            'transparent',
                        ],
                        // 'circle-stroke-color': [
                        //     'case',
                        //     [
                        //         '==',
                        //         ['get', 'status'],
                        //         CONSUMER_STATUS_OPERATING,
                        //     ],
                        //     this.colors[9],
                        //     [
                        //         '==',
                        //         ['get', 'status'],
                        //         CONSUMER_STATUS_PROJECTED,
                        //     ],
                        //     this.colors[4],
                        //     // this.colors[3],
                        //     'transparent',
                        // ],
                        // 'circle-stroke-width': 1,
                        'circle-opacity': 0.9,
                    },
                    maxzoom: 16,
                    minzoom: 11,
                },
                // {
                //     id: 'consumer',
                //     source: 'infrastructure',
                //     type: 'circle',
                //     layout: {},
                //     paint: {
                //         'circle-stroke-width': 1,
                //         'circle-color': 'rgb(104,130,175)',
                //         'circle-stroke-color': 'rgb(255,255,255)',
                //         'circle-radius': [
                //             'case',
                //             ['==', ['get', 'type'], 'MAIN_BIG'],
                //             4,
                //             ['==', ['get', 'type'], 'BIG'],
                //             3,
                //             ['==', ['get', 'type'], 'SMALL'],
                //             2,
                //             3,
                //         ],
                //     },
                //     'source-layer': 'consumer',
                // },
                {
                    id: 'gas_distribution_station',
                    source: 'infrastructure',
                    type: 'circle',
                    layout: {
                        visibility: 'none',
                    },
                    paint: {
                        'circle-stroke-width': 1,
                        'circle-color': 'rgb(240,230,140)',
                        'circle-stroke-color': 'rgb(189,183,107)',
                        'circle-radius': 5,
                    },
                    'source-layer': 'gas_distribution_station',
                    minzoom: this.layerMinZoom1,
                },
                // {
                //     id: 'thermoelectric_power_station',
                //     source: 'infrastructure',
                //     type: 'circle',
                //     layout: {},
                //     paint: {
                //         'circle-stroke-width': 1,
                //         'circle-color': 'rgb(255,165,0)',
                //         'circle-stroke-color': 'rgb(255,255,255)',
                //         'circle-radius': 5,
                //     },
                //     'source-layer': 'thermoelectric_power_station',
                // },
            ],
            sources: {
                infrastructure: {
                    type: 'vector',
                    // url: 'http://192.168.245.196:8162/infrastructure/',
                    tiles: [infrastructureUrl],
                },
            },
        }

        style.sources['raster-tiles'] = {
            type: 'raster',
            tiles: [
                'https://pkk.rosreestr.ru/arcgis/rest/services/BaseMaps/BaseMap/MapServer/tile/{z}/{y}/{x}/',
            ],
            tileSize: 256,
            attribution: '',
        }

        style.layers = style.layers.filter(layer => {
            return layer.id !== 'water' && layer.id !== 'river'
        })

        await customizeLayersStyle.call(this, style)
    }
    return style
}
