<template>
    <transition :name="$style.slide">
        <div
            :class="$style.loaderWrapper"
            v-if="loadingSourceUrls?.length || globalLoading"
            ref="mapLoader"
        >
            <el-button loading>{{
                loadingDescription(
                    this.$state.loadingSourceUrls?.[
                        this.$state.loadingSourceUrls?.length - 1
                    ]
                )
            }}</el-button>
        </div>
    </transition>
</template>

<script>
import { loadingDescription } from './model/loadingDescription'
import { mapState } from 'vuex'

export default {
    props: {
        color: {
            type: String,
            // default: COLORS[3],
            default: 'var(--color2)',
        },
    },
    methods: {
        loadingDescription,
    },
    computed: {
        ...mapState({
            loadingSourceUrls: state => state.loadingSourceUrls,
            globalLoading: state => state.globalLoading,
        }),
    },
}
</script>

<style lang="stylus" module>
.slide
    &:global(-enter-from)
        transform: translateY(-150%);
    &:global(-leave-to)
        transform: translateY(-150%);

.loaderWrapper
    // background-color #fff
    padding var(--indent2)
    position absolute
    //top 50%
    left 50%
    transition transform var(--transitionDuration1)
    //transform: translateY(-150%);
    z-index 9999
    border-radius var(--borderRadius1)
    // transform rotate(90deg)
    .loaderBlock
        transition transform var(--transitionDuration1)
    &:not(:hover)
        .loaderBlock
            transform translateX(100%)

.loaderBlock
    display: flex;
    justify-content: center;
    align-items: center;
.loaderIos
    position relative
    animation iosIntro .6s
    svg
        fill: #ffffff;

        path:nth-of-type(1)
            animation: pulse 1s infinite linear
        path:nth-of-type(2)
            animation: pulse 1s -.083s infinite linear

        path:nth-of-type(3)
            animation: pulse 1s -.166s infinite linear
        path:nth-of-type(4)
            animation: pulse 1s -.249s infinite linear

        path:nth-of-type(5)
            animation: pulse 1s -.332s infinite linear

        path:nth-of-type(6)
            animation: pulse 1s -.415s infinite linear

        path:nth-of-type(7)
            animation: pulse 1s -.498s infinite linear

        path:nth-of-type(8)
            animation: pulse 1s -.581s infinite linear

        path:nth-of-type(9)
            animation: pulse 1s -.664s infinite linear

        path:nth-of-type(10)
            animation: pulse 1s -.747s infinite linear

        path:nth-of-type(11)
            animation: pulse 1s -.83s infinite linear

        path:nth-of-type(12)
            animation: pulse 1s -.913s infinite linear

@keyframes pulse {
    50% { fill: #868686; }
    to { fill: rgba(134,134,134,.4); }
}
</style>
