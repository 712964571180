// import { /* convertProjection, */ getCenterOfCoordinates } from '@/lib'
export default function (this: any, layerFillId) {
    // this.map.on('dblclick', layerFillId, event => {
    //     try {
    //         // console.log('dblClick feat', event.point)
    //         // const firstFeature = event?.features?.[0]
    //         const firstFeature = this.map.queryRenderedFeatures(event.point, {
    //             layers: [layerFillId],
    //         })[0]
    //         // const regionNumber = firstFeature.properties.number
    //         //TODO: MARK of new tiles loading
    //         // const regionNumber = firstFeature.id
    //         const regionNumber = firstFeature.properties.id
    //         const proposalMarkersRef = this.$refs.proposalMarkers
    //         if (!proposalMarkersRef) return
    //         const regionsProposals = proposalMarkersRef.regionsProposals
    //         const proposalOfRegion = regionsProposals[regionNumber]
    //         const proposalCentroid = proposalOfRegion.centroid
    //         const flyToParams: any = {
    //             center: proposalCentroid,
    //         }
    //         if (this.mapZoom < this.layerMinZoom1) {
    //             // flyToParams.zoom = this.layerMinZoom1
    //             flyToParams.zoom = 6.8
    //         }
    //         setTimeout(() => {
    //             this.map.flyTo(flyToParams)
    //         }, 0)
    //     } catch (error) {
    //         console.error(error)
    //     }
    // })
}
