<template>
    <div :class="$style.container">
        <div :class="$style.selected" @click="toggleDropdown">
            {{ chosenAppModule?.name || 'Выберите модуль' }}
        </div>
        <Transition :name="$style.dropDown">
            <div :class="$style.dropdownList" v-if="isOpen">
                <div :class="$style.dropdownList__row"></div>
                <div
                    :class="$style.dropdownList__row"
                    v-for="module in notSelectedAppModules"
                    :key="module.id"
                    @click="setChosenAppModule(module), (isOpen = false)"
                >
                    {{ module.name }}
                </div>
            </div>
        </Transition>
    </div>
</template>
<script>
import { mapGetters, mapMutations, mapState } from 'vuex'

export default {
    data() {
        return {
            isOpen: false,
        }
    },
    props: {
        chooseModuleId: String,
    },
    components: {},
    computed: {
        ...mapState({
            appModuleAccess: state => state.appModuleAccess,
            chosenAppModule: state => state.chosenAppModule,
        }),
        ...mapGetters(['allowedAppModules']),

        notSelectedAppModules() {
            return Object.values(this.allowedAppModules).filter(
                module => module.id !== this.chosenAppModule.id
            )
        },
    },
    methods: {
        ...mapMutations(['setChosenAppModule']),

        toggleDropdown() {
            this.isOpen = !this.isOpen
        },
    },
}
</script>

<style lang="stylus" module>
//transitions
.dropDown
    &:global(-enter-active), &:global(-leave-active)
        transition all .2s
        transform translateY(0)
        opacity 1
    &:global(-enter-from), &:global(-leave-to)
        transform translateY(-10px)
        opacity 0

.container
    display: flex;
    flex-direction column
    align-items: center;
    position absolute
    z-index 999
    right 200px
    background-color: #F6F6F6;
    border-radius 10px
    cursor pointer
    width 200px
    text-align: center;
    .selected
        border-radius 10px
        background-color: #000;
        color #F6F6F6;
        width: 100%;
        height 40px
        padding: 10px 20px;
        position: absolute
        z-index: 999;
    .dropdownList
        position: absolute
        z-index: 1;
        width: 100%;
        background-color #F6F6F6;
        border-radius 10px
        .dropdownList__row
            padding: 10px 20px;
            height 40px
</style>
