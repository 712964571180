// import highlightGdsPipelines from './highlightGdsPipelines'
import toggleActiveGds from '@/entities/Map/gasification/model/addGds/toggleActiveGds'

import watchIsShowGds from './watchIsShowGds'
import addPulsedIconClass, {
    IconCircle,
} from '@/entities/Map/general/model/mapUtils/addPulsedIconClass'
import clickObjectActionDefault from '@/entities/Map/general/model/clickObjectActionDefault'
import { GDS_STATUS_ICON_COLORS } from '@/shared/constants'

export default function (this: any, layerIds) {
    const maplibreglPopup = this.maplibregl.Popup
    layerIds.forEach((layerId, index: number) => {
        // let activePopup: any = null
        const clickHandler = event => {
            console.log('gds event', event?.features)
            // console.log('state.gds', this.$state.view.gdsMenu)
            // console.log('help', event)
            // event.originalEvent.stopPropagation()
            // event.originalEvent.preventDefault()
            event.preventDefault()
            if (!event.defaultPrevented) {
                // click was performed outside of layer_1
                // layer_2 exclusive functionality
                event.preventDefault()
                console.log(
                    'outside click Gds',
                    event,
                    event.originalEvent.defaultPrevented
                )
            }
            // event.originalEvent.stopImmediatePropagation()
            // event.originalEvent.cancelBubble = true
            const firstFeature = event?.features?.[0]
            if (!firstFeature) return
            const coordinates = firstFeature?.geometry?.coordinates?.slice()
            if (!coordinates) return
            const content = event.features[0].properties?.name

            // if (!content) return
            // Ensure that if the map is zoomed out such that multiple
            // copies of the feature are visible, the popup appears
            // over the copy being pointed to.
            // while (Math.abs(event.lngLat.lng - coordinates[0]) > 180) {
            //     coordinates[0] += event.lngLat.lng > coordinates[0] ? 360 : -360
            // }
            this.$state.view.gdsMenu.gdsName = `${content}`
            //TODO: MARK of new tiles loading
            // toggleActiveGds.call(this, firstFeature.id, coordinates)
            console.log('toggleActiveGds id', firstFeature.properties.id)
            const sourceId = firstFeature.layer.source
            const sourceLayerId = firstFeature.layer['source-layer']
            const sourceImageName = firstFeature.layer.layout['icon-image']

            console.log(
                'firstFeature.properties?.status',
                firstFeature.properties?.status,
                GDS_STATUS_ICON_COLORS[firstFeature?.properties?.status]
            )
            clickObjectActionDefault.call(
                this,
                event,
                sourceId,
                sourceLayerId,
                addPulsedIconClass.call(
                    this,
                    new IconCircle(100, 22),
                    this.map,
                    sourceId,
                    '#f7f7f7',
                    sourceLayerId
                )
            )
            toggleActiveGds.call(this, firstFeature.properties.id, coordinates)
        }
        const mouseenterHandler = () => {
            this.mapCanvasStyle.cursor = 'help'
        }
        const mouseleaveHandler = () => {
            this.mapCanvasStyle.cursor = ''
        }

        this.map.on('click', layerId, clickHandler)
        this.map.on('mouseenter', layerId, mouseenterHandler)
        this.map.on('mouseleave', layerId, mouseleaveHandler)
        watchIsShowGds.call(this)
    })
}
