import { emptyFeatureCollection } from '@/entities/Map/general/model/mapUtils/mapUtilsConstants'
import { PROPOSALS_STATUSES_COLORS } from '@/shared/constants'

export default async function (this: any, sourceId) {
    if (!this.map.getSource(sourceId)) {
        await this.addSource(
            sourceId,
            this.featureCollectionType,
            emptyFeatureCollection(),
            this.map,
            {
                cluster: true,
                clusterMaxZoom: 14, // Max zoom to cluster points on
                clusterRadius: 80,
                clusterProperties: {
                    applicationStatusColor: [
                        'concat',
                        ['concat', ['get', 'applicationGeneralStatus'], ','],
                        ',',
                    ],
                },
            }
        )
    }

    const markers = {}
    let markersOnScreen = {}

    const updateMarkers = () => {
        const newMarkers = {}
        const features = this.map.querySourceFeatures(sourceId)

        // for every cluster on the screen, create an HTML marker for it (if we didn't yet),
        // and add it to the map if it's not there already
        for (const feature of features) {
            const coords = feature.geometry.coordinates
            const props = feature.properties
            if (!props.cluster) continue
            const id = props.cluster_id

            let marker: any = markers[id]
            if (!marker) {
                // const el = createDonutChart(props);
                const el = createDonutChart(props)
                marker = markers[id] = new this.maplibregl.Marker({
                    element: el,
                }).setLngLat(coords)
            }
            newMarkers[id] = marker

            if (!markersOnScreen[id]) marker.addTo(this.map)
        }
        // for every marker we've added previously, remove those that are no longer visible
        for (const id in markersOnScreen) {
            if (!newMarkers[id]) markersOnScreen[id].remove()
        }
        markersOnScreen = newMarkers
    }

    // after the GeoJSON data is loaded, update markers on the screen on every frame
    updateMarkers()
    this.map.on('render', () => {
        if (!this.map.isSourceLoaded(sourceId)) return
        updateMarkers()
    })

    // code for creating an SVG donut chart from feature properties
    function createDonutChart(props) {
        const statusesInCluster = props.applicationStatusColor
            .split(',')
            .slice(0, -1)
        const countsOfStatuses = {}
        statusesInCluster.forEach(
            x => (countsOfStatuses[x] = (countsOfStatuses[x] || 0) + 1)
        ) // count the number of occurrences of each element
        const mostCommonStatus = Object.keys(countsOfStatuses).reduce((a, b) =>
            countsOfStatuses[a] >= countsOfStatuses[b] ? a : b
        )

        const offsets: any = {}
        const counts = [
            props.mag1,
            props.mag2,
            props.mag3,
            props.mag4,
            props.mag5,
        ]
        let total: any = 0
        for (const count in countsOfStatuses) {
            offsets[count] = total
            total += countsOfStatuses[count]
        }
        const fontSize =
            total >= 1000 ? 16 : total >= 100 ? 16 : total >= 10 ? 14 : 12
        const r = total >= 1000 ? 30 : total >= 100 ? 24 : total >= 10 ? 20 : 18
        const r0 = Math.round(r * 0.7)
        const w = r * 2

        let html = `
          <svg width="${w}" height="${w}" viewbox="0 0 ${w} ${w}" text-anchor="middle" style="font: ${fontSize}px sans-serif; display: block">
            `

        for (const status in countsOfStatuses) {
            html += donutSegment(
                offsets[status] / total,
                (offsets[status] + countsOfStatuses[status]) / total,
                r,
                r0,
                PROPOSALS_STATUSES_COLORS['applicationGeneralStatus'][status]
            )
        }
        html += `
          <circle cx="${r}" cy="${r}" r="${r0}" fill="rgba(255,255,255,0.72)" />
          <text fill="#595959" font-family="SF Pro Text" font-weight="bold" dominant-baseline="central" transform="translate(${r}, ${r})">
            ${total.toLocaleString()}
          </text>
          </svg>
        `

        const el = document.createElement('div')
        el.innerHTML = html
        return el
    }

    function donutSegment(start, end, r, r0, color) {
        if (end - start === 1) end -= 0.00001
        const a0 = 2 * Math.PI * (start - 0.25)
        const a1 = 2 * Math.PI * (end - 0.25)
        const x0 = Math.cos(a0),
            y0 = Math.sin(a0)
        const x1 = Math.cos(a1),
            y1 = Math.sin(a1)
        const largeArc = end - start > 0.5 ? 1 : 0

        // draw an SVG path
        return `<path d="M ${r + r0 * x0} ${r + r0 * y0} L ${r + r * x0} ${
            r + r * y0
        } A ${r} ${r} 0 ${largeArc} 1 ${r + r * x1} ${r + r * y1} L ${
            r + r0 * x1
        } ${r + r0 * y1} A ${r0} ${r0} 0 ${largeArc} 0 ${r + r0 * x0} ${
            r + r0 * y0
        }" fill="${color}" />`
    }
}
