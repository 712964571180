export default async function (this: any, allLayers) {
    //сейчас эти методы используются в основном для добавления ивентов к слоям
    const downloadedPromises = [
        this.addPipelinesBranch(),
        this.addPipelinesSettlement(),
        this.addPipelinesMajor(),
        // this.addPipelinesConsumer(),
        this.addConsumers(),
        // // this.addBuildings(),
        this.addCityArea(),
        // this.addStations3d(),
        this.addAzs(),
        this.addGds(),
        this.addSocialObjects(),
    ]
    const downloadedCallbacks = await Promise.all(downloadedPromises)

    downloadedCallbacks.forEach(downloadedCallback => {
        if (typeof downloadedCallback !== 'function') return
        downloadedCallback()
    })
}
