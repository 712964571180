export default function (this: any) {
    // const layersIds = [this.gdsIcon, this.gdsSybol]
    const layersIds = this.gdsLayerIds
    let unwatchIsShowGds: any = null
    this.$watch(
        'activeRegionNumber',
        async activeRegionNumber => {
            // console.log('activeRegionNumber:', activeRegionNumber)

            if (unwatchIsShowGds) {
                unwatchIsShowGds()
                unwatchIsShowGds = null
            }
            await this.$nextTick()
            unwatchIsShowGds = this.$watch(
                'isShowGds',
                isShowGds => {
                    // console.log('activeRegionNumber isShowGds:', isShowGds)
                    if (isShowGds) {
                        this.setLayersVisibility(layersIds, 'visible')
                        return
                    }
                    this.setLayersVisibility(layersIds, 'none')
                },
                {
                    immediate: true,
                }
            )
        },
        {
            immediate: true,
        }
    )
    this.$watch(
        'isShowGds',
        isShowGds => {
            if (isShowGds) {
                this.setLayersVisibility(layersIds, 'visible')
                return
            }
            this.setLayersVisibility(layersIds, 'none')
        },
        {
            immediate: true,
        }
    )
}
