import { popupTypes } from '@/entities/Map/general/model/addLayerHoverActions/types/popupTypes'
import { popupBackgroundColorsBySourceLayer } from '@/shared/constants'
import setPopupData from '@/entities/Map/general/model/setPopupData'

export default function (this: any, source, hoveringLayerId, getHtmlContent) {
    let activePopup: any = null
    const sourceAndSourceLayer = {
        source,
        // sourceLayer: 'gas_pipeline_inter_settlement',
    }
    // let activePipelineSource: any = ''
    let prevPopupId
    // let isClicked = false

    // let hoveredFeatureId = ''
    const mouseenterHandler = event => {
        const feature = event.features[0]
        // console.log('mouseenterHandler', feature)
        this.mapCanvasStyle.cursor = 'help'
        if (!feature) return
        const {
            lngLat: { lng, lat },
        } = event
        const lngLat = [lng, lat]
        if (activePopup) {
            activePopup.setLngLat(lngLat)
        } else {
            activePopup = setPopupData.call(
                this,
                feature,
                event.lngLat,
                prevPopupId,
                getHtmlContent
            )
            // console.log('activePopup', activePopup)
        }
    }

    const mousemoveHandler = event => {
        const feature = event.features[0]
        // console.log('mouseenterHandler', event.features[0])
        this.mapCanvasStyle.cursor = 'help'
        if (!feature) return
        const {
            lngLat: { lng, lat },
        } = event
        const lngLat = [lng, lat]
        activePopup = setPopupData.call(
            this,
            feature,
            event.lngLat,
            prevPopupId,
            getHtmlContent
        )
    }

    const mouseleaveHandler = event => {
        this.mapCanvasStyle.cursor = ''
        this.$state.map.popupMap.isShow = false
    }

    // this.map.on('mouseenter', hoveringLayerId, mouseenterHandler)
    this.map.on(
        this.isMobileView ? 'touchend' : 'mouseleave',
        hoveringLayerId,
        mouseleaveHandler
    )
    this.map.on(
        this.isMobileView ? 'touchstart' : 'mousemove',
        hoveringLayerId,
        mousemoveHandler
    )

    // this.changeFocusedRegionHandler(() => {
    //   // this.map.off('mouseenter', hoveringLayerId, mouseenterHandler)
    //   this.map.off('mouseleave', hoveringLayerId, mouseleaveHandler)
    //   this.map.off('mousemove', hoveringLayerId, mousemoveHandler)
    //   activePopup?.remove?.()
    // })
}
