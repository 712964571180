import { FeatureCollection, Point } from 'geojson'
import { shallowReactive } from 'vue'
import loadSource from '@/entities/Map/general/model/loadSource'
import { ActionTree, Module, MutationTree } from 'vuex'
import { BASE_MAP_ENTITY_PATHS } from '@/app/providers/store/modules/mapEntity/api/config'
import {
    MapEntityState,
    ObjectEntityStateGeneric,
} from '@/app/providers/store/modules/mapEntity/mapEntity.types'

interface MapEntityStoreModuleOptions {
    source_path: string
    api_paths: any & typeof BASE_MAP_ENTITY_PATHS
}

export interface LoadActionPayload {
    loadFn: typeof loadSource
}

export interface AbstractModule<S extends ObjectEntityStateGeneric, R>
    extends Module<MapEntityState<S>, R> {
    source_path: MapEntityStoreModuleOptions['source_path']
    api_paths?: MapEntityStoreModuleOptions['api_paths']
}

const defaultState: MapEntityState<any> = {
    featureCollections: {
        all: null,
        regions: null,
    },
    activeObject: null,
}

export class MapEntityStoreModule<S extends ObjectEntityStateGeneric, R>
    implements AbstractModule<S, R>
{
    source_path = ''
    api_paths = BASE_MAP_ENTITY_PATHS
    state = defaultState

    mutations: MutationTree<MapEntityState<S>> = {
        setFeatureCollectionAll: (
            state,
            featureCollection: FeatureCollection<Point>
        ) => {
            state.featureCollections.all = shallowReactive(featureCollection)
        },
        setFeatureCollectionForRegion: (
            state,
            featureCollection: FeatureCollection<Point>
        ) => {
            state.featureCollections.regions =
                shallowReactive(featureCollection)
        },
        setActiveObject: (
            state,
            activeObject: MapEntityState<S>['activeObject']
        ) => {
            state.activeObject = activeObject
        },
    }

    actions: ActionTree<MapEntityState<S>, R> = {
        loadAll: async (
            { commit, state, rootState },
            { loadFn }: LoadActionPayload
        ) => {
            const featureCollection = await loadFn<FeatureCollection<Point>>(
                this.source_path + this.api_paths.getAll
            )
            if (!featureCollection) return
            commit('setFeatureCollectionAll', featureCollection)
        },
        loadByRegion: async (
            { commit, state, rootState },
            { loadFn, regionId }: { regionId: number } & LoadActionPayload
        ) => {
            const featureCollection = await loadFn<FeatureCollection<Point>>(
                this.source_path + this.api_paths.getByRegion + regionId
            )
            if (!featureCollection) return
            commit('setFeatureCollectionForRegion', featureCollection)
        },
        loadById: async (
            { commit, state, rootState },
            {
                loadFn,
                id,
            }: {
                id: MapEntityState<S>['activeObject']['id']
            } & LoadActionPayload
        ) => {
            const feature = await loadFn<MapEntityState<S>['activeObject']>(
                this.source_path + this.api_paths.getById + id
            )
            if (!feature) return
            commit('setActiveObject', feature)
        },
    }

    getters = {}
    modules = {}
    namespaced: boolean

    constructor(
        storeModule: Module<S, R>,
        options: MapEntityStoreModuleOptions
    ) {
        this.source_path = options.source_path
        this.api_paths = { ...this.api_paths, ...options.api_paths }

        this.state = storeModule.state
            ? storeModule.state instanceof Function
                ? { ...this.state, ...storeModule.state() }
                : { ...this.state, ...storeModule.state }
            : this.state
        this.namespaced = !!storeModule.namespaced
        this.mutations =
            (storeModule.mutations && {
                ...this.mutations,
                ...storeModule.mutations,
            }) ||
            this.mutations
        this.actions =
            (storeModule.actions && {
                ...this.actions,
                ...storeModule.actions,
            }) ||
            this.actions
        this.getters =
            (storeModule.getters && {
                ...this.getters,
                ...storeModule.getters,
            }) ||
            this.getters
        this.modules =
            (storeModule.modules && {
                ...this.modules,
                ...storeModule.modules,
            }) ||
            this.modules
    }
}
