import highlightGdsPipelines from '@/entities/Map/gasification/model/addGds/highlightGdsPipelines'
import axiosInstance from '@/shared/plugins/axios'
import gdsClearEffects from '@/entities/Map/gasification/model/addGds/gdsClearEffects'
import { stepsListIndexes } from '@/shared/constants'

export default function (
    this: any,
    firstFeatureId?,
    gdsCenterCoordinates?,
    close?
) {
    const clearLayers = () => {
        if (!this.$state.view.gdsMenu.gdsPipelinesData) return
        // this.$state.view.gdsMenu.gdsPipelinesData.forEach(feature => {
        //     this.map.removeLayer(`highlightGdsPipelines_${feature.id}`)
        // })
        this.map.removeLayer(`highlightGdsPipelines`)
        this.$state.view.gdsMenu.gdsPipelinesData = null
    }

    console.log('CLOSE MENU EV', firstFeatureId)

    const getGdsData = () => {
        this.loadSource(`/infrastructure/pipeline/gds/${firstFeatureId}`).then(
            source => {
                //TODO: узнать что за source
                console.log(
                    `/infrastructure/pipeline/gds/${firstFeatureId}`,
                    source?.features
                )

                if (source?.features.length === 0 || !source) return

                highlightGdsPipelines.call(
                    this,
                    source.features,
                    gdsCenterCoordinates
                )
                this.$state.view.gdsMenu.gdsPipelinesData = source.features
                return source.features
            }
        )
        this.$state.view.gdsMenu.gdsData = {}
        this.$state.view.gdsMenu.isGdsDataLoading = true
        this.loadGdsData.call(this, firstFeatureId)
        this.$state.devView.loadedItems = [
            `ID: <strong>${firstFeatureId}</strong>`,
        ]
    }

    this.$state.view.gdsMenu.gdsId = firstFeatureId
    this.$state.view.gdsMenu.state = !this.$state.view.gdsMenu.state
    // }
    if (this.$state.view.gdsMenu.state) {
        // this.f7.pages.main.router.navigate('/gds-view/')
        // this.$commit('setIsStepsListChangeLocked', true)
        this.$dispatch('setStepActiveId', stepsListIndexes.gdsInfoStepIndex)

        getGdsData()
    } else {
        gdsClearEffects.call(this)

        clearLayers()
    }
}
