import { handleAgnksObjectSelect } from '@/pages/AGNKS/ObjectsViewAGNKS/model/handleAnksObjectSelect'

export default function (this: any, layerIds) {
    console.log('mapCanvasStyle', layerIds)
    layerIds.forEach((layerId, index: number) => {
        // let activePopup: any = null
        const clickHandler = event => {
            console.log('agnks event', event?.features)

            event.preventDefault()
            if (!event.defaultPrevented) {
                // click was performed outside of layer_1
                // layer_2 exclusive functionality
                event.preventDefault()
                console.log(
                    'outside click Gds',
                    event,
                    event.originalEvent.defaultPrevented
                )
            }

            const firstFeature = event?.features?.[0]
            handleAgnksObjectSelect(firstFeature, this, this.$dispatch)
        }
        const mouseenterHandler = () => {
            this.mapCanvasStyle.cursor = 'help'
        }
        const mouseleaveHandler = () => {
            this.mapCanvasStyle.cursor = ''
        }

        this.map.on('click', layerId, clickHandler)
        this.map.on('mouseenter', layerId, mouseenterHandler)
        this.map.on('mouseleave', layerId, mouseleaveHandler)
    })
}
