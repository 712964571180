export default function (this: any, nodeClicked, eventType: string) {
    console.log('nodeExpandEvent', nodeClicked)
    // switch (eventType) {
    //   case 'expanded':
    //     this.$state.view.regionsView.expandedNodes.add(nodeClicked.id)
    //     return
    //   case 'collapsed':
    //     this.$state.view.regionsView.expandedNodes.delete(nodeClicked.id)
    //     break
    // }
}
