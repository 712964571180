import {
    IMG_AZS_COMPANY_NOVATEK,
    IMG_MAZK_COMPANY_NOVATEK,
} from '@/shared/constants'
import { htmlToElement } from '@/shared/lib'
export default function (this: any, status: string, size: number) {
    const getMarkerFillStroke = status => {
        let fill = ''
        let stroke = ''

        switch (status) {
            case IMG_MAZK_COMPANY_NOVATEK:
                fill = this.colors[39]
                stroke = this.colors[9]
                break
            default:
                fill = '#000000'
                stroke = this.colors[9]
                break
        }

        return {
            fill,
            stroke,
        }
    }
    const { fill, stroke } = getMarkerFillStroke(status)
    const el = htmlToElement(`
                    <svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 22.107 20.437">
                      <g id="Сгруппировать_2424" data-name="Сгруппировать 2424" fill="${fill}" transform="translate(1.053 1)">
                        <path id="Контур_3147" data-name="Контур 3147" d="M12.918,0H7.082A4.167,4.167,0,0,0,3.475,2.083L.558,7.136a4.165,4.165,0,0,0,0,4.165l2.917,5.052a4.167,4.167,0,0,0,3.607,2.083h5.835a4.165,4.165,0,0,0,3.607-2.083L19.442,11.3a4.165,4.165,0,0,0,0-4.165L16.525,2.083A4.165,4.165,0,0,0,12.918,0" transform="translate(0 0)" stroke="${stroke}" stroke-width="2"/>
                        <path id="Контур_3148" data-name="Контур 3148" d="M9.928,3.473H7.146a1.989,1.989,0,0,0-1.722.993L4.033,6.876a1.991,1.991,0,0,0,0,1.987l1.391,2.41a1.986,1.986,0,0,0,1.722.993H9.928a1.984,1.984,0,0,0,1.72-.993l1.391-2.41a1.984,1.984,0,0,0,0-1.987l-1.391-2.41a1.987,1.987,0,0,0-1.72-.993" transform="translate(1.463 1.349)" fill="#fff" stroke="${stroke}" stroke-width="2"/>
                      </g>
                    </svg>
                `)

    return el
}
