import { AGNKS_API_PATHS } from '@/entities/ANGKS/api/config'
import { Feature, FeatureCollection, Point } from 'geojson'

export interface AGNKSStatsOfSubject {
    finished_count: number | null
    all_proposal_count: number | null
    in_work_count: number | null
    rejected_count?: number | null
}

export interface AGNKSStats {
    russia: AGNKSStatsOfSubject | null
    region: AGNKSStatsOfSubject | null
}

export interface AGNKSEntityState {
    activeObject: AGNKSObject | null
}

export enum ANGKSStatuses {
    UNDER_CONSTRUCTION = 'UNDER_CONSTRUCTION',
    OPERATING = 'OPERATING',
}

export const ANGKSStatusesColors = {
    [ANGKSStatuses.UNDER_CONSTRUCTION]: '#ff9500',
    [ANGKSStatuses.OPERATING]: '#4cd964',
}

export interface AGNKSObject {
    id: number
    sourceId: string
    reaId: string
    region: string
    name: string
    publicityType: string
    address: string
    owner: string
    operator: string
    provider: string
    tinOwner: string
    tinOperator: string
    tinProvider: string
    year: string
    power: string
    status: keyof typeof ANGKSStatuses
    searchStatus: number
    aprove: boolean
    geometry: Point
}

export interface AGNKSFeaturePointProperties {
    status: keyof typeof ANGKSStatuses
}
export type AGNKSFeaturePoint = Feature<Point, AGNKSFeaturePointProperties>
