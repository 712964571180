export const awaitComponentPositiveValue = async (
    componentInstance: any,
    propKey: string
) => {
    return new Promise(resolve => {
        const unwatch = componentInstance.$watch(
            propKey,
            value => {
                if (!value) return
                setTimeout(() => {
                    unwatch()
                    resolve(value)
                }, 0)
            },
            {
                immediate: true,
            }
        )
    })
}
