import MiniMap from './MiniMap'
export default async function (this: any) {
    this.maplibregl.MiniMap = MiniMap
    // const style = await this.getMapStyle()
    // console.log('minimap style:', style)

    const miniMap = new this.maplibregl.MiniMap({
        // center: [-5.46102832274704, 54.039187268526774],
        center: [107.98639829758218, 68.43758819956565],
        // container: this.$refs.map,
        container: this.$refs.miniMap,
        zoom: 0.8,
        zoomLevels: [],
        style: this.mapStyle,
        trackingAreaMarkerElement: this.$refs.miniMapTrackingAreaMarker,
        // width: 450,
        // height: 240,
        // minimized: !this.isShowMiniMap,
        // toggleCallback: isShowMiniMap => {
        //     this.$commit('setViewPartialState', {
        //         isShowMiniMap,
        //     })
        // },
    })
    miniMap.onAdd(this.map)
    this.miniMap = miniMap.miniMap
    this.miniMapInstance = miniMap
    globalThis.miniMap = miniMap

    // console.log('miniMap:', miniMap)
    // miniMap.miniMap.scrollZoom.disable()
    await new Promise(resolve => {
        this.miniMap.on('load', resolve)
    })
}
