import { lifetimeProposalsMs } from '@/shared/constants'

export default async function (
    this: any,
    url,
    newDistrictIdsSet: string[],
    signal?: AbortSignal
) {
    const featuresOfDistricts: any[] = []
    for (let i = 0; i < newDistrictIdsSet?.length; i++) {
        try {
            const loadedFeatures = await this.loadSource(
                `${url}${newDistrictIdsSet[i]}`,
                lifetimeProposalsMs,
                signal
            )
            if (!loadedFeatures) return
            featuresOfDistricts.push(loadedFeatures)
        } catch (error) {
            if (error.__CANCEL__) {
                break
            }
        }
    }

    return featuresOfDistricts
}
