import { defineComponent } from 'vue'

import addLayerHoverActions from '../addLayerHoverActions'
import addClickActions from '../addClickActions'
import addPipelinesBranch from './methods/addPipelinesBranch'
import addPipelinesConsumer from './methods/addPipelinesConsumer'
import addPipelinesMajor from './methods/addPipelinesMajor'
import addPipelinesSettlement from './methods/addPipelinesSettlement'
export default defineComponent({
    methods: {
        addPipelinesBranch,
        addPipelinesMajor,
        addPipelinesSettlement,
        addLayerHoverActions,
        addClickActions,
        addPipelinesConsumer,
    },
})
