// import '@/registerServiceWorker'
import '@/app/styles/index.styl'
import 'vue-transitions-css'

import { createApp } from 'vue'

import App from '@/app/index.vue'
import { initAxios } from '@/shared/plugins/axios'
import installElementPlus from '@/shared/plugins/element'
import initFramework7 from '@/shared/plugins/framework7'
import { router, store } from '@/app/providers'
import initKeycloak from '@/features/general/Auth/model/initKeycloak'

const app = createApp(App)
export type AppContext = typeof App

const appGlobalProperties = app.config.globalProperties
appGlobalProperties.$app = app
appGlobalProperties.$VUE_APP_BUILD_WITH_DEVELOPMENT_PARTS =
    process.env.VUE_APP_BUILD_WITH_DEVELOPMENT_PARTS === '1'

installElementPlus(app)
initFramework7(app)
initAxios(app)
app.use(store)
Object.assign(appGlobalProperties, {
    $state: store.state,
    $commit: store.commit,
    $dispatch: store.dispatch,
})

app.use(router)

initKeycloak(appGlobalProperties)

export default app
