import { setPartialState } from '@/shared/lib'
export default {
    state: {
        loadedItems: [],
    },
    mutations: {
        setViewPartialState: setPartialState,
    },
    getters: {},
    actions: {},
    modules: {},
}
