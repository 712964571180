//
import { COLORS } from '@/shared/constants'

const highlighterOpacity = 0.6
export default function (this: any, featureData) {
    const currentHighlightSource = this.$map.getSource(
        this.highlightSourceAndLayerId
    )
    if (currentHighlightSource) {
        currentHighlightSource.setData(featureData)
    } else {
        this.$map.addSource(this.highlightSourceAndLayerId, {
            type: 'geojson',
            data: featureData,
        })
    }

    // this.removeHighlighter()
    this.$emit('removeHighlighter')

    const color = COLORS[18]

    if (this.featureGeometryType === 'Point') {
        this.$map.addLayer({
            id: this.highlightSourceAndLayerId,
            type: 'circle',
            source: this.highlightSourceAndLayerId,
            paint: {
                'circle-color': color,
                'circle-radius': 15,
                'circle-opacity': highlighterOpacity,
            },
        })
    } else {
        this.$map.addLayer({
            id: this.highlightSourceAndLayerId,
            type: 'line',
            source: this.highlightSourceAndLayerId,
            paint: {
                'line-opacity': highlighterOpacity,
                'line-color': color,
                'line-width': 7.5,
            },
        })
    }

    // //=========
    // // console.log(
    // //     'HIGHLIGHT',
    // //     this.$map.queryRenderedFeatures({
    // //         layers: [
    // //             'gas_pipeline_major',
    // //             'gas_pipeline_branch',
    // //             'gas_pipeline_consumer',
    // //             'consumer',
    // //             'gas_distribution_station',
    // //         ],
    // //     })
    // //     // this.$map.queryRenderedFeatures(this.featureCoordinates)
    // // )
    // // const currentHighlightFeature = this.$map
    // //     .queryRenderedFeatures()
    // //     .filter(feature => feature.id === this.feature.id)
    // // console.log('currentHighlightFeature', currentHighlightFeature)
    // const currentHighlightSource = this.$map.getSource(
    //     this.highlightSourceAndLayerId
    // )
    // if (currentHighlightSource) {
    //     currentHighlightSource.setData({
    //         geometry: {
    //             coordinates: this.featureCoordinates,
    //             type: 'Point',
    //         },
    //         id: this.feature.id,
    //         type: 'Feature',
    //     })
    // } else {
    //     this.$map.addSource(this.highlightSourceAndLayerId, {
    //         type: 'geojson',
    //         data: {
    //             geometry: {
    //                 coordinates: this.featureCoordinates,
    //                 type: 'Point',
    //             },
    //             id: this.feature.id,
    //             type: 'Feature',
    //         },
    //     })
    // }
    //
    // // this.removeHighlighter()
    // this.$emit('removeHighlighter')
    // if (this.$map.hasImage('markerBalloon'))
    //     this.$map.removeImage('markerBalloon')
    //
    // const color = COLORS[18]
    //
    // if (this.featureGeometryType === 'Point') {
    //     // this.$map.addLayer({
    //     //     id: this.highlightSourceAndLayerId,
    //     //     type: 'circle',
    //     //     source: this.highlightSourceAndLayerId,
    //     //     paint: {
    //     //         'circle-color': color,
    //     //         'circle-radius': 15,
    //     //         'circle-opacity': highlighterOpacity,
    //     //     },
    //     // })
    //     this.$map.loadImage(
    //         // eslint-disable-next-line @typescript-eslint/no-var-requires
    //         require('../../../../../../../assets/icon-ballon.png'),
    //         (error, image) => {
    //             if (error) throw error
    //
    //             this.$map.addImage('markerBalloon', image)
    //             this.$map.addLayer({
    //                 id: this.highlightSourceAndLayerId,
    //                 type: 'symbol',
    //                 source: this.highlightSourceAndLayerId,
    //                 layout: {
    //                     'icon-image': 'markerBalloon',
    //                     'icon-size': 0.7,
    //                 },
    //                 paint: {
    //                     'icon-opacity': 0.85,
    //                 },
    //             })
    //         }
    //     )
    // } else {
    //     this.$map.addLayer({
    //         id: this.highlightSourceAndLayerId,
    //         type: 'line',
    //         source: this.highlightSourceAndLayerId,
    //         paint: {
    //             'line-opacity': highlighterOpacity,
    //             'line-color': color,
    //             'line-width': 7.5,
    //         },
    //     })
    // }
}
