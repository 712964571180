export default function (
    this: any,
    layerId,
    enterCallback,
    leaveCallback,
    moveCallback?
) {
    let hoveredFeatureId = null
    const mousemoveHandler = event => {
        const feature = event?.features?.[0]
        if (!feature) return
        const featureId = feature?.id
        if (!featureId) return
        if (featureId === hoveredFeatureId) return
        if (!event.features.length) {
            return
        }
        if (hoveredFeatureId !== null) {
            leaveCallback(hoveredFeatureId)
        }
        hoveredFeatureId = featureId
        enterCallback(hoveredFeatureId, feature)
    }
    const mouseleaveHandler = () => {
        if (hoveredFeatureId !== null) {
            leaveCallback(hoveredFeatureId)
        }
        hoveredFeatureId = null
    }

    this.map.on('mousemove', layerId, mousemoveHandler)
    this.map.on('mouseleave', layerId, mouseleaveHandler)

    const removeHandlers = () => {
        this.map.off('mousemove', layerId, mousemoveHandler)
        this.map.off('mouseleave', layerId, mouseleaveHandler)

        if (hoveredFeatureId) {
            leaveCallback(hoveredFeatureId)
        }
    }

    return removeHandlers
}
