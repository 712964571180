export default `<?xml version="1.0" encoding="utf-8"?>
    <!-- Generator: Adobe Illustrator 25.4.6, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 200 200" style="enable-background:new 0 0 200 200;" xml:space="preserve">
    <style type="text/css">
.st0{fill:#4351A2;}
.st1{fill:#4551A2;}
.st2{fill:#40B6E8;}
.st3{fill:#ED2756;}
    </style>
    <g>
    <path class="st0" d="M198.14,189.93c-3.06,0.01-6.11,0.01-9.17,0.05c-0.59,0.01-0.82-0.32-1.07-0.73
    c-1.51-2.48-3.06-4.94-4.55-7.44c-0.36-0.6-0.78-0.85-1.41-0.68c-0.63,0.17-0.33,0.73-0.34,1.11c-0.03,2.26-0.04,4.51,0,6.77
    c0.01,0.73-0.14,1.01-0.94,0.99c-2.44-0.05-4.88-0.04-7.31-0.01c-0.61,0.01-0.8-0.16-0.8-0.79c0.02-7.83,0.02-15.65,0-23.48
    c0-0.59,0.19-0.73,0.75-0.73c2.51,0.03,5.02,0.03,7.53,0c0.66-0.01,0.77,0.25,0.77,0.83c-0.03,2.26-0.02,4.51,0,6.77
    c0,0.33-0.29,0.87,0.36,0.96c0.5,0.06,0.95,0.08,1.3-0.5c1.44-2.44,2.96-4.83,4.42-7.27c0.34-0.56,0.7-0.8,1.38-0.79
    c2.84,0.04,5.67,0.02,8.52,0.02c0.03,0.46-0.27,0.7-0.45,0.99c-2.27,3.55-4.56,7.09-6.84,10.64c-0.25,0.39-0.49,0.71-0.12,1.24
    c2.58,3.78,5.11,7.6,7.67,11.4c0.07,0.11,0.14,0.26,0.32,0.22C198.14,189.64,198.14,189.78,198.14,189.93z"/>
    <path class="st0" d="M47.47,190.84c-2.28,0.06-4.5-0.24-6.63-1.05c-3.74-1.41-6.13-4.04-7.16-7.9c-0.81-3.06-0.81-6.13,0.09-9.15
    c1.23-4.12,4.03-6.74,8.14-7.92c3.6-1.03,7.26-1.06,10.87-0.09c5.47,1.46,8.72,5.55,9.08,11.3c0.17,2.68-0.04,5.31-1.18,7.79
    c-2.18,4.75-6.26,6.5-11.13,6.98c-0.29,0.03-0.58,0.04-0.87,0.04C48.27,190.84,47.87,190.84,47.47,190.84z M51.94,178.08
    c0.03-2.55-0.35-4.41-1.09-5.66c-1.61-2.69-5.21-2.76-6.63,0.01c-1.68,3.28-1.62,6.72-0.05,10.06c0.68,1.45,1.95,2.08,3.56,2.01
    c1.53-0.07,2.57-0.87,3.26-2.18C51.73,180.9,51.9,179.36,51.94,178.08z"/>
    <path class="st0" d="M65.56,177.42c0-3.82,0.02-7.64-0.02-11.45c-0.01-0.73,0.14-0.99,0.94-0.99c5.16,0.04,10.32,0.02,15.49,0.02
    c1.5,0,2.97,0.24,4.34,0.87c2.2,1,3.35,2.7,3.34,5.15c-0.02,2.44-0.82,4.41-3.23,5.42c-0.28,0.12-0.63,0.18-0.62,0.64
    c0.01,0.41,0.22,0.55,0.58,0.66c3.05,0.94,4.22,3.22,3.89,6.67c-0.26,2.7-2.26,4.71-5.28,5.31c-0.97,0.19-1.94,0.26-2.92,0.26
    c-5.16,0-10.32-0.02-15.49,0.02c-0.79,0.01-1.05-0.16-1.04-1C65.59,185.13,65.56,181.27,65.56,177.42z M74.59,182.11
    c0,0.62,0.01,1.24,0,1.85c-0.01,0.4,0.12,0.62,0.56,0.61c1.27-0.03,2.55,0.1,3.81-0.12c1.31-0.23,2-1.09,1.95-2.34
    c-0.05-1.26-0.79-2.08-2.09-2.22c-0.65-0.07-1.31-0.04-1.96-0.04C74.59,179.84,74.59,179.85,74.59,182.11z M74.59,172.37
    c0,0.36,0.04,0.73-0.01,1.09c-0.13,0.88,0.25,1.15,1.09,1.08c0.87-0.08,1.75-0.02,2.61-0.09c1.28-0.11,1.91-0.77,1.94-1.91
    c0.04-1.21-0.57-1.99-1.81-2.2c-0.57-0.09-1.16-0.06-1.74-0.06C74.59,170.27,74.59,170.27,74.59,172.37z"/>
    <path class="st1" d="M1.87,177.37c0-3.82,0.02-7.64-0.01-11.46c-0.01-0.7,0.16-0.94,0.9-0.93c2.47,0.05,4.95,0.04,7.42,0.01
    c0.62-0.01,0.81,0.18,0.8,0.8c-0.03,2.37,0.01,4.73-0.03,7.1c-0.01,0.66,0.17,0.87,0.85,0.86c2.58-0.04,5.17-0.04,7.75,0
    c0.69,0.01,0.86-0.21,0.85-0.86c-0.04-2.37,0-4.73-0.03-7.1c-0.01-0.6,0.14-0.81,0.78-0.81c2.51,0.03,5.02,0.03,7.53,0
    c0.65-0.01,0.79,0.22,0.79,0.82c-0.02,7.79-0.02,15.58,0,23.37c0,0.61-0.16,0.82-0.79,0.81c-2.51-0.03-5.02-0.03-7.53,0
    c-0.65,0.01-0.77-0.23-0.77-0.81c0.03-2.51,0-5.02,0.02-7.53c0.01-0.6-0.05-0.91-0.79-0.89c-2.62,0.05-5.24,0.04-7.86,0.01
    c-0.61-0.01-0.8,0.16-0.79,0.79c0.03,2.51-0.01,5.02,0.03,7.53c0.01,0.72-0.19,0.93-0.91,0.92c-2.44-0.04-4.88-0.05-7.31,0
    c-0.74,0.01-0.91-0.23-0.9-0.93C1.89,185.16,1.87,181.27,1.87,177.37z"/>
    <path class="st1" d="M106.6,165c1.67,0,3.35,0.03,5.02-0.01c0.63-0.02,0.95,0.16,1.17,0.8c2.7,7.78,5.43,15.54,8.18,23.31
    c0.26,0.73,0.12,0.91-0.65,0.9c-2.65-0.04-5.31-0.04-7.97,0c-0.63,0.01-0.87-0.2-1.03-0.79c-0.23-0.86-0.12-2.07-0.84-2.48
    c-0.69-0.4-1.76-0.14-2.67-0.15c-1.53-0.01-3.06,0.03-4.58-0.02c-0.66-0.02-0.98,0.18-1.13,0.83c-0.19,0.84-0.14,1.96-0.77,2.43
    c-0.67,0.51-1.75,0.14-2.66,0.15c-2.04,0.02-4.07-0.01-6.11,0.02c-0.62,0.01-0.82-0.08-0.58-0.78c2.77-7.83,5.52-15.67,8.25-23.51
    c0.2-0.56,0.47-0.71,1.02-0.7C103.03,165.01,104.82,165,106.6,165z M106.47,180.76c0.69,0,1.38-0.02,2.07,0.01
    c0.46,0.02,0.55-0.15,0.44-0.58c-0.65-2.57-1.27-5.14-1.91-7.71c-0.08-0.32-0.15-0.63-0.6-0.63c-0.49,0-0.48,0.37-0.55,0.66
    c-0.63,2.5-1.24,5-1.87,7.5c-0.13,0.52-0.1,0.82,0.57,0.77C105.23,180.71,105.85,180.76,106.47,180.76z"/>
    <path class="st1" d="M155.12,180.22c-1.38,0-2.77-0.03-4.15,0.01c-0.68,0.02-0.87-0.22-0.85-0.88c0.05-1.34,0.05-2.69,0-4.04
    c-0.03-0.71,0.18-0.93,0.9-0.91c2.73,0.04,5.46-0.01,8.18,0.03c0.8,0.01,0.84-0.24,0.54-0.87c-1-2.07-2.84-2.69-4.92-2.85
    c-2.48-0.19-4.94,0.06-7.34,0.71c-0.69,0.19-0.8,0.04-0.79-0.6c0.03-1.6,0.02-3.2,0.01-4.8c0-0.43,0.03-0.74,0.58-0.85
    c4.86-0.96,9.74-1.5,14.54,0.15c4.93,1.69,7.44,5.38,7.91,10.46c0.31,3.36-0.09,6.62-2.04,9.51c-2.07,3.07-5.15,4.5-8.69,5.05
    c-3.85,0.6-7.68,0.24-11.49-0.46c-0.6-0.11-0.84-0.35-0.81-1c0.06-1.6,0.04-3.2,0.01-4.8c-0.01-0.61,0.13-0.7,0.75-0.54
    c2.91,0.73,5.87,1.04,8.85,0.48c1.62-0.3,2.88-1.14,3.54-2.74c0.3-0.73,0.32-1.13-0.68-1.08
    C157.82,180.27,156.47,180.22,155.12,180.22z"/>
    <path class="st1" d="M132.17,165c3.82,0,7.63,0.02,11.45-0.02c0.77-0.01,1.09,0.12,1.05,0.99c-0.08,1.71-0.06,3.42-0.01,5.12
    c0.02,0.69-0.21,0.86-0.87,0.84c-1.96-0.04-3.93,0.03-5.89-0.03c-0.89-0.03-1.16,0.18-1.15,1.12c0.05,5.27-0.01,10.54,0.05,15.82
    c0.01,1.02-0.31,1.18-1.21,1.15c-2.25-0.06-4.51-0.07-6.76,0c-0.93,0.03-1.23-0.17-1.22-1.17c0.06-5.27,0.01-10.54,0.04-15.82
    c0.01-0.83-0.17-1.13-1.06-1.1c-2.07,0.07-4.14-0.01-6.22,0.04c-0.73,0.02-0.93-0.22-0.91-0.93c0.05-1.71,0.05-3.42,0-5.13
    c-0.02-0.72,0.21-0.91,0.91-0.9C124.32,165.01,128.25,165,132.17,165z"/>
    <g>
    <path class="st2" d="M83.18,36.58c1.33-1.06,2.71-1.17,4.13-0.19c0.11,0.11,0.22,0.22,0.34,0.33c0.72,1.38,0.79,2.71-0.35,3.93
    c-1.19,1.05-2.5,0.72-3.81,0.34l0.01,0.01c0.1-0.25-0.02-0.29-0.23-0.24l0.02,0.02c0.05-0.21,0-0.32-0.24-0.23l0.01,0.02
    c0.04-0.58-0.48-0.94-0.57-1.49c-0.06,0.55-0.66,0.89-0.52,1.49l0.01-0.01c-0.28-0.17-0.36,0.05-0.46,0.23l0.02-0.02
    c-0.39,0.21-0.78,0.41-1.2,0.64c0.45,0.3,0.82,0.55,1.19,0.79l0.08,0.01l0.01,0.08c0.12,0.08,0.23,0.16,0.35,0.24l-0.02-0.02
    c0.12,0.39,0.25,0.79,0.8,1.35c-0.35-0.73,0.38-0.94,0.1-1.44c0.18-0.18,0.35-0.35,0.53-0.53c0.23,0.09,0.43,0,0.6-0.13
    c1.16-0.87,2.19-0.4,3.19,0.31c0.14,0.1,0.3,0.16,0.45,0.23c0.18,0.43,0.35,0.87,0.55,1.35c0.27-0.5,0.51-0.93,0.75-1.37
    c0.14-0.03,0.21-0.11,0.17-0.26c1.11-0.56,2.23-0.87,3.43-0.28c0.4,0.4,0.8,0.8,1.2,1.2c0.57,0.97,0.44,1.93-0.09,2.86
    c-0.32,0.33-0.64,0.66-0.96,0.99c-0.29,0.13-0.59,0.26-0.94,0.42c0.37,0.15,0.67,0.27,0.96,0.39c0.29,0.28,0.57,0.55,0.86,0.83
    c0.12,0.29,0.24,0.58,0.43,1.05c0.18-0.45,0.29-0.74,0.41-1.03c0.31-0.3,0.61-0.6,0.92-0.9c0.33-0.11,0.67-0.22,1.09-0.36
    c-0.43-0.19-0.76-0.33-1.09-0.47c-0.3-0.29-0.59-0.57-0.89-0.86c-0.44-0.95-0.55-1.92-0.11-2.9c0.4-0.4,0.8-0.79,1.19-1.19
    c0.85-0.37,1.72-0.35,2.59-0.09c0.54,0.43,1.1,0.85,1.4,1.5c0.23,0.75,0.24,1.51,0,2.26c-0.34,0.63-0.83,1.11-1.42,1.49
    c-0.3-0.02-0.52,0.15-0.76,0.32c0.26,0.04,0.51,0.08,0.75,0.12c0.27,0.2,0.55,0.39,0.82,0.59c0.03,0.05,0.06,0.08,0.11,0.11
    c0.18,0.27,0.36,0.54,0.54,0.82c0.05,0.17,0.11,0.34,0.21,0.67c0.08-0.32,0.12-0.49,0.16-0.66c0.28-0.37,0.56-0.74,0.84-1.11
    c0.26-0.15,0.52-0.3,0.78-0.45c0.67-0.11,1.35-0.15,2.03-0.04c0.38,0.13,0.64,0.53,1.1,0.5l-0.01-0.01c0.18,0.57,0.55,1,0.96,1.55
    c0.45-0.96,1.1-1.58,1.96-1.98c0.5-0.01,0.99-0.02,1.49-0.04c1.02,0.39,1.73,1.08,2.1,2.11c0.01,0.4,0.02,0.8,0.03,1.2
    c-0.19,0.41-0.38,0.81-0.58,1.22c-0.17,0.17-0.35,0.35-0.52,0.52c-0.36,0.15-0.73,0.31-1.09,0.46c-0.49-0.01-0.98-0.02-1.47-0.04
    c-0.23-0.11-0.45-0.23-0.68-0.34c-0.24-0.21-0.48-0.42-0.72-0.62c-0.06-0.04-0.11-0.09-0.17-0.13l0.03,0
    c0.01-0.31-0.13-0.67-0.38-0.7c-0.4-0.04-0.4,0.41-0.52,0.7c-0.29,0.28-0.57,0.57-0.86,0.85c-0.27,0.1-0.56,0.16-0.78,0.46
    c0.27,0.13,0.53,0.26,0.78,0.38c0.3,0.31,0.6,0.61,0.9,0.92c0.12,0.22,0.08,0.62,0.4,0.61c0.25-0.01,0.23-0.39,0.34-0.6
    c0.34-0.34,0.68-0.67,1.01-1.01c0.21-0.1,0.42-0.21,0.62-0.31c0.49-0.01,0.98-0.02,1.47-0.03c0.31,0.12,0.62,0.24,0.93,0.36
    c0.33,0.33,0.66,0.66,0.99,0.99c0.1,0.29,0.17,0.59,0.44,0.81c0.1-0.28,0.2-0.55,0.29-0.82c0.31-0.31,0.63-0.63,0.94-0.94
    c0.35-0.14,0.7-0.28,1.06-0.42c0.3,0,0.6-0.01,0.9-0.01c0.55,0.12,1.05,0.36,1.51,0.68c0.1,0.09,0.21,0.19,0.31,0.28
    c0.09,0.09,0.11,0.3,0.32,0.21l-0.02-0.01c-0.01,0.5,0.29,0.9,0.43,1.35c0,0.29,0,0.57,0,0.86c-0.13,0.34-0.26,0.69-0.39,1.03
    c-0.38,0.37-0.76,0.73-1.13,1.1c-0.27,0.03-0.51,0.1-0.67,0.37c0.24,0.09,0.44,0.16,0.63,0.23c0.58,0.34,0.97,0.87,1.35,1.4
    c0.04,0.2,0.07,0.39,0.12,0.67c0.1-0.28,0.17-0.47,0.24-0.66c0.36-0.63,0.83-1.14,1.45-1.51c0.29-0.09,0.58-0.17,0.87-0.26
    c0.28,0,0.55,0.01,0.83,0.01c0.33,0.12,0.67,0.25,1,0.37c0.4,0.4,0.79,0.8,1.19,1.21c0.09,0.34,0.17,0.67,0.26,1.01
    c-0.01,0.33-0.01,0.67-0.02,1c-0.15,0.33-0.29,0.66-0.44,0.99c-0.32,0.3-0.64,0.61-0.97,0.91c-0.27,0.08-0.54,0.17-0.94,0.29
    c0.33,0.12,0.56,0.2,0.78,0.29c0.67,0.43,1.16,1.04,1.54,1.97c0.37-0.99,0.91-1.54,1.59-1.97c0.31-0.09,0.63-0.18,0.94-0.27
    c0.29,0.01,0.59,0.02,0.88,0.02c0.31,0.1,0.62,0.2,0.93,0.29c0.58,0.4,0.95,0.97,1.33,1.55c-0.01,0.33,0.03,0.65,0.2,0.94
    c-0.18,1.36-0.82,2.41-2.27,2.94c1.9,1.1,2.15,1.58,2.11,3.75c-0.39,0.99-1.11,1.66-2.23,2.08c1.25,0.33,1.88,1.12,2.32,2.24
    c0.35-1.26,1.15-1.92,2.46-2.34c-1.15-0.46-1.89-1.05-2.27-2c-0.33-1.94,0.61-3.21,2.63-3.57c0.18,0.01,0.35,0.02,0.53,0.03
    c0.22,0.07,0.45,0.14,0.67,0.21c0.58,0.32,1.04,0.77,1.34,1.37c0.07,0.23,0.15,0.47,0.22,0.7c0,0.13,0,0.26,0,0.39
    c-0.05,1.53-0.95,2.39-2.62,2.9c1.53,0.26,2.29,1.01,2.69,2.17c1.18-1.8,2.16-2.39,3.45-2.09c1.31,0.3,2.17,1.12,2.33,2.29
    c0.1,0.73,0.02,1.44-0.38,2.1c-0.39,0.64-1.01,0.96-1.72,1.32c1.32,0.62,2.17,1.47,2.14,2.95c-0.02,1.45-0.92,2.24-2.13,2.86
    c1.65,0.77,2.24,1.68,2.11,3.18c-0.09,1.16-0.9,2.15-2.04,2.42c-1.82,0.43-2.99-0.42-3.74-2.12c-0.21,0.61-0.43,1.05-0.81,1.42
    c-0.37,0.37-0.84,0.58-1.36,0.82c1.64,0.78,2.32,1.77,2.22,3.12c-0.09,1.23-0.93,2.25-2.18,2.56c-1.76,0.43-2.82-0.53-3.63-2.06
    c-0.37,1.1-1.13,1.68-2.03,2.17c1.82,1.11,2.06,1.56,2,3.7c-1.46,2.75-3.8,2.79-5.74,0.03c-0.51,1.04-1.24,1.81-2.49,2.04
    c1.82,0.76,2.55,1.7,2.43,3.06c-0.11,1.22-0.97,2.24-2.11,2.51c-1.45,0.34-2.4-0.21-3.58-2.08c-0.46,1.35-1.34,2.21-2.75,2.27
    c-1.44,0.05-2.39-0.78-3-2.19c-0.48,1.07-1.05,1.83-2.26,2.12c1.67,0.77,2.5,1.89,2.19,3.62c-0.18,1.01-1.16,1.98-2.22,2.15
    c-1.67,0.27-2.78-0.51-3.49-2.15c-0.55,1.44-1.46,2.19-2.9,2.2c-1.45,0.01-2.28-0.86-2.84-2.14c-0.29,0.48-0.45,0.94-0.83,1.24
    c-0.39,0.31-0.78,0.62-1.17,0.94c1.3,0.78,1.55,1.08,2.03,2.36c1.1-1.89,2.16-2.53,3.55-2.16c1.11,0.3,1.97,1.41,1.99,2.58
    c0.02,1.34-0.76,2.37-2.06,2.73c-0.79,0.22-1.51-0.01-2.18-0.41c-0.73-0.43-0.94-1.23-1.3-1.97c-0.28,1.27-0.87,2.16-2.1,2.5
    c-1.72,0.46-2.82-0.42-3.71-1.89c-0.71,1.26-1.61,2.09-3,2.01c-1.41-0.08-2.27-0.94-2.76-2.28c-0.95,1.83-1.92,2.41-3.45,2.15
    c-1.16-0.2-1.99-1.09-2.23-2.39c-0.13-0.72,0.08-1.36,0.45-1.96c0.39-0.62,1-0.96,1.86-1.3c-1.23-0.37-2-1.01-2.34-2.24
    c-0.74,1.63-1.81,2.45-3.44,2.18c-1.17-0.19-2-0.91-2.27-2.09c-0.41-1.77,0.51-2.89,2.12-3.7c-1.2-0.25-1.72-1.08-2.24-2.07
    c-0.52,1.26-1.4,1.94-2.66,2.27c1.77,0.44,2.62,1.49,2.51,2.98c-0.1,1.28-1.03,2.3-2.24,2.44c-1.5,0.18-2.62-0.52-3.07-1.87
    c-0.26-0.79-0.1-1.49,0.27-2.2c0.39-0.75,1.12-1.04,2.04-1.38c-1.6-0.5-2.58-1.38-2.6-2.91c-0.02-1.45,0.79-2.42,2.18-2.91
    c-1.55-0.89-1.61-0.96-2.17-2.33c-0.87,1.76-1.75,2.36-3.23,2.22c-1.21-0.12-2.09-0.91-2.39-2.19c-0.34-1.39,0.17-2.3,2.05-3.54
    c-1.46-0.47-2.2-1.48-2.19-2.93c0.02-1.45,0.93-2.29,2.26-2.81c-1.32-0.47-2.19-1.29-2.26-2.69c-0.08-1.48,0.73-2.43,2.18-3.06
    c-1.11-0.39-1.72-1.16-2.25-2.23c-0.64,1.63-1.7,2.3-3.17,2.16c-1.19-0.11-2.04-0.84-2.35-1.99c-0.49-1.79,0.42-2.95,2.06-3.72
    c-1.27-0.5-2.08-1.2-2.19-2.49c-0.15-1.6,0.61-2.64,2.18-3.26c-1.4-0.55-2.14-1.4-2.19-2.83c-0.05-1.49,0.86-2.27,2.07-2.97
    c-1.34-0.61-2.09-1.46-2.07-2.91c0.02-1.47,0.89-2.25,2.19-2.85c-1.16-0.35-1.77-1.09-2.17-2.03c0-0.52,0-1.04,0-1.57
    c0.4-0.97,0.98-1.78,2.32-2.08c-1.22-0.46-1.89-1.13-2.31-2.03c-0.01-0.71-0.13-1.43,0.17-2.12c0.4-0.81,1.13-1.26,2-1.7
    c-0.98-0.25-1.54-0.85-2-1.55c-0.34-1.02-0.28-2.01,0.25-2.96c0.24-0.23,0.49-0.46,0.73-0.7c0.33-0.16,0.66-0.32,1.02-0.5
    c-0.36-0.17-0.68-0.32-1-0.47c-0.26-0.25-0.52-0.5-0.78-0.76c-0.74-1.24-0.36-2.45,0.06-3.67l-0.02,0.01
    c0.24,0.08,0.31,0,0.24-0.23l-0.02,0.02c0.24,0.08,0.32,0,0.23-0.24l-0.01,0.01c0.47,0.16,0.7-0.29,1.09-0.42
    c-0.49-0.16-0.74-0.71-1.31-0.65l0.01,0.01c0.01-0.63-0.59-1-0.68-1.61c-0.23-1.59,0.32-2.58,2.08-3.53
    c-1.68-0.87-2.23-1.73-2.08-3.27c0.11-1.14,0.91-2.08,2.08-2.37c1.73-0.43,2.83,0.43,3.6,1.89c1.23-1.77,2.24-2.28,3.66-1.89
    c1.22,0.33,2,1.25,2.11,2.48c0.13,1.45-0.5,2.39-2.13,3.15c1.4,0.79,1.48,0.88,2.19,2.22c0.51-1.38,1.43-2.21,2.93-2.18
    c1.39,0.03,2.28,0.79,2.78,2.2c0.7-1.59,1.79-2.42,3.53-2.16c0.6,0.09,0.99,0.59,1.57,0.68l-0.01-0.01
    c0.25,0.53,0.5,1.06,0.81,1.73c0.06-0.68,0.42-1.06,0.6-1.53L83.18,36.58z M75.85,40.79l0.02-0.02c-0.16,0-0.26,0.06-0.23,0.24
    l0.02-0.02c-0.43,0-0.75,0.22-1.21,0.46c0.51,0.16,0.8,0.44,1.21,0.42l-0.02-0.02c0.06,0.12,0.13,0.25,0.19,0.37
    c0.12,0.1,0.23,0.19,0.35,0.29c0.04,0.1,0.08,0.2,0.12,0.3c0.11,0.29,0.23,0.57,0.36,0.91c0.21-0.43,0.39-0.82,0.57-1.21
    c0.19-0.18,0.38-0.35,0.58-0.53c0.35-0.18,0.71-0.37,1.17-0.61c-0.49-0.16-0.83-0.27-1.17-0.39l0.02,0.02
    c-0.03-0.18-0.19-0.21-0.32-0.25c-0.04-0.02-0.08-0.05-0.1-0.09c-0.23-0.47-0.46-0.94-0.72-1.49c-0.23,0.53-0.42,0.97-0.61,1.4
    l0.02-0.02C75.94,40.55,75.86,40.63,75.85,40.79z M65.58,42.53c0.1-0.07,0.19-0.15,0.29-0.22c0.06-0.07,0.12-0.13,0.18-0.2
    c0.07-0.09,0.14-0.17,0.21-0.26l-0.02,0.02c0.41-0.04,0.77-0.18,1.13-0.48c-0.51-0.24-0.93-0.43-1.35-0.62l0.02,0.02
    c-0.01-0.15-0.09-0.23-0.24-0.24l0.02,0.02c-0.19-0.4-0.39-0.81-0.64-1.33c-0.27,0.52-0.47,0.93-0.68,1.33l0.02-0.02
    c-0.16,0-0.23,0.08-0.24,0.24l0.02-0.02c-0.4,0.2-0.8,0.4-1.34,0.67c0.57,0.26,1.01,0.45,1.44,0.65c0.04,0.03,0.07,0.06,0.09,0.1
    c0.21,0.44,0.42,0.88,0.68,1.42c0.21-0.45,0.46-0.72,0.43-1.1L65.58,42.53z M69.96,40.79l0.02-0.02
    c-0.38,0.22-0.76,0.44-1.31,0.75c0.64,0.12,0.98,0.39,1.34,0.61c0.1,0.11,0.21,0.23,0.31,0.34c0.21,0.38,0.42,0.76,0.65,1.17
    c0.18-0.41,0.34-0.77,0.51-1.13c0.16-0.16,0.32-0.32,0.48-0.48c0.36-0.19,0.73-0.37,1.13-0.58c-0.38-0.36-0.92-0.37-1.17-0.79
    l-0.09-0.01l-0.01-0.09c-0.49-0.3-0.58-0.88-0.86-1.31c-0.27,0.46-0.53,0.89-0.78,1.31l0.02-0.02
    C70.12,40.63,70.04,40.71,69.96,40.79z M89.71,47.49c0.25-0.09,0.49-0.17,0.85-0.29c-0.35-0.12-0.59-0.19-0.82-0.27
    c-0.41-0.41-0.83-0.82-1.24-1.24c-0.09-0.25-0.17-0.5-0.28-0.81c-0.12,0.33-0.21,0.59-0.3,0.84c-0.29,0.48-0.7,0.84-1.14,1.18
    c-0.27,0.02-0.51,0.12-0.72,0.35c0.26,0.09,0.5,0.18,0.73,0.26c0.38,0.38,0.75,0.76,1.13,1.14c0.09,0.24,0.18,0.48,0.32,0.85
    c0.13-0.38,0.22-0.61,0.3-0.85C88.86,48.2,89.27,47.82,89.71,47.49z M66.7,46.91c-0.43-0.41-0.86-0.82-1.29-1.22
    c-0.06-0.19-0.13-0.56-0.18-0.55c-0.35,0.02-0.23,0.36-0.31,0.56c-0.39,0.4-0.79,0.81-1.18,1.21c-0.26,0.09-0.52,0.18-0.88,0.3
    c0.38,0.13,0.65,0.22,0.92,0.31c0.37,0.38,0.74,0.75,1.11,1.13c0.09,0.2,0,0.55,0.34,0.52c0.09-0.01,0.15-0.34,0.23-0.52
    c0.42-0.39,0.84-0.78,1.26-1.17c0.27-0.09,0.54-0.19,0.8-0.28C67.24,47.11,66.97,47.01,66.7,46.91z M72.58,47.41
    c0.17-0.05,0.33-0.1,0.5-0.15c-0.07-0.24-0.3-0.24-0.49-0.31c-0.62-0.31-1.06-0.8-1.42-1.38c-0.06-0.19-0.11-0.38-0.22-0.73
    c-0.12,0.34-0.19,0.54-0.25,0.74c-0.31,0.56-0.76,0.98-1.28,1.35c-0.24,0.09-0.48,0.18-0.78,0.29c0.33,0.12,0.56,0.2,0.79,0.28
    c0.5,0.3,0.9,0.7,1.21,1.19c0.09,0.23,0.18,0.46,0.3,0.8c0.12-0.33,0.2-0.57,0.29-0.8C71.58,48.16,72.03,47.73,72.58,47.41z
    M80.92,46.93c-0.21,0.03-0.51,0.08-0.51,0.27c-0.01,0.22,0.32,0.15,0.49,0.24c0.53,0.32,0.93,0.78,1.31,1.25
    c0.02,0.28,0.13,0.52,0.33,0.75c0.08-0.26,0.15-0.5,0.22-0.74c0.39-0.49,0.79-0.98,1.37-1.27c0.22-0.07,0.43-0.14,0.68-0.22
    c-0.27-0.1-0.49-0.19-0.71-0.27c-0.63-0.29-1.01-0.84-1.42-1.36c-0.04-0.2-0.08-0.4-0.16-0.82c-0.15,0.45-0.22,0.64-0.28,0.83
    C81.89,46.13,81.46,46.58,80.92,46.93z M118.7,64.72c0.19-0.08,0.38-0.16,0.64-0.27c-0.34-0.11-0.57-0.18-0.8-0.25
    c-0.34-0.31-0.68-0.62-1.02-0.93c-0.17-0.35-0.34-0.71-0.49-1.01c-0.16,0.37-0.34,0.76-0.51,1.15c-0.48,0.43-0.98,0.85-1.75,1.07
    c1.19,0.37,1.86,1.12,2.27,2.23C117.42,65.84,117.88,65.12,118.7,64.72z M109.82,58.46c-0.23,0.03-0.59,0.03-0.59,0.28
    c0,0.25,0.39,0.15,0.58,0.27c0.69,0.47,1.13,1.15,1.55,2c0.19-1.04,0.9-1.52,1.5-2.09c0.21-0.04,0.42-0.09,0.63-0.13
    c-0.19-0.15-0.37-0.3-0.64-0.29c-0.47-0.35-0.9-0.74-1.25-1.22c-0.09-0.23-0.17-0.47-0.32-0.86c-0.12,0.4-0.19,0.63-0.27,0.86
    C110.62,57.67,110.22,58.07,109.82,58.46z M76.44,48.72c0.07,0.19,0.16,0.56,0.21,0.55c0.32-0.04,0.25-0.35,0.3-0.58
    c0.44-0.43,0.89-0.85,1.33-1.28c0.19-0.05,0.39-0.1,0.75-0.2c-0.36-0.14-0.56-0.21-0.75-0.28c-0.54-0.36-0.98-0.82-1.36-1.35
    c0-0.23-0.06-0.54-0.26-0.53c-0.2,0.01-0.13,0.34-0.17,0.53c-0.38,0.51-0.81,0.96-1.32,1.33c-0.26,0.09-0.52,0.19-0.81,0.29
    c0.28,0.09,0.54,0.18,0.8,0.27C75.67,47.82,76.07,48.25,76.44,48.72z M99.89,51.2c-0.03-0.13-0.06-0.27-0.09-0.4
    c-0.22,0.07-0.1,0.28-0.17,0.4c-0.53,0.73-1.06,1.46-2.23,1.7c1.24,0.49,1.99,1.21,2.35,2.35c0.14-0.31,0.18-0.63,0.36-0.76
    C101.87,53.26,101.03,52.22,99.89,51.2z M117.11,107.22c0.35-1.31,1.28-1.95,2.6-2.39c-1.48-0.18-2.12-1.11-2.72-2.19
    c-0.34,1.18-1.12,1.79-2.22,2.21C116.01,105.28,116.78,106.01,117.11,107.22z M65.15,84.31c0.35-1.42,1.16-2.12,2.47-2.59
    c-1.36-0.26-1.93-1.12-2.54-2.14c-0.33,1.27-1.2,1.79-2.22,2.22C64.36,82.52,64.61,82.79,65.15,84.31z M91.6,64.5
    c1.3,0.28,1.91,1.16,2.47,2.27c0.31-1.28,1.19-1.82,2.24-2.32c-1.27-0.3-1.85-1.19-2.37-2.29C93.56,63.41,92.81,64.09,91.6,64.5z
    M105.53,66.93c0.44-1.41,1.21-2.06,2.31-2.47c-1.19-0.35-1.86-1.1-2.35-2.32c-0.35,1.28-1.1,1.95-2.28,2.35
    C104.37,64.93,105.14,65.53,105.53,66.93z M68.58,76.06c1.33,0.33,1.9,1.16,2.41,2.23c0.37-1.21,1.11-1.86,2.22-2.25
    c-1.11-0.45-1.87-1.12-2.27-2.28C70.5,74.88,69.8,75.58,68.58,76.06z M80.2,70.25c1.24,0.43,1.87,1.23,2.34,2.4
    c0.23-1.32,1.14-1.85,2.08-2.46c-1.12-0.42-1.76-1.13-2.09-2.17C81.6,69.59,81.57,69.63,80.2,70.25z M125.16,81.73
    c-1.22-0.3-1.86-1.09-2.38-2.14c-0.42,1.16-1.14,1.81-2.26,2.16c1.09,0.45,1.91,1.06,2.31,2.49
    C123.19,82.8,124.02,82.19,125.16,81.73z M76.71,83.9c0.4-1.13,1.27-1.7,2.35-2.2c-1.35-0.22-1.88-1.16-2.42-2.21
    c-0.34,1.21-1.07,1.87-2.21,2.27C75.52,82.21,76.29,82.81,76.71,83.9z M105.48,79.34c-0.34,1.37-1.1,2.02-2.25,2.43
    c1.13,0.42,1.85,1.11,2.34,2.42c0.32-1.38,1.19-1.91,2.16-2.45C106.58,81.37,105.94,80.63,105.48,79.34z M94.01,56.63
    c-0.43,1.11-1.27,1.68-2.39,2.18c1.28,0.28,1.84,1.1,2.37,2.18c0.45-1.18,1.11-1.91,2.36-2.21C95.18,58.3,94.39,57.71,94.01,56.63
    z M109.27,99.1c1.1,0.4,1.59,1.29,2.09,2.3c0.25-1.22,1.12-1.79,2.09-2.4c-1.12-0.41-1.8-1.08-2.12-2.26
    C110.89,97.85,110.3,98.6,109.27,99.1z M88.21,101.45c0.42-1.16,1.06-1.9,2.33-2.42c-1.36-0.39-1.89-1.24-2.37-2.34
    c-0.24,1.28-1.13,1.84-2.06,2.43C87.17,99.58,87.87,100.23,88.21,101.45z M70.92,96.63c-0.3,1.36-1.15,1.96-2.25,2.53
    c1.29,0.4,1.82,1.26,2.31,2.35c0.21-1.32,1.13-1.86,2.07-2.47C72.01,98.57,71.3,97.92,70.92,96.63z M136.78,81.72
    c-1.42-0.26-2.01-1.13-2.48-2.26c-0.35,1.25-1.12,1.88-2.24,2.33c1.17,0.38,1.83,1.13,2.31,2.26
    C134.79,82.82,135.55,82.18,136.78,81.72z M86.01,70.27c1.16,0.49,1.88,1.16,2.22,2.35c0.32-1.21,1.11-1.86,2.24-2.42
    c-1.29-0.4-1.8-1.25-2.29-2.2C87.84,69.11,87.15,69.76,86.01,70.27z M111.36,72.6c0.25-1.25,1.11-1.82,2.09-2.41
    c-1.15-0.4-1.78-1.12-2.14-2.29c-0.42,1.19-1.08,1.95-2.42,2.37C110.29,70.64,110.87,71.5,111.36,72.6z M70.95,56.31
    c-0.34,1.29-1.17,1.94-2.3,2.49c1.31,0.26,1.83,1.16,2.33,2.23c0.35-1.19,1.07-1.89,2.25-2.31
    C72.02,58.28,71.29,57.57,70.95,56.31z M105.5,78.37c0.46-1.24,1.15-1.99,2.43-2.36c-1.34-0.34-1.92-1.2-2.48-2.29
    c-0.27,1.32-1.16,1.85-2.19,2.33C104.39,76.42,105.11,77.08,105.5,78.37z M117.01,67.82c-0.29,1.34-1.13,1.89-2.11,2.44
    c1.09,0.49,1.8,1.17,2.13,2.33c0.41-1.11,1.06-1.88,2.3-2.36C118.04,69.77,117.43,69.04,117.01,67.82z M82.52,89.84
    c0.33-1.19,1.07-1.85,2.19-2.26c-1.1-0.48-1.9-1.13-2.24-2.52c-0.33,1.39-1.16,2-2.26,2.52C81.43,87.97,82.06,88.76,82.52,89.84z
    M102.08,99.02c-1.28-0.35-1.92-1.12-2.37-2.38c-0.21,1.33-1.09,1.9-2.1,2.48c1.12,0.47,1.82,1.15,2.16,2.33
    C100.13,100.2,100.89,99.53,102.08,99.02z M84.76,99.06c-1.25-0.41-1.86-1.18-2.32-2.35c-0.31,1.25-1.11,1.84-2.09,2.43
    c1.16,0.41,1.71,1.23,2.17,2.23C82.84,100.21,83.55,99.54,84.76,99.06z M74.37,58.81c1.25,0.24,1.81,1.06,2.35,2.11
    c0.44-1.08,1.09-1.79,2.26-2.17c-1.17-0.46-1.84-1.17-2.32-2.32C76.31,57.72,75.47,58.3,74.37,58.81z M111.32,102.37
    c-0.39,1.34-1.18,2.06-2.41,2.53c1.33,0.24,1.9,1.15,2.44,2.15c0.53-1.29,0.83-1.58,2.18-2.2
    C112.45,104.4,111.61,103.76,111.32,102.37z M65.23,56.44c-0.51,1.18-1.22,1.9-2.42,2.31c1.23,0.33,1.88,1.07,2.35,2.17
    c0.49-1.09,1.16-1.8,2.34-2.17C66.34,58.3,65.58,57.69,65.23,56.44z M120.41,76.04c1.29,0.33,1.9,1.12,2.43,2.14
    c0.49-1.09,1.14-1.82,2.4-2.16c-1.29-0.3-1.89-1.14-2.49-2.16C122.32,75.02,121.6,75.65,120.41,76.04z M74.53,52.95
    c1.1,0.36,1.7,1.18,2.26,2.36c0.28-1.41,1.23-1.89,2.23-2.45c-1.27-0.18-1.76-1.11-2.36-1.99C76.18,51.86,75.57,52.61,74.53,52.95
    z M68.66,70.27c1.28,0.48,1.91,1.21,2.32,2.34c0.3-1.25,1.1-1.84,2.2-2.39c-1.21-0.44-1.86-1.16-2.24-2.34
    C70.6,69.07,69.9,69.76,68.66,70.27z M99.75,107.15c0.44-1.13,1.05-1.95,2.38-2.3c-1.27-0.4-1.94-1.16-2.38-2.3
    c-0.4,1.18-1.2,1.81-2.21,2.34C98.71,105.26,99.36,106,99.75,107.15z M74.4,76.07c1.24,0.31,1.87,1.09,2.3,2.22
    c0.47-1.17,1.13-1.95,2.42-2.25c-1.3-0.33-1.92-1.2-2.49-2.32C76.33,75.01,75.51,75.57,74.4,76.07z M93.97,91.05
    c-0.45,1.26-1.19,1.88-2.47,2.32c1.41,0.19,1.93,1.11,2.55,2.07c0.43-1.12,1.17-1.75,2.3-2.16
    C95.14,92.97,94.51,92.22,93.97,91.05z M103.23,93.29c1.14,0.45,1.91,1.08,2.26,2.36c0.49-1.23,1.19-1.92,2.28-2.36
    c-1.19-0.37-1.8-1.19-2.31-2.24C105.12,92.24,104.4,92.9,103.23,93.29z M107.76,110.57c-1.23-0.22-1.73-1.21-2.32-2.22
    c-0.3,1.2-1.08,1.82-2.24,2.27c1.19,0.37,1.93,1.04,2.29,2.31C106,111.7,106.67,110.98,107.76,110.57z M65.22,66.79
    c0.36-1.26,1.16-1.89,2.27-2.34c-1.19-0.28-1.78-1.12-2.36-2.11c-0.45,1.12-1.14,1.8-2.32,2.13
    C64.05,64.83,64.7,65.62,65.22,66.79z M70.94,90.91c-0.38,1.32-1.15,1.97-2.28,2.4c1.17,0.41,1.87,1.13,2.34,2.36
    c0.29-1.34,1.13-1.91,2.2-2.4C72.04,92.89,71.34,92.2,70.94,90.91z M102.03,58.77c-1.09-0.47-1.85-1.14-2.29-2.36
    c-0.35,1.26-1.21,1.83-2.27,2.4c1.3,0.25,1.82,1.15,2.32,2.14C100.19,59.85,100.84,59.12,102.03,58.77z M76.64,62.23
    c-0.35,1.23-1.12,1.84-2.22,2.27c1.14,0.41,1.91,1.04,2.31,2.42c0.35-1.37,1.18-1.94,2.16-2.48
    C77.77,64.04,77.17,63.3,76.64,62.23z M93.94,78.11c1-1.47,1.11-1.57,2.34-2.09c-1.08-0.36-1.79-1.09-2.36-2.22
    c-0.39,1.25-1.18,1.83-2.22,2.26C92.85,76.38,93.53,77.08,93.94,78.11z M90.62,110.55c-1.32-0.26-1.94-1.12-2.43-2.26
    c-0.31,1.22-1.07,1.87-2.26,2.32c1.22,0.37,1.87,1.13,2.35,2.32C88.65,111.6,89.49,110.99,90.62,110.55z M93.98,102.59
    c-0.48,1.26-1.22,1.88-2.29,2.3c1.19,0.32,1.83,1.05,2.31,2.15c0.52-1.1,1.14-1.86,2.44-2.19
    C95.21,104.5,94.5,103.81,93.98,102.59z M134.31,89.88c0.45-1.19,1.1-1.92,2.43-2.35c-1.32-0.35-1.91-1.16-2.43-2.24
    c-0.36,1.24-1.18,1.84-2.26,2.32C133.25,87.94,133.92,88.65,134.31,89.88z M128.59,85.25c-0.43,1.19-1.16,1.85-2.32,2.32
    c1.26,0.36,1.9,1.15,2.36,2.27c0.32-1.24,1.1-1.86,2.22-2.3C129.73,87.1,129.01,86.44,128.59,85.25z M113.65,87.52
    c-1.31-0.36-1.98-1.12-2.37-2.35c-0.34,1.29-1.16,1.9-2.24,2.44c1.25,0.31,1.8,1.18,2.3,2.16
    C111.85,88.51,112.23,88.13,113.65,87.52z M76.67,90.96c-0.38,1.3-1.12,1.96-2.28,2.35c1.2,0.38,1.88,1.11,2.32,2.3
    c0.42-1.24,1.17-1.91,2.34-2.31C77.87,92.95,77.16,92.23,76.67,90.96z M99.76,84.04c0.43-1.14,1.18-1.83,2.28-2.31
    c-1.24-0.33-1.85-1.12-2.28-2.27c-0.41,1.14-1.02,1.94-2.29,2.26C98.62,82.23,99.35,82.9,99.76,84.04z M68.59,87.56
    c1.3,0.39,1.96,1.15,2.36,2.29c0.36-1.18,1.08-1.87,2.26-2.3c-1.16-0.47-1.9-1.16-2.26-2.34C70.57,86.35,69.89,87.1,68.59,87.56z
    M94.07,112.99c0.32-1.44,1.18-1.97,2.28-2.44c-1.23-0.3-1.88-1.06-2.37-2.13c-0.48,1.11-1.1,1.86-2.48,2.16
    C92.83,110.95,93.54,111.68,94.07,112.99z M105.52,107.06c0.5-1.12,1.14-1.82,2.24-2.18c-1.11-0.45-1.82-1.18-2.33-2.41
    c-0.24,1.43-1.19,1.9-2.18,2.43C104.4,105.21,105.03,105.92,105.52,107.06z M99.82,95.78c0.29-1.47,1.15-2.02,2.22-2.53
    c-1.23-0.33-1.84-1.12-2.31-2.25c-0.34,1.24-1.12,1.87-2.23,2.33C98.68,93.72,99.36,94.43,99.82,95.78z M97.41,87.56
    c1.3,0.4,1.97,1.15,2.37,2.29c0.35-1.18,1.08-1.87,2.28-2.29c-1.17-0.41-1.85-1.15-2.31-2.22C99.31,86.41,98.66,87.11,97.41,87.56
    z M99.75,78.32c0.42-1.17,1.11-1.9,2.28-2.29c-1.13-0.44-1.88-1.11-2.25-2.31c-0.43,1.16-1.16,1.87-2.31,2.31
    C98.63,76.44,99.37,77.12,99.75,78.32z M79.09,87.58c-1.33-0.45-1.92-1.25-2.45-2.29c-0.32,1.25-1.19,1.8-2.19,2.33
    c1.15,0.3,1.75,1.04,2.23,2.07C77.17,88.7,77.76,87.93,79.09,87.58z M82.44,50.74c-0.43,1.19-1.16,1.79-2.24,2.22
    c1.26,0.4,1.86,1.24,2.33,2.37c0.24-1.34,1.14-1.92,2.21-2.46C83.56,52.61,82.99,51.83,82.44,50.74z M99.75,67.84
    c-0.31,1.27-1.08,1.9-2.17,2.42c1.12,0.49,1.82,1.19,2.22,2.33c0.31-1.22,1.1-1.82,2.1-2.37C100.82,69.74,100.1,69.07,99.75,67.84
    z M117.05,79.47c-0.39,1.17-1.08,1.9-2.24,2.26c1.05,0.48,1.8,1.15,2.24,2.27c0.43-1.14,1.17-1.79,2.32-2.27
    C118.07,81.41,117.47,80.59,117.05,79.47z M91.59,99.12c1.39,0.31,1.91,1.19,2.51,2.17c0.28-1.21,1.09-1.73,2.14-2.24
    c-1.15-0.4-1.81-1.11-2.27-2.27C93.58,97.98,92.88,98.62,91.59,99.12z M109.05,76.06c1.17,0.34,1.76,1.19,2.31,2.2
    c0.31-1.2,1.09-1.81,2.26-2.27c-1.22-0.37-1.95-1.05-2.35-2.41C110.89,74.97,110.09,75.56,109.05,76.06z M76.71,107.05
    c0.46-1.15,1.15-1.87,2.36-2.21c-1.23-0.34-1.85-1.12-2.38-2.2c-0.46,1.18-1.22,1.8-2.3,2.21
    C75.56,105.19,76.22,105.93,76.71,107.05z M82.51,84.16c0.32-1.39,1.21-1.92,2.17-2.44c-1.28-0.52-1.62-0.87-2.19-2.18
    c-0.44,1.03-1.06,1.84-2.41,2.22C81.34,82.19,82.04,82.88,82.51,84.16z M88.21,84.1c0.42-1.24,1.23-1.85,2.26-2.39
    c-1.21-0.34-1.82-1.11-2.25-2.26c-0.36,1.22-1.07,1.92-2.29,2.3C87.03,82.18,87.8,82.8,88.21,84.1z M97.58,110.59
    c1.06,0.46,1.83,1.17,2.22,2.45c0.25-1.37,1.19-1.92,2.19-2.52c-1.22-0.28-1.79-1.12-2.22-2.14
    C99.02,109.83,98.88,109.97,97.58,110.59z M128.63,95.74c0.3-1.41,1.17-1.97,2.24-2.49c-1.23-0.32-1.85-1.12-2.32-2.26
    c-0.33,1.27-1.13,1.88-2.23,2.34C127.49,93.72,128.17,94.44,128.63,95.74z M125.17,93.29c-1.22-0.34-1.86-1.09-2.34-2.16
    c-0.53,1.11-1.2,1.83-2.41,2.17c1.27,0.29,1.86,1.15,2.44,2.13C123.33,94.35,124,93.67,125.17,93.29z M62.89,76.04
    c1.2,0.4,1.83,1.1,2.28,2.17c0.51-1.1,1.17-1.85,2.48-2.21c-1.31-0.33-1.98-1.05-2.5-2.27C64.72,74.95,64.04,75.6,62.89,76.04z
    M88.21,55.23c0.41-1.16,1.22-1.8,2.27-2.31c-0.93-0.29-1.55-0.75-1.92-1.51c-0.23-0.48-0.46-0.38-0.66,0.01
    c-0.39,0.75-1.05,1.18-1.95,1.54C87.1,53.4,87.81,54.07,88.21,55.23z M93.97,85.37c-0.3,1.15-1.13,1.7-2.26,2.22
    c1.24,0.32,1.82,1.11,2.31,2.18c0.45-1.16,1.1-1.88,2.32-2.16C95.2,87.13,94.39,86.52,93.97,85.37z M90.4,87.56
    c-1.11-0.36-1.67-1.22-2.24-2.2c-0.37,1.18-1.15,1.79-2.21,2.26c1.23,0.32,1.88,1.08,2.28,2.22
    C88.65,88.74,89.24,87.92,90.4,87.56z M128.58,79.53c-0.41,1.06-1.05,1.86-2.34,2.25c1.27,0.42,1.9,1.18,2.41,2.34
    c0.24-1.34,1.17-1.86,2.11-2.39C129.5,81.18,129.17,80.85,128.58,79.53z M108.94,81.83c1.31,0.32,1.88,1.14,2.39,2.22
    c0.35-1.24,1.18-1.84,2.24-2.32c-1.21-0.31-1.84-1.09-2.24-2.13C110.45,81.1,110.41,81.14,108.94,81.83z M119.34,76.04
    c-1.16-0.43-1.85-1.19-2.33-2.29c-0.36,1.2-1.12,1.86-2.27,2.3c1.23,0.33,1.9,1.08,2.3,2.24
    C117.46,77.16,118.14,76.42,119.34,76.04z M94,72.35c0.38-1.05,1.17-1.63,2.3-2.15c-1.27-0.33-1.84-1.12-2.34-2.23
    c-0.36,1.23-1.09,1.87-2.25,2.28C92.87,70.69,93.58,71.3,94,72.35z M70.93,62.12c-0.38,1.3-1.16,1.92-2.37,2.44
    c1.38,0.27,1.92,1.13,2.46,2.26c0.27-1.33,1.12-1.88,2.16-2.37C72.02,64.08,71.37,63.35,70.93,62.12z M99.75,62.18
    c-0.4,1.18-1.08,1.91-2.37,2.34c1.33,0.32,1.91,1.17,2.45,2.28c0.29-1.3,1.12-1.89,2.23-2.36
    C100.86,64.09,100.16,63.37,99.75,62.18z M82.49,56.43c-0.43,1.19-1.18,1.87-2.26,2.34c1.21,0.38,1.83,1.15,2.25,2.25
    c0.39-1.17,1.07-1.89,2.24-2.25C83.67,58.31,82.86,57.69,82.49,56.43z M108.97,64.46c1.22,0.45,1.93,1.16,2.37,2.43
    c0.28-1.42,1.2-1.92,2.18-2.44c-1.14-0.38-1.83-1.07-2.19-2.21C110.86,63.27,110.25,64.06,108.97,64.46z M113.6,93.3
    c-1.21-0.39-1.88-1.11-2.3-2.22c-0.46,1.11-1.16,1.8-2.26,2.23c1.14,0.4,1.82,1.16,2.3,2.27C111.66,94.34,112.46,93.76,113.6,93.3
    z M122.77,96.97c-0.26,1.16-1.23,1.58-2.21,2.19c1.23,0.29,1.8,1.1,2.3,2.2c0.34-1.23,1.13-1.82,2.16-2.32
    C123.91,98.64,123.24,97.97,122.77,96.97z M105.53,56.59c-0.51,1.09-1.18,1.71-2.21,2.16c1.12,0.45,1.83,1.13,2.2,2.48
    c0.31-1.39,1.13-1.95,2.18-2.6C106.58,58.3,106.05,57.6,105.53,56.59z M94,114.21c-0.49,1.13-1.22,1.78-2.35,2.2
    c1.26,0.28,1.87,1.08,2.36,2.15c0.44-1.08,1.13-1.73,2.18-2.17C95.18,115.92,94.45,115.32,94,114.21z M80.09,110.6
    c1.34,0.42,1.93,1.22,2.43,2.26c0.33-1.23,1.15-1.83,2.25-2.31c-1.24-0.33-1.86-1.1-2.29-2.2
    C82.04,109.45,81.41,110.21,80.09,110.6z M122.82,89.7c0.5-1.03,1.13-1.78,2.5-2.2c-1.41-0.25-1.97-1.09-2.55-2.06
    c-0.42,1.09-1.17,1.67-2.22,2.15C121.76,87.95,122.33,88.72,122.82,89.7z M114.84,99.08c1.17,0.48,1.86,1.18,2.19,2.35
    c0.34-1.18,1.12-1.84,2.18-2.4c-1.17-0.43-1.76-1.21-2.19-2.23C116.66,97.89,116,98.58,114.84,99.08z M80.15,104.87
    c1.27,0.38,1.91,1.13,2.35,2.23c0.4-1.16,1.09-1.88,2.27-2.24c-1.13-0.42-1.89-1.09-2.27-2.32
    C82.04,103.69,81.36,104.41,80.15,104.87z M117.01,85.22c-0.35,1.29-1.15,1.88-2.2,2.37c1.15,0.39,1.87,1.07,2.21,2.26
    c0.46-1.08,1.03-1.94,2.35-2.25C118.22,87.11,117.49,86.45,117.01,85.22z M76.67,101.43c0.4-1.16,1.08-1.86,2.17-2.37
    c-1.15-0.47-1.75-1.26-2.22-2.32c-0.2,1.3-1.14,1.79-2.03,2.38C75.94,99.79,76.17,100.04,76.67,101.43z M88.17,73.63
    c-0.3,1.37-1.14,1.96-2.22,2.42c1.2,0.34,1.87,1.09,2.27,2.25c0.4-1.17,1.12-1.87,2.31-2.29C89.35,75.63,88.68,74.9,88.17,73.63z
    M70.98,84.17c0.32-1.37,1.19-1.92,2.16-2.47c-1.17-0.34-1.81-1.1-2.22-2.23c-0.36,1.21-1.09,1.88-2.28,2.28
    C69.78,82.21,70.51,82.86,70.98,84.17z M94.04,84.18c0.31-1.42,1.2-1.96,2.26-2.48c-1.21-0.25-1.84-1-2.31-2.14
    c-0.46,1.11-1.11,1.82-2.35,2.27C92.91,82.18,93.54,82.88,94.04,84.18z M88.2,102.57c-0.36,1.19-1.13,1.86-2.22,2.28
    c1.11,0.42,1.89,1.06,2.23,2.34c0.43-1.22,1.12-1.94,2.3-2.35C89.3,104.41,88.65,103.66,88.2,102.57z M70.91,50.88
    c-0.47,0.95-1.05,1.75-2.34,2.04c1.27,0.48,1.99,1.21,2.42,2.43c0.27-1.35,1.16-1.92,2.14-2.43
    C71.93,52.39,71.77,52.24,70.91,50.88z M82.51,78.31c0.36-1.22,1.1-1.89,2.32-2.29c-1.2-0.37-1.92-1.07-2.33-2.25
    c-0.43,1.16-1.2,1.81-2.33,2.33C81.45,76.38,82.04,77.19,82.51,78.31z M88.2,90.98c-0.36,1.28-1.12,1.93-2.35,2.36
    c1.28,0.33,1.94,1.09,2.41,2.32c0.33-1.29,1.15-1.89,2.27-2.42C89.28,92.94,88.67,92.16,88.2,90.98z M88.21,56.43
    c-0.38,1.23-1.22,1.82-2.19,2.38c1.13,0.31,1.75,1.03,2.19,2.06c0.4-1.04,1.14-1.67,2.16-2.17
    C89.31,58.22,88.66,57.59,88.21,56.43z M96.34,52.89c-1.21-0.24-1.79-1.05-2.4-1.97c-0.49,1.03-1.16,1.62-2.17,2.07
    c1.17,0.4,1.78,1.17,2.29,2.27C94.4,53.93,95.23,53.38,96.34,52.89z M82.5,91.03c-0.42,1.14-1.13,1.85-2.29,2.28
    c1.17,0.4,1.81,1.16,2.31,2.26c0.33-1.25,1.14-1.85,2.27-2.27C83.6,92.93,82.88,92.23,82.5,91.03z M80.11,64.47
    c1.27,0.42,1.92,1.19,2.43,2.29c0.31-1.31,1.18-1.84,2.23-2.29c-1.2-0.34-1.87-1.08-2.29-2.23C82.05,63.34,81.4,64.09,80.11,64.47
    z M105.49,101.36c0.45-1.17,1.17-1.87,2.39-2.36c-1.35-0.28-1.86-1.16-2.43-2.16c-0.32,1.22-1.09,1.77-2.13,2.22
    C104.39,99.54,105.12,100.14,105.49,101.36z M65.09,50.97c-0.48,1-1.11,1.67-2.31,1.96c1.2,0.4,1.9,1.09,2.41,2.17
    c0.46-1.13,1.22-1.76,2.22-2.15C66.25,52.41,66.25,52.41,65.09,50.97z M85.84,116.44c1.33,0.36,1.98,1.13,2.37,2.3
    c0.33-1.17,1.04-1.88,2.23-2.39c-1.17-0.42-1.77-1.17-2.27-2.17C87.83,115.37,87.04,115.97,85.84,116.44z M125.17,70.24
    c-1.3-0.38-1.95-1.09-2.39-2.11c-0.3,1.09-1.12,1.61-2.16,2.2c1.22,0.36,1.76,1.14,2.24,2.23
    C123.2,71.31,123.93,70.71,125.17,70.24z M105.48,68.1c-0.4,1.13-1.17,1.7-2.22,2.21c1.22,0.37,1.82,1.09,2.29,2.28
    c0.34-1.23,1.05-1.85,2.24-2.39C106.56,69.87,106.06,69.09,105.48,68.1z M62.72,35.67c1.44,0.31,2.01,1.22,2.53,2.28
    c0.29-1.26,1.11-1.87,2.2-2.31c-1.15-0.33-1.83-1.07-2.28-2.22C64.72,34.56,64.07,35.3,62.72,35.67z M76.68,72.47
    c0.46-1.06,1.09-1.76,2.28-2.28c-1.27-0.34-1.76-1.18-2.32-2.14c-0.29,1.2-1.12,1.74-2.16,2.27
    C75.66,70.65,76.28,71.35,76.68,72.47z M114.77,93.31c1.17,0.37,1.87,1.08,2.28,2.29c0.38-1.21,1.15-1.86,2.29-2.34
    c-1.29-0.31-1.85-1.15-2.33-2.23C116.69,92.26,115.92,92.9,114.77,93.31z M103.25,87.59c1.25,0.35,1.83,1.08,2.27,2.14
    c0.47-1.09,1.09-1.82,2.12-2.2c-1.08-0.33-1.59-1.17-2.16-2.07C105.09,86.51,104.38,87.09,103.25,87.59z M62.93,70.26
    c1.27,0.43,1.79,1.24,2.34,2.22c0.27-1.2,1.03-1.73,2.03-2.24c-1.08-0.46-1.74-1.11-2.1-2.27C64.75,69.13,64.12,69.79,62.93,70.26
    z M85.98,64.46c1.11,0.41,1.87,1.08,2.23,2.28c0.42-1.13,1.13-1.85,2.32-2.29c-1.24-0.3-1.84-1.08-2.35-2.05
    C87.75,63.46,87.07,64.12,85.98,64.46z"/>
    <path class="st0" d="M59.46,62.42c0.4,0.94,1.01,1.68,2.17,2.03c-1.3,0.6-2.17,1.39-2.19,2.85c-0.02,1.45,0.73,2.29,2.07,2.91
    c-1.21,0.7-2.12,1.49-2.07,2.97c0.05,1.43,0.79,2.28,2.19,2.83c-1.56,0.62-2.32,1.66-2.18,3.26c0.12,1.29,0.92,1.99,2.19,2.49
    c-1.64,0.77-2.55,1.93-2.06,3.72c0.31,1.15,1.16,1.87,2.35,1.99c1.47,0.14,2.52-0.53,3.17-2.16c0.53,1.07,1.13,1.85,2.25,2.23
    c-1.46,0.63-2.26,1.58-2.18,3.06c0.07,1.4,0.95,2.22,2.26,2.69c-1.32,0.51-2.24,1.36-2.26,2.81c-0.02,1.45,0.72,2.45,2.19,2.93
    c-1.88,1.25-2.38,2.15-2.05,3.54c0.31,1.28,1.18,2.07,2.39,2.19c1.48,0.14,2.36-0.46,3.23-2.22c0.56,1.38,0.62,1.45,2.17,2.33
    c-1.4,0.49-2.2,1.46-2.18,2.91c0.02,1.53,1,2.41,2.6,2.91c-0.93,0.34-1.65,0.63-2.04,1.38c-0.37,0.7-0.53,1.41-0.27,2.2
    c0.45,1.35,1.57,2.04,3.07,1.87c1.21-0.14,2.15-1.16,2.24-2.44c0.11-1.49-0.74-2.53-2.51-2.98c1.26-0.33,2.15-1.01,2.66-2.27
    c0.51,0.99,1.04,1.82,2.24,2.07c-1.61,0.81-2.53,1.93-2.12,3.7c0.27,1.17,1.1,1.9,2.27,2.09c1.63,0.27,2.7-0.56,3.44-2.18
    c0.34,1.23,1.11,1.87,2.34,2.24c-0.86,0.33-1.47,0.67-1.86,1.3c-0.37,0.6-0.58,1.24-0.45,1.96c0.23,1.3,1.06,2.19,2.23,2.39
    c1.53,0.26,2.5-0.32,3.45-2.15c0.48,1.34,1.35,2.21,2.76,2.28c1.39,0.07,2.29-0.75,3-2.01c0.89,1.47,1.99,2.35,3.71,1.89
    c1.24-0.33,1.82-1.23,2.1-2.5c0.36,0.75,0.57,1.55,1.3,1.97c0.68,0.4,1.39,0.62,2.18,0.41c1.3-0.35,2.08-1.38,2.06-2.73
    c-0.01-1.16-0.88-2.28-1.99-2.58c-1.39-0.37-2.44,0.27-3.55,2.16c-0.48-1.27-0.74-1.58-2.03-2.36c0.4-0.32,0.79-0.62,1.17-0.94
    c0.38-0.31,0.54-0.77,0.83-1.24c0.56,1.28,1.39,2.15,2.84,2.14c1.43-0.01,2.34-0.76,2.9-2.2c0.7,1.64,1.81,2.42,3.49,2.15
    c1.06-0.17,2.04-1.14,2.22-2.15c0.31-1.73-0.52-2.85-2.19-3.62c1.21-0.28,1.78-1.04,2.26-2.12c0.61,1.41,1.56,2.24,3,2.19
    c1.41-0.05,2.3-0.92,2.75-2.27c1.17,1.87,2.13,2.42,3.58,2.08c1.13-0.26,1.99-1.29,2.11-2.51c0.12-1.35-0.61-2.3-2.43-3.06
    c1.25-0.23,1.98-1,2.49-2.04c1.94,2.76,4.28,2.72,5.74-0.03c0.12-0.14,0.24-0.28,0.36-0.41c0.36,1.47,1.32,2.17,2.81,2.2
    c-0.05,0.42-0.1,0.85-0.15,1.27c-1.13,0.12-1.77,0.76-1.97,1.86c-0.44,0.04-0.87,0.07-1.31,0.11c-0.46-1.96-1.6-2.89-3.09-2.53
    c-1.23,0.3-1.97,1.28-1.93,2.53c0.05,1.38,0.88,2.18,2.53,2.44c-0.06,0.51-0.11,1.02-0.17,1.53c-0.86,0.2-1.41,0.71-1.61,1.58
    c-0.6,0.24-1.22,0.32-1.86,0.17c-0.5-1.66-1.31-2.36-2.49-2.14c-0.96,0.18-1.73,0.98-1.82,1.9c-0.11,1.08,0.54,1.86,1.94,2.36
    c0.06,0.77,0.08,1.53-0.41,2.2c-0.23,0.24-0.46,0.47-0.68,0.71c-0.84,0.45-1.72,0.53-2.62,0.2c-0.7-1.22-1.5-1.63-2.53-1.28
    c-0.86,0.29-1.36,1.23-1.19,2.18c0.14,0.77,0.72,1.13,1.33,1.46c0.47,1-0.02,1.91-0.27,2.84l0.02-0.01
    c-1,0.48-1.98,1.08-3.16,0.59c-0.41-0.88-1.05-1.5-2.07-1.36c-1.14,0.15-1.57,0.99-1.68,2.04c-0.6,0.13-1.21,0.1-1.82,0.03
    c-0.18-1.5-0.8-2.19-2.01-2.2c-1.1-0.01-1.84,0.62-2.01,1.71c-0.16,1.06,0.43,1.8,1.82,2.27c0.18,0.89,0.11,1.73-0.41,2.51
    c-0.22,0.24-0.45,0.48-0.67,0.72c-0.93,0.46-1.88,0.6-2.87,0.18c-0.39-0.81-0.92-1.39-1.92-1.25c-0.69,0.1-1.13,0.53-1.28,1.16
    c-0.2,0.83,0.14,1.48,0.87,1.92c0.5,2.64-1.02,4.15-3.92,3.87c-0.9-1.23-1.64-1.17-2.46,0.21c-1.07,0.42-2.14,0.49-3.19-0.07
    c-0.24-0.61-0.62-1.02-1.34-1c-0.67,0.02-1.04,0.38-1.19,1c-1.03,0.42-2.06,0.57-3.09,0.02c-1.16-1.45-1.94-1.43-2.75,0.05
    c-0.31,0.03-0.55,0.33-0.9,0.25c-0.44,0.01-0.88,0.01-1.32,0.02c-0.39,0.09-0.68-0.2-1.04-0.25c-0.18-0.56-0.51-0.99-1.13-1.02
    c-0.67-0.03-1.04,0.42-1.27,1c-1.16,0.64-2.33,0.56-3.5,0.01c-0.71-1.21-1.35-1.2-2.17,0.03c-1.48,0.72-2.79,0.41-3.98-0.66
    l0.02,0.02c-0.72-1.14-0.92-2.32-0.28-3.56c0.83-0.44,1.27-1.08,0.9-2.03c-0.29-0.72-0.91-0.87-1.61-0.77
    c-0.65,0.09-0.97,0.57-1.2,1.12c-1.32,0.61-2.53,0.32-3.68-0.45l0.02-0.02c-0.9-1.23-1.14-2.52-0.32-3.89
    c1.02-0.9,1.03-1.39,0.05-2.23c-0.57-1.14-0.66-2.28-0.04-3.43c1.31-0.8,1.31-1.74-0.02-2.58c-0.55-0.96-0.5-1.95-0.15-2.95
    c0.74-0.26,1.3-0.69,1.29-1.55c-0.01-0.83-0.59-1.19-1.27-1.44c-0.34-0.87-0.36-1.75-0.05-2.63c0.86-0.19,1.46-0.67,1.5-1.59
    c0.04-1-0.61-1.48-1.49-1.72c-0.29-0.8-0.34-1.6-0.05-2.41c0.89-0.27,1.59-0.76,1.54-1.78c-0.04-0.9-0.64-1.39-1.51-1.53
    c-0.27-0.85-0.32-1.69,0.02-2.53c0.92-0.12,1.46-0.67,1.5-1.57c0.04-0.98-0.59-1.49-1.5-1.68c-0.28-0.67-0.25-1.36-0.17-2.05
    c1.49-0.42,2.12-1.08,2.06-2.2c-0.05-1.05-0.66-1.63-2.07-1.95c0-0.5,0-1,0-1.5c1.56-0.3,2.17-0.91,2.19-2.17
    c0.02-1.3-0.5-1.85-2.11-2.24c0-0.42,0-0.83,0-1.25c1.44-0.28,2.2-1.01,2.28-2.17c0.09-1.22-0.62-2.01-2.21-2.47
    c0-0.35-0.01-0.7-0.01-1.05c1.55-0.4,2.2-1.05,2.25-2.24c0.06-1.27-0.5-1.97-1.97-2.46c0-0.32,0-0.64,0-0.96
    c1.48-0.56,2.22-1.38,2.13-2.53c-0.1-1.18-0.72-2.03-1.93-2.35c0.03-0.27,0.06-0.54,0.09-0.81c0.85-0.32,1.48-0.88,1.85-1.72
    C59.16,62.43,59.31,62.42,59.46,62.42z M82.22,119.38c0.05-1.53-1.08-2.77-2.56-2.82c-1.4-0.05-2.67,1.15-2.72,2.56
    c-0.06,1.53,1.07,2.76,2.57,2.8C80.95,121.97,82.17,120.82,82.22,119.38z M116.74,113.5c0.01-1.5-1.12-2.64-2.59-2.62
    c-1.4,0.02-2.63,1.2-2.64,2.54c-0.01,1.41,1.24,2.69,2.61,2.7C115.63,116.11,116.74,115.01,116.74,113.5z M62.22,93
    c1.54,0.02,2.64-1.01,2.7-2.52c0.05-1.41-1.18-2.68-2.59-2.68c-1.43-0.01-2.62,1.2-2.62,2.64C59.7,91.89,60.77,92.98,62.22,93z
    M68.05,110.24c1.46,0,2.58-1.1,2.57-2.53c0-1.44-1.12-2.52-2.59-2.51c-1.47,0.01-2.5,1.08-2.48,2.59
    C65.57,109.2,66.62,110.25,68.05,110.24z M62.29,93.79c-1.43,0-2.39,0.95-2.4,2.38c-0.01,1.42,1.07,2.5,2.46,2.47
    c1.34-0.03,2.37-1.1,2.37-2.47C64.72,94.82,63.67,93.79,62.29,93.79z M108.43,121.6c1.32-0.01,2.36-1.03,2.37-2.33
    c0.01-1.33-1.1-2.45-2.42-2.43c-1.28,0.02-2.36,1.1-2.36,2.37C106.02,120.59,107.05,121.61,108.43,121.6z M91.14,127.3
    c1.34-0.01,2.32-1,2.33-2.35c0.01-1.18-1.1-2.28-2.32-2.28c-1.3-0.01-2.37,1.05-2.37,2.33C88.78,126.32,89.8,127.31,91.14,127.3z
    M76.11,119.28c0.01-1.4-0.9-2.32-2.29-2.31c-1.36,0.01-2.34,0.98-2.32,2.32c0.01,1.25,1.02,2.27,2.27,2.29
    C75.11,121.6,76.1,120.63,76.11,119.28z M87.65,125.01c0-1.25-1-2.31-2.22-2.34c-1.3-0.02-2.36,0.99-2.39,2.29
    c-0.03,1.37,0.91,2.33,2.28,2.34C86.7,127.32,87.65,126.38,87.65,125.01z M68.05,115.7c1.29,0,2.33-1,2.31-2.24
    c-0.01-1.28-1.01-2.22-2.35-2.2c-1.29,0.02-2.2,0.93-2.21,2.19C65.8,114.79,66.7,115.7,68.05,115.7z M96.83,127.26
    c1.27,0.01,2.3-0.95,2.35-2.21c0.05-1.2-1.13-2.36-2.37-2.33c-1.16,0.03-2.16,1.06-2.19,2.26
    C94.6,126.29,95.53,127.25,96.83,127.26z M62.31,99.72c-1.29,0-2.33,1.01-2.3,2.25c0.02,1.24,1.09,2.3,2.32,2.28
    c1.19-0.01,2.18-1.02,2.2-2.25C64.56,100.65,63.65,99.72,62.31,99.72z M77.39,124.96c-0.02,1.26,0.9,2.25,2.13,2.29
    c1.23,0.04,2.24-0.92,2.26-2.15c0.03-1.27-0.87-2.23-2.13-2.26C78.36,122.81,77.41,123.69,77.39,124.96z M64.31,107.72
    c0.01-1.18-0.9-2.08-2.07-2.06c-1.04,0.01-2.03,1.02-2.03,2.05c0,1.08,1.04,2.09,2.13,2.07
    C63.44,109.76,64.31,108.85,64.31,107.72z M70.01,119.2c-0.02-1.06-0.89-1.96-1.92-1.97c-1.11-0.01-2.02,0.88-2.03,2
    c-0.01,1.15,0.81,1.96,2.01,1.96C69.28,121.19,70.03,120.42,70.01,119.2z M73.79,123.08c-1.19,0.02-1.95,0.82-1.91,2.02
    c0.03,1.1,0.86,1.92,1.95,1.91c1.13,0,2-0.87,2.01-2C75.84,123.93,74.92,123.06,73.79,123.08z M64.19,113.52
    c0.02-0.99-0.86-1.91-1.84-1.94c-1.04-0.04-1.97,0.87-1.96,1.92c0.01,0.99,0.92,1.89,1.89,1.88
    C63.3,115.37,64.17,114.52,64.19,113.52z M91.23,129c-0.96-0.06-1.77,0.65-1.85,1.61c-0.08,0.99,0.58,1.82,1.52,1.89
    c1.1,0.09,1.95-0.64,1.98-1.69C92.92,129.87,92.18,129.06,91.23,129z M87.14,130.84c0.02-0.97-0.81-1.83-1.78-1.83
    c-0.91-0.01-1.74,0.8-1.79,1.72c-0.05,0.97,0.76,1.79,1.78,1.79C86.35,132.52,87.12,131.79,87.14,130.84z M69.75,125.01
    c-0.01-0.99-0.73-1.7-1.72-1.69c-0.93,0.01-1.67,0.73-1.69,1.64c-0.02,0.93,0.78,1.75,1.71,1.78
    C68.99,126.76,69.75,125.99,69.75,125.01z M79.61,129.04c-0.88-0.02-1.76,0.87-1.75,1.75c0.01,0.89,0.77,1.67,1.66,1.7
    c0.96,0.04,1.78-0.71,1.79-1.66C81.33,129.93,80.49,129.05,79.61,129.04z M63.98,124.99c-0.03-0.87-0.86-1.66-1.71-1.64
    c-0.86,0.02-1.66,0.84-1.65,1.69c0.01,0.92,0.82,1.63,1.82,1.58C63.31,126.58,64.01,125.84,63.98,124.99z M62.25,117.62
    c-0.93,0.03-1.67,0.79-1.63,1.67c0.04,0.91,0.82,1.65,1.7,1.63c0.94-0.03,1.6-0.73,1.59-1.7
    C63.89,118.24,63.22,117.59,62.25,117.62z M73.83,129.2c-0.86-0.01-1.62,0.72-1.64,1.54c-0.01,0.85,0.83,1.67,1.68,1.66
    c0.85-0.01,1.55-0.76,1.55-1.63C75.42,129.91,74.7,129.2,73.83,129.2z M69.43,130.72c-0.09-0.81-0.53-1.31-1.4-1.28
    c-0.86,0.03-1.34,0.51-1.33,1.38c0.01,0.88,0.58,1.35,1.38,1.34C68.92,132.14,69.38,131.59,69.43,130.72z M77.45,136.36
    c0.03-0.06,0.06-0.12,0.09-0.17c-0.06,0-0.12-0.02-0.17-0.01c-0.02,0-0.06,0.08-0.05,0.08C77.36,136.3,77.41,136.33,77.45,136.36z
    "/>
    <path class="st2" d="M73.85,30.04c1.57,0.01,2.74,1.19,2.74,2.76c-0.01,1.51-1.29,2.81-2.76,2.81c-1.44,0-2.9-1.47-2.86-2.89
    C71.01,31.28,72.35,30.03,73.85,30.04z"/>
    <path class="st2" d="M58.19,46.67c-0.46-0.15-0.8,0.23-1.24,0.27c-1.32,0.12-2.43-0.47-2.9-1.64c-0.45-1.11-0.09-2.4,0.87-3.12
    c0.96-0.72,2.3-0.71,3.25,0.03c0.96,0.74,1.28,2.07,0.78,3.24c-0.11,0.26-0.23,0.52-0.35,0.78c0,0,0.02-0.01,0.02-0.01
    c-0.15,0.01-0.24,0.07-0.23,0.24l0.02-0.02c-0.18-0.02-0.32,0.01-0.23,0.24L58.19,46.67z"/>
    <path class="st2" d="M54.25,51.92c0.26-0.32-0.09-0.52-0.17-0.76c-0.42-1.17-0.18-2.32,0.68-3.08c0.79-0.7,2.01-0.84,3.05-0.34
    c0.19,0.09,0.39,0.17,0.58,0.25c0,0-0.01-0.02-0.01-0.02c-0.01,0.17,0.07,0.25,0.24,0.24l-0.02-0.02
    c0.54,1.04,0.85,2.09,0.28,3.23c-0.29,0.43-0.66,0.79-1.11,1.07c-0.99,0.47-1.94,0.19-2.9-0.13l0.01,0.01
    c0.02-0.17-0.08-0.23-0.23-0.24l0.02,0.02c-0.08-0.21-0.15-0.45-0.46-0.23L54.25,51.92z"/>
    <path class="st2" d="M59.16,38.6c-0.03,1.42-1.3,2.62-2.71,2.57c-1.39-0.05-2.55-1.24-2.54-2.63c0-1.45,1.19-2.61,2.65-2.6
    C58.02,35.96,59.19,37.15,59.16,38.6z"/>
    <path class="st2" d="M93.16,36.83c0.78,1.17,0.73,2.33-0.04,3.48c-0.14,0.12-0.28,0.24-0.42,0.36c-1.02,0.62-2.05,0.72-3.06,0.03
    c-0.9-0.61-1.24-1.5-1.09-2.55c0.06-0.45,0.27-0.88,0.41-1.32l-0.01,0.01c0.18,0.02,0.23-0.07,0.23-0.23l-0.02,0.01
    c1.24-0.75,2.48-0.99,3.74-0.06C92.99,36.65,93.07,36.74,93.16,36.83z"/>
    <path class="st2" d="M54.5,57.63c-0.96-1.38-0.5-2.68,0.19-3.98l-0.02,0.01c1.03-0.16,2.04-0.79,3.11-0.16
    c0.43,0.29,0.79,0.65,1.09,1.07c0.32,0.71,0.25,1.45,0.2,2.19c-0.39,0.67-0.89,1.23-1.61,1.56c-0.9,0.31-1.73,0-2.56-0.31
    l0.02,0.02c-0.07-0.08-0.13-0.16-0.2-0.24C54.64,57.74,54.57,57.69,54.5,57.63z"/>
    <path class="st2" d="M131.68,104.57c-1.48-0.04-2.44-0.74-2.81-2.2c-0.23-2.25,1.92-3.59,3.7-2.77c1.04,0.48,1.37,1.42,1.58,2.44
    c-0.09,0.33-0.17,0.67-0.26,1c-0.34,0.51-0.75,0.94-1.25,1.3C132.33,104.42,132,104.49,131.68,104.57z"/>
    <path class="st2" d="M59.01,62.44c-0.37,0.84-1,1.4-1.85,1.72c-0.7,0.06-1.36-0.16-2.04-0.25l0.02,0.02
    c-0.06-0.07-0.12-0.15-0.18-0.22c-0.11-0.09-0.23-0.18-0.34-0.27c-0.69-1.02-0.75-2.11-0.35-3.25l-0.02,0.01
    c0.08-0.07,0.16-0.14,0.24-0.22c0.15-0.15,0.3-0.3,0.45-0.45c0.78-0.53,1.65-0.49,2.52-0.43c0.68,0.44,1.26,0.97,1.61,1.72
    C59.03,61.37,59.02,61.9,59.01,62.44z"/>
    <path class="st1" d="M76.05,136.65c0.44-0.01,0.88-0.01,1.32-0.02c0.02,0.74,0.82,0.51,1.12,0.89l-0.02-0.02
    c0.08,0.08,0.16,0.16,0.24,0.24l-0.01-0.02c1.08,2.14,0.21,4.11-1.84,4.19c-1.15,0.04-2.17-0.65-2.5-1.7
    c-0.35-1.13,0.08-2.26,1.05-2.95C75.63,137.11,76.25,137.27,76.05,136.65z"/>
    <path class="st2" d="M79.61,30.25c1.43,0.01,2.57,1.14,2.55,2.55c-0.01,1.43-1.14,2.52-2.58,2.51c-1.47-0.01-2.5-1.05-2.5-2.55
    C77.07,31.3,78.13,30.24,79.61,30.25z"/>
    <path class="st3" d="M143.74,66.01c-0.71-1.35-0.14-3.05,1.34-3.58c1.29-0.47,2.69,0.23,3.19,1.48c0.33,0.84-0.08,1.6-0.15,2.4
    l0.02-0.01c-0.25-0.01-0.37,0.08-0.24,0.34l-0.07,0.02l-0.01,0.08c-1.11,0.35-2.22,0.86-3.36,0.08
    C144.21,66.55,143.97,66.28,143.74,66.01z"/>
    <path class="st3" d="M149.66,72.01c0.05-0.46-0.34-0.77-0.42-1.19c-0.22-1.2,0.4-2.4,1.51-2.84c1.04-0.42,2.24-0.09,2.92,0.82
    c0.68,0.92,0.63,2.27-0.12,3.12c-0.78,0.89-2.1,1.07-3.18,0.47C150.14,72.26,150.01,71.91,149.66,72.01L149.66,72.01z"/>
    <path class="st3" d="M141.83,60.65c-1.19,0.81-2.35,0.7-3.49-0.11l-0.01,0c-0.86-1.2-0.68-2.41,0-3.61l-0.01,0.01
    c0.07,0,0.15,0.04,0.21,0.01c1.52-0.79,2.47-0.75,3.36,0.13c0.88,0.87,0.93,1.92,0.16,3.37l0.01-0.02
    c-0.08,0.08-0.15,0.15-0.23,0.23L141.83,60.65z"/>
    <path class="st1" d="M70.95,137.04c1.37-0.01,2.44,1,2.48,2.33c0.04,1.35-1.1,2.49-2.47,2.48c-1.34-0.01-2.4-1.08-2.42-2.42
    C68.52,138.1,69.57,137.06,70.95,137.04z"/>
    <path class="st2" d="M57.06,64.97c1.2,0.32,1.83,1.17,1.93,2.35c0.1,1.15-0.65,1.97-2.13,2.53c-0.45-0.07-0.9-0.13-1.35-0.2
    c-0.53-0.28-0.86-0.76-1.26-1.18c-0.18-0.7-0.23-1.4-0.02-2.1c0.33-0.42,0.62-0.87,1.09-1.16C55.89,64.97,56.47,64.91,57.06,64.97
    z"/>
    <path class="st3" d="M133.2,56.55c0.93-0.4,1.84-0.23,2.72,0.16l-0.02-0.01c0.08,0.08,0.16,0.15,0.23,0.23l-0.01-0.01
    c0.08,0.08,0.15,0.16,0.23,0.23l-0.01-0.01c0.58,1.01,0.69,2.04,0.11,3.09c-0.17,0.19-0.35,0.39-0.52,0.58
    c-0.68,0.36-1.39,0.56-2.17,0.42c-0.28-0.92-0.9-1.51-1.8-1.8c-0.05-0.63-0.04-1.25,0.21-1.84C132.57,57.3,132.96,57,133.2,56.55z
    "/>
    <path class="st3" d="M130.46,51.24c0.58,0.88,0.81,1.81,0.43,2.83c-0.59,0.24-0.93,0.75-1.29,1.23c-0.56,0.2-1.13,0.15-1.7,0.11
    c-0.24-0.92-0.86-1.49-1.74-1.79c-0.14-0.68,0-1.31,0.27-1.93c0.29-0.3,0.59-0.6,0.88-0.9C128.45,50.37,129.51,50.48,130.46,51.24
    L130.46,51.24z"/>
    <path class="st1" d="M84.36,137.52c-0.34,0.33,0.07,0.49,0.18,0.69c0.56,1.09,0.34,2.32-0.55,3.04c-0.82,0.66-2.08,0.68-2.92,0.03
    c-0.91-0.7-1.14-1.93-0.62-3.03c0.11-0.22,0.51-0.37,0.25-0.72l-0.03-0.01c0.07,0.07,0.12,0.18,0.25,0.09
    c1.04-0.7,2.07-0.77,3.11,0c0.13,0.1,0.26,0.07,0.34-0.09L84.36,137.52z"/>
    <path class="st2" d="M95.09,40.34c0.15-0.31-0.16-0.44-0.26-0.65c-0.44-0.97-0.38-1.89,0.31-2.71c0.65-0.77,1.74-0.95,2.77-0.53
    c0.21,0.09,0.37,0.38,0.66,0.19l-0.01-0.01c-0.17,0.29,0.07,0.35,0.25,0.44l-0.01-0.03c0.51,0.93,0.45,1.89,0.2,2.87l0.01-0.01
    c-0.2-0.04-0.26,0.12-0.34,0.24c-0.1,0.1-0.21,0.21-0.31,0.31c-1,0.65-2.03,0.4-3.07,0.1l0.01,0.01c0.07-0.22-0.01-0.3-0.23-0.23
    L95.09,40.34z"/>
    <path class="st2" d="M56.87,70.81c1.47,0.5,2.03,1.2,1.97,2.47c-0.06,1.19-0.71,1.84-2.25,2.24c-0.32-0.06-0.64-0.12-0.96-0.19
    c-0.53-0.31-0.96-0.7-1.26-1.24c-0.22-0.69-0.21-1.37,0.06-2.04c0.26-0.4,0.58-0.73,0.99-0.98
    C55.88,70.85,56.37,70.82,56.87,70.81z"/>
    <path class="st2" d="M56.52,30.51c1.27,0,2.29,1,2.3,2.25c0.01,1.24-1.02,2.29-2.27,2.29c-1.24,0.01-2.29-1.02-2.3-2.26
    C54.24,31.56,55.27,30.51,56.52,30.51z"/>
    <path class="st2" d="M85.38,35.06c-1.24,0.01-2.3-1.02-2.31-2.25c-0.02-1.25,1-2.29,2.25-2.3c1.26-0.01,2.28,0.98,2.3,2.25
    C87.64,34.01,86.63,35.04,85.38,35.06z"/>
    <path class="st1" d="M54.88,101.23c-0.32-0.23-0.61,0.06-0.92,0.08c-1,0.06-1.78-0.29-2.27-1.17c-0.45-0.8-0.39-1.61,0.12-2.36
    c0.57-0.83,1.38-1.1,2.37-0.93c0.23,0.04,0.45,0.28,0.7,0.07l0-0.02c-0.1,0.24,0.12,0.26,0.24,0.34c0,0.05,0.03,0.08,0.09,0.09
    c0.12,0.08,0.23,0.16,0.35,0.24l-0.02-0.02c0.55,1.02,0.47,2.04,0,3.07l0.02-0.02c-0.17-0.01-0.23,0.09-0.24,0.24l0.02-0.02
    c-0.15,0.01-0.29,0.03-0.24,0.24l0.02-0.02c-0.18-0.04-0.35-0.05-0.24,0.23L54.88,101.23z"/>
    <path class="st3" d="M138.65,62.76c1.02-0.61,2.04-0.68,3.02,0.05c1.08,0.81,1.13,1.91,0.77,3.1c-0.37,0.25-0.69,0.56-0.89,0.96
    c-0.74,0.4-1.52,0.45-2.32,0.23c-0.18-0.85-0.68-1.43-1.51-1.71c-0.16-0.88,0.1-1.66,0.6-2.37l-0.01,0.01
    c0.11-0.09,0.22-0.18,0.33-0.27L138.65,62.76z M140.89,64.76c-0.08-0.41-0.32-0.71-0.78-0.69c-0.45,0.03-0.72,0.3-0.69,0.78
    c0.03,0.43,0.3,0.64,0.71,0.65C140.58,65.5,140.86,65.27,140.89,64.76z"/>
    <path class="st2" d="M56.6,76.57c1.59,0.46,2.3,1.26,2.21,2.47c-0.08,1.16-0.85,1.89-2.28,2.17c-0.25-0.01-0.51-0.03-0.76-0.04
    l0.02,0.01l-0.06-0.02l0.01-0.05c-0.54-0.33-1.01-0.73-1.33-1.29c-0.25-0.55-0.1-1.15-0.16-1.72l-0.02,0.01
    c0.17,0.07,0.16-0.08,0.2-0.17c0.29-0.49,0.69-0.87,1.17-1.18C55.93,76.7,56.27,76.63,56.6,76.57z"/>
    <path class="st3" d="M149.23,87.64c-0.01-1.41,1.03-2.48,2.43-2.5c1.38-0.01,2.48,1.08,2.5,2.46c0.01,1.38-1.08,2.49-2.46,2.5
    C150.3,90.11,149.24,89.05,149.23,87.64z M151.74,86.58c-0.72,0.03-1.13,0.39-1.14,1.01c-0.01,0.67,0.4,1.09,1.11,1.07
    c0.64-0.02,1.01-0.41,0.99-1.06C152.7,86.95,152.32,86.61,151.74,86.58z"/>
    <path class="st1" d="M51.86,95.14c0.25-0.13,0.13-0.28,0.03-0.43c-0.64-0.94-0.67-1.9,0.03-2.8c0.71-0.9,1.67-1.03,2.73-0.7
    c0.3,0.46,0.67,0.85,1.15,1.13c0.3,0.83,0.18,1.62-0.24,2.38l0.02-0.02c-0.08,0.08-0.16,0.16-0.23,0.24l0.02-0.02
    c-0.08,0.08-0.16,0.16-0.24,0.23l0.02,0c-1,0.55-1.98,0.65-2.93-0.1C52.06,94.95,51.93,94.97,51.86,95.14L51.86,95.14z"/>
    <path class="st1" d="M55.12,106.71c-0.27,0.08-0.54,0.17-0.82,0.23c-1.06,0.2-1.97-0.03-2.55-1c-0.55-0.91-0.45-1.81,0.2-2.64
    c0.59-0.75,1.71-0.99,2.68-0.61c0.2,0.08,0.38,0.22,0.56,0.32c0.06,0.06,0.13,0.13,0.19,0.19c0.82,0.99,0.61,2.03,0.16,3.08
    l0.02-0.02c-0.17-0.01-0.25,0.07-0.24,0.24l0.02-0.02c-0.16,0-0.27,0.05-0.24,0.24L55.12,106.71z"/>
    <path class="st3" d="M149.88,77.91c-0.21-0.6-0.68-1.07-0.67-1.78c0.02-1.19,0.63-2.13,1.72-2.48c1.03-0.33,2.15,0.04,2.78,0.93
    c0.6,0.84,0.57,2.13-0.07,2.94c-0.71,0.9-1.86,1.21-2.92,0.78c-0.29-0.12-0.57-0.27-0.86-0.41L149.88,77.91z M152.73,75.89
    c-0.07-0.52-0.4-0.92-1.03-0.93c-0.68-0.01-1.12,0.43-1.09,1.08c0.03,0.62,0.43,1.06,1.15,1.01
    C152.41,77,152.7,76.61,152.73,75.89z"/>
    <path class="st3" d="M149.67,83.58c0.13-0.33-0.16-0.53-0.26-0.78c-0.44-1.17-0.06-2.39,0.97-3.04c0.95-0.6,2.32-0.45,3.1,0.34
    c0.79,0.81,0.93,2.14,0.32,3.1c-0.63,0.99-1.93,1.39-3.06,0.96c-0.29-0.11-0.49-0.45-0.86-0.36c0,0,0.01,0.01,0.01,0.01
    C149.94,83.6,149.88,83.51,149.67,83.58L149.67,83.58z M152.74,81.92c0-0.72-0.37-1.1-0.98-1.13c-0.68-0.03-1.1,0.37-1.11,1.05
    c-0.01,0.69,0.42,1.07,1.09,1.06C152.37,82.89,152.7,82.49,152.74,81.92z"/>
    <path class="st2" d="M62.33,29.22c-1.27,0.01-2.21-0.9-2.22-2.17c-0.01-1.28,0.9-2.22,2.17-2.23c1.26-0.01,2.22,0.93,2.23,2.17
    C64.52,28.25,63.58,29.21,62.33,29.22z"/>
    <path class="st2" d="M68.08,24.81c1.23,0.01,2.22,1.02,2.21,2.24c-0.01,1.21-1.03,2.19-2.26,2.18c-1.24-0.01-2.2-0.98-2.18-2.23
    C65.86,25.75,66.84,24.8,68.08,24.81z"/>
    <path class="st1" d="M55.32,112.37c-0.29-0.26-0.44,0.09-0.63,0.17c-1.09,0.49-2.17,0.26-2.82-0.62c-0.63-0.85-0.56-2.03,0.16-2.8
    c0.73-0.79,1.87-0.9,2.88-0.29c0.15,0.09,0.3,0.2,0.44,0.3c0,0-0.01-0.01-0.01-0.01c0.4,0.67,0.58,1.37,0.4,2.16
    c-0.09,0.39-0.6,0.62-0.4,1.1L55.32,112.37z"/>
    <path class="st2" d="M107.16,42.43c0.87-0.46,1.73-0.43,2.58,0.05c0.2,0.2,0.41,0.39,0.61,0.59c0.25,0.63,0.29,1.29,0.24,1.96
    c-0.33,0.63-0.79,1.12-1.42,1.45c-0.56-0.02-1.12-0.03-1.68-0.05c-0.43-0.32-0.83-0.66-1.11-1.12c-0.01-0.11-0.04-0.19-0.17-0.16
    l0.02,0.01c0.01-0.68-0.08-1.36,0.22-2.01C106.68,42.9,106.92,42.66,107.16,42.43z"/>
    <path class="st3" d="M147.91,72.2c-0.5-0.24-0.7,0.3-1.08,0.41c-0.76,0.21-1.48,0.15-2.19-0.15c-0.3-0.31-0.6-0.62-0.9-0.92
    c-0.47-0.94-0.22-1.87,0.05-2.8l-0.02,0.01c0.19,0.04,0.23-0.09,0.25-0.23l-0.03,0.02c0.15-0.02,0.23-0.1,0.23-0.25l-0.01,0.02
    c0.14-0.02,0.29-0.03,0.23-0.24l-0.01,0.01c1.12-0.4,2.21-0.28,3.28,0.2l-0.01-0.01C148.64,69.54,148.4,70.87,147.91,72.2
    L147.91,72.2z M146.99,70.24c-0.12-0.62-0.46-1.04-1.09-1.02c-0.66,0.02-1.07,0.41-1.04,1.11c0.03,0.64,0.42,1,1.04,1
    C146.61,71.33,146.9,70.85,146.99,70.24z"/>
    <path class="st1" d="M65.15,137.26c1.29-0.02,2.21,0.87,2.23,2.14c0.01,1.24-0.96,2.23-2.16,2.23c-1.15,0-2.15-0.97-2.2-2.12
    C62.97,138.3,63.93,137.29,65.15,137.26z"/>
    <path class="st3" d="M132.61,54.33c-0.64-1.39-0.46-2.42,0.52-3.09c0.82-0.56,1.9-0.52,2.62,0.14c0.74,0.68,0.96,1.53,0.57,2.48
    c-0.36,0.88-1.36,1.42-2.31,1.27c-0.25-0.04-0.49-0.1-0.74-0.15l0.02,0.01c-0.03-0.18-0.17-0.22-0.32-0.25
    C132.85,54.59,132.73,54.46,132.61,54.33z"/>
    <path class="st2" d="M56.53,82.46c1.62,0.39,2.13,0.94,2.11,2.24c-0.02,1.26-0.64,1.87-2.19,2.17c-0.23-0.05-0.46-0.11-0.7-0.16
    c-0.58-0.22-0.93-0.71-1.29-1.18c-0.09-0.56-0.1-1.12-0.01-1.68c0.34-0.51,0.71-1,1.3-1.25C56.02,82.55,56.27,82.5,56.53,82.46z"
    />
    <path class="st3" d="M151.73,91.19c1.2,0.02,2.11,0.97,2.09,2.18c-0.02,1.18-1,2.13-2.17,2.11c-1.17-0.02-2.12-1.01-2.1-2.19
    C149.57,92.08,150.52,91.17,151.73,91.19z"/>
    <path class="st1" d="M54.47,85.53c0.36,0.47,0.7,0.96,1.29,1.18c0.11,0.61,0.16,1.23-0.04,1.83c-0.45,0.29-0.81,0.65-1.07,1.12
    c-0.93,0.29-1.78,0.09-2.58-0.41l0.01,0c-0.57-0.96-0.87-1.94-0.14-2.94c0.6-0.83,1.46-1,2.43-0.79
    C54.39,85.53,54.43,85.53,54.47,85.53z"/>
    <path class="st3" d="M116.01,45.1c1.01-0.4,1.93-0.22,2.78,0.41c0.07,0.09,0.13,0.18,0.2,0.26c0.4,0.7,0.42,1.45,0.3,2.22
    c-0.58,0.32-1.09,0.71-1.36,1.34c-0.46,0.03-0.92,0.06-1.38,0.09c-0.3-0.85-0.87-1.42-1.71-1.74c0.05-0.53,0.11-1.07,0.16-1.6
    C115.43,45.85,115.71,45.47,116.01,45.1z"/>
    <path class="st1" d="M61.56,133.55c0.02,1.19-0.93,2.17-2.1,2.18c-1.1,0.01-2.09-0.95-2.12-2.06c-0.04-1.17,0.93-2.16,2.11-2.17
    C60.59,131.5,61.54,132.41,61.56,133.55z"/>
    <path class="st1" d="M55.77,116.4c-0.01,1.17-0.91,2.07-2.06,2.09c-1.2,0.01-2.13-0.93-2.12-2.14c0.01-1.2,0.97-2.13,2.17-2.09
    C54.89,114.29,55.79,115.25,55.77,116.4z"/>
    <path class="st2" d="M75.9,27.02c0,1.18-0.88,2.09-2.03,2.12c-1.16,0.03-2.16-0.96-2.16-2.13c0-1.2,0.95-2.11,2.17-2.09
    C75.05,24.95,75.9,25.83,75.9,27.02z"/>
    <path class="st2" d="M119.76,115.65c-1.41-0.5-2.05-1.28-1.94-2.36c0.09-0.92,0.86-1.72,1.82-1.9c1.18-0.22,1.99,0.48,2.49,2.14
    c-0.14,0.42-0.28,0.85-0.42,1.27l0.02-0.01c-0.08,0.07-0.16,0.15-0.24,0.22l0,0c-0.08,0.08-0.15,0.16-0.22,0.24l0.02-0.02
    c-0.14,0.02-0.28,0.04-0.23,0.24l0.01-0.02C120.63,115.52,120.2,115.59,119.76,115.65z"/>
    <path class="st3" d="M159.53,81.9c-0.02,1.17-0.94,2.09-2.08,2.07c-1.13-0.02-2.07-0.98-2.07-2.11c0-1.14,0.93-2.1,2.05-2.11
    C158.63,79.73,159.55,80.68,159.53,81.9z"/>
    <path class="st3" d="M157.46,78.12c-1.13,0.01-2.09-0.91-2.12-2.05c-0.03-1.15,0.86-2.09,2.02-2.13c1.22-0.04,2.16,0.87,2.16,2.09
    C159.52,77.19,158.62,78.1,157.46,78.12z"/>
    <path class="st3" d="M121.27,45.58c0.5-0.05,0.86-0.47,1.41-0.49c1.52-0.04,2.54,1.11,2.22,2.6c-0.05,0.24-0.24,0.46-0.12,0.72
    c0,0,0.01-0.01,0.01-0.01c-0.21-0.06-0.26,0.11-0.33,0.24c-0.01,0.05-0.04,0.08-0.09,0.09c-0.77,0.52-1.6,0.64-2.49,0.34
    c-0.34-0.33-0.67-0.66-1.01-0.99c-0.16-0.89,0-1.73,0.42-2.53L121.27,45.58z"/>
    <path class="st1" d="M55.76,82.6c-0.59,0.25-0.96,0.74-1.3,1.25c-1.01,0.29-1.87,0.06-2.48-0.8c-0.5-0.71-0.51-1.5-0.09-2.25
    c0.56-1,1.49-1.15,2.53-0.98c0.32,0.56,0.79,0.96,1.33,1.29c0,0,0.05,0.07,0.05,0.07l-0.02-0.01
    C55.76,81.64,55.76,82.12,55.76,82.6z"/>
    <path class="st3" d="M151.67,62.71c1.14-0.01,2.07,0.94,2.05,2.1c-0.02,1.13-0.88,1.98-2.02,1.99c-1.15,0.01-2.01-0.82-2.04-1.96
    C149.64,63.64,150.5,62.73,151.67,62.71z"/>
    <path class="st3" d="M143.95,58.72c0.03-1.14,0.89-1.95,2.05-1.91c1.11,0.03,1.97,0.95,1.92,2.06c-0.04,1.09-1.01,2-2.07,1.95
    C144.75,60.77,143.92,59.86,143.95,58.72z"/>
    <path class="st2" d="M91.14,30.77c1.09,0.02,2,0.97,1.97,2.06c-0.03,1.12-0.95,1.99-2.06,1.96c-1.08-0.04-1.89-0.89-1.89-1.99
    C89.15,31.68,90.06,30.75,91.14,30.77z"/>
    <path class="st2" d="M101.15,40.01c-0.91-1.76-0.37-3.26,1.23-3.41c1.62-0.15,2.66,1.21,2.09,2.74c-0.07,0.2-0.14,0.4-0.21,0.6
    l0.01-0.03c-0.11,0.08-0.23,0.16-0.34,0.24l-0.01,0.07l-0.07,0.01c-0.94,0.49-1.82,0.36-2.67-0.22L101.15,40.01z"/>
    <path class="st1" d="M55.6,76.76c-0.48,0.31-0.88,0.69-1.17,1.18c-0.1,0.02-0.24-0.01-0.2,0.17l0.02-0.01
    c-0.28-0.02-0.57-0.02-0.84-0.07c-1.15-0.2-1.92-1.25-1.73-2.34c0.2-1.22,1.22-1.83,2.7-1.61c0.3,0.54,0.73,0.94,1.26,1.24
    C55.76,75.81,55.76,76.29,55.6,76.76z"/>
    <path class="st2" d="M102.5,127.05c-1.4-0.47-1.99-1.21-1.82-2.27c0.17-1.09,0.91-1.72,2.01-1.71c1.2,0.01,1.83,0.7,2.01,2.2
    c-0.12,0.29-0.32,0.56-0.22,0.9l0.02-0.02c-0.23,0-0.23,0.18-0.24,0.33c-0.03,0.03-0.06,0.07-0.1,0.1
    c-0.16,0.02-0.33,0.01-0.33,0.25l0.01-0.02C103.38,126.89,102.94,126.97,102.5,127.05z"/>
    <path class="st2" d="M54.65,89.66c0.25-0.47,0.62-0.83,1.07-1.12c0.24-0.06,0.49-0.11,0.73-0.17c1.41,0.32,2.03,0.9,2.07,1.95
    c0.05,1.12-0.57,1.78-2.06,2.2c-0.2-0.15-0.42-0.19-0.66-0.17c-0.48-0.28-0.85-0.67-1.15-1.13C54.52,90.69,54.5,90.18,54.65,89.66
    z"/>
    <path class="st2" d="M116.01,45.1c-0.3,0.37-0.58,0.75-1,0.99c-0.52,0.19-1.05,0.18-1.59,0.06c-0.44-0.26-0.8-0.62-1.09-1.04
    c-0.22-0.64,0.09-1.29,0-1.93l-0.01,0.01c0.27,0.03,0.34-0.09,0.24-0.33l0.07-0.02l0.02-0.07c0.25,0.1,0.34,0,0.33-0.24
    l-0.01,0.01c0.48,0.17,0.87-0.27,1.35-0.17C115.35,42.56,116.38,43.21,116.01,45.1z"/>
    <path class="st1" d="M104.14,126.59c0.03-0.03,0.07-0.06,0.1-0.1c0.08-0.11,0.16-0.22,0.24-0.33l-0.02,0.02
    c0.95-0.33,1.85-0.29,2.63,0.44l-0.01-0.02c0.13,0.33,0.32,0.66,0.38,1.01c0.12,0.78-0.15,1.43-0.81,1.89
    c-0.66,0.45-1.36,0.49-2.05,0.08c-0.69-0.4-1.02-1.02-0.97-1.81c0.02-0.32,0.12-0.63,0.18-0.95c0,0-0.01,0.02-0.01,0.02
    C103.92,126.75,104.03,126.67,104.14,126.59z"/>
    <path class="st1" d="M53.73,120.23c1.13,0.02,1.87,0.77,1.86,1.92c0,1.09-0.82,1.93-1.9,1.94c-1.08,0.01-2-0.94-1.96-2.02
    C51.77,121,52.63,120.21,53.73,120.23z"/>
    <path class="st3" d="M159.31,70.29c-0.01,1.08-0.78,1.88-1.83,1.89c-1.08,0.01-1.93-0.84-1.93-1.93c0.01-1.1,0.86-1.91,1.96-1.87
    C158.58,68.42,159.32,69.21,159.31,70.29z"/>
    <path class="st2" d="M79.59,25.15c1.04,0,1.89,0.82,1.91,1.84c0.02,1.06-0.88,1.93-1.95,1.9c-1.03-0.02-1.84-0.86-1.84-1.89
    C77.72,25.97,78.55,25.14,79.59,25.15z"/>
    <path class="st2" d="M54.68,26.98c0.02-1.04,0.84-1.85,1.86-1.84c1.09,0.01,1.89,0.86,1.85,1.97c-0.03,1.02-0.88,1.81-1.92,1.78
    C55.44,28.86,54.66,28.02,54.68,26.98z"/>
    <path class="st1" d="M121.71,114.8c1.76-0.63,3.21,0.22,3.02,1.76c-0.12,0.98-0.99,1.74-1.94,1.7c-1-0.04-1.74-0.81-1.76-1.83
    c-0.01-0.32,0.03-0.65,0.04-0.97c0,0-0.01,0.02-0.01,0.02c0.17,0.01,0.21-0.11,0.23-0.24l-0.02,0.02
    c0.08-0.08,0.15-0.16,0.22-0.24l0,0c0.08-0.07,0.16-0.15,0.24-0.22L121.71,114.8z"/>
    <path class="st1" d="M115.6,120.72c0.33,0.08,0.51-0.22,0.77-0.32c0.88-0.32,1.84-0.06,2.28,0.72c0.45,0.8,0.42,1.62-0.23,2.3
    c-0.65,0.68-1.46,0.75-2.28,0.32c-0.79-0.42-1.08-1.36-0.8-2.24c0.08-0.27,0.38-0.46,0.28-0.79L115.6,120.72z"/>
    <path class="st2" d="M113.66,121.13c-0.61-0.33-1.19-0.7-1.33-1.46c-0.18-0.96,0.32-1.89,1.19-2.18c1.03-0.35,1.83,0.06,2.53,1.28
    c0.12,0.72-0.26,1.32-0.45,1.96l0.02-0.02C114.97,120.88,114.38,121.28,113.66,121.13z"/>
    <path class="st1" d="M129.99,109.13c-0.09,0.25,0.12,0.39,0.22,0.57c0.42,0.79,0.3,1.54-0.27,2.19c-0.55,0.63-1.27,0.74-2.06,0.5
    c-0.23-0.07-0.4-0.32-0.69-0.24c0,0-0.01,0.02,0,0.02c-0.3-0.68-0.62-1.35-0.47-2.13c0.55-0.33,0.97-0.78,1.33-1.31
    c0.72-0.16,1.31,0.27,1.97,0.41L129.99,109.13z"/>
    <path class="st1" d="M90.09,139.43c0,1.1-0.74,1.84-1.84,1.83c-1.06,0-1.88-0.81-1.87-1.85c0.01-1.01,0.87-1.86,1.89-1.86
    C89.32,137.55,90.09,138.35,90.09,139.43z"/>
    <path class="st3" d="M144.66,91.15c1.1-0.53,2.14-0.43,3.02,0.41c0.75,0.72,0.9,1.65,0.57,2.62c-0.46,1.34-1.99,1.97-3.3,1.4
    c-0.36-0.16-0.7-0.36-1.04-0.55c-0.03-0.03-0.07-0.03-0.1,0c-0.18-0.98-0.63-1.95-0.07-2.95
    C144.04,91.78,144.35,91.47,144.66,91.15z M145.86,95.02c0.95,0.02,1.66-0.64,1.71-1.6c0.05-1.02-0.63-1.76-1.62-1.78
    c-0.97-0.02-1.74,0.74-1.74,1.7C144.22,94.24,144.95,95,145.86,95.02z"/>
    <path class="st1" d="M55.42,71.06c-0.4,0.25-0.73,0.58-0.99,0.98c-1.54,0.23-2.52-0.38-2.59-1.62c-0.08-1.36,0.82-2.09,2.41-1.96
    c0.4,0.42,0.73,0.9,1.26,1.18C55.56,70.12,55.61,70.6,55.42,71.06z"/>
    <path class="st3" d="M112.66,42.76l-0.02,0.07c0,0-0.07,0.02-0.07,0.02c-0.11,0.09-0.28,0.13-0.24,0.33l0.01-0.01
    c-0.67,0.03-1.34,0.23-1.98-0.12c-0.2-0.2-0.41-0.39-0.61-0.59c-0.21-0.63-0.36-1.26,0.02-1.88c0.5-0.82,1.25-1.11,2.13-0.83
    c0.89,0.29,1.31,0.98,1.25,1.92c-0.02,0.28-0.11,0.56-0.16,0.84l0.01-0.01C112.88,42.6,112.77,42.68,112.66,42.76z"/>
    <path class="st2" d="M106.51,125.24c0.11-1.05,0.54-1.9,1.68-2.04c1.02-0.13,1.66,0.48,2.07,1.36c-0.16,2.07-0.74,2.45-3.17,2.04
    c0,0,0.01,0.02,0.01,0.02C107.08,126.07,106.6,125.74,106.51,125.24z"/>
    <path class="st3" d="M130.44,47.15c0,0.98-0.79,1.79-1.78,1.82c-1.03,0.03-1.86-0.8-1.83-1.86c0.02-1,0.81-1.76,1.82-1.76
    C129.64,45.36,130.44,46.17,130.44,47.15z"/>
    <path class="st2" d="M132.61,54.33c0.12,0.13,0.24,0.27,0.36,0.4c0.09,0.11,0.09,0.32,0.32,0.25l-0.02-0.01
    c-0.02,0.53-0.05,1.05-0.07,1.58c-0.24,0.45-0.63,0.75-1.03,1.04c-0.43,0.01-0.86,0.02-1.29,0.03c-0.5-0.26-0.91-0.61-1.16-1.12
    c-0.04-0.4-0.08-0.8-0.12-1.2c0.36-0.48,0.7-0.99,1.29-1.23C131.48,54.06,132.07,54.02,132.61,54.33z"/>
    <path class="st2" d="M129.57,107.7c0.2-1.1,0.84-1.74,1.97-1.86c1.79,0.58,2.08,1.31,1.32,3.3l0.01-0.01
    c-0.96,0.58-1.91,0.43-2.87,0l0.02,0.01C129.86,108.66,129.71,108.18,129.57,107.7z"/>
    <path class="st2" d="M138.34,103.48c-0.83,0.5-1.65,0.19-2.47-0.02c0,0,0.03,0.01,0.03,0.01c-0.08-0.55-0.57-0.96-0.5-1.55
    c0.09-1.12,0.78-1.64,1.81-1.82c0.22,0.02,0.45,0.05,0.67,0.07c0.44,0.3,0.82,0.65,1.1,1.1c0.02,0.59,0.06,1.18-0.19,1.74
    C138.64,103.17,138.49,103.33,138.34,103.48z"/>
    <path class="st3" d="M147.71,85.74c0.5,0.81,0.89,1.65,0.59,2.63c-0.46,1.47-1.85,2.03-3.62,1.44c-0.22-0.45-0.58-0.77-0.99-1.04
    c-0.3-0.77-0.3-1.53-0.01-2.3c0.44-0.25,0.74-0.64,1-1.06c1.09-0.53,2.08-0.25,3.04,0.34L147.71,85.74z M145.9,85.95
    c-0.95,0.02-1.65,0.72-1.65,1.66c-0.01,0.98,0.74,1.7,1.74,1.68c0.94-0.02,1.67-0.74,1.67-1.66
    C147.67,86.65,146.9,85.92,145.9,85.95z"/>
    <path class="st1" d="M99.76,131.84c1-0.02,1.81,0.74,1.84,1.72c0.02,1.01-0.72,1.79-1.74,1.81c-1.04,0.02-1.81-0.7-1.83-1.71
    C97.99,132.66,98.75,131.86,99.76,131.84z"/>
    <path class="st3" d="M147.92,80.08c0.57,1.06,0.57,2.16,0.22,3.28c0,0,0.01-0.01,0.01-0.01c-0.22-0.06-0.26,0.11-0.33,0.24
    c0,0.06-0.04,0.09-0.1,0.09c-0.86,0.69-1.85,0.69-2.87,0.54l0.01,0.01c0.03-0.16-0.08-0.18-0.2-0.2c-0.25-0.4-0.55-0.74-0.95-1
    c-0.29-0.75-0.33-1.51-0.05-2.28c0.53-0.28,0.91-0.69,1.2-1.21c1-0.4,1.91-0.04,2.83,0.31l-0.01-0.01
    c-0.05,0.2,0.02,0.28,0.23,0.23L147.92,80.08z M145.9,80.13c-0.9,0.01-1.67,0.78-1.69,1.67c-0.02,0.97,0.75,1.76,1.7,1.76
    c0.96,0,1.69-0.78,1.66-1.78C147.54,80.85,146.81,80.12,145.9,80.13z"/>
    <path class="st3" d="M144.63,73.84c1.12-0.5,2.19-0.46,3.08,0.46c1.02,1.05,0.74,2.23,0.21,3.4l0.01-0.02
    c-0.25,0.05-0.43,0.16-0.45,0.45l0.01-0.01c-0.85,0.27-1.7,0.58-2.6,0.21c-0.26-0.62-0.71-1.05-1.32-1.34
    c-0.17-0.78-0.16-1.54,0.17-2.28C144.04,74.42,144.34,74.13,144.63,73.84z M147.65,76.01c0-0.91-0.83-1.7-1.77-1.68
    c-0.91,0.02-1.65,0.75-1.67,1.65c-0.02,0.95,0.74,1.72,1.69,1.73C146.87,77.71,147.64,76.96,147.65,76.01z"/>
    <path class="st3" d="M103.84,40.23l0.07-0.01c0,0,0.01-0.07,0.02-0.07c0.32,0.22,0.35,0.02,0.34-0.24l-0.01,0.03
    c0.1,0.01,0.21,0.06,0.29,0.02c0.78-0.4,1.54-0.44,2.22,0.17c0.73,0.65,0.7,1.46,0.39,2.3c-0.24,0.24-0.48,0.48-0.71,0.72
    c-0.48,0.1-0.96,0.17-1.45,0.08c-0.3-0.52-0.73-0.93-1.22-1.27C103.6,41.37,104.15,40.82,103.84,40.23z"/>
    <path class="st3" d="M140.12,51.25c0.98,0,1.71,0.7,1.72,1.69c0.02,1.04-0.72,1.81-1.73,1.81c-0.95,0-1.73-0.76-1.76-1.7
    C138.32,52.07,139.12,51.26,140.12,51.25z"/>
    <path class="st1" d="M59.42,137.74c0.95-0.02,1.76,0.76,1.76,1.69c0,0.92-0.82,1.71-1.76,1.7c-0.91-0.01-1.65-0.73-1.68-1.65
    C57.71,138.52,58.44,137.75,59.42,137.74z"/>
    <path class="st1" d="M132.65,104.34c0.49-0.36,0.91-0.79,1.25-1.3c0.75-0.25,1.31,0.41,2,0.43c0,0-0.03-0.01-0.03-0.01
    c-0.25,0.22,0,0.4,0.08,0.56c0.32,0.71,0.2,1.38-0.3,1.92c-0.47,0.51-1.09,0.71-1.79,0.47c-0.77-0.27-1.16-0.84-1.22-1.64
    C132.63,104.63,132.65,104.48,132.65,104.34z"/>
    <path class="st3" d="M145.97,101.17c-1.2,0.03-2.17-0.89-2.2-2.08c-0.03-1.18,0.92-2.17,2.1-2.19c1.17-0.02,2.16,0.91,2.21,2.1
    C148.12,100.16,147.17,101.14,145.97,101.17z M145.98,97.76c-0.81,0.04-1.31,0.42-1.35,1.25c-0.04,0.83,0.46,1.31,1.23,1.35
    c0.79,0.04,1.32-0.45,1.34-1.24C147.23,98.35,146.79,97.84,145.98,97.76z"/>
    <path class="st2" d="M143.69,88.77c0.41,0.27,0.77,0.59,0.99,1.04c-0.01,0.45-0.01,0.89-0.02,1.34c-0.31,0.31-0.62,0.62-0.93,0.93
    c-0.41,0.02-0.82,0.04-1.24,0.05c-0.83-0.37-1.35-0.96-1.23-1.91c0.12-0.95,0.75-1.41,1.65-1.55
    C143.17,88.71,143.43,88.74,143.69,88.77z"/>
    <path class="st2" d="M98.57,32.82c-0.01,0.98-0.76,1.68-1.75,1.65c-0.99-0.03-1.68-0.79-1.64-1.77c0.04-0.9,0.81-1.64,1.7-1.63
    C97.84,31.08,98.58,31.85,98.57,32.82z"/>
    <path class="st3" d="M157.43,85.94c0.99-0.01,1.75,0.73,1.74,1.69c0,0.95-0.78,1.68-1.76,1.67c-0.95-0.02-1.64-0.7-1.66-1.63
    C155.73,86.68,156.44,85.95,157.43,85.94z"/>
    <path class="st1" d="M55.37,127.85c0.02,0.97-0.72,1.76-1.66,1.77c-0.95,0.01-1.68-0.74-1.68-1.73c0-0.95,0.66-1.66,1.59-1.69
    C54.59,126.16,55.35,126.87,55.37,127.85z"/>
    <path class="st2" d="M124.35,48.75c0.05-0.01,0.08-0.04,0.09-0.09c0.19,0.03,0.34,0,0.33-0.24c0,0-0.01,0.01-0.01,0.01
    c0.39-0.01,0.79-0.05,1.18-0.01c1.08,0.12,1.53,0.92,1.37,2.38c-0.29,0.3-0.59,0.6-0.88,0.9c-0.47,0.01-0.95,0.01-1.42,0.02
    c-0.31-0.3-0.62-0.6-0.93-0.9C123.91,50.09,124.14,49.42,124.35,48.75z"/>
    <path class="st2" d="M144.63,73.84c-0.29,0.29-0.59,0.58-0.88,0.87c-0.47,0.16-0.96,0.15-1.45,0.09c-0.32-0.33-0.64-0.66-0.95-1
    c0-0.44,0-0.87,0-1.31c0.27-0.42,0.58-0.79,1.05-0.99c0.45-0.15,0.89-0.02,1.34,0.04c0.3,0.31,0.6,0.62,0.9,0.92
    C144.64,72.92,144.63,73.38,144.63,73.84z"/>
    <path class="st2" d="M52.45,61.62c-0.01,0.89-0.8,1.64-1.7,1.64c-0.9,0-1.65-0.77-1.65-1.68c0-0.91,0.75-1.64,1.68-1.63
    C51.68,59.97,52.47,60.75,52.45,61.62z"/>
    <path class="st2" d="M50.73,57.49c-0.91-0.02-1.64-0.8-1.62-1.7c0.02-0.89,0.81-1.63,1.71-1.61c0.89,0.02,1.65,0.81,1.63,1.7
    C52.42,56.78,51.62,57.52,50.73,57.49z"/>
    <path class="st2" d="M110.05,38.54c0,0.95-0.69,1.63-1.64,1.63c-0.95,0-1.66-0.7-1.66-1.62c0.01-0.91,0.75-1.66,1.66-1.66
    C109.32,36.88,110.05,37.61,110.05,38.54z"/>
    <path class="st2" d="M52.44,67.39c0,0.89-0.76,1.6-1.7,1.59c-0.95-0.01-1.65-0.71-1.63-1.64c0.01-0.89,0.79-1.65,1.69-1.63
    C51.68,65.72,52.45,66.5,52.44,67.39z"/>
    <path class="st2" d="M123.98,113.36c0.19-0.87,0.75-1.38,1.61-1.58c0.58-0.05,0.98,0.6,1.59,0.4c0,0,0.01-0.02,0.01-0.02
    c-0.39,0.22-0.04,0.44,0.02,0.63c0.34,1.13-0.1,2.05-1.12,2.3c-0.99,0.25-1.73-0.27-2.01-1.41
    C124.05,113.56,124.01,113.46,123.98,113.36z"/>
    <path class="st1" d="M70.99,143.6c0.95,0.01,1.58,0.62,1.6,1.55c0.02,0.95-0.68,1.67-1.62,1.66c-0.91,0-1.65-0.78-1.61-1.69
    C69.38,144.22,70.07,143.59,70.99,143.6z"/>
    <path class="st1" d="M76.65,146.8c-0.87-0.04-1.56-0.78-1.54-1.65c0.02-0.93,0.75-1.6,1.71-1.56c0.91,0.04,1.57,0.72,1.55,1.62
    C78.36,146.11,77.56,146.85,76.65,146.8z"/>
    <path class="st3" d="M157.5,63.15c0.86,0.03,1.55,0.75,1.56,1.62c0,0.93-0.72,1.62-1.67,1.59c-0.92-0.03-1.63-0.8-1.57-1.7
    C155.87,63.84,156.67,63.13,157.5,63.15z"/>
    <path class="st3" d="M117.1,39.88c0.92,0,1.58,0.65,1.6,1.56c0.01,0.97-0.69,1.66-1.64,1.63c-0.88-0.02-1.58-0.75-1.56-1.63
    C115.5,40.56,116.2,39.88,117.1,39.88z"/>
    <path class="st2" d="M85.33,28.57c-0.9-0.01-1.57-0.67-1.57-1.55c0-0.9,0.65-1.55,1.56-1.56c0.96-0.01,1.67,0.68,1.64,1.61
    C86.94,27.94,86.25,28.58,85.33,28.57z"/>
    <path class="st1" d="M55.33,65.2c-0.47,0.29-0.76,0.74-1.09,1.16c-0.86,0.15-1.59,0-2.01-0.88c-0.32-0.67-0.21-1.3,0.29-1.81
    c0.61-0.62,1.34-0.66,2.09-0.23c0.12,0.09,0.23,0.18,0.34,0.27c0.06,0.07,0.12,0.15,0.18,0.22l-0.02-0.02
    C55.24,64.33,55.38,64.75,55.33,65.2z"/>
    <path class="st2" d="M50.81,51.69c-0.87,0.03-1.59-0.65-1.62-1.54c-0.03-0.91,0.7-1.69,1.59-1.69c0.85,0,1.56,0.72,1.58,1.6
    C52.37,50.94,51.69,51.66,50.81,51.69z"/>
    <path class="st2" d="M56.64,106.05c0.88,0.24,1.53,0.72,1.49,1.72c-0.04,0.92-0.64,1.41-1.5,1.59c-0.46,0.05-0.82-0.43-1.3-0.24
    c0,0,0.01,0.01,0.01,0.01c-0.26-0.79-0.59-1.58-0.22-2.43c0,0-0.02,0.02-0.02,0.02c0.17,0.01,0.23-0.09,0.24-0.24l-0.02,0.02
    c0.15-0.01,0.25-0.07,0.24-0.24l-0.02,0.02C55.91,106.21,56.27,106.13,56.64,106.05z"/>
    <path class="st2" d="M52.36,73.11c0.01,0.88-0.67,1.6-1.54,1.63c-0.92,0.03-1.64-0.69-1.62-1.64c0.02-0.92,0.66-1.55,1.58-1.55
    C51.68,71.55,52.35,72.21,52.36,73.11z"/>
    <path class="st3" d="M138.34,103.48c0.15-0.16,0.3-0.32,0.45-0.47c1.56-0.65,2.71-0.37,3.26,0.81c0.41,0.88,0.3,1.74-0.37,2.43
    c-0.66,0.68-1.5,0.87-2.4,0.47c-0.91-0.4-1.32-1.13-1.26-2.09C138.05,104.24,138.23,103.87,138.34,103.48z M140.17,106.25
    c0.76-0.01,1.41-0.69,1.42-1.48c0-0.78-0.66-1.48-1.4-1.5c-0.82-0.01-1.48,0.68-1.46,1.54
    C138.74,105.63,139.37,106.26,140.17,106.25z"/>
    <path class="st2" d="M96.21,132.29c-0.73-0.44-1.07-1.1-0.87-1.92c0.15-0.63,0.59-1.06,1.28-1.16c1-0.14,1.53,0.44,1.92,1.25
    C98.27,132.1,97.77,132.49,96.21,132.29z"/>
    <path class="st2" d="M50.72,45.92c-0.87-0.02-1.54-0.73-1.53-1.63c0.01-0.91,0.66-1.55,1.57-1.55c0.96,0,1.65,0.72,1.6,1.64
    C52.31,45.26,51.58,45.94,50.72,45.92z"/>
    <path class="st2" d="M55.22,97.33c-0.06,0-0.09-0.03-0.09-0.09c-0.01-0.16,0.01-0.35-0.24-0.34l0,0.02
    c0.08-0.59,0.16-1.18,0.24-1.77c0,0-0.02,0-0.02,0c0.08-0.08,0.16-0.16,0.24-0.23l-0.02,0.02c0.08-0.08,0.16-0.16,0.23-0.24
    l-0.02,0.02c0.36-0.05,0.72-0.11,1.08-0.16c0.92,0.19,1.55,0.69,1.5,1.68c-0.04,0.9-0.58,1.45-1.5,1.57
    c-0.36-0.09-0.73-0.17-1.09-0.26l0.02,0.02C55.51,97.39,55.36,97.36,55.22,97.33z"/>
    <path class="st3" d="M153.25,58.77c0.02,0.84-0.74,1.62-1.57,1.61c-0.8-0.01-1.53-0.71-1.57-1.51c-0.04-0.86,0.65-1.62,1.52-1.66
    C152.49,57.18,153.23,57.9,153.25,58.77z"/>
    <path class="st2" d="M55.38,103.21c-0.06-0.06-0.13-0.13-0.19-0.19c-0.28-0.56-0.21-1.19-0.31-1.79c0,0,0,0.02,0,0.02
    c0.24,0.09,0.21-0.1,0.24-0.23l-0.02,0.02c0.08-0.08,0.16-0.16,0.24-0.24c0,0-0.02,0.02-0.02,0.02c0.08-0.08,0.16-0.16,0.24-0.24
    c0,0-0.02,0.02-0.02,0.02c0.36-0.09,0.71-0.19,1.07-0.28c0.88,0.14,1.47,0.64,1.51,1.53c0.05,1.02-0.65,1.51-1.54,1.78
    C56.19,103.5,55.78,103.35,55.38,103.21z"/>
    <path class="st1" d="M111.33,129.44c-0.87,0.01-1.61-0.72-1.58-1.58c0.02-0.79,0.74-1.49,1.56-1.5c0.84-0.01,1.53,0.64,1.57,1.47
    C112.92,128.7,112.22,129.43,111.33,129.44z"/>
    <path class="st2" d="M50.77,80.46c-0.8,0.01-1.51-0.68-1.54-1.5c-0.03-0.88,0.66-1.6,1.53-1.61c0.84,0,1.51,0.65,1.53,1.49
    C52.32,79.72,51.63,80.45,50.77,80.46z"/>
    <path class="st2" d="M118.99,45.78c-0.07-0.09-0.14-0.18-0.2-0.26c-0.17-0.35-0.36-0.7-0.37-1.12c-0.02-0.64,0.21-1.14,0.79-1.44
    c0.61-0.31,1.21-0.26,1.72,0.19c0.47,0.41,0.65,0.93,0.51,1.57c-0.06,0.28-0.46,0.51-0.17,0.86l0.01-0.01
    C120.52,45.67,119.77,45.93,118.99,45.78z"/>
    <path class="st3" d="M101.15,40.01c0,0,0.02,0.01,0.02,0.01c-0.14,0.69,0.52,1.28,0.27,1.99c-0.38,0.37-0.76,0.75-1.14,1.12
    c-0.26,0.02-0.52,0.04-0.79,0.06c-0.31-0.65-0.86-1.07-1.4-1.5c0.08-0.41,0.17-0.82,0.25-1.23c0.1-0.1,0.21-0.21,0.31-0.31
    c0.16-0.02,0.3-0.06,0.34-0.24L99,39.9C99.73,39.73,100.43,40.06,101.15,40.01z M100.16,41.47c-0.05-0.24-0.19-0.37-0.45-0.31
    c-0.21,0.04-0.29,0.19-0.27,0.38c0.03,0.25,0.2,0.36,0.44,0.32C100.11,41.83,100.15,41.66,100.16,41.47z"/>
    <path class="st2" d="M138.65,62.76c0,0-0.02,0.01-0.02,0.01c-0.3-0.14-0.33,0.04-0.33,0.27l0.01-0.01
    c-0.58,0.08-1.16,0.15-1.75,0.23l0.01,0.02c0.01-0.25-0.16-0.24-0.33-0.24c-0.02-0.05-0.05-0.08-0.09-0.09
    c0-0.17-0.07-0.29-0.25-0.33l0.01,0.02c-0.2-0.61-0.26-1.22,0.03-1.82c0.17-0.19,0.35-0.39,0.52-0.58
    c0.64-0.03,1.28-0.03,1.86,0.31l0.01,0C138.85,61.22,138.65,62.01,138.65,62.76z"/>
    <path class="st2" d="M50.72,37.03c0.9-0.02,1.54,0.56,1.57,1.44c0.04,0.91-0.63,1.62-1.53,1.61c-0.85-0.01-1.53-0.67-1.54-1.51
    C49.21,37.71,49.85,37.05,50.72,37.03z"/>
    <path class="st2" d="M50.78,86.16c-0.83,0.01-1.54-0.71-1.51-1.54c0.02-0.78,0.7-1.45,1.49-1.45c0.8-0.01,1.47,0.63,1.51,1.42
    C52.3,85.4,51.6,86.15,50.78,86.16z"/>
    <path class="st2" d="M63.8,21.29c-0.02,0.78-0.7,1.45-1.48,1.46c-0.84,0.01-1.55-0.7-1.53-1.52c0.02-0.84,0.74-1.5,1.58-1.45
    C63.16,19.82,63.82,20.52,63.8,21.29z"/>
    <path class="st3" d="M134.38,45.68c0.78-0.01,1.47,0.64,1.5,1.44c0.03,0.79-0.61,1.48-1.41,1.52c-0.82,0.04-1.57-0.67-1.57-1.48
    C132.9,46.39,133.59,45.7,134.38,45.68z"/>
    <path class="st1" d="M47.95,106.25c-0.77,0.02-1.49-0.66-1.52-1.43c-0.03-0.74,0.65-1.5,1.41-1.54c0.81-0.05,1.57,0.67,1.56,1.49
    C49.4,105.54,48.73,106.23,47.95,106.25z"/>
    <path class="st0" d="M49.4,99.03c0.02,0.79-0.62,1.47-1.42,1.51c-0.81,0.04-1.57-0.68-1.55-1.49c0.01-0.78,0.69-1.45,1.48-1.45
    C48.69,97.58,49.39,98.25,49.4,99.03z"/>
    <path class="st3" d="M153.16,99.08c-0.02,0.79-0.69,1.44-1.49,1.43c-0.8-0.01-1.46-0.66-1.46-1.46c-0.01-0.82,0.73-1.54,1.55-1.5
    C152.52,97.6,153.18,98.31,153.16,99.08z"/>
    <path class="st2" d="M69.52,21.4c-0.07,0.81-0.75,1.4-1.56,1.34c-0.83-0.06-1.47-0.81-1.39-1.64c0.08-0.81,0.75-1.37,1.58-1.32
    C69.03,19.84,69.6,20.51,69.52,21.4z"/>
    <path class="st1" d="M65.26,146.65c-0.8,0.02-1.48-0.61-1.52-1.4c-0.04-0.83,0.64-1.53,1.49-1.52c0.81,0.01,1.45,0.64,1.46,1.44
    C66.7,145.96,66.05,146.63,65.26,146.65z"/>
    <path class="st1" d="M49.4,93.3c0.01,0.79-0.61,1.43-1.43,1.45c-0.84,0.02-1.49-0.54-1.54-1.35c-0.05-0.82,0.64-1.53,1.48-1.53
    C48.72,91.88,49.39,92.51,49.4,93.3z"/>
    <path class="st1" d="M47.9,89.07c-0.79-0.01-1.47-0.68-1.47-1.45c0-0.77,0.67-1.44,1.47-1.45c0.86-0.01,1.54,0.67,1.51,1.51
    C49.37,88.47,48.72,89.08,47.9,89.07z"/>
    <path class="st1" d="M53.62,132.16c0.83-0.03,1.47,0.54,1.51,1.36c0.05,0.86-0.59,1.55-1.44,1.55c-0.81,0-1.43-0.61-1.45-1.43
    C52.23,132.79,52.79,132.19,53.62,132.16z"/>
    <path class="st2" d="M141.54,66.88c0.2-0.41,0.52-0.71,0.89-0.96c0.44-0.03,0.88-0.06,1.3,0.1c0.24,0.27,0.47,0.54,0.71,0.81
    c0,0.42-0.01,0.84-0.01,1.26l0.01-0.01c-0.16,0-0.2,0.11-0.23,0.24l0.01-0.02c-0.08,0.08-0.16,0.17-0.23,0.25
    c0,0,0.03-0.02,0.03-0.02c-0.08,0.08-0.17,0.15-0.25,0.23l0.02-0.01c-0.57,0.09-1.14,0.12-1.7-0.08
    c-0.11-0.12-0.22-0.24-0.33-0.36C141.52,67.85,141.53,67.36,141.54,66.88z"/>
    <path class="st3" d="M138.98,101.27c-0.28-0.45-0.66-0.8-1.1-1.1c-0.11-0.5-0.28-0.99-0.21-1.52c0.2-0.07,0.41-0.14,0.61-0.21
    c0,0.22-0.02,0.44,0,0.65c0.06,1.05,0.69,1.73,1.66,1.81c1.07,0.09,1.92-0.52,2.08-1.49c0.17-1.02-0.35-1.93-1.33-2.2
    c-0.37-0.1-0.79-0.05-1.18-0.06c0.05-0.19,0.1-0.37,0.15-0.56c0.68-0.09,1.31,0.1,1.94,0.3l-0.02-0.01
    c0.08,0.11,0.17,0.22,0.25,0.33c0.04-0.01,0.08,0,0.12,0.04c0.86,1.36,0.91,2.32,0.17,3.31
    C141.45,101.46,140.43,101.7,138.98,101.27z"/>
    <path class="st2" d="M52.07,89.25c-0.35,0.27,0.01,0.5,0.06,0.74c0.18,0.77-0.04,1.36-0.74,1.73c-0.63,0.33-1.2,0.18-1.67-0.33
    c-0.47-0.51-0.53-1.12-0.17-1.7c0.42-0.67,1.06-0.8,1.8-0.57C51.58,89.19,51.77,89.57,52.07,89.25
    C52.07,89.25,52.07,89.25,52.07,89.25z"/>
    <path class="st3" d="M163.4,77.43c-0.98,0.02-1.66-0.61-1.61-1.43c0.05-0.84,0.5-1.35,1.38-1.38c0.83-0.03,1.51,0.61,1.45,1.41
    C164.57,76.87,164.1,77.36,163.4,77.43z"/>
    <path class="st3" d="M158.85,93.32c0,0.84-0.62,1.45-1.46,1.4c-0.87-0.05-1.34-0.56-1.34-1.41c0-0.85,0.5-1.35,1.35-1.4
    C158.23,91.86,158.85,92.49,158.85,93.32z"/>
    <path class="st3" d="M147.29,52.85c0.02,0.92-0.65,1.63-1.44,1.56c-0.82-0.07-1.35-0.52-1.39-1.39c-0.03-0.81,0.63-1.48,1.45-1.44
    C146.77,51.63,147.21,52.14,147.29,52.85z"/>
    <path class="st2" d="M60.8,130.54c0.23-0.55,0.55-1.03,1.2-1.12c0.7-0.1,1.32,0.05,1.61,0.77c0.38,0.95-0.07,1.59-0.9,2.03
    C61.36,132.17,61.07,131.92,60.8,130.54z"/>
    <path class="st2" d="M56.68,112c0.68,0.26,1.27,0.61,1.27,1.44c0.01,0.86-0.55,1.29-1.29,1.55c-1.54-0.43-1.71-0.76-1.34-2.62
    l0,0.02C55.77,112.26,56.22,112.13,56.68,112z"/>
    <path class="st1" d="M47.92,83.25c-0.8-0.12-1.35-0.53-1.34-1.42c0.02-0.87,0.51-1.35,1.38-1.34c0.89,0,1.35,0.54,1.36,1.37
    C49.32,82.73,48.77,83.16,47.92,83.25z"/>
    <path class="st2" d="M104.02,32.84c-0.05,0.74-0.51,1.28-1.34,1.31c-0.85,0.04-1.33-0.52-1.42-1.33c-0.08-0.71,0.6-1.39,1.34-1.42
    C103.35,31.37,104,31.98,104.02,32.84z"/>
    <path class="st2" d="M141.96,97.25c-0.03-0.04-0.07-0.05-0.12-0.04c0.01-0.18-0.01-0.34-0.25-0.33l0.02,0.01
    c0.06-0.49,0.12-0.98,0.18-1.46c0.14-0.14,0.29-0.27,0.43-0.41c0.1-0.09,0.35-0.07,0.24-0.32l-0.01,0.01
    c0.45,0.11,0.9,0.22,1.35,0.33c0.04-0.02,0.07-0.02,0.1,0c0.42,0.66,0.73,1.35,0.11,2.05C143.4,97.78,142.67,97.59,141.96,97.25z"
    />
    <path class="st1" d="M94.02,138.05c0.83,0.06,1.37,0.48,1.37,1.35c0,0.85-0.46,1.37-1.34,1.38c-0.87,0.01-1.36-0.48-1.39-1.33
    C92.64,138.58,93.16,138.14,94.02,138.05z"/>
    <path class="st3" d="M98.57,36.63c0.08-0.4-0.3-0.74-0.15-1.16c0.27-0.79,0.77-1.23,1.63-1.07c0.81,0.15,1.18,0.72,1.08,1.5
    c-0.1,0.8-0.63,1.16-1.43,1.2c-0.31,0.01-0.58-0.36-0.9-0.08c0,0,0.01,0.03,0.01,0.03c0.2-0.31,0.01-0.39-0.25-0.44L98.57,36.63z"
    />
    <path class="st1" d="M47.83,112.01c-0.87-0.1-1.36-0.56-1.34-1.42c0.02-0.87,0.56-1.35,1.4-1.33c0.84,0.02,1.35,0.53,1.32,1.41
    C49.19,111.53,48.67,111.95,47.83,112.01z"/>
    <path class="st3" d="M124.25,41.45c-0.11,0.91-0.58,1.42-1.43,1.39c-0.83-0.03-1.34-0.54-1.31-1.4c0.03-0.85,0.54-1.32,1.4-1.31
    C123.79,40.14,124.14,40.72,124.25,41.45z"/>
    <path class="st2" d="M72.46,21.33c0.03-0.91,0.53-1.37,1.34-1.46c0.7-0.08,1.38,0.62,1.4,1.35c0.02,0.74-0.62,1.44-1.34,1.41
    C73.03,22.59,72.53,22.15,72.46,21.33z"/>
    <path class="st1" d="M54.92,59.55c-0.15,0.15-0.3,0.3-0.45,0.45c-0.12,0.03-0.25,0.05-0.24,0.22l0.02-0.01
    c-0.18-0.01-0.35-0.03-0.53-0.03c-0.65,0-1.09-0.31-1.33-0.89c-0.22-0.55-0.1-1.07,0.33-1.47c0.54-0.51,1.15-0.51,1.78-0.18
    c0.07,0.06,0.14,0.12,0.21,0.17c0.07,0.08,0.13,0.16,0.2,0.24l-0.02-0.02C55.11,58.52,55.12,59.03,54.92,59.55z"/>
    <path class="st2" d="M118.67,118.56c0.23-0.24,0.46-0.47,0.68-0.71c0.54,0.03,1.09-0.03,1.53,0.41c0.47,0.48,0.51,1.02,0.26,1.6
    c-0.25,0.58-0.75,0.79-1.33,0.73c-0.64-0.06-1.1-0.4-1.19-1.07C118.58,119.21,118.65,118.88,118.67,118.56z"/>
    <path class="st2" d="M52.1,32.75c0.03,0.91-0.51,1.33-1.31,1.42c-0.72,0.08-1.38-0.61-1.37-1.36c0.01-0.86,0.48-1.37,1.32-1.39
    C51.58,31.4,52.06,31.89,52.1,32.75z"/>
    <path class="st3" d="M147.28,104.72c-0.06,0.86-0.46,1.36-1.34,1.38c-0.88,0.02-1.37-0.5-1.38-1.32
    c-0.01-0.82,0.45-1.35,1.33-1.37C146.77,103.41,147.19,103.89,147.28,104.72z"/>
    <path class="st3" d="M164.55,70.5c-0.06,0.71-0.55,1.13-1.42,1.1c-0.84-0.03-1.24-0.53-1.26-1.33c-0.02-0.8,0.58-1.37,1.37-1.36
    C164.03,68.93,164.58,69.52,164.55,70.5z"/>
    <path class="st2" d="M135.93,56.71c0.01-0.03,0.03-0.07,0.03-0.1c-0.12-0.76-0.21-1.55,0.63-1.97c0.5-0.25,1.05-0.25,1.51,0.15
    c0.77,0.65,0.41,1.41,0.21,2.16c0,0,0.01-0.01,0.01-0.01c-0.64,0.25-1.3,0.27-1.97,0.2l0.01,0.01c0.05-0.21-0.05-0.26-0.23-0.23
    l0.01,0.01c0.02-0.18-0.02-0.29-0.23-0.23L135.93,56.71z"/>
    <path class="st2" d="M142.03,60.45c0.2-0.04,0.41-0.07,0.61-0.12c0.64-0.16,1.15,0.06,1.48,0.61c0.34,0.56,0.25,1.12-0.19,1.59
    c-0.42,0.44-0.96,0.51-1.49,0.27c-0.61-0.26-0.8-0.79-0.73-1.42c0.03-0.24,0.08-0.48,0.13-0.72l-0.02,0.01
    c0.2,0.05,0.28-0.03,0.23-0.23L142.03,60.45z"/>
    <path class="st2" d="M130.47,51.24c0.13-0.29-0.17-0.44-0.23-0.68c-0.17-0.66-0.07-1.23,0.53-1.61c0.55-0.35,1.11-0.29,1.59,0.16
    c0.47,0.44,0.59,0.99,0.27,1.57c-0.34,0.61-0.87,0.82-1.56,0.65C130.87,51.29,130.69,51.11,130.47,51.24L130.47,51.24z"/>
    <path class="st2" d="M114.2,39.87c-0.8-0.06-1.31-0.47-1.33-1.29c-0.02-0.81,0.42-1.3,1.26-1.33c0.84-0.02,1.33,0.47,1.35,1.25
    C115.5,39.29,115.03,39.78,114.2,39.87z"/>
    <path class="st1" d="M129.92,116.29c-0.07,0.88-0.49,1.37-1.3,1.37c-0.81,0-1.29-0.47-1.29-1.28c0-0.81,0.48-1.3,1.28-1.3
    C129.42,115.07,129.83,115.57,129.92,116.29z"/>
    <path class="st1" d="M81.2,145.25c0.06-0.87,0.51-1.37,1.3-1.38c0.79,0,1.29,0.47,1.29,1.29c0,0.81-0.46,1.28-1.27,1.29
    C81.67,146.48,81.3,145.96,81.2,145.25z"/>
    <path class="st3" d="M93.16,36.83c-0.09-0.09-0.18-0.18-0.26-0.27c-0.26-0.64-0.35-1.28,0.21-1.8c0.47-0.44,1.04-0.49,1.6-0.16
    c0.52,0.31,0.72,0.81,0.6,1.39c-0.13,0.64-0.56,1.02-1.2,1.05C93.79,37.05,93.48,36.91,93.16,36.83z"/>
    <path class="st3" d="M106.88,35.75c-0.09,0.81-0.54,1.26-1.33,1.3c-0.76,0.04-1.36-0.62-1.3-1.4c0.06-0.82,0.54-1.25,1.35-1.22
    C106.42,34.45,106.81,34.96,106.88,35.75z"/>
    <path class="st2" d="M51.85,95.14c-0.12,0.32,0.19,0.52,0.21,0.81c0.05,0.64-0.14,1.15-0.74,1.44c-0.56,0.27-1.08,0.13-1.5-0.28
    c-0.42-0.42-0.51-0.95-0.23-1.5c0.3-0.61,0.83-0.74,1.46-0.69C51.34,94.93,51.53,95.27,51.85,95.14L51.85,95.14z"/>
    <path class="st1" d="M47.93,74.72c0.8,0.07,1.29,0.5,1.28,1.31c-0.01,0.8-0.47,1.25-1.3,1.25c-0.84,0-1.28-0.49-1.28-1.28
    C46.62,75.2,47.12,74.77,47.93,74.72z"/>
    <path class="st2" d="M91.05,25.69c0.83,0.06,1.35,0.47,1.37,1.28c0.02,0.8-0.42,1.32-1.25,1.34c-0.82,0.02-1.31-0.43-1.34-1.25
    C89.8,26.25,90.27,25.81,91.05,25.69z"/>
    <path class="st1" d="M49.24,116.31c-0.06,0.8-0.45,1.31-1.27,1.33c-0.82,0.03-1.31-0.44-1.34-1.24c-0.03-0.8,0.45-1.3,1.25-1.34
    C48.69,115.04,49.12,115.53,49.24,116.31z"/>
    <path class="st3" d="M132.85,109.14c0.33,0.1,0.52-0.21,0.78-0.29c0.95-0.3,1.74-0.1,2.27,0.74c0.53,0.83,0.44,1.66-0.25,2.38
    c-0.63,0.66-1.65,0.76-2.38,0.23c-0.79-0.58-1.04-1.34-0.71-2.29c0.09-0.26,0.41-0.44,0.3-0.78L132.85,109.14z M134.31,109.14
    c-0.78,0.02-1.45,0.71-1.45,1.49c0,0.81,0.64,1.42,1.48,1.41c0.84-0.01,1.44-0.61,1.43-1.45
    C135.76,109.78,135.1,109.13,134.31,109.14z"/>
    <path class="st3" d="M141.37,110.55c-0.03,0.86-0.51,1.26-1.22,1.36c-0.65,0.09-1.39-0.62-1.35-1.29
    c0.05-0.74,0.45-1.24,1.23-1.27C140.86,109.33,141.27,109.82,141.37,110.55z"/>
    <path class="st2" d="M101.41,130.27c0.22-0.24,0.45-0.48,0.67-0.72c0.36-0.07,0.72-0.09,1.06,0.08c0.57,0.29,0.85,0.74,0.72,1.4
    c-0.12,0.62-0.53,0.94-1.12,0.99c-0.66,0.07-1.1-0.31-1.3-0.91C101.36,130.87,101.42,130.56,101.41,130.27z"/>
    <path class="st1" d="M106.77,133.61c-0.06,0.81-0.52,1.26-1.26,1.23c-0.74-0.03-1.19-0.52-1.15-1.3c0.04-0.72,0.48-1.14,1.19-1.14
    C106.32,132.41,106.73,132.86,106.77,133.61z"/>
    <path class="st2" d="M78.27,136.39c0.81-1.48,1.58-1.5,2.75-0.05c-0.16,0.38-0.38,0.75-0.34,1.2l0.03,0.01
    c-0.65,0.22-1.33,0.23-2,0.19c0,0,0.01,0.02,0.01,0.02c0.07-0.23-0.01-0.31-0.24-0.24l0.02,0.02
    C78.63,137.11,78.21,136.79,78.27,136.39z"/>
    <path class="st2" d="M56.4,20c0.86,0.03,1.31,0.42,1.36,1.19c0.05,0.84-0.42,1.3-1.24,1.3c-0.76,0-1.21-0.42-1.22-1.22
    C55.3,20.48,55.74,20.1,56.4,20z"/>
    <path class="st2" d="M56.8,117.94c1.33,0.83,1.33,1.78,0.02,2.58c-0.79-0.01-1.45-0.21-1.51-1.15
    C55.24,118.31,55.93,118.02,56.8,117.94z"/>
    <path class="st2" d="M147.72,83.69c0.06,0,0.09-0.04,0.1-0.09c0.21,0.05,0.37,0.04,0.33-0.24c0,0-0.01,0.01-0.01,0.01
    c0.51,0.07,1.02,0.14,1.53,0.22l-0.01-0.01c-0.08,0.23,0,0.31,0.23,0.23c0,0-0.01-0.01-0.01-0.01c-0.16,0.21,0.01,0.41,0.03,0.61
    c0.07,1.13-0.58,1.65-1.66,1.36c-0.17-0.05-0.35-0.31-0.54-0.02c0,0,0.01,0.02,0.01,0.02C147.69,85.07,147.43,84.38,147.72,83.69z
    "/>
    <path class="st2" d="M84.11,136.32c0.15-0.63,0.52-0.98,1.19-1c0.72-0.02,1.1,0.39,1.34,1c-0.24,1.44-0.79,1.73-2.28,1.21
    l0.02,0.01C84.25,137.14,84.07,136.75,84.11,136.32z"/>
    <path class="st2" d="M149.87,77.9c-0.25,0.19-0.02,0.38,0.01,0.57c0.17,1.18-0.3,1.68-1.47,1.58c-0.17-0.01-0.33-0.14-0.49,0.02
    c0,0,0.01,0.01,0.01,0.01c0.1-0.25-0.01-0.3-0.23-0.23l0.01,0.01c-0.07-0.58-0.14-1.17-0.21-1.75l-0.01,0.01
    c0.43,0.14,0.54-0.06,0.45-0.45l-0.01,0.02c0.65,0.07,1.31,0.14,1.96,0.21L149.87,77.9z"/>
    <path class="st0" d="M47.93,123.35c-0.78-0.03-1.21-0.47-1.21-1.23c0-0.7,0.39-1.16,1.14-1.19c0.79-0.03,1.27,0.43,1.29,1.17
    C49.18,122.83,48.73,123.3,47.93,123.35z"/>
    <path class="st2" d="M137.3,108.92c-0.77-0.02-1.22-0.4-1.26-1.11c-0.04-0.76,0.38-1.25,1.16-1.28c0.72-0.02,1.18,0.39,1.21,1.11
    C138.45,108.38,138.04,108.84,137.3,108.92z"/>
    <path class="st2" d="M51.96,101.9c-0.03,0.82-0.48,1.27-1.22,1.26c-0.7,0-1.15-0.47-1.16-1.18c-0.01-0.76,0.42-1.2,1.22-1.19
    C51.55,100.81,51.91,101.22,51.96,101.9z"/>
    <path class="st2" d="M50.79,25.82c0.8,0.1,1.19,0.53,1.16,1.24c-0.03,0.78-0.54,1.19-1.29,1.14c-0.71-0.05-1.14-0.53-1.1-1.23
    C49.61,26.22,50.1,25.83,50.79,25.82z"/>
    <path class="st2" d="M89.84,136.38c0.82-1.38,1.55-1.44,2.46-0.21c0.07,0.76-0.08,1.42-0.97,1.55
    C90.35,137.85,89.96,137.24,89.84,136.38z"/>
    <path class="st2" d="M124.52,44.2c0.04-0.63,0.41-1.03,1.15-1.05c0.81-0.02,1.23,0.45,1.23,1.19c0,0.76-0.53,1.21-1.27,1.18
    C124.95,45.48,124.53,45.03,124.52,44.2z"/>
    <path class="st3" d="M158.63,58.89c-0.09,0.68-0.51,1.1-1.21,1.08c-0.71-0.02-1.16-0.47-1.14-1.18c0.02-0.75,0.49-1.22,1.26-1.16
    C158.24,57.67,158.61,58.13,158.63,58.89z"/>
    <path class="st3" d="M162.07,81.85c0.04-0.7,0.4-1.14,1.15-1.14c0.74-0.01,1.17,0.42,1.18,1.13c0.01,0.72-0.43,1.17-1.15,1.18
    C162.53,83.03,162.12,82.61,162.07,81.85z"/>
    <path class="st0" d="M60.62,47.69c-0.24,0.23-0.49,0.46-0.73,0.7c-0.45,0.06-0.87-0.08-1.29-0.2l0.02,0.02
    c-0.01-0.15-0.09-0.23-0.24-0.24c0,0,0.01,0.02,0.01,0.02c-0.07-0.44-0.14-0.88-0.21-1.32l-0.01,0.02
    c0.17,0.01,0.19-0.13,0.23-0.24c0,0-0.02,0.02-0.02,0.02c0.08-0.08,0.16-0.16,0.23-0.24c0,0-0.02,0.01-0.02,0.01
    c0.38-0.29,0.83-0.25,1.27-0.26c0.26,0.25,0.52,0.5,0.78,0.76C60.63,47.05,60.63,47.37,60.62,47.69z"/>
    <path class="st0" d="M124,122.14c-0.09,0.7-0.47,1.15-1.19,1.13c-0.65-0.02-1.07-0.42-1.09-1.1c-0.02-0.73,0.43-1.17,1.11-1.19
    C123.53,120.97,123.95,121.42,124,122.14z"/>
    <path class="st0" d="M53.72,140.58c-0.72-0.07-1.17-0.44-1.17-1.17c0-0.73,0.46-1.13,1.17-1.12c0.68,0.01,1.09,0.45,1.11,1.09
    C54.86,140.09,54.42,140.5,53.72,140.58z"/>
    <path class="st2" d="M113.4,123.97c0.25-0.04,0.5-0.11,0.74-0.1c0.56,0.01,0.95,0.28,1.13,0.83c0.17,0.53-0.04,0.92-0.44,1.23
    c-0.42,0.33-0.88,0.32-1.31,0.03c-0.47-0.32-0.62-0.79-0.44-1.33c0.08-0.23,0.22-0.45,0.33-0.67L113.4,123.97z"/>
    <path class="st2" d="M72.61,136.39c0.23-0.58,0.6-1.03,1.27-1c0.62,0.02,0.95,0.46,1.13,1.02c-0.05,0.75-0.41,1.27-1.2,1.28
    C72.99,137.69,72.73,137.09,72.61,136.39z"/>
    <path class="st2" d="M131.51,112.37c0.69,0.03,1.11,0.43,1.09,1.16c-0.02,0.67-0.42,1.08-1.12,1.08
    c-0.76,0.01-1.16-0.46-1.15-1.16C130.33,112.79,130.74,112.38,131.51,112.37z"/>
    <path class="st3" d="M141.28,47.13c-0.03,0.73-0.43,1.16-1.14,1.16c-0.66,0-1.1-0.43-1.11-1.09c-0.01-0.71,0.43-1.16,1.15-1.16
    C140.83,46.04,141.22,46.44,141.28,47.13z"/>
    <path class="st2" d="M149.66,72c-0.26,0.23,0.02,0.37,0.09,0.54c0.24,0.56,0.22,1.07-0.27,1.47c-0.44,0.36-0.95,0.37-1.4,0.03
    c-0.51-0.37-0.56-0.9-0.34-1.46c0.05-0.13,0.11-0.26,0.17-0.39l-0.01,0.01C148.45,71.82,149.09,72.2,149.66,72
    C149.66,72.01,149.66,72,149.66,72z"/>
    <path class="st1" d="M54.69,53.65c-0.57,0.45-1.18,0.76-1.82,0.17c-0.45-0.41-0.51-0.93-0.17-1.46c0.39-0.61,0.98-0.48,1.55-0.44
    l-0.02-0.01c0.12,0.15,0.19,0.37,0.46,0.23l-0.02-0.02c-0.04,0.2-0.05,0.36,0.23,0.24l-0.01-0.01c-0.27,0.4,0,0.89-0.21,1.31
    L54.69,53.65z"/>
    <path class="st3" d="M128.61,40.33c0.71,0.07,1.14,0.44,1.14,1.17c0,0.69-0.44,1.07-1.09,1.1c-0.66,0.02-1.11-0.37-1.14-1.04
    C127.47,40.84,127.88,40.43,128.61,40.33z"/>
    <path class="st2" d="M80.7,21.3c-0.05,0.69-0.44,1.09-1.11,1.09c-0.73-0.01-1.14-0.43-1.12-1.18c0.01-0.71,0.46-1.09,1.1-1.1
    C80.28,20.11,80.69,20.55,80.7,21.3z"/>
    <path class="st3" d="M92.7,40.67c0.14-0.12,0.28-0.24,0.42-0.36c0.66-0.37,1.31-0.24,1.97,0.02l-0.01-0.01
    c0,0.15,0.07,0.24,0.23,0.23l-0.01-0.01c0.07,0.41,0.15,0.82,0.22,1.22c-0.4,0.4-0.8,0.79-1.19,1.19c-0.19,0-0.39-0.01-0.58-0.01
    c-0.4-0.4-0.8-0.8-1.2-1.2C92.6,41.39,92.65,41.03,92.7,40.67z M94.78,41.49c-0.11-0.4-0.27-0.74-0.78-0.71
    c-0.48,0.03-0.71,0.31-0.69,0.78c0.02,0.44,0.31,0.66,0.69,0.68C94.46,42.25,94.72,41.96,94.78,41.49z"/>
    <path class="st3" d="M111.29,34.62c0.75,0,1.15,0.41,1.14,1.13c-0.01,0.66-0.4,1.07-1.08,1.09c-0.74,0.01-1.15-0.43-1.14-1.14
    C110.21,35.06,110.57,34.63,111.29,34.62z"/>
    <path class="st3" d="M164.33,87.51c-0.03,0.83-0.46,1.21-1.09,1.23c-0.7,0.02-1.15-0.42-1.14-1.15c0.01-0.68,0.44-1.1,1.08-1.11
    C163.88,86.47,164.29,86.91,164.33,87.51z"/>
    <path class="st1" d="M59.63,146.29c-0.89,0-1.28-0.4-1.3-1.06c-0.02-0.72,0.38-1.17,1.13-1.17c0.67,0,1.09,0.37,1.12,1.07
    C60.6,145.88,60.17,146.24,59.63,146.29z"/>
    <path class="st3" d="M163.26,65.9c-0.74-0.05-1.14-0.41-1.15-1.09c-0.01-0.73,0.39-1.14,1.12-1.14c0.69,0,1.07,0.39,1.09,1.07
    C164.34,65.48,163.91,65.84,163.26,65.9z"/>
    <path class="st1" d="M49.05,70.26c-0.07,0.75-0.42,1.12-1.13,1.12c-0.77,0-1.14-0.44-1.12-1.16c0.02-0.68,0.43-1.07,1.12-1.07
    C48.68,69.15,49.01,69.61,49.05,70.26z"/>
    <path class="st2" d="M147.8,66.74l0.01-0.08l0.07-0.02c0.08-0.11,0.16-0.22,0.24-0.34l-0.02,0.01c0.25-0.01,0.5-0.04,0.75-0.01
    c0.73,0.1,1.1,0.56,0.95,1.27c-0.15,0.69-0.63,1-1.37,0.84c-0.24-0.05-0.44-0.4-0.74-0.14l0.01,0.01
    C147.89,67.78,147.51,67.24,147.8,66.74z"/>
    <path class="st2" d="M143.02,103.04c-0.71-0.04-1.09-0.42-1.1-1.1c0-0.63,0.37-1.03,1-1.07c0.69-0.04,1.13,0.34,1.15,1.02
    C144.09,102.56,143.7,102.98,143.02,103.04z"/>
    <path class="st2" d="M50.77,108.82c-0.65-0.07-1.09-0.41-1.09-1.1c0-0.68,0.44-1.09,1.11-1.08c0.62,0.01,1.02,0.37,1.04,1.02
    C51.86,108.37,51.46,108.73,50.77,108.82z"/>
    <path class="st2" d="M56.86,123.95c0.98,0.84,0.97,1.33-0.05,2.23c-0.72-0.11-1.4-0.34-1.34-1.24
    C55.53,124.12,56.12,123.9,56.86,123.95z"/>
    <path class="st2" d="M96.86,25.95c0.63,0.11,1.05,0.41,1.05,1.07c0,0.66-0.41,1.03-1.03,1.03c-0.63,0-1.03-0.4-1.03-1.04
    C95.85,26.37,96.27,26.05,96.86,25.95z"/>
    <path class="st3" d="M152.69,53.01c-0.07,0.64-0.44,1.03-1.06,1.02c-0.62-0.01-1.04-0.42-1.02-1.05c0.02-0.61,0.42-1.03,1.06-1.02
    C152.3,51.98,152.64,52.38,152.69,53.01z"/>
    <path class="st2" d="M109.47,32.73c-0.1,0.66-0.41,1.05-1.07,1.06c-0.68,0.01-1.01-0.42-1.03-1.02c-0.02-0.63,0.37-0.99,1.03-1
    C109.09,31.77,109.35,32.19,109.47,32.73z"/>
    <path class="st0" d="M48.9,64.79c-0.02,0.57-0.33,0.94-0.92,0.98c-0.6,0.04-0.98-0.31-1.03-0.89c-0.05-0.63,0.31-1.05,0.96-1.06
    C48.5,63.81,48.83,64.18,48.9,64.79z"/>
    <path class="st2" d="M66.94,136.43c0.83-1.23,1.46-1.24,2.17-0.03c-0.04,0.64-0.29,1.09-1,1.15
    C67.31,137.61,67.13,137.01,66.94,136.43z"/>
    <path class="st0" d="M48.9,127.87c-0.02,0.6-0.35,0.97-0.93,1c-0.64,0.03-1.03-0.36-1.03-1c0.01-0.59,0.38-0.94,0.97-0.94
    C48.52,126.93,48.85,127.27,48.9,127.87z"/>
    <path class="st3" d="M87.65,36.72c-0.11-0.11-0.23-0.22-0.34-0.33c-0.23-0.77-0.22-1.46,0.71-1.71c0.67-0.18,1.07,0.21,1.31,0.82
    c0.16,0.42-0.29,0.72-0.18,1.12l0.02-0.01c-0.22-0.07-0.32-0.02-0.23,0.23l0.01-0.01C88.52,36.79,88.08,36.76,87.65,36.72z
    M88.67,35.75c-0.04-0.22-0.14-0.4-0.39-0.41c-0.25-0.02-0.39,0.12-0.41,0.37c-0.02,0.25,0.13,0.4,0.37,0.42
    C88.48,36.14,88.61,35.98,88.67,35.75z"/>
    <path class="st2" d="M45.05,66.36c0.56,0.13,0.93,0.42,0.91,1.03c-0.02,0.61-0.36,0.94-0.96,0.93c-0.66-0.01-1-0.4-0.97-1.04
    C44.07,66.69,44.51,66.45,45.05,66.36z"/>
    <path class="st2" d="M49.78,113.5c0.07-0.61,0.37-0.99,0.97-0.98c0.64,0.01,1.01,0.39,0.97,1.04c-0.03,0.6-0.44,0.92-1.01,0.9
    C50.14,114.43,49.82,114.06,49.78,113.5z"/>
    <path class="st3" d="M135.38,116.27c-0.02,0.65-0.34,1.04-0.91,1.08c-0.64,0.04-1.05-0.35-1.05-0.98c0-0.57,0.33-0.93,0.96-0.95
    C135,115.41,135.3,115.74,135.38,116.27z"/>
    <path class="st2" d="M45.05,62.6c-0.6-0.03-0.97-0.35-1.01-0.93c-0.05-0.59,0.27-0.96,0.87-1.01c0.66-0.05,1.03,0.29,1.06,0.94
    C45.98,62.2,45.59,62.49,45.05,62.6z"/>
    <path class="st2" d="M120.87,38.53c0.01,0.61-0.29,0.94-0.91,0.96c-0.61,0.02-0.98-0.31-1.01-0.88c-0.03-0.63,0.36-1.05,0.98-1.04
    C120.49,37.57,120.87,37.91,120.87,38.53z"/>
    <path class="st2" d="M45.16,79.87c-0.72-0.01-1.09-0.32-1.12-0.9c-0.04-0.65,0.36-1.03,0.98-1.04c0.57-0.01,0.95,0.34,0.95,0.95
    C45.97,79.48,45.62,79.78,45.16,79.87z"/>
    <path class="st2" d="M45.97,73.17c-0.05,0.6-0.41,0.97-0.98,0.94c-0.6-0.03-1.02-0.41-0.96-1.07c0.05-0.59,0.43-0.89,1.03-0.86
    C45.69,72.22,45.95,72.6,45.97,73.17z"/>
    <path class="st2" d="M148.78,91.41c-0.62-0.05-1-0.41-1-0.95c-0.01-0.59,0.35-1.01,1.03-0.98c0.62,0.02,0.9,0.39,0.89,0.98
    C149.69,91.06,149.34,91.35,148.78,91.41z"/>
    <path class="st3" d="M118.01,35.7c-0.04,0.6-0.34,0.94-0.94,0.94c-0.55,0-0.88-0.32-0.92-0.85c-0.04-0.59,0.31-0.98,0.88-1
    C117.61,34.77,117.96,35.11,118.01,35.7z"/>
    <path class="st2" d="M45.95,50.07c-0.09,0.58-0.39,0.92-0.93,0.92c-0.6,0-0.93-0.38-0.92-0.97c0.01-0.55,0.38-0.88,0.9-0.88
    C45.58,49.16,45.89,49.54,45.95,50.07z"/>
    <path class="st2" d="M85.46,22.18c-0.6-0.03-0.99-0.3-1.02-0.84c-0.04-0.6,0.31-0.97,0.92-0.98c0.57-0.01,0.9,0.36,0.92,0.88
    C86.3,21.77,85.95,22.09,85.46,22.18z"/>
    <path class="st2" d="M125.66,120.17c-0.52-0.09-0.89-0.35-0.87-0.92c0.02-0.62,0.42-0.94,1-0.9c0.53,0.04,0.87,0.4,0.82,0.96
    C126.56,119.85,126.23,120.15,125.66,120.17z"/>
    <path class="st2" d="M45.94,84.62c-0.05,0.53-0.29,0.89-0.86,0.94c-0.56,0.04-0.93-0.22-0.98-0.79c-0.05-0.59,0.23-0.98,0.87-1
    C45.54,83.76,45.84,84.08,45.94,84.62z"/>
    <path class="st2" d="M143.95,55.82c-0.09,0.51-0.32,0.89-0.89,0.92c-0.55,0.02-0.94-0.26-0.97-0.81
    c-0.04-0.59,0.28-0.97,0.91-0.98C143.57,54.94,143.83,55.29,143.95,55.82z"/>
    <path class="st2" d="M45.95,55.86c-0.1,0.53-0.37,0.87-0.94,0.87c-0.56,0-0.91-0.3-0.91-0.86c0-0.59,0.31-0.97,0.95-0.93
    C45.61,54.98,45.87,55.34,45.95,55.86z"/>
    <path class="st2" d="M138.09,50.1c-0.05,0.56-0.38,0.86-0.93,0.83c-0.5-0.02-0.81-0.35-0.8-0.86c0.01-0.5,0.3-0.83,0.81-0.84
    C137.74,49.22,138.06,49.51,138.09,50.1z"/>
    <path class="st3" d="M94.9,29.84c-0.04,0.59-0.37,0.88-0.93,0.84c-0.57-0.04-0.85-0.42-0.8-0.96c0.04-0.49,0.36-0.78,0.88-0.77
    C94.64,28.96,94.91,29.27,94.9,29.84z"/>
    <path class="st1" d="M117.04,128.79c-0.6-0.06-0.85-0.39-0.81-0.95c0.04-0.58,0.38-0.86,0.93-0.82c0.56,0.04,0.86,0.41,0.82,0.95
    C117.94,128.51,117.58,128.79,117.04,128.79z"/>
    <path class="st3" d="M88.36,30.7c-0.58-0.07-0.94-0.32-0.95-0.9c0-0.52,0.27-0.82,0.79-0.85c0.56-0.04,0.93,0.23,0.97,0.8
    C89.21,30.33,88.82,30.58,88.36,30.7z"/>
    <path class="st2" d="M51.62,119.31c-0.05,0.5-0.33,0.77-0.84,0.76c-0.56-0.01-0.89-0.31-0.88-0.87c0.01-0.5,0.34-0.8,0.84-0.79
    C51.3,118.42,51.62,118.71,51.62,119.31z"/>
    <path class="st2" d="M45.89,44.28c-0.09,0.52-0.32,0.89-0.91,0.87c-0.52-0.01-0.8-0.31-0.81-0.82c-0.01-0.58,0.32-0.89,0.87-0.88
    C45.54,43.46,45.81,43.79,45.89,44.28z"/>
    <path class="st2" d="M96.94,137.41c-0.57-0.03-0.92-0.34-0.93-0.88c-0.01-0.5,0.31-0.83,0.81-0.84c0.47-0.01,0.84,0.2,0.89,0.74
    C97.76,137.01,97.47,137.31,96.94,137.41z"/>
    <path class="st2" d="M50.77,22.1c-0.56-0.08-0.92-0.36-0.87-0.93c0.05-0.48,0.36-0.77,0.89-0.74c0.58,0.03,0.86,0.33,0.82,0.9
    C51.58,21.86,51.24,22.08,50.77,22.1z"/>
    <path class="st3" d="M134.39,40.62c0.53,0.08,0.85,0.37,0.84,0.86c-0.01,0.48-0.28,0.82-0.81,0.82c-0.57,0-0.88-0.33-0.86-0.88
    C133.58,40.9,133.96,40.69,134.39,40.62z"/>
    <path class="st3" d="M145.89,46.34c0.52-0.01,0.83,0.29,0.87,0.77c0.05,0.49-0.23,0.83-0.75,0.87c-0.56,0.05-0.9-0.23-0.92-0.8
    C145.07,46.65,145.4,46.41,145.89,46.34z"/>
    <path class="st3" d="M99.78,28.97c0.56,0.04,0.87,0.32,0.86,0.82c-0.01,0.54-0.33,0.88-0.9,0.84c-0.5-0.04-0.78-0.35-0.75-0.87
    C99.02,29.26,99.31,28.99,99.78,28.97z"/>
    <path class="st2" d="M45.11,91.26c-0.66-0.03-0.94-0.29-0.94-0.81c0-0.58,0.32-0.87,0.87-0.86c0.51,0.01,0.82,0.35,0.83,0.83
    C45.88,90.94,45.53,91.24,45.11,91.26z"/>
    <path class="st2" d="M109.23,130.81c-0.11,0.52-0.4,0.81-0.91,0.76c-0.52-0.05-0.77-0.42-0.72-0.91c0.04-0.45,0.38-0.7,0.84-0.69
    C108.96,130,109.17,130.36,109.23,130.81z"/>
    <path class="st0" d="M89.07,145.2c-0.03,0.52-0.32,0.81-0.83,0.8c-0.5-0.01-0.8-0.34-0.79-0.84c0.01-0.51,0.35-0.81,0.83-0.81
    C88.79,144.35,89.05,144.69,89.07,145.2z"/>
    <path class="st0" d="M47.95,132.76c0.49,0.11,0.82,0.37,0.79,0.89c-0.03,0.51-0.36,0.8-0.86,0.78c-0.51-0.02-0.81-0.37-0.78-0.86
    C47.12,133.07,47.47,132.81,47.95,132.76z"/>
    <path class="st2" d="M45.81,96.15c-0.06,0.59-0.36,0.88-0.87,0.84c-0.51-0.04-0.77-0.38-0.73-0.89c0.04-0.47,0.34-0.72,0.79-0.72
    C45.53,95.39,45.75,95.74,45.81,96.15z"/>
    <path class="st1" d="M99.79,140.25c-0.49-0.06-0.81-0.31-0.8-0.83c0.01-0.51,0.31-0.8,0.82-0.8c0.52,0,0.79,0.31,0.79,0.81
    C100.6,139.94,100.29,140.21,99.79,140.25z"/>
    <path class="st1" d="M59.93,42.3c-0.64,0.02-1.37,0.02-1.28-0.87c0.09-0.95,0.87-0.72,1.49-0.63c0,0-0.01-0.01-0.01-0.01
    c0.07,0.36,0.15,0.72,0.22,1.07l0.01-0.01c-0.2-0.04-0.19,0.12-0.23,0.24l0.02-0.02c-0.08,0.08-0.16,0.16-0.24,0.23L59.93,42.3z"
    />
    <path class="st2" d="M45.03,37.72c0.51,0.09,0.79,0.38,0.77,0.89c-0.02,0.46-0.31,0.73-0.77,0.73c-0.51,0-0.8-0.29-0.81-0.81
    C44.2,37.98,44.58,37.81,45.03,37.72z"/>
    <path class="st2" d="M148.53,60.83c0.82,0.02,1.03,0.36,1.01,0.85c-0.03,0.46-0.31,0.71-0.78,0.7c-0.52-0.01-0.82-0.3-0.81-0.8
    C147.95,61.08,148.28,60.82,148.53,60.83z"/>
    <path class="st2" d="M62.97,135.77c-0.16,0.27,0.15,0.46,0.13,0.7c-0.03,0.5-0.24,0.83-0.78,0.86c-0.48,0.02-0.76-0.24-0.81-0.7
    c-0.06-0.52,0.22-0.83,0.75-0.87c0.24-0.02,0.49,0.01,0.73,0.02L62.97,135.77z"/>
    <path class="st2" d="M57.14,130.07l-0.02,0.02c0.07,0.2,0.18,0.41,0.19,0.61c0.03,0.51-0.24,0.83-0.75,0.86
    c-0.46,0.02-0.75-0.23-0.79-0.7c-0.05-0.53,0.23-0.82,0.74-0.87C56.71,129.97,56.92,130.04,57.14,130.07z"/>
    <path class="st3" d="M163.33,59.57c-0.61,0-0.89-0.29-0.89-0.8c0-0.45,0.25-0.74,0.74-0.75c0.54-0.01,0.83,0.31,0.84,0.78
    C164.01,59.23,163.72,59.53,163.33,59.57z"/>
    <path class="st3" d="M168.94,76.81c-0.5-0.08-0.77-0.36-0.73-0.88c0.04-0.48,0.34-0.69,0.81-0.68c0.47,0.02,0.73,0.28,0.74,0.75
    C169.76,76.53,169.45,76.77,168.94,76.81z"/>
    <path class="st0" d="M48.66,58.82c-0.07,0.45-0.32,0.74-0.8,0.71c-0.42-0.02-0.67-0.29-0.68-0.71c-0.01-0.47,0.25-0.77,0.72-0.78
    C48.4,58.04,48.61,58.36,48.66,58.82z"/>
    <path class="st0" d="M42.89,99.14c-0.06,0.46-0.34,0.66-0.81,0.63c-0.45-0.03-0.66-0.32-0.66-0.71c-0.01-0.47,0.29-0.75,0.76-0.73
    C42.64,98.35,42.89,98.63,42.89,99.14z"/>
    <path class="st0" d="M71.02,150.09c0.47,0.07,0.73,0.33,0.69,0.81c-0.04,0.5-0.36,0.67-0.83,0.63c-0.44-0.04-0.66-0.32-0.64-0.72
    C70.25,150.33,70.55,150.1,71.02,150.09z"/>
    <path class="st0" d="M65.97,150.77c-0.04,0.47-0.25,0.75-0.74,0.76c-0.42,0.01-0.7-0.18-0.74-0.61c-0.05-0.48,0.2-0.77,0.66-0.82
    C65.62,150.05,65.87,150.34,65.97,150.77z"/>
    <path class="st2" d="M62.38,14.76c0.5,0.04,0.64,0.37,0.62,0.83c-0.02,0.46-0.34,0.67-0.72,0.66c-0.46-0.01-0.74-0.32-0.72-0.79
    C61.58,14.98,61.88,14.75,62.38,14.76z"/>
    <path class="st3" d="M163.27,92.57c0.46,0.09,0.73,0.35,0.68,0.83c-0.05,0.47-0.35,0.7-0.83,0.65c-0.44-0.04-0.64-0.35-0.62-0.75
    C162.5,92.83,162.8,92.6,163.27,92.57z"/>
    <path class="st0" d="M42.89,93.28c-0.01,0.48-0.24,0.76-0.72,0.77c-0.48,0.01-0.75-0.29-0.75-0.74c0-0.41,0.26-0.7,0.67-0.72
    C42.54,92.56,42.84,92.8,42.89,93.28z"/>
    <path class="st2" d="M132.2,44.3c0.01,0.5-0.32,0.75-0.73,0.76c-0.43,0.01-0.74-0.27-0.75-0.74c-0.01-0.47,0.27-0.73,0.74-0.73
    C131.92,43.59,132.19,43.82,132.2,44.3z"/>
    <path class="st2" d="M44.96,33.52c-0.46-0.07-0.74-0.33-0.7-0.78c0.03-0.44,0.31-0.72,0.78-0.69c0.47,0.03,0.71,0.32,0.69,0.78
    C45.71,33.29,45.43,33.52,44.96,33.52z"/>
    <path class="st3" d="M169.72,70.28c-0.05,0.49-0.33,0.74-0.79,0.72c-0.47-0.02-0.73-0.32-0.7-0.79c0.03-0.42,0.3-0.64,0.72-0.65
    C169.45,69.55,169.68,69.83,169.72,70.28z"/>
    <path class="st3" d="M157.46,99.77c-0.48-0.02-0.76-0.29-0.75-0.75c0-0.4,0.26-0.69,0.68-0.7c0.45-0.01,0.78,0.2,0.79,0.69
    C158.19,99.48,157.93,99.74,157.46,99.77z"/>
    <path class="st3" d="M168.93,81.14c0.55,0.01,0.83,0.27,0.82,0.74c-0.01,0.48-0.33,0.72-0.79,0.7c-0.41-0.01-0.69-0.27-0.7-0.69
    C168.24,81.41,168.51,81.16,168.93,81.14z"/>
    <path class="st2" d="M57.26,15.47c-0.1,0.44-0.32,0.73-0.73,0.72c-0.45-0.01-0.75-0.31-0.69-0.78c0.05-0.39,0.32-0.64,0.75-0.61
    C57.02,14.83,57.18,15.14,57.26,15.47z"/>
    <path class="st0" d="M54.43,145.23c-0.08,0.48-0.37,0.71-0.83,0.67c-0.48-0.04-0.71-0.37-0.65-0.82c0.05-0.41,0.33-0.62,0.76-0.61
    C54.2,144.49,54.4,144.78,54.43,145.23z"/>
    <path class="st2" d="M45.03,102.72c-0.44-0.04-0.74-0.27-0.78-0.72c-0.04-0.46,0.22-0.74,0.7-0.76c0.46-0.02,0.75,0.18,0.78,0.67
    C45.76,102.39,45.46,102.61,45.03,102.72z"/>
    <path class="st0" d="M53.65,46.41c0.52,0.06,0.78,0.33,0.77,0.79c-0.02,0.41-0.28,0.7-0.69,0.7c-0.44,0-0.77-0.25-0.78-0.73
    C52.93,46.7,53.25,46.48,53.65,46.41z"/>
    <path class="st2" d="M45.72,107.74c-0.06,0.41-0.3,0.67-0.7,0.68c-0.41,0.01-0.68-0.25-0.7-0.66c-0.02-0.46,0.27-0.75,0.73-0.73
    C45.44,107.05,45.66,107.34,45.72,107.74z"/>
    <path class="st2" d="M68.76,15.45c-0.02,0.45-0.25,0.71-0.66,0.73c-0.46,0.02-0.74-0.27-0.73-0.73c0.01-0.41,0.28-0.66,0.7-0.65
    C68.48,14.81,68.7,15.07,68.76,15.45z"/>
    <path class="st3" d="M83.18,36.58c0,0-0.01,0.02-0.01,0.02c-0.44-0.26-0.96-0.03-1.42-0.21l0.01,0.01
    c-0.02-0.21-0.05-0.42-0.06-0.62c-0.03-0.53,0.26-0.86,0.77-0.84c0.55,0.02,0.83,0.37,0.78,0.95
    C83.25,36.12,82.92,36.32,83.18,36.58z M82.86,35.75c-0.01-0.23-0.09-0.41-0.36-0.41c-0.25,0-0.41,0.14-0.42,0.37
    c-0.01,0.23,0.13,0.4,0.38,0.42C82.72,36.15,82.84,35.99,82.86,35.75z"/>
    <path class="st3" d="M158.13,52.97c-0.05,0.38-0.22,0.67-0.65,0.7c-0.48,0.04-0.76-0.24-0.76-0.69c0-0.39,0.26-0.66,0.68-0.67
    C157.83,52.31,158.04,52.57,158.13,52.97z"/>
    <path class="st2" d="M148.79,96.9c-0.5-0.06-0.74-0.31-0.72-0.73c0.02-0.42,0.27-0.68,0.71-0.65c0.41,0.03,0.67,0.27,0.65,0.71
    C149.43,96.68,149.11,96.83,148.79,96.9z"/>
    <path class="st2" d="M73.91,141.65c0.37,0.02,0.61,0.26,0.6,0.68c-0.01,0.42-0.28,0.66-0.7,0.66c-0.45,0-0.66-0.29-0.66-0.7
    C73.15,141.87,73.4,141.63,73.91,141.65z"/>
    <path class="st0" d="M42.85,87.6c-0.08,0.42-0.3,0.68-0.74,0.66c-0.39-0.02-0.61-0.3-0.61-0.65c0-0.42,0.28-0.66,0.72-0.64
    C42.61,87,42.77,87.26,42.85,87.6z"/>
    <path class="st2" d="M50.08,124.99c0.03-0.44,0.31-0.67,0.71-0.66c0.41,0.01,0.66,0.27,0.64,0.72c-0.02,0.44-0.27,0.67-0.69,0.66
    C50.31,125.69,50.1,125.41,50.08,124.99z"/>
    <path class="st2" d="M103.34,26.99c-0.08,0.47-0.29,0.72-0.73,0.69c-0.44-0.02-0.66-0.29-0.64-0.7c0.02-0.42,0.27-0.65,0.7-0.64
    C103.12,26.36,103.27,26.67,103.34,26.99z"/>
    <path class="st3" d="M152.38,104.75c-0.03,0.39-0.21,0.68-0.65,0.69c-0.47,0.01-0.75-0.25-0.73-0.71c0.01-0.41,0.28-0.64,0.7-0.65
    C152.12,104.08,152.33,104.33,152.38,104.75z"/>
    <path class="st3" d="M105.53,29.1c0.46,0.05,0.71,0.26,0.71,0.7c0,0.43-0.25,0.68-0.67,0.69c-0.44,0-0.66-0.27-0.68-0.69
    C104.88,29.36,105.16,29.17,105.53,29.1z"/>
    <path class="st0" d="M42.84,104.77c-0.1,0.38-0.27,0.67-0.72,0.64c-0.45-0.03-0.63-0.33-0.6-0.72c0.02-0.36,0.26-0.57,0.64-0.58
    C42.6,104.1,42.76,104.4,42.84,104.77z"/>
    <path class="st3" d="M123.5,35.75c-0.02,0.43-0.24,0.67-0.68,0.65c-0.43-0.02-0.67-0.27-0.66-0.69c0.01-0.43,0.26-0.66,0.7-0.65
    C123.29,35.07,123.52,35.3,123.5,35.75z"/>
    <path class="st2" d="M114.81,32.78c-0.05,0.36-0.2,0.59-0.61,0.6c-0.39,0-0.64-0.17-0.66-0.55c-0.02-0.41,0.21-0.69,0.65-0.67
    C114.56,32.17,114.78,32.4,114.81,32.78z"/>
    <path class="st0" d="M42.85,81.83c-0.05,0.42-0.29,0.67-0.71,0.66c-0.39-0.01-0.58-0.26-0.59-0.64c-0.01-0.39,0.19-0.64,0.56-0.67
    C42.54,81.16,42.77,81.42,42.85,81.83z"/>
    <path class="st2" d="M155.14,73.13c-0.01,0.36-0.2,0.59-0.58,0.62c-0.38,0.02-0.64-0.2-0.68-0.56c-0.04-0.43,0.23-0.69,0.65-0.7
    C154.89,72.49,155.12,72.72,155.14,73.13z"/>
    <path class="st0" d="M77.34,150.87c-0.1,0.38-0.33,0.58-0.7,0.52c-0.32-0.05-0.52-0.3-0.47-0.62c0.04-0.3,0.25-0.54,0.58-0.53
    C77.11,150.26,77.3,150.52,77.34,150.87z"/>
    <path class="st2" d="M85.36,141.67c0.39,0.04,0.6,0.25,0.62,0.62c0.02,0.38-0.21,0.63-0.57,0.65c-0.43,0.02-0.68-0.26-0.67-0.67
    C84.75,141.92,84.97,141.68,85.36,141.67z"/>
    <path class="st2" d="M79.57,141.64c0.46,0.04,0.69,0.26,0.68,0.69c-0.01,0.43-0.24,0.66-0.67,0.65c-0.43-0.02-0.66-0.26-0.65-0.68
    C78.92,141.86,79.18,141.66,79.57,141.64z"/>
    <path class="st2" d="M155.14,78.9c-0.01,0.39-0.25,0.6-0.61,0.61c-0.42,0.01-0.69-0.24-0.65-0.67c0.03-0.36,0.26-0.57,0.66-0.54
    C154.93,78.32,155.13,78.51,155.14,78.9z"/>
    <path class="st2" d="M45.66,27c-0.09,0.41-0.27,0.66-0.66,0.65c-0.38-0.01-0.6-0.23-0.6-0.61c0-0.44,0.24-0.68,0.67-0.65
    C45.47,26.4,45.57,26.73,45.66,27z"/>
    <path class="st3" d="M168.87,64.14c0.47,0.01,0.71,0.2,0.74,0.58c0.03,0.43-0.22,0.71-0.65,0.7c-0.38,0-0.6-0.26-0.6-0.64
    C168.34,64.4,168.57,64.19,168.87,64.14z"/>
    <path class="st2" d="M119.87,124.39c0.5,0.01,0.68,0.27,0.69,0.62c0.01,0.37-0.24,0.63-0.6,0.64c-0.41,0.01-0.66-0.24-0.64-0.68
    C119.34,124.57,119.6,124.42,119.87,124.39z"/>
    <path class="st3" d="M82.2,30.3c-0.66-0.15-0.51-0.58-0.26-0.94c0.21-0.32,0.56-0.44,0.93-0.2c0.39,0.25,0.51,0.61,0.26,1
    c-0.24,0.37-0.56,0.65-0.94,0.13c0.32-0.1,0.76-0.08,0.67-0.61c-0.03-0.2-0.22-0.3-0.4-0.25C81.9,29.57,82.25,30,82.2,30.3z"/>
    <path class="st0" d="M60.03,150.79c0,0.34-0.18,0.55-0.5,0.6c-0.38,0.06-0.62-0.16-0.65-0.53c-0.03-0.39,0.24-0.6,0.59-0.61
    C59.8,150.23,59.96,150.48,60.03,150.79z"/>
    <path class="st2" d="M68.65,142.35c-0.06,0.34-0.26,0.55-0.58,0.54c-0.39,0-0.59-0.24-0.57-0.63c0.02-0.33,0.22-0.55,0.56-0.53
    C68.44,141.74,68.62,141.99,68.65,142.35z"/>
    <path class="st2" d="M91.07,21.81c-0.34-0.03-0.53-0.23-0.52-0.55c0.01-0.37,0.23-0.6,0.62-0.57c0.4,0.03,0.56,0.29,0.51,0.66
    C91.63,21.69,91.4,21.84,91.07,21.81z"/>
    <path class="st2" d="M45.04,114.09c-0.3-0.04-0.55-0.17-0.6-0.5c-0.05-0.38,0.16-0.64,0.52-0.68c0.31-0.03,0.55,0.14,0.59,0.48
    C45.6,113.78,45.39,113.98,45.04,114.09z"/>
    <path class="st0" d="M42.71,110.61c0.03,0.35-0.15,0.55-0.48,0.57c-0.36,0.02-0.62-0.13-0.64-0.54c-0.01-0.34,0.18-0.55,0.5-0.58
    C42.46,110.02,42.68,110.22,42.71,110.61z"/>
    <path class="st2" d="M73.81,14.91c0.35,0.05,0.55,0.2,0.55,0.57c-0.01,0.34-0.15,0.58-0.5,0.6c-0.39,0.02-0.61-0.22-0.6-0.61
    C73.26,15.11,73.53,14.98,73.81,14.91z"/>
    <path class="st3" d="M145.9,110.05c0.32,0.08,0.51,0.26,0.49,0.61c-0.02,0.37-0.25,0.49-0.58,0.47c-0.35-0.02-0.53-0.24-0.49-0.56
    C145.35,110.24,145.58,110.09,145.9,110.05z"/>
    <path class="st2" d="M62.49,9.16c0.32,0.06,0.51,0.24,0.5,0.59c-0.01,0.35-0.22,0.55-0.54,0.55c-0.38-0.01-0.61-0.24-0.57-0.64
    C61.92,9.32,62.14,9.15,62.49,9.16z"/>
    <path class="st2" d="M50.81,131.3c-0.26-0.03-0.49-0.1-0.52-0.42c-0.04-0.33,0.06-0.58,0.44-0.6c0.29-0.02,0.5,0.11,0.54,0.4
    C51.32,131.02,51.15,131.22,50.81,131.3z"/>
    <path class="st2" d="M57.09,136.48c0,0.33-0.13,0.57-0.48,0.61c-0.4,0.05-0.63-0.16-0.64-0.55c-0.01-0.33,0.17-0.55,0.53-0.55
    C56.85,135.98,57.05,136.14,57.09,136.48z"/>
    <path class="st1" d="M42.16,76.59c-0.4-0.04-0.58-0.24-0.57-0.58c0.01-0.42,0.27-0.56,0.66-0.53c0.37,0.03,0.48,0.28,0.45,0.61
    C42.67,76.44,42.44,76.58,42.16,76.59z"/>
    <path class="st3" d="M140.2,42.03c-0.32-0.02-0.54-0.17-0.57-0.51c-0.03-0.35,0.16-0.53,0.49-0.56c0.33-0.02,0.56,0.13,0.57,0.48
    C140.72,41.78,140.5,41.95,140.2,42.03z"/>
    <path class="st2" d="M44.99,119.78c-0.31-0.07-0.55-0.23-0.5-0.58c0.03-0.28,0.22-0.45,0.52-0.45c0.37,0.01,0.51,0.22,0.49,0.57
    C45.47,119.62,45.3,119.77,44.99,119.78z"/>
    <path class="st3" d="M111.39,29.32c0.29,0.05,0.46,0.21,0.45,0.51c-0.02,0.29-0.18,0.47-0.48,0.46c-0.35-0.01-0.53-0.21-0.49-0.55
    C110.89,29.42,111.12,29.34,111.39,29.32z"/>
    <path class="st2" d="M154.52,66.83c0.31,0.07,0.55,0.24,0.52,0.58c-0.02,0.28-0.2,0.47-0.51,0.47c-0.29,0-0.49-0.14-0.51-0.45
    C153.99,67.07,154.19,66.9,154.52,66.83z"/>
    <path class="st0" d="M47.44,139.42c-0.01-0.31,0.17-0.47,0.45-0.47c0.28-0.01,0.48,0.12,0.48,0.44c0.01,0.35-0.14,0.54-0.51,0.53
    C47.56,139.9,47.45,139.71,47.44,139.42z"/>
    <path class="st3" d="M151.7,47.64c-0.26-0.04-0.49-0.13-0.49-0.45c-0.01-0.35,0.17-0.53,0.52-0.52c0.31,0.01,0.45,0.21,0.44,0.49
    C152.16,47.46,151.99,47.62,151.7,47.64z"/>
    <path class="st0" d="M65.69,29.78c-0.01,0.25-0.09,0.47-0.41,0.5c-0.35,0.03-0.54-0.13-0.56-0.48c-0.01-0.31,0.18-0.46,0.46-0.47
    C65.47,29.31,65.66,29.46,65.69,29.78z"/>
    <path class="st0" d="M77.2,29.78c0,0.29-0.11,0.49-0.42,0.5c-0.36,0.02-0.52-0.17-0.52-0.52c0-0.32,0.19-0.45,0.47-0.45
    C77.02,29.32,77.21,29.47,77.2,29.78z"/>
    <path class="st2" d="M155.06,84.64c-0.1,0.38-0.28,0.57-0.63,0.53c-0.3-0.04-0.43-0.24-0.41-0.54c0.01-0.3,0.18-0.47,0.48-0.48
    C154.85,84.14,154.97,84.4,155.06,84.64z"/>
    <path class="st0" d="M111.32,133.12c0.29,0.01,0.49,0.16,0.51,0.45c0.02,0.3-0.16,0.49-0.45,0.51c-0.33,0.03-0.57-0.14-0.56-0.5
    C110.83,133.29,111.03,133.15,111.32,133.12z"/>
    <path class="st2" d="M126.24,38.56c-0.07,0.32-0.25,0.51-0.6,0.48c-0.31-0.03-0.45-0.22-0.44-0.51c0.02-0.36,0.25-0.52,0.58-0.49
    C126.06,38.06,126.2,38.27,126.24,38.56z"/>
    <path class="st3" d="M168.95,87.33c0.32,0.01,0.49,0.2,0.52,0.46c0.03,0.28-0.14,0.49-0.43,0.52c-0.34,0.04-0.56-0.18-0.57-0.5
    C168.46,87.54,168.69,87.39,168.95,87.33z"/>
    <path class="st3" d="M82.51,23.64c0.23,0.04,0.41,0.14,0.43,0.4c0.02,0.3-0.15,0.49-0.43,0.51c-0.23,0.02-0.4-0.15-0.42-0.4
    C82.06,23.85,82.23,23.69,82.51,23.64z"/>
    <path class="st0" d="M71.49,29.81c-0.05,0.27-0.18,0.46-0.48,0.47c-0.35,0.01-0.55-0.19-0.53-0.52c0.02-0.28,0.25-0.44,0.53-0.43
    C71.29,29.33,71.45,29.51,71.49,29.81z"/>
    <path class="st1" d="M76.73,35.25c0.29,0.02,0.47,0.16,0.47,0.46c0,0.35-0.19,0.52-0.54,0.49c-0.32-0.02-0.41-0.24-0.41-0.51
    C76.25,35.39,76.43,35.25,76.73,35.25z"/>
    <path class="st0" d="M48.37,53.02c-0.01,0.3-0.19,0.45-0.48,0.45c-0.36,0-0.51-0.21-0.48-0.55c0.03-0.31,0.25-0.4,0.53-0.38
    C48.25,52.55,48.38,52.72,48.37,53.02z"/>
    <path class="st0" d="M71.45,35.75c-0.04,0.26-0.2,0.44-0.48,0.45c-0.29,0.02-0.48-0.16-0.51-0.43c-0.03-0.35,0.21-0.53,0.52-0.53
    C71.26,35.24,71.42,35.45,71.45,35.75z"/>
    <path class="st3" d="M88.34,23.65c0.12,0.01,0.33,0.07,0.38,0.33c0.06,0.31-0.11,0.51-0.38,0.55c-0.29,0.05-0.48-0.15-0.49-0.44
    C87.84,23.84,87.98,23.68,88.34,23.65z"/>
    <path class="st0" d="M42.55,116.36c-0.01,0.23-0.09,0.4-0.37,0.41c-0.27,0.01-0.4-0.11-0.41-0.37c-0.01-0.24,0.09-0.42,0.35-0.44
    C42.39,115.93,42.5,116.11,42.55,116.36z"/>
    <path class="st3" d="M129.07,35.76c-0.09,0.27-0.24,0.45-0.54,0.41c-0.26-0.04-0.36-0.24-0.35-0.46c0.02-0.3,0.21-0.44,0.51-0.39
    C128.94,35.35,129.05,35.52,129.07,35.76z"/>
    <path class="st0" d="M42.56,70.29c-0.05,0.25-0.17,0.41-0.44,0.4c-0.28-0.01-0.36-0.19-0.35-0.43c0.01-0.26,0.16-0.4,0.41-0.38
    C42.45,69.88,42.54,70.07,42.56,70.29z"/>
    <path class="st3" d="M140.12,115.95c0.26,0.01,0.4,0.14,0.41,0.4c0,0.19-0.06,0.36-0.28,0.39c-0.26,0.05-0.43-0.08-0.47-0.33
    C139.74,116.18,139.87,116.01,140.12,115.95z"/>
    <path class="st2" d="M62.75,142.29c-0.06,0.35-0.25,0.48-0.54,0.44c-0.26-0.03-0.36-0.23-0.34-0.47c0.02-0.32,0.23-0.43,0.52-0.39
    C62.64,141.92,62.74,142.1,62.75,142.29z"/>
    <path class="st2" d="M149.15,55.82c-0.02,0.23-0.12,0.4-0.37,0.42c-0.25,0.02-0.39-0.14-0.41-0.38c-0.02-0.24,0.12-0.4,0.36-0.43
    C148.99,55.41,149.1,55.59,149.15,55.82z"/>
    <path class="st2" d="M137.25,113.89c-0.24-0.02-0.4-0.15-0.42-0.39c-0.03-0.25,0.13-0.4,0.37-0.42c0.24-0.02,0.41,0.12,0.43,0.36
    C137.64,113.69,137.48,113.83,137.25,113.89z"/>
    <path class="st3" d="M174.7,70.63c-0.19-0.04-0.33-0.15-0.33-0.36c0-0.22,0.14-0.32,0.35-0.32c0.2,0,0.35,0.11,0.33,0.33
    C175.03,70.48,174.92,70.62,174.7,70.63z"/>
    <path class="st2" d="M68.21,10.06c-0.2-0.01-0.34-0.12-0.35-0.32c-0.01-0.2,0.12-0.33,0.32-0.35c0.21-0.02,0.33,0.12,0.34,0.32
    C68.54,9.91,68.42,10.03,68.21,10.06z"/>
    <path class="st2" d="M50.79,15.08c0.24,0.07,0.39,0.22,0.37,0.47c-0.03,0.27-0.23,0.36-0.47,0.32c-0.2-0.03-0.32-0.17-0.31-0.38
    C50.4,15.24,50.56,15.11,50.79,15.08z"/>
    <path class="st2" d="M91.44,142.34c-0.06,0.2-0.19,0.33-0.39,0.3c-0.18-0.03-0.33-0.17-0.29-0.38c0.04-0.18,0.17-0.33,0.38-0.29
    C91.34,142,91.45,142.14,91.44,142.34z"/>
    <path class="st2" d="M79.95,15.48c-0.01,0.19-0.13,0.33-0.31,0.35c-0.25,0.04-0.4-0.12-0.39-0.36c0.01-0.2,0.14-0.32,0.34-0.32
    C79.79,15.16,79.9,15.28,79.95,15.48z"/>
    <path class="st2" d="M45.07,20.88c0.12,0,0.3,0.09,0.31,0.35c0.01,0.24-0.12,0.4-0.38,0.4c-0.19,0-0.34-0.1-0.36-0.3
    C44.61,21.09,44.69,20.91,45.07,20.88z"/>
    <path class="st3" d="M76.77,23.75c0.2,0.03,0.32,0.15,0.33,0.35c0,0.22-0.15,0.32-0.35,0.32c-0.22,0-0.33-0.13-0.31-0.34
    C76.45,23.88,76.55,23.74,76.77,23.75z"/>
    <path class="st1" d="M54.07,41.5c0,0.25-0.11,0.39-0.39,0.39c-0.22,0-0.35-0.12-0.38-0.31c-0.04-0.25,0.08-0.43,0.34-0.47
    C53.89,41.07,54.04,41.22,54.07,41.5z"/>
    <path class="st0" d="M59.3,30.08c-0.08-0.02-0.24-0.13-0.18-0.33c0.04-0.16,0.2-0.33,0.4-0.26c0.11,0.04,0.22,0.19,0.23,0.31
    C59.78,29.99,59.66,30.13,59.3,30.08z"/>
    <path class="st0" d="M94.37,145.14c-0.02,0.23-0.14,0.37-0.33,0.37c-0.21,0-0.31-0.15-0.3-0.36c0.01-0.16,0.09-0.27,0.26-0.29
    C94.21,144.84,94.31,144.99,94.37,145.14z"/>
    <path class="st3" d="M163.52,53c-0.06,0.22-0.18,0.35-0.38,0.31c-0.18-0.04-0.34-0.15-0.29-0.37c0.04-0.18,0.17-0.33,0.38-0.3
    C163.42,52.67,163.49,52.83,163.52,53z"/>
    <path class="st0" d="M59.73,35.76c-0.03,0.22-0.18,0.33-0.36,0.28c-0.11-0.03-0.23-0.18-0.25-0.3c-0.04-0.2,0.12-0.34,0.31-0.34
    C59.63,35.4,59.74,35.55,59.73,35.76z"/>
    <path class="st3" d="M117.07,30.1c-0.16-0.03-0.25-0.15-0.23-0.3c0.03-0.18,0.14-0.34,0.36-0.26c0.15,0.05,0.2,0.19,0.18,0.34
    C117.35,30.05,117.23,30.1,117.07,30.1z"/>
    <path class="st2" d="M108.43,27.37c-0.23-0.01-0.33-0.15-0.34-0.34c-0.01-0.2,0.1-0.35,0.31-0.36c0.2,0,0.33,0.15,0.35,0.33
    C108.77,27.22,108.6,27.31,108.43,27.37z"/>
    <path class="st2" d="M97.11,21.2c0.01,0.2-0.07,0.34-0.28,0.36c-0.2,0.02-0.35-0.07-0.37-0.28c-0.02-0.19,0.1-0.33,0.29-0.37
    C96.95,20.88,97.06,21.01,97.11,21.2z"/>
    <path class="st2" d="M154.5,90.11c0.17,0.01,0.33,0.07,0.36,0.28c0.02,0.2-0.1,0.34-0.29,0.36c-0.2,0.03-0.34-0.08-0.37-0.28
    C154.16,90.27,154.26,90.15,154.5,90.11z"/>
    <path class="st2" d="M148.78,101.69c0.19,0,0.25,0.13,0.24,0.28c-0.01,0.16-0.1,0.27-0.27,0.25c-0.16-0.02-0.26-0.12-0.25-0.29
    C148.5,101.76,148.62,101.69,148.78,101.69z"/>
    <path class="st2" d="M137.2,44.57c-0.15-0.04-0.24-0.14-0.23-0.3c0.01-0.17,0.13-0.24,0.29-0.22c0.16,0.02,0.24,0.14,0.23,0.29
    C137.47,44.5,137.37,44.59,137.2,44.57z"/>
    <path class="st2" d="M143.34,107.75c-0.01,0.21-0.13,0.32-0.33,0.33c-0.26,0.01-0.4-0.14-0.4-0.39c0-0.2,0.14-0.31,0.35-0.32
    C143.23,107.36,143.35,107.49,143.34,107.75z"/>
    <path class="st2" d="M102.55,136.24c0.28-0.03,0.38,0.1,0.39,0.3c0.01,0.15-0.05,0.28-0.22,0.31c-0.2,0.03-0.36-0.03-0.4-0.25
    C102.28,136.39,102.4,136.28,102.55,136.24z"/>
    <path class="st2" d="M85.7,15.57c-0.11,0.06-0.19,0.13-0.29,0.14c-0.16,0.03-0.26-0.09-0.23-0.23c0.01-0.07,0.14-0.18,0.2-0.17
    C85.46,15.32,85.53,15.43,85.7,15.57z"/>
    <path class="st3" d="M94.1,24.4c-0.22,0.03-0.33-0.09-0.35-0.28c-0.02-0.19,0.07-0.34,0.28-0.35c0.2-0.01,0.34,0.09,0.37,0.29
    C94.42,24.26,94.28,24.36,94.1,24.4z"/>
    <path class="st2" d="M143.05,50.42c-0.2,0.01-0.36-0.05-0.4-0.27c-0.04-0.26,0.07-0.43,0.34-0.43c0.2,0,0.34,0.11,0.35,0.32
    C143.35,50.26,143.26,50.38,143.05,50.42z"/>
    <path class="st3" d="M169.3,58.83c-0.02,0.21-0.16,0.3-0.36,0.29c-0.21-0.02-0.31-0.15-0.29-0.36c0.03-0.2,0.16-0.31,0.36-0.29
    C169.23,58.48,169.32,58.62,169.3,58.83z"/>
    <path class="st3" d="M175.04,76.06c-0.09,0.25-0.23,0.34-0.43,0.26c-0.18-0.07-0.29-0.21-0.22-0.4c0.07-0.19,0.24-0.25,0.42-0.2
    C175.02,75.77,175.04,75.94,175.04,76.06z"/>
    <path class="st0" d="M42.18,121.81c0.21,0.03,0.29,0.17,0.28,0.37c-0.01,0.16-0.12,0.26-0.28,0.26c-0.2,0-0.35-0.12-0.36-0.32
    C41.82,121.93,41.97,121.82,42.18,121.81z"/>
    <path class="st1" d="M129,122.16c-0.11,0.32-0.28,0.4-0.51,0.33c-0.26-0.08-0.28-0.3-0.21-0.51c0.06-0.18,0.22-0.26,0.41-0.22
    C128.95,121.81,128.97,122.02,129,122.16z"/>
    <path class="st2" d="M44.96,124.68c0.24-0.02,0.32,0.13,0.33,0.32c0.01,0.15-0.05,0.29-0.22,0.31c-0.19,0.03-0.37-0.02-0.4-0.24
    C44.64,124.86,44.78,124.74,44.96,124.68z"/>
    <path class="st2" d="M114.45,130.77c-0.01,0.17-0.13,0.24-0.27,0.26c-0.15,0.02-0.28-0.05-0.3-0.21
    c-0.03-0.21,0.11-0.32,0.31-0.31C114.34,130.52,114.44,130.61,114.45,130.77z"/>
    <path class="st2" d="M154.55,61.88c-0.16,0-0.27-0.08-0.29-0.24c-0.01-0.15,0.03-0.29,0.21-0.3c0.16-0.01,0.28,0.06,0.29,0.23
    C154.76,61.71,154.72,61.84,154.55,61.88z"/>
    <path class="st2" d="M50.53,136.56c0.03-0.14,0.08-0.31,0.25-0.27c0.11,0.02,0.24,0.17,0.26,0.28c0.02,0.14-0.09,0.27-0.26,0.27
    C50.59,136.84,50.54,136.71,50.53,136.56z"/>
    <path class="st2" d="M131.81,119.3c-0.13,0.06-0.21,0.13-0.3,0.14c-0.16,0.02-0.26-0.08-0.24-0.23c0.01-0.07,0.13-0.18,0.2-0.18
    C131.55,119.04,131.63,119.15,131.81,119.3z"/>
    <path class="st2" d="M56.48,142.05c0.1,0.08,0.21,0.13,0.23,0.2c0.05,0.16-0.05,0.27-0.21,0.25c-0.07-0.01-0.18-0.12-0.19-0.2
    C56.31,142.24,56.41,142.16,56.48,142.05z"/>
    <path class="st0" d="M82.74,150.9c-0.06,0.04-0.13,0.14-0.21,0.15c-0.15,0.02-0.29-0.05-0.26-0.23c0.01-0.08,0.12-0.18,0.2-0.2
    C82.62,150.59,82.73,150.68,82.74,150.9z"/>
    <path class="st0" d="M48.14,47.14c-0.04,0.2-0.15,0.28-0.3,0.23c-0.07-0.02-0.14-0.15-0.14-0.24c0-0.17,0.14-0.24,0.28-0.2
    C48.06,46.95,48.1,47.08,48.14,47.14z"/>
    <path class="st2" d="M45.02,130.5c0.08,0.12,0.18,0.21,0.17,0.28c-0.01,0.06-0.14,0.17-0.21,0.16c-0.07-0.01-0.17-0.13-0.17-0.2
    C44.81,130.67,44.93,130.61,45.02,130.5z"/>
    <path class="st2" d="M120.2,32.83c-0.12,0.06-0.22,0.14-0.29,0.12c-0.06-0.02-0.15-0.16-0.13-0.21c0.02-0.07,0.15-0.15,0.22-0.14
    C120.07,32.6,120.12,32.72,120.2,32.83z"/>
    <path class="st2" d="M74.13,9.83c-0.04,0.02-0.14,0.11-0.21,0.08c-0.07-0.03-0.12-0.15-0.17-0.23c0.08-0.05,0.17-0.13,0.25-0.13
    C74.11,9.56,74.21,9.64,74.13,9.83z"/>
    <path class="st3" d="M99.79,23.88c0.08,0.07,0.15,0.13,0.22,0.2c-0.05,0.05-0.09,0.11-0.15,0.14c-0.14,0.07-0.2-0.05-0.21-0.15
    C99.65,24.02,99.73,23.96,99.79,23.88z"/>
    <path class="st3" d="M71.17,24.08c-0.05,0.05-0.08,0.12-0.14,0.14c-0.14,0.06-0.2-0.06-0.2-0.16c0-0.1,0.07-0.2,0.21-0.14
    C71.09,23.96,71.12,24.03,71.17,24.08z"/>
    <path class="st3" d="M82.44,18.43c0.11,0.1,0.23,0.16,0.22,0.21c-0.01,0.08-0.1,0.15-0.15,0.22c-0.05-0.04-0.13-0.08-0.13-0.13
    C82.36,18.66,82.4,18.58,82.44,18.43z"/>
    <path class="st0" d="M42.1,65.05c-0.06-0.11-0.15-0.2-0.14-0.27c0.01-0.08,0.12-0.19,0.19-0.2c0.16,0,0.27,0.12,0.22,0.28
    C42.35,64.94,42.22,64.98,42.1,65.05z"/>
    <path class="st0" d="M53.92,150.75c0,0.19-0.1,0.3-0.24,0.3c-0.08,0-0.21-0.09-0.23-0.16c-0.05-0.17,0.09-0.26,0.23-0.27
    C53.76,150.61,53.85,150.7,53.92,150.75z"/>
    <path class="st2" d="M56.83,9.79c-0.19,0.07-0.28,0.14-0.37,0.14c-0.08-0.01-0.19-0.09-0.2-0.16c-0.01-0.06,0.09-0.18,0.15-0.2
    C56.48,9.55,56.57,9.64,56.83,9.79z"/>
    <path class="st1" d="M105.82,139.46c-0.11,0.08-0.17,0.18-0.25,0.19c-0.19,0.02-0.23-0.14-0.2-0.28c0.01-0.06,0.16-0.13,0.24-0.13
    C105.67,139.26,105.73,139.37,105.82,139.46z"/>
    <path class="st0" d="M122.99,127.84c-0.02,0.07-0.04,0.14-0.06,0.2c-0.06-0.03-0.15-0.05-0.16-0.1c-0.02-0.05,0.04-0.12,0.06-0.18
    C122.88,127.79,122.93,127.81,122.99,127.84z"/>
    <path class="st3" d="M174.81,65.15c-0.06,0.01-0.13,0.02-0.19,0.01c-0.01,0-0.03-0.07-0.02-0.08c0.04-0.04,0.09-0.07,0.14-0.1
    C174.76,65.04,174.79,65.09,174.81,65.15z"/>
    <path class="st1" d="M47.65,145.19c0.1-0.09,0.16-0.19,0.23-0.21c0.18-0.04,0.26,0.1,0.23,0.24c-0.01,0.06-0.14,0.14-0.22,0.14
    C47.82,145.37,47.76,145.27,47.65,145.19z"/>
    <path class="st3" d="M88.24,18.9c-0.05-0.13-0.09-0.22-0.12-0.3c0.09-0.03,0.17-0.07,0.26-0.08c0.02,0,0.1,0.12,0.08,0.14
    C88.42,18.74,88.34,18.8,88.24,18.9z"/>
    <path class="st2" d="M44.95,136.63c-0.01-0.05-0.02-0.1-0.03-0.16c0.05,0,0.11,0,0.16,0.01c0.02,0,0.03,0.05,0.04,0.07
    C45.06,136.58,45.01,136.61,44.95,136.63z"/>
    <path class="st1" d="M142.22,95.02c-0.14,0.14-0.29,0.27-0.43,0.41c-0.64,0.41-1.31,0.68-2.09,0.6c-0.51-1.97-2.2-2.85-3.72-1.93
    c-0.99,0.6-1.3,1.52-1.13,2.61c0.17,1.09,1.05,1.75,2.52,1.97c0.11,0.49-0.1,0.95-0.15,1.42c-1.04,0.17-1.72,0.7-1.81,1.82
    c-0.41,0.04-0.83,0.08-1.24,0.12c-0.21-1.02-0.54-1.96-1.58-2.44c-1.78-0.82-3.93,0.52-3.7,2.77c-0.12,0.14-0.24,0.28-0.36,0.41
    c0.06-2.15-0.18-2.6-2-3.7c0.89-0.49,1.65-1.07,2.03-2.17c0.8,1.53,1.87,2.49,3.63,2.06c1.25-0.3,2.09-1.33,2.18-2.56
    c0.1-1.35-0.59-2.34-2.22-3.12c0.52-0.23,0.99-0.45,1.36-0.82c0.37-0.37,0.6-0.81,0.81-1.42c0.75,1.7,1.92,2.55,3.74,2.12
    c1.13-0.27,1.94-1.26,2.04-2.42c0.12-1.5-0.46-2.41-2.11-3.18c1.22-0.62,2.11-1.41,2.13-2.86c0.03-1.49-0.82-2.33-2.14-2.95
    c0.72-0.36,1.34-0.67,1.72-1.32c0.4-0.67,0.48-1.37,0.38-2.1c-0.16-1.16-1.02-1.99-2.33-2.29c-1.29-0.29-2.26,0.29-3.45,2.09
    c-0.4-1.15-1.16-1.91-2.69-2.17c1.67-0.5,2.57-1.36,2.62-2.9c0.21,0.01,0.42,0.02,0.63,0.03c0.46,1.63,1.11,2.29,2.29,2.35
    c1.15,0.05,1.96-0.59,2.4-1.92c0.62,0,1.24,0.04,1.83,0.25c0.32,0.33,0.64,0.66,0.95,1c0.35,0.71,0.3,1.45,0.15,2.2
    c-0.91,0.39-1.43,1.03-1.36,2.07c0.08,1.14,0.87,1.55,1.78,1.86c0.13,0.65,0.12,1.3-0.03,1.95c-1.06,0.16-1.71,0.7-1.73,1.85
    c-0.02,1.23,0.74,1.71,1.8,1.91c0.25,0.68,0.18,1.36-0.01,2.03c-0.9,0.14-1.54,0.6-1.65,1.55c-0.12,0.95,0.4,1.54,1.23,1.91
    c0.34,0.86,0.2,1.72-0.04,2.57l0.01-0.01C142.27,94.72,142.26,94.88,142.22,95.02z"/>
    <path class="st3" d="M112.33,45.11c0.29,0.43,0.64,0.78,1.09,1.05c0.05,0.51,0.28,1.01,0.12,1.54c-0.84,0.28-1.4,0.85-1.73,1.66
    c-0.21,0.02-0.42,0.05-0.63,0.07c-0.37-1.04-1.08-1.72-2.1-2.11c0.03-0.28,0.07-0.56,0.1-0.84c0.63-0.33,1.09-0.83,1.42-1.45
    C111.18,44.85,111.76,44.91,112.33,45.11z M111.32,46.42c-0.41,0.06-0.75,0.27-0.72,0.75c0.02,0.45,0.33,0.75,0.78,0.73
    c0.38-0.02,0.69-0.27,0.69-0.7C112.08,46.75,111.83,46.47,111.32,46.42z"/>
    <path class="st0" d="M131.43,70.39c-2.02,0.35-2.96,1.63-2.63,3.57c-0.09,0-0.19,0.01-0.28,0.01c0.05-2.17-0.21-2.65-2.11-3.75
    c1.45-0.52,2.09-1.57,2.27-2.94c0.06,0.02,0.11,0.04,0.17,0.05c0.26,1.42,0.93,2.44,2.51,2.56
    C131.39,70.05,131.41,70.22,131.43,70.39z"/>
    <path class="st1" d="M133.97,72c-0.3-0.6-0.76-1.04-1.34-1.37c0.05-0.34,0.11-0.69,0.16-1.03c0.31-0.31,0.61-0.61,0.92-0.92
    c1.77-0.33,2.62,0.6,2.22,2.43c-0.23,0.23-0.46,0.47-0.69,0.7C134.82,71.88,134.4,71.94,133.97,72z"/>
    <path class="st0" d="M59.05,60.83c-0.35-0.75-0.93-1.28-1.61-1.72c0-0.26,0.01-0.53,0.01-0.79c0.72-0.33,1.22-0.89,1.62-1.56
    c0.14-0.01,0.28-0.02,0.41-0.03c0.42,0.91,1.08,1.57,2.31,2.03c-1.35,0.31-1.92,1.12-2.32,2.08
    C59.32,60.84,59.19,60.84,59.05,60.83z"/>
    <path class="st3" d="M116.07,53.62c-0.46-0.32-0.96-0.56-1.51-0.68c0.02-0.13,0.03-0.26,0.05-0.39c1.01-0.32,1.69-0.99,2.02-2
    c0.4,0.01,0.8,0.03,1.2,0.04c0.34,0.74,0.82,1.34,1.65,1.57c0.02,0.34,0.05,0.69,0.07,1.03c-1.17,0.33-1.95,1.04-2.23,2.25
    c-0.07-0.01-0.14-0.01-0.21,0c-0.14-0.45-0.43-0.85-0.43-1.35c0.14-0.01,0.28-0.03,0.43-0.04c0.69-0.08,1.04-0.49,0.98-1.16
    c-0.05-0.62-0.45-1-1.08-0.96c-0.68,0.03-1.01,0.47-0.99,1.15C116.03,53.26,116.05,53.44,116.07,53.62z"/>
    <path class="st0" d="M117.11,107.22c-0.34-1.21-1.1-1.94-2.34-2.37c1.1-0.42,1.88-1.03,2.22-2.21c0.61,1.07,1.25,2.01,2.72,2.19
    C118.39,105.27,117.46,105.91,117.11,107.22z"/>
    <path class="st0" d="M65.15,84.31c-0.54-1.52-0.79-1.78-2.29-2.51c1.02-0.43,1.89-0.95,2.22-2.22c0.6,1.02,1.18,1.88,2.54,2.14
    C66.31,82.18,65.5,82.89,65.15,84.31z"/>
    <path class="st0" d="M91.6,64.5c1.21-0.41,1.96-1.08,2.33-2.35c0.53,1.1,1.1,1.99,2.37,2.29c-1.04,0.5-1.92,1.04-2.24,2.32
    C93.5,65.66,92.89,64.78,91.6,64.5z"/>
    <path class="st0" d="M105.53,66.93c-0.39-1.4-1.17-2.01-2.33-2.45c1.18-0.4,1.93-1.07,2.28-2.35c0.49,1.22,1.17,1.98,2.35,2.32
    C106.74,64.88,105.97,65.52,105.53,66.93z"/>
    <path class="st0" d="M68.58,76.06c1.22-0.48,1.92-1.18,2.36-2.31c0.4,1.16,1.16,1.83,2.27,2.28c-1.12,0.4-1.86,1.04-2.22,2.25
    C70.48,77.22,69.91,76.39,68.58,76.06z"/>
    <path class="st0" d="M80.2,70.25c1.37-0.62,1.4-0.66,2.32-2.23c0.33,1.04,0.97,1.76,2.09,2.17c-0.93,0.6-1.85,1.14-2.08,2.46
    C82.07,71.48,81.44,70.68,80.2,70.25z"/>
    <path class="st0" d="M125.16,81.73c-1.14,0.47-1.97,1.07-2.33,2.51c-0.4-1.43-1.22-2.04-2.31-2.49c1.11-0.36,1.84-1,2.26-2.16
    C123.3,80.64,123.94,81.43,125.16,81.73z"/>
    <path class="st0" d="M76.71,83.9c-0.41-1.09-1.19-1.7-2.27-2.14c1.14-0.4,1.87-1.06,2.21-2.27c0.54,1.05,1.07,2,2.42,2.21
    C77.97,82.2,77.11,82.77,76.71,83.9z"/>
    <path class="st0" d="M105.48,79.34c0.46,1.29,1.1,2.03,2.25,2.4c-0.97,0.53-1.84,1.07-2.16,2.45c-0.48-1.31-1.21-2-2.34-2.42
    C104.38,81.35,105.14,80.71,105.48,79.34z"/>
    <path class="st0" d="M94.01,56.63c0.38,1.08,1.17,1.67,2.34,2.15c-1.25,0.3-1.91,1.03-2.36,2.21c-0.53-1.08-1.09-1.91-2.37-2.18
    C92.74,58.31,93.58,57.74,94.01,56.63z"/>
    <path class="st0" d="M109.27,99.1c1.03-0.49,1.62-1.25,2.06-2.36c0.32,1.18,1,1.85,2.12,2.26c-0.97,0.61-1.84,1.18-2.09,2.4
    C110.86,100.38,110.36,99.5,109.27,99.1z"/>
    <path class="st0" d="M88.21,101.45c-0.34-1.22-1.03-1.87-2.09-2.33c0.93-0.59,1.82-1.15,2.06-2.43c0.48,1.1,1,1.95,2.37,2.34
    C89.27,99.54,88.63,100.29,88.21,101.45z"/>
    <path class="st0" d="M70.92,96.63c0.38,1.29,1.08,1.94,2.13,2.4c-0.94,0.61-1.86,1.15-2.07,2.47c-0.49-1.09-1.02-1.95-2.31-2.35
    C69.78,98.59,70.63,97.99,70.92,96.63z"/>
    <path class="st0" d="M136.78,81.72c-1.23,0.47-1.99,1.1-2.41,2.33c-0.48-1.13-1.14-1.88-2.31-2.26c1.12-0.44,1.89-1.07,2.24-2.33
    C134.77,80.59,135.37,81.46,136.78,81.72z"/>
    <path class="st0" d="M86.01,70.27c1.14-0.51,1.84-1.15,2.17-2.27c0.49,0.95,1,1.8,2.29,2.2c-1.14,0.56-1.92,1.21-2.24,2.42
    C87.88,71.43,87.17,70.76,86.01,70.27z"/>
    <path class="st0" d="M111.36,72.6c-0.5-1.1-1.07-1.96-2.47-2.33c1.34-0.42,2-1.19,2.42-2.37c0.36,1.17,0.99,1.89,2.14,2.29
    C112.48,70.78,111.61,71.35,111.36,72.6z"/>
    <path class="st0" d="M70.95,56.31c0.34,1.26,1.07,1.97,2.28,2.41c-1.18,0.43-1.9,1.12-2.25,2.31c-0.5-1.07-1.02-1.97-2.33-2.23
    C69.78,58.26,70.61,57.6,70.95,56.31z"/>
    <path class="st0" d="M105.5,78.37c-0.39-1.29-1.11-1.95-2.24-2.32c1.03-0.48,1.92-1.01,2.19-2.33c0.56,1.09,1.14,1.96,2.48,2.29
    C106.65,76.38,105.96,77.13,105.5,78.37z"/>
    <path class="st0" d="M117.01,67.82c0.42,1.21,1.03,1.94,2.32,2.41c-1.24,0.48-1.89,1.25-2.3,2.36c-0.34-1.16-1.04-1.84-2.13-2.33
    C115.88,69.72,116.73,69.16,117.01,67.82z"/>
    <path class="st0" d="M82.52,89.84c-0.45-1.08-1.09-1.87-2.3-2.26c1.1-0.51,1.92-1.12,2.26-2.52c0.34,1.39,1.14,2.03,2.24,2.52
    C83.59,87.99,82.85,88.65,82.52,89.84z"/>
    <path class="st0" d="M102.08,99.02c-1.19,0.51-1.96,1.18-2.31,2.43c-0.34-1.19-1.04-1.87-2.16-2.33c1.01-0.59,1.89-1.15,2.1-2.48
    C100.16,97.9,100.81,98.67,102.08,99.02z"/>
    <path class="st0" d="M84.76,99.06c-1.21,0.48-1.91,1.15-2.24,2.31c-0.46-1-1.01-1.83-2.17-2.23c0.98-0.58,1.77-1.18,2.09-2.43
    C82.89,97.87,83.51,98.65,84.76,99.06z"/>
    <path class="st0" d="M74.37,58.81c1.09-0.51,1.94-1.09,2.29-2.39c0.49,1.15,1.15,1.87,2.32,2.32c-1.17,0.39-1.82,1.1-2.26,2.17
    C76.19,59.87,75.62,59.05,74.37,58.81z"/>
    <path class="st0" d="M111.32,102.37c0.29,1.39,1.13,2.03,2.21,2.48c-1.35,0.62-1.65,0.91-2.18,2.2c-0.54-1-1.11-1.9-2.44-2.15
    C110.14,104.43,110.93,103.71,111.32,102.37z"/>
    <path class="st0" d="M65.23,56.44c0.35,1.25,1.1,1.86,2.27,2.31c-1.18,0.37-1.85,1.08-2.34,2.17c-0.46-1.09-1.12-1.84-2.35-2.17
    C64.02,58.35,64.73,57.62,65.23,56.44z"/>
    <path class="st0" d="M120.41,76.04c1.19-0.4,1.91-1.02,2.34-2.18c0.59,1.02,1.2,1.86,2.49,2.16c-1.26,0.34-1.91,1.07-2.4,2.16
    C122.31,77.16,121.71,76.37,120.41,76.04z"/>
    <path class="st0" d="M74.53,52.95c1.05-0.33,1.65-1.08,2.13-2.07c0.6,0.88,1.09,1.81,2.36,1.99c-1,0.56-1.95,1.04-2.23,2.45
    C76.23,54.12,75.63,53.31,74.53,52.95z"/>
    <path class="st0" d="M68.66,70.27c1.24-0.5,1.93-1.2,2.27-2.39c0.37,1.18,1.03,1.9,2.24,2.34c-1.1,0.55-1.9,1.15-2.2,2.39
    C70.57,71.47,69.94,70.74,68.66,70.27z"/>
    <path class="st0" d="M99.75,107.15c-0.39-1.15-1.04-1.89-2.2-2.26c1.01-0.53,1.81-1.16,2.21-2.34c0.44,1.14,1.11,1.9,2.38,2.3
    C100.8,105.2,100.2,106.02,99.75,107.15z"/>
    <path class="st0" d="M74.4,76.07c1.11-0.5,1.94-1.06,2.24-2.35c0.56,1.12,1.19,1.99,2.49,2.32c-1.29,0.31-1.95,1.08-2.42,2.25
    C76.27,77.15,75.63,76.38,74.4,76.07z"/>
    <path class="st0" d="M93.97,91.05c0.54,1.17,1.17,1.92,2.39,2.23c-1.13,0.4-1.87,1.04-2.3,2.16c-0.62-0.95-1.15-1.87-2.55-2.07
    C92.77,92.93,93.52,92.31,93.97,91.05z"/>
    <path class="st0" d="M103.23,93.29c1.17-0.39,1.88-1.05,2.23-2.24c0.51,1.05,1.13,1.87,2.31,2.24c-1.1,0.44-1.79,1.14-2.28,2.36
    C105.14,94.37,104.37,93.74,103.23,93.29z"/>
    <path class="st0" d="M107.76,110.57c-1.08,0.42-1.76,1.13-2.26,2.36c-0.37-1.27-1.11-1.94-2.29-2.31
    c1.16-0.46,1.94-1.07,2.24-2.27C106.02,109.36,106.53,110.35,107.76,110.57z"/>
    <path class="st0" d="M65.22,66.79c-0.52-1.18-1.17-1.96-2.41-2.32c1.18-0.34,1.87-1.02,2.32-2.13c0.58,0.99,1.17,1.83,2.36,2.11
    C66.38,64.91,65.58,65.53,65.22,66.79z"/>
    <path class="st0" d="M70.94,90.91c0.4,1.29,1.09,1.98,2.26,2.36c-1.07,0.49-1.91,1.07-2.2,2.4c-0.47-1.23-1.17-1.95-2.34-2.36
    C69.8,92.88,70.56,92.23,70.94,90.91z"/>
    <path class="st0" d="M102.03,58.77c-1.19,0.36-1.85,1.08-2.24,2.18c-0.5-0.98-1.01-1.88-2.32-2.14c1.05-0.57,1.92-1.14,2.27-2.4
    C100.18,57.63,100.94,58.3,102.03,58.77z"/>
    <path class="st0" d="M76.64,62.23c0.53,1.07,1.13,1.81,2.24,2.2c-0.98,0.54-1.81,1.12-2.16,2.48c-0.39-1.37-1.17-2-2.31-2.42
    C75.51,64.07,76.29,63.46,76.64,62.23z"/>
    <path class="st0" d="M93.94,78.11c-0.41-1.03-1.1-1.73-2.24-2.06c1.04-0.42,1.83-1.01,2.22-2.26c0.57,1.13,1.28,1.86,2.36,2.22
    C95.05,76.55,94.94,76.65,93.94,78.11z"/>
    <path class="st0" d="M90.62,110.55c-1.13,0.44-1.96,1.05-2.34,2.39c-0.48-1.19-1.13-1.95-2.35-2.32c1.19-0.45,1.95-1.1,2.26-2.32
    C88.68,109.43,89.3,110.29,90.62,110.55z"/>
    <path class="st0" d="M93.98,102.59c0.52,1.23,1.23,1.91,2.46,2.26c-1.3,0.33-1.92,1.09-2.44,2.19c-0.48-1.09-1.12-1.83-2.31-2.15
    C92.76,104.47,93.5,103.85,93.98,102.59z"/>
    <path class="st0" d="M134.31,89.88c-0.39-1.23-1.05-1.95-2.27-2.27c1.08-0.48,1.9-1.08,2.26-2.32c0.53,1.08,1.11,1.89,2.43,2.24
    C135.41,87.96,134.76,88.69,134.31,89.88z"/>
    <path class="st0" d="M128.59,85.25c0.42,1.19,1.15,1.85,2.26,2.29c-1.12,0.44-1.9,1.06-2.22,2.3c-0.46-1.12-1.09-1.92-2.36-2.27
    C127.43,87.11,128.15,86.45,128.59,85.25z"/>
    <path class="st0" d="M113.65,87.52c-1.42,0.61-1.8,0.99-2.31,2.26c-0.5-0.99-1.05-1.85-2.3-2.16c1.08-0.54,1.9-1.15,2.24-2.44
    C111.67,86.4,112.34,87.16,113.65,87.52z"/>
    <path class="st0" d="M76.67,90.96c0.49,1.27,1.2,1.99,2.38,2.34c-1.16,0.4-1.92,1.08-2.34,2.31c-0.44-1.18-1.12-1.92-2.32-2.3
    C75.55,92.91,76.29,92.25,76.67,90.96z"/>
    <path class="st0" d="M99.76,84.04c-0.4-1.14-1.14-1.8-2.28-2.32c1.27-0.32,1.87-1.12,2.29-2.26c0.43,1.15,1.04,1.94,2.28,2.27
    C100.93,82.21,100.19,82.89,99.76,84.04z"/>
    <path class="st0" d="M68.59,87.56c1.3-0.47,1.97-1.21,2.36-2.35c0.36,1.18,1.1,1.87,2.26,2.34c-1.18,0.42-1.9,1.12-2.26,2.3
    C70.56,88.71,69.89,87.95,68.59,87.56z"/>
    <path class="st0" d="M94.07,112.99c-0.53-1.31-1.24-2.04-2.58-2.4c1.38-0.3,2-1.05,2.48-2.16c0.5,1.07,1.14,1.83,2.37,2.13
    C95.25,111.02,94.39,111.55,94.07,112.99z"/>
    <path class="st0" d="M105.52,107.06c-0.49-1.14-1.12-1.85-2.26-2.17c0.99-0.53,1.94-1,2.18-2.43c0.5,1.23,1.22,1.96,2.33,2.41
    C106.66,105.24,106.02,105.94,105.52,107.06z"/>
    <path class="st0" d="M99.82,95.78c-0.46-1.35-1.14-2.06-2.32-2.45c1.11-0.46,1.89-1.09,2.23-2.33c0.47,1.13,1.08,1.92,2.31,2.25
    C100.97,93.76,100.11,94.31,99.82,95.78z"/>
    <path class="st0" d="M97.41,87.56c1.25-0.45,1.9-1.15,2.34-2.22c0.47,1.07,1.15,1.81,2.31,2.22c-1.2,0.41-1.93,1.1-2.28,2.29
    C99.38,88.71,98.71,87.96,97.41,87.56z"/>
    <path class="st0" d="M99.75,78.32c-0.37-1.2-1.11-1.89-2.27-2.29c1.15-0.44,1.88-1.15,2.31-2.31c0.37,1.2,1.12,1.88,2.25,2.31
    C100.86,76.43,100.16,77.15,99.75,78.32z"/>
    <path class="st0" d="M79.09,87.58c-1.33,0.35-1.91,1.12-2.41,2.12c-0.48-1.02-1.08-1.77-2.23-2.07c1-0.53,1.87-1.08,2.19-2.33
    C77.17,86.33,77.76,87.13,79.09,87.58z"/>
    <path class="st0" d="M82.44,50.74c0.55,1.09,1.12,1.86,2.3,2.14c-1.07,0.53-1.97,1.12-2.21,2.46c-0.47-1.13-1.07-1.97-2.33-2.37
    C81.28,52.53,82.01,51.93,82.44,50.74z"/>
    <path class="st0" d="M99.75,67.84c0.35,1.23,1.06,1.9,2.15,2.38c-1,0.55-1.79,1.15-2.1,2.37c-0.4-1.14-1.1-1.84-2.22-2.33
    C98.67,69.74,99.45,69.12,99.75,67.84z"/>
    <path class="st0" d="M117.05,79.47c0.41,1.12,1.02,1.94,2.32,2.26c-1.15,0.48-1.89,1.13-2.32,2.27c-0.44-1.13-1.19-1.8-2.24-2.27
    C115.98,81.37,116.66,80.64,117.05,79.47z"/>
    <path class="st0" d="M91.59,99.12c1.29-0.49,1.99-1.13,2.37-2.34c0.47,1.16,1.12,1.87,2.27,2.27c-1.04,0.51-1.86,1.03-2.14,2.24
    C93.5,100.3,92.98,99.43,91.59,99.12z"/>
    <path class="st0" d="M109.05,76.06c1.03-0.5,1.84-1.09,2.22-2.48c0.4,1.36,1.13,2.03,2.35,2.41c-1.16,0.46-1.95,1.07-2.26,2.27
    C110.82,77.25,110.23,76.4,109.05,76.06z"/>
    <path class="st0" d="M76.71,107.05c-0.49-1.13-1.15-1.86-2.32-2.19c1.08-0.42,1.84-1.04,2.3-2.21c0.53,1.08,1.15,1.86,2.38,2.2
    C77.86,105.19,77.17,105.9,76.71,107.05z"/>
    <path class="st0" d="M82.51,84.16c-0.47-1.28-1.17-1.97-2.43-2.39c1.36-0.38,1.97-1.19,2.41-2.22c0.56,1.31,0.9,1.65,2.19,2.18
    C83.72,82.24,82.83,82.77,82.51,84.16z"/>
    <path class="st0" d="M88.21,84.1c-0.41-1.3-1.18-1.92-2.28-2.35c1.22-0.38,1.92-1.08,2.29-2.3c0.43,1.15,1.04,1.93,2.25,2.26
    C89.44,82.25,88.63,82.86,88.21,84.1z"/>
    <path class="st0" d="M97.58,110.59c1.3-0.61,1.44-0.76,2.19-2.22c0.43,1.03,0.99,1.86,2.22,2.14c-1,0.6-1.94,1.16-2.19,2.52
    C99.41,111.75,98.64,111.05,97.58,110.59z"/>
    <path class="st0" d="M128.63,95.74c-0.46-1.3-1.14-2.01-2.31-2.41c1.1-0.46,1.9-1.07,2.23-2.34c0.47,1.14,1.09,1.94,2.32,2.26
    C129.79,93.77,128.93,94.32,128.63,95.74z"/>
    <path class="st0" d="M125.17,93.29c-1.17,0.38-1.85,1.06-2.31,2.15c-0.58-0.98-1.17-1.84-2.44-2.13c1.22-0.34,1.88-1.07,2.41-2.17
    C123.31,92.21,123.95,92.96,125.17,93.29z"/>
    <path class="st0" d="M62.89,76.04c1.15-0.44,1.84-1.09,2.26-2.31c0.52,1.23,1.19,1.94,2.5,2.27c-1.31,0.36-1.97,1.11-2.48,2.21
    C64.72,77.14,64.08,76.44,62.89,76.04z"/>
    <path class="st0" d="M88.21,55.23c-0.39-1.15-1.11-1.83-2.25-2.27c0.9-0.36,1.56-0.79,1.95-1.54c0.2-0.39,0.43-0.49,0.66-0.01
    c0.36,0.76,0.99,1.22,1.92,1.51C89.43,53.43,88.62,54.06,88.21,55.23z"/>
    <path class="st0" d="M93.97,85.37c0.42,1.15,1.23,1.76,2.37,2.24c-1.22,0.28-1.88,1-2.32,2.16c-0.48-1.07-1.06-1.86-2.31-2.18
    C92.84,87.07,93.67,86.52,93.97,85.37z"/>
    <path class="st0" d="M90.4,87.56c-1.16,0.36-1.75,1.18-2.18,2.28c-0.4-1.15-1.05-1.91-2.28-2.22c1.06-0.47,1.84-1.08,2.21-2.26
    C88.73,86.34,89.29,87.2,90.4,87.56z"/>
    <path class="st0" d="M128.58,79.53c0.59,1.32,0.92,1.65,2.18,2.19c-0.94,0.54-1.87,1.05-2.11,2.39c-0.5-1.16-1.14-1.92-2.41-2.34
    C127.53,81.39,128.17,80.59,128.58,79.53z"/>
    <path class="st0" d="M108.94,81.83c1.47-0.69,1.51-0.73,2.39-2.23c0.4,1.04,1.03,1.81,2.24,2.13c-1.07,0.48-1.89,1.09-2.24,2.32
    C110.82,82.97,110.26,82.14,108.94,81.83z"/>
    <path class="st1" d="M128.52,73.97c0.09,0,0.19-0.01,0.28-0.01c0.39,0.95,1.13,1.54,2.28,2c-1.3,0.41-2.11,1.08-2.46,2.34
    c-0.45-1.12-1.08-1.91-2.32-2.24C127.41,75.63,128.13,74.96,128.52,73.97z"/>
    <path class="st0" d="M119.34,76.04c-1.2,0.38-1.88,1.12-2.3,2.25c-0.4-1.16-1.07-1.9-2.3-2.24c1.15-0.44,1.91-1.1,2.27-2.3
    C117.49,74.85,118.17,75.61,119.34,76.04z"/>
    <path class="st0" d="M94,72.35c-0.41-1.06-1.13-1.67-2.29-2.11c1.16-0.41,1.89-1.05,2.25-2.28c0.5,1.11,1.07,1.9,2.34,2.23
    C95.16,70.72,94.37,71.3,94,72.35z"/>
    <path class="st0" d="M70.93,62.12c0.44,1.24,1.09,1.96,2.25,2.33c-1.03,0.49-1.89,1.04-2.16,2.37c-0.54-1.13-1.08-1.99-2.46-2.26
    C69.78,64.04,70.55,63.42,70.93,62.12z"/>
    <path class="st0" d="M99.75,62.18c0.41,1.19,1.11,1.91,2.31,2.26c-1.11,0.47-1.94,1.06-2.23,2.36c-0.54-1.12-1.12-1.96-2.45-2.28
    C98.67,64.09,99.35,63.36,99.75,62.18z"/>
    <path class="st0" d="M82.49,56.43c0.37,1.26,1.17,1.88,2.24,2.34c-1.17,0.36-1.85,1.08-2.24,2.25c-0.43-1.1-1.04-1.87-2.25-2.25
    C81.32,58.3,82.06,57.62,82.49,56.43z"/>
    <path class="st0" d="M108.97,64.46c1.27-0.4,1.89-1.19,2.36-2.22c0.36,1.14,1.05,1.83,2.19,2.21c-0.98,0.51-1.9,1.01-2.18,2.44
    C110.9,65.62,110.19,64.91,108.97,64.46z"/>
    <path class="st0" d="M113.6,93.3c-1.15,0.46-1.94,1.04-2.26,2.28c-0.48-1.11-1.16-1.87-2.3-2.27c1.09-0.43,1.79-1.12,2.26-2.23
    C111.72,92.19,112.39,92.91,113.6,93.3z"/>
    <path class="st0" d="M122.77,96.97c0.47,1,1.13,1.67,2.26,2.06c-1.03,0.5-1.82,1.09-2.16,2.32c-0.51-1.09-1.07-1.9-2.3-2.2
    C121.55,98.55,122.51,98.13,122.77,96.97z"/>
    <path class="st0" d="M105.53,56.59c0.51,1.01,1.05,1.71,2.17,2.04c-1.04,0.65-1.87,1.21-2.18,2.6c-0.37-1.35-1.09-2.03-2.2-2.48
    C104.35,58.3,105.02,57.68,105.53,56.59z"/>
    <path class="st0" d="M94,114.21c0.45,1.11,1.18,1.7,2.19,2.18c-1.05,0.44-1.74,1.09-2.18,2.17c-0.49-1.07-1.1-1.87-2.36-2.15
    C92.78,116,93.51,115.35,94,114.21z"/>
    <path class="st0" d="M80.09,110.6c1.31-0.39,1.94-1.14,2.38-2.25c0.43,1.11,1.05,1.88,2.29,2.2c-1.09,0.48-1.92,1.08-2.25,2.31
    C82.02,111.82,81.43,111.02,80.09,110.6z"/>
    <path class="st0" d="M122.82,89.7c-0.49-0.98-1.06-1.75-2.27-2.11c1.05-0.48,1.8-1.06,2.22-2.15c0.58,0.97,1.14,1.81,2.55,2.06
    C123.95,87.92,123.31,88.67,122.82,89.7z"/>
    <path class="st0" d="M114.84,99.08c1.16-0.5,1.82-1.18,2.18-2.28c0.43,1.02,1.02,1.8,2.19,2.23c-1.06,0.56-1.84,1.22-2.18,2.4
    C116.7,100.25,116.01,99.56,114.84,99.08z"/>
    <path class="st0" d="M80.15,104.87c1.22-0.46,1.89-1.18,2.35-2.34c0.39,1.24,1.15,1.91,2.27,2.32c-1.18,0.36-1.88,1.08-2.27,2.24
    C82.06,106,81.42,105.25,80.15,104.87z"/>
    <path class="st0" d="M117.01,85.22c0.48,1.23,1.21,1.89,2.36,2.38c-1.32,0.32-1.89,1.17-2.35,2.25c-0.34-1.19-1.06-1.87-2.21-2.26
    C115.86,87.1,116.66,86.51,117.01,85.22z"/>
    <path class="st1" d="M58.87,54.57c-0.3-0.42-0.66-0.78-1.09-1.07c0-0.34,0-0.68,0-1.01c0.44-0.28,0.81-0.64,1.11-1.07
    c0.25-0.02,0.51-0.04,0.76-0.06c0.46,0.71,1.02,1.3,2,1.55c-0.87,0.44-1.59,0.9-2,1.7C59.39,54.59,59.13,54.58,58.87,54.57z"/>
    <path class="st0" d="M76.67,101.43c-0.51-1.38-0.74-1.63-2.08-2.31c0.88-0.58,1.83-1.08,2.03-2.38c0.47,1.06,1.07,1.85,2.22,2.32
    C77.75,99.56,77.07,100.27,76.67,101.43z"/>
    <path class="st0" d="M88.17,73.63c0.51,1.27,1.18,2,2.37,2.38c-1.2,0.42-1.92,1.12-2.31,2.29c-0.41-1.16-1.07-1.91-2.27-2.25
    C87.03,75.59,87.87,75,88.17,73.63z"/>
    <path class="st0" d="M70.98,84.17c-0.47-1.31-1.21-1.96-2.34-2.42c1.19-0.39,1.92-1.07,2.28-2.28c0.41,1.13,1.05,1.89,2.22,2.23
    C72.17,82.25,71.3,82.81,70.98,84.17z"/>
    <path class="st0" d="M94.04,84.18c-0.5-1.29-1.14-2-2.4-2.36c1.25-0.45,1.89-1.16,2.35-2.27c0.47,1.14,1.1,1.89,2.31,2.14
    C95.25,82.22,94.35,82.76,94.04,84.18z"/>
    <path class="st0" d="M88.2,102.57c0.45,1.09,1.11,1.84,2.3,2.27c-1.17,0.41-1.86,1.13-2.3,2.35c-0.34-1.28-1.11-1.92-2.23-2.34
    C87.07,104.43,87.83,103.76,88.2,102.57z"/>
    <path class="st0" d="M70.91,50.88c0.86,1.36,1.03,1.51,2.22,2.04c-0.98,0.51-1.87,1.08-2.14,2.43c-0.43-1.21-1.14-1.94-2.42-2.43
    C69.86,52.62,70.44,51.82,70.91,50.88z"/>
    <path class="st0" d="M82.51,78.31c-0.47-1.11-1.05-1.92-2.34-2.21c1.13-0.52,1.89-1.17,2.33-2.33c0.4,1.18,1.13,1.88,2.33,2.25
    C83.6,76.41,82.86,77.09,82.51,78.31z"/>
    <path class="st0" d="M88.2,90.98c0.46,1.18,1.07,1.96,2.33,2.26c-1.11,0.52-1.93,1.13-2.27,2.42c-0.47-1.23-1.13-1.99-2.41-2.32
    C87.09,92.91,87.85,92.26,88.2,90.98z"/>
    <path class="st0" d="M88.21,56.43c0.45,1.16,1.1,1.8,2.16,2.26c-1.02,0.5-1.76,1.13-2.16,2.17c-0.44-1.02-1.06-1.74-2.19-2.06
    C86.98,58.25,87.83,57.66,88.21,56.43z"/>
    <path class="st0" d="M96.34,52.89c-1.11,0.49-1.95,1.04-2.28,2.37c-0.51-1.1-1.13-1.87-2.29-2.27c1.01-0.44,1.69-1.04,2.17-2.07
    C94.55,51.84,95.14,52.65,96.34,52.89z"/>
    <path class="st0" d="M82.5,91.03c0.38,1.2,1.1,1.9,2.29,2.27c-1.12,0.42-1.94,1.02-2.27,2.27c-0.5-1.11-1.14-1.87-2.31-2.26
    C81.37,92.89,82.08,92.17,82.5,91.03z"/>
    <path class="st0" d="M80.11,64.47c1.29-0.38,1.94-1.13,2.37-2.24c0.42,1.14,1.09,1.89,2.29,2.23c-1.05,0.46-1.92,0.98-2.23,2.29
    C82.03,65.66,81.39,64.89,80.11,64.47z"/>
    <path class="st0" d="M105.49,101.36c-0.37-1.22-1.11-1.82-2.18-2.29c1.04-0.46,1.82-1,2.13-2.22c0.57,0.99,1.08,1.87,2.43,2.16
    C106.66,99.5,105.94,100.2,105.49,101.36z"/>
    <path class="st0" d="M65.09,50.97c1.15,1.45,1.15,1.45,2.32,1.98c-1.01,0.4-1.76,1.02-2.22,2.15c-0.52-1.08-1.21-1.77-2.41-2.17
    C63.98,52.64,64.61,51.96,65.09,50.97z"/>
    <path class="st1" d="M85.84,116.44c1.2-0.47,1.99-1.07,2.33-2.27c0.49,1,1.1,1.76,2.27,2.17c-1.19,0.51-1.9,1.22-2.23,2.39
    C87.82,117.57,87.17,116.8,85.84,116.44z"/>
    <path class="st0" d="M125.17,70.24c-1.24,0.48-1.97,1.08-2.31,2.32c-0.47-1.09-1.02-1.87-2.24-2.23c1.04-0.59,1.86-1.1,2.16-2.2
    C123.22,69.14,123.87,69.85,125.17,70.24z"/>
    <path class="st0" d="M105.48,68.1c0.57,0.99,1.08,1.76,2.31,2.1c-1.18,0.54-1.89,1.16-2.24,2.39c-0.47-1.19-1.07-1.91-2.29-2.28
    C104.31,69.81,105.08,69.23,105.48,68.1z"/>
    <path class="st0" d="M99.89,51.2c1.14,1.02,1.98,2.06,0.22,3.3c-0.18,0.12-0.22,0.45-0.36,0.76c-0.36-1.14-1.11-1.85-2.35-2.35
    c1.17-0.25,1.71-0.97,2.23-1.7C99.72,51.2,99.81,51.2,99.89,51.2z"/>
    <path class="st1" d="M76.68,72.47c-0.4-1.12-1.02-1.82-2.2-2.15c1.03-0.53,1.87-1.06,2.16-2.27c0.56,0.96,1.05,1.8,2.32,2.14
    C77.77,70.71,77.14,71.41,76.68,72.47z"/>
    <path class="st1" d="M114.77,93.31c1.14-0.41,1.92-1.06,2.24-2.29c0.48,1.08,1.04,1.92,2.33,2.23c-1.14,0.49-1.91,1.13-2.29,2.34
    C116.64,94.39,115.94,93.68,114.77,93.31z"/>
    <path class="st3" d="M106.38,45.3c0.28,0.47,0.69,0.8,1.11,1.12c0.03,0.31,0.06,0.62,0.09,0.92c-0.86,0.4-1.51,1.02-1.96,1.98
    c-0.4-0.55-0.78-0.98-0.96-1.55c0.23,0.08,0.45,0.19,0.68,0.24c0.54,0.12,0.95-0.09,1.07-0.63c0.13-0.59-0.15-1.02-0.74-1.11
    c-0.53-0.08-0.9,0.21-0.99,0.76c-0.04,0.24-0.01,0.5-0.02,0.74c-0.45,0.03-0.72-0.37-1.1-0.5c0-0.18,0-0.36,0-0.55
    c0.71-0.33,1.18-0.9,1.55-1.57c0.37,0,0.73-0.01,1.1-0.01l-0.02-0.01C106.18,45.27,106.28,45.29,106.38,45.3z"/>
    <path class="st1" d="M103.25,87.59c1.13-0.5,1.84-1.08,2.23-2.13c0.57,0.9,1.08,1.73,2.16,2.07c-1.03,0.38-1.65,1.11-2.12,2.2
    C105.07,88.68,104.5,87.94,103.25,87.59z"/>
    <path class="st1" d="M62.93,70.26c1.19-0.47,1.82-1.13,2.27-2.29c0.37,1.16,1.02,1.81,2.1,2.27c-1,0.51-1.77,1.03-2.03,2.24
    C64.72,71.5,64.2,70.69,62.93,70.26z"/>
    <path class="st1" d="M85.98,64.46c1.1-0.34,1.78-1,2.2-2.06c0.51,0.98,1.11,1.75,2.35,2.05c-1.19,0.44-1.9,1.15-2.32,2.29
    C87.84,65.54,87.09,64.88,85.98,64.46z"/>
    <path class="st1" d="M122.58,60.26c-0.4-0.4-0.79-0.8-1.18-1.21c0.06-0.4,0.13-0.79,0.19-1.19c0.15-0.16,0.31-0.31,0.46-0.47
    c1.78-0.1,2.2,0.25,2.29,1.85c-0.33,0.33-0.66,0.66-1,0.99C123.09,60.24,122.84,60.25,122.58,60.26z"/>
    <path class="st1" d="M128.48,66.34c-0.37-0.57-0.75-1.15-1.33-1.55c0.08-0.3,0.16-0.59,0.24-0.89l-0.02,0.03
    c0.15-0.15,0.31-0.31,0.46-0.46l-0.02,0.02c1.56-0.34,2.12,0.04,2.44,1.61c-0.47,0.33-0.86,0.74-1.15,1.24
    C128.9,66.34,128.69,66.34,128.48,66.34z"/>
    <path class="st1" d="M116.89,60.43c-0.38-0.53-0.77-1.06-1.35-1.4c0.01-0.2,0.03-0.4,0.04-0.6c0.38-0.37,0.76-0.73,1.13-1.1
    c0.34,0.01,0.69,0.01,1.03,0.02c0.25,0.24,0.51,0.48,0.76,0.72c0.06,0.06,0.11,0.12,0.17,0.18l-0.02-0.02
    c0.01,0.23,0.03,0.46,0.04,0.69c-0.63,0.37-1.09,0.89-1.45,1.51C117.13,60.44,117.01,60.43,116.89,60.43z"/>
    <path class="st0" d="M76.93,45.59c0.37,0.53,0.81,0.99,1.36,1.35c0,0.16,0,0.32,0,0.48c-0.44,0.43-0.89,0.85-1.33,1.28
    c-0.17,0.01-0.34,0.02-0.51,0.02c-0.37-0.47-0.77-0.9-1.27-1.24c0-0.19,0.01-0.37,0.01-0.56c0.51-0.38,0.95-0.83,1.32-1.33
    C76.65,45.59,76.79,45.59,76.93,45.59z"/>
    <path class="st0" d="M111.6,57.27c0.35,0.48,0.78,0.87,1.25,1.22c0,0.14,0.01,0.28,0.01,0.43c-0.6,0.57-1.31,1.06-1.5,2.09
    c-0.42-0.85-0.86-1.53-1.55-2c0-0.18,0-0.37,0.01-0.55c0.4-0.4,0.8-0.79,1.2-1.19C111.21,57.28,111.41,57.27,111.6,57.27z"/>
    <path class="st1" d="M124.4,64.75c-0.68,0.43-1.22,0.98-1.59,1.97c-0.39-0.93-0.87-1.54-1.54-1.97c0.05-0.19,0.1-0.38,0.15-0.58
    c0.32-0.3,0.64-0.61,0.97-0.91c0.45-0.01,0.9,0.02,1.32,0.2c0.14,0.11,0.28,0.23,0.42,0.34C124.22,64.12,124.31,64.43,124.4,64.75
    z"/>
    <path class="st1" d="M117.51,63.27c0.34,0.31,0.68,0.62,1.02,0.93c0.05,0.17,0.11,0.35,0.16,0.52c-0.82,0.4-1.28,1.12-1.66,1.99
    c-0.41-1.12-1.08-1.87-2.27-2.23c0.77-0.22,1.27-0.63,1.75-1.07C116.85,63.36,117.18,63.31,117.51,63.27z"/>
    <path class="st1" d="M82.67,45.58c0.41,0.52,0.79,1.07,1.42,1.36c0.01,0.16,0.02,0.33,0.03,0.5c-0.58,0.29-0.98,0.78-1.37,1.27
    c-0.18,0-0.36-0.01-0.54-0.01c-0.38-0.47-0.78-0.93-1.31-1.25c0.01-0.17,0.01-0.34,0.02-0.51c0.54-0.35,0.98-0.8,1.32-1.35
    C82.38,45.58,82.52,45.58,82.67,45.58z"/>
    <path class="st1" d="M70.64,48.69c-0.31-0.49-0.71-0.89-1.21-1.19c0-0.19-0.01-0.38-0.01-0.58c0.51-0.37,0.96-0.79,1.28-1.35
    c0.16,0,0.31,0,0.47,0c0.36,0.58,0.8,1.07,1.42,1.38c0,0.15-0.01,0.31-0.01,0.46c-0.55,0.32-0.99,0.75-1.34,1.28
    C71.04,48.69,70.84,48.69,70.64,48.69z"/>
    <path class="st0" d="M66.7,47.48c-0.42,0.39-0.84,0.78-1.26,1.17c-0.19,0-0.38,0-0.56-0.01c-0.37-0.38-0.74-0.75-1.11-1.13
    c-0.01-0.2-0.02-0.41-0.04-0.61c0.39-0.4,0.79-0.81,1.18-1.21c0.17,0,0.33-0.01,0.5-0.01c0.43,0.41,0.86,0.82,1.29,1.22
    C66.7,47.1,66.7,47.29,66.7,47.48z"/>
    <path class="st1" d="M88.49,45.69c0.41,0.41,0.83,0.82,1.24,1.24c-0.01,0.19-0.02,0.38-0.02,0.57c-0.45,0.33-0.86,0.7-1.18,1.16
    c-0.21,0-0.41,0-0.62-0.01c-0.38-0.38-0.75-0.76-1.13-1.14c0-0.2-0.01-0.4-0.01-0.61c0.44-0.34,0.85-0.71,1.14-1.18
    C88.11,45.71,88.3,45.7,88.49,45.69z"/>
    <path class="st1" d="M106.92,53.31c-0.34,0.34-0.68,0.67-1.01,1.01c-0.25,0-0.5-0.01-0.74-0.01c-0.3-0.31-0.6-0.61-0.9-0.92
    c0-0.28,0-0.56,0-0.85c0.29-0.28,0.57-0.57,0.86-0.85c0.3,0,0.6,0,0.9,0c0,0-0.03,0-0.03,0c0.06,0.04,0.11,0.09,0.17,0.13
    c0.24,0.21,0.48,0.42,0.72,0.62C106.88,52.73,106.9,53.02,106.92,53.31z"/>
    <path class="st0" d="M92.68,46.8c0.32-0.33,0.64-0.66,0.96-0.99c0.26,0.02,0.52,0.03,0.78,0.05c0.3,0.29,0.59,0.57,0.89,0.86
    c0,0.28,0,0.55,0,0.83c-0.31,0.3-0.61,0.6-0.92,0.9c-0.28-0.01-0.56-0.01-0.84-0.02c-0.29-0.28-0.57-0.55-0.86-0.83
    C92.7,47.34,92.69,47.07,92.68,46.8z"/>
    <path class="st1" d="M112.59,53.38c-0.31,0.31-0.63,0.63-0.94,0.94c-0.24,0-0.49,0-0.73,0c-0.33-0.33-0.66-0.66-0.99-0.99
    c0.06-0.32,0.11-0.64,0.17-0.96c0.17-0.17,0.35-0.35,0.52-0.52C112.02,51.53,112.48,51.88,112.59,53.38z"/>
    <path class="st1" d="M116.07,53.62c-0.02-0.18-0.04-0.36-0.04-0.53c-0.02-0.68,0.3-1.12,0.99-1.15c0.63-0.03,1.03,0.35,1.08,0.96
    c0.06,0.67-0.3,1.08-0.98,1.16c-0.14,0.02-0.28,0.03-0.43,0.04c0,0,0.02,0.01,0.02,0.01c-0.11-0.07-0.21-0.14-0.32-0.21
    C116.27,53.81,116.17,53.71,116.07,53.62z"/>
    <path class="st1" d="M71.82,40.57l0.01,0.09c0,0,0.09,0.01,0.09,0.01c0.02,0.45,0.19,0.9,0.03,1.36
    c-0.16,0.16-0.32,0.32-0.48,0.48c-0.38-0.01-0.77-0.03-1.15-0.04c-0.1-0.11-0.21-0.23-0.31-0.34c-0.01-0.46-0.02-0.91-0.03-1.36
    l-0.02,0.02c0.2,0.04,0.24-0.08,0.24-0.24l-0.02,0.02C70.73,40.45,71.27,40.47,71.82,40.57z"/>
    <path class="st0" d="M77.81,41.98c-0.19,0.18-0.38,0.35-0.58,0.53c-0.35,0-0.7,0-1.05,0c-0.12-0.1-0.23-0.19-0.35-0.29
    c-0.06-0.12-0.13-0.25-0.19-0.37l0.02,0.02c0-0.29,0-0.59,0-0.88l-0.02,0.02c0.18,0.02,0.21-0.11,0.23-0.24l-0.02,0.02
    c0.08-0.08,0.16-0.16,0.24-0.24l-0.02,0.02c0.45-0.09,0.91-0.22,1.33,0.09c0.03,0.04,0.06,0.07,0.1,0.09
    c0.11,0.08,0.22,0.17,0.32,0.25l-0.02-0.02C77.81,41.32,77.81,41.65,77.81,41.98z"/>
    <path class="st3" d="M98.92,47.96c-0.27-0.2-0.55-0.39-0.82-0.59c0-0.15,0.01-0.29,0.01-0.44c0.59-0.38,1.08-0.86,1.42-1.49
    c0.26,0.02,0.52,0.03,0.78,0.05c0.34,0.41,0.66,0.83,1.14,1.09c0.04,0.25,0.07,0.5,0.11,0.75c-0.26,0.15-0.52,0.3-0.78,0.45
    c-0.05-0.04-0.1-0.08-0.15-0.12c0.16-0.49,0.12-0.94-0.34-1.25c-0.29-0.2-0.61-0.21-0.92-0.02c-0.55,0.34-0.45,0.85-0.34,1.36
    C98.99,47.81,98.96,47.89,98.92,47.96z"/>
    <path class="st0" d="M81.64,42.29l-0.01-0.08l-0.08-0.01c0-0.48,0-0.95,0-1.43l-0.02,0.02c0.28,0.18,0.34-0.08,0.46-0.23
    l-0.01,0.01c0.36,0,0.73,0,1.09,0l-0.01-0.02c0.08,0.08,0.16,0.16,0.24,0.23l-0.02-0.02c0.03,0.13,0.06,0.25,0.23,0.24l-0.01-0.01
    c-0.03,0.3-0.06,0.59-0.09,0.89c-0.18,0.18-0.35,0.35-0.53,0.53c-0.3,0.03-0.6,0.06-0.9,0.09c0,0,0.02,0.02,0.02,0.02
    C81.97,42.3,81.8,42.3,81.64,42.29z"/>
    <path class="st0" d="M66.06,42.11c-0.06,0.07-0.12,0.13-0.18,0.2c-0.1,0.07-0.19,0.15-0.29,0.22l0.02-0.02
    c-0.39-0.03-0.8-0.01-1.11-0.31c-0.02-0.04-0.05-0.07-0.09-0.1c-0.3-0.42-0.04-0.88-0.1-1.32l-0.02,0.02
    c0.21,0.06,0.29-0.02,0.24-0.24l-0.02,0.02c0.44,0,0.88,0,1.32,0l-0.02-0.02c0,0.16,0.1,0.22,0.24,0.24l-0.02-0.02
    c0.07,0.37,0.15,0.74,0.22,1.1l0.02-0.02C66.19,41.94,66.12,42.02,66.06,42.11z"/>
    <path class="st3" d="M112.59,53.38c-0.12-1.5-0.57-1.85-1.96-1.53c0.19-0.41,0.38-0.81,0.57-1.22c0.17,0.01,0.35,0.02,0.52,0.03
    c0.35,0.91,0.99,1.53,1.91,1.87c0.01,0.14,0.01,0.29,0.02,0.43C113.3,53.1,112.94,53.24,112.59,53.38z"/>
    <path class="st3" d="M123.7,63.46c-0.42-0.18-0.87-0.21-1.32-0.2c0.15-0.33,0.29-0.66,0.44-0.99c0.07,0,0.14-0.01,0.22-0.02
    C123.26,62.65,123.48,63.06,123.7,63.46z"/>
    <path class="st3" d="M99.57,48.89c-0.18-0.27-0.36-0.54-0.54-0.82c0.07-0.04,0.15-0.08,0.22-0.11c0.51,0.13,0.99,0.14,1.38-0.3
    c0.05,0.04,0.1,0.08,0.15,0.12c-0.28,0.37-0.56,0.74-0.84,1.11C99.81,48.89,99.69,48.89,99.57,48.89z"/>
    <path class="st3" d="M121.59,57.86c-0.06,0.4-0.13,0.79-0.19,1.19c-0.33-0.12-0.67-0.25-1-0.37c0.01-0.07,0.01-0.13,0-0.2
    C120.8,58.27,121.19,58.06,121.59,57.86z"/>
    <path class="st3" d="M117.74,57.36c-0.34-0.01-0.69-0.01-1.03-0.02c0.13-0.34,0.26-0.69,0.39-1.03l0.11,0.01l0.11-0.01
    C117.46,56.66,117.6,57.01,117.74,57.36z"/>
    <path class="st3" d="M133.97,72c0.42-0.06,0.85-0.12,1.27-0.19c-0.11,0.28-0.22,0.57-0.33,0.85c-0.24,0.01-0.48,0.02-0.73,0.04
    C134.12,72.47,134.05,72.23,133.97,72z"/>
    <path class="st3" d="M117.51,63.27c-0.33,0.05-0.67,0.09-1,0.14c0.17-0.39,0.35-0.79,0.51-1.15
    C117.17,62.56,117.34,62.91,117.51,63.27z"/>
    <path class="st3" d="M124.4,64.75c-0.09-0.32-0.18-0.63-0.27-0.95c0.37,0.15,0.75,0.31,1.12,0.46c0.03,0.07,0.06,0.14,0.09,0.22
    C125.03,64.57,124.71,64.66,124.4,64.75z"/>
    <path class="st3" d="M122.58,60.26c0.25-0.01,0.51-0.01,0.76-0.02c-0.11,0.35-0.23,0.7-0.34,1.04c-0.05-0.01-0.1-0.01-0.15-0.01
    C122.76,60.93,122.67,60.59,122.58,60.26z"/>
    <path class="st3" d="M132.8,69.6c-0.05,0.34-0.11,0.69-0.16,1.03c-0.22-0.07-0.45-0.14-0.67-0.21c0-0.18,0-0.36,0-0.54
    C132.24,69.79,132.52,69.69,132.8,69.6z"/>
    <path class="st3" d="M127.39,63.9c-0.08,0.3-0.16,0.59-0.24,0.89c-0.31-0.1-0.62-0.2-0.93-0.29c0.01-0.08,0.02-0.16,0.02-0.25
    C126.65,64.2,126.94,63.79,127.39,63.9z"/>
    <path class="st3" d="M118.7,58.93c-0.01-0.23-0.03-0.46-0.04-0.69c0.34-0.05,0.61,0.15,0.91,0.25c0,0.06,0,0.12,0,0.18
    C119.28,58.76,118.99,58.84,118.7,58.93z"/>
    <path class="st3" d="M110.1,52.36c-0.06,0.32-0.11,0.64-0.17,0.96c-0.31-0.12-0.62-0.24-0.93-0.36c0.01-0.05,0.01-0.1,0-0.14
    C109.37,52.67,109.74,52.52,110.1,52.36z"/>
    <path class="st3" d="M104.26,52.54c0,0.28,0,0.56,0,0.85c-0.25-0.12-0.5-0.25-0.78-0.38C103.7,52.71,103.99,52.64,104.26,52.54z"
    />
    <path class="st3" d="M106.01,51.69c-0.3,0-0.6,0-0.9,0c0.12-0.28,0.12-0.74,0.52-0.7C105.88,51.02,106.02,51.38,106.01,51.69z"/>
    <path class="st3" d="M111.6,57.27c-0.2,0-0.39,0-0.59,0c0.07-0.23,0.14-0.46,0.27-0.86C111.43,56.81,111.52,57.04,111.6,57.27z"/>
    <path class="st3" d="M128.48,66.34c0.21,0,0.42,0,0.62-0.01c-0.08,0.33-0.17,0.66-0.25,1c-0.06-0.01-0.12-0.03-0.17-0.05
    C128.51,66.99,128.48,66.67,128.48,66.34z"/>
    <path class="st3" d="M106.92,53.31c-0.02-0.29-0.04-0.58-0.05-0.87c0.23,0.11,0.45,0.23,0.68,0.34c-0.02,0.07-0.02,0.14,0,0.21
    C107.33,53.11,107.12,53.21,106.92,53.31z"/>
    <path class="st3" d="M115.58,58.43c-0.01,0.2-0.03,0.4-0.04,0.6c-0.19-0.07-0.39-0.14-0.63-0.23
    C115.07,58.53,115.32,58.46,115.58,58.43z"/>
    <path class="st3" d="M110.92,54.32c0.24,0,0.49,0,0.73,0c-0.09,0.27-0.19,0.53-0.29,0.82C111.09,54.91,111.02,54.6,110.92,54.32z"
    />
    <path class="st3" d="M121.42,64.17c-0.05,0.19-0.1,0.38-0.15,0.58c-0.22-0.08-0.45-0.16-0.78-0.29
    C120.88,64.34,121.15,64.25,121.42,64.17z"/>
    <path class="st3" d="M105.16,54.31c0.25,0,0.5,0.01,0.74,0.01c-0.11,0.21-0.09,0.59-0.34,0.6
    C105.23,54.93,105.28,54.53,105.16,54.31z"/>
    <path class="st3" d="M109.82,58.46c0,0.18,0,0.37-0.01,0.55c-0.19-0.12-0.58-0.02-0.58-0.27
    C109.24,58.49,109.59,58.49,109.82,58.46z"/>
    <path class="st3" d="M118.7,64.72c-0.05-0.17-0.11-0.35-0.16-0.52c0.23,0.07,0.46,0.15,0.8,0.25
    C119.07,64.56,118.89,64.64,118.7,64.72z"/>
    <path class="st3" d="M112.87,58.92c0-0.14-0.01-0.28-0.01-0.43c0.26-0.01,0.45,0.14,0.64,0.29
    C113.29,58.83,113.08,58.88,112.87,58.92z"/>
    <path class="st3" d="M116.89,60.43c0.12,0,0.24,0.01,0.36,0.01c-0.07,0.19-0.14,0.38-0.24,0.66
    C116.96,60.82,116.92,60.63,116.89,60.43z"/>
    <path class="st3" d="M116.38,53.9c0.11,0.07,0.21,0.14,0.32,0.21C116.49,54.2,116.47,53.99,116.38,53.9z"/>
    <path class="st3" d="M106.15,51.82c-0.06-0.04-0.11-0.09-0.17-0.13C106.03,51.73,106.09,51.78,106.15,51.82z"/>
    <path class="st3" d="M99.03,48.07c-0.05-0.03-0.09-0.06-0.11-0.11c0.04-0.07,0.08-0.14,0.11-0.22c0.07,0.07,0.14,0.14,0.22,0.22
    C99.17,47.99,99.1,48.03,99.03,48.07z"/>
    <path class="st2" d="M82.22,119.38c-0.04,1.44-1.27,2.59-2.71,2.55c-1.5-0.05-2.62-1.27-2.57-2.8c0.05-1.42,1.32-2.61,2.72-2.56
    C81.14,116.6,82.26,117.85,82.22,119.38z"/>
    <path class="st2" d="M116.74,113.5c-0.01,1.51-1.12,2.61-2.62,2.61c-1.38,0-2.62-1.29-2.61-2.7c0.01-1.34,1.24-2.52,2.64-2.54
    C115.62,110.86,116.75,112,116.74,113.5z"/>
    <path class="st2" d="M62.22,93c-1.45-0.02-2.52-1.11-2.52-2.57c0-1.44,1.2-2.64,2.62-2.64c1.41,0.01,2.64,1.27,2.59,2.68
    C64.86,91.98,63.76,93.02,62.22,93z"/>
    <path class="st2" d="M68.05,110.24c-1.43,0-2.48-1.04-2.5-2.46c-0.02-1.51,1.01-2.58,2.48-2.59c1.48-0.01,2.59,1.08,2.59,2.51
    C70.63,109.14,69.51,110.24,68.05,110.24z"/>
    <path class="st2" d="M128.04,108.73c-0.36,0.52-0.78,0.98-1.33,1.31c-0.32,0.07-0.64,0.14-0.95,0.21
    c-1.65-0.26-2.48-1.06-2.53-2.44c-0.04-1.25,0.71-2.23,1.93-2.53c1.49-0.36,2.63,0.57,3.09,2.53
    C128.18,108.12,128.11,108.42,128.04,108.73z"/>
    <path class="st2" d="M62.29,93.79c1.38,0,2.43,1.03,2.43,2.38c0,1.37-1.03,2.44-2.37,2.47c-1.39,0.03-2.47-1.05-2.46-2.47
    C59.9,94.74,60.86,93.79,62.29,93.79z"/>
    <path class="st2" d="M108.43,121.6c-1.38,0.01-2.41-1.01-2.41-2.39c0-1.27,1.08-2.35,2.36-2.37c1.31-0.02,2.42,1.1,2.42,2.43
    C110.79,120.57,109.75,121.59,108.43,121.6z"/>
    <path class="st2" d="M91.14,127.3c-1.34,0.01-2.36-0.98-2.36-2.3c0-1.28,1.07-2.33,2.37-2.33c1.21,0,2.32,1.1,2.32,2.28
    C93.45,126.3,92.47,127.29,91.14,127.3z"/>
    <path class="st2" d="M76.11,119.28c-0.01,1.35-1,2.32-2.34,2.29c-1.25-0.02-2.26-1.04-2.27-2.29c-0.01-1.34,0.96-2.31,2.32-2.32
    C75.21,116.96,76.12,117.88,76.11,119.28z"/>
    <path class="st2" d="M87.65,125.01c-0.01,1.37-0.95,2.31-2.33,2.3c-1.37-0.01-2.3-0.97-2.28-2.34c0.03-1.3,1.08-2.31,2.39-2.29
    C86.65,122.7,87.66,123.76,87.65,125.01z"/>
    <path class="st2" d="M68.05,115.7c-1.35,0-2.26-0.91-2.25-2.25c0.01-1.27,0.93-2.18,2.21-2.19c1.33-0.02,2.34,0.92,2.35,2.2
    C70.38,114.7,69.35,115.7,68.05,115.7z"/>
    <path class="st2" d="M96.83,127.26c-1.3-0.01-2.24-0.98-2.21-2.29c0.02-1.2,1.02-2.23,2.19-2.26c1.24-0.03,2.42,1.13,2.37,2.33
    C99.13,126.31,98.11,127.27,96.83,127.26z"/>
    <path class="st2" d="M62.31,99.72c1.34,0,2.25,0.94,2.22,2.29c-0.02,1.23-1.01,2.24-2.2,2.25c-1.23,0.01-2.3-1.04-2.32-2.28
    C59.99,100.73,61.02,99.71,62.31,99.72z"/>
    <path class="st2" d="M77.39,124.96c0.02-1.26,0.97-2.15,2.26-2.12c1.26,0.03,2.16,0.98,2.13,2.26c-0.03,1.23-1.03,2.19-2.26,2.15
    C78.3,127.21,77.37,126.21,77.39,124.96z"/>
    <path class="st2" d="M64.31,107.72c-0.01,1.13-0.87,2.03-1.97,2.05c-1.09,0.02-2.13-0.99-2.13-2.07c0-1.04,0.99-2.04,2.03-2.05
    C63.41,105.65,64.32,106.55,64.31,107.72z"/>
    <path class="st2" d="M70.01,119.2c0.02,1.22-0.73,1.99-1.94,1.99c-1.2,0-2.03-0.81-2.01-1.96c0.01-1.12,0.92-2.01,2.03-2
    C69.12,117.25,69.99,118.14,70.01,119.2z"/>
    <path class="st2" d="M73.79,123.08c1.13-0.02,2.05,0.85,2.04,1.93c-0.01,1.12-0.88,1.99-2.01,2c-1.08,0-1.91-0.81-1.95-1.91
    C71.84,123.9,72.6,123.09,73.79,123.08z"/>
    <path class="st2" d="M64.19,113.52c-0.02,1-0.89,1.85-1.91,1.86c-0.97,0.01-1.88-0.89-1.89-1.88c-0.01-1.05,0.92-1.96,1.96-1.92
    C63.33,111.61,64.21,112.54,64.19,113.52z"/>
    <path class="st2" d="M91.23,129c0.95,0.06,1.69,0.87,1.66,1.82c-0.03,1.05-0.89,1.78-1.98,1.69c-0.94-0.08-1.61-0.9-1.52-1.89
    C89.46,129.65,90.27,128.95,91.23,129z"/>
    <path class="st2" d="M87.14,130.84c-0.02,0.96-0.79,1.68-1.79,1.68c-1.02,0-1.83-0.82-1.78-1.79c0.05-0.93,0.88-1.73,1.79-1.72
    C86.33,129.01,87.16,129.87,87.14,130.84z"/>
    <path class="st2" d="M69.75,125.01c0.01,0.98-0.76,1.75-1.71,1.72c-0.93-0.02-1.73-0.85-1.71-1.78c0.02-0.91,0.76-1.63,1.69-1.64
    C69.02,123.31,69.74,124.02,69.75,125.01z"/>
    <path class="st2" d="M79.61,129.04c0.88,0.02,1.72,0.9,1.7,1.79c-0.02,0.95-0.83,1.7-1.79,1.66c-0.89-0.04-1.64-0.81-1.66-1.7
    C77.85,129.91,78.73,129.02,79.61,129.04z"/>
    <path class="st2" d="M63.98,124.99c0.03,0.85-0.67,1.59-1.54,1.63c-1,0.05-1.81-0.66-1.82-1.58c-0.01-0.85,0.8-1.67,1.65-1.69
    C63.12,123.33,63.95,124.12,63.98,124.99z"/>
    <path class="st2" d="M62.25,117.62c0.97-0.03,1.64,0.62,1.65,1.59c0.02,0.96-0.65,1.67-1.59,1.7c-0.88,0.02-1.65-0.72-1.7-1.63
    C60.58,118.41,61.32,117.65,62.25,117.62z"/>
    <path class="st2" d="M73.83,129.2c0.87,0.01,1.59,0.71,1.59,1.57c0.01,0.88-0.7,1.62-1.55,1.63c-0.86,0.01-1.7-0.82-1.68-1.66
    C72.2,129.91,72.97,129.19,73.83,129.2z"/>
    <path class="st2" d="M69.43,130.72c-0.05,0.88-0.52,1.42-1.35,1.43c-0.81,0.01-1.37-0.46-1.38-1.34
    c-0.01-0.87,0.47-1.35,1.33-1.38C68.9,129.4,69.34,129.91,69.43,130.72z"/>
    <path class="st1" d="M77.45,136.36c-0.05-0.03-0.1-0.06-0.14-0.1c-0.01-0.01,0.03-0.08,0.05-0.08c0.06-0.01,0.12,0,0.17,0.01
    C77.51,136.24,77.48,136.3,77.45,136.36z"/>
    <path class="st2" d="M131.96,59.43c0.91,0.3,1.52,0.88,1.8,1.8c-0.01,0.37-0.01,0.75-0.02,1.12c-0.37,0.78-0.9,1.38-1.77,1.62
    c-0.3,0-0.6,0.01-0.89,0.01c-0.92-0.21-1.48-0.8-1.83-1.64c-0.04-0.35-0.08-0.7-0.12-1.05l-0.01,0.02l0.06-0.01l-0.01-0.06
    c0.34-0.91,0.94-1.56,1.9-1.84C131.38,59.41,131.67,59.42,131.96,59.43z"/>
    <path class="st2" d="M126.16,53.63c0.88,0.3,1.5,0.86,1.74,1.79c0,0.33-0.01,0.66-0.01,0.99c-0.29,0.81-0.86,1.33-1.65,1.64
    c-0.33,0-0.67,0.01-1,0.01c-0.89-0.27-1.44-0.89-1.78-1.73c0-0.3,0-0.61,0-0.91c0.31-0.88,0.86-1.51,1.78-1.79
    C125.55,53.63,125.86,53.63,126.16,53.63z"/>
    <path class="st2" d="M137.71,65.4c0.83,0.28,1.33,0.85,1.51,1.71c-0.01,0.28-0.02,0.57-0.03,0.85c-0.29,0.62-0.77,1.05-1.36,1.38
    c-0.38,0.01-0.77,0.01-1.15,0.02c-0.63-0.3-1.1-0.77-1.42-1.38c-0.01-0.28-0.02-0.55-0.04-0.83c0.16-0.93,0.74-1.48,1.61-1.77
    C137.12,65.39,137.42,65.4,137.71,65.4z"/>
    <path class="st1" d="M140.89,64.76c-0.03,0.51-0.31,0.75-0.76,0.74c-0.41,0-0.68-0.22-0.71-0.65c-0.04-0.49,0.24-0.76,0.69-0.78
    C140.57,64.04,140.81,64.34,140.89,64.76z"/>
    <path class="st1" d="M151.74,86.58c0.58,0.04,0.95,0.38,0.97,1.02c0.01,0.65-0.35,1.03-0.99,1.06c-0.7,0.03-1.12-0.4-1.11-1.07
    C150.62,86.97,151.03,86.61,151.74,86.58z"/>
    <path class="st1" d="M152.73,75.89c-0.03,0.73-0.31,1.11-0.97,1.16c-0.72,0.05-1.12-0.39-1.15-1.01
    c-0.03-0.65,0.41-1.08,1.09-1.08C152.33,74.97,152.66,75.37,152.73,75.89z"/>
    <path class="st1" d="M152.74,81.92c-0.03,0.57-0.37,0.97-1,0.98c-0.68,0.02-1.1-0.37-1.09-1.06c0.01-0.68,0.43-1.09,1.11-1.05
    C152.36,80.83,152.73,81.2,152.74,81.92z"/>
    <path class="st1" d="M146.99,70.24c-0.09,0.61-0.38,1.09-1.08,1.09c-0.63,0-1.01-0.35-1.04-1c-0.03-0.71,0.38-1.09,1.04-1.11
    C146.53,69.2,146.87,69.62,146.99,70.24z"/>
    <path class="st2" d="M114.6,52.55c-0.32-0.01-0.65-0.02-0.97-0.03c-0.91-0.34-1.55-0.96-1.91-1.87c0.03-0.43,0.05-0.87,0.08-1.3
    c0.33-0.81,0.89-1.38,1.73-1.66c0.44,0,0.87,0,1.31,0c0.84,0.32,1.41,0.9,1.71,1.74c0.02,0.37,0.05,0.75,0.07,1.12
    C116.29,51.56,115.61,52.23,114.6,52.55z"/>
    <path class="st2" d="M119.47,52.17c-0.82-0.23-1.31-0.83-1.65-1.57c0.04-0.42,0.07-0.84,0.11-1.26c0.27-0.64,0.78-1.03,1.36-1.34
    c0.52,0.03,1.04,0.07,1.56,0.1c0.34,0.33,0.67,0.66,1.01,0.99c0.12,0.5,0.19,1.01,0.13,1.52c-0.25,0.79-0.8,1.28-1.55,1.56
    C120.12,52.16,119.79,52.16,119.47,52.17z"/>
    <path class="st1" d="M145.86,95.02c-0.91-0.02-1.64-0.77-1.65-1.68c-0.01-0.96,0.77-1.71,1.74-1.7c0.99,0.02,1.67,0.76,1.62,1.78
    C147.52,94.37,146.81,95.04,145.86,95.02z"/>
    <path class="st3" d="M127.89,56.4c0.62-0.2,1.23-0.04,1.83,0.09c0.26,0.51,0.66,0.86,1.16,1.12c0.2,0.58,0.27,1.17,0.21,1.78
    c-0.96,0.27-1.56,0.92-1.9,1.84c0,0-0.06,0.01-0.06,0.01l0,0.06l0.01-0.02c-0.25,0-0.5,0-0.75-0.01c-0.1-0.66-0.55-1.15-0.8-1.74
    c0.16,0.08,0.31,0.16,0.47,0.24c0.57,0.32,1.07,0.16,1.44-0.29c0.36-0.43,0.36-0.95,0.01-1.39c-0.34-0.43-0.8-0.61-1.34-0.36
    c-0.55,0.26-0.81,0.69-0.68,1.3c0.04,0.17,0.07,0.35,0.11,0.52c-0.5-0.22-1-0.45-1.5-0.67c0.04-0.28,0.09-0.57,0.13-0.85
    C127.03,57.74,127.6,57.21,127.89,56.4z"/>
    <path class="st2" d="M142.92,86.65c-1.06-0.2-1.82-0.69-1.8-1.91c0.02-1.14,0.67-1.69,1.73-1.85c0.29,0.05,0.58,0.1,0.87,0.16
    c0.39,0.26,0.7,0.6,0.95,1c0.02,0.11-0.03,0.29,0.2,0.2l-0.01-0.01c-0.06,0.4-0.12,0.79-0.18,1.19c-0.26,0.42-0.56,0.8-1,1.06
    C143.42,86.53,143.17,86.59,142.92,86.65z"/>
    <path class="st1" d="M145.9,85.95c1-0.02,1.77,0.71,1.76,1.68c0,0.92-0.73,1.64-1.67,1.66c-1.01,0.02-1.75-0.69-1.74-1.68
    C144.25,86.66,144.95,85.97,145.9,85.95z"/>
    <path class="st2" d="M142.88,80.93c-0.92-0.31-1.71-0.73-1.78-1.86c-0.07-1.05,0.45-1.68,1.36-2.07c0.38,0,0.75,0,1.13,0
    c0.6,0.29,1.05,0.72,1.32,1.34c-0.01,0.41-0.01,0.82-0.02,1.23c-0.29,0.52-0.67,0.93-1.2,1.21
    C143.41,80.82,143.14,80.88,142.88,80.93z"/>
    <path class="st1" d="M145.9,80.13c0.91-0.01,1.64,0.72,1.67,1.66c0.03,1-0.7,1.78-1.66,1.78c-0.95,0-1.72-0.8-1.7-1.76
    C144.23,80.91,145,80.15,145.9,80.13z"/>
    <path class="st1" d="M147.65,76.01c0,0.95-0.78,1.71-1.74,1.7c-0.96-0.01-1.72-0.78-1.69-1.73c0.02-0.9,0.76-1.63,1.67-1.65
    C146.82,74.3,147.65,75.09,147.65,76.01z"/>
    <path class="st2" d="M101.44,42c0.78-0.29,1.56-0.29,2.34-0.04c0.49,0.34,0.91,0.75,1.22,1.27c0.25,0.63,0.13,1.29,0.13,1.94
    c-0.36,0.67-0.83,1.24-1.55,1.57c-0.73,0.19-1.45,0.18-2.14-0.16c-0.48-0.26-0.8-0.68-1.14-1.09c-0.26-0.79-0.27-1.58,0-2.37
    C100.68,42.74,101.06,42.37,101.44,42z"/>
    <path class="st1" d="M145.98,97.76c0.81,0.08,1.25,0.58,1.23,1.35c-0.02,0.79-0.56,1.28-1.34,1.24c-0.77-0.04-1.26-0.52-1.23-1.35
    C144.66,98.19,145.17,97.81,145.98,97.76z"/>
    <path class="st3" d="M122,50.61c0.71-0.06,1.41-0.11,2.09,0.2c0.31,0.3,0.62,0.6,0.93,0.9c0.24,0.62,0.35,1.26,0.22,1.92
    c-0.92,0.27-1.48,0.9-1.78,1.79c-0.29,0.01-0.58,0.02-0.87,0.04c-0.34-1.14-1.05-1.92-2.23-2.24c0.03-0.35,0.05-0.7,0.08-1.05
    C121.2,51.88,121.75,51.39,122,50.61z"/>
    <path class="st3" d="M142.08,68.65c0.65,0.89,0.67,1.84,0.32,2.84c-0.47,0.21-0.78,0.58-1.05,0.99c-0.59,0.23-1.2,0.33-1.83,0.18
    c-0.11-0.3-0.23-0.6-0.34-0.9c0.14,0.04,0.28,0.09,0.42,0.12c1.12,0.26,2.05-0.29,2.22-1.3c0.17-1.05-0.57-2-1.6-2.04
    c-1.04-0.04-1.74,0.74-1.71,1.93c0,0.22,0.02,0.43,0.03,0.65c-0.28-0.1-0.56-0.2-0.84-0.31c-0.05-0.5-0.03-1,0.14-1.48
    c0.59-0.33,1.07-0.76,1.36-1.38c0.91-0.31,1.75-0.13,2.56,0.33C141.87,68.41,141.98,68.53,142.08,68.65z"/>
    <path class="st1" d="M140.17,106.25c-0.79,0.01-1.42-0.62-1.45-1.44c-0.02-0.86,0.64-1.55,1.46-1.54c0.75,0.01,1.41,0.72,1.4,1.5
    C141.58,105.56,140.93,106.24,140.17,106.25z"/>
    <path class="st1" d="M100.16,41.47c-0.01,0.19-0.05,0.36-0.27,0.39c-0.25,0.04-0.41-0.07-0.44-0.32c-0.02-0.2,0.06-0.34,0.27-0.38
    C99.97,41.1,100.11,41.23,100.16,41.47z"/>
    <path class="st3" d="M136.56,63.26c0.17,0.7,0.33,1.39,0.26,2.12c-0.87,0.29-1.45,0.84-1.61,1.77c-0.4,0.03-0.8,0.05-1.2,0.08
    c-0.11-0.45-0.21-0.89-0.32-1.34c0.21,0.03,0.43,0.08,0.64,0.07c0.72-0.02,1.17-0.36,1.18-1.11c0.01-0.68-0.36-1.11-1.04-1.13
    c-0.72-0.03-1.14,0.36-1.19,1.1c-0.01,0.21,0,0.43,0,0.64c-0.46-0.22-0.91-0.44-1.37-0.67c0.02-0.27,0.04-0.55,0.06-0.82
    c0.87-0.24,1.4-0.84,1.77-1.62c0.75-0.18,1.45,0.1,2.16,0.27l-0.01-0.02c-0.02,0.19,0.12,0.26,0.25,0.33
    c0.05,0.02,0.08,0.05,0.09,0.09c0.08,0.13,0.13,0.28,0.33,0.24L136.56,63.26z"/>
    <path class="st2" d="M137.68,98.66c-0.1,0.01-0.21,0.01-0.31,0.02c-1.47-0.22-2.35-0.88-2.52-1.97c-0.17-1.09,0.14-2.01,1.13-2.61
    c1.53-0.92,3.21-0.04,3.72,1.93c-0.01,0.19-0.02,0.37-0.03,0.56c-0.05,0.19-0.1,0.37-0.15,0.56c-0.28,0.55-0.72,0.96-1.22,1.3
    C138.09,98.52,137.88,98.59,137.68,98.66z"/>
    <path class="st1" d="M138.29,98.45c0.5-0.34,0.94-0.75,1.22-1.3c0.4,0.02,0.81-0.04,1.18,0.06c0.98,0.27,1.51,1.18,1.33,2.2
    c-0.17,0.97-1.01,1.58-2.08,1.49c-0.97-0.08-1.6-0.76-1.66-1.81C138.27,98.89,138.29,98.67,138.29,98.45z"/>
    <path class="st1" d="M134.31,109.14c0.79-0.02,1.45,0.64,1.46,1.45c0.01,0.84-0.58,1.45-1.43,1.45c-0.84,0.01-1.48-0.6-1.48-1.41
    C132.86,109.86,133.53,109.16,134.31,109.14z"/>
    <path class="st1" d="M94.78,41.49c-0.06,0.47-0.32,0.76-0.78,0.74c-0.38-0.01-0.68-0.24-0.69-0.68c-0.02-0.47,0.21-0.75,0.69-0.78
    C94.51,40.75,94.68,41.1,94.78,41.49z"/>
    <path class="st1" d="M88.67,35.75c-0.06,0.23-0.19,0.39-0.44,0.37c-0.24-0.02-0.38-0.17-0.37-0.42c0.02-0.25,0.16-0.38,0.41-0.37
    C88.53,35.35,88.63,35.53,88.67,35.75z"/>
    <path class="st1" d="M82.86,35.75c-0.03,0.24-0.14,0.4-0.4,0.37c-0.24-0.02-0.39-0.19-0.38-0.42c0.01-0.23,0.17-0.37,0.42-0.37
    C82.78,35.34,82.86,35.51,82.86,35.75z"/>
    <path class="st1" d="M82.2,30.3c0.05-0.3-0.3-0.73,0.26-0.88c0.19-0.05,0.37,0.05,0.4,0.25c0.09,0.53-0.35,0.51-0.67,0.61
    C82.18,30.3,82.2,30.3,82.2,30.3z"/>
    <path class="st2" d="M139.52,72.67c0,0.29-0.01,0.59-0.01,0.88c-0.44,1.33-1.25,1.97-2.4,1.92c-1.18-0.05-1.83-0.72-2.29-2.35
    c0.03-0.15,0.06-0.3,0.1-0.46c0.11-0.28,0.22-0.57,0.33-0.85c0.23-0.23,0.46-0.47,0.69-0.7c0.28-0.1,0.57-0.2,0.85-0.3
    c0.3,0,0.6,0.01,0.9,0.01c0.28,0.1,0.56,0.2,0.84,0.31c0,0-0.02-0.01-0.02-0.01c0.07,0.06,0.14,0.11,0.21,0.17
    c0.08,0.23,0.23,0.39,0.45,0.48C139.29,72.07,139.41,72.37,139.52,72.67z"/>
    <path class="st1" d="M111.32,46.42c0.51,0.04,0.76,0.33,0.75,0.78c0,0.42-0.31,0.68-0.69,0.7c-0.45,0.02-0.75-0.28-0.78-0.73
    C110.58,46.69,110.91,46.48,111.32,46.42z"/>
    <path class="st2" d="M133.7,65.89c0.11,0.45,0.21,0.89,0.32,1.34c-0.01,0.21-0.03,0.41-0.04,0.62c-0.09,0.28-0.18,0.55-0.26,0.83
    c-0.31,0.31-0.61,0.61-0.92,0.92c-0.28,0.09-0.56,0.19-0.84,0.28c-0.2,0-0.4,0-0.6,0c-1.59-0.11-2.25-1.14-2.51-2.56
    c0.08-0.33,0.17-0.66,0.25-1c0.29-0.5,0.68-0.9,1.15-1.24c0.3-0.1,0.6-0.2,0.89-0.31c0.26,0,0.51,0,0.77,0.01
    c0.46,0.22,0.91,0.44,1.37,0.67l-0.02-0.02c0.02,0.14,0.07,0.25,0.24,0.24l-0.02-0.02c0,0.16,0.04,0.28,0.24,0.24L133.7,65.89z"/>
    <path class="st3" d="M133.72,68.68c0.09-0.28,0.18-0.55,0.26-0.83c0.42,0.04,0.85,0.09,1.27,0.13c0.33,0.61,0.79,1.08,1.42,1.38
    c0.04,0.48,0.08,0.96,0.12,1.45c-0.28,0.1-0.57,0.2-0.85,0.3C136.33,69.28,135.48,68.35,133.72,68.68z"/>
    <path class="st2" d="M120.36,53.21c1.17,0.32,1.88,1.1,2.23,2.24c0,0.28-0.01,0.57-0.01,0.85c-0.18,0.36-0.35,0.73-0.53,1.09
    c-0.15,0.16-0.31,0.31-0.46,0.47c-0.4,0.21-0.79,0.41-1.19,0.62c-0.28,0-0.56,0.01-0.83,0.01c-0.3-0.1-0.57-0.3-0.91-0.25
    c0,0,0.02,0.02,0.02,0.02c-0.06-0.06-0.11-0.12-0.17-0.18c-0.25-0.24-0.51-0.48-0.76-0.72c-0.14-0.35-0.28-0.7-0.42-1.04
    c0-0.29,0-0.58,0-0.86c0.28-1.21,1.06-1.92,2.23-2.25C119.81,53.2,120.09,53.2,120.36,53.21z"/>
    <path class="st0" d="M65.2,36.2c-0.3,0-0.47-0.15-0.49-0.46c-0.01-0.32,0.17-0.46,0.46-0.48c0.31-0.02,0.48,0.15,0.48,0.45
    C65.66,36.01,65.52,36.2,65.2,36.2z"/>
    <path class="st1" d="M104.67,47.78c0-0.25-0.02-0.5,0.02-0.74c0.09-0.55,0.46-0.84,0.99-0.76c0.59,0.09,0.87,0.53,0.74,1.11
    c-0.12,0.54-0.53,0.75-1.07,0.63C105.11,47.97,104.89,47.86,104.67,47.78L104.67,47.78z"/>
    <path class="st2" d="M126.11,58.9c0.5,0.22,1,0.45,1.5,0.67l-0.02-0.02c0.25,0.59,0.7,1.08,0.8,1.74
    c-0.03,0.34-0.06,0.69-0.09,1.03c-0.1,0.42-0.5,0.7-0.5,1.16l0.02-0.02c-0.31,0-0.5,0.12-0.46,0.46l0.02-0.03
    c-0.45-0.11-0.74,0.3-1.15,0.35c-0.33,0-0.67,0-1,0.01c-0.37-0.15-0.75-0.31-1.12-0.46c-0.14-0.11-0.28-0.23-0.42-0.34
    c-0.22-0.4-0.44-0.81-0.66-1.21c-0.01-0.32-0.03-0.65-0.04-0.97c0.11-0.35,0.23-0.7,0.34-1.04c0.33-0.33,0.66-0.66,1-0.99
    c0.33-0.12,0.66-0.24,0.98-0.35C125.58,58.89,125.85,58.89,126.11,58.9z"/>
    <path class="st3" d="M125.32,58.89c-0.33,0.12-0.66,0.24-0.98,0.35c-0.08-1.6-0.51-1.95-2.29-1.85c0.18-0.36,0.35-0.73,0.53-1.09
    c0.3,0.01,0.59,0.02,0.89,0.03c0.34,0.84,0.89,1.46,1.78,1.73C125.27,58.33,125.29,58.61,125.32,58.89z"/>
    <path class="st3" d="M127.81,63.48c-0.01-0.46,0.4-0.75,0.5-1.16c0.32,0.01,0.64,0.01,0.96,0.02c0.34,0.84,0.91,1.44,1.83,1.64
    c0.02,0.27,0.04,0.54,0.06,0.81c-0.3,0.1-0.6,0.2-0.89,0.31C129.93,63.52,129.37,63.15,127.81,63.48z"/>
    <path class="st3" d="M127.38,63.93c-0.04-0.35,0.15-0.46,0.46-0.46C127.68,63.62,127.53,63.77,127.38,63.93z"/>
    <path class="st3" d="M118.51,58.08c0.06,0.06,0.11,0.12,0.17,0.18C118.62,58.2,118.56,58.14,118.51,58.08z"/>
    <path class="st1" d="M89.1,42.02c-0.12,0.06-0.19,0.14-0.14,0.28c-0.43,0.03-0.87,0.14-1.3-0.01c-0.28-0.45-0.38-0.91-0.11-1.41
    c0.4-0.29,0.81-0.43,1.26-0.11C89.26,41.11,89.21,41.56,89.1,42.02z"/>
    <path class="st1" d="M99.25,47.96c-0.07-0.07-0.14-0.14-0.22-0.22c-0.12-0.51-0.21-1.01,0.34-1.36c0.31-0.19,0.62-0.19,0.92,0.02
    c0.46,0.32,0.5,0.76,0.34,1.25C100.24,48.09,99.75,48.08,99.25,47.96z"/>
    <path class="st1" d="M127.61,59.57c-0.04-0.17-0.08-0.35-0.11-0.52c-0.13-0.62,0.13-1.05,0.68-1.3c0.54-0.25,1-0.07,1.34,0.36
    c0.35,0.45,0.35,0.97-0.01,1.39c-0.38,0.45-0.88,0.61-1.44,0.29c-0.15-0.09-0.31-0.16-0.47-0.24L127.61,59.57z"/>
    <path class="st1" d="M139.18,71.77c-0.22-0.09-0.37-0.25-0.45-0.48c-0.07-0.06-0.14-0.11-0.21-0.17c0,0,0.02,0.01,0.02,0.01
    c-0.01-0.22-0.03-0.43-0.03-0.65c-0.03-1.19,0.67-1.97,1.71-1.93c1.03,0.04,1.78,0.98,1.6,2.04c-0.17,1.01-1.1,1.56-2.22,1.3
    C139.46,71.86,139.32,71.81,139.18,71.77z"/>
    <path class="st1" d="M133.29,65.46c0-0.22-0.01-0.43,0-0.64c0.05-0.74,0.47-1.13,1.19-1.1c0.68,0.03,1.05,0.46,1.04,1.13
    c-0.01,0.75-0.46,1.09-1.18,1.11c-0.21,0.01-0.42-0.05-0.64-0.07l0.02,0.02c0-0.16-0.1-0.22-0.24-0.24l0.02,0.02
    c-0.02-0.14-0.07-0.25-0.24-0.24L133.29,65.46z"/>
    <path class="st3" d="M138.52,71.12c0.07,0.06,0.14,0.11,0.21,0.17C138.66,71.23,138.59,71.18,138.52,71.12z"/>
    <path class="st3" d="M133.27,65.45c0.17-0.01,0.22,0.1,0.24,0.24C133.34,65.69,133.29,65.59,133.27,65.45z"/>
    <path class="st3" d="M133.49,65.66c0.14,0.02,0.24,0.08,0.24,0.24C133.53,65.94,133.49,65.82,133.49,65.66z"/>
        </g>
        </g>
        </svg>
`
