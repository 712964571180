import {
    PIPELINE_STATUS_OPERATING,
    PIPELINE_STATUS_PROJECTED,
    PIPELINE_STATUS_UNDER_CONSTRUCTION,
    PIPELINE_TYPE_INTER_SETTLEMENT,
} from '@/shared/constants'
import { popupTypes } from '@/entities/Map/general/model/addLayerHoverActions/types/popupTypes'
export default async function (this: any) {
    const pipelineSettlementUrl = this.pipelinesSettlementUrlFirstPart

    const layerTypeUnderConstructionLineId = this.getLayerId(
        pipelineSettlementUrl,
        this.layerTypeLine,
        PIPELINE_STATUS_UNDER_CONSTRUCTION
    )

    if (!this.map.hasImage(layerTypeUnderConstructionLineId)) {
        const underConstructionIconSvg = this.getPipelineSvgPattern(
            PIPELINE_TYPE_INTER_SETTLEMENT
        )
        if (!underConstructionIconSvg) return
        const loadedImg = await this.loadSvgToMapImages(
            underConstructionIconSvg,
            layerTypeUnderConstructionLineId
        )
        if (!loadedImg) return
    }

    return () => {
        const layerLineTypeId = this.getLayerId(
            pipelineSettlementUrl,
            // this.layerTypeLine,
            PIPELINE_STATUS_OPERATING
        )

        const layerLineProjectedTypeId = this.getLayerId(
            pipelineSettlementUrl,
            // this.layerTypeLine,
            PIPELINE_STATUS_PROJECTED
        )
        const sourceLayer = this.getLayerById(
            this.map.getStyle().layers,
            layerLineProjectedTypeId
        )

        this.addLayerHoverActions(layerLineProjectedTypeId, {
            mainLayerOptions: {
                ...sourceLayer,
            },

            hoveringLayerOptions: {
                type: this.layerTypeLine,
                // source: this.infrastructureTileSourceName,
                'source-layer': this.pipelinesSettlementUrlFirstPart,
                layout: {
                    'line-cap': 'round',
                    'line-join': 'round',
                },
                paint: {
                    'line-color': 'rgba(0,0,0, 0)',
                    'line-width': 20,
                },
            },

            hoveringDecorationLayerOptions: {
                type: this.layerTypeLine,
                // source: this.infrastructureTileSourceName,
                'source-layer': this.pipelinesSettlementUrlFirstPart,
                layout: {
                    'line-cap': 'round',
                    'line-join': 'round',
                },
                paint: {
                    // 'line-color': this.colors['17'],
                    'line-color': [
                        'case',
                        ['boolean', ['feature-state', 'hover'], false],
                        this.colors['17'],
                        'rgba(0,0,0,0)',
                        // 1
                    ],
                    'line-width': [
                        'case',
                        ['boolean', ['feature-state', 'hover'], false],
                        10,
                        1,
                    ],
                },
            },

            getHtmlContent: feature => {
                const {
                    properties: { name, year },
                } = feature
                let html = ''
                if (name) {
                    html += `<div>${name}</div>`
                }
                if (year) {
                    html += `<div>Окончание работ: ${year}</div>`
                }
                return {
                    htmlContent: html,
                    type: popupTypes.default,
                }
            },
        })

        this.addLayerAfter(
            `${this.layerIdHoveringPostfix + this.layerLineProjectedTypeId}`,
            {
                id: layerTypeUnderConstructionLineId,
                type: 'symbol',
                // source: pipelineSettlementUrl,
                source: this.infrastructureTileSourceName,
                'source-layer': this.pipelinesSettlementUrlFirstPart,
                layout: {
                    'icon-image': layerTypeUnderConstructionLineId,
                    'symbol-placement': 'line',
                    'symbol-spacing': 1,
                },
                minzoom: this.layerMinZoom1,
                filter: ['==', 'status', PIPELINE_STATUS_UNDER_CONSTRUCTION],
            }
        )
    }
    // } catch (error) {
    //     console.error(error)
    // }
}
