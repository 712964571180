export interface ITreeList {
    label: string
    description?: string
    children?: ITreeList[]
    geometry?: number[]
    id?: number | string
    all_proposal_count: number
    envelope?: any[]
}

export class TreeList implements ITreeList {
    constructor(
        public label,
        public children,
        public geometry,
        public id,
        public all_proposal_count,
        public envelope?,
        public description?
    ) {
        this.label = label
        this.description = description
        this.children = children
        this.geometry = geometry
        this.id = id
        this.all_proposal_count = all_proposal_count
        this.envelope = envelope
    }
}
