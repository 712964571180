<template>
    <div :class="$style.mapBtns">
        <div :class="$style.zoomController">
            <div :class="$style.btn" @click="zoomIn">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                >
                    <g
                        id="Group_6"
                        data-name="Group 6"
                        transform="translate(-1848 -225)"
                    >
                        <rect
                            id="Rectangle_8"
                            data-name="Rectangle 8"
                            width="24"
                            height="24"
                            transform="translate(1848 225)"
                            fill="none"
                        />
                        <g id="Plus" transform="translate(1 1.143)">
                            <path
                                id="Union_2"
                                data-name="Union 2"
                                d="M6.857,14.857V9.143H1.143a1.143,1.143,0,1,1,0-2.286H6.857V1.143a1.143,1.143,0,1,1,2.286,0V6.857h5.714a1.143,1.143,0,1,1,0,2.286H9.143v5.714a1.143,1.143,0,1,1-2.286,0Z"
                                transform="translate(1851 227.857)"
                                fill="#4d4d4d"
                            />
                        </g>
                    </g>
                </svg>
            </div>
            <div :class="$style.btn" @click="zoomOut">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="2.714"
                    viewBox="0 0 16 2.714"
                >
                    <g id="Minus" transform="translate(-1851 -236)">
                        <path
                            id="Path_4"
                            data-name="Path 4"
                            d="M5,8.357A1.264,1.264,0,0,1,6.143,7H19.857A1.264,1.264,0,0,1,21,8.357a1.264,1.264,0,0,1-1.143,1.357H6.143A1.264,1.264,0,0,1,5,8.357Z"
                            transform="translate(1846 229)"
                            fill="#4d4d4d"
                        />
                    </g>
                </svg>
            </div>
        </div>
        <div :class="$style.fullScreenBtn" @click="toggleFullscreen">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
            >
                <g id="Icon_fullscreen" transform="translate(-1851 -559)">
                    <path
                        id="Path_6"
                        data-name="Path 6"
                        d="M3,3H9V5H5V9H3Z"
                        transform="translate(1848 556)"
                        fill="#4d4d4d"
                    />
                    <path
                        id="Path_7"
                        data-name="Path 7"
                        d="M3,21H9V19H5V15H3Z"
                        transform="translate(1848 554)"
                        fill="#4d4d4d"
                    />
                    <path
                        id="Path_8"
                        data-name="Path 8"
                        d="M15,21h6V15H19v4H15Z"
                        transform="translate(1846 554)"
                        fill="#4d4d4d"
                    />
                    <path
                        id="Path_9"
                        data-name="Path 9"
                        d="M21,3H15V5h4V9h2Z"
                        transform="translate(1846 556)"
                        fill="#4d4d4d"
                    />
                </g>
            </svg>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        zoomIn() {
            this.$map.zoomIn()
        },
        zoomOut() {
            this.$map.zoomOut()
        },
        toggleFullscreen() {
            let element = document.body

            if (event instanceof HTMLElement) {
                element = event
            }

            const isFullscreen =
                document.webkitIsFullScreen || document.mozFullScreen || false

            element.requestFullScreen =
                element.requestFullScreen ||
                element.webkitRequestFullScreen ||
                element.mozRequestFullScreen ||
                function () {
                    return false
                }
            document.cancelFullScreen =
                document.cancelFullScreen ||
                document.webkitCancelFullScreen ||
                document.mozCancelFullScreen ||
                function () {
                    return false
                }

            isFullscreen
                ? document.cancelFullScreen()
                : element.requestFullScreen()
        },
    },
}
</script>

<style lang="stylus" module>
.mapBtns
    position absolute
    right 10px
    top 50%
    z-index 10
    display flex
    flex-direction column
    gap 15px
    transform translateY(-50%)
.zoomController
    background-color white
    border-radius 10px
    overflow hidden
    & .btn
        display flex
        justify-content center
        align-items center
        width 40px
        height 40px
        cursor pointer
        &:hover
            background-color #F6F6F6
.fullScreenBtn
    display flex
    justify-content center
    align-items center
    background-color white
    border-radius 10px
    width 40px
    height 40px
    cursor pointer
    &:hover
        background-color #F6F6F6
</style>
