export default function (this: any, layerFillId) {
    const sourceAndSourceLayer = {
        source: this.infrastructureTileSourceName,
        sourceLayer: this.tileRegionSourceLayer,
    }
    const mouseenterCallback = (hoveredFeatureId, feature) => {
        const hoveredRegionName = feature?.properties?.name

        if (hoveredRegionName) {
            this.$commit('setPartialState', {
                hoveredRegionName,
            })
        }
        const hoveredRegionNumber = feature?.properties?.number
        if (hoveredRegionNumber) {
            this.$state.hoveredRegionNumber = hoveredRegionNumber
        }
        if (feature?.state?.focus) {
            return
        }
        this.map.setFeatureState(
            {
                id: hoveredFeatureId,
                ...sourceAndSourceLayer,
            },
            { hover: true }
        )
    }
    const mouseleaveCallback = hoveredFeatureId => {
        this.$commit('setPartialState', {
            hoveredRegionName: '',
        })
        this.map.setFeatureState(
            {
                id: hoveredFeatureId,
                ...sourceAndSourceLayer,
            },
            { hover: false }
        )
    }
    this.setMouseenterMouseleave(
        layerFillId,
        mouseenterCallback,
        mouseleaveCallback
    )
}
