import addHoveredPopup from '@/entities/Map/general/model/addLayerHoverActions/addHoveredPopup'
import { SOCIAL_OBJECTS_LAYER_IDS } from '@/shared/constants'
import { popupTypes } from '@/entities/Map/general/model/addLayerHoverActions/types/popupTypes'

export default async function (this: any) {
    return () => {
        SOCIAL_OBJECTS_LAYER_IDS.forEach(layerId => {
            const { source } = this.map.getLayer(layerId)
            const getHtmlContent = () => {
                return {
                    htmlContent: null,
                    type: popupTypes.socialObjectsPopup,
                }
            }

            addHoveredPopup.call(this, source, layerId, getHtmlContent)
        })
    }
}
