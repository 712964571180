import { Map, Marker, SymbolLayerSpecification } from 'maplibre-gl'
import { ICanvasMarkerInstance } from '../../canvasMarkers/types'
import { getImageIdByBoundId } from '../../CustomLayers/utils/getImageIdByBoundId'

export const addBaseMarkerEvents = (
    map: Map,
    layer: SymbolLayerSpecification,
    activeMarker: ICanvasMarkerInstance,
    imageName: string
) => {
    const removeActiveMarker = () => {
        activeMarker.value?.remove()
        activeMarker.value = null
        activeMarker.id.value = null
    }

    const checkIsClusterExistsOnMap = () => {
        removeActiveMarker()
        map.off('zoom', checkIsClusterExistsOnMap)
        map.off('move', checkIsClusterExistsOnMap)
        map.off('moveend', checkIsClusterExistsOnMap)
    }

    const mouseenterHandler = event => {
        event.preventDefault()
        event.originalEvent.stopPropagation()

        const features = event.features
        const newMarkerId = features[0].properties.cluster_id
        if (newMarkerId === activeMarker.id.value) return

        map.on('zoom', checkIsClusterExistsOnMap)
        map.on('move', checkIsClusterExistsOnMap)
        map.on('moveend', checkIsClusterExistsOnMap)

        if (activeMarker.value) {
            removeActiveMarker()
        }
        activeMarker.layer = features[0].layer

        if (!activeMarker.value) {
            const el = (
                map.getImage(getImageIdByBoundId(imageName, newMarkerId))
                    ?.userImage as any
            )?.canvasModelComponent
            const marker = new Marker({
                element: el,
            }).setLngLat(features[0].geometry.coordinates)
            marker.addTo(map)
            activeMarker.id.value = newMarkerId
            activeMarker.value = marker
        }
    }
    const mouseleaveHandler = event => {
        event.preventDefault()
        event.originalEvent.stopPropagation()

        if (
            event.originalEvent.relatedTarget === map.getCanvas() ||
            !event.originalEvent.relatedTarget
        ) {
            removeActiveMarker()
        }
    }

    map.on('mousemove', layer.id, mouseenterHandler)
    map.on('mouseleave', layer.id, mouseleaveHandler)
}
