<template>
    <div :class="$style.mapPopupContainer">
        <div :class="$style.mapPopup__block">
            <div :class="$style.mapPopup__head">
                <div
                    :class="$style.mapPopup__icon"
                    v-html="svgComponent[properties.vendor] || ''"
                ></div>
                <div :class="$style.mapPopup__title">
                    {{ properties?.name || 'нет данных' }}
                </div>
            </div>
            <div :class="$style.mapPopup__subtitle">
                {{ properties?.address || 'нет данных' }}
            </div>
        </div>
        <div
            :class="$style.mapPopup__block"
            v-if="popupInfo.length && !popupIsLoading"
        >
            <div :class="$style.mapPopup__title">Топливо:</div>
            <div
                :class="$style.mapPopup__priceArea"
                v-for="itemInfo in popupInfo"
                :key="itemInfo.id"
            >
                <div :class="$style.mapPopup__priceTitle">
                    {{ getFuelName(itemInfo?.fuelType) }}
                </div>
                <div :class="$style.mapPopup__priceValue">
                    {{ itemInfo.value }}
                </div>
            </div>
        </div>
        <f7-preloader style="align-self: center" v-if="popupIsLoading" />
    </div>
</template>

<script>
import { mapState } from 'vuex'

import { VENDOR_TYPE } from '../../../../../../shared/constants'
import loadSource from '../../../model/loadSource'
import NovatekSVG from '../../../../../../shared/ui/IconsSVG/NovatekSVG.ts'
import { fuelTypes } from '../types'

export default {
    data() {
        return {
            svgComponent: {
                [VENDOR_TYPE.NOVATEK]: NovatekSVG,
            },
        }
    },
    computed: {
        ...mapState({
            featureId: state => state.map.popupMap.id,
            properties: state => state.map.popupMap.properties,
            popupLngLat: state => state.map.popupMap.lngLat,
            popupIsLoading: state => state.map.popupMap.isLoading,
            popupInfo: state => state.map.popupMap.info,
            popupProperties: state => state.map.popupMap.popupProperties,
        }),
    },
    methods: {
        loadSource,
        getFuelName(fuelType) {
            switch (fuelType) {
                case fuelTypes.LNG:
                    return 'СПГ'
                case fuelTypes.METHANE:
                    return 'Метан'
                case fuelTypes.PROPANE_BUTANE_TECH:
                    return 'Пропан-бутан технический'
            }
        },
    },
    mounted() {
        document.querySelector('.maplibregl-popup-close-button').style.display =
            this.popupProperties?.closeButton ? 'flex' : 'none'
    },
}
</script>

<style lang="stylus" module>
.scrollbar
    padding-right 13px
.infoElement
    border-bottom 1px solid gray
.tariff, .volume
    display flex
    flex-direction row
    margin-bottom 10px
.paths
    margin-bottom 10px
    ul
        margin-left 25px
        & li
            list-style square

.mapPopupContainer
    //padding-left: calc(var(--f7-list-item-padding-horizontal) + var(--f7-safe-area-left) - var(--menu-list-offset));
    display flex
    flex-direction column
    font-family SF Pro Text

    //padding: 35px 10px 15px;
    padding: 10px 40px 10px 10px;
    background: #ffffff85
    border-radius: 10px;
    backdrop-filter: blur(11px);
.mapPopupContainer > .mapPopup__block:last-child
    padding 0
.mapPopup__block
    padding 0 0 20px 0
.mapPopup__head
    display flex
    align-items center
    margin-bottom 10px
.mapPopup__icon
    height 50px
    width 50px
    margin-right 10px
.mapPopup__title
    font-size 13px
    font-weight: 600
.mapPopup__subtitle
    color #a1a1a1
.mapPopup__priceArea
    display flex
    background-color #ECECEC
    border-radius: 5px
    width fit-content
    font-weight 700
    font-size 13px
    padding 2px 6px
    margin 10px 0
.mapPopup__priceTitle
    margin-right 20px
.mapPopup__priceValue
    color #22AAE5
    font-size 15px
</style>
