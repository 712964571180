import {
    CITY_CAPITAL_DISTRICT,
    CITY_CAPITAL_OTHER,
    CITY_CAPITAL_REGION,
    CITY_STATUS_CONNECTED,
    CITY_STATUS_DISCONNECTED,
    CITY_STATUS_PERSPECTIVE,
    IMG_CITY_CAPITAL_DISTRICT,
    IMG_CITY_CAPITAL_REGION,
    IMG_CITY_OTHER_CONNECTED,
    IMG_CITY_OTHER_DISCONNECTED,
    IMG_CITY_OTHER_PERSPECTIVE,
    LAYER_MIN_ZOOM2,
} from '@/shared/constants'
import { setPartialStateDeep } from '@/shared/lib/setPartialStateDeep'
// import { convertProjection } from '@/lib'

export default async function (this: any) {
    // const cityUrl = `${this.cityUrlFirstPart + regionId}`
    // const source = await this.loadSource(cityUrl)
    // if (!source) return
    // console.log('city source:', source)
    // const features = source.features
    // if (!features) return
    // // convertProjection(features)
    // features.forEach(feature => {
    //     let icon = ''
    //     const capital = feature?.properties?.capital
    //     const status = feature?.properties?.status
    //
    //     switch (capital) {
    //         case CITY_CAPITAL_DISTRICT:
    //             icon = IMG_CITY_CAPITAL_DISTRICT
    //             break
    //         case CITY_CAPITAL_REGION:
    //             icon = IMG_CITY_CAPITAL_REGION
    //             break
    //         case CITY_CAPITAL_OTHER:
    //             switch (status) {
    //                 case CITY_STATUS_CONNECTED:
    //                     icon = IMG_CITY_OTHER_CONNECTED
    //                     break
    //                 case CITY_STATUS_DISCONNECTED:
    //                     icon = IMG_CITY_OTHER_DISCONNECTED
    //                     break
    //                 case CITY_STATUS_PERSPECTIVE:
    //                     icon = IMG_CITY_OTHER_PERSPECTIVE
    //                     break
    //             }
    //             break
    //     }
    //     feature.properties.icon = icon
    // })
    //
    // await this.addSource(cityUrl, this.featureCollectionType, source)
    //
    // return () => {
    //     const getLayerOptionsByTemplate = (data = {}) => {
    //         return setPartialStateDeep(
    //             {
    //                 // type: this.layerTypeCircle,
    //                 type: this.layerTypeSymbol,
    //                 source: cityUrl,
    //                 paint: {
    //                     'text-color': this.colors[3],
    //                     'text-halo-color': this.colors[9],
    //                     'text-halo-width': 1,
    //                 },
    //                 layout: {
    //                     'icon-image': ['get', 'icon'],
    //                     // 'text-field': ['get', 'name'],
    //                     'text-field': [
    //                         'case',
    //                         ['==', ['get', 'capital'], CITY_CAPITAL_DISTRICT],
    //                         ['get', 'name'],
    //                         ['==', ['get', 'capital'], CITY_CAPITAL_REGION],
    //                         ['upcase', ['get', 'name']],
    //                         ['get', 'name'],
    //                     ],
    //                     // 'text-field': 'test',
    //                     'text-variable-anchor': [
    //                         'top',
    //                         'bottom',
    //                         'left',
    //                         'right',
    //                     ],
    //                     // 'text-radial-offset': 0.5,
    //                     'text-justify': 'auto',
    //                     'text-font': ['Nunito Bold Italic'],
    //                     'text-offset': [1, 1],
    //                     'text-size': 14,
    //                     // 'text-size': [
    //                     //     'case',
    //                     //     ['==', ['get', 'capital'], CITY_CAPITAL_DISTRICT],
    //                     //     14,
    //                     //     ['==', ['get', 'capital'], CITY_CAPITAL_REGION],
    //                     //     16,
    //                     //     13,
    //                     // ],
    //                     'text-letter-spacing': 0.05,
    //                 },
    //                 // filter: ['==', 'capital', CITY_CAPITAL_DISTRICT],
    //                 minzoom: this.layerMinZoom1,
    //             },
    //             data
    //         )
    //     }
    //     const notOtherCityTypeLayerId = this.getLayerId(
    //         cityUrl,
    //         this.layerTypeCircle,
    //         `NOT_${CITY_CAPITAL_OTHER}`
    //     )
    //     this.addLayer(
    //         getLayerOptionsByTemplate({
    //             id: notOtherCityTypeLayerId,
    //             filter: ['!=', 'capital', CITY_CAPITAL_OTHER],
    //         })
    //     )
    //     const otherCityTypeLayerId = this.getLayerId(
    //         cityUrl,
    //         this.layerTypeCircle,
    //         CITY_CAPITAL_OTHER
    //     )
    //     this.addLayer(
    //         getLayerOptionsByTemplate({
    //             id: otherCityTypeLayerId,
    //             filter: ['==', 'capital', CITY_CAPITAL_OTHER],
    //             maxzoom: LAYER_MIN_ZOOM2,
    //         })
    //     )
    // }
}
