export default function (this: any, params) {
    const { id, isLayerSave = true } = params

    const currentLayer = this.map.getLayer(id)
    if (currentLayer) {
        // this.map.removeLayer(id)
        return currentLayer
    }
    this.map.addLayer(params)
    const layer = this.map.getLayer(id)
    if (!layer) return

    // this.layers[id] = layer
    if (isLayerSave) {
        this.activeRegionLayers[id] = layer
    }

    return layer
}
