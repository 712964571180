import toggleActiveGds from '@/entities/Map/gasification/model/addGds/toggleActiveGds'
import {
    stepsListIndexes,
    TILE_DISTRICT_SOURCE_LAYER,
    ZOOM_HIERARCHY_MIN_LEVEL,
} from '@/shared/constants'
import setChangeStepByZoom from '@/widgets/MenuPanel/ui/TimeLine/methods/setChangeStepByZoom'
import mapAutomaticFlyEventSideEffects from '@/entities/Map/general/model/mapUtils/mapAutomaticEventSideEffects/mapAutomaticFlyEventSideEffects'
import clearDivisionChosenData from '@/widgets/MenuPanel/ui/TimeLine/methods/stepsActions/generalSideEffects/clearDivisionChosenData'
import { DebounceMapEvent } from '@/entities/Map/general/model/mapUtils/debounceMapEvent'

const toFixed = 6
export default function (this: any) {
    const setLocationHashWithCenter = () => {
        if (!this.mapCenter || !this.mapZoom) return
        const [lng, lat] = this.mapCenter
        history.pushState(
            {},
            '',
            process.env.VUE_APP_BASE_URL +
                this.$route.path +
                `#${lng.toFixed(toFixed)},${lat.toFixed(
                    toFixed
                )},${this.mapZoom.toFixed(toFixed)}`
        )
    }
    this.map.on('click', event => {
        const { lng, lat } = event.lngLat
        console.log('overall click', event?.features)

        this.$state.view.isShowMapLegend = false

        // this.$state.view.isShowMapLegend = false
        if (!event.defaultPrevented) {
            // event.preventDefault()
            // console.log('outside click', event, event.features)
        }
        if (this.$state.view.gdsMenu.state) {
            toggleActiveGds.call(this, null, true)
        }
        if (
            this.$state.map.zoom >
                ZOOM_HIERARCHY_MIN_LEVEL[
                    stepsListIndexes.districtInfoStepIndex
                ] &&
            this.$state.view.stepActiveId < stepsListIndexes.gdsInfoStepIndex &&
            this.$state.view.stepPrevId === stepsListIndexes.gdsInfoStepIndex
        ) {
            this.$dispatch(
                'setStepActiveId',
                stepsListIndexes.districtInfoStepIndex
            )
        }
        //remove focused feature
        if (this.$state.map.focusedFeatureOptions) {
            this.map.setFeatureState(this.$state.map.focusedFeatureOptions, {
                hover: false,
            })
            this.$state.map.focusedFeatureOptions = null
        }

        this.$commit('clearMap', { context: this, event })
        // this.miniMap.setCenter([lng, lat])
    })
    this.map.on('dblclick', event => {
        // event.preventDefault()
    })

    this.map.on('load', () => {
        const locationHash = location.hash
        if (locationHash) {
            const locationHashSubstr = locationHash.substr(1)
            const locationHashSplitted = locationHashSubstr.split(',')
            const lng = parseFloat(locationHashSplitted[0])
            const lat = parseFloat(locationHashSplitted[1])
            const zoom = parseFloat(locationHashSplitted[2])
            this.map.setCenter([lng, lat])
            this.map.setZoom(zoom)
        } else {
            setLocationHashWithCenter()
        }
    })

    const debounceMoveEvent = new DebounceMapEvent({
        callback: () =>
            this.setChangedFeaturesInViewport(
                TILE_DISTRICT_SOURCE_LAYER,
                ZOOM_HIERARCHY_MIN_LEVEL[stepsListIndexes.districtsStepIndex]
            ),
        ms: 500,
    })
    this.map.on('move', async event => {
        this.$state.view.isShowMapLegend = false

        const { lng, lat } = this.map.getCenter()
        this.$commit('setMapPartialState', {
            center: [lng, lat],
        })

        if (this.$state.map.popupMap.isShow) {
            this.$state.map.popupMap.isShow = false
        }

        if (!event.originalEvent) {
            this.$state.map.isAutomaticMapEvent = true
            mapAutomaticFlyEventSideEffects.call(this)
        } else {
            this.$state.map.isAutomaticMapEvent = false
            mapAutomaticFlyEventSideEffects.call(this, false)

            this.$state.hoveredDistrictProperties = ''
            this.$state.hoveredRegionName = ''
            this.$state.focusedCityAreaName = ''
        }

        this.$state.map.proposalsInfoMarkers = []

        //debounce
        if (!debounceMoveEvent.isDebouncing) {
            debounceMoveEvent.start()
        }
    })

    this.map.on('zoom', event => {
        //actions which fires when map moving programmatically
        this.$state.view.isShowMapLegend = false

        if (!event.originalEvent) {
            mapAutomaticFlyEventSideEffects.call(this)
        } else {
            mapAutomaticFlyEventSideEffects.call(this, false)
        }

        const zoom = this.map.getZoom()

        setChangeStepByZoom.call(this, zoom)

        this.$commit('setMapPartialState', {
            zoom,
        })
        // setInitialPitchAndBearing()

        if (this.$state.map.popupMap.isShow) {
            this.$state.map.popupMap.isShow = false
        }
    })

    this.map.on('rotateend', () => {
        this.$commit('setMapPartialState', {
            bearing: this.map.getBearing(),
        })
    })

    this.map.on('pitchend', event => {
        this.$commit('setMapPartialState', {
            pitch: this.map.getPitch(),
        })
    })

    // this.map.on('idle', () => {
    //     setInitialPitchAndBearing()
    // })

    // const getCityArea = () => {
    //     const zoom = this.map.getZoom()
    //     if (zoom < 9) return
    //     const {
    //         _sw: { lng: swLng, lat: swLat },
    //         _ne: { lng: neLng, lat: neLat },
    //     } = this.map.getBounds()

    //     this.$commit('setMapPartialState', {
    //         bounds: { swLng, swLat, neLng, neLat },
    //     })
    // }
    // getCityArea()
    this.map.on('movestart', event => {
        // console.log('movestart event.originalEvent', event.originalEvent)
        if (!event.originalEvent) {
            return
        } else {
            clearDivisionChosenData.call(this)
        }
        // getCityArea()
    })
    this.map.on('moveend', event => {
        // console.log('moveend event', event)
        this.$state.map.isAutomaticMapEvent = true

        this.setMetersInPx()
        // getCityArea()
        setLocationHashWithCenter()
    })
    // this.map.on('zoomend', () => {
    //     getCityArea()
    // })
    // )
}
