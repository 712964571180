<template>
    <el-tooltip
        effect="light"
        placement="top"
        :offset="10"
        :hide-after="!currentHoveredLine"
    >
        <template #content v-if="currentHoveredLine">
            {{ currentHoveredLine.name }}: {{ currentHoveredLine.qty }}
        </template>
        <div ref="proposalStatsInfoMarkerEl" :class="[$style.proposalMarker]">
            <div
                :class="[
                    $style.proposalMarker__header,
                    ,
                    isMore && $style.proposalMarker__header_more,
                ]"
            >
                <div
                    :class="$style.chartWrapper"
                    @mouseenter="
                        hoverChartLine({
                            id: 'all_proposal_count',
                            qty: proposal.properties?.all_proposal_count,
                        })
                    "
                >
                    <transition name="shrink" mode="out-in" appear>
                        <svg
                            :class="$style.chart"
                            viewBox="0 0 100 100"
                            v-if="proposalsByTypeChart"
                        >
                            <path
                                v-for="proposalsByTypeChartElement of proposalsByTypeChart"
                                v-show="proposalsByTypeChartElement.qty > 0"
                                :key="proposalsByTypeChartElement.id"
                                :stroke-dasharray="
                                    proposalsByTypeChartElement.strokeDasharray
                                "
                                :stroke="proposalsByTypeChartElement.stroke"
                                :class="$style.chart__pathRemaining"
                                :style="{
                                    transform:
                                        proposalsByTypeChartElement.transformRotate,
                                }"
                                d="
                                M 50, 50
                                m -45, 0
                                a 45,45 0 1,0 90,0
                                a 45,45 0 1,0 -90,0
                            "
                                @mouseenter="
                                    hoverChartLine(proposalsByTypeChartElement)
                                "
                            />
                        </svg>
                    </transition>
                    <div
                        :class="$style.chartMainQty"
                        @mouseenter="
                            hoverChartLine({
                                id: 'all_proposal_count',
                                qty: proposal.properties?.all_proposal_count,
                            })
                        "
                    >
                        {{ proposal.properties?.all_proposal_count }}
                    </div>
                </div>
                <span :class="$style.title">{{
                    proposal.properties.name
                }}</span>

                <!--                <f7-button-->
                <!--                    v-if="isMore"-->
                <!--                    :class="$style.closeTrigger"-->
                <!--                    @click="close"-->
                <!--                    round-->
                <!--                    large-->
                <!--                >-->
                <!--                    <f7-icon f7="multiply" size="24px" color="blue" />-->
                <!--                </f7-button>-->
            </div>

            <!--            <More-->
            <!--                v-if="isMore"-->
            <!--                v-bind="{-->
            <!--                    proposal: proposal.properties,-->
            <!--                    palette,-->
            <!--                }"-->
            <!--            />-->
        </div>
    </el-tooltip>
</template>
<script>
import { mapState } from 'vuex'

import More from './components/More'
import setMarker from './methods/setMarker'
import { markerTransition } from './constants'
import { proposalsDistrictColorTypes } from '../../../../../shared/constants'
export default {
    components: {
        // More,
    },
    props: {
        proposal: {
            type: Object,
            required: true,
        },
        type: {
            type: String,
        },
    },
    data() {
        return {
            isMore: false,
            isAppeared: false,
            proposalsMore: null,
            isHovered: false,
            currentHoveredLine: null,
            palette: {
                finished_count: proposalsDistrictColorTypes.finished_count,
                in_work_count: proposalsDistrictColorTypes.in_work_count,
                no_data_count: proposalsDistrictColorTypes.no_data_count,
                rejected_count: proposalsDistrictColorTypes.rejected_count,
            },
        }
    },
    computed: {
        ...mapState({
            hoveredRegionNumber: state => state.hoveredRegionNumber,
        }),
        isShowMarker() {
            return this.proposal.isShow
        },
        paletteKeys() {
            return Object.keys(this.palette)
        },
        isBig() {
            return this.isHovered
        },
        proposalsByType() {
            return this.proposal.proposalsByType
        },
        allProposals() {
            return this.proposalsByType?.ALL
        },
        proposalsByTypeChart() {
            let coefficient = 1
            return Object.entries(this.proposal.properties).reduce(
                (accumulator, proposalsByTypeEntry) => {
                    const proposalType = proposalsByTypeEntry[0]
                    if (!this.paletteKeys.includes(proposalType))
                        return accumulator
                    const proposalQuantityOfType = proposalsByTypeEntry[1]
                    const strokeDasharrayCoefficient =
                        proposalQuantityOfType /
                        this.proposal.properties.all_proposal_count
                    const strokeDasharray = `${
                        strokeDasharrayCoefficient * 283
                    } 283`
                    const stroke = this.palette[proposalType]
                    const transformRotate = `rotate(${360 * coefficient}deg)`
                    coefficient -= strokeDasharrayCoefficient
                    accumulator.push({
                        id: proposalType,
                        strokeDasharray,
                        stroke,
                        transformRotate,
                        qty: proposalQuantityOfType,
                    })
                    return accumulator
                },
                []
            )
        },
    },
    watch: {
        isShowMarker(isShow) {
            if (!isShow) this.closeEffect()
        },
    },
    methods: {
        setMarker,
        updateMarker(center) {
            this.marker.setLngLat(center)
        },
        removeMarkerComponent() {
            this.$commit('removeProposalStatsInfoMarker', {
                markerId: this.proposal.id,
            })
        },
        expandMarker() {
            if (this.isHovered) return
            this.isHovered = true
            this.isAppeared = true
        },
        async clickOnMarker(event) {
            event.preventDefault()
            event.stopPropagation()
            event.stopImmediatePropagation()
            this.expandMarker()
        },
        markerMousewheel(event) {
            if (!this.isMore) return
            // event.preventDefault()
            // event.stopPropagation()
            event.stopImmediatePropagation()
        },
        markerMousemove(event) {
            if (!this.isMore) return
            event.stopImmediatePropagation()
        },
        hoverChartLine(lineData) {
            this.currentHoveredLine = {
                name: this.translateTypes[lineData.id],
                qty: lineData.qty,
            }
        },
        closeEffect() {
            console.log('closeEffect')
            this.isHovered = false
            this.isAppeared = false
            this.proposalsMore = null
        },
        async close(event) {
            event.stopPropagation()
            event.stopImmediatePropagation()
            // this.closeEffect()
            // await new Promise(resolve => {
            //     setTimeout(resolve, markerTransition)
            // })
            // console.log('timeout closed')
            this.removeMarkerComponent()
        },
    },
    setup() {
        const translateTypes = {
            all_proposal_count: 'Всего заявок',
            finished_count: 'Выполнено подключение',
            in_work_count: 'В работе',
            no_data_count: 'Без данных',
            rejected_count: 'Отклоненных',
        }

        return {
            marker: null,
            translateTypes,
        }
    },
    mounted() {
        this.setMarker(this.proposal.lngLat)
        // this.expandMarker()
        // setTimeout(() => this.expandMarker(), markerTransition)
    },
    beforeUnmount() {
        this.marker?.remove()
    },
}
</script>

<style lang="stylus" module>
.proposalMarkerWrapper
    width: 120%;
    height 120%
.proposalMarker
    position absolute
    top 0
    left 0
    display flex
    justify-content center
    align-items center
    border-radius 50%
    font-family: Noto Sans Bold;
    font-weight: 400;
    font-size 12px
    transition width var(--transitionDuration1), height var(--transitionDuration1), font-size var(--transitionDuration1), border-radius var(--transitionDuration1)
    &.proposalMarker_big,
    &.proposalMarker_more
        z-index 1
    &.proposalMarker_big
        // .chart
        //     position absolute
        &:not(.proposalMarker_more)
            width 104px
            height 104px
            font-size 15px
            box-shadow: var(--el-box-shadow-dark);
            background-color: #ffffff;
            .chartWrapper
                width calc(100% + 2px)
                height calc(100% + 2px)
    &:not(.proposalMarker_more)
        &.appearance
            width 0
            height 0

        width 104px
        height 104px
        // var(--f7-page-bg-color)
        background-color: #ffffff96
        backdrop-filter: blur(3px)
        box-shadow: var(--el-box-shadow-dark);
        .chartWrapper
            position absolute
            width calc(100% + 5px)
            height calc(100% + 5px)
            top 50%
            left 50%
            transform translate(-50%, -50%)
        .title
            font-family Noto Sans Regular
            position absolute
            text-shadow -1px -1px 0 #fff 1px -1px 0 #fff -1px 1px 0 #fff 1px 1px 0 #fff
            left calc(100% + 15px)
            background white
            padding 5px
            border-radius 20px
            box-shadow var(--el-box-shadow)
    &.proposalMarker_more
        // filter url(#shadowed-goo)
        background-color: #ffffff96
        backdrop-filter: blur(9px)
        z-index 1
        display flex
        flex-direction column
        width 300px
        height 260px
        max-height 450px
        // height (100vh - 50px)
        // padding var(--indent1) var(--indent2) var(--indent2) var(--indent2)
        padding var(--indent1) var(--indent2) var(--indent2) var(--indent2)
        border-radius var(--f7-button-border-radius)
        box-shadow var(--f7-button-raised-box-shadow)
        .chartWrapper
            width 60px
            height 60px
            position relative
        .title
            font-size 15px
            // padding-right var(--indent3)
            width 100%
            text-align center

.proposalMarker__header
    // font-weight bold
    flex-shrink 0
    display flex
    justify-content space-between
    align-items center
    width 100%
    &:not(.proposalMarker__header_more)
        position absolute
        top 0
        left 0
        width 100%
        height 100%
        display flex
        justify-content center
        align-items center
    // &.proposalMarker__header_more

.closeTrigger
    right 0
    top 0
    &:global(.button)
        position absolute
// .title
.chartWrapper
    display flex
    justify-content center
    align-items center
    font-weight 500
    cursor default
    //box-shadow var(--el-box-shadow)

.chart
    width 100%
    height 100%
    position absolute

/*pointer-events all*/
.chartMainQty
    position absolute
    z-index 1
    font-size: 16px

.chart__pathRemaining
    stroke-width 9
    stroke-linecap round
    transform rotate(90deg)
    transform-origin center
    fill-rule nonzero
    fill none
    transition var(--transitionDuration1)
    cursor pointer
    box-shadow var(--el-box-shadow)
    &:hover
        stroke-width 13
        stroke-dashoffset: 10px;
        //opacity: 0.7;


.svgText
    position absolute
    width calc(100%  + 5px)
    font-size 13px
</style>
