<script>
export default {
    components: {},
    props: {
        databaseName: {
            type: String,
            default: 'myDatabase',
        },
        storeName: {
            type: String,
            default: 'myStore',
        },
    },
    data() {
        return {}
    },
    computed: {},
    methods: {
        async isDatabaseExist(dbname) {
            const databases = indexedDB.databases
            if (!databases) return
            return (await indexedDB.databases()).some(db => db.name === dbname)
        },

        async initialize() {
            console.log('indexedDbController init')
            // if (await this.isDatabaseExist(this.databaseName)) return true
            return new Promise((resolve, reject) => {
                // const dRequest = indexedDB.deleteDatabase(this.databaseName)
                // dRequest.onerror = () => {
                //     reject(dRequest.error)
                // }
                const request = indexedDB.open(this.databaseName)
                request.onupgradeneeded = () => {
                    request.result.createObjectStore(this.storeName)
                    resolve(true)
                }
                request.onsuccess = () => {
                    resolve(true)
                }
                request.oncomplete = () => {
                    resolve(true)
                }
                request.onerror = () => {
                    reject(request.error)
                }
            })
        },

        get(key) {
            return new Promise((resolve, reject) => {
                const oRequest = indexedDB.open(this.databaseName)
                oRequest.onsuccess = () => {
                    const db = oRequest.result
                    const tx = db.transaction(this.storeName, 'readonly')
                    const st = tx.objectStore(this.storeName)
                    const gRequest = st.get(key)
                    gRequest.onsuccess = () => {
                        resolve(gRequest.result)
                    }
                    gRequest.oncomplete = () => {
                        resolve(true)
                    }
                    gRequest.onerror = () => {
                        reject(gRequest.error)
                    }
                }
                oRequest.onerror = () => {
                    reject(oRequest.error)
                }
            })
        },

        set(key, value) {
            return new Promise((resolve, reject) => {
                const oRequest = indexedDB.open(this.databaseName)
                oRequest.onsuccess = () => {
                    const db = oRequest.result
                    const tx = db.transaction(this.storeName, 'readwrite')
                    const st = tx.objectStore(this.storeName)
                    const sRequest = st.put(value, key)
                    sRequest.onsuccess = () => {
                        resolve(true)
                    }
                    sRequest.oncomplete = () => {
                        resolve(true)
                    }
                    sRequest.onerror = () => {
                        reject(sRequest.error)
                    }
                }
                oRequest.onerror = () => {
                    reject(oRequest.error)
                }
            })
        },

        remove(key) {
            return new Promise((resolve, reject) => {
                const oRequest = indexedDB.open(this.databaseName)
                oRequest.onsuccess = () => {
                    const db = oRequest.result
                    const tx = db.transaction(this.storeName, 'readwrite')
                    const st = tx.objectStore(this.storeName)
                    const rRequest = st.delete(key)
                    rRequest.onsuccess = () => {
                        resolve(true)
                    }
                    rRequest.oncomplete = () => {
                        resolve(true)
                    }
                    rRequest.onerror = () => {
                        reject(rRequest.error)
                    }
                }
                oRequest.onerror = () => {
                    reject(oRequest.error)
                }
            })
        },
        deleteDB() {
            const deleteRequest = indexedDB.deleteDatabase(this.databaseName)
            deleteRequest.onsuccess = () => {
                console.log(`Database '${this.databaseName}' deleted`)
            }
            deleteRequest.oncomplete = () => {
                console.log(`Database '${this.databaseName}' deleted`)
            }
            deleteRequest.onerror = error => {
                console.error(
                    `Database '${this.databaseName}' delete error:`,
                    error
                )
            }
        },
    },

    render() {
        return null
    },
}
</script>
