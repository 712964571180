import loadSource from '@/entities/Map/general/model/loadSource'

export default function (this: any, type, districtId) {
    let url = ''
    switch (type) {
        case this.types.CONSUMERS_TYPE:
            url = `/gasification/consumer/district/${districtId}`
            break
        case this.types.GDS_TYPE:
            url = `/infrastructure/gds/district/${districtId}`
            break
        case this.types.PIPELINE_MAJOR_TYPE:
            url = `/infrastructure/pipeline/major/district/${districtId}`
            break
        case this.types.PIPELINE_BRANCH_TYPE:
            url = `/infrastructure/pipeline/branch/district/${districtId}`
            break
        case this.types.PIPELINE_SETTLEMENT_TYPE:
            url = `/infrastructure/pipeline/settlement/district/${districtId}`
            break
    }
    return loadSource.call(this, url).then(source => {
        // console.log(`${url} source:`, source)
        this.loadDataResults[type].source = source
    })
}
