<template>
    <el-scrollbar :class="$style.timelineWrapper">
        <div :class="$style.timelineContainer">
            <div :class="$style.timelineStep">
                <div
                    :class="$style.timelineIcon"
                    v-for="(step, index) in stepsList"
                    :key="step.id"
                    @mouseenter="hoveredStepId = step.id"
                    @mouseleave="hoveredStepId = null"
                    @click="handleClickStepActiveId(step.id)"
                    :style="{
                        width: index < stepsList.length - 1 ? '100%' : 'auto',
                    }"
                >
                    <div
                        :class="[
                            $style.timelineIndicator,
                            (stepActiveId === step.id ||
                                (step.id === hoveredStepId &&
                                    step.id <= stepActiveId)) &&
                                $style.timelineIndicator_active,
                            step.id < stepActiveId &&
                                step.id !== hoveredStepId &&
                                $style.timelineIndicator_success,
                        ]"
                    ></div>
                    <div
                        :class="$style.timelineLineContainer"
                        v-if="index < stepsList.length - 1"
                    >
                        <div
                            :class="[
                                $style.timelineLine,
                                step.id < stepActiveId &&
                                    $style.timelineLine_success,
                            ]"
                        ></div>
                    </div>
                </div>
            </div>
        </div>
        <div :class="$style.timelineDescContainer">
            <div
                :class="$style.timelineDesc"
                v-for="(step, index) in stepsList"
                :key="step.id"
                :style="{
                    'justify-content':
                        index === stepsList.length - 1 || index === 0
                            ? ''
                            : 'center',
                }"
            >
                <div
                    :class="$style.timelineDesc__text"
                    @mouseenter="hoveredStepId = step.id"
                    @mouseleave="hoveredStepId = null"
                    @click="handleClickStepActiveId(step.id)"
                >
                    {{ step.content }}
                </div>
            </div>
        </div>
    </el-scrollbar>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import districtsStepAction from './methods/stepsActions/districtsActions/districtsStepAction'
import regionsStepAction from './methods/stepsActions/regionsActions/regionsStepAction'
import useChangeStepsActions from './methods/useChangeStepsActions'
import { stepsList as defaultStepsList } from '@/widgets/MenuPanel/ui/TimeLine/config/stepsLists.config'

export default {
    props: {
        stepsList: {
            type: Array,
            required: false,
            default: () => defaultStepsList,
        },
    },
    data() {
        return {
            hoveredStepId: null,
        }
    },
    methods: {
        ...mapActions(['handleClickStepActiveId']),

        useChangeStepsActions,
        regionsStepAction,
        districtsStepAction,
    },
    watch: {
        stepActiveId: {
            handler(newStepId) {
                this.useChangeStepsActions(newStepId)
            },
            immediate: true,
        },
    },
    computed: {
        ...mapState({
            stepActiveId: state => state.view.stepActiveId,
            stepsListDirection: state => state.view.stepsListDirection,
            mapZoom: state => state.map.zoom,
        }),
    },
    created() {
        // this.stepActiveId
    },
    beforeMount() {
        // this.setChangeStepByZoom(this.mapZoom)
    },
}
</script>

<style lang="stylus" module>
defaultBackgroundColor = #e4e7ed
activeBackgroundColor = #44f567

.timelineWrapper
    width: 100%
.timelineContainer
    display: flex;
    width: 100%;
.timelineStep
  display flex
  //flex-direction column
  cursor pointer
  width: 100%;
.timelineIcon
  display flex
  align-items center
  height 20px
.timelineIndicator
  background-color defaultBackgroundColor
  border-radius 50%
  width 12px
  height 12px
  transition .3s ease-in-out
  border 2px solid defaultBackgroundColor
  &_active
    border: 2px solid activeBackgroundColor
  &_success
    border: 2px solid activeBackgroundColor
    background-color activeBackgroundColor
.timelineLineContainer
    padding 9px 0
    flex 2
    height 100%
.timelineLine
    width 100%
    height 100%
    background-color defaultBackgroundColor
    transition .3s ease-in-out
    &_success
        background-color activeBackgroundColor
.timelineDescContainer
    display flex
    width 100%
.timelineDesc
    margin 5px
    font-size 14px
    width 100%
    display: flex;
.timelineDesc__text
    padding 2px 5px
    //background-color defaultBackgroundColor
    border-radius 10px
    white-space: nowrap;
    cursor pointer
</style>
