export const setPartialStateDeep = function (state, data) {
    Object.entries(data).forEach(dataEntry => {
        const dataKey = dataEntry[0]
        const dataValue = dataEntry[1]
        const stateValue = state[dataKey]
        if (
            !stateValue ||
            Object.prototype.toString.call(dataValue) !== '[object Object]'
        ) {
            state[dataKey] = dataValue
            return
        }
        setPartialStateDeep(stateValue, dataValue)
    })
    return state
}
