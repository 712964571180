import {
    CONSUMERS_TYPE,
    GDS_TYPE,
    PIPELINE_BRANCH_TYPE,
    PIPELINE_CONSUMER,
    PIPELINE_MAJOR_TYPE,
    PIPELINE_SETTLEMENT_TYPE,
} from '@/shared/constants'

export default function (this: any, url, type, id) {
    let zoom = 11
    const getSourceFromUrl = async (url, zoom) => {
        const response = await this.$axios.get(url).catch(error => {
            console.error(error)
        })
        console.log(url, response?.data)
        const source = response?.data
        source.zoom = zoom
        if (!source) return
        return source
    }

    switch (type) {
        case CONSUMERS_TYPE:
            url = `/consumers/${id}`
            zoom = 11
            break
        case GDS_TYPE:
            url = `/infrastructure/gds/district/${id}`
            break
        case PIPELINE_MAJOR_TYPE:
            url = `/infrastructure/pipeline/${id}`
            break
        case PIPELINE_BRANCH_TYPE:
            url = `/infrastructure/pipeline/${id}`
            zoom = 10
            break
        case PIPELINE_SETTLEMENT_TYPE:
            url = `/infrastructure/pipeline/${id}`
            zoom = 10
            break
        case PIPELINE_CONSUMER:
            url = `/infrastructure/pipeline/${id}`
            zoom = 12
            break
    }

    const source = getSourceFromUrl(url, zoom)

    console.log('getSourceFromUrl', source)
    return source
}
