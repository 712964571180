import addProposalsDistrict from '@/entities/Map/gasification/model/addMapLayers/addProposalsDistrict'
import prepareProposalsToGeo from '@/pages/DistrictsView/methods/prepareProposalsToGeo'
import {
    appModuleIds,
    stepsListIndexes,
    ZOOM_HIERARCHY_MIN_LEVEL,
} from '@/shared/constants'
import { watch, WatchStopHandle } from 'vue'

const watcher: {
    unwatch: WatchStopHandle | null
} = {
    unwatch: null,
}
function abortRequest(this: any, abortController: AbortController) {
    if (this.chosenAppModule?.id !== appModuleIds.gasification) {
        abortController.abort()
    }
}
export default async function (this: any, newDistrictIdsSet: string[]) {
    if (this.chosenAppModule?.id !== appModuleIds.gasification) return

    const abortController = new AbortController()
    if (watcher.unwatch) {
        console.log('abort unwatch', watcher)

        abortRequest.call(this, abortController)
        watcher.unwatch()
        watcher.unwatch = null
    }
    watcher.unwatch = this.$watch(
        () => this.chosenAppModule?.id,
        () => {
            abortRequest.call(this, abortController)
        }
    )

    const fetchedFeatures = await this.loadFeaturesByDistrictsInViewport(
        '/gasification/proposal/district/stats/',
        newDistrictIdsSet,
        abortController.signal
    )
    // console.log('fetchedFeatures', fetchedFeatures)
    if (!fetchedFeatures?.length || !fetchedFeatures) return

    for (let i = 0; i < fetchedFeatures.length; i++) {
        if (!fetchedFeatures[i]) return
        if (i > 0) {
            await addProposalsDistrict.call(
                this,
                prepareProposalsToGeo(fetchedFeatures[i]),
                true
            )
        } else {
            await addProposalsDistrict.call(
                this,
                prepareProposalsToGeo(fetchedFeatures[i])
            )
        }
    }
}
