import { stepsListIndexes } from '@/shared/constants'

export const stepsList = [
    {
        id: stepsListIndexes.regionsStepIndex,
        title: 'Выберите регион',
        content: 'Выбор региона',
    },
    {
        id: stepsListIndexes.districtsStepIndex,
        title: 'Выберите район',
        content: 'Выбор района',
    },
    {
        id: stepsListIndexes.districtInfoStepIndex,
        title: 'Информация по району',
        content: 'Информация по району',
        stepsList: [
            {
                id: stepsListIndexes.gdsInfoStepIndex,
                title: 'Информация по ГРС',
                content: 'Информация по ГРС',
            },
            {
                id: stepsListIndexes.gasPlanStepIndex,
                title: 'План газификации',
                content: 'План газификации',
            },
        ],
    },
]

export const stepsList_ANGKS = [
    {
        id: stepsListIndexes.regionsStepIndex,
        title: 'Выберите регион',
        content: 'Выбор региона',
    },
    {
        id: stepsListIndexes.districtsStepIndex,
        title: 'Объекты АГНКС в регионе',
        content: 'Объекты АГНКС в регионе',
        stepsList: [
            {
                id: stepsListIndexes.agnksInfoStepIndex,
                title: 'Информация по АГНКС',
                content: 'Информация по АГНКС',
            },
        ],
    },
]
