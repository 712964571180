export default function (this: any, mainLayerId, layerProps) {
    const allLayers = this.map.getStyle().layers
    const mainLayerStyle = this.getLayerById(allLayers, mainLayerId)
    const mainLayerStyleIndex = allLayers.indexOf(mainLayerStyle)

    // console.log('mainLayerId', mainLayerId, 'mainLayerStyleIndex', mainLayerStyleIndex)

    //insert layer right after main layer in layers hierarchy
    if (mainLayerStyleIndex > -1) {
        allLayers.splice(mainLayerStyleIndex + 1, 0, layerProps)

        const mapStyle = this.map.getStyle()
        this.map.setStyle({ ...mapStyle, layers: allLayers })
    }

    const { id, isLayerSave = true } = layerProps

    if (isLayerSave) {
        this.activeRegionLayers[id] = layerProps
    }
}
