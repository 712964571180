import { popupTypes } from '@/entities/Map/general/model/addLayerHoverActions/types/popupTypes'

export default async function (this: any) {
    const azsFeatures = await this.loadSource('/lng/station/russia')
    if (!azsFeatures) throw new Error('!azsFeatures')
    console.log('lakesFeatures:', azsFeatures)
    const azsLayerId = 'lng_stations'

    this.addSource(
        this.azsTileSourceName,
        this.featureCollectionType,
        azsFeatures
    )
    const azsSourceLayer = this.getLayerById(
        this.map.getStyle().layers,
        azsLayerId
    )

    return () => {
        this.addLayerHoverActions(azsLayerId, {
            mainLayerOptions: azsLayerId,

            hoveringLayerOptions: {
                type: this.layerTypeCircle,
                source: this.azsTileSourceName,
                minzoom: azsSourceLayer.minzoom,
                paint: {
                    'circle-color': 'rgba(0,0,0,0)',
                    'circle-radius': 20,
                    'circle-opacity': 0.7,
                },
            },

            hoveringDecorationLayerOptions: {
                type: this.layerTypeCircle,
                source: this.azsTileSourceName,
                minzoom: azsSourceLayer.minzoom,
                paint: {
                    // 'line-color': this.colors['17'],
                    'circle-color': [
                        'case',
                        ['boolean', ['feature-state', 'hover'], false],
                        // this.colors[39],
                        // this.colors[39],
                        'rgba(0,0,0,0)',
                        'rgba(0,0,0,0)',
                    ],
                    'circle-radius': [
                        'case',
                        ['boolean', ['feature-state', 'hover'], false],
                        10,
                        5,
                    ],
                    'circle-opacity': [
                        'case',
                        ['boolean', ['feature-state', 'hover'], false],
                        0.7,
                        0,
                    ],
                },
            },

            getHtmlContent: () => {
                return {
                    htmlContent: null,
                    type: popupTypes.lngPopup,
                }
            },
        })
    }
}
