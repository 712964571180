import { checkSetCollectionChanged } from '@/shared/lib/checkSetCollectionChanged'

export default function (this: any, layerId: string, zoomLevel = 10) {
    if (this.mapZoom < zoomLevel) return

    const newFeaturesSet = [...this.getLayerFeaturesInViewport(layerId)]

    // console.log(
    //     'checkSetCollectionChanged',
    //     checkSetCollectionChanged(
    //         this.$state.map.districtsInViewport,
    //         newFeaturesSet
    //     )
    // )
    // console.log('performance start', performance.now());
    if (
        checkSetCollectionChanged(
            this.$state.map.districtsInViewport,
            newFeaturesSet
        )
    ) {
        this.$state.map.districtsInViewport = newFeaturesSet
    }
    // console.log('performance end', performance.now());
}
