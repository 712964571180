<template>
    <div
        :class="[
            $style.mapPopupContainer,
            popupProperties?.closeButton &&
                $style.mapPopupContainer_withCloseButton,
        ]"
        :style="{
            background: popupProperties.backgroundColor
                ? popupProperties.backgroundColor
                : '#ffffff85',
        }"
    >
        <div :class="$style.mapPopup__block">
            <div :class="$style.mapPopup__title">
                {{ properties?.name || 'нет данных' }}
            </div>
            <!--            <div :class="$style.mapPopup__subtitle">-->
            <!--                {{ properties?.address || 'нет данных' }}-->
            <!--            </div>-->
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    data() {
        return {}
    },
    computed: {
        ...mapState({
            featureId: state => state.map.popupMap.id,
            properties: state => state.map.popupMap.properties,
            popupLngLat: state => state.map.popupMap.lngLat,
            popupIsLoading: state => state.map.popupMap.isLoading,
            popupInfo: state => state.map.popupMap.info,
            popupProperties: state => state.map.popupMap.popupProperties,
        }),
    },
    methods: {
        stylizePopupDefaultEls() {
            const closeButton = document.querySelector(
                '.maplibregl-popup-close-button'
            )
            if (!closeButton) return

            closeButton.style.display = this.popupProperties?.closeButton
                ? 'flex'
                : 'none'
        },
    },
    updated() {
        this.stylizePopupDefaultEls()
    },
}
</script>

<style lang="stylus" module src="./generalMapPopupStyle.styl"></style>
