import { MapLayerEventType } from 'maplibre-gl'

import { IPulseEffect } from '@/entities/Map/general/model/mapUtils/addPulsedIconClass'

export default function (
    this: any,
    event: MapLayerEventType['click'],
    sourceId: string,
    sourceLayerId: string,
    pulsedIcon?: IPulseEffect
): void {
    if (event.features) {
        this.$dispatch('focusObject', {
            sourceId,
            sourceLayerId,
            feature: event.features[0],
            context: this,
        })

        pulsedIcon && this.$commit('setPulseEffect', pulsedIcon)
    }

    event.originalEvent?.preventDefault()
}
