import { stepsListIndexes } from '@/shared/constants'

export default function (this: any, stepId) {
    if (!this.$state.map.isAutomaticMapEvent) return

    switch (stepId) {
        case stepsListIndexes.regionsStepIndex:
            this.regionsStepAction()
            break
        case stepsListIndexes.districtsStepIndex:
            this.districtsStepAction()
            break
    }
}
