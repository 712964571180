import { appModuleIds, stepsListIndexes } from '@/shared/constants'

export const stepListIndexesToChangeByZoom = [
    stepsListIndexes.regionsStepIndex,
    stepsListIndexes.districtsStepIndex,
    stepsListIndexes.districtInfoStepIndex,
]

export const stepListIndexesToChangeByZoomMapByAppModules: {
    [key in keyof typeof appModuleIds]?: string[]
} = {
    [appModuleIds.AGNKS]: [
        stepsListIndexes.regionsStepIndex,
        stepsListIndexes.districtsStepIndex,
    ],
    [appModuleIds.gasificationPlan]: [
        stepsListIndexes.regionsStepIndex,
        stepsListIndexes.districtsStepIndex,
    ],
    [appModuleIds.gasification]: [...stepListIndexesToChangeByZoom],
    [appModuleIds.spg]: [...stepListIndexesToChangeByZoom],
}
