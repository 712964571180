import { LAYER_MIN_ZOOM2 } from '@/shared/constants'
export default function (
    this: any,
    { buildingsSource, emptySource, layerId, sourceId }
) {
    // const getBuildings = () => {
    //     const {
    //         _sw: { lng: swLng, lat: swLat },
    //         _ne: { lng: neLng, lat: neLat },
    //     } = this.map.getBounds()
    //
    //     // const url = `/city_area/by_extent?x1=${convertedSwLng}&y1=${convertedSwLat}&x2=${convertedNeLng}&y2=${convertedNeLat}`
    //     const url = `/building/by_extent?x1=${swLng}&y1=${swLat}&x2=${neLng}&y2=${neLat}`
    //     this.$axios.get(url).then(response => {
    //         const data = response.data
    //         if (!data) {
    //             buildingsSource.setData(emptySource)
    //             return
    //         }
    //         // buildingsSource.setData(data)
    //
    //         buildingsSource.setData(data)
    //     })
    // }
    // this.map.on('idle', () => {
    //     getBuildings()
    // })
    // getBuildings()
    // this.map.on('movestart', () => {
    //     getBuildings()
    // })
    const removeFeatureHoveredState = id => {
        this.map.setFeatureState(
            {
                source: sourceId,
                id,
            },
            { isHovered: false }
        )
    }
    let hoveredBuildingId = ''
    this.map.on('mousemove', layerId, event => {
        if (hoveredBuildingId) removeFeatureHoveredState(hoveredBuildingId)
        const firstFeatureId = event?.features?.[0]?.id
        if (!firstFeatureId) return
        hoveredBuildingId = firstFeatureId
        this.map.setFeatureState(
            {
                source: sourceId,
                id: hoveredBuildingId,
            },
            { isHovered: true }
        )
    })
    this.map.on('mouseleave', layerId, () => {
        if (hoveredBuildingId) removeFeatureHoveredState(hoveredBuildingId)
    })

    this.map.on('moveend', () => {
        const zoom = this.map.getZoom()
        if (zoom < LAYER_MIN_ZOOM2) return
        setTimeout(() => {
            // getBuildings()
        }, 0)
    })
}
