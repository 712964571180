import {
    PIPELINE_STATUS_OPERATING,
    PIPELINE_STATUS_PROJECTED,
    PIPELINE_STATUS_UNDER_CONSTRUCTION,
    PIPELINE_TYPE_MAJOR,
} from '@/shared/constants'
import { popupTypes } from '@/entities/Map/general/model/addLayerHoverActions/types/popupTypes'
// import { convertProjection /* multiLineStringToLineString */ } from '@/lib'
export default async function (this: any) {
    // const underConstructionIconSvg =
    //     this.getPipelineSvgPattern(PIPELINE_TYPE_MAJOR)
    // if (!underConstructionIconSvg) return
    // const loadedImg = await this.loadSvgToMapImages(
    //     underConstructionIconSvg,
    //     this.underConstructionMajorPipelineLayerId
    // )
    // if (!loadedImg) return
    // }

    // this.map.setPaintProperty(
    //     this.pipelinesMajorUrl,
    //     'line-color',
    //     'transparent'
    // )
    const sourceLayerUnderConstruction = this.getLayerById(
        this.map.getStyle().layers,
        this.underConstructionMajorPipelineLayerId
    )
    const sourceLayerProjected = this.getLayerById(
        this.map.getStyle().layers,
        this.projectedMajorPipelineLayerId
    )

    return () => {
        const lineOverallPaintOptions = {
            'line-color': this.colors['37'],
        }

        // this.addLayerAfter(this.pipelinesMajorUrl, {
        //     ...sourceLayer,
        //
        //     id: this.operatingMajorPipelineLayerId,
        //     type: this.layerTypeLine,
        //     // source: this.pipelinesMajorUrl,
        //     source: this.infrastructureTileSourceName,
        //     'source-layer': this.pipelinesMajorUrl,
        //     paint: {
        //         ...lineOverallPaintOptions,
        //         // 'line-color': '#007ac2',
        //         'line-width': {
        //             type: 'exponential',
        //             base: 6,
        //             stops: [
        //                 [0, 1.5],
        //                 [this.layerMinZoom1, 3],
        //                 [this.mapMaxZoom, 6],
        //             ],
        //         },
        //     },
        //     layout: {
        //         'line-cap': 'round',
        //         'line-join': 'round',
        //     },
        //     filter: ['==', 'status', PIPELINE_STATUS_OPERATING],
        // })
        //
        // this.addLayerAfter(this.operatingMajorPipelineLayerId, {
        //     ...sourceLayer,
        //
        //     id: this.projectedMajorPipelineLayerId,
        //     type: this.layerTypeLine,
        //     // source: this.pipelinesMajorUrl,
        //     source: this.infrastructureTileSourceName,
        //     'source-layer': this.pipelinesMajorUrl,
        //     paint: {
        //         ...lineOverallPaintOptions,
        //         'line-dasharray': [1, 3],
        //         // 'line-color': this.colors[3],
        //         // 'line-color': 'rgba(255,255,0,0.55)',
        //         // 'line-gradient': [],
        //         // 'line-gap-width': 1,
        //     },
        //     layout: {
        //         'line-cap': 'round',
        //         'line-join': 'round',
        //     },
        //     filter: ['==', 'status', PIPELINE_STATUS_PROJECTED],
        // })
        //
        // this.addLayerAfter(this.projectedMajorPipelineLayerId, {
        //     id: this.underConstructionMajorPipelineLayerId,
        //     type: this.layerTypeLine,
        //     // source: this.pipelinesMajorUrl,
        //     source: this.infrastructureTileSourceName,
        //     'source-layer': this.pipelinesMajorUrl,
        //     paint: {
        //         'line-dasharray': [0, 3],
        //         'line-color': this.colors['3_opacity1'],
        //         'line-width': 4,
        //     },
        //     layout: {
        //         'line-cap': 'round',
        //         'line-join': 'round',
        //     },
        //     filter: ['==', 'status', PIPELINE_STATUS_UNDER_CONSTRUCTION],
        // })

        sourceLayerUnderConstruction &&
            this.addLayerHoverActions(this.projectedMajorPipelineLayerId, {
                mainLayerOptions: {
                    ...sourceLayerUnderConstruction,
                    id: this.underConstructionMajorPipelineLayerId,
                },

                hoveringLayerOptions: {
                    type: this.layerTypeLine,
                    source: this.infrastructureTileSourceName,
                    'source-layer': this.pipelinesMajorUrl,
                    layout: {
                        'line-cap': 'round',
                        'line-join': 'round',
                    },
                    paint: {
                        'line-color': 'rgba(0,0,0,0)',
                        'line-width': 20,
                    },
                },

                hoveringDecorationLayerOptions: {
                    type: this.layerTypeLine,
                    source: this.infrastructureTileSourceName,
                    'source-layer': this.pipelinesMajorUrl,
                    layout: {
                        'line-cap': 'round',
                        'line-join': 'round',
                    },
                    paint: {
                        // 'line-color': this.colors['17'],
                        'line-color': [
                            'case',
                            ['boolean', ['feature-state', 'hover'], false],
                            this.colors['17'],
                            'rgba(0,0,0,0)',
                        ],
                        'line-width': [
                            'case',
                            ['boolean', ['feature-state', 'hover'], false],
                            10,
                            5,
                        ],
                    },
                },

                getHtmlContent: feature => {
                    const {
                        properties: { name, year },
                    } = feature
                    let html = ''
                    if (name) {
                        html += `<div>${name}</div>`
                    }
                    if (year) {
                        html += `<div>Окончание работ: ${year}</div>`
                    }
                    return {
                        htmlContent: html,
                        type: popupTypes.inlineHtml,
                    }
                },
            })

        sourceLayerProjected &&
            this.addLayerHoverActions(
                this.underConstructionMajorPipelineLayerId,
                {
                    mainLayerOptions: {
                        ...sourceLayerProjected,
                        id: this.projectedMajorPipelineLayerId,
                    },

                    hoveringLayerOptions: {
                        type: this.layerTypeLine,
                        source: this.infrastructureTileSourceName,
                        'source-layer': this.pipelinesMajorUrl,
                        layout: {
                            'line-cap': 'round',
                            'line-join': 'round',
                        },
                        paint: {
                            'line-color': 'rgba(0,0,0,0)',
                            'line-width': 20,
                        },
                    },

                    hoveringDecorationLayerOptions: {
                        type: this.layerTypeLine,
                        source: this.infrastructureTileSourceName,
                        'source-layer': this.pipelinesMajorUrl,
                        layout: {
                            'line-cap': 'round',
                            'line-join': 'round',
                        },
                        paint: {
                            // 'line-color': this.colors['17'],
                            'line-color': [
                                'case',
                                ['boolean', ['feature-state', 'hover'], false],
                                this.colors['17'],
                                'rgba(0,0,0,0)',
                            ],
                            'line-width': [
                                'case',
                                ['boolean', ['feature-state', 'hover'], false],
                                10,
                                5,
                            ],
                        },
                    },

                    getHtmlContent: feature => {
                        const {
                            properties: { name, year },
                        } = feature
                        let html = ''
                        if (name) {
                            html += `<div>${name}</div>`
                        }
                        if (year) {
                            html += `<div>Окончание работ: ${year}</div>`
                        }
                        return {
                            htmlContent: html,
                            type: popupTypes.default,
                        }
                    },
                }
            )
        // const mouseenterHandler = () => {
        //     this.mapCanvasStyle.cursor = 'help'
        // }
        // const mouseleaveHandler = () => {
        //     this.mapCanvasStyle.cursor = ''
        // }
        // this.map.on(
        //     'mouseenter',
        //     this.operatingMajorPipelineLayerId,
        //     mouseenterHandler
        // )
        // this.map.on(
        //     'mouseleave',
        //     this.operatingMajorPipelineLayerId,
        //     mouseleaveHandler
        // )
    }
}
