import {
    ANGKSStatuses,
    ANGKSStatusesColors,
} from '@/entities/ANGKS/model/AGNKSEntity.types'
import setEvents from '@/entities/Map/AGNKS/model/addEvents/setEvents'
import addLayerCluster from '@/shared/plugins/CanvasClusterCustom/clusterFeatures/addLayerCluster'
import { clustersDbscan, featureCollection } from '@turf/turf'
import { SymbolLayerSpecification } from 'maplibre-gl'
import { initChart } from '@/shared/plugins/CanvasClusterCustom/EchartsIntegration/model/initChart'
import { pieChartShell } from '@/entities/Map/AGNKS/model/Charts/pieChartShell'
import setClusterEvents from '@/entities/Map/general/model/eventMethods/setClusterEvents'
import addSuperCluster from '@/shared/plugins/CanvasClusterCustom/clusterFeatures/addSuperCluster'
import { ECharts } from 'echarts'
import { agnks_statuses_series_name } from '@/entities/Map/AGNKS/model/Charts/constants'

const agnksLayerId = 'AGNKS'
export const agnksMainLayerIdFeature = `${agnksLayerId}_LARGE_22`

export default async function (this: any) {
    const agnksFeatures = this.$state.AGNKSEntity.featureCollections.all
    if (!agnksFeatures) throw new Error('!AGNKSFeatures')
    const agnksSourceId = 'AGNKS'

    await this.addSource(
        agnksSourceId,
        this.featureCollectionType,
        featureCollection([]),
        this.map
    )

    const agnksLayer: SymbolLayerSpecification = {
        id: agnksMainLayerIdFeature,
        type: 'symbol',
        source: agnksSourceId,
        layout: {
            'icon-allow-overlap': true,
            'icon-image': [
                'case',
                ['==', ['get', 'status'], ANGKSStatuses.UNDER_CONSTRUCTION],
                'AGNKS_under_construction_22',
                ['==', ['get', 'status'], ANGKSStatuses.OPERATING],
                'AGNKS_operating_22',
                'AGNKS_under_construction_22',
            ],
        },
    }

    const addingLayers = [agnksLayer]
    addingLayers.forEach(layer => this.addLayer(layer))
    setEvents.call(
        this,
        addingLayers.map(layer => layer.id)
    )

    //add clusters
    const addClusterChartEvents = (chart: ECharts) => {
        chart.on(
            'mouseover',
            { seriesName: agnks_statuses_series_name },
            (params: any) => {
                chart.setOption({
                    title: {
                        show: false,
                    },
                })
            }
        )
        chart.on(
            'mouseout',
            { seriesName: agnks_statuses_series_name },
            (params: any) => {
                chart.setOption({
                    title: {
                        show: true,
                    },
                })
            }
        )

        chart.on('mousemove', 'title', (params: any) => {
            chart.getZr().setCursorStyle('zoom-in')
        })
        chart.on('mouseout', 'title', (params: any) => {
            chart.getZr().setCursorStyle('default')
        })
    }
    const { layer: clusterLayer, supercluster } = addSuperCluster(
        this.map,
        'agnks_cluster',
        agnksFeatures,
        agnksLayer,
        initChart(pieChartShell, addClusterChartEvents)
    )

    setClusterEvents.call(
        this,
        [clusterLayer].map(layer => layer.id),
        clusterId => supercluster.getClusterExpansionZoom(clusterId)
    )
}
