import { setPartialState } from '@/shared/lib'
import { tileServerType, tileServerTypes } from '@/shared/constants'
import { IPulseEffect } from '@/entities/Map/general/model/mapUtils/addPulsedIconClass'
import { proposalsInfoMarker } from '@/app/providers/store/modules/types/mapTypes'
import { markerTransition } from '@/entities/Map/general/ui/ProposalStatsInfoMarker/constants'
const getDefaultState = () => ({
    mapStyleCurrentType: tileServerType.tileServerSergStyle,
    mapStyleTypes: tileServerTypes,
    center: [107.98639829758218, 68.43758819956565],
    zoom: 1,
    bearing: 0,
    pitch: 0,
    metersInPx: 0,
    bounds: null,
    // isShowPopulationDensity: true,
    isAutomaticMapEvent: false,
    focusedFeatureOptions: null,
    focusedObject: null,
    highlightSourceAndLayers: [],
    pulseEffectInstance: null,
    isShowPopulationDensity: false,
    isShowProposals: true,
    isShowGds: true,
    isShowConsumers: true,
    isShowCityAreas: true,
    isShowAzs: true,
    isShowGasPlans: true,
    activeDistrictsFillLayerId: '',
    popupMap: {
        popupInstance: null,
        isShow: false,
        popupType: '',
        id: null,
        lngLat: [],
        properties: null,
        popupProperties: {
            closeButton: true,
        },
        info: [] as any[] | string,
        isLoading: false,
    },
    proposalsInfoMarkers: [] as proposalsInfoMarker[],
    activeLayersGroup: 'scheme',
    districtsInViewport: null,
    divisionsData: {
        regions: null,
    },
    isAutomaticZoomChangeLocked: false,
})
export default {
    state: {
        defaultState: getDefaultState(),
        ...getDefaultState(),
    },
    mutations: {
        setMapPartialState: setPartialState,

        setIsAutomaticZoomChangeLocked: (
            state: any,
            payload: { isLocked: boolean }
        ) => {
            state.isAutomaticZoomChangeLocked = payload.isLocked
        },
        setFocusedObj: (state: any, payload: { feature: any }) => {
            state.focusedObject = payload.feature
        },
        setPulseEffect: (state: any, payload: IPulseEffect) => {
            console.log('setPulseEffect')
            state.pulseEffectInstance = Object.freeze(payload)
            state.pulseEffectInstance?.add()
        },
        clearMap: (state: any, { context, event }) => {
            console.log('clearMap event', context, event?._defaultPrevented)
            if (state.focusedObject?.id && context) {
                const newState = {
                    source: state.focusedObject.source,
                    id: state.focusedObject.id,
                }
                context.map.setFeatureState(
                    (state.focusedObject.sourceLayer && {
                        ...newState,
                        sourceLayer: state.focusedObject.sourceLayer,
                    }) ||
                        newState,
                    { isFocused: false }
                )

                state.focusedObject = null
                // remove pulseEffect
                state.pulseEffectInstance?.remove()
                state.pulseEffectInstance = null
            }
            if (state.highlightSourceAndLayers.length) {
                state.highlightSourceAndLayers.forEach(layerId =>
                    context.map.removeLayer(layerId)
                )
                state.highlightSourceAndLayers = []
            }
            //removeAllProposalStatsInfoMarkers
            if (state.proposalsInfoMarkers.length) {
                state.proposalsInfoMarkers.forEach(
                    (marker: proposalsInfoMarker) => {
                        marker.isShow = false
                    }
                )
                state.proposalsInfoMarkers = []
            }
        },
        removeProposalStatsInfoMarker: (state: any, { markerId }) => {
            console.log('markerId', markerId)
            state.proposalsInfoMarkers = state.proposalsInfoMarkers.filter(
                marker => marker.id !== markerId
            )
        },
    },
    actions: {
        focusObject: (
            { commit, state },
            { sourceId, sourceLayerId, feature, context }
        ) => {
            commit('clearMap', { context })

            context.map.setFeatureState(
                {
                    source: sourceId,
                    sourceLayer: sourceLayerId,
                    id: feature.id,
                },
                { isFocused: true }
            )

            commit('setFocusedObj', { feature })
        },
    },
    modules: {},
}
