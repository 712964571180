export default function (this: any, event) {
    const districtFeature = event?.features?.[0]
    console.log('districtFeature', districtFeature, event)
    if (!districtFeature) return
    this.$commit('setViewPartialState', {
        isMenuOpen: true,
    })
    const districtFeatureId = districtFeature.properties.id
    if (!districtFeatureId) return

    this.setInitialLoadDataResult()
    this.loadDistrictObjectsData(districtFeatureId)

    this.isShowDistrictInfoBlock = true
    const districtFeatureProperties = districtFeature.properties
    if (!districtFeatureProperties) return
    const districtName = districtFeatureProperties.name
    if (districtName) {
        this.selectedDistrictName = districtName
    }
}
