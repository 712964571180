import {
    COLORS,
    CONSUMER_STATUS_OPERATING,
    CONSUMER_STATUS_PROJECTED,
    INFRASTRUCTURE_URL,
    MAIN_MAP_STYLE_URL,
    MAIN_MAP_STYLE_URL_PROD,
    PIPELINE_STATUS_OPERATING,
    PIPELINE_STATUS_PROJECTED,
    TILE_SERVER_SERG_URL,
} from '@/shared/constants'

import customizeLayersStyle from './customizeLayersStyle'
const infrastructureUrl = process.env.VUE_APP_BUILD_WITH_DEVELOPMENT_PARTS
    ? 'http://192.168.245.196:8162/infrastructure/'
    : 'https://gmap.cdu.ru/tile/infrastructure/'
const substratStyleUrl = TILE_SERVER_SERG_URL
const infrastructureStyleUrl = INFRASTRUCTURE_URL
const mainStyleUrl = process.env.VUE_APP_BUILD_WITH_DEVELOPMENT_PARTS
    ? MAIN_MAP_STYLE_URL
    : MAIN_MAP_STYLE_URL_PROD

export default async function (this: any) {
    let style = {
        id: 'gas',
        version: 8,
        layers: [
            // {
            //     id: 'rasterTiles',
            //     type: 'raster',
            //     source: 'raster-tiles',
            //     minzoom: 0,
            //     maxzoom: 22,
            // },
            {
                id: 'region',
                source: 'infrastructure',
                type: 'fill',
                layout: {},
                paint: {
                    // 'fill-outline-color': 'rgb(100,100,100)',
                    'fill-outline-color': 'transparent',
                    // 'fill-color': 'rgb(230,230,230)',
                    'fill-color': 'transparent',
                    'fill-opacity': 0.3,
                },
                'source-layer': 'region',
            },
            {
                id: 'region_lines',
                source: 'infrastructure',
                type: 'line',
                layout: {},
                paint: {
                    // 'line-color': 'rgba(211,211,211,0.76)',
                    'line-color': 'transparent',
                    'line-opacity': 0.6,
                    'line-width': 4,
                },
                'source-layer': 'region',
            },
            {
                id: 'district',
                source: 'infrastructure',
                type: 'fill',
                layout: {},
                paint: {
                    'fill-outline-color': 'rgb(150,150,150)',
                    'fill-color': 'rgb(230,230,230)',
                },
                'source-layer': 'district',
            },
            {
                id: 'city_town',
                type: 'fill',
                source: 'infrastructure',
                paint: {
                    // 'fill-color': 'rgb(176,176,176)',
                    'fill-color': 'rgba(176,176,176,0.71)',
                    'fill-opacity': 0.3,
                },
                'source-layer': 'city_town',
            },
            {
                id: 'city_area',
                source: 'infrastructure',
                type: 'fill',
                layout: {},
                paint: {
                    'fill-color': [
                        'case',
                        ['==', ['get', 'status'], 'DISCONNECTED'],
                        'rgb(234,140,112)',
                        ['==', ['get', 'status'], 'CONNECTED'],
                        'rgb(126,209,79)',
                        'rgb(230,230,230)',
                    ],
                },
                'source-layer': 'city_area',
            },
            // {
            //   id: 'building',
            //   source: 'infrastructure',
            //   type: 'fill',
            //   layout: {},
            //   paint: { 'fill-color': 'rgb(161,158,157)' },
            //   'source-layer': 'building',
            // },
            {
                id: 'gas_pipeline_major',
                source: 'infrastructure',
                type: 'line',
                layout: { 'line-cap': 'round', 'line-join': 'round' },
                paint: {
                    'line-width': 4,
                    // 'line-color': 'rgb(89,89,89)',
                    'line-color': 'rgb(89,89,89)',
                },
                'source-layer': 'gas_pipeline_major',
            },
            {
                id: 'gas_pipeline_branch',
                source: 'infrastructure',
                type: 'line',
                layout: { 'line-cap': 'round', 'line-join': 'round' },
                paint: {
                    'line-width': 3,
                    'line-color': 'rgb(71,97,167)',
                },
                'source-layer': 'gas_pipeline_branch',
            },
            {
                id: 'gas_pipeline_inter_settlement',
                source: 'infrastructure',
                type: 'line',
                layout: { 'line-cap': 'round', 'line-join': 'round' },
                paint: {
                    'line-width': 1,
                    'line-color': 'rgb(71,97,167)',
                },
                'source-layer': 'gas_pipeline_inter_settlement',
            },
            {
                id: 'gas_pipeline_consumer',
                source: 'infrastructure',
                type: 'line',
                layout: { 'line-cap': 'round', 'line-join': 'round' },
                paint: {
                    'line-width': 1,
                    'line-color': 'rgb(71,97,167)',
                },
                'source-layer': 'gas_pipeline_consumer',
            },
            {
                id: 'consumer',
                type: 'circle',
                source: 'infrastructure',
                'source-layer': 'consumer',
                paint: {
                    'circle-radius': 2.5,
                    'circle-color': [
                        'case',
                        ['==', ['get', 'status'], CONSUMER_STATUS_OPERATING],
                        this.colors[4],
                        ['==', ['get', 'status'], CONSUMER_STATUS_PROJECTED],
                        this.colors[9],
                        // this.colors[3],
                        'transparent',
                    ],
                    'circle-stroke-color': [
                        'case',
                        ['==', ['get', 'status'], CONSUMER_STATUS_OPERATING],
                        'transparent',
                        ['==', ['get', 'status'], CONSUMER_STATUS_PROJECTED],
                        this.colors[4],
                        // this.colors[3],
                        'transparent',
                    ],
                    'circle-stroke-width': 1,
                    'circle-opacity': 0.9,
                },
            },
            {
                id: 'consumer_big',
                type: 'circle',
                source: 'infrastructure',
                'source-layer': 'consumer_big',
                paint: {
                    'circle-radius': 4,
                    'circle-opacity': 0.9,
                    'circle-color': [
                        'case',
                        ['==', ['get', 'status'], CONSUMER_STATUS_OPERATING],
                        this.colors[4],
                        ['==', ['get', 'status'], CONSUMER_STATUS_PROJECTED],
                        this.colors[9],
                        // this.colors[3],
                        'transparent',
                    ],
                    'circle-stroke-color': [
                        'case',
                        ['==', ['get', 'status'], CONSUMER_STATUS_OPERATING],
                        this.colors[9],
                        ['==', ['get', 'status'], CONSUMER_STATUS_PROJECTED],
                        this.colors[4],
                        // this.colors[3],
                        'transparent',
                    ],
                    'circle-stroke-width': 1,
                },
            },
            {
                id: 'consumer_main_big',
                type: 'circle',
                source: 'infrastructure',
                'source-layer': 'consumer_main_big',
                paint: {
                    'circle-radius': 4,
                    'circle-color': [
                        'case',
                        ['==', ['get', 'status'], CONSUMER_STATUS_OPERATING],
                        this.colors[4],
                        ['==', ['get', 'status'], CONSUMER_STATUS_PROJECTED],
                        this.colors[9],
                        // this.colors[3],
                        'transparent',
                    ],
                    'circle-stroke-color': [
                        'case',
                        ['==', ['get', 'status'], CONSUMER_STATUS_OPERATING],
                        this.colors[9],
                        ['==', ['get', 'status'], CONSUMER_STATUS_PROJECTED],
                        this.colors[4],
                        // this.colors[3],
                        'transparent',
                    ],
                    'circle-stroke-width': 1,
                    'circle-opacity': 0.9,
                },
            },
            {
                id: 'consumer_small',
                type: 'circle',
                source: 'infrastructure',
                'source-layer': 'consumer_small',
                paint: {
                    'circle-radius': 2,
                    'circle-color': [
                        'case',
                        ['==', ['get', 'status'], CONSUMER_STATUS_OPERATING],
                        this.colors[4],
                        ['==', ['get', 'status'], CONSUMER_STATUS_PROJECTED],
                        this.colors[9],
                        // this.colors[3],
                        'transparent',
                    ],
                    // 'circle-stroke-color': [
                    //     'case',
                    //     [
                    //         '==',
                    //         ['get', 'status'],
                    //         CONSUMER_STATUS_OPERATING,
                    //     ],
                    //     this.colors[9],
                    //     [
                    //         '==',
                    //         ['get', 'status'],
                    //         CONSUMER_STATUS_PROJECTED,
                    //     ],
                    //     this.colors[4],
                    //     // this.colors[3],
                    //     'transparent',
                    // ],
                    // 'circle-stroke-width': 1,
                    'circle-opacity': 0.9,
                },
            },
            // {
            //     id: 'consumer',
            //     source: 'infrastructure',
            //     type: 'circle',
            //     layout: {},
            //     paint: {
            //         'circle-stroke-width': 1,
            //         'circle-color': 'rgb(104,130,175)',
            //         'circle-stroke-color': 'rgb(255,255,255)',
            //         'circle-radius': [
            //             'case',
            //             ['==', ['get', 'type'], 'MAIN_BIG'],
            //             4,
            //             ['==', ['get', 'type'], 'BIG'],
            //             3,
            //             ['==', ['get', 'type'], 'SMALL'],
            //             2,
            //             3,
            //         ],
            //     },
            //     'source-layer': 'consumer',
            // },
            {
                id: 'gas_distribution_station',
                source: 'infrastructure',
                type: 'circle',
                layout: {
                    visibility: 'none',
                },
                paint: {
                    'circle-stroke-width': 1,
                    'circle-color': 'rgb(240,230,140)',
                    'circle-stroke-color': 'rgb(189,183,107)',
                    'circle-radius': 5,
                },
                'source-layer': 'gas_distribution_station',
            },
            // {
            //     id: 'thermoelectric_power_station',
            //     source: 'infrastructure',
            //     type: 'circle',
            //     layout: {},
            //     paint: {
            //         'circle-stroke-width': 1,
            //         'circle-color': 'rgb(255,165,0)',
            //         'circle-stroke-color': 'rgb(255,255,255)',
            //         'circle-radius': 5,
            //     },
            //     'source-layer': 'thermoelectric_power_station',
            // },

            //*tile server Serega*
            // {
            //     id: 'shpLand__fill',
            //     type: 'fill',
            //     source: 'TILES_FROM_SHAPES_SOURCE_NAME',
            //     'source-layer': 'shpLand',
            //     paint: {
            //         'fill-color': 'rgba(34, 185, 89, 0.392)',
            //     },
            // },
            // {
            //     id: 'shpWater_custom',
            //     type: 'fill',
            //     source: 'TILES_FROM_SHAPES_SOURCE_NAME',
            //     'source-layer': 'shpWater',
            //     paint: {
            //         'fill-color': 'rgb(158,189,255)',
            //     },
            // },
            // {
            //     id: 'shp_water-polygon__fill',
            //     type: 'fill',
            //     source: 'TILES_FROM_SHAPES_SOURCE_NAME',
            //     'source-layer': 'shp_water-polygon',
            //     paint: {
            //         'fill-color': 'rgb(158,189,255)',
            //     },
            // },
        ],
        sources: {
            infrastructure: {
                type: 'vector',
                // url: 'http://192.168.245.196:8162/infrastructure/',
                url: infrastructureUrl,
            },

            //     *tile server Serega*
            //     TILES_FROM_SHAPES_SOURCE_NAME: {
            //         type: 'vector',
            //         tiles: [
            //             'http://192.168.245.238:3000/tile/{z}/{x}/{y}.vector.pbf',
            //         ],
            //         minZoom: 0,
            //         maxZoom: 14,
            //         maxzoom: 24,
            //     },
        },
    }
    // (style.sources['raster-tiles'] = {
    //     type: 'raster',
    //     tiles: [
    //         // 'https://stamen-tiles.a.ssl.fastly.net/watercolor/{z}/{x}/{y}.jpg',
    //         // 'https://pkk.rosreestr.ru/arcgis/rest/services/BaseMaps/BaseMap/MapServer/tile/{z}/{x}/{y}',
    //         'https://pkk.rosreestr.ru/arcgis/rest/services/BaseMaps/BaseMap/MapServer/tile/{z}/{y}/{x}/',
    //     ],
    //     tileSize: 256,
    //     attribution: '',
    // })

    //hover layers
    // const layers0 = [
    //     {
    //         id: 'region0',
    //         source: 'infrastructure',
    //         type: 'fill',
    //         layout: {},
    //         paint: {
    //             'fill-outline-color': 'rgb(100,100,100)',
    //             'fill-color': 'rgb(230,230,230)',
    //         },
    //         'source-layer': 'region',
    //     },
    //     {
    //         id: 'region_lines0',
    //         source: 'infrastructure',
    //         type: 'line',
    //         layout: {},
    //         paint: {
    //             // 'line-color': 'rgba(211,211,211,0.76)',
    //             'line-color': 'transparent',
    //             'line-opacity': 0.6,
    //             'line-width': 4,
    //         },
    //         'source-layer': 'region',
    //     },
    // ]
    // console.log('layers0', layers0)

    const mainStyle = await fetch(mainStyleUrl)
        .then((response: any) => response.json())
        .catch(error => console.log('style ERROR', error))
    if (!mainStyle) return console.error('!mainStyle')

    style = mainStyle

    console.log('style:', mainStyle)

    // Object.assign(style, {
    //     glyphs: mainStyle.glyphs,
    //     sprite: mainStyle.sprite,
    // })
    // Object.assign(style.sources, substratStyle.sources)
    // Object.assign(style.sources, infrastructureStyle.sources)
    //
    // style.layers = [...substratStyle.layers, ...style.layers]

    //exclude some layers
    const layersToModify = [
        'thermoelectric_power_station',
        // 'gas_distribution_station',
    ]

    // // @ts-ignore
    // style.layers = style.layers.map(layer => {
    //     if (layersToModify.includes(layer.id)) {
    //         const {type} = layer
    //
    //         const paitProps = {
    //             ...layer.paint,
    //
    //             [`${type}-color`]: 'transparent',
    //         }
    //         type === 'circle' ? Object.assign(paitProps, {[`${type}-stroke-color`]: 'transparent'}) : ''
    //         const layerProps = {
    //             ...layer,
    //
    //             paint: paitProps
    //         }
    //
    //         return layerProps
    //     } else return layer
    // })
    style.layers = style.layers.filter(layer => {
        return !layersToModify.includes(layer.id)
    })

    // console.log('style layers modified:', style.layers)
    //
    // style.layers = [
    //     ...style.layers,
    //     ...style.layers.filter(defaultLayer => {
    //         const dublicatedLayer = style.layers.find(
    //             customLayer => defaultLayer.id === customLayer.id
    //         )
    //         return !(defaultLayer.id && dublicatedLayer)
    //     }),
    // ]

    // console.log('style.layers', style.layers)

    customizeLayersStyle.call(this, style)
    return style
}
