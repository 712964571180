import { agnksMainLayerIdFeature } from '@/entities/Map/AGNKS/model/addAGNKS'
import clickObjectActionDefault from '@/entities/Map/general/model/clickObjectActionDefault'
import addPulsedIconClass, {
    IconCircle,
} from '@/entities/Map/general/model/mapUtils/addPulsedIconClass'
import { AGNKSFeaturePoint } from '@/entities/ANGKS/model/AGNKSEntity.types'

export const clickEventAGNKS = (
    clickedObject: AGNKSFeaturePoint,
    appContext: any
) => {
    const agnksFeatureLayer = appContext.map.getLayer(agnksMainLayerIdFeature)
    console.log('agnksFeatureLayer', agnksFeatureLayer)
    clickObjectActionDefault.call(
        appContext,
        {
            features: [
                {
                    ...clickedObject,
                    source: agnksFeatureLayer.source,
                    sourceLayer: agnksFeatureLayer.sourceLayer,
                },
            ],
        } as any,
        agnksFeatureLayer.source,
        agnksFeatureLayer.sourceLayer,
        addPulsedIconClass.call(
            appContext,
            new IconCircle(300, 22),
            appContext.map,
            agnksFeatureLayer.source,
            '#f7f7f7',
            agnksFeatureLayer.sourceLayer
        )
    )
}
