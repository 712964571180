import addSource from '@/entities/Map/general/model/addSource'
import {
    appModuleIds,
    proposalsDistrictColorTypes,
    stepsListIndexes,
    ZOOM_HIERARCHY_MIN_LEVEL,
} from '@/shared/constants'
import setEvents from '@/entities/Map/gasification/model/addMapLayers/methods/setEvents'
import setClusterEvents from '@/entities/Map/general/model/eventMethods/setClusterEvents'
import { emptyFeatureCollection } from '@/entities/Map/general/model/mapUtils/mapUtilsConstants'

const proposalsSourceId = 'proposalsStatsSource'
const emptySource = emptyFeatureCollection()

export default async function (this: any, features, concatData?: boolean) {
    if (this.chosenAppModule?.id !== appModuleIds.gasification) return

    const proposalsSource = this.$map.getSource(proposalsSourceId)
    const proposalsId = 'proposals_district'

    if (proposalsSource) {
        if (concatData) {
            emptySource.features = [
                ...proposalsSource._data.features,
                ...features,
            ]
        } else {
            emptySource.features = features
        }

        proposalsSource.setData(emptySource)

        console.log('proposalsStats data updated')
        return
    } else {
        if (features) emptySource.features = features
        console.log('emptySource', emptySource)

        await addSource.call(
            this,
            proposalsSourceId,
            this.featureCollectionType,
            emptySource,
            this.$map,
            {
                cluster: true,
                clusterMaxZoom: 10, // Max zoom to cluster points on
                clusterRadius: 80,
                clusterProperties: {
                    sum_all_proposal_count: [
                        '+',
                        ['get', 'all_proposal_count'],
                    ],
                },
            }
        )
        const proposalsCircleId = `${proposalsId}__circle`
        this.$map.addLayer({
            id: proposalsCircleId,
            type: 'circle',
            source: proposalsSourceId,
            paint: {
                'circle-radius': {
                    type: 'exponential',
                    base: 4,
                    stops: [
                        [12, 2.7],
                        [15, 4],
                    ],
                },
                'circle-color': '#8a8a8a',
            },
            minzoom: 8,
        })
        const proposalsAllCountId = `${proposalsId}__all_proposal_count`
        this.addLayerAfter(`${proposalsId}__circle`, {
            id: proposalsAllCountId,
            type: 'symbol',
            source: proposalsSourceId,
            layout: {
                'text-field': ['get', 'all_proposal_count'],
                'text-font': ['Noto Sans Bold'],
                'text-size': {
                    type: 'exponential',
                    base: 16,
                    stops: [
                        [12, 15],
                        [15, 18],
                    ],
                },
                'text-allow-overlap': false,
                'text-ignore-placement': false,
                'text-offset': [0, -1.2],
            },
            paint: {
                'text-color': '#000000',
                'text-halo-color': '#ffffff',
                'text-halo-width': 1.5,
            },
            minzoom:
                ZOOM_HIERARCHY_MIN_LEVEL[
                    stepsListIndexes.districtInfoStepIndex
                ],
        })
        const proposalsFinishedCountId = `${proposalsId}__finished_count`
        this.addLayerAfter(`${proposalsId}__all_proposal_count`, {
            id: proposalsFinishedCountId,
            type: 'symbol',
            source: proposalsSourceId,
            layout: {
                'text-field': ['get', 'finished_count'],
                'text-font': ['Noto Sans Bold'],
                'text-size': 14,
                // 'text-variable-anchor': [
                //   "top",
                // ],
                'text-allow-overlap': true,
                'text-ignore-placement': false,
                'text-offset': [2, 0.7],
            },
            paint: {
                'text-color': proposalsDistrictColorTypes['finished_count'],
                'text-halo-color': '#ffffff',
                'text-halo-width': 1.5,
            },
            minzoom:
                ZOOM_HIERARCHY_MIN_LEVEL[stepsListIndexes.settlementsStepIndex],
        })
        const proposalsInWorkCountId = `${proposalsId}__in_work_count`
        this.addLayerAfter(`${proposalsId}__finished_count`, {
            id: proposalsInWorkCountId,
            type: 'symbol',
            source: proposalsSourceId,
            layout: {
                'text-field': ['get', 'in_work_count'],
                'text-font': ['Noto Sans Bold'],
                'text-size': 14,
                // 'text-variable-anchor': [
                //   "left",
                //   "right"
                // ],
                'text-allow-overlap': true,
                'text-ignore-placement': false,
                'text-offset': [0, 1.2],
            },
            paint: {
                'text-color': proposalsDistrictColorTypes['in_work_count'],
                'text-halo-color': '#ffffff',
                'text-halo-width': 1.5,
            },
            minzoom:
                ZOOM_HIERARCHY_MIN_LEVEL[stepsListIndexes.settlementsStepIndex],
        })
        const proposalsRejectedCountId = `${proposalsId}__rejected_count`
        this.addLayerAfter(`${proposalsId}__in_work_count`, {
            id: proposalsRejectedCountId,
            type: 'symbol',
            source: proposalsSourceId,
            layout: {
                'text-field': ['get', 'rejected_count'],
                'text-font': ['Noto Sans Bold'],
                'text-size': 14,
                // 'text-variable-anchor': [
                //   "bottom",
                // ],
                'text-allow-overlap': true,
                'text-ignore-placement': false,
                'text-offset': [-2, 0.7],
            },
            paint: {
                'text-color': proposalsDistrictColorTypes['rejected_count'],
                'text-halo-color': '#ffffff',
                'text-halo-width': 1.5,
            },
            minzoom:
                ZOOM_HIERARCHY_MIN_LEVEL[stepsListIndexes.settlementsStepIndex],
        })

        const proposalsClickArea = `${proposalsId}__click-area`
        this.addLayerAfter(proposalsRejectedCountId, {
            id: proposalsClickArea,
            type: 'circle',
            source: proposalsSourceId,
            paint: {
                'circle-radius': {
                    type: 'exponential',
                    base: 20,
                    stops: [
                        [8, 17],
                        [10, 20],
                        [14, 35],
                    ],
                },
                'circle-color': 'transparent',
            },
            minzoom: 8,
        })

        const proposalsCluster = `${proposalsId}__cluster`
        this.map.addLayer({
            id: proposalsCluster,
            type: 'circle',
            source: proposalsSourceId,
            filter: ['has', 'point_count'],
            paint: {
                // 'circle-color': 'rgba(187,113,137,0.49)',
                'circle-radius': 4,
                'circle-color': '#000000',
            },
            minzoom: 8,
        })
        const proposalsClusterCount = `${proposalsId}__cluster-count`
        this.addLayerAfter(proposalsCluster, {
            id: proposalsClusterCount,
            type: 'symbol',
            source: proposalsSourceId,
            filter: ['has', 'point_count'],
            layout: {
                'text-field': ['get', 'sum_all_proposal_count'],
                'text-font': ['Noto Sans Bold'],
                'text-size': 18,
                'text-allow-overlap': false,
                'text-ignore-placement': false,
                'text-offset': [0, -1.2],
            },
            paint: {
                'text-color': '#000000',
                'text-halo-color': '#ffffff',
                'text-halo-width': 1.5,
            },
            minzoom: 8,
        })
        const proposalsClusterArea = `${proposalsId}__cluster-area`
        this.addLayerAfter(proposalsClusterCount, {
            id: proposalsClusterArea,
            type: 'circle',
            source: proposalsSourceId,
            filter: ['has', 'point_count'],
            paint: {
                // 'circle-color': 'rgba(187,113,137,0.49)',
                'circle-radius': {
                    type: 'exponential',
                    base: 26,
                    stops: [
                        [8, 20],
                        [10, 26],
                    ],
                },
                'circle-color': 'transparent',
            },
            minzoom: 8,
        })

        const allProposalsLayers = [
            proposalsCircleId,
            proposalsAllCountId,
            proposalsFinishedCountId,
            proposalsInWorkCountId,
            proposalsRejectedCountId,
            proposalsClickArea,
            proposalsCluster,
            proposalsClusterCount,
            proposalsClusterArea,
        ]
        setEvents.call(this, [
            // proposalsCircleId,
            // proposalsAllCountId,
            // proposalsFinishedCountId,
            // proposalsInWorkCountId,
            // proposalsRejectedCountId,
            proposalsClickArea,
        ])
        setClusterEvents.call(this, [
            proposalsCluster,
            proposalsClusterCount,
            proposalsClusterArea,
        ])
        this.$watch(
            'isShowProposals',
            isShowProposals => {
                if (isShowProposals) {
                    this.setLayersVisibility(allProposalsLayers, 'visible')
                    return
                }
                this.setLayersVisibility(allProposalsLayers, 'none')
            },
            {
                immediate: true,
            }
        )
    }
}
