import {
    COLORS,
    PIPELINE_TYPE_BRANCH,
    PIPELINE_TYPE_INTER_SETTLEMENT,
    PIPELINE_TYPE_MAJOR,
} from '@/shared/constants'
const getMarginLeft = marginLeft =>
    marginLeft ? `style="margin-left: ${marginLeft}px"` : ''
const getSvgWithContent = (svgContent, size, marginLeft) => {
    return `<svg
    viewBox="0 0 100 100"
    width="${size}" 
    height="${size}"
xmlns="http://www.w3.org/2000/svg"
${getMarginLeft(marginLeft)}
>
    ${svgContent}
</svg>`
}
export default function (this: any, type, size, marginLeft?) {
    let svg = ''

    // console.log('type:', type)

    switch (type) {
        case PIPELINE_TYPE_MAJOR:
            svg = getSvgWithContent(
                `<circle cx="50" cy="50" r="50" fill="#005688" />`,
                size || 4,
                marginLeft
            )
            break
        case PIPELINE_TYPE_BRANCH:
            svg = getSvgWithContent(
                `<circle cx="50" cy="50" r="50" fill="${COLORS[4]}" />`,
                size || 4,
                marginLeft
            )
            break
        case PIPELINE_TYPE_INTER_SETTLEMENT:
            svg = getSvgWithContent(
                `<circle cx="50" cy="50" r="50" fill="${COLORS[5]}" />`,
                size || 3,
                marginLeft
            )
    }

    return svg
}
