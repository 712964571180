<template>
    <div>
        <LocationsList
            v-bind="{
                handleLocationClick: handleRegionNodeClick,
                locations: regionsTree,
                isLoading,
            }"
        />
    </div>
</template>

<script>
import TreeView from '../../widgets/MenuPanel/ui/TreeView'
import LocationsList from '../../features/general/LocationsList/ui/LocationsList.vue'

import { mapState } from 'vuex'
import loadSource from '../../entities/Map/general/model/loadSource'
import { TreeList } from '../../widgets/MenuPanel/ui/TreeView/methods/TreeList'
import handleRegionNodeClick from './methods/handleRegionNodeClick'
import handleNodeExpandToggle from './methods/handleNodeExpandToggle'
import { appModuleIds, stepsListIndexes } from '../../shared/constants'
import {
    setRegionsListGasPlans,
    setRegionsListProposals,
} from './methods/setRegionsList'

export default {
    name: 'RegionsView',
    components: {
        // TreeView,
        LocationsList,
    },
    data() {
        return {
            isLoading: false,
        }
    },
    methods: {
        loadSource,
        handleRegionNodeClick,
        handleNodeExpandToggle,
        setRegionsListProposals,
        setRegionsListGasPlans,

        async setListActions() {
            await this.$dispatch('setRegionsStats', this)
            await this.$dispatch('setRegionsPlansCount', this)
            await this.setRegionList()
        },
        async setRegionList() {
            this.isLoading = true

            const features = await this.getAllRegions()
            const regionsConverted = Object.entries(features)

            // this.$state.view.treeLists.regions = regionsConverted.map(
            //     countyData => {
            //         const [countyName, regionName] = countyData
            //         const sortedRegionNames = regionName.sort((a, b) => {
            //             if (a.name < b.name) return -1
            //             if (a.name > b.name) return 1
            //             return 0
            //         })
            //
            //         return new TreeList(
            //             countyName,
            //             sortedRegionNames.map(region => {
            //                 return new TreeList(
            //                     region.name,
            //                     null,
            //                     region.centroid,
            //                     region.code,
            //                     this.regionsProposalsStats?.[
            //                         region.code
            //                     ]?.all_proposal_count
            //                 )
            //             }),
            //             null,
            //             countyName,
            //             null
            //         )
            //     }
            // )

            const flattenRegions = regionsConverted.reduce(
                (acc, el) => acc.concat(el[1]),
                []
            )
            const sortedRegions = flattenRegions.sort((a, b) => {
                if (a.name < b.name) return -1
                if (a.name > b.name) return 1
                return 0
            })

            switch (this.chosenAppModule?.id) {
                case appModuleIds.gasification:
                    this.setRegionsListProposals(sortedRegions)
                    break
                case appModuleIds.spg:
                    this.setRegionsListProposals(sortedRegions)
                    break
                case appModuleIds.gasificationPlan:
                    this.setRegionsListGasPlans(sortedRegions)
                    break
                default:
                    this.setRegionsListProposals(sortedRegions)
                    break
            }

            this.isLoading = false

            this.$state.map.divisionsData.regions = {}
            for (let k in flattenRegions) {
                this.$state.map.divisionsData.regions[flattenRegions[k].code] =
                    flattenRegions[k]
            }

            // console.log('this.districtsTree', this.regionsTree)
        },
        async getAllRegions() {
            return await this.loadSource('/regions/fed')
        },
    },
    watch: {
        async chosenAppModule() {
            console.log(
                'this.$state.chosenAppModule',
                this.$state.chosenAppModule
            )
            await this.setListActions()
        },
        async activeYear() {
            await this.setListActions()
        },
    },
    async mounted() {
        await this.setListActions()
    },
    computed: {
        ...mapState({
            regionsTree: state => state.view.treeLists.regions,
            regionsProposalsStats: state => state.view.statsProposals.regions,
            regionsGasificationPlans: state =>
                state.view.gasificationPlan.regions,
            chosenAppModule: state => state.chosenAppModule,
            activeYear: state => state.view.gasificationPlan.activeYear,
            stepActiveId: state => state.view.stepActiveId,
        }),
    },
}
</script>

<style scoped></style>
