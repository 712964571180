<template>
    <div>
        <f7-block :class="$style.blockText" v-if="!isShowDistrictInfoBlock">
            Нажмите на район для получения информации по газовой инфраструктуре
        </f7-block>
        <f7-list
            v-if="isShowDistrictInfoBlock"
            :class="$style.accordion"
            accordion-list
            inset
        >
            <!-- title="Условные обозначения" -->
            <f7-list-item accordion-item accordion-item-closed>
                <template #title> Газовая инфраструктура </template>
                <f7-accordion-content>
                    <f7-block>
                        <div :class="$style.districtInfoBlockWrapper">
                            <!-- @change="handleChange" -->
                            <div :class="$style.content">
                                <el-collapse
                                    v-model="activeCollapseNames"
                                    :class="$style.collapse"
                                >
                                    <transition-group
                                        name="fade-x"
                                        mode="out-in"
                                    >
                                        <template
                                            v-for="(
                                                loadDataResult, key
                                            ) of loadDataResults"
                                        >
                                            <ObjectsList
                                                v-if="
                                                    // loadDataResult?.source?.features
                                                    loadDataResult?.source
                                                        ?.length
                                                "
                                                :key="key"
                                                :class="$style.objectsList"
                                                @removeHighlighter="
                                                    removeHighlighter
                                                "
                                                v-bind="{
                                                    getGdsMarkerElement,
                                                    getPipelineSvgPattern,
                                                    objectsType: key,
                                                    source: loadDataResult.source,
                                                    highlightSourceAndLayerId,
                                                }"
                                            />
                                        </template>
                                    </transition-group>
                                </el-collapse>
                            </div>
                        </div>
                    </f7-block>
                </f7-accordion-content>
            </f7-list-item>
        </f7-list>
    </div>
</template>
<script>
import {
    CONSUMERS_TYPE,
    GDS_TYPE,
    PIPELINE_BRANCH_TYPE,
    PIPELINE_MAJOR_TYPE,
    PIPELINE_SETTLEMENT_TYPE,
} from '@/shared/constants'
const types = {
    CONSUMERS_TYPE,
    GDS_TYPE,
    PIPELINE_MAJOR_TYPE,
    PIPELINE_BRANCH_TYPE,
    PIPELINE_SETTLEMENT_TYPE,
}
import { mapState } from 'vuex'

import loadGdsData from '../../../pages/GdsView/methods/loadGdsData'
import ObjectsList from './components/ObjectsList'
import districtClickHandler from './methods/districtClickHandler'
import loadDistrictObjectsData from './methods/loadDistrictObjectsData'
import loadDistrictObjectsDataOfType from './methods/loadDistrictObjectsDataOfType'
import setInitialLoadDataResult from './methods/setInitialLoadDataResult'
import getGdsMarkerElement from '../../../entities/Map/gasification/model/getGdsMarkerElement'
import getPipelineSvgPattern from '../../../entities/Map/general/model/getPipelineSvgPattern'
import districtMoveHandler from './methods/districtMoveHandler'

export default {
    components: {
        ObjectsList,
    },
    props: {},
    data() {
        return {
            activeCollapseNames: Object.keys(types),
            isShowDistrictInfoBlock: false,
            selectedDistrictName: '',
            loadDataResults: null,
            highlightSourceAndLayerId: 'highlighter',
        }
    },
    computed: {
        ...mapState({
            focusedRegionName: state => state.focusedRegionName,
            focusedRegionNumber: state => state.focusedRegionNumber,
            focusedDistrictNumber: state => state.focusedDistrictNumber,
            focusedDistrictName: state => state.focusedDistrictName,
            activeDistrictsFillLayerId: state =>
                state.map.activeDistrictsFillLayerId,
        }),
    },
    methods: {
        loadDistrictObjectsDataOfType,
        loadDistrictObjectsData,
        loadGdsData,
        districtClickHandler,
        districtMoveHandler,
        setInitialLoadDataResult,
        getGdsMarkerElement,
        getPipelineSvgPattern,

        handleChange() {
            // console.log('handleChange')
        },
        removeHighlighter() {
            const currentHighlightLayer = this.$map.getLayer(
                this.highlightSourceAndLayerId
            )
            if (!currentHighlightLayer) return
            this.$map.removeLayer(this.highlightSourceAndLayerId)
        },
        closeDistrictInfoBlock() {
            this.isShowDistrictInfoBlock = false
            this.removeHighlighter()
        },
    },
    beforeCreate() {
        this.types = types
    },
    mounted() {
        this.districtMoveHandler()
    },
    created() {
        this.$watch(
            'activeDistrictsFillLayerId',
            (activeDistrictsFillLayerId, activeDistrictsFillLayerIdPrev) => {
                if (!activeDistrictsFillLayerId) return
                this.$map?.off(
                    'click',
                    activeDistrictsFillLayerIdPrev,
                    this.districtClickHandler
                )
                this.$map?.on(
                    'click',
                    activeDistrictsFillLayerId,
                    this.districtClickHandler
                )
            },
            {
                immediate: true,
            }
        )
        this.$watch('focusedRegionNumber', () => {
            this.isShowDistrictInfoBlock = false
        })
        this.$watch('focusedDistrictNumber', () => {
            // console.log(
            //     'focusedDistrictNumber changed',
            //     this.focusedDistrictNumber
            // )
            this.districtMoveHandler()
        })
        this.$watch('isLoadingDistrictData', () => {
            console.log('isLoadingDistrictData', this.isLoadingDistrictData)
        })
        this.$state.map.highlightSourceAndLayers = [
            ...this.$state.map.highlightSourceAndLayers,
            this.highlightSourceAndLayerId,
        ]
    },
}
</script>

<style lang="stylus" module>
//transitions
.fade-move,
.fade-enter-active,
.fade-leave-active {
    transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}

/* 2. declare enter from and leave to state */
.fade-enter-from,
.fade-leave-to {
    opacity: 0;
    transform: scaleY(0.01) translate(30px, 0);
}

/* 3. ensure leaving items are taken out of layout flow so that moving
      animations can be calculated correctly. */
.fade-leave-active {
    position: absolute;
}

.blockText
    &:global(.block)
        margin-bottom 0
.accordion
    &:global(.list)
        margin-top var(--indent2)
.districtInfoBlockWrapper
    width 100%
    // max-height 720px
    display flex
    flex-direction column
    // padding 0 80px 0 0
    // padding-left 50px

.districtInfoBlock
    width 100%
    display flex
    flex-direction column
.header
    flex-shrink 0
    display flex
    align-items center
    padding var(--indent1)
.title
    width 100%
    padding-right var(--indent1)
    text-transform uppercase
    font-weight bold
.closeTrigger
    flex-shrink 0
.content
    width 100%
    display flex
    justify-content center
    // border-left 1px solid var(--el-border-color-lighter)
//     height 100%
//     overflow hidden
// .objectsList
//     margin-top var(--indent1)

.collapse
    width 100%
    &:global(.el-collapse)
        --el-collapse-header-background-color transparent
        --el-collapse-content-background-color transparent
        border none
    :global(.el-collapse-item__header)
        align-items baseline
        padding 0 var(--indent1)
</style>
