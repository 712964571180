export function loadingDescription(this: any, loadingSourceUrl, featureId?) {
    if (!loadingSourceUrl?.length) return 'загрузка'

    const headUrl = loadingSourceUrl?.split('/')[loadingSourceUrl.length - 1]

    const loadName = 'подгрузка'

    const urlHeads = {
        '/lng/russia/': loadName + ' АЗС на карту',
        '/proposal/district/': loadName + ' заявок по близлежащим районам',
        '/pipeline/gds/':
            loadName +
            ' газопроводов для ГРС ' +
            this.$state.view.gdsMenu.gdsName,
        '/lng/station/price/': loadName + ' цен по АЗС',
        '/city_area/gds/':
            loadName +
            ' информации по населенным пунктам, связанным с ГРС ' +
            this.$state.view.gdsMenu.gdsName,
        '/gds/district/':
            loadName + ' ГРС по району ' + this.$state.focusedDistrictName,
        '/pipeline/':
            loadName +
            ' газопроводов по району ' +
            this.$state.focusedDistrictName,
        '/gds/':
            loadName + ' данных для ГРС ' + this.$state.view.gdsMenu.gdsName,
    }

    for (const urlHead in urlHeads) {
        if (loadingSourceUrl.includes(urlHead)) {
            return urlHeads[urlHead]
        }
    }

    return 'загрузка'
}
