import { setPartialStateDeep } from '@/shared/lib'

import addEvents from './addEvents'

export default function (
    this: any,
    prevLayerId,
    {
        clickingLayerId,
        mainLayerOptions,
        hoveringLayerOptionsMixin,
        hoveringLayerOptions,
        hoveringDecorationLayerOptions,
        getHtmlContent,
        minLayerZoom = null,
    }
) {
    // console.log(prevLayerId, mainLayerOptions)
    const { id: mainLayerId, source, filter, minzoom } = mainLayerOptions
    const hoveringLayerId = mainLayerId + this.layerIdHoveringPostfix
    const focusingLayerId = mainLayerId + this.layerIdHoveringDecorationPostfix

    if (hoveringLayerOptionsMixin) {
        hoveringLayerOptions = JSON.parse(JSON.stringify(mainLayerOptions))
        setPartialStateDeep(hoveringLayerOptions, {
            ...hoveringLayerOptionsMixin,
        })
    }
    // const isLayerExist = this.$map.getLayer(mainLayerOptions.id)
    //
    // if (isLayerExist) return

    setPartialStateDeep(hoveringLayerOptions, {
        id: hoveringLayerId,
    })
    setPartialStateDeep(hoveringDecorationLayerOptions, {
        id: focusingLayerId,
    })
    Object.entries({
        source,
        filter,
        minzoom,
    }).forEach(entry => {
        const key = entry[0]
        const value = entry[1]
        if (!value) return
        hoveringLayerOptions[key] = value
        hoveringDecorationLayerOptions[key] = value
    })
    console.log('hoveringLayerOptions', hoveringLayerOptions)
    console.log(
        'hoveringDecorationLayerOptions',
        hoveringDecorationLayerOptions
    )

    this.addLayerAfter(prevLayerId, hoveringDecorationLayerOptions)
    this.addLayerAfter(focusingLayerId, hoveringLayerOptions)
    // this.addLayerAfter(hoveringLayerId, mainLayerOptions)

    addEvents.call(
        this,
        source,
        clickingLayerId,
        mainLayerId,
        getHtmlContent,
        minLayerZoom && minLayerZoom
    )
}
