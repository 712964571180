export default function (features: any) {
    return Object.entries(features).map(feature => {
        const [id, properties] = feature

        return {
            id: +id,
            type: 'Feature',
            properties,
            geometry: {
                coordinates: (properties as any).centroid,
                type: 'Point',
            },
        }
    })
}
