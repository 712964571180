export default function (
    this: any,
    event,
    eventHandler: (...args: any) => any,
    focusEffects: {
        layerId: string
        start: (...args: any) => void
        isDebounceEffect?: boolean
    }[]
) {
    const findFeatureInTiles = () => {
        const featureId = eventHandler(event, !event.originalEvent)

        if (featureId && this.map.areTilesLoaded()) {
            console.log(
                'findFeatureInTiles executed',
                featureId,
                this.map.areTilesLoaded()
            )

            this.map.off('render', findFeatureInTiles)

            setTimeout(() => {
                // this.map.fitTo(featureBbox)

                for (let i = 0; i < focusEffects.length; i++) {
                    focusEffects[i].start.call(
                        this,
                        focusEffects[i].layerId,
                        featureId,
                        focusEffects[i].isDebounceEffect
                    )
                }
            }, 0)
            return
        }
    }
    this.map.on('render', findFeatureInTiles)
}
