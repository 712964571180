export default async function (this: any) {
    const lakesFeatures = await this.loadSource(this.lakesUrl)
    if (!lakesFeatures) throw new Error('!lakesFeatures')
    console.log('lakesFeatures:', lakesFeatures)

    this.addSource(this.lakesUrl, this.featureCollectionType, lakesFeatures)

    const layerTypeLine = this.layerTypeLine
    const layerTypeFill = this.layerTypeFill
    const layerFillId = this.getLayerId(this.lakesUrl, layerTypeFill)
    const layerLineId = this.getLayerId(this.lakesUrl, layerTypeLine)

    this.addLayer({
        id: layerLineId,
        type: 'line',
        source: this.lakesUrl,
        paint: {
            'line-color': this.colors[7],
            'line-width': 2,
        },
        layout: {
            'line-cap': 'round',
            'line-join': 'round',
        },
    })

    this.addLayer({
        id: layerFillId,
        type: layerTypeFill,
        source: this.lakesUrl,
        layout: {},
        paint: {
            'fill-color': this.colors[8],
        },
    })
}
