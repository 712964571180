<template>
    <div
        :class="$style.divisionScale"
        :style="{
            width: divisionScaleWidthPx + 'px',
        }"
    >
        <div :class="$style.divisionScale__value">
            {{ metersInDivisionScale }}
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
    components: {
        // f7Card,
    },
    data() {
        return {
            divisionScaleWidthPx: 110,
        }
    },
    computed: {
        ...mapState({
            metersInPx: state => state.map.metersInPx,
        }),
        metersInDivisionScale() {
            const meters = Math.round(
                this.metersInPx * this.divisionScaleWidthPx
            )
            if (meters > 1000) {
                const km = (meters / 1000).toFixed(1).replace('.0', '')

                return `${km} км`
            }
            return `${meters} м`
        },
    },
}
</script>

<style lang="stylus" module>
.divisionScale
    background-color #333
    height 6px
    display flex
    justify-content center
    align-items center
    border-radius var(--borderRadius1)
    // border 1px solid var(--f7-page-bg-color)
    border 1px solid #fff

.logosWrapper
    display flex

.divisionScale__value
    position absolute
    font-weight bold
    // background-color var(--f7-page-bg-color)
    background-color #fff
    padding 0 5px
    border-radius var(--borderRadius1)
    color #333
</style>
