import {
    stepsListIndexes,
    TILE_DISTRICT_SOURCE_LAYER,
    ZOOM_HIERARCHY_MIN_LEVEL,
    zoomLevelToLayerAvailability,
} from '@/shared/constants'
import {
    fillFocusEffect,
    lineFocusEffect,
} from '@/entities/Map/general/model/addDistrictsOfRegion/methods/focusLayerEffects'
import focusTileFeature from '@/entities/Map/general/model/mapUtils/focusTileFeature'
import { waitUntilTilesAreLoaded } from '../mapUtils/waitUntilTilesAreLoaded'
import { layerFocusEffectColors } from '@/entities/Map/general/model/config/map.constants'
// let moveEvent: any = null

export default async function (this: any, regionId) {
    console.log('addDistrictsOfRegion')
    const layerFillTypeId = TILE_DISTRICT_SOURCE_LAYER
    this.$state.map.activeDistrictsFillLayerId = layerFillTypeId
    const framesPerSecond = 30
    const initialOpacity = 0.3
    let opacity = initialOpacity
    const initialRadius = 0.5
    let radius = initialRadius
    const maxRadius = 20
    let timeOutMarker, animationFrameId

    const districtFillEffectLayerId = 'district_fill-effect'
    const districtOutlineEffectLayerId = 'district_outline-effect'

    console.log('addDistrictsOfRegion')

    const animateMarker = (focusedRegionNumber, timestamp) => {
        timeOutMarker = setTimeout(() => {
            animationFrameId = requestAnimationFrame(
                <FrameRequestCallback>animateMarker
            )

            radius += (maxRadius - radius) / framesPerSecond
            opacity -= 0.9 / framesPerSecond

            if (opacity <= 0) {
                return
            }

            this.$map.setPaintProperty(
                districtFillEffectLayerId,
                'line-opacity',
                ['case', ['==', ['id'], focusedRegionNumber], opacity, 0.3]
            )
        }, 1000 / framesPerSecond)
    }

    return () => {
        this.map.addLayer({
            id: districtFillEffectLayerId,
            source: 'admin',
            'source-layer': TILE_DISTRICT_SOURCE_LAYER,
            type: this.layerTypeFill,
            layout: {},
            paint: {
                'fill-color': layerFocusEffectColors.fill,
                'fill-opacity': 0,
            },
        })
        this.addLayerAfter('boundary-land-level-6', {
            id: districtOutlineEffectLayerId,
            source: 'admin',
            'source-layer': TILE_DISTRICT_SOURCE_LAYER,
            type: this.layerTypeLine,
            layout: {
                'line-cap': 'round',
                'line-join': 'round',
            },
            paint: {
                'line-color': layerFocusEffectColors.line,
                'line-opacity': 0,
                'line-width': 4,
            },
            minzoom: zoomLevelToLayerAvailability.district,
            // maxzoom:
            //     ZOOM_HIERARCHY_MIN_LEVEL[stepsListIndexes.settlementsStepIndex],
        })

        const mouseenterCallback = (hoveredFeatureId, feature) => {
            const districtProperties = feature?.properties
            if (!districtProperties) return

            this.$commit('setPartialState', {
                hoveredDistrictProperties: districtProperties,
            })
            // console.log(
            //     'mouseenterCallback districtProperties',
            //     this.$state.hoveredDistrictProperties
            // )
        }
        const mouseleaveCallback = (hoveredFeatureId, feature) => {
            this.$commit('setPartialState', {
                hoveredDistrictProperties: '',
            })
        }
        const moveEvent = (event?, isAutomatic?) => {
            if (!this.map.getLayer(layerFillTypeId)) return
            const features = this.map.queryRenderedFeatures(
                this.map.project(this.map.getCenter()),
                {
                    layers: [layerFillTypeId],
                }
            )
            const districtId = features?.[0]?.properties.id

            if (!districtId) return

            this.focusedDistrictId = districtId
            this.$commit('setPartialState', {
                focusedDistrictNumber: districtId,
            })
            this.$commit('setPartialState', {
                focusedDistrictName: features?.[0]?.properties?.name,
            })

            return districtId
        }
        //TODO: вынести отдельно в eventHandlers, подцепить к focusTileFeature фабрике
        const moveProgrammedEvent = () => {
            const features = this.map.queryRenderedFeatures({
                layers: [layerFillTypeId],
            })
            if (!features.length) {
                return
            }

            const feature = features.find(
                feature =>
                    feature.properties.id ==
                    this.$state.view.districtsView.chosenDistrict.id
            )
            // console.log('featureRegion', feature)
            //TODO: MARK of new tiles loading
            // const featureId = feature.id
            if (!feature) return 'e'

            const districtId = feature.properties.id

            if (!districtId) return

            this.focusedDistrictId = districtId
            this.$commit('setPartialState', {
                focusedDistrictNumber: districtId,
            })
            this.$commit('setPartialState', {
                focusedDistrictName: feature.properties.name,
            })

            return districtId
        }

        const mountedDistrictId = moveEvent()
        if (mountedDistrictId) {
            fillFocusEffect.call(
                this,
                districtFillEffectLayerId,
                mountedDistrictId,
                true
            )
            lineFocusEffect.call(
                this,
                districtOutlineEffectLayerId,
                mountedDistrictId,
                true
            )
        }

        this.map.on('move', event => {
            if (this.$state.map.zoom < zoomLevelToLayerAvailability.district)
                return

            if (!event.originalEvent) return

            const districtId = moveEvent(event)

            if (!districtId) return

            // lineFocusEffect.call(this, districtOutlineEffectLayerId, districtId)
        })
        this.map.on('moveend', event => {
            console.log('moveend')

            waitUntilTilesAreLoaded.call(this, () => {
                moveEvent(event)
            })

            if (
                this.$state.view.stepActiveId !==
                stepsListIndexes.districtInfoStepIndex
            )
                return

            if (!event.originalEvent) {
                this.$commit('setPartialState', {
                    focusedCityAreaName: '',
                })

                focusTileFeature.call(this, event, moveProgrammedEvent, [
                    {
                        layerId: districtFillEffectLayerId,
                        start: fillFocusEffect,
                        isDebounceEffect: true,
                    },
                    {
                        layerId: districtOutlineEffectLayerId,
                        start: lineFocusEffect,
                        isDebounceEffect: true,
                    },
                ])
            }
        })
        // this.map.on('zoomend', event => {
        //     if (
        //         this.$state.map.zoom < zoomLevelToLayerAvailability.district ||
        //         this.$state.map.zoom >=
        //             ZOOM_HIERARCHY_MIN_LEVEL[
        //                 stepsListIndexes.settlementsStepIndex
        //             ]
        //     )
        //         return
        //
        //     if (!event.originalEvent) {
        //         setTimeout(() => {
        //             moveEvent(event, !event.originalEvent)
        //         }, 500)
        //     }
        // })
        const removeHandlers = this.setMouseenterMouseleave(
            layerFillTypeId,
            mouseenterCallback,
            mouseleaveCallback
        )
    }
}
