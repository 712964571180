import focusTileFeature from '@/entities/Map/general/model/mapUtils/focusTileFeature'
import {
    fillFocusEffect,
    lineFocusEffect,
} from '@/entities/Map/general/model/addDistrictsOfRegion/methods/focusLayerEffects'
import {
    appModuleIds,
    stepsListIndexes,
    TILE_DISTRICT_SOURCE_LAYER,
    ZOOM_HIERARCHY_MIN_LEVEL,
    zoomLevelToLayerAvailability,
} from '@/shared/constants'
import {
    FOCUS_TILE_FEATURE_STEP_LIST_INDEXES_BY_APP_MODULES,
    layerFocusEffectColors,
} from '@/entities/Map/general/model/config/map.constants'

export default function (this: any, layerFillId) {
    console.log('layerFillId', layerFillId)
    const regionFillEffectLayerId = 'region_fill-effect'
    const regionOutlineEffectLayerId = 'region_outline-effect'

    this.map.addLayer({
        id: regionFillEffectLayerId,
        source: 'admin',
        'source-layer': layerFillId,
        type: this.layerTypeFill,
        layout: {},
        paint: {
            'fill-color': layerFocusEffectColors.fill,
            // 'fill-color': 'rgba(255,107,155,0.62)',
            'fill-opacity': 0,
            'fill-antialias': true,
            'fill-opacity-transition': {
                duration: 300,
                delay: 0,
            },
        },
        // maxzoom:
        //     ZOOM_HIERARCHY_MIN_LEVEL[stepsListIndexes.districtInfoStepIndex],
    })
    this.addLayerAfter('boundary-land-level-2-dash', {
        id: regionOutlineEffectLayerId,
        source: 'admin',
        'source-layer': layerFillId,
        type: this.layerTypeLine,
        layout: {
            'line-cap': 'round',
            'line-join': 'round',
        },
        paint: {
            'line-color': layerFocusEffectColors.line,
            // 'line-color': 'rgba(255,17,100,0.8)',
            'line-opacity': 0,
            'line-width': {
                type: 'exponential',
                base: 3.5,
                stops: [
                    [0, 2],
                    [6, 4],
                ],
            },
            'line-opacity-transition': {
                duration: 300,
                delay: 0,
            },
        },
        // maxzoom:
        //     ZOOM_HIERARCHY_MIN_LEVEL[stepsListIndexes.districtInfoStepIndex],
    })

    const sourceAndSourceLayer = {
        source: this.infrastructureTileSourceName,
        sourceLayer: this.tileRegionSourceLayer,
    }
    const toggleFeatureStyle = (featureId, isHover) => {
        this.map.setFeatureState(
            {
                ...sourceAndSourceLayer,
                id: featureId,
            },
            { focus: isHover }
        )
    }
    const checkFocusedFeature = (event?) => {
        // console.log('checkFocusedFeature')
        const features = this.map.queryRenderedFeatures(
            this.map.project(this.map.getCenter()),
            {
                layers: [layerFillId],
            }
        )
        if (!features.length) {
            if (this.$state.focusedRegionNumber) {
                toggleFeatureStyle(this.$state.focusedRegionNumber, false)
            }
            return
        }
        const feature = features[0]
        //TODO: MARK of new tiles loading
        // const featureId = feature.id
        const featureId = feature.properties.id
        const featureProperties = feature.properties
        // console.log('featureRegion', feature)
        const featureNumber = featureProperties.number
        if (featureId === this.$state.focusedRegionNumber) return featureId

        if (featureNumber) {
            toggleFeatureStyle(this.$state.focusedRegionNumber, false)
        }

        this.$commit('setPartialState', {
            focusedRegionName: featureProperties.name,
        })

        this.$state.focusedRegionNumber = +featureId
        toggleFeatureStyle(this.$state.focusedRegionNumber, true)

        return featureId
    }
    const checkFocusedFeatureProgrammedEvent = (event?) => {
        const features = this.map.queryRenderedFeatures({
            layers: [layerFillId],
        })
        if (!features.length) {
            return
        }
        // console.log(
        //     'features Region',
        //     features,
        //     this.$state.view.regionsView.chosenRegion.id
        // )
        const feature = features.find(
            feature =>
                +feature.properties.id ===
                +this.$state.view.regionsView.chosenRegion.id
        )
        // console.log('featureRegion', feature)
        //TODO: MARK of new tiles loading
        // const featureId = feature.id
        if (!feature) return 'e'

        const featureId = feature.properties.id
        const featureProperties = feature.properties
        const featureNumber = featureProperties.number

        // if (featureNumber) {
        //     toggleFeatureStyle(this.$state.focusedRegionNumber, false)
        // }

        this.$commit('setPartialState', {
            focusedRegionName: featureProperties.name,
        })

        this.$state.focusedRegionNumber = +featureId
        // toggleFeatureStyle(this.$state.focusedRegionNumber, true)

        return featureId
    }

    this.$state.view.isStepsListLocked = false
    const mountedRegionId = checkFocusedFeature()
    if (mountedRegionId) {
        lineFocusEffect.call(
            this,
            regionOutlineEffectLayerId,
            mountedRegionId,
            true
        )
        fillFocusEffect.call(
            this,
            regionFillEffectLayerId,
            mountedRegionId,
            true
        )
    }
    this.map.on('move', event => {
        if (!event.originalEvent) return

        const regionId = checkFocusedFeature(event)

        if (!regionId) return

        // lineFocusEffect.call(this, regionOutlineEffectLayerId, regionId)
    })
    this.map.on('moveend', event => {
        const stepIndexesOfModule =
            FOCUS_TILE_FEATURE_STEP_LIST_INDEXES_BY_APP_MODULES[
                this.$state.chosenAppModule.id
            ]
        console.log('zoomLevelStep', this.$state.view.stepActiveId)

        if (
            this.$state.view.stepActiveId !==
                stepsListIndexes.districtsStepIndex &&
            this.$state.view.stepActiveId !== stepsListIndexes.regionsStepIndex
        )
            return

        if (!event.originalEvent) {
            focusTileFeature.call(
                this,
                event,
                checkFocusedFeatureProgrammedEvent,
                [
                    {
                        layerId: regionFillEffectLayerId,
                        start: fillFocusEffect,
                        isDebounceEffect: true,
                    },
                    {
                        layerId: regionOutlineEffectLayerId,
                        start: lineFocusEffect,
                        isDebounceEffect: true,
                    },
                ]
            )
        }
    })
    this.map.on('zoom', event => {
        if (!event.originalEvent) {
            setTimeout(() => {
                checkFocusedFeatureProgrammedEvent()
            }, 500)
        }
    })
}
