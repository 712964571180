import {
    COLORS,
    IMG_GDS_STATUS_CLOSED,
    IMG_GDS_STATUS_LIMITED,
    IMG_GDS_STATUS_OPERATING,
    IMG_GDS_STATUS_PROJECTED,
} from '@/shared/constants'
import { htmlToElement } from '@/shared/lib'
export default function (this: any, status, size = 25) {
    const getMarkerFillStroke = status => {
        let fill = ''
        let stroke = ''

        switch (status) {
            case IMG_GDS_STATUS_CLOSED:
                fill = COLORS[10]
                stroke = COLORS[4]
                break
            case IMG_GDS_STATUS_LIMITED:
                fill = COLORS[4]
                stroke = COLORS[10]
                break
            case IMG_GDS_STATUS_OPERATING:
                fill = COLORS[4]
                stroke = COLORS[9]
                break
            case IMG_GDS_STATUS_PROJECTED:
                fill = COLORS[9]
                stroke = COLORS[4]
                break
        }

        return {
            fill,
            stroke,
        }
    }
    const { fill, stroke } = getMarkerFillStroke(status)
    const el = htmlToElement(`
                    <svg viewBox="-5 -5 110 110" fill="${fill}" width="${size}" height="${size}" stroke="${stroke}" xmlns="http://www.w3.org/2000/svg">
            	        <polygon points="50 15, 100 100, 0 100" style="stroke-width: 2px;vector-effect: non-scaling-stroke"/>
                    </svg>
                `)

    return el
}
