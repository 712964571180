export default async function (this: any, exceptionLayerIds: any = []) {
    this.$commit('setPartialState', {
        activeRegionDensityPalette: [],
    })
    Object.keys(this.activeRegionLayers).forEach(activeRegionLayerKey => {
        if (exceptionLayerIds.includes(activeRegionLayerKey)) return
        const layer = this.map.getLayer(activeRegionLayerKey)
        if (!layer) return

        this.map.removeLayer(activeRegionLayerKey)
    })
    // this.activeRegionLayers = {}
    // console.log('active region source source:', this.activeRegionSources)
    // Object.keys(this.activeRegionSources).forEach(activeRegionSourceKey => {
    //     const source = this.map.getSource(activeRegionSourceKey)
    //     if (!source) return

    //     this.map.removeSource(activeRegionSourceKey)
    // })
    // this.activeRegionSources = {}
}
