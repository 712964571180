<template>
    <div :class="[$style.wrapper]">
        <f7-list inset :style="{ margin: 0 }">
            <f7-list-item header="Выберите модуль">
                <f7-menu>
                    <f7-menu-item
                        :text="chosenAppModule?.name || 'Выберите модуль'"
                        dropdown
                        :style="{ width: '120px' }"
                    >
                        <f7-menu-dropdown
                            :class="$style.dropdownContent"
                            bg-color="#fff"
                            right
                        >
                            <f7-menu-dropdown-item
                                v-for="module of allowedAppModules"
                                :key="module.id"
                                :disabled="!module.allowAccess"
                                @click="setChosenAppModule(module)"
                            >
                                {{ module.name }}
                            </f7-menu-dropdown-item>
                        </f7-menu-dropdown>
                    </f7-menu-item>
                </f7-menu>
            </f7-list-item>
        </f7-list>
    </div>
</template>
<script>
import { mapGetters, mapMutations, mapState } from 'vuex'

export default {
    data() {
        return {
            isShowMenu: true,
            isPressed: false,
            isHoverButton: false,
            isMenuOpen: false,
        }
    },
    components: {},
    watch: {},
    computed: {
        ...mapState({
            appModuleAccess: state => state.appModuleAccess,
            chosenAppModule: state => state.chosenAppModule,
        }),
        ...mapGetters(['allowedAppModules']),
    },
    methods: {
        ...mapMutations(['setChosenAppModule']),
    },
}
</script>

<style lang="stylus" module>
.dropdownContent
    .menu-dropdown-content
        background #ffffff
.wrapper
    display: flex;
    align-items: center;
    position absolute
    z-index 999
    right 70px
.showRightMenu__content
    display flex
    flex-direction column
    background rgba(255, 255, 255, 0.68)
    backdrop-filter blur(11px)
    border-radius var(--f7-list-inset-border-radius);
    visibility visible
.showRightMenu__toggleButton
    display flex
    background rgba(255, 255, 255, 0.68)
    //width: 12px
    //height: 30px;
    backdrop-filter: blur(11px);
    border-radius: 20px 0 0 20px
    cursor pointer
    box-shadow: var(--boxShadow1);
    transition transform var(--transitionDuration1)
    user-select none
    visibility visible
    padding: 5px 0;
.showRightMenu__toggleButton_hover

.showRightMenu__toggleButtonTitle
    display flex
    align-items center
    justify-content center
    padding 0 10px 0 0
.showRightMenu__toggleButton_pressed
    background: rgba(234, 234, 234, 0.64);
.showRightMenu__wrapper_hided
    transform translateX(91.5%)
</style>
