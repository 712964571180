export default function (this: any) {
    this.loadDataResults = [
        this.types.CONSUMERS_TYPE,
        this.types.GDS_TYPE,
        this.types.PIPELINE_MAJOR_TYPE,
        this.types.PIPELINE_BRANCH_TYPE,
        this.types.PIPELINE_SETTLEMENT_TYPE,
    ].reduce((accumulator, type) => {
        accumulator[type] = {
            source: null,
            error: '',
        }
        return accumulator
    }, {})
}
