import { stepsListIndexes, ZOOM_HIERARCHY_MIN_LEVEL } from '@/shared/constants'

export default function (this: any) {
    console.log(
        'districtsStepAction',
        !this.$state.map.isAutomaticZoomChangeLocked
    )

    if (!this.$state.map.isAutomaticZoomChangeLocked) {
        if (this.$state.view.regionsView.chosenRegion.bbox) {
            this.$map.fitBounds(
                this.$state.view.regionsView.chosenRegion.bbox,
                {
                    speed: 20,
                    // zoom: 8,
                    // padding: {
                    //     right: 700,
                    // },
                    duration: 0,
                }
            )
        } else {
            this.$map.jumpTo({
                zoom: ZOOM_HIERARCHY_MIN_LEVEL[
                    stepsListIndexes.districtsStepIndex
                ],
                center:
                    this.$state.view.regionsView.chosenRegion.geometry ||
                    this.$state.map.divisionsData.regions[
                        this.$state.focusedRegionNumber
                    ]?.centroid,
            })
        }
    }
    this.$commit('setIsAutomaticZoomChangeLocked', {
        isLocked: false,
    })
}
