import { stepsListIndexes, ZOOM_HIERARCHY_MIN_LEVEL } from '@/shared/constants'
import gasificationMapStyle from '@/entities/Map/gasification/model/getMapStyle/gasificationMapStyle'
import setAsyncLayersGas from '@/entities/Map/gasification/model/setAsyncLayersGas'

export default async function (this: any) {
    await this.setMapStyle(gasificationMapStyle)
    await this.addRegions()
    console.log('setAsyncLayers start')
    await setAsyncLayersGas.call(this, this.mapStyle.layers)
    console.log('setAsyncLayers complete')
    await this.setActiveRegion(this.focusedRegionNumber)

    this.$VUE_APP_BUILD_WITH_DEVELOPMENT_PARTS &&
        this.setChangedFeaturesInViewport(
            'district',
            ZOOM_HIERARCHY_MIN_LEVEL[stepsListIndexes.districtsStepIndex]
        )
}
