<template>
    <div :class="$style.mainView">
        <transition :name="$style.fade">
            <f7-link
                v-if="stepActiveId > stepsList[0].id"
                @click="menuGoBack"
                icon-f7="icon-arrow_turn_up_left"
                :class="$style.backLink__container"
            >
                Назад
            </f7-link>
        </transition>
        <el-scrollbar
            ref="scrollbarEl"
            :style="{ marginBottom: $refs.menuFooter?.clientHeight + 'px' }"
        >
            <div :class="$style.mainViewWrapper" ref="mainViewWrapperEl">
                <router-view v-slot="{ Component }">
                    <transition name="shrink" mode="out-in" appear>
                        <keep-alive>
                            <component :is="Component" />
                        </keep-alive>
                    </transition>
                </router-view>
            </div>
        </el-scrollbar>
    </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'

import menuGoBack from './methods/menuGoBack'
import { stepsListIndexes } from '../../../../shared/constants'
import { stepsListDirections } from '../../../../app/providers/store/modules/view/types'
import { router } from '../../../../app/providers'
import { stepsList } from '@/widgets/MenuPanel/ui/TimeLine/config/stepsLists.config'

export default {
    inject: ['appContext'],
    data() {
        return {
            activeComponent: null,
        }
    },
    methods: {
        menuGoBack,

        // createDynamicView() {
        //     this.activeComponent = markRaw(
        //         defineAsyncComponent(this.stepActiveComponent)
        //     )
        // },
        routerMenuNavigate(routeName = this.stepActiveId) {
            // console.log(
            //     'this.stepActiveId',
            //     this.stepActiveId,
            //     typeof this.stepActiveId
            // )
            router.push({ name: routeName.toString(), replace: true })
        },
    },
    watch: {
        stepActiveId(newRoute) {
            this.routerMenuNavigate(newRoute)
        },
        stepsListDirection() {
            console.log('stepsListDirection', this.stepsListDirection)
        },
    },
    computed: {
        ...mapState({
            stepActiveId: state => state.view.stepActiveId,
            stepsListDirection: state => state.view.stepsListDirection,
        }),
        ...mapGetters(['stepTitle', 'stepActiveComponent']),
        stepsList() {
            return stepsList
        },
        stepsListIndexes() {
            return stepsListIndexes
        },
        stepsListDirections() {
            return stepsListDirections
        },
    },
    mounted() {
        this.routerMenuNavigate()
    },
}
</script>

<style lang="stylus" module>
//transitions
.fade
    &:global(-enter-active)
        transition: .1s ease-in;
        opacity: 0;
.fade
    &:global(-leave-active)
        transition: .1s ease-out;
        opacity: 0;

.slide_positive,
    &:global(-enter-active, -leave-active)
        transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
.slide_negative,
    &:global(-enter-active, -leave-active)
        transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);

.slide_negative
    &:global(-enter)
        transform: translateX(100%);
    &:global(-leave-to)
        transform: translateX(-100%);

.slide_positive
    &:global(-enter)
        transform: translateX(-100%);
    &:global(-leave-to)
        transform: translateX(100%)

p {
    text-indent 20px
}

.page
    > :global(.page-content)
        height auto
        min-height: 100%;
        padding-top var(--f7-page-navbar-offset)
        display: flex;
        flex-direction: column;

.mainContainer
    position: relative
    height: 100%

.menuFooterWrapper
    background-color: var(--f7-page-bg-color);
    z-index: 999
.menuFooterContainer
    border-top: solid #d5d5d5 1px;
    box-shadow: 0px -100px 76px -58px #ffffffb8;
    height: 80px;
.mainView
    flex: 1;
    overflow: hidden;
.mainViewWrapper
    padding: 0 0 40px 0;
.scrollbarContent
    display flex
    flex-direction column
    min-height 100%
    justify-content space-between
    :global(.el-scrollbar__view)
        min-height 100%
        display flex
        flex-direction column
        z-index: 9999;
        //position: absolute;
        justify-content: flex-end;
.backLink__container
    margin-left: calc(var(--f7-list-inset-side-margin) + var(--f7-safe-area-outer-left));
    margin-right: calc(var(--f7-list-inset-side-margin) + var(--f7-safe-area-outer-left));
    margin-bottom: 0;
    margin-top: 0;
</style>
