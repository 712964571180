import { LAYER_MIN_ZOOM1, MAP_CENTER_COORDS } from '@/shared/constants'

export default function (this: any) {
    console.log(
        'regionsStepAction',
        !this.$state.map.isAutomaticZoomChangeLocked
    )
    !this.$state.map.isAutomaticZoomChangeLocked &&
        this.$map.jumpTo({
            zoom: LAYER_MIN_ZOOM1,
            center: MAP_CENTER_COORDS,
        })
    this.$commit('setIsAutomaticZoomChangeLocked', {
        isLocked: false,
    })
}
