import {
    stepsListIndexes,
    TILE_DISTRICT_SOURCE_LAYER,
    ZOOM_HIERARCHY_MIN_LEVEL,
    zoomLevelToLayerAvailability,
} from '@/shared/constants'
import focusTileFeature from '@/entities/Map/general/model/mapUtils/focusTileFeature'
import {
    fillFocusEffect,
    lineFocusEffect,
} from '@/entities/Map/general/model/addDistrictsOfRegion/methods/focusLayerEffects'
import {
    stepListIndexesToChangeByZoom,
    stepListIndexesToChangeByZoomMapByAppModules,
} from '@/widgets/MenuPanel/ui/TimeLine/methods/stepsActions/config/stepListIndexesToChangeByZoom.config'
import { FOCUS_TILE_FEATURE_STEP_LIST_INDEXES_BY_APP_MODULES } from '@/entities/Map/general/model/config/map.constants'
export const cityAreafillLayers = [
    'city_area_other',
    'city_area_region_capitals',
    'city_big',
]
export const cityAreaFillEffectLayerId = 'cityArea_fill-effect'
export const cityAreaOutlineEffectLayerId = 'cityArea_outline-effect'

export default async function (this: any) {
    const cityAreaLabelLayerId = 'city_area_label'
    const cityAreaLayerId = 'city_area'
    const cityAreaSourceLayerId = 'city_area'
    const cityPointSourceId = 'city_point'
    const sourceLayer = layerId =>
        this.getLayerById(this.map.getStyle().layers, layerId)

    return () => {
        this.map.addLayer({
            id: cityAreaFillEffectLayerId,
            source: this.infrastructureTileSourceName,
            'source-layer': cityAreaSourceLayerId,
            type: this.layerTypeFill,
            layout: {},
            paint: {
                'fill-color': '#ffffff',
                'fill-opacity': 0,
            },
        })
        this.map.addLayer({
            id: cityAreaOutlineEffectLayerId,
            source: this.infrastructureTileSourceName,
            'source-layer': cityAreaSourceLayerId,
            type: this.layerTypeLine,
            layout: {
                'line-cap': 'round',
                'line-join': 'round',
            },
            paint: {
                'line-color': '#7b6aee',
                'line-opacity': 0,
                'line-width': 3,
            },
        })

        const moveEvent = (event, isAutomatic?) => {
            // console.log('moveEvent executed')
            const isLayerExist = cityAreafillLayers.map(layerId =>
                this.map.getLayer(layerId)
            )
            if (!isLayerExist.length) return
            let foundFeature
            for (let i = 0; i < cityAreafillLayers.length; i++) {
                const features = this.map.queryRenderedFeatures({
                    layers: [cityAreafillLayers[i]],
                })
                if (features.length) {
                    foundFeature = features.find(
                        feature =>
                            feature.properties.id ===
                            this.$state.focusedCityAreaNumber
                    )
                    if (foundFeature) break
                }
            }
            if (!foundFeature) return

            const cityAreaFeature = foundFeature
            if (isAutomatic)
                console.log(
                    event?.type,
                    event,
                    'features CityArea: ',
                    foundFeature
                )
            // const districtId = features?.[0]?.id

            const cityAreaId = cityAreaFeature?.properties.id

            // if (!districtId) return
            // console.log('moveend event District', features?.[0]?.properties?.name)
            if (!cityAreaId) return

            // this.$commit('setPartialState', {
            //     focusedCityAreaNumber: cityAreaId,
            // })
            // this.$commit('setPartialState', {
            //     focusedCityAreaName: features?.[0]?.[0]?.properties?.name,
            // })

            return cityAreaId
        }

        // this.map.on('move', event => {
        //     if (!event.originalEvent) return
        //
        //     this.map.setPaintProperty(cityAreaOutlineEffectLayerId, 'line-opacity', 0)
        // })
        this.map.on('moveend', event => {
            const stepIndexesOfModule =
                FOCUS_TILE_FEATURE_STEP_LIST_INDEXES_BY_APP_MODULES[
                    this.$state.chosenAppModule.id
                ]
            const zoomLevelStep =
                ZOOM_HIERARCHY_MIN_LEVEL[
                    stepIndexesOfModule.find(
                        stepIndex =>
                            stepIndex === stepsListIndexes.settlementsStepIndex
                    )
                ]

            if (!zoomLevelStep) return

            if (this.$state.map.zoom !== zoomLevelStep) return

            if (!event.originalEvent) {
                focusTileFeature.call(this, event, moveEvent, [
                    {
                        layerId: cityAreaFillEffectLayerId,
                        start: fillFocusEffect,
                        isDebounceEffect: true,
                    },
                    {
                        layerId: cityAreaOutlineEffectLayerId,
                        start: lineFocusEffect,
                    },
                ])
            }
        })

        this.$watch('focusedDistrictNumber', () => {
            this.map.setPaintProperty(
                cityAreaOutlineEffectLayerId,
                'line-opacity',
                0
            )
        })
    }
}
