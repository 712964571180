
// @ts-nocheck

import { ElConfigProvider } from 'element-plus/lib'
import ru from 'element-plus/lib/locale/lang/ru'
import { f7ready } from 'framework7-vue'
import { f7 } from 'framework7-vue'
import { defineComponent } from 'vue'
import { mapGetters, mapMutations, mapState } from 'vuex'

import setPopup from '@/entities/Map/general/ui/PopupMap/methods/setPopup'
import addAzs from '@/entities/Map/gasification/model/addAzs'
import flyToObj from '@/entities/Map/general/model/devMethods/flyToObj'
import getAzsMarkerElement from '@/entities/Map/gasification/model/getAzsMarkerElement'
import getGzsMarkerElement from '@/entities/Map/gasification/model/getGzsMarkerElement'
import geoJsonStyleLoad from '@/entities/Map/general/model/getMapStyle/geoJsonStyleLoad'
import getMazkMarkerElement from '@/entities/Map/gasification/model/getMazkMarkerElement'
import { awaitComponentPositiveValue } from '@/shared/lib/awaitComponentPositiveValue'
import ProposalStatsInfoMarker from '@/entities/Map/general/ui/ProposalStatsInfoMarker'

import RegionsView from '@/pages/RegionsView'

import loadGdsData from '@/pages/GdsView/methods/loadGdsData'

import MapEl from '@/entities/Map/general/ui/MapEl.vue'
import DevView from '../widgets/DevView'
import DistrictInfoBlock from '../features/gasification/DistrictInfoBlock'
import DivisionScale from '../features/general/DivisionScale'
import IndexedDbController from './providers/indexedDb/IndexedDbController'
import Loader from '../features/general/Loader'
import MapLegends from '../features/gasification/MapLegends'
import MenuPanel from '../widgets/MenuPanel'
import PopulationDensity from '../features/general/PopulationDensity'
import PopupMap from '../entities/Map/general/ui/PopupMap'
import SelectModuleView from '../features/general/SelectModuleView/SelectModuleView'
import ChooseModuleMenu from '../features/general/ChooseModuleMenu/ChooseModuleMenu'

import created from '../processes/created'
import addBuildings from '../entities/Map/general/model/addBuildings'
import addCity from '../entities/Map/general/model/addCity'
import addConsumers from '../entities/Map/general/model/addConsumers'
import addDistrictsOfRegion from '../entities/Map/general/model/addDistrictsOfRegion'
import addProposals from '../entities/Map/gasification/model/addProposals'
import addGds from '../entities/Map/gasification/model/addGds'
import addLakes from '../entities/Map/general/model/addLakes'
import addLayer from '../entities/Map/general/model/addLayer'
import addRegions from '../entities/Map/general/model/addRegions'
import addCityArea from '../entities/Map/general/model/addCityArea'
import addRivers from '../entities/Map/general/model/addRivers'
import addSource from '../entities/Map/general/model/addSource'
import changeFocusedRegionHandler from '../entities/Map/general/model/changeFocusedRegionHandler'
import clearActiveRegion from '../entities/Map/general/model/clearActiveRegion'
import colorizeDistrictsByDensity from '../entities/Map/general/model/colorizeDistrictsByDensity'
import getCityMarkerElement from '../entities/Map/general/model/getCityMarkerElement'
import getGdsMarkerElement from '../entities/Map/gasification/model/getGdsMarkerElement'
import getLayerId from '../entities/Map/general/model/getLayerId'
import getMapStyle from '../entities/Map/general/model/getMapStyle'
import tileServerRasterStyle from '../entities/Map/general/model/getMapStyle/tileServerRasterStyle'
import tileServerSergStyle from '../entities/Map/general/model/getMapStyle/tileServerSergStyle'
import getPipelineSvgPattern from '../entities/Map/general/model/getPipelineSvgPattern'
import initKeycloak from '../features/general/Auth/model/initKeycloak-'
import initMap from '../entities/Map/general/model/initMap'
import initMiniMap from '../entities/Map/general/model/initMiniMap'
import loadSource from '../entities/Map/general/model/loadSource'
import loadSvgToMapImages from '../entities/Map/general/model/loadSvgToMapImages'
import setActiveRegion from '../entities/Map/general/model/setActiveRegion'
import setAsyncLayers from '../entities/Map/general/model/setAsyncLayers'
import setLayersVisibility from '../entities/Map/general/model/setLayersVisibility'
import setMetersInPx from '../entities/Map/general/model/setMetersInPx'
import setMouseenterMouseleave from '../entities/Map/general/model/setMouseenterMouseleave'
import sourceLoadedHandler from '../entities/Map/general/model/sourceLoadedHandler'
import Pipelines from '../entities/Map/general/model/Pipelines'
import setMapStyle from '@/entities/Map/general/model/getMapStyle/setMapStyle'
import {
    appModuleIds,
    stepsListIndexes,
    ZOOM_HIERARCHY_MIN_LEVEL,
} from '@/shared/constants'
import addLayerAfter from '@/entities/Map/general/model/addLayerAfter'
import getLayerById from '@/entities/Map/general/model/getLayerById'
import getHospitalMarkerElement from '@/entities/Map/general/model/getHospitalMarkerElement'
import getMedicalMarkerElement from '@/entities/Map/general/model/getMedicalMarkerElement'
import addSocialObjects from '@/entities/Map/general/model/addSocialObjects'
import removePopup from '@/entities/Map/general/ui/PopupMap/methods/removePopup'
import getLayerFeaturesInViewport from '@/entities/Map/general/model/mapUtils/getLayerFeaturesInViewport'
import setChangedFeaturesInViewport from '@/entities/Map/general/model/addRegions/addEvents/setChangedFeaturesInViewport'
import getSchoolMarkerElement from '@/entities/Map/general/model/getSchoolMarkerElement'
import getKindergartenMarkerElement from '@/entities/Map/general/model/getKindergartenMarkerElement'
import handleRegionNodeClick from '@/pages/RegionsView/methods/handleRegionNodeClick'
import ShowRightMenu from '@/features/gasification/ShowRightMenu'
import setRegionsStats from '@/pages/RegionsView/methods/setRegionsStats'
import setActiveDistrict from '@/entities/Map/general/model/setActiveDistrict'
import loadFeaturesByDistrictsInViewport from '@/entities/Map/general/model/mapUtils/loadFeaturesByDistrictsInViewport'
import districtProposalsStatsGeo from '@/widgets/MenuPanel/ui/TimeLine/methods/stepsActions/districtsActions/districtProposalsStatsGeo'
import {
    AvailableDevices,
    RootState,
} from '@/app/providers/store/types/rootTypes'
import proposalDistrictIcon from '@/entities/Map/general/model/proposalDistrictIcon'
import AppModuleController from '@/entities/Map/general/model/AppModuleController/AppModuleController'
import { emptyFeatureCollection } from '@/entities/Map/general/model/mapUtils/mapUtilsConstants'
import addSpg from '@/entities/Map/spg/model/addSpg'
import NavController from '@/widgets/NavController/NavController.vue'
import AdditionalMenu from '@/widgets/AdditionalMenu/AdditionalMenu.vue'
import MapBtns from '@/widgets/MapBtns/MapBtns.vue'
import addAGNKS from '@/entities/Map/AGNKS/model/addAGNKS'
import setChangeStepByZoom from '@/widgets/MenuPanel/ui/TimeLine/methods/setChangeStepByZoom'
import { showRightMenuModules } from '@/features/gasification/ShowRightMenu/model/ShowRightMenu.config'

export default defineComponent({
    name: 'App',
    mixins: [Pipelines],
    components: {
        MapEl,
        SelectModuleView,
        ElConfigProvider,
        IndexedDbController,
        MapLegends,
        DivisionScale,
        MenuPanel,
        PopupMap,
        Loader,
        // Logout,
        DistrictInfoBlock,
        PopulationDensity,
        DevView,
        RegionsView,
        ShowRightMenu,
        ProposalStatsInfoMarker,
        ChooseModuleMenu,
        NavController,
        AdditionalMenu,
        MapBtns,
    },
    provide() {
        return {
            appContext: this,
        }
    },
    data() {
        return {
            isF7Ready: false,
            locale: ru,
            links: [],
            activeRegionNumber: 0,
            focusedDistrictId: 0,
            mapIsLoaded: false,
            repairWorks: false,
            f7params: {
                name: 'gasification',
                theme: 'ios',
                autoDarkTheme: false,
                id: 'io.framework7.gasification',
                routes: [
                    {
                        id: 0,
                        path: '/',
                        asyncComponent: () => import('@/pages/RegionsView'),
                    },
                    {
                        id: 1,
                        path: '/district-view/',
                        asyncComponent: () => import('@/pages/DistrictsView'),
                    },
                    {
                        id: 2,
                        path: '/gasification-plan/',
                        asyncComponent: () =>
                            import('@/pages/GasificationPlan'),
                    },
                    {
                        id: 2,
                        path: '/gds-view/',
                        asyncComponent: () => import('@/pages/GdsView'),
                    },
                ],
            },
            populationDensityOfRegions: {},
        }
    },
    computed: {
        ...mapState({
            sources: state => state.sources,
            mapCenter: state => state.map.center,
            mapBounds: state => state.map.bounds,
            focusedRegionNumber: state => state.focusedRegionNumber,
            focusedRegionName: state => state.focusedRegionName,
            focusedDistrictNumber: state => state.focusedDistrictNumber,
            mapZoom: state => state.map.zoom,
            mapBearing: state => state.map.bearing,
            mapPitch: state => state.map.pitch,
            mapStyleCurrentType: state => state.map.mapStyleCurrentType,
            isShowMiniMap: state => state.view.isShowMiniMap,
            isShowPopulationDensity: state => state.map.isShowPopulationDensity,
            isShowGds: state => state.map.isShowGds,
            popupMap: state => state.map.popupMap,
            popupMapId: state => state.map.popupMap.id,
            isShowPopupMap: state => state.map.popupMap.isShow,
            isShowConsumers: state => state.map.isShowConsumers,
            isShowCityAreas: state => state.map.isShowCityAreas,
            activeRegionDensityPalette: state =>
                state.activeRegionDensityPalette,
            loadingSourceUrls: state => state.loadingSourceUrls,
            keycloakTokenParsed: state => state.user.keycloakTokenParsed,
            gdsMenuState: state => state.view.gdsMenu.state,
            isMenuOpen: state => state.view.isMenuOpen,
            isShowAzs: state => state.map.isShowAzs,
            isShowMapLegend: state => state.view.isShowMapLegend,
            menuPanelWidth: state => state.view.menuPanelWidth,
            windowViewportWidth: state => state.view.windowViewportWidth,
            deviceType: state => state.deviceType,
            errorAcces: state => state.error,
            isReleaseNotes: state => state.view.releaseNotes,
            isReleaseNotesShowed: state => state.view.isReleaseNotesShowed,
            appVersion: state => state.appVersion,
            releaseDate: state => state.releaseDate,
            districtsInViewport: state => state.map.districtsInViewport,
            indexedDbController: state => state.indexedDbController,
            activeDistrictsFillLayerId: state =>
                state.map.activeDistrictsFillLayerId,
            proposalsInfoMarkers: state => state.map.proposalsInfoMarkers,
            isShowProposals: state => state.map.isShowProposals,
            chooseModuleId: state => state.chooseModuleId,
            allowAccess: state => state.user.allowAccess,
            appModuleAccess: state => state.appModuleAccess,
            chosenAppModule: state => state.chosenAppModule,
        }),
        ...mapGetters(['isMobileView', 'allowedAppModules']),
        appModuleIds() {
            return appModuleIds
        },
        showRightMenuModules() {
            return showRightMenuModules
        },

        // persistedChooseModuleId() {
        //     return localStorage.getItem('chooseModuleId')
        // },
        AvailableDevices() {
            return AvailableDevices
        },
    },
    watch: {
        mapZoom: {
            handler(mapZoom) {
                if (
                    mapZoom < this.layerMinZoom1 ||
                    this.activeRegionNumber === this.focusedRegionNumber
                )
                    return
                this.activeRegionNumber = this.focusedRegionNumber
                //test
                // this.activeRegionNumber = undefined
            },
            immediate: true,
        },
        focusedRegionNumber: {
            handler(focusedRegionNumber) {
                if (!focusedRegionNumber) return
                if (this.mapZoom < this.layerMinZoom1) return
                this.activeRegionNumber = focusedRegionNumber

                if (!this.activeRegionNumber) {
                    this.$store.commit('setPartialState', {
                        error: {
                            isActive: true,
                            message: `failed region name: ${this.focusedRegionName}`,
                            type: 'warning',
                            statusCode: `activeRegionNumber: ${this.activeRegionNumber}`,
                        },
                    })
                }
            },
            immediate: true,
        },
        activeRegionNumber: {
            handler(activeRegionNumber) {
                if (!activeRegionNumber) return
                // this.setActiveRegion(activeRegionNumber)
            },
            immediate: true,
        },
        gdsMenuState(state) {
            console.log('gdsMenuState', state)
        },
        windowViewportWidth() {
            this.resizeMap(this.isMenuOpen)
        },
        isMenuOpen(isOpen) {
            if (!this.isMobileView) {
                this.resizeMap(isOpen)
            }
        },
        isShowAzs(isShowAzs) {
            const azsLayers = this.map
                .getStyle()
                .layers.filter(l => l.source === 'azs')
            if (!azsLayers.length) return

            if (isShowAzs) {
                this.setLayersVisibility(['lng_stations'], 'visible')
                azsLayers.forEach(layer =>
                    this.setLayersVisibility([layer.id], 'visible')
                )

                return
            }
            this.setLayersVisibility(['lng_stations'], 'none')
            azsLayers.forEach(layer =>
                this.setLayersVisibility([layer.id], 'none')
            )
        },
        popupMap({ popupProperties }) {
            // console.log('popup added', popupProperties?.closeButton)
            this.setPopup(popupProperties)
        },
        async mapStyleCurrentType(newMapStyleType) {
            await this.setMapStyle(newMapStyleType)
        },
        async districtsInViewport(newDistrictIdsSet) {
            // console.log(
            //     'districtsInViewport| features changed: ',
            //     newDistrictIdsSet
            // )
            if (this.isShowProposals) {
                await this.districtProposalsStatsGeo(newDistrictIdsSet)
            }
        },
        isShowProposals(isShow) {
            if (!isShow) return

            if (this.districtsInViewport) {
                this.districtProposalsStatsGeo(this.districtsInViewport)
            }
        },
        loadingSourceUrls(loadingProps) {
            // console.log('loadingSourceUrls', loadingProps)
        },
        isShowPopupMap(isShow) {
            if (!isShow) {
                this.removePopup()
            }
        },
        async chosenAppModule() {
            await this.AppModuleController()

            setChangeStepByZoom.call(this, this.mapZoom)

            this.districtProposalsStatsGeo(this.districtsInViewport)
        },
    },
    methods: {
        initKeycloak,
        setActiveRegion,
        setAsyncLayers,
        initMap,
        initMiniMap,
        addRegions,
        loadSource,
        flyToObj,
        loadGdsData,
        addDistrictsOfRegion,
        addSource,
        addCity,
        addLayer,
        addLayerAfter,
        getLayerById,
        getLayerId,
        addConsumers,
        addCityArea,
        addGds,
        addAzs,
        addSpg,
        addAGNKS,
        addSocialObjects,
        sourceLoadedHandler,
        addLakes,
        addRivers,
        getGdsMarkerElement,
        getHospitalMarkerElement,
        getMedicalMarkerElement,
        getSchoolMarkerElement,
        proposalDistrictIcon,
        getKindergartenMarkerElement,
        getAzsMarkerElement,
        getGzsMarkerElement,
        getMazkMarkerElement,
        setMetersInPx,
        setLayersVisibility,
        clearActiveRegion,
        setMouseenterMouseleave,
        getPipelineSvgPattern,
        loadSvgToMapImages,
        getCityMarkerElement,
        changeFocusedRegionHandler,
        getMapStyle,
        setMapStyle,
        tileServerRasterStyle,
        tileServerSergStyle,
        geoJsonStyleLoad,
        addBuildings,
        colorizeDistrictsByDensity,
        setPopup,
        removePopup,
        getLayerFeaturesInViewport,
        setChangedFeaturesInViewport,
        addProposals,
        handleRegionNodeClick,
        setRegionsStats,
        setActiveDistrict,
        loadFeaturesByDistrictsInViewport,
        districtProposalsStatsGeo,
        AppModuleController,

        ...mapMutations(['setChosenAppModule']),

        resizeMap(isOpen) {
            const leftPadding =
                isOpen && !this.isMobileView ? this.menuPanelWidth / 2 : 0
            console.log('isMenuOpen ', 'padding: ', leftPadding)

            this.map?.easeTo({
                padding: {
                    left: leftPadding,
                },
                duration: 300, // In ms. This matches the CSS transition duration property.
            })
        },
        toggleMapLegend() {
            this.$state.view.isShowMapLegend = !this.$state.view.isShowMapLegend
        },
        async checkModulesAccess() {
            for (const module in this.appModuleAccess) {
                const res = await this.$axios
                    .get(this.appModuleAccess[module].checkAccessUrl)
                    .catch(e => e)

                if (res.status === 200) {
                    this.$state.appModuleAccess[module].allowAccess = true
                } else {
                    this.$state.appModuleAccess[module].allowAccess = false
                    continue
                }
                console.log('statusCode', res.status)
            }

            this.setChosenAppModule(
                this.chosenAppModule || this.allowedAppModules[0]
            )
        },
    },
    beforeCreate() {
        const setWindowSizeToStore = () => {
            this.$state.view.windowViewportWidth = window.innerWidth
        }
        window.addEventListener('resize', setWindowSizeToStore)
        setWindowSizeToStore()
    },
    created,
    async mounted() {
        emptyFeatureCollection().features = [124]

        this.$commit('checkDeviceType')

        f7ready(f7 => {
            // console.log('f7 ready:', f7)
            this.isF7Ready = true
        })
        global.vm = this

        console.log('this', this)
        this.f7 = f7

        await awaitComponentPositiveValue(this, 'keycloakTokenParsed')

        await this.checkModulesAccess()

        this.$state.indexedDbController = this.$refs.indexedDbController
        await this.$state.indexedDbController.initialize()

        await this.AppModuleController()
    },
    // eslint-disable-next-line prettier/prettier
});
