<template>
    <div class="mapPopup" id="mapPopup" v-if="featureId">
        <AzsPopup v-if="popupType === popupTypes.lngPopup" />
        <DefaultHoverPopup v-if="popupType === popupTypes.socialObjectsPopup" />
        <InlineHtmlPopup v-if="popupType === popupTypes.inlineHtml" />
        <DefaultClickPopup v-if="popupType === popupTypes.default" />
        <SpgPopup v-if="popupType === popupTypes.spgLngPopup" />
    </div>
</template>

<script>
import { mapState } from 'vuex'
import AzsPopup from './components/AzsPopup'
import DefaultHoverPopup from './components/DefaultHoverPopup'
import { popupTypes } from '../../model/addLayerHoverActions/types/popupTypes'
import DefaultClickPopup from './components/DefaultClickPopup'
import InlineHtmlPopup from './components/InlineHtmlPopup'
import SpgPopup from './components/SpgPopup'

export default {
    data() {
        return {
            popupTypes,
        }
    },
    components: {
        AzsPopup,
        DefaultHoverPopup,
        DefaultClickPopup,
        InlineHtmlPopup,
        SpgPopup,
    },
    computed: {
        ...mapState({
            popupType: state => state.map.popupMap.popupType,
            featureId: state => state.map.popupMap.id,
        }),
    },
    mounted() {
        console.log('popupTypes', this.popupType)
    },
}
</script>

<style lang="stylus" module></style>
