import { popupTypes } from '@/entities/Map/general/model/addLayerHoverActions/types/popupTypes'
import { emptyFeatureCollection } from '@/entities/Map/general/model/mapUtils/mapUtilsConstants'
import setClusterEvents from '@/entities/Map/general/model/eventMethods/setClusterEvents'
enum SpgPowerTypes {
    MEDIUM_TONNAGE_PRODUCTION = 'MEDIUM_TONNAGE_PRODUCTION',
    LOW_TONNAGE_PRODUCTION = 'LOW_TONNAGE_PRODUCTION',
    LARGE_TONNAGE_PRODUCTION = 'LARGE_TONNAGE_PRODUCTION',
}
enum SpgStatusTypes {
    PERSPECTIVE = 'PERSPECTIVE',
    WORKING = 'WORKING',
}
enum SpgTypes {
    FACTORY = 'FACTORY',
    TERMINAL = 'TERMINAL',
}

export default async function (this: any) {
    const spgFeatures = await this.loadSource('/lng/factories/russia')
    if (!spgFeatures) throw new Error('!spgFeatures')
    const spgLayerId = 'lng-factories'
    const spgSourceId = 'spg'

    await this.addSource(
        spgSourceId,
        this.featureCollectionType,
        spgFeatures,
        this.map,
        {
            cluster: true,
            clusterMaxZoom: 7, // Max zoom to cluster points on
            clusterRadius: 20,
            clusterProperties: {
                type: ['coalesce', ['get', 'type']],
                powerType: ['coalesce', ['get', 'powerType']],
                status: ['coalesce', ['get', 'status']],
            },
        }
    )
    const spgCluster = `__cluster`
    const spgClusterLayer = {
        id: spgCluster,
        type: 'symbol',
        source: spgSourceId,
        maxzoom: 7,
        minzoom: 5,
        filter: [
            'all',
            ['==', 'type', SpgTypes.FACTORY],
            ['==', 'powerType', SpgPowerTypes.MEDIUM_TONNAGE_PRODUCTION],
            ['has', 'point_count'],
        ],
        layout: {
            'icon-allow-overlap': true,
            'icon-image': [
                'case',
                ['==', ['get', 'status'], SpgStatusTypes.PERSPECTIVE],
                'LNG_middle_perspective_15',
                ['==', ['get', 'status'], SpgStatusTypes.WORKING],
                'LNG_middle_15',
                'LNG_middle_perspective_15',
            ],
        },
    }
    const spgClusterCount = `${spgLayerId}__cluster-count`
    const spgClusterCountLayer = {
        id: spgClusterCount,
        type: 'symbol',
        source: spgSourceId,
        filter: ['has', 'point_count'],
        layout: {
            'text-field': ['get', 'point_count'],
            'text-font': ['Noto Sans Bold'],
            'text-size': 12,
            'text-allow-overlap': false,
            'text-ignore-placement': false,
            // 'text-offset': [0, -1.4],
        },
        paint: {
            'text-color': '#fff',
            'text-halo-color': '#283cff',
            'text-halo-width': 1,
        },
        minzoom: 0,
    }
    const spgClusterArea = `${spgLayerId}__cluster-area`
    const spgClusterAreaLayer = {
        id: spgClusterArea,
        type: 'circle',
        source: spgSourceId,
        filter: ['has', 'point_count'],
        paint: {
            // 'circle-color': 'rgba(187,113,137,0.49)',
            'circle-radius': {
                type: 'exponential',
                base: 26,
                stops: [
                    [8, 20],
                    [10, 26],
                ],
            },
            'circle-color': 'transparent',
        },
        minzoom: 0,
    }
    const generateClusterLayer = ({ id, filter, layout }) => {
        return {
            id: `${id + spgCluster}`,
            type: 'symbol',
            source: spgSourceId,
            minzoom: 0,
            filter: [...filter, ['has', 'point_count']],
            layout,
        }
    }

    const spgLabelLayer = {
        id: `${spgLayerId}_label`,
        type: 'symbol',
        source: spgSourceId,
        minzoom: 10,
        layout: {
            'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
            'text-justify': 'auto',
            'text-max-width': 7,
            'text-letter-spacing': 0.05,
            'text-field': ['get', 'name'],
            'text-offset': [1, 0.75],
            'text-size': {
                stops: [
                    [10, 12],
                    [12, 14],
                    [14, 18],
                    [22, 22],
                ],
                type: 'exponential',
                base: 1,
            },
            'text-allow-overlap': false,
            'text-font': ['Noto Sans Italic'],
        },
        paint: {
            'text-halo-color': '#fff',
            'text-color': '#000',
            'text-halo-width': 1,
        },
    }
    const spgFactoryLowIconLayerSmall = {
        id: `${spgLayerId}_FACTORY_LOW_11`,
        type: 'symbol',
        source: spgSourceId,
        maxzoom: 5,
        minzoom: 0,
        filter: [
            'all',
            ['==', 'type', SpgTypes.FACTORY],
            ['==', 'powerType', SpgPowerTypes.LOW_TONNAGE_PRODUCTION],
        ],
        layout: {
            'icon-allow-overlap': true,
            'icon-image': [
                'case',
                ['==', ['get', 'status'], SpgStatusTypes.PERSPECTIVE],
                'LNG_small_perspective_11',
                ['==', ['get', 'status'], SpgStatusTypes.WORKING],
                'LNG_small_11',
                'LNG_small_perspective_11',
            ],
        },
    }
    const spgFactoryLowIconLayerMiddle = {
        id: `${spgLayerId}_FACTORY_LOW_15`,
        type: 'symbol',
        source: spgSourceId,
        minzoom: 0,
        maxzoom: 7,
        filter: [
            'all',
            ['==', 'type', SpgTypes.FACTORY],
            ['==', 'powerType', SpgPowerTypes.LOW_TONNAGE_PRODUCTION],
        ],
        layout: {
            'icon-allow-overlap': true,
            'icon-image': [
                'case',
                ['==', ['get', 'status'], SpgStatusTypes.PERSPECTIVE],
                'LNG_small_perspective_15',
                ['==', ['get', 'status'], SpgStatusTypes.WORKING],
                'LNG_small_15',
                'LNG_small_perspective_15',
            ],
        },
    }
    const spgFactoryLowIconLayerBig = {
        id: `${spgLayerId}_FACTORY_LOW_22`,
        type: 'symbol',
        source: spgSourceId,
        minzoom: 7,
        filter: [
            'all',
            ['==', 'type', SpgTypes.FACTORY],
            ['==', 'powerType', SpgPowerTypes.LOW_TONNAGE_PRODUCTION],
        ],
        layout: {
            'icon-allow-overlap': true,
            'icon-image': [
                'case',
                ['==', ['get', 'status'], SpgStatusTypes.PERSPECTIVE],
                'LNG_small_perspective_22',
                ['==', ['get', 'status'], SpgStatusTypes.WORKING],
                'LNG_small_22',
                'LNG_small_perspective_22',
            ],
        },
    }

    const spgFactoryMediumIconLayerSmall = {
        id: `${spgLayerId}_FACTORY_MEDIUM_11`,
        type: 'symbol',
        source: spgSourceId,
        maxzoom: 5,
        minzoom: 0,
        filter: [
            'all',
            ['==', 'type', SpgTypes.FACTORY],
            ['==', 'powerType', SpgPowerTypes.MEDIUM_TONNAGE_PRODUCTION],
        ],
        layout: {
            'icon-allow-overlap': true,
            'icon-image': [
                'case',
                ['==', ['get', 'status'], SpgStatusTypes.PERSPECTIVE],
                'LNG_middle_perspective_11',
                ['==', ['get', 'status'], SpgStatusTypes.WORKING],
                'LNG_middle_11',
                'LNG_middle_perspective_11',
            ],
        },
    }
    const spgFactoryMediumIconLayerMiddle = {
        id: `${spgLayerId}_FACTORY_MEDIUM_15`,
        type: 'symbol',
        source: spgSourceId,
        maxzoom: 7,
        minzoom: 0,
        filter: [
            'all',
            ['==', 'type', SpgTypes.FACTORY],
            ['==', 'powerType', SpgPowerTypes.MEDIUM_TONNAGE_PRODUCTION],
        ],
        layout: {
            'icon-allow-overlap': true,
            'icon-image': [
                'case',
                ['==', ['get', 'status'], SpgStatusTypes.PERSPECTIVE],
                'LNG_middle_perspective_15',
                ['==', ['get', 'status'], SpgStatusTypes.WORKING],
                'LNG_middle_15',
                'LNG_middle_perspective_15',
            ],
        },
    }
    const spgFactoryMediumIconLayerBig = {
        id: `${spgLayerId}_FACTORY_MEDIUM_22`,
        type: 'symbol',
        source: spgSourceId,
        minzoom: 7,
        filter: [
            'all',
            ['==', 'type', SpgTypes.FACTORY],
            ['==', 'powerType', SpgPowerTypes.MEDIUM_TONNAGE_PRODUCTION],
        ],
        layout: {
            'icon-allow-overlap': true,
            'icon-image': [
                'case',
                ['==', ['get', 'status'], SpgStatusTypes.PERSPECTIVE],
                'LNG_middle_perspective_22',
                ['==', ['get', 'status'], SpgStatusTypes.WORKING],
                'LNG_middle_22',
                'LNG_middle_perspective_22',
            ],
        },
    }

    const spgFactoryLargeIconLayerSmall = {
        id: `${spgLayerId}_FACTORY_LARGE_11`,
        type: 'symbol',
        source: spgSourceId,
        maxzoom: 5,
        minzoom: 0,
        filter: [
            'all',
            ['==', 'type', SpgTypes.FACTORY],
            ['==', 'powerType', SpgPowerTypes.LARGE_TONNAGE_PRODUCTION],
        ],
        layout: {
            'icon-allow-overlap': true,
            'icon-image': [
                'case',
                ['==', ['get', 'status'], SpgStatusTypes.PERSPECTIVE],
                'LNG_big_perspective_11',
                ['==', ['get', 'status'], SpgStatusTypes.WORKING],
                'LNG_big_11',
                'LNG_big_perspective_11',
            ],
        },
    }
    const spgFactoryLargeIconLayerMiddle = {
        id: `${spgLayerId}_FACTORY_LARGE_15`,
        type: 'symbol',
        source: spgSourceId,
        maxzoom: 7,
        minzoom: 0,
        filter: [
            'all',
            ['==', 'type', SpgTypes.FACTORY],
            ['==', 'powerType', SpgPowerTypes.LARGE_TONNAGE_PRODUCTION],
        ],
        layout: {
            'icon-allow-overlap': true,
            'icon-image': [
                'case',
                ['==', ['get', 'status'], SpgStatusTypes.PERSPECTIVE],
                'LNG_big_perspective_15',
                ['==', ['get', 'status'], SpgStatusTypes.WORKING],
                'LNG_big_15',
                'LNG_big_perspective_15',
            ],
        },
    }
    const spgFactoryLargeIconLayerBig = {
        id: `${spgLayerId}_FACTORY_LARGE_22`,
        type: 'symbol',
        source: spgSourceId,
        minzoom: 7,
        filter: [
            'all',
            ['==', 'type', SpgTypes.FACTORY],
            ['==', 'powerType', SpgPowerTypes.LARGE_TONNAGE_PRODUCTION],
        ],
        layout: {
            'icon-allow-overlap': true,
            'icon-image': [
                'case',
                ['==', ['get', 'status'], SpgStatusTypes.PERSPECTIVE],
                'LNG_big_perspective_22',
                ['==', ['get', 'status'], SpgStatusTypes.WORKING],
                'LNG_big_22',
                'LNG_big_perspective_22',
            ],
        },
    }

    // const spgTerminalIconLayerSmall =
    //   {
    //       "id": `${spgLayerId}_TERMINAL_11`,
    //       "type": "symbol",
    //       "source": spgSourceId,
    //       "maxzoom": 5,
    //       "minzoom": 0,
    //       "filter": [
    //           "all",
    //           [
    //               "==",
    //               "type",
    //               SpgTypes.TERMINAL
    //           ]
    //       ],
    //       "layout": {
    //           "icon-allow-overlap": true,
    //           "icon-image": [
    //               "case",
    //               [
    //                   "==",
    //                   [
    //                       "get",
    //                       "status"
    //                   ],
    //                   SpgStatusTypes.PERSPECTIVE
    //               ],
    //               "LNG_regasification__perspective_11",
    //               [
    //                   "==",
    //                   [
    //                       "get",
    //                       "status"
    //                   ],
    //                   SpgStatusTypes.WORKING
    //               ],
    //               "LNG_regasification_11",
    //               "LNG_regasification__perspective_11"
    //           ]
    //       }
    //   }
    const spgTerminalIconLayerMiddle = {
        id: `${spgLayerId}_TERMINAL_15`,
        type: 'symbol',
        source: spgSourceId,
        maxzoom: 5,
        filter: ['all', ['==', 'type', SpgTypes.TERMINAL]],
        layout: {
            'icon-allow-overlap': true,
            'icon-image': [
                'case',
                ['==', ['get', 'status'], SpgStatusTypes.PERSPECTIVE],
                'LNG_regasification__perspective_15',
                ['==', ['get', 'status'], SpgStatusTypes.WORKING],
                'LNG_regasification_15',
                'LNG_regasification__perspective_15',
            ],
        },
    }

    const spgTerminalIconLayerBig = {
        id: `${spgLayerId}_TERMINAL_22`,
        type: 'symbol',
        source: spgSourceId,
        minzoom: 5,
        filter: ['all', ['==', 'type', SpgTypes.TERMINAL]],
        layout: {
            'icon-allow-overlap': true,
            'icon-image': [
                'case',
                ['==', ['get', 'status'], SpgStatusTypes.PERSPECTIVE],
                'LNG_regasification__perspective_22',
                ['==', ['get', 'status'], SpgStatusTypes.WORKING],
                'LNG_regasification_22',
                'LNG_regasification__perspective_22',
            ],
        },
    }

    const clusterLayers = [
        spgFactoryLowIconLayerBig,
        spgFactoryMediumIconLayerBig,
        spgFactoryLargeIconLayerBig,
        spgTerminalIconLayerBig,
    ].map(layer => generateClusterLayer(layer))
    const addingLayers = [
        // spgFactoryLowIconLayerSmall,
        spgFactoryLowIconLayerMiddle,
        spgFactoryLowIconLayerBig,
        // spgFactoryMediumIconLayerSmall,
        spgFactoryMediumIconLayerMiddle,
        spgFactoryMediumIconLayerBig,
        // spgFactoryLargeIconLayerSmall,
        spgFactoryLargeIconLayerMiddle,
        spgFactoryLargeIconLayerBig,
        // spgTerminalIconLayerSmall,
        spgTerminalIconLayerMiddle,
        spgTerminalIconLayerBig,
    ]
    //
    addingLayers.forEach(layer => this.addLayer(layer))
    const clusterLayersAdding = [...clusterLayers, spgClusterCountLayer]
    clusterLayersAdding.forEach(layer => this.map.addLayer(layer))
    setClusterEvents.call(
        this,
        clusterLayersAdding.map(layer => layer.id)
    )

    return () => {
        addingLayers.forEach(layer =>
            this.addLayerHoverActions(layer.id, {
                mainLayerOptions: layer,

                hoveringLayerOptions: {
                    type: this.layerTypeCircle,
                    source: spgSourceId,
                    paint: {
                        'circle-color': 'rgba(0,0,0,0)',
                        'circle-radius': 20,
                        'circle-opacity': 0.7,
                    },
                },

                hoveringDecorationLayerOptions: {
                    type: this.layerTypeCircle,
                    source: spgSourceId,
                    paint: {
                        // 'line-color': this.colors['17'],
                        'circle-color': [
                            'case',
                            ['boolean', ['feature-state', 'hover'], false],
                            // this.colors[39],
                            // this.colors[39],
                            'rgba(0,0,0,0)',
                            'rgba(0,0,0,0)',
                        ],
                        'circle-radius': [
                            'case',
                            ['boolean', ['feature-state', 'hover'], false],
                            10,
                            5,
                        ],
                        'circle-opacity': [
                            'case',
                            ['boolean', ['feature-state', 'hover'], false],
                            0.7,
                            0,
                        ],
                    },
                },

                getHtmlContent: () => {
                    return {
                        htmlContent: null,
                        type: popupTypes.spgLngPopup,
                    }
                },
            })
        )
    }
}
