<template>
    <f7-list inset>
        <f7-list-item>
            <el-container direction="vertical" v-loading="isLoading">
                <el-tree
                    :class="$style.treeContainer"
                    icon=""
                    v-if="dataTreeList?.length"
                    :data="dataTreeList"
                    node-key="id"
                    @node-click="handleNodeClick"
                    @node-expand="
                        node => handleNodeExpandToggle(node, 'expanded')
                    "
                    @node-collapse="
                        node => handleNodeExpandToggle(node, 'collapsed')
                    "
                    :default-expanded-keys="[
                        ...this.$state.view.regionsView.expandedNodes,
                    ]"
                >
                    <template #default="{ data }">
                        <div :class="$style.treeNode">
                            <div :class="$style.treeNode__title">
                                {{ data.label }}
                            </div>
                            <div
                                :class="$style.treeNode__proposalsCount"
                                v-if="
                                    isShowProposals &&
                                    chosenAppModule?.id ===
                                        appModuleIds.gasification
                                "
                            >
                                <el-tooltip
                                    class="box-item"
                                    effect="light"
                                    content="Всего заявок"
                                    :hide-after="0"
                                    v-if="data.all_proposal_count"
                                    transition=""
                                >
                                    {{
                                        data?.all_proposal_count
                                            ?.toString()
                                            .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ' '
                                            )
                                    }}
                                </el-tooltip>
                            </div>
                        </div>
                    </template>
                </el-tree>

                <div v-if="dataTreeList?.length === 0">нет данных</div>

                <!--                <el-button v-if="!dataTreeList" loading>Загрузка</el-button>-->
            </el-container>
        </f7-list-item>
    </f7-list>
</template>
<script>
import { mapState } from 'vuex'
import { appModuleIds } from '../../../../shared/constants'

export default {
    props: {
        handleNodeClick: {
            type: Function,
            required: true,
        },
        handleNodeExpandToggle: {
            type: Function,
            required: false,
        },
        dataTreeList: {
            type: Array,
            required: true,
        },
        isLoading: {
            type: Boolean,
            required: false,
        },
    },
    components: {},
    computed: {
        ...mapState({
            isShowProposals: state => state.map.isShowProposals,
            chosenAppModule: state => state.chosenAppModule,
        }),

        appModuleIds() {
            return appModuleIds
        },
    },
}
</script>

<style lang="stylus" module>
.treeContainer
    width 100%
.treeNode
    display flex
    //justify-content space-between
    font-size var(--el-font-size-base)
    width: 95%
.treeNode__title
    min-width: 0;
    flex-shrink: 1;
    white-space: var(--f7-list-item-title-white-space);
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: var(--f7-list-item-title-font-size);
    font-weight: var(--f7-list-item-title-font-weight);
    color: var(--f7-list-item-title-text-color);
    line-height: var(--f7-list-item-title-line-height);
    width: 85%;
.treeNode__proposalsCount
    font-family: Noto Sans Bold
    color: #000000
    font-weight: 600;
    width: 15%;
    margin-left 20px
    text-align: end;
</style>
