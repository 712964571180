import { AZS_COMPANY_NOVATEK } from '@/shared/constants'

export default function (this: any, type: string) {
    let url = ''
    switch (type) {
        case this.azsTileSourceName:
            url = `/lng/russia`
            break
    }
    return this.loadSource(url).then(source => {
        this.$map.addSource(type, {
            type: 'geojson',
            data: source,
        })
    })
}
