import Keycloak from 'keycloak-js'

import { store } from '@/app/providers'
import { appModuleIds } from '@/shared/constants'
const keycloakUpdateTokenTimeoutS = 60
const { VUE_APP_KEYCLOAK_REALM, VUE_APP_KEYCLOAK_CLIENT_ID } = process.env
let VUE_APP_KEYCLOAK_URL: string | undefined = process.env.VUE_APP_KEYCLOAK_URL
if (process.env.VUE_APP_BUILD_WITH_DEVELOPMENT_PARTS === '1') {
    VUE_APP_KEYCLOAK_URL = 'http://192.168.245.196:8082/auth'
}
if (
    !VUE_APP_KEYCLOAK_URL ||
    !VUE_APP_KEYCLOAK_REALM ||
    !VUE_APP_KEYCLOAK_CLIENT_ID
) {
    throw new Error('Cannot find .env variables')
}
const initOptions: any = {
    [appModuleIds.gasification]: {
        url: VUE_APP_KEYCLOAK_URL,
        realm: VUE_APP_KEYCLOAK_REALM,
        clientId: VUE_APP_KEYCLOAK_CLIENT_ID,
        // onLoad: 'login-required',
    },
    [appModuleIds.spg]: {
        url: VUE_APP_KEYCLOAK_URL,
        realm: VUE_APP_KEYCLOAK_REALM,
        clientId: VUE_APP_KEYCLOAK_CLIENT_ID,
        // onLoad: 'login-required',
    },
}
// const initOptions = {
//   url: VUE_APP_KEYCLOAK_URL,
//   realm: VUE_APP_KEYCLOAK_REALM,
//   clientId: VUE_APP_KEYCLOAK_CLIENT_ID,
//   // onLoad: 'login-required',
// }

const deleteAllCookies = () => {
    const cookies = window.document.cookie.split(';')

    for (const cookie of cookies) {
        const eqPos = cookie.indexOf('=')
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
        window.document.cookie =
            name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT'
    }
}

export default function (appGlobalProperties: any) {
    store.watch(
        (state: any) => state.chooseModuleId,
        chooseModuleId => {
            console.log('chooseModuleId', chooseModuleId)
            // if (!chooseModuleId) return;
            const persistedChooseModuleId =
                localStorage.getItem('chooseModuleId')
            const keycloak =
                persistedChooseModuleId &&
                Keycloak(initOptions[persistedChooseModuleId])

            if (!keycloak) return
            keycloak
                .init({
                    // onLoad: 'check-sso',
                    silentCheckSsoRedirectUri:
                        globalThis.location.origin + '/silent-check-sso.html',
                })
                .then(authenticated => {
                    if (!authenticated) {
                        console.log('Keycloak not authenticated')
                        keycloak.login()
                        // window.location.reload()
                        return
                    }
                    console.log('Keycloak authenticated')
                    setKeycloakToken()
                })
                .catch(error => {
                    console.error('Authenticated Failed:', error)
                })
            appGlobalProperties.$app.config.globalProperties.$keycloak =
                keycloak
            const setKeycloakToken = () => {
                console.log('setKeycloakToken', keycloak)
                appGlobalProperties.$store.commit('setUserPartialState', {
                    keycloakToken: keycloak.token,
                    keycloakTokenParsed: keycloak.tokenParsed,
                })
            }
            const removeKeycloakToken = () => {
                appGlobalProperties.$store.commit('setUserPartialState', {
                    keycloakToken: '',
                    keycloakTokenParsed: null,
                })
            }
            if (process.env.VUE_APP_BUILD_WITH_DEVELOPMENT_PARTS === '1') {
                globalThis.keycloak = keycloak
            }
            keycloak.onReady = () => {
                console.log('onReady')
            }
            keycloak.onAuthError = () => {
                console.log('onAuthError')
                removeKeycloakToken()
            }
            keycloak.onAuthRefreshSuccess = () => {
                console.log('onAuthRefreshSuccess')
                setKeycloakToken()
            }
            keycloak.onAuthRefreshError = () => {
                console.log('onAuthRefreshError')
                // removeKeycloakToken()
                location.reload()
            }
            keycloak.onTokenExpired = () => {
                console.log('onTokenExpired')
                appGlobalProperties.$store.commit('setUserPartialState', {
                    isKeycloakAuth: false,
                })
                keycloak
                    .updateToken(keycloakUpdateTokenTimeoutS)
                    .then(response => {
                        console.log('Update token response:', response)
                    })
                    .catch(error => {
                        console.error('Update token error:', error)
                    })
            }
            keycloak.onAuthLogout = () => {
                console.log('onAuthLogout')
            }
            keycloak.onAuthSuccess = () => {
                console.log('onAuthSuccess')
                setKeycloakToken()
            }
            store.watch(
                (state: any) => state.user.allowAccess,
                allowAccess => {
                    if (!allowAccess) {
                        console.log('NOT ALLOW')
                        removeKeycloakToken()
                        keycloak.logout()
                        // keycloak.login()
                        // window.location.reload()
                    }
                },
                {
                    immediate: true,
                }
            )
        },
        {
            immediate: true,
        }
    )
}
