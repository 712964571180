<template>
    <div :class="$style.mapLegendWrapper">
        <svg :class="$style.decoration">
            <defs>
                <line
                    id="pipelineMajorOperating"
                    :class="[
                        $style.svgLine__line,
                        $style.svgLine__line_width_3,
                    ]"
                    x1="6"
                    y1="8"
                    x2="94"
                    y2="8"
                    :stroke="colors['37']"
                />
                <line
                    id="pipelineBranchOperating"
                    :class="[
                        $style.svgLine__line,
                        $style.svgLine__line_width_2,
                    ]"
                    x1="6"
                    y1="8"
                    x2="94"
                    y2="8"
                    :stroke="colors[38]"
                />
                <line
                    id="pipelineSettlementOperating"
                    :class="[
                        $style.svgLine__line,
                        $style.svgLine__line_width_1,
                    ]"
                    x1="6"
                    y1="8"
                    x2="94"
                    y2="8"
                    :stroke="colors[5]"
                />
                <line
                    id="pipelineMajorProjected"
                    :class="[
                        $style.svgLine__line,
                        $style.svgLine__line_dashed,
                        $style.svgLine__line_dashed_1,
                        $style.svgLine__line_width_3,
                    ]"
                    x1="6"
                    y1="8"
                    x2="94"
                    y2="8"
                    :stroke="colors[37]"
                />
                <line
                    id="pipelineBranchProjected"
                    :class="[
                        $style.svgLine__line,
                        $style.svgLine__line_dashed,
                        $style.svgLine__line_dashed_2,
                        $style.svgLine__line_width_2,
                    ]"
                    x1="6"
                    y1="8"
                    x2="94"
                    y2="8"
                    :stroke="colors[38]"
                />
                <line
                    id="pipelineSettlementProjected"
                    :class="[
                        $style.svgLine__line,
                        $style.svgLine__line_dashed,
                        $style.svgLine__line_dashed_3,
                        $style.svgLine__line_width_1,
                    ]"
                    x1="6"
                    y1="8"
                    x2="94"
                    y2="8"
                    :stroke="colors[5]"
                />
            </defs>
        </svg>
        <div
            :class="$style.mapLegend__content"
            v-if="
                chosenAppModule?.id === appModuleIds.gasification ||
                chosenAppModule?.id === appModuleIds.gasificationPlan
            "
        >
            <div :class="[$style.mapLegend__col, $style.mapLegend__col1]">
                <div :class="$style.blockTitle">Газопроводы</div>
                <!-- <el-table
                    :data="tableData"
                    border
                    style="width: 100%"
                    :class="$style.table"
                >
                    <el-table-column
                        prop="operating"
                        label="Действующие"
                        width="120"
                    >
                        <template #default="scope">
                            <div
                                :class="$style.cellContent"
                                v-html="scope.row.operating"
                            />
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="projected"
                        label="Проектируемые"
                        width="140"
                    >
                        <template #default="scope">
                            <div
                                :class="$style.cellContent"
                                v-html="scope.row.projected"
                            />
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="underConstruction"
                        label="Строящиеся"
                        width="110"
                    >
                        <template #default="scope">
                            <div
                                :class="$style.cellContent"
                                v-html="scope.row.underConstruction"
                            />
                        </template>
                    </el-table-column>
                    <el-table-column prop="type" label=""> </el-table-column>
                </el-table> -->
                <div class="data-table">
                    <table>
                        <thead>
                            <tr>
                                <th class="label-cell">Действующие</th>
                                <th class="numeric-cell">Проектируемые</th>
                                <th class="medium-only">Строящиеся</th>
                                <th class="medium-only"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(row, index) in tableData" :key="index">
                                <td class="label-cell">
                                    <div
                                        :class="$style.cellContent"
                                        v-html="row.operating"
                                    />
                                </td>
                                <td class="numeric-cell">
                                    <div
                                        :class="$style.cellContent"
                                        v-html="row.projected"
                                    />
                                </td>
                                <td class="medium-only">
                                    <div
                                        :class="$style.cellContent"
                                        v-html="row.underConstruction"
                                    />
                                </td>
                                <td class="medium-only">{{ row.type }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div :class="$style.blockTitle">ГРС</div>
                <div :class="$style.blockContent">
                    <div :class="$style.row_2">
                        <div :class="$style.cell">
                            <div :class="[$style.gdsMarker, $style.marker]">
                                <img
                                    :class="[$style.marker]"
                                    :src="GDS_OPERATING_ICON"
                                    alt="Закрытые на вход"
                                />
                            </div>
                            Действующие
                        </div>
                        <div :class="$style.cell">
                            <div :class="[$style.gdsMarker, $style.marker]">
                                <img
                                    :class="[$style.marker]"
                                    :src="GDS_CLOSED_ENTRANCE_ICON"
                                    alt="Закрытые на вход"
                                />
                            </div>
                            Закрытые на вход
                        </div>
                    </div>
                    <div :class="$style.row_2">
                        <div :class="$style.cell">
                            <div :class="[$style.gdsMarker, $style.marker]">
                                <img
                                    :class="[$style.marker]"
                                    :src="GDS_PROJECTED_ICON"
                                    alt="Проектируемые"
                                />
                            </div>
                            Проектируемые
                        </div>
                        <div :class="$style.cell">
                            <div :class="[$style.gdsMarker, $style.marker]">
                                <img
                                    :class="[$style.marker]"
                                    :src="GDS_CLOSED_ICON"
                                    alt="Закрытые"
                                />
                            </div>
                            Закрытые
                        </div>
                    </div>
                </div>
                <div :class="$style.blockTitle">Потребители газа</div>
                <div :class="$style.blockContent">
                    <div :class="$style.row_2">
                        <div :class="$style.cell">
                            <div
                                :class="[
                                    $style.marker,
                                    'consumersMarker',
                                    'consumersMarker_active',
                                ]"
                                :style="{
                                    backgroundColor: colors[4],
                                }"
                            />
                            Действующие
                        </div>
                    </div>
                    <div :class="$style.row_2">
                        <div :class="$style.cell">
                            <div
                                :class="[
                                    $style.marker,
                                    'consumersMarker',
                                    'consumersMarker_perspective',
                                ]"
                                :style="{
                                    borderColor: colors[4],
                                    backgroundColor: colors[9],
                                }"
                            />
                            Перспективные
                        </div>
                    </div>
                </div>
                <div :class="$style.blockTitle">ЗАПРАВОЧНЫЕ СТАНЦИИ</div>
                <div :class="$style.blockContent">
                    <div :class="$style.row_2">
                        <div :class="$style.cell">
                            <div
                                :class="[$style.gdsMarker, $style.marker]"
                                v-html="automobile_gas_filling_station_22"
                            />
                            Газовые заправочные станции
                        </div>
                        <div :class="$style.cell">
                            <div
                                :class="[$style.gdsMarker, $style.marker]"
                                v-html="cryogenic_gas_station_22"
                            />
                            Крио АЗС
                        </div>
                    </div>
                    <div :class="$style.row_2">
                        <div :class="$style.cell">
                            <div
                                :class="[$style.gdsMarker, $style.marker]"
                                v-html="multi_fuel_filling_complex_22"
                            />
                            МАЗК
                        </div>
                        <div :class="$style.cell">
                            <div
                                :class="[$style.gdsMarker, $style.marker]"
                                v-html="gas_filling_station_22"
                            />
                            ГНС
                        </div>
                    </div>
                </div>
                <div :class="$style.blockSubTitle">
                    Принадлежность к компании:
                </div>
                <div :class="$style.row_wide">
                    <div :class="$style.cell">
                        <div
                            :class="[$style.abstractMarker, $style.marker]"
                            :style="{ backgroundColor: colors[39] }"
                        />
                        НОВАТЭК
                    </div>
                    <div :class="$style.cell">
                        <div
                            :class="[$style.abstractMarker, $style.marker]"
                            :style="{ backgroundColor: colors[38] }"
                        />
                        Газпром
                    </div>
                    <div :class="$style.cell">
                        <div
                            :class="[$style.abstractMarker, $style.marker]"
                            :style="{ backgroundColor: colors[23] }"
                        />
                        Роснефть
                    </div>
                    <div :class="$style.cell">
                        <div
                            :class="[$style.abstractMarker, $style.marker]"
                            :style="{ backgroundColor: colors[24] }"
                        />
                        Прочие
                    </div>
                </div>
                <div :class="$style.blockTitle">Населенные пункты</div>
                <div :class="$style.blockContent">
                    <div :class="$style.row_2">
                        <div :class="[$style.cell, $style.cellSettlements]">
                            <div
                                :class="[
                                    $style.cityArea,
                                    $style.cityArea__connected,
                                ]"
                            >
                                Омск
                            </div>
                            <div>&nbsp; — &nbsp;подключенные</div>
                        </div>
                    </div>
                    <div :class="$style.row_2">
                        <div :class="[$style.cell, $style.cellSettlements]">
                            <div
                                :class="[
                                    $style.cityArea,
                                    $style.cityArea__disconnected,
                                ]"
                            >
                                Чита
                            </div>
                            <div>&nbsp; — &nbsp;неподключенные</div>
                        </div>
                    </div>
                </div>
                <div :class="$style.blockTitle">Строения</div>
                <div :class="$style.blockContent">
                    <div :class="$style.row_2">
                        <div :class="[$style.cell, $style.cellSettlements]">
                            <div
                                class="citySvgWrapper getCityMarker_active"
                                :class="[
                                    $style.citySvgWrapper,
                                    $style.cityLegendRect,
                                ]"
                                :style="{
                                    borderColor: colors[35],
                                    backgroundColor: '#EAE5DF',
                                    opacity: 1,
                                }"
                            ></div>
                            Жилое здание
                        </div>
                        <div :class="[$style.cell, $style.cellSettlements]">
                            <div
                                class="citySvgWrapper getCityMarker_active"
                                :class="[
                                    $style.citySvgWrapper,
                                    $style.cityLegendRect,
                                ]"
                                :style="{
                                    borderColor: colors[20],
                                    backgroundColor: '#D3D1E2',
                                    opacity: 1,
                                }"
                            ></div>
                            Административное здание
                        </div>
                    </div>
                    <div :class="$style.row_2">
                        <div :class="[$style.cell, $style.cellSettlements]">
                            <div
                                class="citySvgWrapper getCityMarker_active"
                                :class="[
                                    $style.citySvgWrapper,
                                    $style.cityLegendRect,
                                ]"
                                :style="{
                                    borderColor: colors[20],
                                    backgroundColor: '#E6DED3',
                                    opacity: 1,
                                }"
                            ></div>
                            Хозяйственное здание
                        </div>
                        <div :class="[$style.cell, $style.cellSettlements]">
                            <div
                                class="citySvgWrapper getCityMarker_active"
                                :class="[
                                    $style.citySvgWrapper,
                                    $style.cityLegendRect,
                                ]"
                                :style="{
                                    borderColor: colors[20],
                                    backgroundImage: `url(${not_defined_fias_polygon_65_svg})`,
                                    backgroundSize: 'cover',
                                    opacity: 1,
                                }"
                            ></div>
                            Неизвестно
                        </div>
                    </div>
                </div>
                <div :class="$style.blockTitle">Медицинские учреждения</div>
                <div :class="$style.blockContent">
                    <div :class="$style.row_2">
                        <div :class="$style.cell">
                            <div
                                :class="[$style.gdsMarker, $style.marker]"
                                v-html="hospitalMarkerHtml"
                            />

                            Стационарные
                        </div>
                    </div>
                    <div :class="$style.row_2">
                        <div :class="$style.cell">
                            <div
                                :class="[$style.gdsMarker, $style.marker]"
                                v-html="medicalMarkerHtml"
                            />

                            Амбулаторные
                        </div>
                    </div>
                </div>

                <div :class="$style.blockTitle">
                    Дошкольные и общеобразовательные организации
                </div>
                <div :class="$style.blockContent">
                    <div :class="$style.row_2">
                        <div :class="$style.cell">
                            <div
                                :class="[$style.marker]"
                                v-html="schoolMarkerHtml"
                            />

                            Школы
                        </div>
                    </div>
                    <div :class="$style.row_2">
                        <div :class="$style.cell">
                            <div
                                :class="[$style.marker]"
                                v-html="kindergartenMarkerHtml"
                            />

                            Детские сады
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            :class="$style.mapLegend__content"
            v-if="chosenAppModule?.id === appModuleIds.spg"
        >
            <div :class="$style.blockTitle">Газопроводы</div>

            <div class="data-table">
                <table>
                    <thead>
                        <tr>
                            <th class="label-cell">Действующие</th>
                            <th class="numeric-cell">Проектируемые</th>
                            <th class="medium-only">Строящиеся</th>
                            <th class="medium-only"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(row, index) in tableData" :key="index">
                            <td class="label-cell">
                                <div
                                    :class="$style.cellContent"
                                    v-html="row.operating"
                                />
                            </td>
                            <td class="numeric-cell">
                                <div
                                    :class="$style.cellContent"
                                    v-html="row.projected"
                                />
                            </td>
                            <td class="medium-only">
                                <div
                                    :class="$style.cellContent"
                                    v-html="row.underConstruction"
                                />
                            </td>
                            <td class="medium-only">{{ row.type }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div :class="$style.blockTitle">ГРС</div>
            <div :class="$style.blockContent">
                <div :class="$style.row_2">
                    <div :class="$style.cell">
                        <div :class="[$style.gdsMarker, $style.marker]">
                            <img
                                :class="[$style.marker]"
                                :src="GDS_OPERATING_ICON"
                                alt="Закрытые на вход"
                            />
                        </div>
                        Действующие
                    </div>
                    <div :class="$style.cell">
                        <div :class="[$style.gdsMarker, $style.marker]">
                            <img
                                :class="[$style.marker]"
                                :src="GDS_CLOSED_ENTRANCE_ICON"
                                alt="Закрытые на вход"
                            />
                        </div>
                        Закрытые на вход
                    </div>
                </div>
                <div :class="$style.row_2">
                    <div :class="$style.cell">
                        <div :class="[$style.gdsMarker, $style.marker]">
                            <img
                                :class="[$style.marker]"
                                :src="GDS_PROJECTED_ICON"
                                alt="Проектируемые"
                            />
                        </div>
                        Проектируемые
                    </div>
                    <div :class="$style.cell">
                        <div :class="[$style.gdsMarker, $style.marker]">
                            <img
                                :class="[$style.marker]"
                                :src="GDS_CLOSED_ICON"
                                alt="Закрытые"
                            />
                        </div>
                        Закрытые
                    </div>
                </div>
            </div>
            <div :class="[$style.mapLegend__col, $style.mapLegend__col1]">
                <div :class="$style.blockTitle">СПГ</div>
                <div :class="$style.blockContent">
                    <div :class="$style.row_5">
                        <div :class="[$style.cellSettlements]">
                            <div
                                class="citySvgWrapper getCityMarker_active"
                                :class="[
                                    $style.citySvgWrapper,
                                    $style.cityLegendRect,
                                ]"
                                :style="{
                                    borderColor: colors[20],
                                    backgroundImage: `url(${LNG_small})`,
                                    backgroundSize: 'contain',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat',
                                    opacity: 1,
                                }"
                            ></div>
                            <span>малотоннажный действующий</span>
                        </div>
                        <div :class="[$style.cellSettlements]">
                            <div
                                class="citySvgWrapper getCityMarker_active"
                                :class="[
                                    $style.citySvgWrapper,
                                    $style.cityLegendRect,
                                ]"
                                :style="{
                                    borderColor: colors[20],
                                    backgroundImage: `url(${LNG_small_perspective})`,
                                    backgroundSize: 'contain',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat',
                                    opacity: 1,
                                }"
                            ></div>
                            <span>малотоннажный перспективный</span>
                        </div>
                        <div :class="[$style.cellSettlements]">
                            <div
                                class="citySvgWrapper getCityMarker_active"
                                :class="[
                                    $style.citySvgWrapper,
                                    $style.cityLegendRect,
                                ]"
                                :style="{
                                    borderColor: colors[20],
                                    backgroundImage: `url(${LNG_middle})`,
                                    backgroundSize: 'contain',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat',
                                    opacity: 1,
                                }"
                            ></div>
                            <span>среднетоннажный действующий</span>
                        </div>
                        <div :class="[$style.cellSettlements]">
                            <div
                                class="citySvgWrapper getCityMarker_active"
                                :class="[
                                    $style.citySvgWrapper,
                                    $style.cityLegendRect,
                                ]"
                                :style="{
                                    borderColor: colors[20],
                                    backgroundImage: `url(${LNG_middle_perspective})`,
                                    backgroundSize: 'contain',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat',
                                    opacity: 1,
                                }"
                            ></div>
                            <span>среднетоннажный перспективный</span>
                        </div>
                        <div :class="[$style.cellSettlements]">
                            <div
                                class="citySvgWrapper getCityMarker_active"
                                :class="[
                                    $style.citySvgWrapper,
                                    $style.cityLegendRect,
                                ]"
                                :style="{
                                    borderColor: colors[20],
                                    backgroundImage: `url(${LNG_big})`,
                                    backgroundSize: 'contain',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat',
                                    opacity: 1,
                                }"
                            ></div>
                            <span>крупнотоннажный действующий</span>
                        </div>
                        <div :class="[$style.cellSettlements]">
                            <div
                                class="citySvgWrapper getCityMarker_active"
                                :class="[
                                    $style.citySvgWrapper,
                                    $style.cityLegendRect,
                                ]"
                                :style="{
                                    borderColor: colors[20],
                                    backgroundImage: `url(${LNG_big_perspective})`,
                                    backgroundSize: 'contain',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat',
                                    opacity: 1,
                                }"
                            ></div>
                            <span>крупнотоннажный перспективный</span>
                        </div>
                        <div :class="[$style.cellSettlements]">
                            <div
                                class="citySvgWrapper getCityMarker_active"
                                :class="[
                                    $style.citySvgWrapper,
                                    $style.cityLegendRect,
                                ]"
                                :style="{
                                    borderColor: colors[20],
                                    backgroundImage: `url(${LNG_regasification})`,
                                    backgroundSize: 'contain',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat',
                                    opacity: 1,
                                }"
                            ></div>
                            <span>терминал действующий</span>
                        </div>
                        <div :class="[$style.cellSettlements]">
                            <div
                                class="citySvgWrapper getCityMarker_active"
                                :class="[
                                    $style.citySvgWrapper,
                                    $style.cityLegendRect,
                                ]"
                                :style="{
                                    borderColor: colors[20],
                                    backgroundImage: `url(${LNG_regasification__perspective})`,
                                    backgroundSize: 'contain',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat',
                                    opacity: 1,
                                }"
                            ></div>
                            <span>терминал перспективный</span>
                        </div>
                    </div>
                </div>
                <div :class="$style.blockTitle">Строения</div>
                <div :class="$style.blockContent">
                    <div :class="$style.row_2">
                        <div :class="[$style.cell, $style.cellSettlements]">
                            <div
                                class="citySvgWrapper getCityMarker_active"
                                :class="[
                                    $style.citySvgWrapper,
                                    $style.cityLegendRect,
                                ]"
                                :style="{
                                    borderColor: colors[35],
                                    backgroundColor: '#EAE5DF',
                                    opacity: 1,
                                }"
                            ></div>
                            Жилое здание
                        </div>
                        <div :class="[$style.cell, $style.cellSettlements]">
                            <div
                                class="citySvgWrapper getCityMarker_active"
                                :class="[
                                    $style.citySvgWrapper,
                                    $style.cityLegendRect,
                                ]"
                                :style="{
                                    borderColor: colors[20],
                                    backgroundColor: '#D3D1E2',
                                    opacity: 1,
                                }"
                            ></div>
                            Административное здание
                        </div>
                    </div>
                    <div :class="$style.row_2">
                        <div :class="[$style.cell, $style.cellSettlements]">
                            <div
                                class="citySvgWrapper getCityMarker_active"
                                :class="[
                                    $style.citySvgWrapper,
                                    $style.cityLegendRect,
                                ]"
                                :style="{
                                    borderColor: colors[20],
                                    backgroundColor: '#E6DED3',
                                    opacity: 1,
                                }"
                            ></div>
                            Хозяйственное здание
                        </div>
                        <div :class="[$style.cell, $style.cellSettlements]">
                            <div
                                class="citySvgWrapper getCityMarker_active"
                                :class="[
                                    $style.citySvgWrapper,
                                    $style.cityLegendRect,
                                ]"
                                :style="{
                                    borderColor: colors[20],
                                    backgroundImage: `url(${not_defined_fias_polygon_65_svg})`,
                                    backgroundSize: 'cover',
                                    opacity: 1,
                                }"
                            ></div>
                            Неизвестно
                        </div>
                    </div>
                </div>
                <div :class="$style.blockTitle">Медицинские учреждения</div>
                <div :class="$style.blockContent">
                    <div :class="$style.row_2">
                        <div :class="$style.cell">
                            <div
                                :class="[$style.gdsMarker, $style.marker]"
                                v-html="hospitalMarkerHtml"
                            />

                            Стационарные
                        </div>
                    </div>
                    <div :class="$style.row_2">
                        <div :class="$style.cell">
                            <div
                                :class="[$style.gdsMarker, $style.marker]"
                                v-html="medicalMarkerHtml"
                            />

                            Амбулаторные
                        </div>
                    </div>
                </div>

                <div :class="$style.blockTitle">
                    Дошкольные и общеобразовательные организации
                </div>
                <div :class="$style.blockContent">
                    <div :class="$style.row_2">
                        <div :class="$style.cell">
                            <div
                                :class="[$style.marker]"
                                v-html="schoolMarkerHtml"
                            />

                            Школы
                        </div>
                    </div>
                    <div :class="$style.row_2">
                        <div :class="$style.cell">
                            <div
                                :class="[$style.marker]"
                                v-html="kindergartenMarkerHtml"
                            />

                            Детские сады
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import GDS_OPERATING_ICON from '../../../shared/assets/mapIcons/gds_operating_22.svg'
import GDS_PROJECTED_ICON from '../../../shared/assets/mapIcons/gds_projected_22.svg'
import GDS_CLOSED_ICON from '../../../shared/assets/mapIcons/gds_closed_22.svg'
import GDS_CLOSED_ENTRANCE_ICON from '../../../shared/assets/mapIcons/gds_closed_entrance_22.svg'
import automobile_gas_filling_station_22 from '../../../shared/assets/mapIcons/automobile_gas_filling_station_22'
import gas_filling_station_22 from '../../../shared/assets/mapIcons/gas_filling_station_22'
import multi_fuel_filling_complex_22 from '../../../shared/assets/mapIcons/multi_fuel_filling_complex_22'
import cryogenic_gas_station_22 from '../../../shared/assets/mapIcons/cryogenic_gas_station_22'
import not_defined_fias_polygon_65_svg from '../../../shared/assets/mapIcons/not_defined_fias_polygon_65.svg'
import LNG_big from '../../../shared/assets/mapIcons/LNG_big.svg'
import LNG_big_perspective from '../../../shared/assets/mapIcons/LNG_big_perspective.svg'
import LNG_middle from '../../../shared/assets/mapIcons/LNG_middle.svg'
import LNG_middle_perspective from '../../../shared/assets/mapIcons/LNG_middle_perspective.svg'
import LNG_regasification from '../../../shared/assets/mapIcons/LNG_regasification.svg'
import LNG_regasification__perspective from '../../../shared/assets/mapIcons/LNG_regasification__perspective.svg'
import LNG_small from '../../../shared/assets/mapIcons/LNG_small.svg'
import LNG_small_perspective from '../../../shared/assets/mapIcons/LNG_small_perspective.svg'

import {
    IMG_AZS_COMPANY_NOVATEK,
    IMG_CITY_CAPITAL_DISTRICT,
    IMG_CITY_CAPITAL_REGION,
    IMG_GDS_STATUS_CLOSED,
    IMG_GDS_STATUS_LIMITED,
    IMG_GDS_STATUS_OPERATING,
    IMG_GDS_STATUS_PROJECTED,
    PIPELINE_TYPE_BRANCH,
    PIPELINE_TYPE_INTER_SETTLEMENT,
    PIPELINE_TYPE_MAJOR,
} from '@/shared/constants'

import { appModuleIds, COLORS } from '../../../shared/constants'
const colors = COLORS

export default {
    props: {
        colors: {
            type: Object,
            require: true,
        },
        getGdsMarkerElement: {
            type: Function,
            required: true,
        },
        getHospitalMarkerElement: {
            type: Function,
            required: true,
        },
        getMedicalMarkerElement: {
            type: Function,
            required: true,
        },
        getSchoolMarkerElement: {
            type: Function,
            required: true,
        },
        getKindergartenMarkerElement: {
            type: Function,
            required: true,
        },
        getPipelineSvgPattern: {
            type: Function,
            required: true,
        },
        getCityMarkerElement: {
            type: Function,
            required: true,
        },
        getAzsMarkerElement: {
            type: Function,
            required: true,
        },
        getGzsMarkerElement: {
            type: Function,
            required: true,
        },
        getMazkMarkerElement: {
            type: Function,
            required: true,
        },
    },

    setup() {
        return {
            GDS_OPERATING_ICON,
            GDS_PROJECTED_ICON,
            GDS_CLOSED_ICON,
            GDS_CLOSED_ENTRANCE_ICON,
            automobile_gas_filling_station_22,
            gas_filling_station_22,
            multi_fuel_filling_complex_22,
            cryogenic_gas_station_22,
            not_defined_fias_polygon_65_svg,
            LNG_big,
            LNG_big_perspective,
            LNG_middle,
            LNG_middle_perspective,
            LNG_regasification,
            LNG_regasification__perspective,
            LNG_small,
            LNG_small_perspective,
        }
    },

    data() {
        const svgFromTemplate = useId => {
            return `<svg
                viewBox="0 0 100 16"
                xmlns="http://www.w3.org/2000/svg"
                class="${this.$style.svgLine}"
            >
                <use href="#${useId}" />
            </svg>`
        }
        return {
            tableData: [
                {
                    operating: svgFromTemplate('pipelineMajorOperating'),
                    projected: svgFromTemplate('pipelineMajorProjected'),
                    underConstruction: this.getMultipleSvg(
                        this.getPipelineSvgPattern(PIPELINE_TYPE_MAJOR, 6, 2),
                        5
                    ),
                    type: 'Магистральные',
                },
                {
                    operating: svgFromTemplate('pipelineBranchOperating'),
                    projected: svgFromTemplate('pipelineBranchProjected'),
                    underConstruction: this.getMultipleSvg(
                        this.getPipelineSvgPattern(PIPELINE_TYPE_BRANCH, 4, 2),
                        6
                    ),
                    type: 'Отводы',
                },
                {
                    operating: svgFromTemplate('pipelineSettlementOperating'),
                    projected: svgFromTemplate('pipelineSettlementProjected'),
                    underConstruction: this.getMultipleSvg(
                        this.getPipelineSvgPattern(
                            PIPELINE_TYPE_INTER_SETTLEMENT,
                            3,
                            2
                        ),
                        7
                    ),
                    type: 'Межпоселковые',
                },
            ],
            gdsActiveMarkerHtml: '',
            gdsLimitedMarkerHtml: '',
            azsKrioDefaultMarkerHtml: '',
            gzsDefaultMarkerHtml: '',
            mazkDefaultMarkerHtml: '',
            hospitalMarkerHtml: '',
            medicalMarkerHtml: '',
            schoolMarkerHtml: '',
            kindergartenMarkerHtml: '',
            gdsPerspectiveMarkerHtml: '',
            gdsClosedMarkerHtml: '',
            majorOperatingPipelineHtml: '',
            branchOperatingPipelineHtml: '',
            settlementOperatingPipelineHtml: '',
            cityCapitalDistrictMarkerHtml: '',
            cityCapitalRegionMarkerHtml: '',
        }
    },

    computed: {
        ...mapState({
            isShowMapLegend: state => state.view.isShowMapLegend,
            activeRegionDensityPalette: state =>
                state.activeRegionDensityPalette.reverse(),
            loadingSourceUrls: state => state.loadingSourceUrls,
            mapZoom: state => state.map.zoom,
            layerMinZoom1: state => state.layerMinZoom[1],
            chosenAppModule: state => state.chosenAppModule,
        }),
        isPaletteShowTemplate() {
            return this.mapZoom < this.layerMinZoom1
        },
        activeRegionDensityPaletteCompute() {
            return this.isPaletteShowTemplate
                ? this.emptyPalette
                : this.activeRegionDensityPalette
        },
        appModuleIds() {
            return appModuleIds
        },
    },

    methods: {
        getMultipleSvg(template, count) {
            let result = template
            for (let i = 0; i < count - 1; i++) result += template
            return result
        },
        toggleMapLegend() {
            this.$commit('setViewPartialState', {
                isShowMapLegend: !this.isShowMapLegend,
            })
        },
    },

    created() {
        this.densityStrokeColors = [
            this.colors[16],
            this.colors[15],
            this.colors[14],
            this.colors[13],
            this.colors[12],
        ]
        this.emptyPalette = new Array(this.densityStrokeColors.length).fill('')
    },

    mounted() {
        this.gdsActiveMarkerHtml = this.getGdsMarkerElement(
            IMG_GDS_STATUS_OPERATING,
            25
        ).outerHTML

        this.gdsLimitedMarkerHtml = this.getGdsMarkerElement(
            IMG_GDS_STATUS_LIMITED,
            25
        ).outerHTML

        this.gdsPerspectiveMarkerHtml = this.getGdsMarkerElement(
            IMG_GDS_STATUS_PROJECTED,
            25
        ).outerHTML

        this.gdsClosedMarkerHtml = this.getGdsMarkerElement(
            IMG_GDS_STATUS_CLOSED,
            25
        ).outerHTML

        this.hospitalMarkerHtml = this.getHospitalMarkerElement(
            '',
            22
        ).outerHTML
        this.medicalMarkerHtml = this.getMedicalMarkerElement('', 22).outerHTML

        this.schoolMarkerHtml = this.getSchoolMarkerElement('', 22).outerHTML
        this.kindergartenMarkerHtml = this.getKindergartenMarkerElement(
            '',
            22
        ).outerHTML

        this.cityCapitalDistrictMarkerHtml = this.getCityMarkerElement(
            IMG_CITY_CAPITAL_DISTRICT,
            14
        )

        this.cityCapitalRegionMarkerHtml = this.getCityMarkerElement(
            IMG_CITY_CAPITAL_REGION,
            22
        )

        this.azsKrioDefaultMarkerHtml = this.getAzsMarkerElement(
            '',
            22
        ).outerHTML

        this.gzsDefaultMarkerHtml = this.getGzsMarkerElement('', 22).outerHTML
        this.mazkDefaultMarkerHtml = this.getMazkMarkerElement('', 22).outerHTML
    },
}
</script>

<style lang="stylus" module>
.cellContent
    display flex
    justify-content center
    align-items center
.table
    &:global(.el-table)
        --el-table-header-background-color transparent
        --el-color-white transparent
        background-color transparent
.operatingSvgWrapper
    width 30px
    height 10px
    > svg:not(:first-child)
        margin-left 2px
// .operatingSvgWrapper2
// mapLegendToggleTrigger_w_h = 54px
.mapLegendWrapper
    display flex
    flex-direction column
    // margin var(--indent1)
    // width mapLegendToggleTrigger_w_h
    // height mapLegendToggleTrigger_w_h
    // &.mapLegendWrapper_open
    //     width 985px
    //     height 455px
.mapLegendToggleTrigger
    // position absolute
    // right 0
    // top 0
    // z-index 1
    // background-color #fff
    // width mapLegendToggleTrigger_w_h
    // height mapLegendToggleTrigger_w_h
    border-radius 6px
    display flex
    justify-content center
    align-items center
    cursor pointer
    font-size 40px
    transition transform .3s

.mapLegendToggleTrigger_showLegend
    transform rotate(180deg)
.mapLegend
    display flex
    flex-direction column
    text-transform lowercase
    font-size 17px
    width 100%
    height 100%
    // :global
    //     .el-card
    //         background-color var(--color_1_opacity_1)
    //         backdrop-filter var(--backdropBlur1)
    //         height 100%
    //     .el-card__header
    //         padding 8px 10px
    &:global(.el-card)
        background-color var(--color_1_opacity_1)
        backdrop-filter var(--backdropBlur1)
        height 100%
        &:global(.is-always-shadow)
            box-shadow var(--boxShadow1)
    :global(.el-card__header)
        padding 8px 10px


.mapLegend__header
    display flex
    align-items center
.apLegend__title
    text-transform uppercase
    display flex
    justify-content center
    width 100%
    font-weight bold
    font-size 16px

.mapLegend__content
    display flex
    flex-direction column
    width 100%
.mapLegend__col
    display flex
    flex-direction column
    flex-shrink 0
.mapLegend__col1
    width 100%
.blockTitle
    text-transform uppercase
    margin 10px 0
    font-weight 600
    font-size 14px
.blockSubTitle
    text-transform uppercase
    margin 10px 0
    font-weight 600
    font-size 12px
    opacity .5
.blockContent
    display flex
    justify-content space-between
    position relative
    flex-wrap wrap
    //padding 0 20px 0 20px
.blockContentGds
    align-items flex-end
    flex-wrap wrap
.row_1, .row_2, .row_3
    display flex
    flex-direction column
.row_3
    width (100% / 4)
    // width (100% / 3)
    flex-shrink 0
.row_2
    flex-shrink 0
    padding-right 10px
    @media (min-width: 630px) {
      width (100% / 2)
    }
.row_5
    flex-shrink 0
    padding-right 10px
    width 100%
.row_wide
    display flex
    flex-wrap wrap
    flex-direction: row
    justify-content: space-between
    width: 100%;
    //padding 0 20px 0 20px
.cell
    margin 5px 0 5px 0
    white-space nowrap
    height 25px
    display flex
    align-items center

.cellEmptyDecorator
    &:before
        content ''
        width 22px
        height 3px
        background-color #333
        margin-top 6px
        opacity .3

.cellGds
    width 135px
.cellGdsHeader
    justify-content center
.citySvgWrapper
    display flex
    margin-right 10px
.cellSettlements
.cellWidthFull
    width 100%
    display: flex;
    //width 200px
.gdsLineCell
    justify-content center
    align-items center
.svgLine
    width 47px
    height 16px

.svgLine__line
    vector-effect non-scaling-stroke
    //stroke-linecap round

.svgLine__line_dashed_1
    stroke-dasharray 8 8
.svgLine__line_dashed_2
    stroke-dasharray 6 6
.svgLine__line_dashed_3
    stroke-dasharray 3 6

.svgLine__line_width_1
    stroke-width 2px
.svgLine__line_width_2
    stroke-width 4px
.svgLine__line_width_3
    stroke-width 6px
.svgLine__line_width_4
    stroke-width 16px

.marker
    height 22px
    width 22px
    margin-right 12px
    flex-shrink 0
    display flex
.abstractMarker
    width 16px
    height 16px
    border-radius 2px

// .gdsMarker

.cityLegendRect
    width 30px
    height 20px

.legendGdsMarker
    position relative !important

.settlementsMarker
    w_h = 14px
    border-radius 50%
    width w_h
    height w_h
    box-sizing content-box

.settlementsMarker_capital
    border 5px solid
.settlementsMarker_centers
    border 1px solid
    align-self center
    margin-top -4px

.loader
    position: absolute
    left: 50%
    top: 0
    transform: translate(-50%, 0)

.decoration
    width 0
    height 0

.cityArea
  font-family 'Noto Sans Regular'
  text-stroke 2px #fff
  &__connected
    color #005688
  &__disconnected
    color #D01011
</style>

<style lang="stylus">
.consumersMarker
    w_h = 12px
    width w_h
    height w_h
    border-radius 50%
    align-self center
    margin-top -4px
.consumersMarker_perspective
    border 1px solid
// .consumersMarker_active
//     background-color #000
</style>
