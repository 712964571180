import setPopupData from '@/entities/Map/general/model/setPopupData'
import toggleMoveWheelEvent from '@/entities/Map/general/model/initMap/addEvents/removeEventsActions/toggleMoveWheelEvent'

export default function (
    this: any,
    source,
    clickingLayerId,
    focusingLayerId,
    getHtmlContent,
    minLayerZoom?
) {
    console.log('minLayerZoom', minLayerZoom, clickingLayerId)

    let activePopup: any = null
    const sourceAndSourceLayer = {
        source,
    }
    let prevPopupId
    let focusedFeatureOptions: any = null

    let hoveredFeatureId = ''
    const clickHandler = event => {
        const feature = event.features?.[0]
        const featureId = feature.id
        console.log('clicked feature clickHandler', event.features?.[0])
        if (!feature) return
        console.log(
            'hoveredFeatureId',
            featureId,
            feature.layer['source-layer']
        )
        hoveredFeatureId = featureId
        focusedFeatureOptions = {
            id: hoveredFeatureId,
            sourceLayer: focusingLayerId,
            ...sourceAndSourceLayer,
        }
        this.$state.map.focusedFeatureOptions = focusedFeatureOptions
        this.map.setFeatureState(focusedFeatureOptions, { hover: true })

        console.log('setFeatureState', focusedFeatureOptions)
        activePopup = setPopupData.call(
            this,
            feature,
            event.lngLat,
            prevPopupId,
            getHtmlContent
        )
    }

    const mouseenterHandler = event => {
        const feature = event.features[0]
        const featureId = feature.id
        if (!featureId) return
        this.mapCanvasStyle.cursor = 'help'
        hoveredFeatureId = featureId
    }

    const mousemoveHandler = event => {
        const feature = event.features[0]
        if (!feature) return
        const featureId = feature.id
        if (!featureId) return
        this.mapCanvasStyle.cursor = 'help'
    }

    const mouseleaveHandler = () => {
        this.mapCanvasStyle.cursor = ''
    }

    // this.$store.watch((state) => state.map.zoom, zoomValue => {
    //     if (zoomValue >= minLayerZoom) {
    //         this.map.on('click', clickingLayerId, clickHandler)
    //         // this.map.on('mouseenter', hoveringLayerId, mouseenterHandler)
    //         this.map.on('mouseleave', clickingLayerId, mouseleaveHandler)
    //         this.map.on('mousemove', clickingLayerId, mousemoveHandler)
    //     } else {
    //         this.map.off('click', clickingLayerId, clickHandler)
    //         // this.map.on('mouseenter', hoveringLayerId, mouseenterHandler)
    //         this.map.off('mouseleave', clickingLayerId, mouseleaveHandler)
    //         this.map.off('mousemove', clickingLayerId, mousemoveHandler)
    //     }
    // })

    this.map.on('click', clickingLayerId, clickHandler)
    // this.map.on('mouseenter', hoveringLayerId, mouseenterHandler)
    this.map.on('mouseleave', clickingLayerId, mouseleaveHandler)
    this.map.on('mousemove', clickingLayerId, mousemoveHandler)

    this.changeFocusedRegionHandler(() => {
        // this.map.off('click', hoveringLayerId, clickHandler)
        // this.map.off('mouseenter', hoveringLayerId, mouseenterHandler)
        // this.map.off('mouseleave', hoveringLayerId, mouseleaveHandler)
        // this.map.off('mousemove', hoveringLayerId, mousemoveHandler)

        activePopup?.remove?.()
        this.map.setFeatureState(
            {
                id: hoveredFeatureId,
                sourceLayer: focusingLayerId,
                ...sourceAndSourceLayer,
            },
            { hover: false }
        )
    })
}
