import { stepsListIndexes } from '@/shared/constants'

export const stepsMenuRoutes = [
    {
        id: stepsListIndexes.regionsStepIndex,
        component: () => import('@/pages/RegionsView/index.vue'),
    },
    {
        id: stepsListIndexes.districtsStepIndex,
        component: () => import('@/pages/DistrictsView/index.vue'),
    },
    {
        id: stepsListIndexes.districtInfoStepIndex,
        component: () => import('@/pages/DistrictInfoView/index.vue'),
    },
    {
        id: stepsListIndexes.gdsInfoStepIndex,
        component: () => import('@/pages/GdsView/index.vue'),
    },
    {
        id: stepsListIndexes.gasPlanStepIndex,
        component: () => import('@/pages/GasificationPlan/index.vue'),
    },
]
