import { ZOOM_HIERARCHY_MIN_LEVEL } from '@/shared/constants'
import {
    stepListIndexesToChangeByZoom,
    stepListIndexesToChangeByZoomMapByAppModules,
} from '@/widgets/MenuPanel/ui/TimeLine/methods/stepsActions/config/stepListIndexesToChangeByZoom.config'

function setStepActiveByMinZoom(
    this: any,
    currZoomValue: number,
    stepIndex: number | string
) {
    if (
        currZoomValue >= ZOOM_HIERARCHY_MIN_LEVEL[stepIndex] &&
        !this.$state.view.isStepsListLocked
    ) {
        this.$dispatch('setStepActiveId', stepIndex)
    }
}

export default function (this: any, currZoomValue) {
    const activeStepListIndexesToCheck =
        stepListIndexesToChangeByZoomMapByAppModules[
            this.$state.chosenAppModule.id
        ] || stepListIndexesToChangeByZoom

    activeStepListIndexesToCheck.forEach(stepIndex => {
        setStepActiveByMinZoom.call(this, currZoomValue, stepIndex)
    })
}
