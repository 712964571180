import { stepListIndexesToClearMap } from '@/widgets/MenuPanel/ui/MainMenuView/methods/menuGoBack.config'

export default function (this: any) {
    if (
        this.$state.view.stepPrevId >= this.$state.view.stepActiveId &&
        this.$state.view.stepActiveId > 1
    ) {
        this.$dispatch('setStepActiveId', this.$state.view.stepActiveId - 1)
    } else {
        this.$dispatch('setStepActiveId', this.$state.view.stepPrevId)
    }

    if (stepListIndexesToClearMap.includes(this.$state.view.stepActiveId)) {
        this.$commit('clearMap', { context: this.appContext, event: null })
    }
}
