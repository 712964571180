import { setPartialState } from '@/shared/lib'
export default {
    state: {
        isKeycloakAuth: false,
        keycloakToken: '',
        keycloakTokenParsed: null,
        allowAccess: true,
    },
    mutations: {
        setUserPartialState: setPartialState,
    },
    actions: {},
    modules: {},
}
