import maplibregl from 'maplibre-gl'

export default function (this: any, popupProperties) {
    if (!this.popupMap.properties) return
    this.$state.map.popupMap.popupInstance?.remove()

    this.$state.map.popupMap.popupInstance = new maplibregl.Popup()

    this.$state.map.popupMap.popupInstance
        .setLngLat(this.popupMap.lngLat)
        .setDOMContent(this.$refs.popupMap.$el)
        .setMaxWidth('300px')
        // .on('close', () => this.$state.map.popupMap = null)
        .addTo(this.map)
}
