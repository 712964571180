import {
    stepsListIndexesChangeByZoomLocked,
    stepsListIndexesChangeByZoomLockedByAppModules,
} from '@/shared/constants'

export default function (this: any, isAutomatic = true) {
    this.$state.view.isStepsListLocked =
        isAutomatic ||
        stepsListIndexesChangeByZoomLockedByAppModules[
            this.$state.chosenAppModule?.id
        ].includes(this.$state.view.stepActiveId)
}
