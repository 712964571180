// export const GDS_STATUS_CLOSED = 'closed'
// export const GDS_STATUS_LIMITED = 'limited'
// export const GDS_STATUS_OPERATING = 'Gas_Distribution_Station'
// export const GDS_STATUS_PROJECTED = 'Gas_Distribution_Station_Perspective'

import { statsProposalsTypes } from '@/app/providers/store/modules/view/types'

export enum tileServerType {
    tileServerSergStyle = 'tileServerSergStyle',
    tileServerRasterStyle = 'tileServerRasterStyle',
}
export const TILE_SERVER_SERG_URL =
    'http://192.168.245.196:8162/static/styles/style.json'
export const INFRASTRUCTURE_URL =
    'http://192.168.245.196:8162/infrastructure/styles'
export const MAIN_MAP_STYLE_URL = 'http://192.168.245.196:8162/style.json'
export const MAIN_MAP_STYLE_URL_PROD = 'https://gmap.cdu.ru/tile/style.json'
export const tileServerTypes = [
    tileServerType.tileServerSergStyle,
    tileServerType.tileServerRasterStyle,
]

export const SOCIAL_OBJECTS_LAYER_IDS = [
    'social_organizations_hospital',
    'social_organizations_medical',
    'school_organizations',
]

export const GDS_STATUS_CLOSED = 'CLOSED'
export const GDS_STATUS_LIMITED = 'OFF_LIMITS'
export const GDS_STATUS_OPERATING = 'OPERATING'
export const GDS_STATUS_PROJECTED = 'PROJECTED'

export const GDS_STATUS_ICON_COLORS = {
    [GDS_STATUS_CLOSED]: '#f7f7f7',
    [GDS_STATUS_OPERATING]: '#465fa9',
    [GDS_STATUS_LIMITED]: '#999999FF',
    [GDS_STATUS_PROJECTED]: '#f7f7f7',
}

export const ICON_ZOOM_LEVELS: number[] = [11, 15, 22]

export enum AZS_TYPE {
    STATIONS = 'STATIONS',
    KRIO = 'KRIO',
    AGZS = 'AGZS',
    MAZK = 'MAZK',
}
export enum VENDOR_TYPE {
    NOVATEK = 'NOVATEK',
}

const IMG_GDS_PREFIX = 'IMG_GDS_'
export const IMG_GDS_STATUS_CLOSED = IMG_GDS_PREFIX + GDS_STATUS_CLOSED
export const IMG_GDS_STATUS_LIMITED = IMG_GDS_PREFIX + GDS_STATUS_LIMITED
export const IMG_GDS_STATUS_OPERATING = IMG_GDS_PREFIX + GDS_STATUS_OPERATING
export const IMG_GDS_STATUS_PROJECTED = IMG_GDS_PREFIX + GDS_STATUS_PROJECTED

const IMG_AZS_PREFIX = 'IMG_AZS_'
const IMG_GZS_PREFIX = 'IMG_GZS_'
const IMG_MAZK_PREFIX = 'IMG_MAZK_'
export const AZS_COMPANY_NOVATEK = 'NOVATEK'
export const IMG_AZS_COMPANY_NOVATEK = IMG_AZS_PREFIX + AZS_COMPANY_NOVATEK
export const IMG_GZS_COMPANY_NOVATEK = IMG_GZS_PREFIX + AZS_COMPANY_NOVATEK
export const IMG_MAZK_COMPANY_NOVATEK = IMG_MAZK_PREFIX + AZS_COMPANY_NOVATEK

export const PIPELINE_STATUS_PROJECTED = 'PR'
export const PIPELINE_STATUS_UNDER_CONSTRUCTION = 'UC'
export const PIPELINE_STATUS_OPERATING = 'OP'
export const PIPELINE_TYPE_MAJOR = 'MAJOR'
export const PIPELINE_TYPE_BRANCH = 'BRANCH'
export const PIPELINE_TYPE_INTER_SETTLEMENT = 'INTER_SETTLEMENT'
const IMG_PIPELINE_PREFIX = 'IMG_CITY_'
export const IMG_PIPELINE_STATUS_UNDER_CONSTRUCTION =
    IMG_PIPELINE_PREFIX + PIPELINE_STATUS_UNDER_CONSTRUCTION

export const CONSUMER_STATUS_PROJECTED = 'PROJECTED'
export const CONSUMER_STATUS_OPERATING = 'OPERATING'

export const CITY_CAPITAL_DISTRICT = 'DISTRICT'
export const CITY_CAPITAL_REGION = 'REGION'
export const CITY_CAPITAL_OTHER = 'OTHER'
export const CITY_STATUS_CONNECTED = 'CONNECTED'
export const CITY_STATUS_DISCONNECTED = 'DISCONNECTED'
export const CITY_STATUS_PERSPECTIVE = 'PERSPECTIVE'
const IMG_CITY_PREFIX = 'IMG_CITY_'
export const IMG_CITY_CAPITAL_DISTRICT = IMG_CITY_PREFIX + CITY_CAPITAL_DISTRICT
export const IMG_CITY_CAPITAL_REGION = IMG_CITY_PREFIX + CITY_CAPITAL_REGION

export const IMG_CITY_OTHER_CONNECTED = `${
    IMG_CITY_PREFIX + CITY_CAPITAL_OTHER
}_${CITY_STATUS_CONNECTED}`
export const IMG_CITY_OTHER_DISCONNECTED = `${
    IMG_CITY_PREFIX + CITY_CAPITAL_OTHER
}_${CITY_STATUS_DISCONNECTED}`
export const IMG_CITY_OTHER_PERSPECTIVE = `${
    IMG_CITY_PREFIX + CITY_CAPITAL_OTHER
}_${CITY_STATUS_PERSPECTIVE}`

export const CONSUMERS_TYPE = 'CONSUMERS_TYPE'
export const GDS_TYPE = 'GDS_TYPE'
export const PIPELINE_MAJOR_TYPE = 'PIPELINE_MAJOR_TYPE'
export const PIPELINE_BRANCH_TYPE = 'PIPELINE_BRANCH_TYPE'
export const PIPELINE_SETTLEMENT_TYPE = 'PIPELINE_SETTLEMENT_TYPE'
export const PIPELINE_CONSUMER = 'PIPELINE_CONSUMER'

export const GDS_DATA_CITY_AREA = 'GDS_DATA_CITY_AREA'
export const GDS_DATA_GDS_INFO = 'GDS_DATA_GDS_INFO'

export const PROPOSALS_STATUSES_COLORS = {
    applicationGeneralStatus: {
        STATUS_1: 'rgba(54,116,204,0.89)',
        STATUS_2: 'rgba(118,255,239,0.89)',
        STATUS_3: 'rgba(118,218,255,0.89)',
        STATUS_4: 'rgba(180,118,255,0.89)',
        STATUS_5: 'rgba(118,161,255,0.89)',
        STATUS_6: 'rgba(118,120,255,0.89)',
        STATUS_7: 'rgba(118,193,255,0.89)',
        STATUS_11: 'rgba(81,76,255,0.89)',
    },
    statusOfTheContract: {
        CONTR_STATUS_1: 'rgba(255,118,175,0.89)',
        CONTR_STATUS_2: 'rgba(255,80,80,0.89)',
        CONTR_STATUS_3: 'rgba(255,61,173,0.89)',
        CONTR_STATUS_4: 'rgba(199,123,59,0.89)',
        CONTR_STATUS_5: 'rgba(255,127,58,0.89)',
        CONTR_STATUS_6: 'rgba(175,56,7,0.89)',
    },
    constrStatusWithin: {
        CONSTR_STATUS_1: 'rgba(246,197,35,0.89)',
        CONSTR_STATUS_2: 'rgba(255,212,118,0.89)',
        CONSTR_STATUS_3: 'rgba(255,237,118,0.89)',
        CONSTR_STATUS_4: 'rgba(213,184,69,0.89)',
        CONSTR_STATUS_5: 'rgba(200,255,118,0.89)',
        CONSTR_STATUS_6: 'rgba(174,255,52,0.89)',
    },
}

export const COLORS = {
    1: 'rgb(89, 89, 89)',
    2: 'rgb(143,143,143)',
    3: 'rgb(51, 51, 51)',
    '3_opacity1': 'rgba(51, 51, 51, 0.75)',
    4: 'rgb(71,97,167)',
    // 4: '#007ac2',
    5: 'rgb(103,125,183)',
    6: 'rgb(106,105,104)',
    7: 'rgb(134,208,243)',
    8: 'rgb(224,242,252)',
    9: '#fff',
    10: 'rgb(250,235,72)',
    11: 'rgb(234,234,234)',
    12: 'rgb(253,243,240)',
    13: 'rgb(250,226,219)',
    14: 'rgb(246,197,181)',
    15: 'rgb(240,162,139)',
    16: 'rgb(219,128,102)',
    17: 'rgba(255,0,255, .4)',
    18: '#0f0',
    19: '#ddeeff',

    20: '#f00',

    // 21: '#FBF46D',
    // 22: '#B4FE98',
    // 23: '#77E4D4',
    // 24: '#998CEB',
    // 25: '#FF5F7E',

    // 21: '#7BD342',
    21: 'rgb(126,209,79)',
    22: '#42C4D3',
    23: '#ECCC2A',
    24: '#707070',
    // 25: '#D35642',
    25: 'rgb(234,140,112)',

    26: '#E2E2E2',
    // 27: '#e4e4e4',
    // 27: '#d3cdfd',
    27: '#CFCFCF',
    // 27: '#d7e0f6',
    28: '#dcdcdc',
    29: '#D3D3D3',
    30: '#4E951A',
    31: '#D52727',
    32: '#C8EBB5',
    33: '#2A79AC',
    34: 'rgb(103,72,176)',
    35: 'rgba(148,125,206,0.63)',
    36: 'rgba(255,0,21,0.63)',
    37: '#005688',
    38: '#0D7FC3',
    39: '#ed2756',
    40: '#005688',
    41: '#528ec4',
}

export enum cityAreaStatusColors {
    CONNECTED = '#005688',
    DISCONNECTED = '#D01011',
}
export enum cityAreaStatusMapColors {
    CONNECTED = 'rgba(0,109,173,0.43)',
    DISCONNECTED = 'rgba(238,12,13,0.43)',
}
// export const LAYER_MIN_ZOOM1 = 6.565
export const LAYER_MIN_ZOOM1 = 4
export const LAYER_MIN_ZOOM2 = 12
export const LAYER_MIN_ZOOM3 = 10.5
export const MAP_MAX_ZOOM = 12.4
export const MAP_MAX_ZOOM1 = 16
export const MAP_MAX_ZOOM24 = 24
// export const LAYER_MIN_ZOOM3 = 5

export const TILE_DISTRICT_SOURCE_LAYER = 'district'

// export const MOBILE_VIEW_START_WIDTH = 630
export const MOBILE_VIEW_START_WIDTH = 912

export const lifetimeProposalsMs = 1000 * 86400

export const popupBackgroundColorsBySourceLayer = {
    social_organizations: 'rgba(253,141,160,0.29)',
    school_organizations: 'rgba(150,150,234,0.29)',
}

export const MAP_CENTER_COORDS = [58.920063, 58.70882]

export const zoomLevelToLayerAvailability = {
    district: 8,
}

export const appModuleIds = {
    gasification: 'gasification',
    spg: 'spg',
    gasificationPlan: 'gasificationPlan',
    AGNKS: 'AGNKS',
}

export const stepsListIndexes = {
    regionsStepIndex: '1',
    districtsStepIndex: '2',
    districtInfoStepIndex: '3',
    gdsInfoStepIndex: '3.1',
    settlementsStepIndex: '3.2',
    gasPlanStepIndex: '3.3',
    agnksInfoStepIndex: '3.4',
}

export const ZOOM_HIERARCHY_MIN_LEVEL = {
    [`${stepsListIndexes.regionsStepIndex}`]: LAYER_MIN_ZOOM1,
    [`${stepsListIndexes.districtsStepIndex}`]: 6,
    [`${stepsListIndexes.districtInfoStepIndex}`]: 8,
    [`${stepsListIndexes.settlementsStepIndex}`]: 14,
}
export const proposalsStatsHierarchy = {
    [`${stepsListIndexes.regionsStepIndex}`]: statsProposalsTypes.regions,
    [`${stepsListIndexes.districtsStepIndex}`]: statsProposalsTypes.districts,
    [`${stepsListIndexes.districtInfoStepIndex}`]: 12,
}

export const proposalsDistrictColorTypes = {
    all_proposal_count: '#5d5d5d',
    finished_count: '#4cd964',
    in_work_count: '#ff9500',
    no_data_count: '#5ac8fa',
    rejected_count: '#ff3b30',
}
export const stepsListIndexesChangeByZoomLocked = [
    stepsListIndexes.gdsInfoStepIndex,
    stepsListIndexes.gasPlanStepIndex,
    stepsListIndexes.settlementsStepIndex,
    stepsListIndexes.agnksInfoStepIndex,
]
export const stepsListIndexesChangeByZoomLockedByAppModules: {
    [key in keyof typeof appModuleIds]?: string[]
} = {
    [appModuleIds.AGNKS]: stepsListIndexesChangeByZoomLocked,
    [appModuleIds.gasification]: stepsListIndexesChangeByZoomLocked,
    [appModuleIds.gasificationPlan]: stepsListIndexesChangeByZoomLocked,
    [appModuleIds.spg]: stepsListIndexesChangeByZoomLocked,
}

export const spriteSizes = ['11', '15', '22']

export enum planViewTitles {
    regions = 'регионов',
    districts = 'районов',
}

export enum GENERAL_STATUSES_MAPPING_RU {
    OPERATING = 'В эксплуатации',
    UNDER_CONSTRUCTION = 'В разработке',
}

export const plansAccentColor = '#FF740A'
