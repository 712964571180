<template>
    <el-autocomplete
        ref="autocomleteRef"
        :class="[
            $style.autocomplete,
            isMenuOpen && $style.autocomplete_menuOpen,
        ]"
        v-model="state"
        :clearable="false"
        :fetch-suggestions="querySearchAsync"
        :popper-class="$style.autocompletePopover"
        placeholder="Поиск мест и адресов"
        @select="handleSelect"
    >
        <template #suffix>
            <div :class="$style.searchLoupe">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                >
                    <g id="Find_icon" transform="translate(-342 -42)">
                        <rect
                            id="Rectangle_5"
                            data-name="Rectangle 5"
                            width="24"
                            height="24"
                            transform="translate(342 42)"
                            fill="none"
                        />
                        <path
                            id="Find_icon-2"
                            data-name="Find_icon"
                            d="M15.408,15.308a6.032,6.032,0,1,0-8.53,0A6.032,6.032,0,0,0,15.408,15.308ZM16.83,5.356A8.044,8.044,0,0,1,17.5,15.976l.045.042,4.265,4.265a1.005,1.005,0,0,1-1.422,1.422L16.119,17.44l-.043-.045a8.043,8.043,0,1,1,.753-12.04Z"
                            transform="translate(341.9 41)"
                            fill="#4d4d4d"
                            fill-rule="evenodd"
                        />
                    </g>
                </svg>
            </div>

            <div :class="$style.searchBtn" @click="clear">
                <svg
                    v-if="!state && !isFill"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    @click.stop="toggleMenu"
                >
                    <g id="Find_icon" transform="translate(366 66) rotate(180)">
                        <rect
                            id="Rectangle_5"
                            data-name="Rectangle 5"
                            width="24"
                            height="24"
                            transform="translate(342 42)"
                            fill="none"
                        />
                        <path
                            id="Path_33"
                            data-name="Path 33"
                            d="M6.544,1.8a1.521,1.521,0,0,1,2.386,0l4.082,5.156a1.521,1.521,0,0,1-1.193,2.466H3.655A1.521,1.521,0,0,1,2.462,6.955Z"
                            transform="translate(359.71 45.764) rotate(90)"
                            fill="#707070"
                        />
                    </g>
                </svg>
                <svg
                    v-else
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                >
                    <g
                        id="Group_195"
                        data-name="Group 195"
                        transform="translate(-1848 -225)"
                    >
                        <rect
                            id="Rectangle_8"
                            data-name="Rectangle 8"
                            width="24"
                            height="24"
                            transform="translate(1848 225)"
                            fill="none"
                        />
                        <g
                            id="Group_196"
                            data-name="Group 196"
                            transform="translate(1542 183)"
                        >
                            <line
                                id="Line_16"
                                data-name="Line 16"
                                x2="11"
                                y2="11"
                                transform="translate(312.5 48.5)"
                                fill="none"
                                stroke="#4d4d4d"
                                stroke-linecap="round"
                                stroke-width="3"
                            />
                            <line
                                id="Line_17"
                                data-name="Line 17"
                                y1="11"
                                x2="11"
                                transform="translate(312.5 48.5)"
                                fill="none"
                                stroke="#4d4d4d"
                                stroke-linecap="round"
                                stroke-width="3"
                            />
                        </g>
                    </g>
                </svg>
            </div>
        </template>

        <template #default="{ item: suggestion }">
            <!-- <div :class="$style.suggestion">
                {{ regionFeature }}
                {{ regionFeature?.properties?.name }}
            </div> -->
            <div :class="$style.suggestionWrapper">
                <div
                    v-html="suggestion.searchResult"
                    :class="$style.suggestion"
                />
                <div :class="$style.suggestionRegion" v-if="suggestion.parent">
                    {{ suggestion.parent }}
                </div>
            </div>

            <!-- {{ regionFeature?.properties?.name }}  -->
        </template>
    </el-autocomplete>
</template>

<script>
import { mapState } from 'vuex'

import { LAYER_MIN_ZOOM2 } from '@/shared/constants'

export default {
    components: {},
    props: {
        isFill: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            state: '',
        }
    },
    computed: {
        ...mapState({
            isMenuOpen: state => state.view.isMenuOpen,
            mapCenter: state => state.map.center,
        }),
    },
    methods: {
        toggleMenu() {
            this.$commit('setViewPartialState', {
                isMenuOpen: !this.isMenuOpen,
            })
        },
        clear() {
            if (this.state) this.$refs.autocomleteRef.inputRef.clear()
            else this.$emit('clear')
        },
        filter(queryString) {
            return regionFeature => {
                return regionFeature?.properties?.name
                    ?.toLowerCase()
                    .includes(queryString.toLowerCase())
            }
        },
        handleSelect(suggestion) {
            const suggestionCenter = suggestion?.center
            console.log('suggestion', suggestion)
            if (!suggestionCenter) return
            const isDistrict = !!suggestion.parent
            // const suggestionCenter = getCenterOfCoordinates(coordinates)
            const zoom = isDistrict ? LAYER_MIN_ZOOM2 : 6
            const convertedSuggestionCenter = suggestionCenter
            this.$map.flyTo({
                center: convertedSuggestionCenter,
                zoom,
                speed: 1.9,
            })
        },
        querySearchAsync(queryString, cb) {
            const returnEmpty = () => cb([])
            // console.log('serachQuery')
            try {
                if (!queryString) return returnEmpty()

                this.$axios
                    .get(
                        `/search?text=${queryString}&center=${this.mapCenter[0]},${this.mapCenter[1]}`
                    )
                    .then(response => {
                        const result = response?.data?.result
                        if (!result) return returnEmpty()

                        cb(result)
                    })
                    .catch(error => {
                        console.error(error)
                    })
            } catch (error) {
                console.error(error)
                return returnEmpty()
            }
        },
    },
}
</script>

<style lang="stylus" module>
.autocomplete
    --el-fill-color-blank: #F6F6F6
    width 100%

    :global(.el-input__wrapper)
        box-shadow: none
        border-radius 10px
        &.is-focus
            box-shadow: none
    :global(.el-input__prefix)
        left 5px
    :global(.el-input__prefix-inner)
        align-items center
    &.autocomplete_menuOpen
        :global(.el-input__inner)
            &:not(:focus):not(:hover)
                border-color var(--f7-segmented-raised-divider-color)
    &:not(.autocomplete_menuOpen)
        box-shadow var(--f7-button-raised-box-shadow)
        border-color transparent
        :global(.el-input__inner)
            border-color transparent
    &:global(.el-autocomplete)
        //border-radius var(--f7-button-border-radius)
        border-radius var(--f7-button-border-radius)
    :global(.el-input__inner)
        height var(--f7-button-height)
        //padding-left 36px
        //padding 0 var(--indent2) 0 35px
        &::placeholder
            color var(--el-text-color-secondary)

.autocompletePopover
    &:global(.el-autocomplete__popper.el-popper[role=tooltip])
        box-shadow var(--f7-button-raised-box-shadow)
        border none
        transform translateY(3px)


.suggestionWrapper
    display flex
    flex-direction column
    line-height 18px
    padding var(--indent3) 0
.suggestion
    > :global(*)
        // background #D3D3D3
        // padding 1px 2px 3px 2px
        // border-radius 3px
        // margin 0 1px
        font-weight bold
.suggestionRegion
    color #999
    white-space pre-wrap

.searchBtn, .searchLoupe
    display flex
    align-items center
    justify-content center
.searchLoupe
    width 20px
    height 20px
.searchBtn
    cursor pointer
    margin-left 10px
    padding-left 2px
    border-left 1px solid #9F9F9F
</style>
