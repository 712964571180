import setAsyncLayersAGNKS from '@/entities/Map/AGNKS/model/setAsyncLayersAGNKS'
import agnksMapStyle from '@/entities/Map/AGNKS/model/getMapStyle/agnksMapStyle'

export default async function (this: any) {
    await this.setMapStyle(agnksMapStyle)
    await this.addRegions()
    await this.setActiveRegion(this.focusedRegionNumber)

    await this.$dispatch('AGNKSEntity/loadAll', {
        loadFn: this.loadSource,
    })
    await setAsyncLayersAGNKS.call(this, this.focusedRegionNumber)
}
