import { stepsListIndexes } from '@/shared/constants'
import { statsProposalsTypes } from '@/app/providers/store/modules/view/types'

const mapStatsObject = (subject: string, statOfSubject) => {
    return {
        subject: subject,
        data: {
            default: {
                name: 'Всего заявок',
                value: statOfSubject?.all_proposal_count,
            },
            finished: {
                name: 'Завершенных',
                value: statOfSubject?.finished_count,
            },
            in_work: {
                name: 'В работе',
                value: statOfSubject?.in_work_count,
            },
            rejected: {
                name: 'Отклоненных',
                value: statOfSubject?.rejected_count,
            },
        },
    }
}

export function mapFocusedProposalsStatsSubject_gas(this: any, state) {
    switch (this.$state.view.stepActiveId) {
        case stepsListIndexes.regionsStepIndex:
            return mapStatsObject(
                this.focusedRegionName,
                this.statsProposals[statsProposalsTypes.regions]?.[
                    this.focusedRegionNumber.toString().padStart(2, '0')
                ]
            )
        case stepsListIndexes.districtsStepIndex:
            return mapStatsObject(
                this.focusedRegionName,
                this.statsProposals[statsProposalsTypes.regions]?.[
                    this.focusedRegionNumber.toString().padStart(2, '0')
                ]
            )
        case stepsListIndexes.districtInfoStepIndex:
            return mapStatsObject(
                this.focusedDistrictName,
                this.statsProposals[statsProposalsTypes.districts]?.[
                    this.focusedDistrictNumber
                ]
            )
        default:
            return state.focusedProposalsStatsSubject
    }
}
