import {
    AGNKS_API_PATHS,
    AGNKS_API_SOURCE_PATH,
} from '@/entities/ANGKS/api/config'
import {
    AGNKSEntityState,
    AGNKSStats,
    ANGKSStatuses,
} from '@/entities/ANGKS/model/AGNKSEntity.types'
import { RootState } from '@/app/providers/store/types/rootTypes'
import { MapEntityStoreModule } from '@/app/providers/store/modules/mapEntity/mapEntity'

export const AGNKSEntity = new MapEntityStoreModule<
    AGNKSEntityState,
    RootState
>(
    {
        namespaced: true,

        state: {
            activeObject: null,
        },

        getters: {
            statsRussia: (state: any): AGNKSStats['russia'] => {
                const russiaFeatures = state.featureCollections.all?.features

                if (!russiaFeatures) return null

                const russiaStats = {
                    all_proposal_count: russiaFeatures.length,
                    finished_count: 0,
                    in_work_count: 0,
                }
                russiaFeatures.forEach(feature => {
                    if (feature.properties.status === ANGKSStatuses.OPERATING) {
                        russiaStats.finished_count += 1
                    }
                    if (
                        feature.properties.status ===
                        ANGKSStatuses.UNDER_CONSTRUCTION
                    ) {
                        russiaStats.in_work_count += 1
                    }
                })

                return russiaStats
            },
            statsRegion: (state: any): AGNKSStats['region'] => {
                const features = state.featureCollections.regions?.features

                if (!features) return null

                const stats = {
                    all_proposal_count: features.length,
                    finished_count: 0,
                    in_work_count: 0,
                }
                features.forEach(feature => {
                    if (feature.properties.status === ANGKSStatuses.OPERATING) {
                        stats.finished_count += 1
                    }
                    if (
                        feature.properties.status ===
                        ANGKSStatuses.UNDER_CONSTRUCTION
                    ) {
                        stats.in_work_count += 1
                    }
                })

                return stats
            },
        },
    },
    {
        source_path: AGNKS_API_SOURCE_PATH,
        api_paths: AGNKS_API_PATHS,
    }
)
