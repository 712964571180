<template>
    <div
        :class="[
            $style.mapLegendWrapper,
            isShowMapLegend && $style.mapLegendWrapper_open,
        ]"
    >
        <div :class="$style.mapLegend__content">
            <div :class="[$style.mapLegend__col, $style.mapLegend__col2]">
                <div :class="$style.blockContent">
                    <Loader
                        v-if="loadingSourceUrls && loadingSourceUrls.length"
                        :class="$style.loader"
                        color="#777"
                    />
                    <div :class="$style.row_2">
                        <div
                            v-for="(
                                densityRange, index
                            ) of activeRegionDensityPaletteCompute"
                            :key="index"
                            :class="[$style.cell, 'justifyCenter']"
                        >
                            <svg
                                viewBox="0 0 100 16"
                                xmlns="http://www.w3.org/2000/svg"
                                :class="[$style.svgLine]"
                            >
                                <line
                                    :class="[
                                        $style.svgLine__line,
                                        $style.svgLine__line_width_4,
                                    ]"
                                    x1="20"
                                    y1="8"
                                    x2="80"
                                    y2="8"
                                    :stroke="densityStrokeColors[index]"
                                />
                            </svg>
                        </div>
                    </div>
                    <div :class="$style.row_2">
                        <div
                            v-for="(
                                densityRange, index
                            ) of activeRegionDensityPaletteCompute"
                            :key="index"
                            :class="[
                                $style.cell,
                                isPaletteShowTemplate &&
                                    $style.cellEmptyDecorator,
                            ]"
                        >
                            {{ densityRange }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

import Loader from '@/features/general/Loader'
import {
    IMG_CITY_CAPITAL_DISTRICT,
    IMG_CITY_CAPITAL_REGION,
    IMG_GDS_STATUS_CLOSED,
    IMG_GDS_STATUS_LIMITED,
    IMG_GDS_STATUS_OPERATING,
    IMG_GDS_STATUS_PROJECTED,
    PIPELINE_TYPE_BRANCH,
    PIPELINE_TYPE_INTER_SETTLEMENT,
    PIPELINE_TYPE_MAJOR,
} from '@/shared/constants'

export default {
    components: {
        Loader,
    },
    props: {
        colors: {
            type: Object,
            require: true,
        },
        getGdsMarkerElement: {
            type: Function,
            required: true,
        },
        getPipelineSvgPattern: {
            type: Function,
            required: true,
        },
        getCityMarkerElement: {
            type: Function,
            required: true,
        },
    },

    data() {
        return {
            gdsActiveMarkerHtml: '',
            gdsLimitedMarkerHtml: '',
            gdsPerspectiveMarkerHtml: '',
            gdsClosedMarkerHtml: '',
            majorOperatingPipelineHtml: '',
            branchOperatingPipelineHtml: '',
            settlementOperatingPipelineHtml: '',
            cityCapitalDistrictMarkerHtml: '',
            cityCapitalRegionMarkerHtml: '',
        }
    },

    computed: {
        ...mapState({
            isShowMapLegend: state => state.view.isShowMapLegend,
            activeRegionDensityPalette: state =>
                state.activeRegionDensityPalette.reverse(),
            loadingSourceUrls: state => state.loadingSourceUrls,
            mapZoom: state => state.map.zoom,
            layerMinZoom1: state => state.layerMinZoom[1],
        }),
        isPaletteShowTemplate() {
            return this.mapZoom < this.layerMinZoom1
        },
        activeRegionDensityPaletteCompute() {
            return this.isPaletteShowTemplate
                ? this.emptyPalette
                : this.activeRegionDensityPalette
        },
        majorOperatingPipelineHtmlComputed() {
            return this.getMultipleSvg(this.majorOperatingPipelineHtml, 5)
        },
        branchOperatingPipelineHtmlComputed() {
            return this.getMultipleSvg(this.branchOperatingPipelineHtml, 6)
        },
        settlementOperatingPipelineHtmlComputed() {
            return this.getMultipleSvg(this.settlementOperatingPipelineHtml, 7)
        },
    },

    methods: {
        getMultipleSvg(template, count) {
            let result = template
            for (let i = 0; i < count - 1; i++) result += template
            return result
        },
        toggleMapLegend() {
            this.$commit('setViewPartialState', {
                isShowMapLegend: !this.isShowMapLegend,
            })
        },
    },

    created() {
        this.densityStrokeColors = [
            this.colors[16],
            this.colors[15],
            this.colors[14],
            this.colors[13],
            this.colors[12],
        ]
        this.emptyPalette = new Array(this.densityStrokeColors.length).fill('')
    },

    mounted() {
        this.gdsActiveMarkerHtml = this.getGdsMarkerElement(
            IMG_GDS_STATUS_OPERATING,
            25
        ).outerHTML

        this.gdsLimitedMarkerHtml = this.getGdsMarkerElement(
            IMG_GDS_STATUS_LIMITED,
            25
        ).outerHTML

        this.gdsPerspectiveMarkerHtml = this.getGdsMarkerElement(
            IMG_GDS_STATUS_PROJECTED,
            25
        ).outerHTML

        this.gdsClosedMarkerHtml = this.getGdsMarkerElement(
            IMG_GDS_STATUS_CLOSED,
            25
        ).outerHTML

        this.majorOperatingPipelineHtml = this.getPipelineSvgPattern(
            PIPELINE_TYPE_MAJOR,
            6
        )
        this.branchOperatingPipelineHtml = this.getPipelineSvgPattern(
            PIPELINE_TYPE_BRANCH,
            4
        )
        this.settlementOperatingPipelineHtml = this.getPipelineSvgPattern(
            PIPELINE_TYPE_INTER_SETTLEMENT,
            3
        )

        this.cityCapitalDistrictMarkerHtml = this.getCityMarkerElement(
            IMG_CITY_CAPITAL_DISTRICT,
            14
        )

        this.cityCapitalRegionMarkerHtml = this.getCityMarkerElement(
            IMG_CITY_CAPITAL_REGION,
            22
        )
    },
}
</script>

<style lang="stylus" module>
.operatingSvgWrapper
    width 30px
    height 10px
    > svg:not(:first-child)
        margin-left 2px
// .operatingSvgWrapper2
// mapLegendToggleTrigger_w_h = 54px
.mapLegendWrapper
    display flex
    height 175px
.mapLegendToggleTrigger
    // position absolute
    // right 0
    // top 0
    // z-index 1
    // background-color #fff
    // width mapLegendToggleTrigger_w_h
    // height mapLegendToggleTrigger_w_h
    border-radius 6px
    display flex
    justify-content center
    align-items center
    cursor pointer
    font-size 40px
    transition transform .3s

.mapLegendToggleTrigger_showLegend
    transform rotate(180deg)
.mapLegend
    display flex
    flex-direction column
    text-transform lowercase
    font-size 17px
    width 100%
    height 100%
    // :global
    //     .el-card
    //         background-color var(--color_1_opacity_1)
    //         backdrop-filter var(--backdropBlur1)
    //         height 100%
    //     .el-card__header
    //         padding 8px 10px
    &:global(.el-card)
        background-color var(--color_1_opacity_1)
        backdrop-filter var(--backdropBlur1)
        height 100%
        &:global(.is-always-shadow)
            box-shadow var(--boxShadow1)
    :global(.el-card__header)
        padding 8px 10px


.mapLegend__header
    display flex
    align-items center
.apLegend__title
    text-transform uppercase
    display flex
    justify-content center
    width 100%
    font-weight bold
    font-size 16px

.mapLegend__content
    display flex
    flex-direction column
    width 100%
.mapLegend__col
    display flex
    flex-direction column
    flex-shrink 0

.blockTitle
    text-transform uppercase
    margin 10px 0
    font-weight 600
    font-size 14px
.blockContent
    display flex
    position relative
.blockContentGds
    align-items flex-end
.row_1, .row_2, .row_3
    display flex
    flex-direction column
.row_3
    width (100% / 4)
    // width (100% / 3)
    flex-shrink 0
.row_2
    width (100% / 2)
    flex-shrink 0
.cell
    margin 5px 0 5px 0
    white-space nowrap
    height 25px
    display flex

.cellEmptyDecorator
    &:before
        content ''
        width 22px
        height 3px
        background-color #333
        margin-top 6px
        opacity .3

.cellGds
    width 135px
.cellGdsHeader
    justify-content center
.citySvgWrapper
    display flex
    margin-right 10px
.cellSettlements
    width 200px
.gdsLineCell
    justify-content center
    align-items center
.svgLine
    width 47px
    height 16px

.svgLine__line
    vector-effect non-scaling-stroke
    stroke-linecap round

.svgLine__line_dashed_1
    stroke-dasharray 7 10
.svgLine__line_dashed_2
    stroke-dasharray 4 7
.svgLine__line_dashed_3
    stroke-dasharray 3 6

.svgLine__line_width_1
    stroke-width 2px
.svgLine__line_width_2
    stroke-width 4px
.svgLine__line_width_3
    stroke-width 6px
.svgLine__line_width_4
    stroke-width 16px

.marker
    margin-right 12px
    flex-shrink 0
// .gdsMarker


.legendGdsMarker
    position relative !important

.settlementsMarker
    w_h = 14px
    border-radius 50%
    width w_h
    height w_h
    box-sizing content-box

.settlementsMarker_capital
    border 5px solid
.settlementsMarker_centers
    border 1px solid
    align-self center
    margin-top -4px

.loader
    position: absolute
    left: 50%
    top: 0
    transform: translate(-50%, 0)

.decoration
    width 0
    height 0
</style>

<style lang="stylus">
.consumersMarker
    w_h = 12px
    width w_h
    height w_h
    border-radius 50%
    align-self center
    margin-top -4px
.consumersMarker_perspective
    border 1px solid
// .consumersMarker_active
//     background-color #000

.getCityMarker
  w_h = 12px
  width w_h
  height w_h
  border-radius 50%
  align-self center
  margin-top -4px
  border 1px solid
.getCityMarker_perspective
  border 1px solid
// .getCityMarker_active
//     background-color #000
</style>
