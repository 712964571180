import { COLORS, TILE_DISTRICT_SOURCE_LAYER } from '@/shared/constants'

export default async function (this: any, focusedRegionNumber) {
    // this.clearActiveRegion([
    //     this.regionFillLayerId,
    //     this.underConstructionMajorPipelineLayerId,
    //     this.operatingMajorPipelineLayerId,
    //     this.projectedMajorPipelineLayerId,
    //     TILE_DISTRICT_SOURCE_LAYER,
    //
    //     this.gdsSymbolIconTypeId,
    //     this.gdsSymbolLabelTypeId,
    //     this.consumersCircleTypeId,
    //     this.consumersCircleTypeId_small,
    //     this.consumersCircleTypeId_main_big,
    //     this.consumersCircleTypeId_cluster,
    //     this.consumersCircleTypeId_symbol,
    // ])

    const downloadedPromises = [
        this.addDistrictsOfRegion(focusedRegionNumber),
        // this.addProposals()
        // this.addCityAreas(),
    ]
    const downloadedCallbacks = await Promise.all(downloadedPromises)

    // this.clearActiveRegion([this.regionFillLayerId])

    downloadedCallbacks.forEach(downloadedCallback => {
        if (typeof downloadedCallback !== 'function') return
        if (focusedRegionNumber !== this.focusedRegionNumber) return
        downloadedCallback()
    })

    // this.map.moveLayer(this.tileCityAreaLabelsSourceLayer)

    // this.addRivers()
    // this.addLakes()
    // this.addPipelines()
}
