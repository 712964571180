export const svgToPng = function (svg, callback) {
    const url = getSvgUrl(svg)
    svgUrlToPng(url, imgData => {
        callback(imgData)
        URL.revokeObjectURL(url)
    })
}
function getSvgUrl(svg) {
    return URL.createObjectURL(new Blob([svg], { type: 'image/svg+xml' }))
}
function svgUrlToPng(svgUrl, callback) {
    const svgImage = document.createElement('img')
    // imgPreview.style.position = 'absolute';
    // imgPreview.style.top = '-9999px';
    document.body.appendChild(svgImage)
    svgImage.onload = function () {
        const canvas = document.createElement('canvas')
        canvas.width = svgImage.clientWidth
        canvas.height = svgImage.clientHeight
        const canvasCtx = canvas.getContext('2d')
        if (!canvasCtx) {
            callback()
            return
        }
        canvasCtx.drawImage(svgImage, 0, 0)
        const imgData = canvas.toDataURL('image/png')
        callback(imgData)
        // document.body.removeChild(imgPreview);
    }
    svgImage.src = svgUrl
}
