const metersPerPixel = (latitude, zoomLevel) => {
    const earthCircumference = 40075017
    const latitudeRadians = latitude * (Math.PI / 180)
    return (
        (earthCircumference * Math.cos(latitudeRadians)) /
        Math.pow(2, zoomLevel + 8)
    )
}
// const pixelValue = (latitude, meters, zoomLevel) => {
//   return meters / metersPerPixel(latitude, zoomLevel)
// }
export default function (this: any) {
    this.$commit('setMapPartialState', {
        metersInPx: metersPerPixel(this.mapCenter[1], this.mapZoom),
    })
}
