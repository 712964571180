<template>
    <div
        :class="[
            $style.mapPopupContainer,
            popupProperties?.closeButton &&
                $style.mapPopupContainer_withCloseButton,
        ]"
        :style="{
            background: popupProperties.backgroundColor
                ? popupProperties.backgroundColor
                : '#ffffff85',
        }"
    >
        <div v-html="properties.htmlContent"></div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    data() {
        return {}
    },
    computed: {
        ...mapState({
            properties: state => state.map.popupMap.properties,
            popupProperties: state => state.map.popupMap.popupProperties,
        }),
    },
    methods: {},
}
</script>

<style lang="stylus" module src="./generalMapPopupStyle.styl"></style>
