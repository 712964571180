import { TILE_DISTRICT_SOURCE_LAYER } from '@/shared/constants'
import { arrayToObjectWithItemsById } from '@/shared/lib'
const divisor = 10
const roundUp = (number /* , divisor */) => {
    const truncNumber = Math.trunc(number)
    const remainder = truncNumber % divisor
    const wholePart = truncNumber - remainder
    if (wholePart < divisor) return truncNumber
    return wholePart
}
export default function (this: any) {
    const visibleDistrictsFeatures = this.map.querySourceFeatures(
        this.infrastructureTileSourceName,
        {
            sourceLayer: TILE_DISTRICT_SOURCE_LAYER,
        }
    )

    const visibleDistrictsFeaturesById = arrayToObjectWithItemsById(
        visibleDistrictsFeatures
    )
    const populationDensityOfActiveRegion =
        this.populationDensityOfRegions[this.activeRegionNumber]

    if (!populationDensityOfActiveRegion) return

    this.$commit('setPartialState', {
        activeRegionDensityPalette: [],
    })

    const populationDensityOfActiveRegionLength =
        populationDensityOfActiveRegion.length
    const partsLength = this.densityPaletteColors.length

    const sortedPopulationDensityOfActiveRegion =
        populationDensityOfActiveRegion.sort(
            (districtDensityEntryA, districtDensityEntryB) => {
                const districtDensityA = districtDensityEntryA[1]
                const districtDensityB = districtDensityEntryB[1]
                return (
                    districtDensityA.population_density -
                    districtDensityB.population_density
                )
            }
        )

    const districtsQuantityOfPalettePart = ~~(
        populationDensityOfActiveRegionLength / partsLength
    )

    let step = 0
    let colorNumber = 0
    const groups: any = []
    for (let i = 0; i < populationDensityOfActiveRegionLength; i++) {
        const populationDensityItem = sortedPopulationDensityOfActiveRegion[i]
        const populationDensity = populationDensityItem[1]

        const districtDensity = roundUp(populationDensity.population_density)
        let currentGroup: any = groups[colorNumber]
        if (!currentGroup) {
            currentGroup = []
            groups[colorNumber] = currentGroup
        }
        currentGroup.push(districtDensity)

        const districtId = populationDensityItem[0]
        const feature = visibleDistrictsFeaturesById[districtId]

        if (feature) {
            const featureId = feature.id
            this.map.setFeatureState(
                {
                    source: this.infrastructureTileSourceName,
                    sourceLayer: TILE_DISTRICT_SOURCE_LAYER,
                    id: featureId,
                },
                { color: this.densityPaletteColors[colorNumber] }
            )
        }

        if (step >= districtsQuantityOfPalettePart - 1) {
            step = 0
            const nextColorNumber = colorNumber + 1
            if (nextColorNumber > partsLength - 1) continue
            colorNumber = nextColorNumber
            continue
        }
        step++
    }

    let previousValue = 0

    groups.forEach((group, index) => {
        if (index === groups.length - 1) {
            this.$commit('setPartialState', {
                activeRegionDensityPalette: [
                    ...this.activeRegionDensityPalette,
                    `более ${group[0]}`,
                ],
            })
            return
        }
        const edgeValue = group[group.length - 1]
        if (index === 0) {
            previousValue = edgeValue
            const text =
                previousValue > 0 ? `менее ${previousValue}` : previousValue
            this.$commit('setPartialState', {
                activeRegionDensityPalette: [
                    ...this.activeRegionDensityPalette,
                    text,
                ],
            })
            return
        }
        this.$commit('setPartialState', {
            activeRegionDensityPalette: [
                ...this.activeRegionDensityPalette,
                `${previousValue} - ${edgeValue}`,
            ],
        })
        previousValue = edgeValue
    })
}
