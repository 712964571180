export default function (this: any, layersIds: any[]) {
    this.$watch(
        'isShowConsumers',
        isShowConsumers => {
            if (isShowConsumers) {
                this.setLayersVisibility(layersIds, 'visible')

                return
            }
            this.setLayersVisibility(layersIds, 'none')
        },
        {
            immediate: true,
        }
    )
}
