import './mapbox-gl.css'

import maplibregl from 'maplibre-gl/dist/maplibre-gl'

import {
    LAYER_MIN_ZOOM1,
    MAP_MAX_ZOOM,
    MAP_MAX_ZOOM1,
    MAP_MAX_ZOOM24,
} from '@/shared/constants'

import addEvents from './addEvents'
import addImages from './addImages'
export default async function (this: any) {
    this.$app.config.globalProperties.$maplibregl = maplibregl
    this.maplibregl = maplibregl

    // const style = await this.getMapStyle()
    // if (!style) return

    this.map = new maplibregl.Map({
        attributionControl: false,
        container: this.$refs.map,
        center: this.mapCenter,
        zoom: this.mapZoom /*  - this.initialZoomRange */,
        bearing: this.mapBearing,
        pitch: this.mapPitch,
        // style: this.mapStyle,
        style: this.mapStyle,
        // maxZoom: MAP_MAX_ZOOM24,
        maxZoom: MAP_MAX_ZOOM1,
        minZoom: this.layerMinZoom1,
        // setRenderWorldCopies: false,
        dragRotate: true,
    })

    this.mapCanvasStyle = this.map.getCanvas().style

    globalThis.map = this.map
    this.$app.config.globalProperties.$map = this.map

    // disable map rotation using right click + drag
    // this.map.dragRotate.disable()
    this.map.dragRotate._mouseRotate.disable()
    this.map.setPadding({
        left:
            this.$state.view.isMenuOpen && !this.isMobileView
                ? this.menuPanelWidth / 2
                : 0,
    })
    // disable map rotation using touch rotation gesture
    // this.map.touchZoomRotate.disableRotation()
    addEvents.call(this)

    await new Promise((resolve: any) => {
        this.map.on('load', resolve)
        this.map.once('error', resolve)
        this.map.on('error', error => {
            // this.$store.commit('setPartialState', {
            //     error: {
            //         isActive: true,
            //         message: `Карта временно недоступна (Tile-server error)`,
            //         type: 'error',
            //         statusCode: 0,
            //     },
            // })
            console.error('Error:', error.error)
        })
    })
    this.mapIsLoaded = true

    await addImages.call(this)

    // const mapFakeStop = () => {
    //     return void 0
    // }
    // this.map.stop = mapFakeStop

    // addSwingEffect.call(this)
}
