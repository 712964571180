// import { COLORS, TILE_DISTRICT_SOURCE_LAYER } from '@/shared/constants'
export default function (this: any) {
    let unwatchIsShowPopulationDensity: any = null
    this.$watch(
        'activeDistrictsFillLayerId',
        activeDistrictsFillLayerId => {
            if (!activeDistrictsFillLayerId) return
            if (unwatchIsShowPopulationDensity) {
                unwatchIsShowPopulationDensity()
                unwatchIsShowPopulationDensity = null
            }
            unwatchIsShowPopulationDensity = this.$watch(
                'isShowPopulationDensity',
                isShowPopulationDensity => {
                    console.log(
                        'isShowPopulationDensity:',
                        isShowPopulationDensity
                    )

                    const layer = this.map.getLayer(activeDistrictsFillLayerId)
                    if (!layer) return
                    if (isShowPopulationDensity) {
                        this.map.setPaintProperty(
                            activeDistrictsFillLayerId,
                            'fill-opacity',
                            1
                        )
                        return
                    }
                    this.map.setPaintProperty(
                        activeDistrictsFillLayerId,
                        'fill-opacity',
                        0
                    )
                },
                {
                    immediate: true,
                }
            )
        },
        {
            immediate: true,
        }
    )
}
