import { COLORS, IMG_GZS_COMPANY_NOVATEK } from '@/shared/constants'
import { htmlToElement } from '@/shared/lib'
export default function (this: any, status: string, size: number) {
    const getMarkerFillStroke = status => {
        let fill = ''
        let stroke = ''

        switch (status) {
            case IMG_GZS_COMPANY_NOVATEK:
                fill = COLORS[39]
                stroke = COLORS[9]
                break
            default:
                fill = '#000000'
                stroke = COLORS[9]
                break
        }

        return {
            fill,
            stroke,
        }
    }
    const { fill, stroke } = getMarkerFillStroke(status)
    const el = htmlToElement(`
                    <svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 22.107 20.437">
                      <g id="Group_2417" data-name="Group 2417" transform="translate(1.053 1)">
                        <path id="Path_3147" data-name="Path 3147" d="M12.918,0H7.082A4.167,4.167,0,0,0,3.475,2.083L.558,7.136a4.165,4.165,0,0,0,0,4.165l2.917,5.052a4.167,4.167,0,0,0,3.607,2.083h5.835a4.165,4.165,0,0,0,3.607-2.083L19.442,11.3a4.165,4.165,0,0,0,0-4.165L16.525,2.083A4.165,4.165,0,0,0,12.918,0" transform="translate(0 0)" 
                        fill="${fill}" stroke="${stroke}" stroke-width="2"/>
                      </g>
                    </svg>
                `)

    return el
}
