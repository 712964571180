//
import { COLORS } from '@/shared/constants'

const highlighterOpacity = 0.6
export default function (this: any, featureData, gdsCenterCoordinates) {
    if (!featureData) return

    const framesPerSecond = 30
    const initialOpacity = 1
    let opacity = initialOpacity
    const initialRadius = 6
    let radius = initialRadius
    const maxRadius = 20
    let timeOutMarker, animationFrameId
    const highlightLayerId = 'highlightGdsPipelines'

    const currentHighlightSource = this.map.getSource(highlightLayerId)
    const currentGeoData = data => {
        return {
            type: 'geojson',
            data: {
                type: 'FeatureCollection',
                features: featureData,
            },
        }
    }
    if (currentHighlightSource) {
        currentHighlightSource.setData({
            type: 'FeatureCollection',
            features: featureData,
        })
    } else {
        this.$map.addSource(highlightLayerId, currentGeoData(featureData))
    }

    // this.removeHighlighter()
    // this.$emit('removeHighlighter')

    // const color = COLORS[7]
    const color = COLORS[22]
    const getLngLatDeep = coordinates => {
        const firstElement = coordinates[0]
        const secondElement = coordinates[1]
        if (
            typeof firstElement === 'number' &&
            typeof firstElement === 'number'
        ) {
            return [firstElement, secondElement]
        }
        return getLngLatDeep(firstElement)
    }

    // featureData.forEach(feature => {
    //     if (this.featureGeometryType === 'Point') {
    //         this.map.addLayer({
    //             id: `highlightGdsPipelines_${feature.id}`,
    //             type: 'circle',
    //             source: 'highlightGdsPipelines',
    //             paint: {
    //                 'circle-color': color,
    //                 'circle-radius': 15,
    //                 'circle-opacity': highlighterOpacity,
    //             },
    //         })
    //     } else {
    this.$map.addLayer({
        id: highlightLayerId,
        type: 'line',
        source: highlightLayerId,
        paint: {
            'line-opacity': highlighterOpacity,
            'line-color': color,
            'line-width': 6.5,
        },
    })
    const layerIdToAddPrev = this.map
        .getStyle()
        .layers.find(
            item => item['source-layer'] === 'gas_distribution_station'
        )?.id
    this.$map.moveLayer(highlightLayerId, layerIdToAddPrev)
    // '#abd300'
    // this.$map.addLayer({
    //     id: `highlightGdsPipelines_sec`,
    //     type: 'line',
    //     source: 'highlightGdsPipelines',
    //     paint: {
    //         'line-opacity': highlighterOpacity,
    //         'line-color': color,
    //         'line-width': 4.5,
    //     },
    // })

    const animateMarker = timestamp => {
        // const currentHighlightLayers = this.highlightSourceAndLayers.map(
        //     layerId => {
        //         return this.$map.getLayer(layerId)
        //     }
        // )
        // if (!currentHighlightLayers[0]) {
        //     this.$state.view.gdsMenu.isActiveCityMarker = false
        //     return
        // }
        timeOutMarker = setTimeout(() => {
            animationFrameId = requestAnimationFrame(animateMarker)

            radius += (maxRadius - radius) / framesPerSecond
            opacity -= 0.9 / framesPerSecond

            if (opacity <= 0) {
                radius = initialRadius
                opacity = initialOpacity
            }

            this.$map.setPaintProperty(highlightLayerId, 'line-width', radius)
            this.$map.setPaintProperty(
                highlightLayerId,
                'line-opacity',
                opacity
            )
        }, 1000 / framesPerSecond)
    }

    // animateMarker(0)

    this.$map.flyTo({
        // center: getLngLatDeep(featureData[0].geometry.coordinates),
        center: getLngLatDeep(gdsCenterCoordinates),
        zoom: 12.3,
    })
}
