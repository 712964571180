import DeviceDetector, { DeviceDetectorResult } from 'device-detector-js'
import { DeviceType } from 'device-detector-js/dist/typings/device'
import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import devView from '@/app/providers/store/modules/devView'
import {
    AvailableDevices,
    RootState,
} from '@/app/providers/store/types/rootTypes'
import { setPartialState } from '@/shared/lib'

import map from './modules/map'
import user from './modules/user'
import view from './modules/view/view'
import { AGNKSEntity } from '@/entities/ANGKS/model/AGNKSEntity'
export const store = createStore<RootState>({
    state: {
        appVersion: '1.1.14',
        prevVersion: '1.1.13',
        releaseDate: '2023-02-17',
        deviceInfo: null,
        deviceType: AvailableDevices.desktop,
        error: {
            isActive: false,
            message: '',
            type: 'error',
            statusCode: 0,
        },
        chosenModule: null,
        indexedDbController: null,
        chosenAppModule: null,
        appModuleAccess: {
            gasification: {
                id: 'gasification',
                name: 'Газификация',
                allowAccess: false,
                checkAccessUrl: '/gasification',
            },
            gasificationPlan: {
                id: 'gasificationPlan',
                name: 'План газификации',
                allowAccess: false,
                checkAccessUrl: '/gasification',
            },
            spg: {
                id: 'spg',
                name: 'СПГ',
                allowAccess: false,
                checkAccessUrl: '/lng',
            },
            AGNKS: {
                id: 'AGNKS',
                name: 'АГНКС',
                allowAccess: false,
                checkAccessUrl: '/agnks',
            },
        },

        sources: {},
        focusedRegionName: '',
        hoveredRegionName: '',
        hoveredRegionNumber: 0,
        focusedRegionNumber: 0,
        hoveredDistrictProperties: '',
        focusedDistrictName: '',
        focusedDistrictNumber: '',
        focusedCityAreaName: '',
        focusedCityAreaNumber: '',
        activeRegionDensityPalette: [],
        sourceBaseUrl: process.env.VUE_APP_SOURCE_SERVER_URL,
        loadingSourceUrls: [],
        globalLoading: false,
        layerMinZoom: {
            1: 6.565,
        },
    },
    mutations: {
        setPartialState,
        checkDeviceType: state => {
            const ua = navigator.userAgent
            //     if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
            //         state.deviceType = 'tablet'
            //     } else if (
            //         /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
            //             ua
            //         )
            //     ) {
            //         state.deviceType = 'mobile'
            //     }
            //     state.deviceType = 'desktop'
            // },

            const deviceDetector = new DeviceDetector()
            const userAgent = ua
            state.deviceInfo = deviceDetector.parse(userAgent)
            state.deviceType = state.deviceInfo.device?.type
        },
        setChosenAppModule: (state, module) => {
            state.chosenAppModule = module
        },
    },
    actions: {},
    getters: {
        allowedAppModules: state =>
            Object.values(state.appModuleAccess).filter(
                module => module.allowAccess
            ),
    },
    modules: {
        view,
        map,
        user,
        devView,
        AGNKSEntity,
    },
    plugins: [
        createPersistedState({
            paths: [
                // 'chooseModuleId',
                'chosenAppModule',
                'focusedRegionNumber',
                'map.center',
                'map.zoom',
                'map.pitch',
                'map.bearing',
                'map.focusedObject',
                'map.focusedObject',
                'map.focusedFeatureOptions',
                // 'map.isShowPopulationDensity',
                // 'map.isShowProposals',
                'map.isShowGds',
                'map.isShowConsumers',
                'map.activeLayersGroup',
                'view.isShowMapLegend',
                'view.isShowMiniMap',
                'view.isMenuOpen',
                'view.isReleaseNotesShowed',
                'view.stepActiveId',
                'view.stepPrevId',
                'sourceBaseUrl',

                'AGNKSEntity',
            ],
        }),
    ],
})
