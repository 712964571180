<template>
    <div
        :class="[isMenuOpen && $style.backdrop]"
        v-if="isMobileView"
        :style="{ backdropFilter: `blur(${backdropBlurValue}px)` }"
        ref="backdropMenu"
    ></div>
    <div
        ref="menuEl"
        :class="[
            $style.menuPanel,
            isMenuOpen && $style.menuPanel_show,
            isDraggingMenu && $style.menuPanel_mobileDragEffects,
        ]"
        :style="{
            width: isMobileView ? '100%' : `${menuPanelWidth}px`,
        }"
    >
        <div :class="$style.dragIconArea">
            <div
                :class="$style.dragIcon"
                ref="dragIcon"
                v-if="isMobileView"
            ></div>
        </div>
        <f7-appbar no-hairline :class="$style.appbar">
            <div
                :class="$style.dragger"
                ref="appBar"
                style="width: 100%; height: 100%; position: absolute"
            ></div>
            <div :class="$style.controlsWrapper">
                <div
                    :class="[
                        $style.searchWrapper,
                        isMenuOpen && $style.searchWrapper_menuOpen,
                    ]"
                >
                    <!-- <f7-searchbar
              search-container=".search-list"
              search-in=".item-title"
              :disable-button="false"
          ></f7-searchbar> -->
                    <Search :is-fill="0 > 0" @clear="clear" />
                    <!--                                        <Controls v-bind="{ toggleButtonTranslateValue }" />-->
                </div>
            </div>
        </f7-appbar>
        <f7-toolbar
            no-border
            :class="[
                $style.toolbarTitle,
                isMenuOpen && $style.toolbarTitle_menuOpen,
            ]"
            position="top"
        >
            <div
                :class="[$style.title, isMenuOpen && $style.title_menuOpen]"
                ref="toolbarTitleEl"
            >
                <!-- v-if="!hoveredRegionName && !hoveredDistrictProperties" -->
                <div :class="$style.titlePlaceholderWrapper">
                    <div :class="$style.titlePlaceholder">
                        <div>{{ focusedRegionName }}&nbsp;</div>
                        <div
                            :class="$style.separator"
                            v-if="isAllowfocusedDistrictName"
                        ></div>
                        <div v-if="isAllowfocusedDistrictName">
                            &nbsp;{{ focusedDistrictName }}
                        </div>
                    </div>
                </div>
            </div>
        </f7-toolbar>
        <div :class="$style.mainContainer">
            <f7-page :class="$style.page">
                <f7-list inset>
                    <f7-list-item>
                        <TimeLine :stepsList="activeStepsListTimeline" />
                    </f7-list-item>
                </f7-list>

                <PlanView
                    v-if="chosenAppModule?.id === appModuleIds.gasificationPlan"
                />

                <MainMenuView v-if="indexedDbController" />

                <f7-popup
                    backdrop
                    swipe-to-close
                    close-by-outside-click
                    close-by-backdrop-click
                    animate
                    :opened="isShowQRCode"
                    @popup:close="() => setShowQRCode(false)"
                >
                    <f7-navbar :title="'QR код'">
                        <f7-nav-right>
                            <f7-link popup-close>
                                <f7-icon f7="multiply_circle_fill"></f7-icon>
                            </f7-link>
                        </f7-nav-right>
                    </f7-navbar>
                    <div :class="$style.popupQR">
                        <div :class="$style.qrImg">
                            <img
                                style="height: 500px; width: 500px"
                                :src="pathToQRCode"
                                alt=""
                            />
                        </div>
                        <div :class="$style.qrText">
                            Если у вас появились дополнительные вопросы по
                            проекту
                        </div>
                    </div>
                </f7-popup>

                <f7-popup
                    backdrop
                    swipe-to-close
                    close-by-outside-click
                    close-by-backdrop-click
                    animate
                    style="height: fit-content"
                    :opened="
                        isReleaseNotes &&
                        chosenAppModule?.id === appModuleIds.gasification
                    "
                    @popup:close="
                        ;(this.$state.view.isReleaseNotes = false),
                            (this.$state.view.isReleaseNotesShowed = true)
                    "
                    class="modal-release-notes"
                >
                    <f7-navbar :title="'Обновления '">
                        <f7-nav-right>
                            <f7-link popup-close>
                                <f7-icon f7="multiply_circle_fill"></f7-icon>
                            </f7-link>
                        </f7-nav-right>
                    </f7-navbar>
                    <f7-block inset no-hairlines>
                        <f7-list>
                            <f7-block-title
                                ><f7-icon f7="map"></f7-icon>
                                География</f7-block-title
                            >
                            <f7-list-item>
                                - исправлены данные о газораспределительных
                                сетях Калининградской, Псковской, Новгородской,
                                Тверской, Ярославской, Ивановской,
                                Нижегородской, Томской, Ленинградской,
                                Вологодской области, а также г. Санкт-Петербург.
                            </f7-list-item>
                        </f7-list>
                        <f7-list>
                            <f7-block-title
                                ><f7-icon f7="gear"></f7-icon>
                                Функционал</f7-block-title
                            >
                            <f7-list-item>
                                - выстроена иерархия и добавлена навигация
                                перехода от федеральных округов до населенных
                                пунктов в меню, учитывающая уровень
                                масштабирования;
                            </f7-list-item>
                            <f7-list-item>
                                - на карте отображена статистика по заявкам на
                                подключение газа;
                            </f7-list-item>
                            <f7-list-item>
                                - разработано меню отображения статистики по
                                заявкам.
                            </f7-list-item>
                        </f7-list>
                        <f7-list>
                            <f7-block-title
                                ><f7-icon f7="tag"></f7-icon>
                                Улучшения</f7-block-title
                            >
                            <f7-list-item>
                                - изменены стили отображения меню.
                            </f7-list-item>
                        </f7-list>
                        <f7-list>
                            <f7-block-title>
                                <f7-icon f7="bandage"></f7-icon> Исправление
                                ошибок
                            </f7-block-title>
                            <f7-list-item>
                                - исправлены ошибки взаимодействия с картой.
                            </f7-list-item>
                        </f7-list>
                        <f7-list>
                            <f7-list-item>
                                Ждем ваших комментариев по проделанной нашим
                                коллективом работе, а также предложений по
                                дальнейшему развитию проекта.
                            </f7-list-item>
                        </f7-list>
                    </f7-block>
                </f7-popup>
            </f7-page>
        </div>

        <div
            :class="$style.menuFooterWrapper"
            ref="menuFooter"
            v-if="deviceType !== AvailableDevices.smartphone"
        >
            <div :class="$style.scrollbarContent">
                <div :class="$style.menuFooterContainer">
                    <div :class="$style.logotypesWrapper">
                        <SvgPart1 :class="$style.logotypes" />

                        <div
                            :class="$style.logotypesCduWrapper"
                            @click="() => setShowQRCode(true)"
                        >
                            <Logotypes :class="$style.logotypesCdu" />
                            <!--                            <span :class="$style.logotypesCduText"-->
                            <!--                                >Проектирование и разработка</span-->
                            <!--                            >-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'

import Logotypes from '@/shared/ui/Logotypes'
import SvgPart1 from '@/shared/ui/Logotypes/Group2379'

import Search from '../MenuPanelNew/ui/Search/Search.vue'
import MainMenuView from './ui/MainMenuView'
import TimeLine from './ui/TimeLine'

import {
    limitTransformTop,
    mobileMenuLevelLow,
    mobileMenuLevelMiddle,
} from './types'
import {
    appModuleIds,
    stepsListIndexes,
    ZOOM_HIERARCHY_MIN_LEVEL,
} from '../../shared/constants'
import { AvailableDevices } from '../../app/providers/store/types/rootTypes'
import PlanView from '../../features/gasificationPlan/PlanView/PlanView'
import { timeLineStepsByAppModules } from '@/widgets/MenuPanel/config/appModulesConfig'

const dragIconColors = {
    default: '#a0aec0',
    active: '#ced5dd',
}

export default {
    components: {
        PlanView,
        Search,
        SvgPart1,
        Logotypes,
        // ReleaseNotes,
        MainMenuView,
        TimeLine,
    },
    props: {
        focusedRegionNumber: {
            type: Number,
            required: true,
        },
        loadSource: {
            type: Function,
            required: true,
        },
        activeDistrictsFillLayerId: {
            type: String,
            required: true,
        },
        getGdsMarkerElement: {
            type: Function,
            required: true,
        },
        getPipelineSvgPattern: {
            type: Function,
            required: true,
        },
    },
    data() {
        return {
            ReleaseNotes: false,
            isShowQRCode: false,
            openQR: {
                clicks: 0,
                timer: 1000,
                timeout: 1000,
            },
            startMenuPosition: 0,
            mobilePositionCoef: 2,
            backdropBlurValue: 0,
            touchPosY: 0,
            toggleButtonTranslateValue: 0,
            isDraggingMenu: false,
        }
    },
    mounted() {
        this.showReleaseNotes()
        this.menuInitPosition()
        this.dragMenuMobile()
    },
    methods: {
        setShowQRCode(isShow) {
            if (!isShow) {
                this.isShowQRCode = isShow
                return
            }

            clearTimeout(this.openQR.timer)

            this.openQR.clicks++

            if (this.openQR.clicks >= 3) {
                this.isShowQRCode = isShow
                this.openQR.clicks = 0
            } else {
                this.openQR.timer = setTimeout(() => {
                    this.openQR.clicks = 0
                }, this.openQR.timeout)
            }
        },
        showReleaseNotes() {
            let lastVersion = localStorage.getItem('appVersion')
            if (!lastVersion) {
                lastVersion = this.$state.prevVersion
            }
            const newVersion = this.$state.appVersion
            const currDate = new Date()
            const releaseDate = new Date(this.$state.releaseDate)
            const releasesDiff = Math.ceil(
                (currDate.getTime() - releaseDate.getTime()) /
                    (1000 * 3600 * 24)
            )
            const isShow =
                +newVersion.split('.')[2] > +lastVersion.split('.')[2] &&
                releasesDiff < 10
            console.log(
                'isShow',
                isShow,
                currDate,
                +newVersion.split('.')[2],
                +lastVersion.split('.')[2],
                releasesDiff
            )

            if (isShow) {
                this.$state.view.releaseNotes = true
                // this.$state.view.releaseNotes = isShow
                localStorage.setItem('appVersion', newVersion)
            }
        },
        translateMenu(translateValue, e) {
            this.$refs.menuEl.style.transform =
                'translateY(' + translateValue + 'px)'
            this.touchPosY = e?.targetTouches[0]?.pageY || translateValue

            this.menuBlurChange()
        },
        menuBlurChange() {
            if (this.touchPosY < this.mobileMenuLevelMiddlePosY) {
                this.backdropBlurValue = this.mobileBackdropBlurValue
                // console.log('backdropFilter', this.$refs.backdropMenu?.style?.backdropFilter)
            } else {
                this.backdropBlurValue = 0
            }
        },
        menuInitPosition() {
            // const transitionDrag = 'transform 299ms linear 0s';
            if (!this.isMobileView) {
                this.$refs.menuEl.style.height = ''
                this.$refs.menuEl.style.transform = ''
                return
            }

            this.toggleButtonTranslateValue = this.isMenuOpen
                ? 0
                : this.mobileMenuLevelLowPosY - document.body.clientHeight
            this.startMenuPosition = this.isMenuOpen
                ? this.mobileMenuLevelLowPosY
                : document.body.clientHeight
            this.translateMenu(this.startMenuPosition)
            // console.log('this.startMenuPosition', this.startMenuPosition)
        },
        autoDragStoppedSideEffects() {
            this.isDraggingMenu = false
            console.log('this.isDraggingMenu', this.isDraggingMenu)
        },
        dragMenuMobile() {
            if (!this.isMobileView) return

            this.$refs.appBar.addEventListener('touchstart', e => {
                this.$refs.dragIcon.style.backgroundColor =
                    dragIconColors.active

                this.$refs.menuEl.style.height = '100%'
                this.$refs.appBar.addEventListener('touchmove', e => {
                    if (
                        e.targetTouches[0].pageY >= limitTransformTop &&
                        e.targetTouches[0].pageY <= this.mobileMenuLevelLowPosY
                    ) {
                        this.isDraggingMenu = true
                        // console.log(e.targetTouches[0].pageY)
                        // this.$refs.menuEl.style.transition = 'none';
                        this.translateMenu(e.targetTouches[0].pageY, e)
                    }
                })
            })

            this.$refs.appBar.addEventListener('touchend', e => {
                // this.isDraggingMenu = false
                // console.log('isDraggingMenu', this.isDraggingMenu)

                this.$refs.dragIcon.style.backgroundColor =
                    dragIconColors.default

                if (
                    e.changedTouches[0].pageY >
                    document.body.clientHeight / this.mobilePositionCoef
                ) {
                    if (
                        this.$refs.menuEl.getBoundingClientRect().y <
                            this.mobileMenuLevelMiddlePosY +
                                (this.mobileMenuLevelLowPosY -
                                    this.mobileMenuLevelMiddlePosY) /
                                    this.mobilePositionCoef ||
                        this.$refs.menuEl.getBoundingClientRect().y <=
                            this.mobileMenuLevelMiddlePosY
                    ) {
                        console.log('mobileMenuLevelMiddlePosY')
                        this.autoDragStoppedSideEffects()

                        this.startMenuPosition = this.mobileMenuLevelMiddlePosY
                        this.mobilePositionCoef = 2
                    }

                    if (
                        this.$refs.menuEl.getBoundingClientRect().y >=
                            this.mobileMenuLevelMiddlePosY +
                                (this.mobileMenuLevelLowPosY -
                                    this.mobileMenuLevelMiddlePosY) /
                                    2 &&
                        this.$refs.menuEl.getBoundingClientRect().y >
                            this.mobileMenuLevelMiddlePosY
                    ) {
                        console.log('mobileMenuLevelLowPosY')
                        this.startMenuPosition = this.mobileMenuLevelLowPosY
                        this.autoDragStoppedSideEffects()
                    }

                    this.translateMenu(this.startMenuPosition, e)

                    setTimeout(
                        () => (this.$refs.menuEl.style.height = '40%'),
                        400
                    )
                } else {
                    this.autoDragStoppedSideEffects()
                    console.log('TOP')
                    this.translateMenu(limitTransformTop, e)
                }

                this.$refs.appBar.removeEventListener('touchmove', e => {
                    // console.log('touchmove listener removed')
                })
            })
        },
    },
    watch: {
        windowViewportWidth(value) {
            this.menuInitPosition()
            this.dragMenuMobile()
        },
        isMenuOpen() {
            this.menuInitPosition()
        },
        isDraggingMenu() {
            // console.log('this.isDraggingMenu', this.isDraggingMenu)
        },
    },
    computed: {
        ...mapState({
            hoveredRegionName: state => state.hoveredRegionName,
            hoveredDistrictProperties: state => state.hoveredDistrictProperties,
            focusedRegionName: state => state.focusedRegionName,
            focusedDistrictName: state => state.focusedDistrictName,
            isMenuOpen: state => state.view.isMenuOpen,
            menuOpenedCollapseItem: state => state.view.menuOpenedCollapseItem,
            isShowPopulationDensity: state => state.map.isShowPopulationDensity,
            windowViewportWidth: state => state.view.windowViewportWidth,
            menuPanelWidth: state => state.view.menuPanelWidth,
            isReleaseNotes: state => state.view.releaseNotes,
            isReleaseNotesShowed: state => state.view.isReleaseNotesShowed,
            appVersion: state => state.appVersion,
            releaseDate: state => state.releaseDate,
            stepsList: state => state.view.stepsList,
            stepActiveId: state => state.view.stepActiveId,
            f7Router: state => state.view.f7Router,
            stepsListDirection: state => state.view.stepsListDirection,
            indexedDbController: state => state.indexedDbController,
            mapZoom: state => state.map.zoom,
            deviceType: state => state.deviceType,
            chosenAppModule: state => state.chosenAppModule,
        }),
        ...mapGetters(['isMobileView']),
        appModuleIds() {
            return appModuleIds
        },
        AvailableDevices() {
            return AvailableDevices
        },

        mobileMenuLevelLowPosY() {
            return mobileMenuLevelLow(
                this.$refs.toolbarTitleEl.clientHeight +
                    this.$refs.appBar.clientHeight
            ).posY
        },
        mobileMenuLevelMiddlePosY() {
            return mobileMenuLevelMiddle().posY
        },
        mobileBackdropBlurValue() {
            return (
                ((document.body.clientHeight - this.touchPosY) /
                    document.body.clientHeight) *
                10
            )
        },

        isAllowHoveredDistrictName() {
            return (
                this.hoveredDistrictProperties &&
                this.mapZoom >=
                    ZOOM_HIERARCHY_MIN_LEVEL[
                        stepsListIndexes.districtInfoStepIndex
                    ]
            )
        },
        isAllowfocusedDistrictName() {
            return (
                this.focusedDistrictName &&
                this.mapZoom >=
                    ZOOM_HIERARCHY_MIN_LEVEL[
                        stepsListIndexes.districtInfoStepIndex
                    ]
            )
        },

        pathToQRCode() {
            return process?.env?.VUE_APP_BASE_URL + '/img/icons/QRZavalov.jpg'
        },
        activeStepsListTimeline() {
            return timeLineStepsByAppModules[this.chosenAppModule?.id]
        },
    },
}
</script>

<style lang="stylus" module>
//transitions
//{v-bind(stepsListDirection.positive)}
//{v-bind(stepsListDirection.negative)}
.fade
  &:global(-enter-active)
    transition: .1s ease-in;
    opacity: 0;
.fade
  &:global(-leave-active)
    transition: .1s ease-out;
    opacity: 0;

.slide_positive,
&:global(-enter-active, -leave-active)
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
.slide_negative,
&:global(-enter-active, -leave-active)
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);

.slide_negative
  &:global(-enter)
    transform: translateX(100%);
  &:global(-leave-to)
    transform: translateX(-100%);

.slide_positive
  &:global(-enter)
    transform: translateX(-100%);
  &:global(-leave-to)
    transform: translateX(100%)

p {
  text-indent 20px
}

.page
  > :global(.page-content)
    //height auto
    overflow hidden
    min-height: 100%;
    padding-top var(--f7-page-navbar-offset)
    display: flex;
    flex-direction: column;

.mainContainer
  position: relative
  height: 100%

.menuFooterWrapper
  //background-color: var(--f7-page-bg-color);
  z-index: 999
.menuFooterContainer
  border-top: solid #d5d5d5 1px;
  box-shadow: 0px -100px 76px -58px #ffffffb8;
  height: 80px;

.scrollbarContent
  display flex
  flex-direction column
  min-height 100%
  justify-content space-between
  :global(.el-scrollbar__view)
    min-height 100%
    display flex
    flex-direction column
    z-index: 9999;
    //position: absolute;
    justify-content: flex-end;
.backLink
  //margin-left: calc(var(--f7-list-inset-side-margin) + var(--f7-safe-area-outer-left));
  cursor pointer

.menuPanel
  height 100%
  position absolute
  max-width 100%
  // left var(--indent2)
  // top var(--indent2)
  left 0
  top 0
  background-color #ffffffe3
  // background-color var(--color_1)
  backdrop-filter var(--backdropBlur2)
  z-index 2
  display flex
  flex-direction column
  // padding 14px
  justify-content flex-start
  // align-items center
  box-shadow var(--boxShadow1)
  // box-shadow var(--f7-button-box-shadow)
  // height calc(100% - var(--indent2) * 2)
  transition transform var(--transitionDuration1)
  // border-radius 0 10px 10px 0
  // padding calc(var(--indent2) * 2) 0 var(--indent2) 0
  // padding 0 0 var(--indent2) 0
  // border-radius var(--borderRadius1)
  // filter url(#shadowed-goo)
  &:not(.menuPanel_show)
    // transform translateX(calc(-100% - var(--indent2)))
    transform translateX(-100%)
    :global(.appbar-inner)
      transform translateX(100%)
  :global(.framework7-root)
    height auto
    flex-shrink 0

  @media (max-width: 912px) {
    //transform: translateY(653px);
    transition transform 0ms

    height: 40%;
    &:not(.menuPanel_show) {
      transform none

      :global(.appbar-inner) {
        transform none
      }
    }
    &:not(.menuPanel_mobileDragEffects) {
      transition transform 400ms
    }
  }

.backdrop
  position absolute
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  //backdrop-filter: blur(5px);
  /* visibility: hidden; */
  transition-duration: 400ms;
  pointer-events: none

.appbar
  &:global(.appbar)
    height auto
  // border-radius 20px
  :global(.appbar-inner)
    transition transform var(--transitionDuration1)
    height auto
    position relative
    padding var(--indent3) var(--indent2)

.dragger
  @media (max-width: 912px) {
    width: 100%
    height: 100%
    position: absolute
    z-index: 2
  }
.dragIcon
  border-radius: 0.25rem;
  width: 3.5rem;
  height: 100%;
  background-color: #a0aec0;
  transition: var(--transitionDuration1);
  pointer-events: none;

.dragIconArea
  width: 100%;
  height: 0.25rem;
  position: absolute;
  z-index: 9999;
  display: flex;
  justify-content: center;
  margin-top: 3px;

.logotypesWrapper
  width 100%
  padding 5px 25%
  display flex
  align-items center
  justify-content center
  height: 100%
//padding-bottom var(--indent1)
//transform scale(0.8)
// flex-direction column

.logotypes
  width 100%
  height auto

.logotypesCduWrapper
  display flex
  justify-content center
  flex-direction column
  margin-left var(--indent2)
.logotypesCdu
  margin-top var(--indent3)
.logotypesCduText
  color #505050
  text-align center
  font-size 12px

.toggleMenuPanelTrigger
  flex-shrink 0
  left calc(100% + (var(--indent2) * 2))
  top 0
  &:global(.button)
    position absolute

// .navbar
//     transform translateY(100%)
.toolbarTitle
  &:global(.toolbar)
    // transform translateY(-100%)
    height 30px
    //background-color #fff
    flex-shrink 0
  &:not(.toolbarTitle_menuOpen)
    :global(.toolbar-inner)
      transform translateX(100%)
  :global(.toolbar-inner)
    transition transform var(--transitionDuration1)
    padding 0 var(--indent2)
    overflow visible

  @media (max-width: 912px) {
    &:not(.toolbarTitle_menuOpen) {
      :global(.toolbar-inner) {
        transform none
      }
    }
  }


.searchWrapper
  display flex
  align-items center
  width 100%
  position relative
  transition transform var(--transitionDuration1)
// &:not(.searchWrapper_menuOpen)
//     transform translateX(calc(100% + var(--indent1)))

.search
  width 100%
  border-radius 10px

.menuInsetControls
  display flex
  // position absolute
  left calc(100% + var(--indent3) + var(--indent2))
  // transition left var(--transitionDuration1)
  align-items center
// & > *:not(:first-child)
//     margin-top var(--indent3)

:global(.ios-translucent-bars)
  .title
    &.title_menuOpen
      background-color transparent


.title
  flex-shrink 0
  width 100%
  height 100%
  font-size 16px
  text-align center
  display inline-block
  align-items center
  justify-content center
  // background-color var(--f7-bars-bg-color)
  background-color #fff
  border-radius var(--f7-button-border-radius)
  padding 0 var(--indent2)
  &:not(.title_menuOpen)
    box-shadow var(--f7-button-raised-box-shadow)

.titlePlaceholderWrapper
  display flex
  align-items center
  justify-content center
  height 100%
.titlePlaceholder
  // visibility hidden
  vertical-align middle
  white-space nowrap
  overflow hidden
  text-overflow ellipsis
  display flex
  align-items center
  justify-content center
  width 100%
.navMapIcon
  margin-right 10px
  height 25px
.separator
  width 1px
  background-color black
  margin 0 5px
  height 15px
.controlsWrapper
  flex-shrink 0
  display flex
  justify-content space-between
  flex-wrap nowrap
  width 100%
  z-index 9999

.controlsButtonSet
  margin-right 14px
  flex-shrink 0

.miniMapAccordion
  &:global(.list)
    :global(.item-link)
      z-index 1
      &:before
        content ''
        position absolute
        width 100%
        height 100%
        top 0
        left 0
        z-index -1
        background var(--f7-list-bg-color)
  :global(.accordion-item-content)
    overflow visible
  &:global(.list.inset)
    //margin-top 0
    & > ul
      overflow hidden
      background transparent

// .scrollbarContent__cell1
.popupQR
  display flex
  flex-direction column
  height 100%
  width 100%
  .qrImg
    display flex
    align-items center
    justify-content center
  .qrText
    width: 100%;
    text-align: center;
    background: aliceblue;
    padding: 10px;
    margin: 10px 0;
</style>
