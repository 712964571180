export default function (this: any) {
    // console.log('this.focusedDistrictId', this.$state.focusedDistrictNumber)
    if (!this.$state.focusedDistrictNumber) return

    this.setInitialLoadDataResult()
    this.loadDistrictObjectsData(this.$state.focusedDistrictNumber)

    this.isShowDistrictInfoBlock = true
    if (this.focusedDistrictName) {
        this.selectedDistrictName = this.focusedDistrictName
    }
}
