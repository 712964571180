export interface IMobileMenuLevels {
    id: number
    posY: number
}

export const limitTransformTop = 25
export const limitTransformBottom = 25

export const mobileMenuLevelLow = (
    elementsClientHeight: number
): IMobileMenuLevels => {
    return {
        id: 0,
        posY: document.body.clientHeight - elementsClientHeight,
    }
}
export const mobileMenuLevelMiddle = (
    elementsClientHeight?: number
): IMobileMenuLevels => {
    return {
        id: 1,
        posY: document.body.clientHeight - document.body.clientHeight * 0.4,
    }
}
export const mobileMenuLevelHigh = (
    elementsClientHeight?: number
): IMobileMenuLevels => {
    return {
        id: 2,
        posY: limitTransformTop,
    }
}
export const mobileMenuLevels = [0, 1, 2]
