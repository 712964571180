import { svgToPng } from '@/shared/lib'
export default async function (this: any, svg, imageId) {
    // console.log('imageId:', imageId)

    const svgToPngConvertedImg = await new Promise((resolve, reject) => {
        svgToPng(svg, imgData => {
            if (!imgData) {
                reject()
                return
            }
            resolve(imgData)
        })
    })
    if (!svgToPngConvertedImg) return
    return await this.map
        .loadImage(svgToPngConvertedImg)
        .then(image => {
            // if (this.map.hasImage(imageId)) {
            //     this.map.updateImage(imageId, image)
            //     return
            // }
            this.map.addImage(imageId, image.data)
            return image
        })
        .catch(error => error)
}
