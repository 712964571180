import { setPartialStateDeep } from '@/shared/lib'

import addEvents from './addEvents'

export default function (
    this: any,
    prevLayerId,
    {
        mainLayerOptions,
        hoveringLayerOptionsMixin,
        hoveringLayerOptions,
        hoveringDecorationLayerOptions,
        getHtmlContent,
    }
) {
    console.log('addLayerHoverActions', prevLayerId, mainLayerOptions)
    const { id: mainLayerId, source, filter, minzoom } = mainLayerOptions
    const maxzoom = mainLayerOptions?.maxzoom
    const hoveringLayerId = mainLayerId + this.layerIdHoveringPostfix
    const hoveringDecorationLayerId =
        mainLayerId + this.layerIdHoveringDecorationPostfix

    if (hoveringLayerOptionsMixin) {
        hoveringLayerOptions = JSON.parse(JSON.stringify(mainLayerOptions))
        setPartialStateDeep(hoveringLayerOptions, {
            ...hoveringLayerOptionsMixin,
        })
    }
    // const isLayerExist = this.$map.getLayer(mainLayerOptions.id)
    //
    // if (isLayerExist) return

    setPartialStateDeep(hoveringLayerOptions, {
        id: hoveringLayerId,
    })
    setPartialStateDeep(hoveringDecorationLayerOptions, {
        id: hoveringDecorationLayerId,
    })
    Object.entries({
        source,
        filter,
        minzoom,
        maxzoom,
    }).forEach(entry => {
        const key = entry[0]
        const value = entry[1]
        if (!value) return
        hoveringLayerOptions[key] = value
        hoveringDecorationLayerOptions[key] = value
    })

    this.addLayerAfter(prevLayerId, hoveringDecorationLayerOptions)
    this.addLayerAfter(hoveringDecorationLayerId, hoveringLayerOptions)
    // this.addLayerAfter(hoveringLayerId, mainLayerOptions)

    addEvents.call(this, source, hoveringLayerId, getHtmlContent)
}
