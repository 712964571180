import {
    COLORS,
    CONSUMER_STATUS_OPERATING,
    CONSUMER_STATUS_PROJECTED,
    CONSUMERS_TYPE,
    GDS_STATUS_CLOSED,
    GDS_STATUS_LIMITED,
    GDS_STATUS_OPERATING,
    GDS_STATUS_PROJECTED,
    GDS_TYPE,
    IMG_GDS_STATUS_CLOSED,
    IMG_GDS_STATUS_LIMITED,
    IMG_GDS_STATUS_OPERATING,
    IMG_GDS_STATUS_PROJECTED,
    PIPELINE_BRANCH_TYPE,
    PIPELINE_MAJOR_TYPE,
    PIPELINE_SETTLEMENT_TYPE,
    PIPELINE_STATUS_OPERATING,
    PIPELINE_STATUS_PROJECTED,
    PIPELINE_STATUS_UNDER_CONSTRUCTION,
    PIPELINE_TYPE_BRANCH,
    PIPELINE_TYPE_INTER_SETTLEMENT,
    PIPELINE_TYPE_MAJOR,
} from '@/shared/constants'
import GDS_STATUS_CLOSED_SVG from '@/shared/assets/mapIcons/gds_closed_22'
import GDS_STATUS_PROJECTED_SVG from '@/shared/assets/mapIcons/gds_projected_22'
import GDS_STATUS_OPERATING_SVG from '@/shared/assets/mapIcons/gds_operating_22'
import GDS_STATUS_CLOSED_ENTRANCE_SVG from '@/shared/assets/mapIcons/gds_closed_entrance_22'

const getMultipleSvg = (template, count) => {
    let result = template
    for (let i = 0; i < count - 1; i++) result += template
    return result
}
const getPipelineHtml = id => {
    return `<svg viewBox="0 0 100 16" xmlns="http://www.w3.org/2000/svg"><use href="#${id}" /></svg>`
}
const getConsumerIconHtml = function (this: any, status) {
    switch (status) {
        case CONSUMER_STATUS_PROJECTED:
            return `<div class="consumersMarker consumersMarker_active" style="background-color: ${COLORS[4]}"></div>`
        case CONSUMER_STATUS_OPERATING:
            return `<div class="consumersMarker consumersMarker_perspective" style="border-color: ${COLORS[4]}; background-color: ${COLORS[9]}"></div>`
    }
}
const getGdsIconHtml = function (this: any, status) {
    switch (status) {
        case GDS_STATUS_CLOSED:
            return GDS_STATUS_CLOSED_SVG
        case GDS_STATUS_LIMITED:
            return GDS_STATUS_CLOSED_ENTRANCE_SVG
        case GDS_STATUS_OPERATING:
            return GDS_STATUS_OPERATING_SVG
        case GDS_STATUS_PROJECTED:
            return GDS_STATUS_PROJECTED_SVG
    }
}
const getPipelineMajorIconHtml = function (this: any, status) {
    switch (status) {
        case PIPELINE_STATUS_PROJECTED:
            return getPipelineHtml('pipelineMajorProjected')
        case PIPELINE_STATUS_UNDER_CONSTRUCTION:
            return getMultipleSvg(
                this.getPipelineSvgPattern(PIPELINE_TYPE_MAJOR, 6, 2),
                5
            )
        case PIPELINE_STATUS_OPERATING:
            return getPipelineHtml('pipelineMajorOperating')
    }
}
const getPipelineBranchIconHtml = function (this: any, status) {
    switch (status) {
        case PIPELINE_STATUS_PROJECTED:
            return getPipelineHtml('pipelineBranchProjected')
        case PIPELINE_STATUS_UNDER_CONSTRUCTION:
            return getMultipleSvg(
                this.getPipelineSvgPattern(PIPELINE_TYPE_BRANCH, 4, 2),
                6
            )
        case PIPELINE_STATUS_OPERATING:
            return getPipelineHtml('pipelineBranchOperating')
    }
}
const getPipelineSettlementIconHtml = function (this: any, status) {
    switch (status) {
        case PIPELINE_STATUS_PROJECTED:
            return getPipelineHtml('pipelineSettlementProjected')
        case PIPELINE_STATUS_UNDER_CONSTRUCTION:
            return getMultipleSvg(
                this.getPipelineSvgPattern(
                    PIPELINE_TYPE_INTER_SETTLEMENT,
                    3,
                    2
                ),
                7
            )
        case PIPELINE_STATUS_OPERATING:
            return getPipelineHtml('pipelineSettlementOperating')
    }
}
export default function (this: any) {
    switch (this.objectsType) {
        case CONSUMERS_TYPE:
            return getConsumerIconHtml.call(this, this.featureProperties.status)

        case GDS_TYPE:
            return getGdsIconHtml.call(this, this.featureProperties.status)

        case PIPELINE_MAJOR_TYPE:
            return getPipelineMajorIconHtml.call(
                this,
                this.featureProperties.status
            )

        case PIPELINE_BRANCH_TYPE:
            return getPipelineBranchIconHtml.call(
                this,
                this.featureProperties.status
            )

        case PIPELINE_SETTLEMENT_TYPE:
            return getPipelineSettlementIconHtml.call(
                this,
                this.featureProperties.status
            )
        default:
            return ''
    }
}
