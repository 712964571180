import { emptyFeatureCollection } from '@/entities/Map/general/model/mapUtils/mapUtilsConstants'
import addSource from '@/entities/Map/general/model/addSource'
import setClusterEvents from '@/entities/Map/general/model/eventMethods/setClusterEvents'
import { GDS_STATUS_PROJECTED, plansAccentColor } from '@/shared/constants'
import { computed, watch } from 'vue'
import { useStore } from 'vuex'
import addPulsedIconClass, {
    IconSquare,
} from '@/entities/Map/general/model/mapUtils/addPulsedIconClass'
const plansSourceId = 'plans'
const emptySource = emptyFeatureCollection()

export default async function (this: any) {
    this.$state.globalLoading = true

    const features = await this.loadSource('/gasification/plan/locations').then(
        res => res?.features
    )
    // console.log('features plans', features)
    const plansSource = this.map.getSource(plansSourceId)

    const plansId = 'plans'

    if (plansSource) {
        emptySource.features = features
        plansSource.setData(emptySource)

        console.log('plans data updated')
        return
    } else {
        if (features) emptySource.features = features
        // console.log('emptySource', emptySource)

        await addSource.call(
            this,
            plansSourceId,
            this.featureCollectionType,
            emptySource,
            this.$map,
            {
                cluster: true,
                clusterMaxZoom: 6,
                clusterRadius: 50,
                clusterProperties: {
                    year: ['coalesce', ['get', 'year']],
                    region_id: ['coalesce', ['get', 'region_id']],
                },
            }
        )
        const plansCircleId = `${plansId}__circle`
        const plansCluster = `${plansId}__cluster`
        const plansClusterCount = `${plansId}__cluster-count`
        const plansClusterArea = `${plansId}__cluster-area`

        const imageId = addPulsedIconClass(
            new IconSquare(25, 25),
            this.$map,
            plansSourceId,
            plansAccentColor,
            null,
            true
        ).add()

        this.$map.addLayer({
            id: plansCircleId,
            type: 'symbol',
            layout: {
                'icon-overlap': 'always',
                'icon-image': imageId,
            },
            source: plansSourceId,
            minzoom: 7,
        })
        const allPlansYears = this.$state.view.gasificationPlan.years
        console.log('allPlansYears', allPlansYears)
        const plansClusterIds = allPlansYears.map(
            year => plansCluster + year.id
        )
        const plansClusterCountIds = allPlansYears.map(
            year => plansClusterCount + year.id
        )
        const plansClusterAreaIds = allPlansYears.map(
            year => plansClusterArea + year.id
        )

        console.log('imageId', imageId)

        allPlansYears.map(year => {
            this.map.addLayer({
                id: plansCluster + year?.id,
                type: 'symbol',
                source: plansSourceId,
                layout: {
                    'icon-overlap': 'always',
                    'icon-image': imageId,
                },
                filter: year?.id
                    ? [
                          'all',
                          ['==', ['get', 'year'], year?.id],
                          ['has', 'point_count'],
                      ]
                    : ['has', 'point_count'],
                minzoom: 0,
                maxzoom: 7,
            })
            this.map.addLayer({
                id: plansClusterCount + year.id,
                type: 'symbol',
                source: 'plans',
                filter: year?.id
                    ? [
                          'all',
                          ['==', ['get', 'year'], year?.id],
                          ['has', 'point_count'],
                      ]
                    : ['has', 'point_count'],
                layout: {
                    'text-field': ['get', 'point_count'],
                    'text-font': ['Noto Sans Bold'],
                    'text-size': 18,
                    'text-allow-overlap': true,
                    'text-ignore-placement': false,
                    'text-offset': [0, -1.2],
                },
                paint: {
                    'text-color': '#000000',
                    'text-halo-color': '#ffffff',
                    'text-halo-width': 1.5,
                },
                minzoom: 0,
                maxzoom: 7,
            })
            this.map.addLayer({
                id: plansClusterArea + year.id,
                type: 'circle',
                source: plansSourceId,
                filter: year?.id
                    ? [
                          'all',
                          ['==', ['get', 'year'], year?.id],
                          ['has', 'point_count'],
                      ]
                    : ['has', 'point_count'],
                paint: {
                    // 'circle-color': 'rgba(187,113,137,0.49)',
                    'circle-radius': {
                        type: 'exponential',
                        base: 26,
                        stops: [
                            [8, 20],
                            [10, 26],
                        ],
                    },
                    'circle-color': 'transparent',
                },
                minzoom: 0,
                maxzoom: 7,
            })
        })

        const allPlansLayers = [
            plansCircleId,
            ...plansClusterIds,
            ...plansClusterCountIds,
            ...plansClusterAreaIds,
        ]
        setClusterEvents.call(this, [
            ...plansClusterIds,
            ...plansClusterCountIds,
            ...plansClusterAreaIds,
        ])

        this.$state.globalLoading = false

        const activeYear = computed(
            () => this.$state.view.gasificationPlan.activeYear
        )
        const isShowGasPlans = computed(() => this.$state.map.isShowGasPlans)

        watch(activeYear, activeYearUpdated => {
            // console.log('activeYearUpdated', activeYearUpdated)
            if (!activeYearUpdated?.id)
                return allPlansLayers.forEach(layerId =>
                    this.$map.setFilter(layerId, null)
                )

            allPlansLayers.forEach(layerId => {
                if (layerId.includes('cluster')) {
                    this.$map.setFilter(layerId, [
                        'all',
                        ['==', ['get', 'year'], activeYearUpdated?.id],
                        ['has', 'point_count'],
                    ])
                    return
                }
                this.$map.setFilter(layerId, [
                    '==',
                    ['get', 'year'],
                    activeYearUpdated?.id,
                ])
            })
        })
        watch(
            isShowGasPlans,
            isShowGasPlans => {
                console.log('isShowGasPlans')
                if (isShowGasPlans) {
                    this.setLayersVisibility(allPlansLayers, 'visible')
                    return
                }
                this.setLayersVisibility(allPlansLayers, 'none')
            },
            {
                immediate: true,
            }
        )
    }
}
