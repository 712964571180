export class DebounceMapEvent {
    callback: () => void
    ms: number
    isExecuting

    constructor(options?: { callback; ms }) {
        this.callback = options?.callback
        this.ms = options?.ms
    }

    start() {
        if (this.isDebouncing) return

        new Promise(resolve => {
            this.isExecuting = true
            setTimeout(resolve, this.ms)
        }).then(() => {
            this.callback()
            this.isExecuting = false
        })
    }

    get isDebouncing(): boolean {
        return this.isExecuting
    }
}
