<template>
    <div :class="$style.objectItem" @click="clickHandler">
        <div :class="[$style.title, isActiveFeature && $style.title_active]">
            <div :class="$style.iconCell" v-html="iconHtml"></div>
            <div :class="$style.idCell">ID {{ feature.id }}</div>
            <div v-if="featureName" :class="$style.nameCell">
                {{ featureName }}
            </div>
        </div>
        <el-icon v-if="isActiveFeature" :size="20" :class="$style.closeTrigger">
            <Close />
        </el-icon>
    </div>
</template>
<script>
import { Close } from '@element-plus/icons'

import loadSource from '../../../../../../../entities/Map/general/model/loadSource'
//import { outsideResolvingPromise } from '../../../../../../lib/outsideResolvingPromise'
//import { convertProjection } from '@/lib'
import iconHtml from './computed/iconHtml'
import higlightFeature from './methods/higlightFeature'
import loadCurrentObjectData from './methods/loadCurrentObjectData'
import setMarker from './methods/setMarker'
export default {
    props: {
        getGdsMarkerElement: {
            type: Function,
            required: true,
        },
        getPipelineSvgPattern: {
            type: Function,
            required: true,
        },
        objectsType: {
            type: String,
            required: true,
        },
        feature: {
            type: Object,
            required: true,
        },
        activeFeature: {
            type: Object,
        },
        highlightSourceAndLayerId: {
            type: String,
        },
    },
    data() {
        return {
            objectItemData: null,
            objectItemGeometry: null,
            objectItemUrl: `/${this.objectsType}/${this.feature.id}`,
        }
    },
    components: {
        Close,
    },
    computed: {
        isActiveFeature() {
            return this.feature === this.activeFeature
        },
        featureProperties() {
            // return this.feature.properties
            return this.feature
        },
        featureName() {
            return this.featureProperties.name
        },
        featureGeometryType() {
            return this.objectItemData.geometry.type
        },
        featureCoordinates() {
            return this.objectItemData.geometry.coordinates
        },
        featureFirstPointLngLat() {
            if (this.featureGeometryType === 'Point') {
                return this.featureCoordinates
            }
            return this.getLngLatDeep(this.featureCoordinates)
        },
        iconHtml,
        queriedFeature() {
            return this.$map.querySourceFeatures('infrastructure', {
                sourceLayer: 'consumer',
                filter: ['==', ['id'], this.feature.id],
            })[0]
        },
    },
    methods: {
        getLngLatDeep(coordinates) {
            const firstElement = coordinates[0]
            const secondElement = coordinates[1]
            if (
                typeof firstElement === 'number' &&
                typeof firstElement === 'number'
            ) {
                return [firstElement, secondElement]
            }
            return this.getLngLatDeep(firstElement)
        },
        // async customFlyTo() {
        //     const outsideResolvingPromiseEvent = outsideResolvingPromise()
        //     this.$map.once('moveend', () => {
        //         outsideResolvingPromiseEvent.resolve()
        //     })
        //     this.$map.easeTo({
        //         zoom: 12,
        //         center: this.featureFirstPointLngLat,
        //     })
        //     await outsideResolvingPromiseEvent
        // },
        async clickHandler() {
            if (this.isActiveFeature) {
                this.$emit('update:activeFeature', null)
                this.$emit('removeHighlighter', null)
                return
            }

            if (this.objectsType.split('_')[0] === 'PIPELINE') {
                console.log('PIPELINE')
                this.objectItemData = await this.loadCurrentObjectData(
                    this.objectItemUrl,
                    this.objectsType,
                    this.feature.id
                )
            } else {
                this.objectItemData = {
                    id: this.feature.id,
                    properties: {
                        name: this.feature.name,
                        status: this.feature.status,
                    },
                    geometry: {
                        coordinates: this.getLngLatDeep([
                            this.feature.x,
                            this.feature.y,
                        ]),
                        type: 'Point',
                    },
                    type: 'Feature',
                    zoom: 12,
                }
            }

            console.log('objectsType', this.objectsType)

            // console.log(
            //     'this.featureFirstPointLngLat',
            //     this.featureFirstPointLngLat
            // )
            this.$emit('update:activeFeature', this.feature)
            // this.$map.flyTo({
            //     center: this.featureFirstPointLngLat,
            // })
            // this.customFlyTo().finally(() => {
            //   this.higlightFeature()
            // })

            this.$dispatch('toggleMenuOpenByDevice')

            this.$map.flyTo({
                center: this.featureFirstPointLngLat,
                zoom: this.objectItemData.zoom,
            })
            this.higlightFeature(this.objectItemData)
        },
        loadCurrentObjectData,
        higlightFeature,
        loadSource,
        setMarker,
        removeHighlighter() {
            this.$emit('removeHighlighter')
        },
    },
    beforeCreate() {
        // convertProjection(this.feature)
    },
}
</script>

<style lang="stylus" module>
.objectItem
    display flex
    border-bottom: 1px solid var(--el-border-color-light)
    cursor pointer
    &:hover
        // font-weight bold
        text-decoration underline

.title
    display flex
    width 100%
    font-size 15px
.idCell
    flex-shrink 0
    width 80px
    align-items center
    display flex
.nameCell
    padding-left var(--indent1)
    align-items center
    display flex
.closeTrigger
    flex-shrink 0
.title_active
    text-decoration underline
.iconCell
    display flex
    align-items center
    justify-content center
    //width 50px
    width 40px
    flex-shrink 0
    padding 3px 5px
</style>
