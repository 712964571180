<template>
    <f7-button
        :class="[$style.trigger, isMenuOpen && $style.trigger_menuOpen]"
        :popover-open="`.${$style.popover}`"
        fill
        :raised="!isMenuOpen"
        color="white"
    >
        <f7-icon f7="layers_alt" size="24px" color="blue"></f7-icon>
        <!-- :backdrop="false" -->
        <f7-popover :class="$style.popover">
            <!-- scrollable -->
            <f7-toolbar :class="$style.toolbar" tabbar bottom>
                <f7-link
                    :tab-link-active="activeLayersGroup === 'scheme'"
                    @click="setLayersGroup('scheme')"
                    >Схема</f7-link
                >
                <!--                <f7-link-->
                <!--                    @click="setLayersGroup('populationDensity')"-->
                <!--                    :tab-link-active="activeLayersGroup === 'populationDensity'"-->
                <!--                    >Плотность населения</f7-link-->
                <!--                >-->
                <!-- @click="setLayersGroup('relief')" -->
                <!--                <f7-link :tab-link-active="activeLayersGroup === 'relief'"-->
                <!--                    >Рельеф</f7-link-->
                <!--                >-->
            </f7-toolbar>
            <f7-list
                v-if="
                    chosenAppModule?.id === appModuleIds.gasification ||
                    chosenAppModule?.id === appModuleIds.gasificationPlan
                "
            >
                <f7-list-item
                    v-if="chosenAppModule?.id === appModuleIds.gasification"
                    checkbox
                    title="Заявки на подключение"
                    :checked="isShowProposals"
                    @change="changeLayer('isShowProposals', $event)"
                />
                <f7-list-item
                    checkbox
                    title="Газораспределительные станции"
                    :checked="isShowGds"
                    @change="changeLayer('isShowGds', $event)"
                />
                <f7-list-item
                    checkbox
                    title="Подключенные объекты"
                    :checked="isShowConsumers"
                    @change="changeLayer('isShowConsumers', $event)"
                />
                <f7-list-item
                    checkbox
                    title="Потребители газа"
                    :checked="isShowAzs"
                    @change="changeLayer('isShowAzs', $event)"
                />
                <f7-list-item
                    v-if="chosenAppModule?.id === appModuleIds.gasificationPlan"
                    checkbox
                    title="Планы газификации"
                    :checked="isShowGasPlans"
                    @change="changeLayer('isShowGasPlans', $event)"
                />
            </f7-list>
            <f7-list v-if="chosenAppModule?.id === appModuleIds.spg">
                <!--                <f7-list-item-->
                <!--                    checkbox-->
                <!--                    title="Потребители газа"-->
                <!--                    :checked="isShowAzs"-->
                <!--                    @change="changeLayer('isShowAzs', $event)"-->
                <!--                />-->
            </f7-list>
        </f7-popover>
    </f7-button>
</template>
<script>
import { mapState } from 'vuex'
import { appModuleIds } from '../../../../shared/constants'
export default {
    components: {},
    props: {},
    data() {
        return {}
    },
    computed: {
        ...mapState({
            isShowPopulationDensity: state => state.map.isShowPopulationDensity,
            isShowProposals: state => state.map.isShowProposals,
            isShowGds: state => state.map.isShowGds,
            isShowConsumers: state => state.map.isShowConsumers,
            isShowAzs: state => state.map.isShowAzs,
            isShowGasPlans: state => state.map.isShowGasPlans,
            isMenuOpen: state => state.view.isMenuOpen,
            activeLayersGroup: state => state.map.activeLayersGroup,
            chosenAppModule: state => state.chosenAppModule,
        }),
        appModuleIds() {
            return appModuleIds
        },
    },
    methods: {
        changeLayer(prop, event) {
            this.$state.map[prop] = event.target.checked
        },
        setLayersGroup(layersGroupName) {
            this.$commit('setMapPartialState', {
                activeLayersGroup: layersGroupName,
            })
        },
    },
    created() {
        this.$watch(
            'activeLayersGroup',
            activeLayersGroup => {
                switch (activeLayersGroup) {
                    case 'scheme':
                        this.$commit('setMapPartialState', {
                            isShowPopulationDensity: false,
                            isShowCityAreas: true,
                        })
                        break
                    case 'populationDensity':
                        this.$commit('setMapPartialState', {
                            isShowPopulationDensity: true,
                            isShowCityAreas: false,
                        })
                        break
                    case 'relief':
                        this.$commit('setMapPartialState', {
                            isShowPopulationDensity: false,
                            isShowCityAreas: false,
                        })
                        break
                }
            },
            {
                immediate: true,
            }
        )
    },
}
</script>

<style lang="stylus" module>
.trigger
    &.trigger_menuOpen
        box-shadow inset 0 0 0 1px var(--f7-segmented-raised-divider-color)
    // &.button

.popover
    &:global(.popover)
        width 350px

.toolbar
    border-radius var(--f7-popover-border-radius)
    overflow hidden
    &:global(.tabbar)
        &:before
            display none
        :global(.link)
            width auto
            padding 0 var(--indent4)
</style>
