import customizeLayersStyle from '../../../general/model/getMapStyle/customizeLayersStyle'

const mainStyleUrl = process.env.VUE_APP_BUILD_WITH_DEVELOPMENT_PARTS
    ? 'http://192.168.245.196:8162/base/style.json'
    : 'https://gmap.cdu.ru/tile/base/style.json'

export default async function (this: any) {
    const mainStyle = await fetch(mainStyleUrl)
        .then((response: any) => response.json())
        .catch(error => console.log('style ERROR', error))
    if (!mainStyle) return console.error('!mainStyle')

    console.log('style:', mainStyle)

    customizeLayersStyle.call(this, mainStyle)
    return mainStyle
}
