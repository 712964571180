import { TreeList } from '@/widgets/MenuPanel/ui/TreeView/methods/TreeList'
import { planViewTitles } from '@/shared/constants'

export const setRegionsListProposals = function (this: any, sortedRegions) {
    this.$state.view.treeLists.regions = sortedRegions.map(region => {
        return new TreeList(
            region.name,
            null,
            region.centroid,
            region.code,
            this.regionsProposalsStats?.[region.code]?.all_proposal_count,
            region.bbox
        )
    })
}
export const setRegionsListGasPlans = function (this: any, sortedRegions) {
    this.$state.view.treeLists.regions = sortedRegions.map(region => {
        return new TreeList(
            region.name,
            null,
            region.centroid,
            region.code,
            this.regionsGasificationPlans?.[region.code]?.count,
            region.bbox
        )
    })
}
