import { appModuleIds, stepsListIndexes } from '@/shared/constants'

export const FOCUS_TILE_FEATURE_LIST_INDEXES_BY_APP_MODULES_DEFAULT = [
    stepsListIndexes.regionsStepIndex,
    stepsListIndexes.districtsStepIndex,
    stepsListIndexes.districtInfoStepIndex,
]
export const FOCUS_TILE_FEATURE_STEP_LIST_INDEXES_BY_APP_MODULES: {
    [key in keyof typeof appModuleIds]?: string[]
} = {
    [appModuleIds.AGNKS]: [
        stepsListIndexes.regionsStepIndex,
        stepsListIndexes.districtsStepIndex,
    ],
    [appModuleIds.gasificationPlan]: [
        stepsListIndexes.regionsStepIndex,
        stepsListIndexes.districtsStepIndex,
    ],
    [appModuleIds.gasification]: [
        ...FOCUS_TILE_FEATURE_LIST_INDEXES_BY_APP_MODULES_DEFAULT,
        stepsListIndexes.settlementsStepIndex,
    ],
    [appModuleIds.spg]: [
        ...FOCUS_TILE_FEATURE_LIST_INDEXES_BY_APP_MODULES_DEFAULT,
    ],
}

export const layerFocusEffectColors = {
    fill: 'rgba(129,163,255,0.66)',
    line: 'rgba(42,99,255,0.8)',
}
