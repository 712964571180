import { stepsListIndexes, ZOOM_HIERARCHY_MIN_LEVEL } from '@/shared/constants'

export default function (this: any, region) {
    console.log('regionClick', region)
    if (!region.id || !region.geometry) return

    // this.$router.push({path:`/district-view/`});
    // this.$state.view.f7Router.navigate('/district-view/')

    console.log('region.code', region.id)
    this.$state.view.regionsView.chosenRegion.id = region.id
    this.$state.view.regionsView.chosenRegion.geometry = region.geometry
    this.$state.view.regionsView.chosenRegion.bbox = region.envelope

    this.$state.focusedRegionNumber = region.id
    this.$state.focusedRegionName = region.label

    this.$dispatch('setStepActiveId', stepsListIndexes.districtsStepIndex)

    if (!region.envelope) {
        this.$map.jumpTo({
            center: region.geometry,
            zoom: ZOOM_HIERARCHY_MIN_LEVEL[stepsListIndexes.districtsStepIndex],
        })
        return
    }

    this.$commit('setIsAutomaticZoomChangeLocked', {
        isLocked: true,
    })
    this.$map.fitBounds(region.envelope, {
        speed: 20,
        // zoom: 8,
        // padding: {
        //     right: 700,
        // },
        duration: 0,
    })
}
