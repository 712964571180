import { DeviceDetectorResult } from 'device-detector-js'
import { DeviceType } from 'device-detector-js/dist/typings/device'
import { appModuleIds } from '@/shared/constants'

export type DeviceTypes = 'tablet' | 'mobile' | 'desktop'
export enum AvailableDevices {
    tablet = 'tablet',
    smartphone = 'smartphone',
    desktop = 'desktop',
}

export interface ErrorAccess {
    isActive: boolean
    message: string
    type: 'error' | 'warning'
    statusCode: any
}

export interface AppModuleAccessOptions<ModuleId = keyof typeof appModuleIds> {
    id: ModuleId
    name: string
    allowAccess: boolean
    checkAccessUrl: string
}

export type AppModuleAccess = {
    [ModuleId in keyof typeof appModuleIds]: AppModuleAccessOptions<ModuleId>
}

export interface RootState {
    appVersion: string
    prevVersion: string
    releaseDate: string
    deviceInfo: DeviceDetectorResult | null
    deviceType?: DeviceType
    error: ErrorAccess
    chosenModule: AppModuleAccessOptions | null
    indexedDbController: any
    chosenAppModule: AppModuleAccessOptions | null
    appModuleAccess: AppModuleAccess

    sources: any
    focusedRegionName: string
    hoveredRegionName: string
    hoveredRegionNumber: number
    focusedRegionNumber: number
    hoveredDistrictProperties: string
    focusedDistrictName: string
    focusedDistrictNumber: string
    focusedCityAreaName: string
    focusedCityAreaNumber: string
    activeRegionDensityPalette: any[]
    sourceBaseUrl?: string
    loadingSourceUrls: string[]
    globalLoading: boolean
    layerMinZoom: {
        1: number
    }
}
