import { DebounceMapEvent } from '@/entities/Map/general/model/mapUtils/debounceMapEvent'

// let timeOutMarker, animationFrameId
// const framesPerSecond = 10
// const initialOpacity = 0.5
// let opacity = initialOpacity
// const initialRadius = 0.5
// let radius = initialRadius
// const maxRadius = 20
//
// function animateMarker (this: any, highlightLayerId, focusedFeatureId) {
//     timeOutMarker = setTimeout(() => {
//         animationFrameId = requestAnimationFrame(<FrameRequestCallback>animateMarker)
//
//         radius += (maxRadius - radius) / framesPerSecond
//         opacity -= 0.1 / framesPerSecond
//
//         if (opacity < 0) {
//             opacity = 0
//             return
//             // radius = initialRadius
//             // opacity = initialOpacity
//         }
//
//         console.log('opacity', opacity)
//         this.$map.setPaintProperty(
//           highlightLayerId,
//           'fill-opacity',
//           [
//               'case',
//               // ['!=', ['get', 'region_id'], this.focusedRegionNumber],
//               // 0,
//               ['==', ['get', 'id'], focusedFeatureId],
//               opacity,
//               0,
//           ]
//         )
//     }, 1000 / framesPerSecond)
// }

export function lineFocusEffect(
    this: any,
    layerId: string,
    focusedFeatureId: number | string,
    isDebounced?: boolean,
    ms = 1000
) {
    this.map.setPaintProperty(layerId, 'line-opacity', [
        'case',
        // ['!=', ['get', 'region_id'], this.focusedRegionNumber],
        // 0,
        ['==', ['get', 'id'], focusedFeatureId],
        1,
        0,
    ])
    if (isDebounced) {
        const debounceFocusEffect = new DebounceMapEvent({
            callback: () => {
                console.log('lineFocusEffect', layerId)
                this.map.setPaintProperty(layerId, 'line-opacity', 0)
            },
            ms,
        })
        debounceFocusEffect.start()
    }
}

export function fillFocusEffect(
    this: any,
    layerId: string,
    focusedFeatureId: number | string,
    isDebounced?: boolean,
    ms = 1000
) {
    this.map.setPaintProperty(layerId, 'fill-opacity', [
        'case',
        // ['!=', ['get', 'region_id'], this.focusedRegionNumber],
        // 0,
        ['==', ['get', 'id'], focusedFeatureId],
        0.5,
        0,
    ])
    if (isDebounced) {
        const debounceFocusEffect = new DebounceMapEvent({
            callback: () => {
                this.map.setPaintProperty(layerId, 'fill-opacity', 0)
            },
            ms,
        })
        debounceFocusEffect.start()
    }

    // animateMarker.call(this, layerId, focusedFeatureId)
}
