export default async function (this: any) {
    const lakesFeatures = await this.loadSource(this.riversUrl)
    if (!lakesFeatures) throw new Error('!lakesFeatures')
    console.log('riversFeatures:', lakesFeatures)

    this.addSource(this.riversUrl, this.featureCollectionType, lakesFeatures)

    const layerTypeLine = this.layerTypeLine
    // const layerTypeFill = this.layerTypeFill
    // const layerFillId = this.getLayerId(this.riversUrl, layerTypeFill)
    const layerLineId = this.getLayerId(this.riversUrl, layerTypeLine)

    this.addLayer({
        id: layerLineId,
        type: 'line',
        source: this.riversUrl,
        paint: {
            'line-color': this.colors[7],
            'line-width': 1,
        },
        layout: {
            'line-cap': 'round',
            'line-join': 'round',
        },
        minzoom: this.layerMinZoom1,
    })

    // this.addLayer({
    //     id: layerFillId,
    //     type: layerTypeFill,
    //     source: this.riversUrl,
    //     layout: {},
    //     paint: {
    //         'fill-color': this.colors[8],
    //     },
    // })
}
