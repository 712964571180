<template>
    <el-tooltip effect="dark" :offset="15">
        <f7-card color="white" :class="[$style.devView]">
            <f7-list>
                <f7-navbar>
                    <strong>
                        <p>Loaded info:</p>
                    </strong>
                </f7-navbar>
                <br />
                <div v-for="(item, index) in loadedItems" :key="index">
                    <p v-html="item"></p>
                </div>

                <f7-list-item title="Zoom">
                    <f7-badge
                        style="height: 40px; width: 40px; font-size: 14px"
                        >{{ this.$state.map.zoom.toFixed(1) }}</f7-badge
                    >
                </f7-list-item>
            </f7-list>
        </f7-card>
    </el-tooltip>
    <!--    <SwitcherMapStyle />-->
</template>
<script>
import { mapState } from 'vuex'

export default {
    components: {},
    data() {
        return {
            isF7Ready: false,
        }
    },
    computed: {
        ...mapState({
            loadedItems: state => state.devView.loadedItems,
        }),
    },
    methods: {},

    setup() {
        return {}
    },
}
</script>

<style lang="stylus" module>
.devView
  // background-color #fff
  min-width 150px
  width fit-content
  height fit-content
  margin 0
  padding 10px
  position absolute !important
  z-index 1
  border-radius var(--borderRadius1)
  top 100px;
  right var(--indent2)
  color #202020 !important
  font-size 16px
// transform rotate(90deg)
</style>
