export default function (this: any, callback) {
    const currentFocusedRegionNumber = this.focusedRegionNumber
    const focusedRegionNumberUnwatch = this.$watch(
        'focusedRegionNumber',
        focusedRegionNumber => {
            if (currentFocusedRegionNumber === focusedRegionNumber) return
            this.$nextTick(focusedRegionNumberUnwatch)
            callback?.()
        },
        { immediate: true }
    )
}
