export default async function (
    this: any,
    sourceId,
    dataType,
    sourceData,
    map = this.map,
    options?
) {
    const currentSource = map.getSource(sourceId)
    if (currentSource) {
        currentSource.setData(sourceData)
        return currentSource
    }
    let data: any = null
    const sourceDataType = sourceData?.type
    if (sourceDataType) {
        data = sourceData
    } else {
        switch (dataType) {
            case this.featureCollectionType:
                data = {
                    type: this.featureCollectionType,
                    features: sourceData,
                }
                break
            case this.featureType:
                data = {
                    type: this.featureType,
                    ...sourceData,
                }
                break
            default:
                return
        }
    }

    map.addSource(sourceId, {
        type: 'geojson',
        data,
        ...options,
    })
    const source = this.map.getSource(sourceId)
    if (!source) return
    // this.sources[sourceId] = source
    // this.activeRegionSources[sourceId] = source
    await new Promise(resolve => {
        this.sourceLoadedHandler(source, resolve)
    })
    return source
}
