export function waitUntilTilesAreLoaded(this: any, onTilesLoaded: () => any) {
    const waitLoadingTiles = () => {
        if (this.map.areTilesLoaded()) {
            this.map.off('render', waitLoadingTiles)

            setTimeout(() => {
                onTilesLoaded()
            }, 0)
            return
        }
    }
    this.map.on('render', waitLoadingTiles)
}
