import {
    PIPELINE_STATUS_OPERATING,
    PIPELINE_STATUS_PROJECTED,
    PIPELINE_STATUS_UNDER_CONSTRUCTION,
    PIPELINE_TYPE_BRANCH,
} from '@/shared/constants'
import { popupTypes } from '@/entities/Map/general/model/addLayerHoverActions/types/popupTypes'
// import { convertProjection /* multiLineStringToLineString */ } from '@/lib'
export default async function (this: any) {
    // const pipelineBranchUrl = `${this.pipelinesBranchUrlFirstPart + regionId}`
    const pipelineBranchUrl = this.pipelinesBranchUrlFirstPart
    // const source = await this.loadSource(pipelineBranchUrl)
    // if (!source) return
    const layerTypeUnderConstructionLineId = this.getLayerId(
        pipelineBranchUrl,
        PIPELINE_STATUS_UNDER_CONSTRUCTION
    )
    // const features = source.features
    // if (!features) return

    // multiLineStringToLineString(features)
    // convertProjection(source)
    // console.log('pipelines branch source:', source)

    // features.forEach(feature => {
    //     console.log('branch feature.properties', feature.properties)
    // })
    // features.forEach((feature: any) => {
    //     if (feature.properties.status === 'UNDER_CONSTRUCTION') {
    //         console.log('branch status:', feature)
    //         this.map.setCenter(feature.geometry.coordinates[0])
    //     }
    // })

    // /* this.activeRegionSources[pipelineBranchUrl] =  */ await this.addSource(
    //     pipelineBranchUrl,
    //     this.featureCollectionType,
    //     // source
    // )

    // if (!this.map.hasImage(layerTypeUnderConstructionLineId)) {
    //     const underConstructionIconSvg =
    //         this.getPipelineSvgPattern(PIPELINE_TYPE_BRANCH)
    //     if (!underConstructionIconSvg) return
    //     const loadedImg = await this.loadSvgToMapImages(
    //         underConstructionIconSvg,
    //         layerTypeUnderConstructionLineId
    //     )
    //     if (!loadedImg) return
    // }

    // const sourceLayer = this.map.getLayer(this.pipelineBranchUrl)
    // this.addLayerAfter(this.pipelineBranchUrl,{
    //     id: 'gas_pipeline_branch',
    //     source: 'infrastructure',
    //     type: 'line',
    //     layout: { 'line-cap': 'round', 'line-join': 'round' },
    //     paint: {
    //         'line-width': 3,
    //         'line-color': 'rgb(71,97,167)',
    //     },
    //     'source-layer': 'gas_pipeline_branch',
    // })
    // this.map.setLayoutProperty('gas_pipeline_branch', 'visibility', 'none')

    return async () => {
        const layerLineTypeId = this.getLayerId(
            pipelineBranchUrl,
            PIPELINE_STATUS_OPERATING
        )
        const sourceLayerOperating = this.getLayerById(
            this.map.getStyle().layers,
            layerLineTypeId
        )
        //
        // this.addLayerAfter('gas_pipeline_branch', {
        //     id: layerLineTypeId,
        //     type: this.layerTypeLine,
        //     // source: pipelineBranchUrl,
        //     source: this.infrastructureTileSourceName,
        //     'source-layer': 'gas_pipeline_branch',
        //     layout: {},
        //     paint: {
        //         // 'line-color': this.colors[38],
        //         'line-color': 'transparent',
        //         'line-width': {
        //             type: 'exponential',
        //             base: 3.5,
        //             stops: [
        //                 [0, 1.5],
        //                 [this.layerMinZoom1, 2],
        //                 [this.mapMaxZoom, 3.5],
        //             ],
        //         },
        //     },
        //     minzoom: this.layerMinZoom1,
        //     filter: ['==', 'status', PIPELINE_STATUS_OPERATING],
        // })
        // this.addLayer({
        //     id: layerLineTypeId,
        //     type: this.layerTypeLine,
        //     // source: pipelineBranchUrl,
        //     source: this.infrastructureTileSourceName,
        //     'source-layer': 'gas_pipeline_branch',
        //     layout: {},
        //     paint: {
        //         // 'line-color': this.colors[4],
        //         'line-color': this.colors[38],
        //         // 'line-color': '#007ac2',
        //         // 'line-width': 3.5,
        //         'line-width': {
        //             type: 'exponential',
        //             base: 3.5,
        //             stops: [
        //                 [0, 1.5],
        //                 [this.layerMinZoom1, 2],
        //                 [this.mapMaxZoom, 3.5],
        //             ],
        //         },
        //     },
        //     minzoom: this.layerMinZoom1,
        //     filter: ['==', 'status', PIPELINE_STATUS_OPERATING],
        // })

        const layerLineProjectedTypeId = this.getLayerId(
            pipelineBranchUrl,
            PIPELINE_STATUS_PROJECTED
        )
        const sourceLayerProjected = this.getLayerById(
            this.map.getStyle().layers,
            layerLineProjectedTypeId
        )
        // this.addLayer({
        //     id: layerLineProjectedTypeId,
        //     type: this.layerTypeLine,
        //     source: pipelineBranchUrl,
        //     layout: {},
        //     paint: {
        //         'line-dasharray': [4, 2],
        //         'line-color': this.colors[4],
        //         'line-width': 2.5,
        //         // 'line-dasharray': [2, 2],
        //     },
        //     minzoom: this.layerMinZoom1,
        //     filter: ['==', 'status', PIPELINE_STATUS_PROJECTED],
        // })

        this.addLayerHoverActions(layerLineProjectedTypeId, {
            mainLayerOptions: {
                ...sourceLayerProjected,
            },

            hoveringLayerOptions: {
                type: this.layerTypeLine,
                source: this.infrastructureTileSourceName,
                'source-layer': 'gas_pipeline_branch',
                layout: {
                    'line-cap': 'round',
                    'line-join': 'round',
                },
                paint: {
                    'line-color': 'rgba(0,0,0, 0)',
                    'line-width': 20,
                },
            },

            hoveringDecorationLayerOptions: {
                type: this.layerTypeLine,
                source: this.infrastructureTileSourceName,
                'source-layer': 'gas_pipeline_branch',
                layout: {
                    'line-cap': 'round',
                    'line-join': 'round',
                },
                paint: {
                    // 'line-color': this.colors['17'],
                    'line-color': [
                        'case',
                        ['boolean', ['feature-state', 'hover'], false],
                        this.colors['17'],
                        'rgba(0,0,0,0)',
                        // 1
                    ],
                    'line-width': [
                        'case',
                        ['boolean', ['feature-state', 'hover'], false],
                        10,
                        5,
                    ],
                },
            },

            getHtmlContent: feature => {
                const {
                    properties: { name, year },
                } = feature
                let html = ''
                if (name) {
                    html += `<div>${name}</div>`
                }
                if (year) {
                    html += `<div>Окончание работ: ${year}</div>`
                }
                return {
                    htmlContent: html,
                    type: popupTypes.default,
                }
            },
        })

        // this.addLayerAfter(
        //     `${layerLineProjectedTypeId + this.layerIdHoveringPostfix}`,
        //     {
        //         id: layerTypeUnderConstructionLineId,
        //         type: this.layerTypeLine,
        //         // source: pipelineBranchUrl,
        //         source: this.infrastructureTileSourceName,
        //         'source-layer': 'gas_pipeline_branch',
        //         paint: {
        //             'line-dasharray': [0, 3],
        //             'line-color': this.colors[4],
        //             'line-width': 4,
        //         },
        //         layout: {
        //             'line-cap': 'round',
        //             'line-join': 'round',
        //         },
        //         filter: ['==', 'status', PIPELINE_STATUS_UNDER_CONSTRUCTION],
        //         minzoom: this.layerMinZoom1,
        //     }
        // )
    }
}
