import {
    COLORS,
    LAYER_MIN_ZOOM2,
    PROPOSALS_STATUSES_COLORS,
} from '@/shared/constants'

import addEvents from './addEvents'
import htmlMarkersCluster from '@/entities/Map/gasification/model/addProposals/htmlMarkersCluster'
const proposalsSourceId = 'proposals'
const emptySource = {
    features: [],
    type: 'FeatureCollection',
}
// let isEventsAdding = false
export default async function (this: any, features) {
    const proposalsSource = this.map.getSource(proposalsSourceId)

    if (proposalsSource) {
        emptySource.features = features
        proposalsSource.setData(emptySource)

        console.log('proposals data updated')
        return
    } else {
        const generateExpression = statusCategory => {
            const caseExpression: any = ['case']

            Object.entries(PROPOSALS_STATUSES_COLORS[statusCategory]).map(
                statusData => {
                    const [statusType, color] = statusData
                    caseExpression.push([
                        '==',
                        ['get', statusCategory],
                        statusType,
                    ])
                    caseExpression.push(color)
                }
            )
            caseExpression.push('rgba(145,145,145,0.66)')
            return caseExpression
        }
        console.log(
            'generateExpression',
            generateExpression('applicationGeneralStatus')
        )

        if (features) emptySource.features = features
        console.log('emptySource', emptySource)

        // await this.addSource(
        //     proposalsSourceId,
        //     this.featureCollectionType,
        //     emptySource,
        //     this.map,
        //     {
        //         cluster: true,
        //         clusterMaxZoom: 14, // Max zoom to cluster points on
        //         clusterRadius: 50,
        //         clusterProperties: {
        //             applicationStatusColor: [
        //                 'concat',
        //                 ['concat', ['get', 'applicationGeneralStatus'], ','],
        //                 ',',
        //             ],
        //         },
        //     }
        // )

        // this.map.addLayer({
        //     id: `${proposalsSourceId}__clusters`,
        //     type: 'circle',
        //     source: proposalsSourceId,
        //     filter: ['has', 'point_count'],
        //     paint: {
        //         // 'circle-color': 'rgba(187,113,137,0.49)',
        //         'circle-color': 'rgba(255,255,255,0.72)',
        //         'circle-radius': [
        //             'step',
        //             ['get', 'point_count'],
        //             15,
        //             100,
        //             20,
        //             750,
        //             25,
        //         ],
        //     },
        // })
        // this.addLayerAfter(`${proposalsSourceId}__clusters`, {
        //     id: `${proposalsSourceId}__count`,
        //     type: 'symbol',
        //     source: proposalsSourceId,
        //     filter: ['has', 'point_count'],
        //     layout: {
        //         'text-field': ['get', 'point_count_abbreviated'],
        //         'text-font': ['Noto Sans Bold'],
        //         'text-size': 12,
        //     },
        //     paint: {
        //         'text-color': '#8a8a8a',
        //     },
        // })
        await htmlMarkersCluster.call(this, proposalsSourceId)

        const applicationGeneralStatusLayerId = `${proposalsSourceId}__applicationGeneralStatus`
        const constrStatusWithinLayerId = `${proposalsSourceId}__constrStatusWithin`
        const statusOfTheContractLayerId = `${proposalsSourceId}__statusOfTheContract`
        this.addLayerAfter('building_unverified_pattern', {
            id: constrStatusWithinLayerId,
            type: 'circle',
            source: proposalsSourceId,
            filter: ['!', ['has', 'point_count']],
            paint: {
                'circle-radius': {
                    type: 'exponential',
                    base: 3.5,
                    stops: [
                        [13, 1],
                        [15, 2],
                        [16, 4],
                    ],
                },
                'circle-color': 'transparent',
                'circle-stroke-width': {
                    type: 'exponential',
                    base: 3.5,
                    stops: [
                        [13, 1],
                        [15, 2],
                        [16, 4],
                    ],
                },
                'circle-stroke-color': generateExpression('constrStatusWithin'),
            },
            // minzoom: 13,
        })
        this.addLayerAfter(constrStatusWithinLayerId, {
            id: applicationGeneralStatusLayerId,
            type: 'circle',
            source: proposalsSourceId,
            filter: ['!', ['has', 'point_count']],
            paint: {
                'circle-radius': {
                    type: 'exponential',
                    base: 3.5,
                    stops: [
                        [13, 4],
                        [15, 5],
                        [16, 8],
                    ],
                },
                'circle-color': 'transparent',
                'circle-stroke-width': {
                    type: 'exponential',
                    base: 3.5,
                    stops: [
                        [13, 1],
                        [15, 2],
                        [16, 4],
                    ],
                },
                'circle-stroke-color': generateExpression(
                    'applicationGeneralStatus'
                ),
            },
            // minzoom: 13,
        })
        this.addLayerAfter(applicationGeneralStatusLayerId, {
            id: statusOfTheContractLayerId,
            type: 'circle',
            source: proposalsSourceId,
            filter: ['!', ['has', 'point_count']],
            paint: {
                'circle-radius': {
                    type: 'exponential',
                    base: 3.5,
                    stops: [
                        [13, 1],
                        [15, 2],
                        [16, 4],
                    ],
                },
                'circle-color': generateExpression('statusOfTheContract'),
            },
            // minzoom: 13,
        })
        // this.map.addLayer({
        //     id: `${proposalsSourceId}__timeConnToGas`,
        //     type: 'symbol',
        //     source: proposalsSourceId,
        //     filter: ['!', ['has', 'point_count']],
        //     layout: {
        //         'text-field': ['get', 'timeConnToGas'],
        //         'text-font': ['Noto Sans Regular'],
        //         'text-size': 10,
        //     },
        //     paint: {
        //         'text-color': '#464646',
        //     },
        // })

        // if (!isEventsAdding) {
        //     addEvents.call(this, {
        //         emptySource,
        //         buildingsSource,
        //         layerId: 'building',
        //         sourceId: this.infrastructureTileSourceName,
        //     })
        //     isEventsAdding = true
        // }
    }
}
