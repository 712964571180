import axios from 'axios'

import { store } from '@/app/providers'

let VUE_APP_SOURCE_SERVER_URL: string | undefined =
    process.env.VUE_APP_SOURCE_SERVER_URL
if (process.env.VUE_APP_BUILD_WITH_DEVELOPMENT_PARTS === '1') {
    VUE_APP_SOURCE_SERVER_URL = 'http://192.168.245.196:8160'
}
if (!VUE_APP_SOURCE_SERVER_URL) {
    throw new Error('SOURCE_SERVER_URL')
}

const axiosInstance = axios.create({
    baseURL: VUE_APP_SOURCE_SERVER_URL,
})
export const initAxios = app => {
    app.config.globalProperties.$axios = axiosInstance
    store.watch(
        (state: any) => state.user.keycloakToken,
        keycloakToken => {
            axiosInstance.defaults.headers.common['Authorization'] =
                keycloakToken ? 'Bearer ' + keycloakToken : ''
        },
        {
            immediate: true,
        }
    )
}
export default axiosInstance
