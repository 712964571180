<template>
    <el-collapse-item :name="objectsType">
        <template #title>
            <div :class="$style.header">
                {{ getObjectsName(objectsType) }} ({{ features.length }})
            </div>
        </template>

        <div :class="$style.objectsList">
            <div :class="$style.content">
                <div :class="$style.list" v-if="source">
                    <ObjectItem
                        v-for="feature of displayedFeatures"
                        :key="feature.id"
                        v-bind="{
                            getGdsMarkerElement,
                            getPipelineSvgPattern,
                            objectsType,
                            feature,
                            activeFeature,
                            highlightSourceAndLayerId,
                        }"
                        @update:activeFeature="activeFeature = $event"
                        @removeHighlighter="$emit('removeHighlighter')"
                    />
                    <div v-if="hiddenFeatures.length" :class="$style.divider">
                        <div
                            :class="$style.dividerContent"
                            @click="
                                isShowHiddenFeatures = !isShowHiddenFeatures
                            "
                        >
                            <template v-if="isShowHiddenFeatures">
                                скрыть уточняемые
                            </template>
                            <template v-else>
                                {{ displayedFeatures.length ? 'и ' : '' }}
                                {{ hiddenFeatures.length }} уточняемых
                            </template>
                        </div>
                    </div>
                </div>
                <div v-else :class="$style.loaderWrapper">
                    <Loader color="#777" />
                </div>
            </div>
        </div>
    </el-collapse-item>
</template>
<script>
import {
    CONSUMERS_TYPE,
    GDS_TYPE,
    PIPELINE_BRANCH_TYPE,
    PIPELINE_MAJOR_TYPE,
    PIPELINE_SETTLEMENT_TYPE,
} from '@/shared/constants'
const types = {
    CONSUMERS_TYPE,
    GDS_TYPE,
    PIPELINE_MAJOR_TYPE,
    PIPELINE_BRANCH_TYPE,
    PIPELINE_SETTLEMENT_TYPE,
}
import Loader from '@/features/general/Loader'

import ObjectItem from './components/ObjectItem'
export default {
    components: {
        Loader,
        ObjectItem,
    },
    props: {
        getGdsMarkerElement: {
            type: Function,
            required: true,
        },
        getPipelineSvgPattern: {
            type: Function,
            required: true,
        },
        objectsType: {
            type: String,
            required: true,
        },
        source: {
            type: Object,
        },
        highlightSourceAndLayerId: {
            type: String,
        },
    },
    data() {
        return {
            activeFeature: null,
            isShowHiddenFeatures: false,
        }
    },
    computed: {
        features() {
            // return this.source.features
            return this.source
        },
        displayedFeatures() {
            if (this.isShowHiddenFeatures) return this.features
            // return this.features.filter(feature => !!feature.properties.name)
            return this.features.filter(feature => !!feature.name)
        },
        hiddenFeatures() {
            // return this.features.filter(feature => !feature.properties.name)
            return this.features.filter(feature => !feature.name)
        },
    },
    methods: {
        getObjectsName(objectsType) {
            switch (objectsType) {
                case types.CONSUMERS_TYPE:
                    return 'Потребители'
                case types.GDS_TYPE:
                    return 'ГРС'
                case types.PIPELINE_MAJOR_TYPE:
                    return 'Магистральные газопроводы'
                case types.PIPELINE_BRANCH_TYPE:
                    return 'Газопроводы-отводы'
                case types.PIPELINE_SETTLEMENT_TYPE:
                    return 'Межпоселковые газопроводы'
            }
        },
    },
    beforeCreate() {
        this.types = types
    },
}
</script>

<style lang="stylus" module>
.objectsList
    display flex
    flex-direction column
    padding 0 var(--indent1)
.header
    margin-bottom var(--indent1)
    // font-weight bold
    // font-size 16px
.content
    overflow-y auto
    margin-bottom var(--indent1)
// .list
//     overflow-y auto
.loaderWrapper
    width 100%
    display flex
    justify-content center
    overflow hidden

.divider
    display flex
    width 100%
    overflow hidden
    align-items center
    margin-top 12px
    font-size 17px
    cursor pointer
    &:after, &:before
        content ''
        width 100%
        height 1px
        background-color var(--el-border-color-light)
.dividerContent
    flex-shrink 0
    padding 5px var(--indent1)
    &:hover
        text-decoration underline
</style>
