export default async function (this: any, source, callback) {
    const sourceLoadedHandler = () => {
        if (
            source &&
            this.map.getSource(source.id) &&
            this.map.isSourceLoaded(source.id)
        ) {
            this.map.off('sourcedata', sourceLoadedHandler)
            callback()
            return
        }
        if (this.map._listeners.sourcedata?.includes(sourceLoadedHandler)) {
            return
        }
        this.map.on('sourcedata', sourceLoadedHandler)
    }
    sourceLoadedHandler()
}
