import { appModuleIds } from '@/shared/constants'
import moduleActionsGas from '@/entities/Map/general/model/AppModuleController/actions/moduleActionsGas'
import moduleActionsSpg from '@/entities/Map/general/model/AppModuleController/actions/moduleActionsSpg'
import moduleActionsGasPlan from '@/entities/Map/general/model/AppModuleController/actions/moduleActionsGasPlan'
import moduleActionsAGNKS from '@/entities/Map/general/model/AppModuleController/actions/moduleActionsAGNKS'

export default async function (this: any) {
    switch (this.chosenAppModule?.id) {
        case appModuleIds.gasification:
            await moduleActionsGas.call(this)
            break
        case appModuleIds.gasificationPlan:
            await moduleActionsGasPlan.call(this)
            break
        case appModuleIds.spg:
            await moduleActionsSpg.call(this)
            break
        case appModuleIds.AGNKS:
            await moduleActionsAGNKS.call(this)
            break
        default:
            return
    }
}
