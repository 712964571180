import {
    appModuleIds,
    MOBILE_VIEW_START_WIDTH,
    stepsListIndexes,
    stepsListIndexesChangeByZoomLockedByAppModules,
} from '@/shared/constants'
import { setPartialState } from '@/shared/lib'
import {
    statsProposalsTypes,
    stepsListDirections,
} from '@/app/providers/store/modules/view/types'
import { stepsMenuRoutes } from '@/app/providers/routerCustom/stepsMenuRoutes'
import loadSource from '@/entities/Map/general/model/loadSource'
import { TreeList } from '@/widgets/MenuPanel/ui/TreeView/methods/TreeList'
import { stepsList } from '@/widgets/MenuPanel/ui/TimeLine/config/stepsLists.config'
import { inject } from 'vue'

export default {
    state: {
        isShowMapLegend: false,
        isShowMiniMap: false,
        windowViewportHeight: 0,
        windowViewportWidth: 0,
        isMenuOpen: true,
        releaseNotes: false,
        isReleaseNotesShowed: false,
        f7Router: null,
        treeLists: {
            regions: null,
            districts: null,
            settlements: null,
        },
        statsProposals: {
            regions: null,
            districts: null,
            settlements: null,
        },
        gasificationPlan: {
            planView: {
                plansCountSum: 0,
                headName: '',
            },
            years: [
                {
                    id: '',
                    label: 'все',
                },
                {
                    id: 2023,
                    label: 2023,
                },
                {
                    id: 2024,
                    label: 2024,
                },
                {
                    id: 2025,
                    label: 2025,
                },
            ],
            activeYear: {
                id: '',
                label: 'все',
            },
            regions: null,
            districts: null,
            settlements: null,
        },
        focusedProposalsStatsSubject: null,
        regionsView: {
            expandedNodes: new Set(['rootTreeNode']),
            chosenRegion: {
                id: null,
                geometry: null,
                bbox: null,
            },
        },
        districtsView: {
            expandedNodes: new Set(['rootTreeNode']),
            chosenDistrict: {
                id: null,
                geometry: null,
                bbox: null,
            },
        },
        isStepsListLocked: false,
        stepActiveId: stepsListIndexes.regionsStepIndex,
        stepPrevId: stepsListIndexes.regionsStepIndex,
        stepsListDirection: stepsListDirections.positive,
        gdsMenu: {
            state: false,
            isOpen: false,
            gdsId: 0,
            gdsName: '',
            gdsPipelinesData: null,
            gdsData: {},
            isGdsDataLoading: false,
            isActiveCityMarker: false,
        },
        menuPanelWidth: 556,
        menuOpenedCollapseItem: [
            'miniMap',
            'mapLegend',
            'populationDensity',
            'districtInfo',
        ],
    },
    mutations: {
        setViewPartialState: setPartialState,
        setMenuOpenByDevice: (state, deviceType) => {
            console.log('deviceType', deviceType)
            // if (deviceType === 'smartphone') {
            //     state.isMenuOpen = !state.isMenuOpen
            // }
        },
        setStepActiveId: (state, id: number | string) => {
            if (state.stepActiveId !== state.stepPrevId) {
                state.stepPrevId = state.stepActiveId
            }
            state.stepActiveId = id.toString()
        },
        setStepsListDirection: (state, direction: stepsListDirections) => {
            state.stepsListDirection = direction
        },
        setIsStepsListChangeLocked: (state, { id, chosenAppModule }) => {
            state.isStepsListLocked =
                stepsListIndexesChangeByZoomLockedByAppModules[
                    chosenAppModule?.id
                ].includes(id)
            // console.log('setIsStepsListChangeLocked', stepsListIndexesChangeByZoomLocked.includes(id))
        },
        setFocusedProposalsStatsSubject: (
            state,
            focusedProposalsStatsSubject
        ) => {
            state.focusedProposalsStatsSubject = focusedProposalsStatsSubject
        },
        //gasPlans
        setActiveYear: (state, year) => {
            state.gasificationPlan.activeYear = year
        },

        //Proposals
        setSettlementsStats: (state, fetchedSettlementsStats) => {
            state.statsProposals.settlements = fetchedSettlementsStats
        },
        setSettlementsList: state => {
            const features: any[] = state.statsProposals.settlements
            if (!features) return

            state.treeLists.settlements = Object.entries(features)
                .map(settlement => {
                    const [settlementId, settlementProperties] = settlement

                    return new TreeList(
                        settlementProperties.name,
                        null,
                        settlementProperties.centroid,
                        settlementId,
                        settlementProperties.all_proposal_count,
                        null
                    )
                })
                .sort((a, b) => {
                    if (a.label < b.label) return -1
                    if (a.label > b.label) return 1
                    return 0
                })
        },
        setRegionsStats: (state, fetchedRegionsStats) => {
            state.statsProposals.regions = fetchedRegionsStats
        },
        setDistrictsStats: (state, fetchedDistrictsStats) => {
            state.statsProposals.districts = fetchedDistrictsStats
        },
        setRegionsPlansCount: (state, fetchedRegionsStats) => {
            state.gasificationPlan.regions = fetchedRegionsStats
        },
        setDistrictsPlansCount: (state, fetchedDistrictsPlans) => {
            state.gasificationPlan.districts = fetchedDistrictsPlans
        },
    },
    getters: {
        stepTitle: state =>
            stepsList.find(step => step.id === state.stepActiveId)?.title,
        isMobileView: state => {
            return state.windowViewportWidth < MOBILE_VIEW_START_WIDTH
        },
        stepActiveComponent: state =>
            stepsMenuRoutes.find(step => step.id === state.stepActiveId)
                ?.component,
    },
    actions: {
        toggleMenuOpenByDevice: ({ commit, rootState }) => {
            commit('setMenuOpenByDevice', rootState.deviceType)
        },
        handleClickStepActiveId: ({ dispatch, state }, id) => {
            if (id >= state.stepActiveId) return

            dispatch('setStepActiveId', id)
        },
        setStepActiveId: ({ commit, dispatch, state, rootState }, id) => {
            // console.log('state.stepActiveId', state.stepActiveId, id)
            if (state.stepActiveId === id) return

            commit('setIsStepsListChangeLocked', {
                id,
                chosenAppModule: rootState.chosenAppModule,
            })
            dispatch('setStepsListDirectionByStep', id)
            commit('setStepActiveId', id)
        },
        setStepsListDirectionByStep: ({ commit, state }, id) => {
            if (id > state.stepActiveId) {
                commit('setStepsListDirection', stepsListDirections.positive)
            } else {
                commit('setStepsListDirection', stepsListDirections.negative)
            }
        },
        setStepsListDirectionByZoom: (
            { commit, rootState },
            zoomValue: number
        ) => {
            if (zoomValue > rootState.map.zoom) {
                commit('setStepsListDirection', stepsListDirections.positive)
            } else {
                commit('setStepsListDirection', stepsListDirections.negative)
            }
        },
        setFocusedProposalsStatsSubject: ({ commit, state, rootState }) => {
            switch (state.stepActiveId) {
                case stepsListIndexes.regionsStepIndex:
                    commit('setFocusedProposalsStatsSubject', {
                        subject: rootState.focusedRegionName,
                        data: state.statsProposals?.[
                            statsProposalsTypes.regions
                        ]?.[
                            rootState.focusedRegionNumber
                                ?.toString()
                                .padStart(2, '0')
                        ],
                    })
                    break
                case stepsListIndexes.districtsStepIndex:
                    commit('setFocusedProposalsStatsSubject', {
                        subject: rootState.focusedRegionName,
                        data: state.statsProposals?.[
                            statsProposalsTypes.regions
                        ]?.[
                            rootState.focusedRegionNumber
                                ?.toString()
                                .padStart(2, '0')
                        ],
                    })
                    break
                case stepsListIndexes.districtInfoStepIndex:
                    commit('setFocusedProposalsStatsSubject', {
                        subject: rootState.focusedDistrictName,
                        data: state.statsProposals?.[
                            statsProposalsTypes.districts
                        ]?.[rootState.focusedDistrictNumber],
                    })
                    break
                default:
                    commit('setFocusedProposalsStatsSubject', {
                        disabled: true,
                    })
            }
        },

        //stats fetch model
        setSettlementsStats: async (
            { commit, state, rootState },
            appContext
        ) => {
            if (
                !state.districtsView.chosenDistrict.id &&
                !rootState.focusedDistrictNumber
            )
                return

            const res = await appContext.loadSource(
                `/gasification/proposal/district/stats/${
                    state.districtsView.chosenDistrict.id ||
                    rootState.focusedDistrictNumber
                }`
            )
            commit('setSettlementsStats', res)
        },
        setRegionsStats: async ({ commit, rootState }, appContext) => {
            if (rootState.chosenAppModule?.id !== appModuleIds.gasification)
                return

            const allRegionsStats = await appContext.loadSource(
                '/gasification/proposal/russia/stats'
            )
            commit('setRegionsStats', allRegionsStats)
        },
        setDistrictsStats: async ({ commit, state, rootState }, appContext) => {
            if (rootState.chosenAppModule?.id !== appModuleIds.gasification)
                return

            const allDistrictsStats = await appContext.loadSource(
                `/gasification/proposal/region/stats/${
                    state.regionsView.chosenRegion.id ||
                    rootState.focusedRegionNumber
                }`
            )

            commit('setDistrictsStats', allDistrictsStats)
        },
        setRegionsPlansCount: async (
            { commit, rootState, state },
            appContext
        ) => {
            if (rootState.chosenAppModule?.id !== appModuleIds.gasificationPlan)
                return

            if (state.gasificationPlan.activeYear.id) {
                const allRegionsPlansCount = await appContext.loadSource(
                    `/gasification/plan/region/year/count/${state.gasificationPlan.activeYear.id}`
                )
                commit('setRegionsPlansCount', allRegionsPlansCount)
                return
            }
            const allRegionsPlansCount = await appContext.loadSource(
                `/gasification/plan/region/count`
            )
            commit('setRegionsPlansCount', allRegionsPlansCount)
        },
        setDistrictsPlansCount: async (
            { commit, state, rootState },
            appContext
        ) => {
            if (rootState.chosenAppModule?.id !== appModuleIds.gasificationPlan)
                return

            if (state.gasificationPlan.activeYear.id) {
                const allDistrictsPlansCount = await appContext.loadSource(
                    `/gasification/plan/district/year/count/${
                        state.regionsView.chosenRegion.id ||
                        rootState.focusedRegionNumber
                    }/${state.gasificationPlan.activeYear.id}`
                )
                commit('setDistrictsPlansCount', allDistrictsPlansCount)
                return
            }
            const allDistrictsPlansCount = await appContext.loadSource(
                `/gasification/plan/district/count/${
                    state.regionsView.chosenRegion.id ||
                    rootState.focusedRegionNumber
                }`
            )
            commit('setDistrictsPlansCount', allDistrictsPlansCount)
        },
    },
    modules: {},
}
