import { EChartsOption } from 'echarts'
import { Feature, Point } from 'geojson'
import { ANGKSStatusesColors } from '@/entities/ANGKS/model/AGNKSEntity.types'
import { agnks_statuses_series_name } from '@/entities/Map/AGNKS/model/Charts/constants'

export const pieChartShell = (features: Feature<Point>[]): EChartsOption => {
    const statusesSet = new Set(
        features.map(feature => feature.properties?.status)
    )
    const featuresWithCountByStatus: { name: string; value: number }[] = []
    statusesSet.forEach((brandName: string) => {
        const count = features.filter(
            feature => feature.properties?.status === brandName
        ).length
        featuresWithCountByStatus.push({
            name: brandName,
            value: count,
        })
    })

    return {
        graphic: {
            elements: [
                {
                    type: 'rect',
                    shape: {
                        x: 0,
                        y: 0,
                        width: '100%',
                        height: '100%',
                    },
                    style: {
                        fill: 'rgba(255, 255, 255, 0.5)', // Semi-transparent white
                        shadowBlur: 10, // Apply blur effect
                    },
                    z: -1, // Place behind the chart
                },
            ],
        },
        tooltip: {
            trigger: 'item',
        },
        legend: {
            show: false,
            top: '5%',
            left: 'center',
        },
        title: {
            text: `${features.length}`,
            textStyle: {
                color: '#000',
                textBorderColor: '#f7f7f7',
                textBorderWidth: 3,
                fontSize: 20,
                fontWeight: 'bold',
                // align: 'center',
                // position: 'center',
            },
            triggerEvent: true,
            left: 'center',
            top: 'center',
        },
        series: [
            {
                name: agnks_statuses_series_name,
                type: 'pie',
                radius: ['50%', '70%'],
                avoidLabelOverlap: false,
                itemStyle: {
                    borderRadius: 10,
                    borderColor: '#fff',
                    borderWidth: 0,
                },
                label: {
                    show: false,
                    position: 'center',
                    valueAnimation: true,
                    fontSize: 25,
                    fontWeight: 'bold',
                    formatter: (params: any) => {
                        return params.data?.value
                    },
                    color: '{data.itemStyle.color}',
                    textBorderColor: '#f7f7f7',
                    textBorderWidth: 3,
                },
                emphasis: {
                    label: {
                        show: true,
                    },
                },
                labelLine: {
                    show: false,
                },
                data: featuresWithCountByStatus.map(statusData => {
                    return {
                        value: statusData.value,
                        name: statusData.name || 'other',
                        itemStyle: {
                            color:
                                ANGKSStatusesColors[statusData.name] ||
                                '#838383',
                        },
                    }
                }),
            },
        ],
    }
}
