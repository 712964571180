import { COLORS, LAYER_MIN_ZOOM2 } from '@/shared/constants'

import addEvents from './addEvents'
const buildingsSourceId = 'buildings'
const emptySource = {
    features: [],
    type: 'FeatureCollection',
}
let isEventsAdding = false
export default async function (this: any) {
    const buildingsSource = this.map.getSource(buildingsSourceId)

    await this.addSource(
        buildingsSourceId,
        this.featureCollectionType,
        emptySource
    )
    // const buildingsSource = this.map.getSource(buildingsSourceId)
    return () => {
        this.addLayer({
            isLayerSave: false,
            // id: buildingsSourceId,
            id: 'building',
            // type: this.layerTypeFill,
            type: 'fill-extrusion',
            // source: buildingsSourceId,
            source: this.infrastructureTileSourceName,
            'source-layer': 'building',
            layout: {},
            // paint: {
            //     'fill-color': '#f00',
            // },
            paint: {
                // 'fill-extrusion-color': '#aaa',
                'fill-extrusion-color': [
                    'case',
                    ['boolean', ['feature-state', 'isHovered'], false],
                    COLORS[17],
                    ['boolean', ['get', 'connected'], true],
                    this.colors[4],
                    '#D3D3D3',
                ],

                // Use an 'interpolate' expression to
                // add a smooth transition effect to
                // the buildings as the user zooms in.
                // 'fill-extrusion-height': [
                //     'interpolate',
                //     ['linear'],
                //     ['zoom'],
                //     15,
                //     0,
                //     15.05,
                //     ['get', 'height'],
                // ],
                'fill-extrusion-height': 10,
                'fill-extrusion-base': [
                    'interpolate',
                    ['linear'],
                    ['zoom'],
                    15,
                    0,
                    15.05,
                    ['get', 'min_height'],
                ],
                'fill-extrusion-opacity': 0.85,
            },
            minzoom: LAYER_MIN_ZOOM2,
        })
        if (!isEventsAdding) {
            addEvents.call(this, {
                emptySource,
                buildingsSource,
                layerId: 'building',
                sourceId: this.infrastructureTileSourceName,
            })
            isEventsAdding = true
        }
    }
}
