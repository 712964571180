<template>
    <div :class="$style.logotypes">
        <!-- <SvgPart1 :class="$style.svg1" /> -->
        <a href="https://cdu.ru/" target="_blank" :class="$style.cduAndReaLink">
            <CduAndRea :class="$style.cduAndReaSvg" />
        </a>
    </div>
</template>
<script>
import CduAndRea from './CduAndRea'
// import SvgPart2 from './Group2377'
// import SvgPart1 from './Group2379'

export default {
    components: {
        // SvgPart1,
        // SvgPart2,
        CduAndRea,
    },
}
</script>

<style lang="stylus" module>
.logotypes
    display inline-flex
    //background-color var(--f7-page-bg-color)
    // backdrop-filter blur(10px)
    //width 260px
    padding 0 3px
    border-radius var(--f7-button-border-radius)
    align-items center
    justify-content center
    // box-shadow var(--f7-button-raised-box-shadow)
    // transition var(--transitionDuration1)
    svg
        // filter drop-shadow( 3px 3px 2px rgba(255, 255, 255, 1))
        position relative
        right -6px
        // filter url(#shadow2)
    // &:hover
    //     background-color #fff
    //     width 500px

.cduAndReaLink
    flex-shrink 0
    width 100%

.cduAndReaSvg
    width 100%
    fill #505050


.svg1
    position relative
    width 100%
    height auto
</style>
