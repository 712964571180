import {
    GENERAL_STATUSES_MAPPING_RU,
    stepsListIndexes,
} from '@/shared/constants'
import { statsProposalsTypes } from '@/app/providers/store/modules/view/types'

const mapStatsObject = (subject: string, statOfSubject) => {
    return {
        subject: subject,
        data: {
            default: {
                name: 'Всего объектов АГНКС',
                value: statOfSubject?.all_proposal_count,
            },
            finished: {
                name: GENERAL_STATUSES_MAPPING_RU.OPERATING,
                value: statOfSubject?.finished_count,
            },
            in_work: {
                name: GENERAL_STATUSES_MAPPING_RU.UNDER_CONSTRUCTION,
                value: statOfSubject?.in_work_count,
            },
        },
    }
}

export function mapFocusedProposalsStatsSubject_AGNKS(this: any, state) {
    switch (this.$state.view.stepActiveId) {
        case stepsListIndexes.regionsStepIndex:
            return mapStatsObject('Россия', this.statsRussia)
        case stepsListIndexes.districtsStepIndex:
            return mapStatsObject(this.focusedRegionName, this.statsRegion)
        default:
            return mapStatsObject(this.focusedRegionName, this.statsRegion)
    }
}
