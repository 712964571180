const cacheLifetimeMs = 1000 * 120
export default async function <ReturnedData = any>(
    this: any,
    sourceUrl,
    lifetimeMs = cacheLifetimeMs,
    signal?: AbortSignal
): Promise<ReturnedData | undefined | null> {
    const getSourceFromUrl = async () => {
        if (this.$state.loadingSourceUrls.includes(sourceUrl)) return

        this.$commit('setPartialState', {
            loadingSourceUrls: [...this.$state.loadingSourceUrls, sourceUrl],
        })
        const response = await this.$axios
            .get(sourceUrl, { signal })
            .finally(() => {
                const sourceUrlIndex =
                    this.$state.loadingSourceUrls.indexOf(sourceUrl)
                if (sourceUrlIndex === -1) return
                this.$commit('setPartialState', {
                    loadingSourceUrls: this.$state.loadingSourceUrls.filter(
                        (url, index) => index !== sourceUrlIndex
                    ),
                })
            })
        const source = response?.data
        if (!source) return
        await this.$state.indexedDbController.set(
            sourceUrl,
            JSON.stringify({
                createdAt: +new Date(),
                source,
            })
        )
        return source
    }

    try {
        let source = null
        const recordFromCache = await this.$state.indexedDbController.get(
            sourceUrl
        )
        if (recordFromCache) {
            const parsedCacheRecord = JSON.parse(recordFromCache)

            const {
                createdAt: cacheRecordCreatedAt,
                source: cacheRecordSource,
            } = parsedCacheRecord

            const currentDateMs = +new Date()

            if (
                lifetimeMs > -1 &&
                currentDateMs - cacheRecordCreatedAt > lifetimeMs
            ) {
                await this.$state.indexedDbController.remove(sourceUrl)
                source = await getSourceFromUrl()
            } else {
                source = cacheRecordSource
            }
        } else {
            source = await getSourceFromUrl()
        }

        this.$state.sources[sourceUrl] = source

        this.$store.commit('setUserPartialState', {
            allowAccess: true,
        })

        return source
    } catch (error) {
        // console.error('errorLoadSource', error)

        if (error.__CANCEL__) {
            throw error
        }

        if (!error.response) {
            this.$store.commit('setPartialState', {
                error: {
                    isActive: true,
                    message: `Нет доступа`,
                    type: 'error',
                    statusCode: '',
                },
            })
            return
        }

        switch (error.response.status) {
            case 401:
                this.$store.commit('setPartialState', {
                    error: {
                        isActive: true,
                        message: `Ошибка авторизации (${error.response.status}). Попробуйте сменить пользователя или перезайти в систему.`,
                        type: 'error',
                        statusCode: error.response.status,
                    },
                })
                break
            case 403:
                // this.$store.commit('setPartialState', {
                //     error: {
                //         isActive: true,
                //         message: `Недоступно для данного пользователя (${error.response.status}). Попробуйте сменить пользователя или перезайти в систему.`,
                //         type: 'error',
                //         statusCode: error.response.status,
                //     },
                // })
                break
            case 404:
                this.$store.commit('setPartialState', {
                    error: {
                        isActive: true,
                        message: `Не найдено (${error.response.status}).`,
                        type: 'error',
                        statusCode: error.response.status,
                    },
                })
                break
            case 500:
                this.$store.commit('setPartialState', {
                    error: {
                        isActive: true,
                        message: `Внутренняя ошибка сервера (${error.response.status}).`,
                        type: 'error',
                        statusCode: error.response.status,
                    },
                })
                break
            case 502:
                this.$store.commit('setPartialState', {
                    error: {
                        isActive: true,
                        message: `Внутренняя ошибка сервера (${error.response.status}).`,
                        type: 'error',
                        statusCode: error.response.status,
                    },
                })
                break
            case 503:
                this.$store.commit('setPartialState', {
                    error: {
                        isActive: true,
                        message: `Сервис недоступен (${error.response.status}).`,
                        type: 'error',
                        statusCode: error.response.status,
                    },
                })
                break
        }
        console.log(error.response.status)
        console.log(error.response.data)
    }
    // try {
    //     await this.indexedDbController.remove(sourceUrl)
    //     return await this.loadSource(sourceUrl)
    // } catch (error) {
    //     console.error(error)
    // }
}
