import {
    IMG_AZS_COMPANY_NOVATEK,
    IMG_CITY_CAPITAL_DISTRICT,
    IMG_CITY_CAPITAL_REGION,
    IMG_CITY_OTHER_CONNECTED,
    IMG_CITY_OTHER_DISCONNECTED,
    IMG_CITY_OTHER_PERSPECTIVE,
    IMG_GDS_STATUS_CLOSED,
    IMG_GDS_STATUS_LIMITED,
    IMG_GDS_STATUS_OPERATING,
    IMG_GDS_STATUS_PROJECTED,
    IMG_GZS_COMPANY_NOVATEK,
    IMG_MAZK_COMPANY_NOVATEK,
} from '@/shared/constants'
const addCityImageToMap = async function (this: any, type, size = 22) {
    const elementHtml = this.getCityMarkerElement(type, size)
    return await this.loadSvgToMapImages(elementHtml, type)
}
const addGdsImageToMap = async function (this: any, type, size = 22) {
    const element = this.getGdsMarkerElement(type, size)
    return await this.loadSvgToMapImages(element.outerHTML, type)
}
const addAzsImageToMap = async function (this: any, type, size = 20) {
    const element = this.getAzsMarkerElement(type, size)
    return await this.loadSvgToMapImages(element.outerHTML, type)
}
const addGzsImageToMap = async function (this: any, type, size = 20) {
    const element = this.getGzsMarkerElement(type, size)
    return await this.loadSvgToMapImages(element.outerHTML, type)
}
const addMazkImageToMap = async function (this: any, type, size = 20) {
    const element = this.getMazkMarkerElement(type, size)
    return await this.loadSvgToMapImages(element.outerHTML, type)
}

export default async function (this: any) {
    // const underConstructionIconSvg = this.getPipelineSvgPattern(
    //     PIPELINE_TYPE_INTER_SETTLEMENT
    // )
    // const loadedImg = await this.loadSvgToMapImages(
    //     underConstructionIconSvg,
    //     layerTypeUnderConstructionLineId
    // )

    await Promise.all([
        addCityImageToMap.call(this, IMG_CITY_CAPITAL_DISTRICT, 13),
        addCityImageToMap.call(this, IMG_CITY_CAPITAL_REGION, 18),
        addCityImageToMap.call(this, IMG_CITY_OTHER_CONNECTED, 10),
        addCityImageToMap.call(this, IMG_CITY_OTHER_DISCONNECTED, 10),
        addCityImageToMap.call(this, IMG_CITY_OTHER_PERSPECTIVE, 10),
        addGdsImageToMap.call(this, IMG_GDS_STATUS_OPERATING),
        addGdsImageToMap.call(this, IMG_GDS_STATUS_CLOSED),
        addGdsImageToMap.call(this, IMG_GDS_STATUS_PROJECTED),
        addGdsImageToMap.call(this, IMG_GDS_STATUS_LIMITED),
        addAzsImageToMap.call(this, IMG_AZS_COMPANY_NOVATEK),
        addGzsImageToMap.call(this, IMG_GZS_COMPANY_NOVATEK),
        addMazkImageToMap.call(this, IMG_MAZK_COMPANY_NOVATEK),
    ])
}
