<template>
    <div
        :class="$style.navWidget"
        :style="{ left: `calc(${menuPanelWidth}px + 20px)` }"
    >
        <!--        <NavRow-->
        <!--            :class="$style.navRow"-->
        <!--            v-model:value="viewMode"-->
        <!--            :options="navOptions"-->
        <!--        ></NavRow>-->

        <Transition :name="$style.slide">
            <ProposalInfo2
                v-bind="{ focusedProposalsStatsSubject }"
                :class="$style.proposalInfo"
            />
        </Transition>
    </div>
</template>

<script>
import ProposalInfo2 from './ui/ProposalInfo/ProposalInfo2.vue'
import { mapState } from 'vuex'

export default {
    props: {
        focusedProposalsStatsSubject: {
            type: Object,
            required: true,
        },
    },
    components: {
        ProposalInfo2,
    },
    computed: {
        ...mapState({
            menuPanelWidth: state => state.view.menuPanelWidth,
        }),
    },
    mounted() {
        console.log(
            'focusedProposalsStatsSubject',
            this.focusedProposalsStatsSubject
        )
    },
}
</script>

<style lang="stylus" module>
.navWidget
    position absolute
    z-index 101
    .navRow, .proposalInfo
        position absolute
    .proposalInfo
        //top 60px
        //left 10px
    .slide
        &:global(-enter-active), &:global(-leave-active)
            transition all .2s ease;
            opacity 1
        &:global(-enter-from), &:global(-leave-to)
            opacity 0
            transform translateX(-10px)
</style>
