import {
    COLORS,
    LAYER_MIN_ZOOM1,
    MAP_MAX_ZOOM,
    PIPELINE_STATUS_OPERATING,
    PIPELINE_STATUS_PROJECTED,
    PIPELINE_STATUS_UNDER_CONSTRUCTION,
    spriteSizes,
} from '@/shared/constants'
import setRegionsStats from '@/pages/RegionsView/methods/setRegionsStats'

export default function (this: any) {
    // document.body.style.backgroundColor = COLORS[19]
    document.body.style.backgroundColor = 'transparent'
    this.maplibregl = null
    // this.mapStyle = this.getMapStyle()
    this.mapStyle = null
    this.map = null
    let mapReadyPromiseResolve
    this.mapReadyPromise = new Promise(resolve => {
        mapReadyPromiseResolve = resolve
    })
    this.mapReadyPromise.resolve = mapReadyPromiseResolve
    this.miniMap = null
    this.miniMapInstance = null
    this.mapCanvasStyle = null
    // this.layers = {}
    // this.activeRegionSources = {}
    this.regionsFeatures = []
    this.activeRegionLayers = {}
    this.activeRegionMarkers = {}
    this.mainTileSourceName = 'gaslinetiles'
    this.infrastructureTileSourceName = 'infrastructure'
    this.gdsTileSourceName = 'gds'
    this.cityTileSourceName = 'city'
    this.cityAreaSourceName = 'cityArea'
    this.tileRegionSourceLayer = 'region'
    this.azsTileSourceName = 'azs'
    // this.tileDistrictSourceLayer = 'district'
    this.tileDistrictLinesSourceLayer = 'districtLines'

    this.tileTownSourceLayer = 'town'
    this.tileCityAreaSourceLayer = 'city_area'
    this.tileCityAreaLineLayer = 'city_area_line'
    this.tileCityAreaLabelsSourceLayer = 'city_areaLabels'
    this.regionsUrl = '/regions'
    // this.consumerUrlFirstPart = '/consumer/'
    this.consumerUrlFirstPart = 'consumer'
    // this.pipelinesMajorUrl = '/pipeline/major'
    this.pipelinesMajorUrl = 'gas_pipeline_major'
    this.pipelinesConsumerUrl = 'gas_pipeline_consumer'
    // this.pipelinesBranchUrlFirstPart = '/pipeline/branch/'
    this.pipelinesBranchUrlFirstPart = 'gas_pipeline_branch'
    // this.pipelinesSettlementUrlFirstPart = '/pipeline/settlement/'
    this.pipelinesSettlementUrlFirstPart = 'gas_pipeline_inter_settlement'
    // this.gdsUrlFirstPart = '/gds/'
    this.gdsUrlFirstPart = 'gas_distribution_station'
    this.gdsIconLayers = spriteSizes.map(
        sizes => this.gdsUrlFirstPart + '_' + sizes
    )
    this.gdsIcon = 'gas_distribution_station_icon'
    this.gdsLabelLayer = 'gas_distribution_station_label'
    this.gdsLayerIds = [...this.gdsIconLayers, this.gdsLabelLayer]
    // this.districtsOfRegionUrlFirstPart = '/district/'
    // this.densityUrlFirstPart = '/density/'
    // this.cityUrlFirstPart = '/city/'
    this.layerTypeLine = 'line'
    this.layerTypeFill = 'fill'
    this.layerTypeCircle = 'circle'
    this.layerTypeSymbol = 'symbol'

    this.layerIdHoveringPostfix = '_hovering'
    this.layerIdHoveringDecorationPostfix = '_hoveringDecoration'

    this.featureCollectionType = 'FeatureCollection'
    this.featureType = 'Feature'

    this.layerMinZoom1 = LAYER_MIN_ZOOM1
    this.mapMaxZoom = MAP_MAX_ZOOM
    this.initialZoomRange = 3

    this.colors = COLORS
    this.densityPaletteColors = [
        COLORS[12],
        COLORS[13],
        COLORS[14],
        COLORS[15],
        COLORS[16],
    ]
    this.regionFillLayerId = this.getLayerId(
        this.regionsUrl,
        this.layerTypeFill
    )
    this.underConstructionMajorPipelineLayerId = this.getLayerId(
        this.pipelinesMajorUrl,
        PIPELINE_STATUS_UNDER_CONSTRUCTION
    )
    this.operatingMajorPipelineLayerId = this.getLayerId(
        this.pipelinesMajorUrl,
        PIPELINE_STATUS_OPERATING
    )
    this.projectedMajorPipelineLayerId = this.getLayerId(
        this.pipelinesMajorUrl,
        PIPELINE_STATUS_PROJECTED
    )

    this.gdsSymbolIconTypeId = this.getLayerId(
        this.gdsUrlFirstPart,
        this.layerTypeSymbol,
        'icon'
    )
    this.gdsSymbolLabelTypeId = this.getLayerId(
        this.gdsUrlFirstPart,
        this.layerTypeSymbol,
        'label'
    )

    this.consumersCircleTypeId = this.getLayerId(
        this.consumerUrlFirstPart,
        this.layerTypeCircle
    )
    this.consumersCircleTypeId_small = this.consumersCircleTypeId + '_small'
    this.consumersCircleTypeId_main_big =
        this.consumersCircleTypeId + '_main_big'
    this.consumersCircleTypeId_cluster = this.getLayerId(
        this.consumerUrlFirstPart,
        this.layerTypeCircle,
        'cluster'
    )
    this.consumersCircleTypeId_symbol = this.getLayerId(
        this.consumerUrlFirstPart,
        this.layerTypeSymbol
    )
}
