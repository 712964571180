import { stepsListIndexes, ZOOM_HIERARCHY_MIN_LEVEL } from '@/shared/constants'
import gasificationMapStyle from '@/entities/Map/gasification/model/getMapStyle/gasificationMapStyle'
import addPlans from '@/entities/Map/gasificationPlan/addPlans'
import setAsyncLayersGasPlans from '@/entities/Map/gasificationPlan/model/setAsyncLayersGasPlans'

export default async function (this: any) {
    await this.setMapStyle(gasificationMapStyle)
    await this.addRegions()
    await setAsyncLayersGasPlans.call(this, this.mapStyle.layers)
    await this.setActiveRegion(this.focusedRegionNumber)
    await addPlans.call(this)

    this.$VUE_APP_BUILD_WITH_DEVELOPMENT_PARTS &&
        this.setChangedFeaturesInViewport(
            'district',
            ZOOM_HIERARCHY_MIN_LEVEL[stepsListIndexes.districtsStepIndex]
        )
}
