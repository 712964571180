export default function (
    this: any,
    layerIds,
    clusterExpansionZoomFn?: (clusterId: number) => number
) {
    layerIds.forEach((layerId, index: number) => {
        const clickHandler = async event => {
            if (!event.defaultPrevented) {
                console.log(
                    'outside click Gds',
                    event,
                    event.originalEvent.defaultPrevented
                )
            }

            const features = this.map.queryRenderedFeatures(event.point, {
                layers: [layerId],
            })
            const firstFeature = features?.[0]

            if (!firstFeature) return

            console.log('firstFeature', firstFeature, clusterExpansionZoomFn)
            const zoom = clusterExpansionZoomFn
                ? clusterExpansionZoomFn(firstFeature.properties.cluster_id)
                : await this.map
                      .getSource(firstFeature.layer.source)
                      .getClusterExpansionZoom(
                          firstFeature.properties.cluster_id
                      )

            if (zoom) {
                this.map.flyTo({
                    center: firstFeature.geometry.coordinates,
                    zoom: zoom,
                    speed: 1.9,
                })
            }
        }
        const mouseenterHandler = () => {
            this.mapCanvasStyle.cursor = 'help'
        }
        const mouseleaveHandler = () => {
            this.mapCanvasStyle.cursor = ''
        }

        this.map.on('click', layerId, clickHandler)
        this.map.on('mousemove', layerId, mouseenterHandler)
        this.map.on('mouseleave', layerId, mouseleaveHandler)
    })
}
