import { GDS_DATA_CITY_AREA, GDS_DATA_GDS_INFO } from '@/shared/constants'

export default function (this: any, type, gdsId) {
    let url = ''
    switch (type) {
        case GDS_DATA_CITY_AREA:
            url = `/city_area/gds/${gdsId}`
            break
        case GDS_DATA_GDS_INFO:
            url = `/infrastructure/gds/${gdsId}`
            break
    }
    return this.loadSource(url).then(source => {
        // console.log(`${url} source:`, source)
        this.$state.view.gdsMenu.gdsData[type] = source
        this.$state.view.gdsMenu.isGdsDataLoading = false
    })
}
