import { popupTypes } from '@/entities/Map/general/model/addLayerHoverActions/types/popupTypes'
import setPopupData from '@/entities/Map/general/model/setPopupData'
import toggleMoveWheelEvent from '@/entities/Map/general/model/initMap/addEvents/removeEventsActions/toggleMoveWheelEvent'

export default function (this: any, source, hoveringLayerId, getHtmlContent) {
    let activePopup: any = null
    const sourceAndSourceLayer = {
        source,
    }
    let activePipelineSource: any = ''
    let prevPopupId

    const clickHandler = event => {
        // isClicked = true
        const feature = event.features?.[0]
        console.log('clicked feature', event.features?.[0])
        if (!feature) return
        // const {
        //     lngLat: { lng, lat },
        // } = event
        activePopup = setPopupData.call(
            this,
            feature,
            event.lngLat,
            prevPopupId,
            getHtmlContent
        )
    }

    let hoveredFeatureId = ''
    const mouseenterHandler = event => {
        const feature = event.features[0]
        const featureId = feature.id
        if (!featureId) return
        this.mapCanvasStyle.cursor = 'help'
        hoveredFeatureId = featureId
        activePipelineSource = feature.layer['source-layer']

        this.map.setFeatureState(
            {
                id: hoveredFeatureId,
                sourceLayer: activePipelineSource,
                ...sourceAndSourceLayer,
            },
            { hover: true }
        )
        // console.log(
        //     'pipelineAction',
        //     this.map.getFeatureState({
        //         id: hoveredFeatureId,
        //         sourceLayer: activePipelineSource,
        //         ...sourceAndSourceLayer,
        //     }),
        //     event.features
        // )
    }

    const mousemoveHandler = event => {
        const feature = event.features[0]
        if (!feature) return
        const {
            lngLat: { lng, lat },
        } = event
        const lngLat = [lng, lat]
        // if (activePopup) {
        //     activePopup.setLngLat(lngLat)
        // } else {
        //     activePopup = setPopup(feature, lngLat)
        // }
    }

    const mouseleaveHandler = () => {
        if (!hoveredFeatureId) return
        this.mapCanvasStyle.cursor = ''
        this.map.setFeatureState(
            {
                id: hoveredFeatureId,
                sourceLayer: activePipelineSource,
                ...sourceAndSourceLayer,
            },
            { hover: false }
        )
        // if (activePopup) {
        //     activePopup.remove()
        //     activePopup = null
        // }
    }

    this.map.on('click', hoveringLayerId, clickHandler)
    this.map.on('mouseenter', hoveringLayerId, mouseenterHandler)
    this.map.on('mouseleave', hoveringLayerId, mouseleaveHandler)
    this.map.on('mousemove', hoveringLayerId, mousemoveHandler)
    this.map.on('mousemove', hoveringLayerId, mousemoveHandler)

    this.changeFocusedRegionHandler(() => {
        // this.map.off('click', hoveringLayerId, clickHandler)
        // this.map.off('mouseenter', hoveringLayerId, mouseenterHandler)
        // this.map.off('mouseleave', hoveringLayerId, mouseleaveHandler)
        // this.map.off('mousemove', hoveringLayerId, mousemoveHandler)
        activePopup?.remove?.()
    })
}
