// import './styles.css'

// import { point } from '@turf/turf'
// import maplibregl from 'maplibre-gl/dist/maplibre-gl'
import maplibregl from 'maplibre-gl/dist/maplibre-gl'
export interface MiniMapOptions {
    container?: HTMLDivElement | null
    trackingAreaMarkerElement: HTMLDivElement | null
    id: string
    width: number
    height: number
    style: string
    center: number[]
    zoom: number
    bounds?: any
    maxBounds?: any
    zoomAdjust: any
    zoomLevels: number[][]
    lineColor: string
    lineWidth: number
    lineOpacity: number
    fillColor: string
    fillOpacity: number
    dragPan: boolean
    scrollZoom: boolean
    boxZoom: boolean
    dragRotate: boolean
    keyboard: boolean
    doubleClickZoom: boolean
    touchZoomRotate: boolean
    minimized: boolean
    toggleDisplay: boolean
    togglePosition: string
    collapsedWidth: number
    collapsedHeight: number
    toggleCallback?: (isShow: boolean) => void
}

class MiniMap {
    private options: MiniMapOptions

    // private parentMap: maplibregl.Map | null
    private parentMap: any
    // private miniMap: maplibregl.Map | null
    private miniMap: any
    // private miniMapCanvas: HTMLElement | null;
    // private container: HTMLDivElement | null
    private miniMapCanvas: any
    private container: any

    private ticking: boolean
    private lastMouseMoveEvent = null
    private isDragging: boolean
    private isCursorOverFeature: boolean
    private previousPoint: number[]
    private currentPoint: number[]
    private trackingRect: any
    private trackingRectCoordinates: number[][][]

    // private toggleDisplayButton: HTMLAnchorElement;
    private toggleDisplayButton: any
    private minimized: boolean

    private onToggleButtonClick
    private onLoad
    private onMove
    private onMouseMove
    private onMouseDown
    private onMouseUp
    trackingAreaMarker: any

    constructor(private config?: MiniMapOptions) {
        // super()
        this.ticking = false
        this.lastMouseMoveEvent = null
        this.parentMap = null
        this.miniMap = null
        this.miniMapCanvas = null
        this.container = null
        this.isDragging = false
        this.isCursorOverFeature = false
        this.previousPoint = [0, 0]
        this.currentPoint = [0, 0]
        this.trackingRectCoordinates = [[[], [], [], [], []]]

        this.minimized = false
        this.trackingAreaMarker = null

        this.options = {
            // container: config.container,
            id: 'maplibre-miniMap',
            width: 320,
            height: 180,
            style: 'maplibre://styles/maplibre/streets-v8',
            center: [0, 0],
            zoom: 6,

            // should be a function; will be bound to MiniMap
            zoomAdjust: null,

            // if parent map zoom >= 18 and miniMap zoom >= 14, set miniMap zoom to 16
            zoomLevels: [
                [18, 14, 16],
                [16, 12, 14],
                [14, 10, 12],
                [12, 8, 10],
                [10, 6, 8],
            ],

            lineColor: '#2196f3',
            lineWidth: 2,
            lineOpacity: 1,

            fillColor: '#2196f3',
            fillOpacity: 0,

            dragPan: false,
            scrollZoom: false,
            boxZoom: false,
            dragRotate: false,
            keyboard: false,
            doubleClickZoom: false,
            touchZoomRotate: false,
            minimized: false,
            toggleDisplay: true,
            collapsedWidth: 20,
            collapsedHeight: 20,
            togglePosition: 'bottomleft',
            trackingAreaMarkerElement: null,
        }

        if (config) {
            Object.assign(this.options, config)
        }
    }

    private onAdd(parentMap: maplibregl.Map): HTMLDivElement | undefined {
        this.parentMap = parentMap

        const opts = this.options
        // const container = this.createContainer(parentMap)
        const container = this.options.container
        this.container = container
        const style = opts.style
        const optsCenter = opts.center
        if (!(container instanceof HTMLDivElement)) {
            return
        }
        const miniMap = (this.miniMap = new maplibregl.Map({
            attributionControl: false,
            container,
            style,
            zoom: opts.zoom,
            // center: opts.center,
            center: { lng: optsCenter[0], lat: optsCenter[1] },
        }))
        miniMap.scrollZoom.disable()
        if (opts.maxBounds) {
            miniMap.setMaxBounds(opts.maxBounds)
        }

        this.onLoad = this.load.bind(this)
        miniMap.on('load', this.onLoad)
        miniMap.on('click', event => {
            const { lng, lat } = event.lngLat
            if (!this.parentMap) return
            this.parentMap.flyTo({
                center: [lng, lat],
            })
        })
        return this.container
    }

    private onRemove() {
        if (!this.parentMap || !this.miniMap) return
        this.parentMap.off('move', this.onMove)

        this.miniMap.off('load', this.onLoad)

        this.miniMap.off('mousemove', this.onMouseMove)
        this.miniMap.off('mousedown', this.onMouseDown)
        this.miniMap.off('mouseup', this.onMouseUp)

        this.miniMap.off('touchmove', this.onMouseMove)
        this.miniMap.off('touchstart', this.onMouseDown)
        this.miniMap.off('touchend', this.onMouseUp)

        // this.miniMapCanvas.removeEventListener('wheel', this.preventDefault)
        // this.miniMapCanvas.removeEventListener(
        //     'mousewheel',
        //     this.preventDefault
        // )

        this.container.removeEventListener('contextmenu', this.preventDefault)
        this.container.parentNode.removeChild(this.container)
        this.miniMap = null
    }

    public changeLayer(layer: string): void {
        this.miniMap.setStyle(layer)
        this.miniMap.on('style.load', () => {
            this.addRect(this.miniMap, this.options)
        })
    }

    public remove(): void {
        this.onRemove()
    }

    private load(): void {
        const opts = this.options
        const parentMap = this.parentMap
        const miniMap = this.miniMap
        const interactions = [
            'dragPan',
            'scrollZoom',
            'boxZoom',
            'dragRotate',
            'keyboard',
            'doubleClickZoom',
            'touchZoomRotate',
        ]

        interactions.forEach(function (i) {
            if (opts[i] !== true) {
                miniMap[i].disable()
            }
        })

        if (typeof opts.zoomAdjust === 'function') {
            this.options.zoomAdjust = opts.zoomAdjust.bind(this)
        } else if (opts.zoomAdjust === null) {
            this.options.zoomAdjust = this.zoomAdjust.bind(this)
        }

        this.addRect(miniMap, opts)

        this.onMove = this.update.bind(this)
        this.onMouseMove = this.mouseMove.bind(this)
        this.onMouseDown = this.mouseDown.bind(this)
        this.onMouseUp = this.mouseUp.bind(this)

        parentMap.on('move', this.onMove)

        miniMap.on('mousemove', this.onMouseMove)
        miniMap.on('mousedown', this.onMouseDown)
        miniMap.on('mouseup', this.onMouseUp)

        miniMap.on('touchmove', this.onMouseMove)
        miniMap.on('touchstart', this.onMouseDown)
        miniMap.on('touchend', this.onMouseUp)

        this.miniMapCanvas = miniMap.getCanvasContainer()
        // this.miniMapCanvas.addEventListener('wheel', this.preventDefault)
        // this.miniMapCanvas.addEventListener('mousewheel', this.preventDefault)
    }

    private addRect(miniMap: maplibregl.Map, opts: MiniMapOptions): void {
        if (miniMap.getLayer('trackingRectOutline')) {
            miniMap.removeLayer('trackingRectOutline')
        }

        if (miniMap.getLayer('trackingRectFill')) {
            miniMap.removeLayer('trackingRectFill')
        }

        if (miniMap.getSource('trackingRect')) {
            miniMap.removeSource('trackingRect')
        }

        miniMap.addSource('trackingRect', {
            type: 'geojson',
            data: {
                type: 'Feature',
                properties: {
                    name: 'trackingRect',
                },
                geometry: {
                    type: 'Polygon',
                    coordinates: this.trackingRectCoordinates,
                },
            },
        })

        const [lng, lat] = opts.center
        if (opts.trackingAreaMarkerElement) {
            this.trackingAreaMarker = new maplibregl.Marker({
                element: opts.trackingAreaMarkerElement,
            })
                .setLngLat({ lng, lat })
                .addTo(miniMap)
        }

        // miniMap.addLayer({
        //     id: 'trackingRectOutline',
        //     type: 'line',
        //     source: 'trackingRect',
        //     layout: {},
        //     paint: {
        //         'line-color': opts.lineColor,
        //         'line-width': opts.lineWidth,
        //         'line-opacity': opts.lineOpacity,
        //         'line-gap-width': 0,
        //     },
        // })

        // // needed for dragging
        // miniMap.addLayer({
        //     id: 'trackingRectFill',
        //     type: 'fill',
        //     source: 'trackingRect',
        //     layout: {},
        //     paint: {
        //         'fill-color': opts.fillColor,
        //         'fill-opacity': opts.fillOpacity,
        //     },
        // })

        this.trackingRect = this.miniMap.getSource('trackingRect')

        this.update()
    }

    private mouseDown(e): void {
        // if (this.isCursorOverFeature) {
        //     this.isDragging = true
        //     this.previousPoint = this.currentPoint
        //     this.currentPoint = [e.lngLat.lng, e.lngLat.lat]
        // }
        this.isDragging = true
        this.previousPoint = this.currentPoint
        this.currentPoint = [e.lngLat.lng, e.lngLat.lat]
    }

    private mouseMove(e): void {
        this.ticking = false

        // const miniMap = this.miniMap
        // const features = miniMap.queryRenderedFeatures(e.point, {
        //     layers: ['trackingRectFill'],
        // })

        // // don't update if we're still hovering the area
        // if (!(this.isCursorOverFeature && features.length > 0)) {
        //     this.isCursorOverFeature = features.length > 0
        //     this.miniMapCanvas.style.cursor = this.isCursorOverFeature
        //         ? 'move'
        //         : ''
        // }

        if (this.isDragging) {
            this.previousPoint = this.currentPoint
            this.currentPoint = [e.lngLat.lng, e.lngLat.lat]

            const offset = [
                this.previousPoint[0] - this.currentPoint[0],
                this.previousPoint[1] - this.currentPoint[1],
            ]

            const newBounds = this.moveTrackingRect(offset)

            this.parentMap.fitBounds(newBounds, {
                duration: 80,
            })
        }
    }

    private mouseUp(): void {
        this.isDragging = false
        this.ticking = false
    }

    private moveTrackingRect(offset: number[]) {
        const source = this.trackingRect
        const data = source._data
        const bounds = data.properties.bounds

        bounds._ne.lat -= offset[1]
        bounds._ne.lng -= offset[0]
        bounds._sw.lat -= offset[1]
        bounds._sw.lng -= offset[0]

        this.convertBoundsToPoints(bounds)
        source.setData(data)

        return bounds
    }

    private setTrackingRectBounds(bounds) {
        const source = this.trackingRect
        const data = source._data

        data.properties.bounds = bounds

        this.convertBoundsToPoints(bounds)
        source.setData(data)
    }

    convertBoundsToPoints(bounds): void {
        const ne = bounds._ne
        const sw = bounds._sw
        const trc = this.trackingRectCoordinates

        trc[0][0][0] = ne.lng
        trc[0][0][1] = ne.lat
        trc[0][1][0] = sw.lng
        trc[0][1][1] = ne.lat
        trc[0][2][0] = sw.lng
        trc[0][2][1] = sw.lat
        trc[0][3][0] = ne.lng
        trc[0][3][1] = sw.lat
        trc[0][4][0] = ne.lng
        trc[0][4][1] = ne.lat

        const point1 = this.miniMap.project([sw.lng, ne.lat])
        const point2 = this.miniMap.project([ne.lng, ne.lat])
        const point3 = this.miniMap.project([sw.lng, sw.lat])
        // const point4 = this.miniMap.project([ne.lng, ne.lat])
        const trackingAreaMarkerWidth = Math.floor(point2.x - point1.x)
        const trackingAreaMarkerHeight = Math.floor(point3.y - point2.y)
        const trackingAreaMarkerElementStyle =
            this.options.trackingAreaMarkerElement?.style
        if (trackingAreaMarkerElementStyle) {
            trackingAreaMarkerElementStyle.width = `${trackingAreaMarkerWidth}px`
            trackingAreaMarkerElementStyle.height = `${trackingAreaMarkerHeight}px`
        }
        const parentMapCenter = this.parentMap.getCenter()

        if (this.trackingAreaMarker) {
            this.trackingAreaMarker.setLngLat(parentMapCenter)
        }
    }

    private update(): void {
        if (this.isDragging) {
            return
        }

        const parentBounds = this.parentMap.getBounds()

        this.setTrackingRectBounds(parentBounds)

        if (typeof this.options.zoomAdjust === 'function') {
            this.options.zoomAdjust()
        }
    }

    private zoomAdjust(): void {
        const miniMap = this.miniMap
        const parentMap = this.parentMap
        const miniZoom = miniMap.getZoom()
        const parentZoom = parentMap.getZoom()
        const levels = this.options.zoomLevels
        let found = false

        levels.forEach(function (zoom) {
            if (!found && parentZoom >= zoom[0]) {
                if (miniZoom >= zoom[1]) {
                    miniMap.setZoom(zoom[2])
                }

                miniMap.setCenter(parentMap.getCenter())
                found = true
            }
        })

        if (!found && miniZoom !== this.options.zoom) {
            if (typeof this.options.bounds === 'object') {
                miniMap.fitBounds(this.options.bounds, { duration: 50 })
            }

            miniMap.setZoom(this.options.zoom)
        }
    }

    private createContainer(parentMap: maplibregl.Map): HTMLDivElement {
        const opts = this.options
        const container = document.createElement('div')

        container.className = 'maplibre-ctrl-miniMap maplibregl-ctrl'
        container.setAttribute(
            'style',
            'width: ' + opts.width + 'px; height: ' + opts.height + 'px;'
        )
        container.addEventListener('contextmenu', this.preventDefault)

        parentMap.getContainer().appendChild(container)

        if (opts.id !== '') {
            container.id = opts.id
        }

        return container
    }

    private preventDefault(e): void {
        e.preventDefault()
    }
}

export default MiniMap
