import { createMemoryHistory, createRouter, RouteRecordRaw } from 'vue-router'
import { appModuleIds, stepsListIndexes } from '@/shared/constants'
import { useStore } from 'vuex'
import { defineAsyncComponent } from 'vue'

const routes: Array<RouteRecordRaw> = [
    {
        name: stepsListIndexes.regionsStepIndex,
        path: '/',
        component: () => import('@/pages/RegionsView/index.vue'),
    },
    {
        name: stepsListIndexes.districtsStepIndex,
        path: '/',
        component: () =>
            import(
                '@/features/general/LocationsViewHOC/ui/LocationsViewHOC.vue'
            ),
    },
    {
        name: stepsListIndexes.districtInfoStepIndex,
        path: '/',
        component: () => import('@/pages/DistrictInfoView/index.vue'),
    },
    {
        name: stepsListIndexes.gdsInfoStepIndex,
        path: '/',
        component: () => import('@/pages/GdsView/index.vue'),
    },
    {
        name: stepsListIndexes.settlementsStepIndex,
        path: '/',
        component: () =>
            import(
                '@/features/gasification/DistrictInfoBlock/components/SettlementsList/index.vue'
            ),
    },
    {
        name: stepsListIndexes.gasPlanStepIndex,
        path: '/',
        component: () => import('@/pages/GasificationPlan/index.vue'),
    },
    {
        name: stepsListIndexes.agnksInfoStepIndex,
        path: '/',
        component: () =>
            import('@/pages/AGNKS/ObjectPageAGNKS/ui/ObjectPageAGNKS.vue'),
    },
]
export const router = createRouter({
    history: createMemoryHistory(process.env.VUE_APP_BASE_URL),
    routes,
})
