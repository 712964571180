import {
    GDS_STATUS_CLOSED,
    GDS_STATUS_LIMITED,
    GDS_STATUS_OPERATING,
    GDS_STATUS_PROJECTED,
    IMG_GDS_STATUS_CLOSED,
    IMG_GDS_STATUS_LIMITED,
    IMG_GDS_STATUS_OPERATING,
    IMG_GDS_STATUS_PROJECTED,
} from '@/shared/constants'

import setEvents from './setEvents'

export default async function (this: any) {
    const sourceLayer = this.getLayerById(
        this.map.getStyle().layers,
        this.gdsUrlFirstPart
    )

    return () => {
        // this.addLayer({
        //     id: this.gdsIcon,
        //     // id: gdsUrl,
        //     type: this.layerTypeSymbol,
        //     source: this.gdsTileSourceName,
        //     'source-layer': this.gdsUrlFirstPart,
        //     layout: {
        //         // 'icon-image': ['get', 'icon'],
        //         'icon-image': [
        //             'case',
        //             ['==', ['get', 'status'], GDS_STATUS_OPERATING],
        //             IMG_GDS_STATUS_OPERATING,
        //             ['==', ['get', 'status'], GDS_STATUS_CLOSED],
        //             IMG_GDS_STATUS_CLOSED,
        //             ['==', ['get', 'status'], GDS_STATUS_LIMITED],
        //             IMG_GDS_STATUS_LIMITED,
        //             ['==', ['get', 'status'], GDS_STATUS_PROJECTED],
        //             IMG_GDS_STATUS_PROJECTED,
        //             '',
        //         ],
        //         'icon-allow-overlap': true,
        //         'text-ignore-placement': false,
        //         'icon-size': 1.2,
        //     },
        //     minzoom: this.layerMinZoom1,
        // })
        //
        // this.addLayer({
        //     id: this.gdsSymbolLabelTypeId,
        //     type: this.layerTypeSymbol,
        //     source: this.gdsTileSourceName,
        //     'source-layer': 'gas_distribution_station',
        //     paint: {
        //         // 'text-color': this.colors[4],
        //         // 'text-color': 'teal',
        //         // 'text-halo-color': this.colors[9],
        //         'text-color': this.colors[9],
        //         'text-halo-color': '#000',
        //         'text-halo-width': 1,
        //     },
        //     layout: {
        //         'text-field': ['get', 'name'],
        //         'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
        //         // 'text-radial-offset': 0.5,
        //         'text-justify': 'auto',
        //         'text-font': ['Noto Sans Bold'],
        //         'text-offset': [1, 1],
        //         // 'text-size': 14,
        //         'text-size': {
        //             type: 'exponential',
        //             base: 1,
        //             stops: [
        //                 [0, 8],
        //                 [22, 24],
        //             ],
        //         },
        //         'text-letter-spacing': 0.05,
        //         // 'icon-allow-overlap': true,
        //         // 'text-allow-overlap': false,
        //         // 'icon-ignore-placement': true,
        //         // 'text-ignore-placement': false,
        //
        //         'text-allow-overlap': false,
        //         'text-ignore-placement': false,
        //     },
        //     minzoom: this.layerMinZoom1,
        // })

        // setEvents.call(this, [this.gdsIcon, this.gdsSymbolLabelTypeId])
        setEvents.call(this, this.gdsLayerIds)
    }
}
