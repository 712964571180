import { GeoJSONSource, Map, SymbolLayerSpecification } from 'maplibre-gl'
import { featureCollection } from '@turf/turf'
import { Feature, Point, FeatureCollection, GeoJSON } from 'geojson'
import {
    canvasSymbolLayer,
    TListImagesCanvasLayer,
} from '../CustomLayers/canvasSymbolLayer/canvasSymbolLayer'
import { CanvasMarker } from '../canvasMarkers/methods/CanvasMarker'
import Supercluster from 'supercluster'
import { TInitCanvasModelComponent } from '../canvasMarkers/methods/canvasImage'
import { addBaseMarkerEvents } from './layerEvents/addBaseMarkerEvents'

export default function (
    map: Map,
    sourceId: string,
    FC: FeatureCollection<Point>,
    mainLayer: SymbolLayerSpecification,
    initCanvasModelFn: (features: Feature<Point>[]) => TInitCanvasModelComponent
) {
    const maxZoom = mainLayer.minzoom || 7

    const cluster = new Supercluster({
        radius: 100,
        maxZoom,
        minZoom: 0,
    })
    cluster.load(FC.features as any)

    const layerId = mainLayer.id
    const mainLayerIdCluster = `${layerId}_cluster`
    const clusterLayer: SymbolLayerSpecification = {
        id: `${layerId}_cluster`,
        type: 'symbol',
        source: sourceId,
        filter: ['has', 'point_count'],
        layout: {
            'icon-image': ['concat', mainLayerIdCluster, ['get', 'cluster_id']],
            'icon-allow-overlap': true,
            'icon-size': [
                'interpolate',
                ['linear'],
                ['zoom'],
                0,
                1,
                maxZoom,
                1,
            ],
        },
        minzoom: 0,
        // maxzoom: maxZoom,
    }
    const activeMarker = new CanvasMarker()

    function updateClusters() {
        const boundsRaw = map.getBounds()
        const bounds = [
            boundsRaw._sw.lng,
            boundsRaw._sw.lat,
            boundsRaw._ne.lng,
            boundsRaw._ne.lat,
        ]
        const clusters = cluster.getClusters(bounds as any, map.getZoom())
        const clusterData = featureCollection([])
        const unclusteredPoints = featureCollection([])

        clusters.forEach(feature => {
            if (feature.properties.cluster) {
                clusterData.features.push(feature)
                return
            }
            unclusteredPoints.features.push(feature)
        })

        const main_source = map.getSource(mainLayer.source) as GeoJSONSource
        if (main_source) {
            main_source.setData(unclusteredPoints as GeoJSON)
        }

        const listImages: TListImagesCanvasLayer = clusterData.features.map(
            feature => {
                const clusterId = feature.properties?.cluster_id
                const featuresOfCluster = cluster.getLeaves(clusterId, Infinity)

                return {
                    boundId: clusterId,
                    initCanvasModelComponent:
                        initCanvasModelFn(featuresOfCluster),
                }
            }
        )

        canvasSymbolLayer(map, {
            layer: clusterLayer,
            imageName: mainLayerIdCluster,
            size: { width: 100, height: 100 },
            activeMarker,
            listImages,
        })

        const cluster_source = map.getSource(sourceId) as GeoJSONSource
        if (cluster_source) {
            cluster_source.setData(clusterData as GeoJSON)
        }

        return { map: map }
    }

    map.addSource(sourceId, {
        type: 'geojson',
        data: featureCollection([]),
    })

    map.on('zoomend', updateClusters).on('moveend', updateClusters)

    addBaseMarkerEvents(map, clusterLayer, activeMarker, mainLayerIdCluster)

    updateClusters()

    return {
        layer: clusterLayer,
        supercluster: cluster,
    }
}
