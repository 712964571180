import addSpg from '@/entities/Map/spg/model/addSpg'

export default async function (this: any, allLayers) {
    //сейчас эти методы используются в основном для добавления ивентов к слоям
    const downloadedPromises = [
        this.addCityArea(),
        this.addPipelinesBranch(),
        this.addPipelinesSettlement(),
        this.addPipelinesMajor(),
        // this.addGds(),
        // this.addSocialObjects(),
        this.addSpg(),
    ]
    const downloadedCallbacks = await Promise.all(downloadedPromises)

    downloadedCallbacks.forEach(downloadedCallback => {
        if (typeof downloadedCallback !== 'function') return
        downloadedCallback()
    })
}
