import './index.less'
import 'framework7-icons'

import Framework7 from 'framework7/bundle'
import Framework7Vue, { registerComponents } from 'framework7-vue/bundle'
// import {
//     f7Accordion,
//     f7AccordionContent,
//     f7AccordionItem,
//     f7AccordionToggle,
//     f7Actions,
//     f7ActionsButton,
//     f7ActionsGroup,
//     f7ActionsLabel,
//     f7App,
//     f7Appbar,
//     f7AreaChart,
//     f7Badge,
//     f7Block,
//     f7BlockFooter,
//     f7BlockHeader,
//     f7BlockTitle,
//     f7Button,
//     f7Card,
//     f7CardContent,
//     f7CardFooter,
//     f7CardHeader,
//     f7Checkbox,
//     f7Chip,
//     f7Col,
//     f7Fab,
//     f7FabBackdrop,
//     f7FabButton,
//     f7FabButtons,
//     f7Gauge,
//     f7Icon,
//     f7Input,
//     f7Link,
//     f7List,
//     f7ListButton,
//     f7ListGroup,
//     f7ListIndex,
//     f7ListInput,
//     f7ListItem,
//     f7ListItemCell,
//     f7ListItemRow,
//     f7LoginScreen,
//     f7LoginScreenTitle,
//     f7Menu,
//     f7MenuDropdown,
//     f7MenuDropdownItem,
//     f7MenuItem,
//     f7Message,
//     f7Messagebar,
//     f7MessagebarAttachment,
//     f7MessagebarAttachments,
//     f7MessagebarSheet,
//     f7MessagebarSheetImage,
//     f7MessagebarSheetItem,
//     f7Messages,
//     f7MessagesTitle,
//     f7Navbar,
//     f7NavLeft,
//     f7NavRight,
//     f7NavTitle,
//     f7NavTitleLarge,
//     f7Page,
//     f7PageContent,
//     f7Panel,
//     f7PhotoBrowser,
//     f7PieChart,
//     f7Popover,
//     f7Popup,
//     f7Preloader,
//     f7Progressbar,
//     f7Radio,
//     f7Range,
//     f7RoutableModals,
//     f7Row,
//     f7Searchbar,
//     f7Segmented,
//     f7Sheet,
//     f7SkeletonAvatar,
//     f7SkeletonBlock,
//     f7SkeletonImage,
//     f7SkeletonText,
//     f7Stepper,
//     f7Subnavbar,
//     f7SwipeoutActions,
//     f7SwipeoutButton,
//     f7Swiper,
//     f7SwiperSlide,
//     f7Tab,
//     f7Tabs,
//     f7TextEditor,
//     f7Toggle,
//     f7Toolbar,
//     f7Treeview,
//     f7TreeviewItem,
//     f7UseIcon,
//     f7View,
//     f7Views,
// } from 'framework7-vue'
Framework7.use(Framework7Vue)
export default app => {
    // ;[
    //     f7Accordion,
    //     f7AccordionContent,
    //     f7AccordionItem,
    //     f7AccordionToggle,
    //     f7Actions,
    //     f7ActionsButton,
    //     f7ActionsGroup,
    //     f7ActionsLabel,
    //     f7App,
    //     f7Appbar,
    //     f7AreaChart,
    //     f7Badge,
    //     f7Block,
    //     f7BlockFooter,
    //     f7BlockHeader,
    //     f7BlockTitle,
    //     f7Button,
    //     f7Card,
    //     f7CardContent,
    //     f7CardFooter,
    //     f7CardHeader,
    //     f7Checkbox,
    //     f7Chip,
    //     f7Col,
    //     f7Fab,
    //     f7FabBackdrop,
    //     f7FabButton,
    //     f7FabButtons,
    //     f7Gauge,
    //     f7Icon,
    //     f7Input,
    //     f7Link,
    //     f7List,
    //     f7ListButton,
    //     f7ListGroup,
    //     f7ListIndex,
    //     f7ListInput,
    //     f7ListItem,
    //     f7ListItemCell,
    //     f7ListItemRow,
    //     f7LoginScreen,
    //     f7LoginScreenTitle,
    //     f7Menu,
    //     f7MenuDropdown,
    //     f7MenuDropdownItem,
    //     f7MenuItem,
    //     f7Message,
    //     f7Messagebar,
    //     f7MessagebarAttachment,
    //     f7MessagebarAttachments,
    //     f7MessagebarSheet,
    //     f7MessagebarSheetImage,
    //     f7MessagebarSheetItem,
    //     f7Messages,
    //     f7MessagesTitle,
    //     f7Navbar,
    //     f7NavLeft,
    //     f7NavRight,
    //     f7NavTitle,
    //     f7NavTitleLarge,
    //     f7Page,
    //     f7PageContent,
    //     f7Panel,
    //     f7PhotoBrowser,
    //     f7PieChart,
    //     f7Popover,
    //     f7Popup,
    //     f7Preloader,
    //     f7Progressbar,
    //     f7Radio,
    //     f7Range,
    //     f7RoutableModals,
    //     f7Row,
    //     f7Searchbar,
    //     f7Segmented,
    //     f7Sheet,
    //     f7SkeletonAvatar,
    //     f7SkeletonBlock,
    //     f7SkeletonImage,
    //     f7SkeletonText,
    //     f7Stepper,
    //     f7Subnavbar,
    //     f7SwipeoutActions,
    //     f7SwipeoutButton,
    //     f7Swiper,
    //     f7SwiperSlide,
    //     f7Tab,
    //     f7Tabs,
    //     f7TextEditor,
    //     f7Toggle,
    //     f7Toolbar,
    //     f7Treeview,
    //     f7TreeviewItem,
    //     f7UseIcon,
    //     f7View,
    //     f7Views,
    // ].forEach((Component: any) => app.component(Component.name, Component))

    registerComponents(app)
}
