import { clickEventAGNKS } from '@/entities/Map/AGNKS/model/addEvents/clickEventAGNKS'
import { stepsListIndexes } from '@/shared/constants'
import { Dispatch } from 'vuex'

export const handleAgnksObjectSelect = (
    clickedObject,
    appContext,
    dispatch
) => {
    clickEventAGNKS(clickedObject, appContext)

    dispatch('setStepActiveId', stepsListIndexes.agnksInfoStepIndex)
}
