export default async function (this: any, mapStyleInit: () => any) {
    if (this.map) {
        this.map.remove()
        this.$app.config.globalProperties.$map = null
        console.log('this.map', this.map)
        this.maplibregl = null
        this.map = null
        let mapReadyPromiseResolve
        this.mapReadyPromise = new Promise(resolve => {
            mapReadyPromiseResolve = resolve
        })
        this.mapReadyPromise.resolve = mapReadyPromiseResolve
        this.mapStyle = null
        this.miniMap = null
    }

    this.mapStyle = await mapStyleInit.call(this)
    console.log('this.mapStyle:', this.mapStyle)

    await this.initMap()
    // await this.initMiniMap()

    this.setMetersInPx()

    if (this.deviceType !== 'mobile') {
        this.resizeMap(this.isMenuOpen)
    }
}
