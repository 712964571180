import { popupTypes } from '@/entities/Map/general/model/addLayerHoverActions/types/popupTypes'
import { popupBackgroundColorsBySourceLayer } from '@/shared/constants'

export default function (
    this: any,
    feature,
    lngLat,
    prevPopupId,
    getHtmlContent
) {
    const { htmlContent, type } = getHtmlContent?.(feature) || null

    if (!type) return

    switch (type) {
        case popupTypes.spgLngPopup:
            if (feature.properties.point_count) return

            prevPopupId = this.$state.map.popupMap?.id
            this.$state.map.popupMap = {
                ...this.$state.map.popupMap,
                popupType: popupTypes.spgLngPopup,
                id: feature.id,
                properties: feature.properties,
                popupProperties: {
                    closeButton: true,
                },
                lngLat,
                isShow: true,
            }
            if (prevPopupId === feature.id) return
            break
        case popupTypes.lngPopup:
            prevPopupId = this.$state.map.popupMap?.id
            this.$state.map.popupMap = {
                ...this.$state.map.popupMap,
                popupType: popupTypes.lngPopup,
                id: feature.id,
                properties: feature.properties,
                popupProperties: {
                    closeButton: true,
                },
                lngLat,
                isShow: true,
            }
            if (prevPopupId === feature.id) return

            this.$state.map.popupMap.isLoading = true
            this.loadSource(`/lng/station/price/${feature.id}`).then(res => {
                this.$state.map.popupMap.isLoading = false
                this.$state.map.popupMap.info = res
            })
            break
        case popupTypes.inlineHtml:
            this.$state.map.popupMap = {
                ...this.$state.map.popupMap,
                popupType: popupTypes.inlineHtml,
                id: feature.id,
                properties: {
                    htmlContent,
                },
                popupProperties: {
                    closeButton: true,
                },
                lngLat,
                isShow: true,
            }
            break
        case popupTypes.socialObjectsPopup:
            prevPopupId = this.$state.map.popupMap?.id

            if (prevPopupId === feature.properties.id) {
                this.$state.map.popupMap = {
                    ...this.$state.map.popupMap,
                    isShow: true,
                    lngLat,
                }
                return
            }

            this.$state.map.popupMap = {
                ...this.$state.map.popupMap,
                popupType: popupTypes.socialObjectsPopup,
                id: feature.properties.id,
                properties: feature.properties,
                popupProperties: {
                    closeButton: false,
                    backgroundColor:
                        popupBackgroundColorsBySourceLayer[feature.sourceLayer],
                },
                lngLat,
                isShow: true,
            }

            this.$state.map.popupMap.info = feature.properties.name
            break
        case popupTypes.default:
            this.$state.map.popupMap = {
                ...this.$state.map.popupMap,
                popupType: popupTypes.default,
                id: feature.id,
                properties: feature.properties,
                popupProperties: {
                    closeButton: true,
                },
                lngLat,
                isShow: true,
            }

            this.$state.map.popupMap.name = htmlContent
            break
        // popup = new this.maplibregl.Popup()
        // popup.setLngLat(lngLat).setHTML(htmlContent).addTo(this.map)
        // return popup
    }
}
