<template>
    <div ref="map" :class="$style.map" />
</template>

<script>
export default {
    name: 'MapEl',
}
</script>

<style lang="stylus" module>
.map
    width 100%
    height 100%
    position absolute
    top 0
    left 0
    user-select none
    transition .3s
</style>
