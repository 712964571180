<template>
    <div
        :class="[
            $style.showRightMenu__wrapper,
            !isShowMenu && $style.showRightMenu__wrapper_hided,
        ]"
    >
        <div
            v-show="false"
            :class="[
                $style.showRightMenu__toggleButton,
                isPressed && $style.showRightMenu__toggleButton_pressed,
            ]"
            ref="toggleButton"
            @click="toggleMenu"
            @mousedown="isPressed = true"
            @mouseup="isPressed = false"
            @mouseenter="toggleHoverButton"
            @mouseleave="toggleHoverButton"
        >
            <f7-icon
                :f7="`chevron_compact_${isShowMenu ? 'right' : 'left'}`"
            ></f7-icon>
            <div
                ref="toggleButtonTitle"
                :class="$style.showRightMenu__toggleButtonTitle"
            >
                Заявки на подключение
            </div>
        </div>
        <div :class="$style.showRightMenu__content">
            <NavController2 v-bind="{ focusedProposalsStatsSubject }" />
            <!--            <StatsProposalsView v-bind="{ focusedProposalsStatsSubject }" />-->
        </div>
    </div>
    <DevelopConsole
        v-if="$VUE_APP_BUILD_WITH_DEVELOPMENT_PARTS && indexedDbController"
        v-bind="{
            indexedDbController,
        }"
    />
</template>
<script>
import { defineAsyncComponent } from 'vue'

import NavController2 from '../../../widgets/NavController/NavController2.vue'
import { statsProposalsTypes } from '../../../app/providers/store/modules/view/types'
import { mapGetters, mapState } from 'vuex'
import { focusedProposalsStatsSubjectByAppModule } from '@/features/gasification/ShowRightMenu/model/focusedProposalsStatsSubject/focusedProposalsStatsSubject.config'
const DevelopConsole = defineAsyncComponent(() =>
    import('../../../widgets/DevelopConsole.vue')
)
export default {
    data() {
        return {
            isShowMenu: true,
            isPressed: false,
            isHoverButton: false,
        }
    },
    components: {
        // StatsProposalsView,
        DevelopConsole,
        NavController2,
    },
    watch: {
        isHoverButton() {
            this.isHoverButtonActions()
        },
        isShowMenu() {
            this.isShowMenuActions()
        },
        // focusedProposalsStatsSubject(newSubjData, oldSubjData) {
        //     if (oldSubjData.disabled) this.isShowMenu = !newSubjData.disabled
        // },
    },
    computed: {
        ...mapState({
            focusedRegionNumber: state => state.focusedRegionNumber,
            focusedDistrictNumber: state => state.focusedDistrictNumber,
            focusedRegionName: state => state.focusedRegionName,
            focusedDistrictName: state => state.focusedDistrictName,
            statsProposals: state => state.view.statsProposals,
            stepActiveId: state => state.view.stepActiveId,
            indexedDbController: state => state.indexedDbController,
            chosenAppModule: state => state.chosenAppModule,
        }),

        ...mapGetters('AGNKSEntity', ['statsRussia', 'statsRegion']),
        /*TODO: этот метод уже есть в vuex, но при смене региона на SettlementsView шаге не работает реактивно
           => избавиться от локального метода в компоненте, заставить работать метод в vuex;
           TODO: focusedProposalsStatsSubject реассайнится каждый раз из-за того, что focusedRegionName/focusedDistrictName меняется в функции moveEvent
           через setPartialState - при одинаковом значении не менять, либо попробовать сделать через отдельный mutation
        */
        focusedProposalsStatsSubject(state) {
            const mapFn =
                focusedProposalsStatsSubjectByAppModule[this.chosenAppModule.id]
            return mapFn.call(this, state)
        },
        statsProposalsTypes() {
            return statsProposalsTypes
        },
    },
    methods: {
        clearCache() {
            console.log('clearCache')
            // this.indexedDbController
            this.indexedDbController.deleteDB()
        },
        toggleMenu() {
            this.isShowMenu = !this.isShowMenu
        },
        toggleHoverButton() {
            this.isHoverButton = !this.isHoverButton
        },
        isHoverButtonActions() {
            if (!this.isShowMenu && this.isHoverButton) {
                this.$refs.toggleButton.style.transform = `translateX(${-this
                    .$refs.toggleButtonTitle.clientWidth}px)`
                return
            }
            if (!this.isShowMenu && !this.isHoverButton) {
                this.$refs.toggleButton.style.transform = `translateX(${0}px)`
            }
        },
        isShowMenuActions() {
            if (this.isShowMenu) {
                this.$refs.toggleButton.style.transform = `translateX(${this.$refs.toggleButtonTitle.clientWidth}px)`
            } else {
                this.$refs.toggleButton.style.transform = `translateX(${0}px)`
            }
        },
    },
    mounted() {
        this.isHoverButtonActions()
        this.isShowMenuActions()
    },
}
</script>

<style lang="stylus" module>
.showRightMenu__wrapper
    display: flex;
    //align-items: center;
    //padding 10px
    position absolute
    z-index 1
    //bottom 35%
    //right 0
    visibility hidden
.showRightMenu__content
    display flex
    flex-direction column
    background rgba(255, 255, 255, 0.68)
    backdrop-filter blur(11px)
    border-radius var(--f7-list-inset-border-radius);
    visibility visible
.showRightMenu__toggleButton
    display flex
    background rgba(255, 255, 255, 0.68)
    //width: 12px
    //height: 30px;
    backdrop-filter: blur(11px);
    border-radius: 20px 0 0 20px
    cursor pointer
    box-shadow: var(--boxShadow1);
    transition transform var(--transitionDuration1)
    user-select none
    visibility visible
    padding: 5px 0;
.showRightMenu__toggleButton_hover

.showRightMenu__toggleButtonTitle
    display flex
    align-items center
    justify-content center
    padding 0 10px 0 0
.showRightMenu__toggleButton_pressed
    background: rgba(234, 234, 234, 0.64);
.showRightMenu__wrapper
    transition transform var(--transitionDuration1)
.showRightMenu__wrapper_hided
    transform translateX(91.5%)
</style>
