import { tileServerType } from '@/shared/constants'

const baseUrl = location.origin + location.pathname
import { arrayToObjectWithItemsById } from '@/shared/lib'

import cityAreaLayer from './cityAreaLayer'
import districtLayer from './districtLayer'
import regionLayer from './regionLayer'
export default async function (this: any, style) {
    this.$state.map.mapStyleCurrentType === tileServerType.tileServerRasterStyle
        ? Object.assign(style, {
              glyphs: `${baseUrl}fonts/{fontstack}/{range}.pbf`,
          })
        : ''
    const layers = await style.layers
    const layersById = arrayToObjectWithItemsById(layers)
    console.log('layersById', layersById)
}
