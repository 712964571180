import {
    LAYER_MIN_ZOOM2,
    PIPELINE_STATUS_OPERATING,
    PIPELINE_STATUS_PROJECTED,
} from '@/shared/constants'

import addEvents from './addEvents'
import { popupTypes } from '@/entities/Map/general/model/addLayerHoverActions/types/popupTypes'

export default async function (this: any) {
    return () => {
        const pipelinesConsumerOperatingLayerId = this.getLayerId(
            this.pipelinesConsumerUrl,
            this.layerTypeLine,
            PIPELINE_STATUS_OPERATING
        )
        this.addLayerAfter(this.pipelinesConsumerUrl, {
            isLayerSave: false,
            id: pipelinesConsumerOperatingLayerId,
            type: this.layerTypeLine,
            source: this.infrastructureTileSourceName,
            'source-layer': this.pipelinesConsumerUrl,
            layout: {},
            paint: {
                'line-color': this.colors[5],
                'line-width': 1,
                // 'line-dasharray': [2, 2],
            },
            // minzoom: LAYER_MIN_ZOOM2,
            minzoom: this.layerMinZoom1,
            filter: ['==', 'status', PIPELINE_STATUS_OPERATING],
        })

        const pipelinesConsumerProjectedLayerId = this.getLayerId(
            this.pipelinesConsumerUrl,
            this.layerTypeLine,
            PIPELINE_STATUS_PROJECTED
        )
        const sourceLayer = this.getLayerById(
            this.map.getStyle().layers,
            this.pipelinesMajorUrl
        )

        this.addLayerAfter(pipelinesConsumerOperatingLayerId, {
            isLayerSave: false,
            id: pipelinesConsumerProjectedLayerId,
            type: this.layerTypeLine,
            source: this.infrastructureTileSourceName,
            'source-layer': this.pipelinesConsumerUrl,
            layout: {},
            paint: {
                'line-dasharray': [2.5, 2.5],
                'line-color': this.colors[5],
                'line-width': 1.5,
                // 'line-dasharray': [2, 2],
            },
            minzoom: LAYER_MIN_ZOOM2,
            // minzoom: this.layerMinZoom1,
            filter: ['==', 'status', PIPELINE_STATUS_PROJECTED],
        })

        this.addLayerHoverActions(pipelinesConsumerProjectedLayerId, {
            mainLayerOptions: {
                ...sourceLayer,
                id: pipelinesConsumerProjectedLayerId,
            },

            hoveringLayerOptions: {
                type: this.layerTypeLine,
                source: this.infrastructureTileSourceName,
                'source-layer': this.pipelinesMajorUrl,
                layout: {
                    'line-cap': 'round',
                    'line-join': 'round',
                },
                paint: {
                    'line-color': 'rgba(0,0,0,0)',
                    'line-width': 20,
                },
            },

            hoveringDecorationLayerOptions: {
                type: this.layerTypeLine,
                source: this.infrastructureTileSourceName,
                'source-layer': this.pipelinesMajorUrl,
                layout: {
                    'line-cap': 'round',
                    'line-join': 'round',
                },
                paint: {
                    // 'line-color': this.colors['17'],
                    'line-color': [
                        'case',
                        ['boolean', ['feature-state', 'hover'], false],
                        this.colors['17'],
                        'rgba(0,0,0,0)',
                    ],
                    'line-width': [
                        'case',
                        ['boolean', ['feature-state', 'hover'], false],
                        10,
                        5,
                    ],
                },
            },

            getHtmlContent: feature => {
                const {
                    properties: { name, year },
                } = feature
                let html = ''
                if (name) {
                    html += `<div>${name}</div>`
                }
                if (year) {
                    html += `<div>Окончание работ: ${year}</div>`
                }
                return {
                    htmlContent: html,
                    type: popupTypes.default,
                }
            },
        })
    }
}
