import {
    CONSUMER_STATUS_OPERATING,
    CONSUMER_STATUS_PROJECTED,
} from '@/shared/constants'
import { setPartialStateDeep } from '@/shared/lib'

import watchIsShowConsumers from './watchIsShowConsumers'
let watcherInstalled = false

const minZoom1 = 12
const defaultOpacity = 0.9
export default async function (this: any) {
    // const consumerUrl = `${this.consumerUrlFirstPart + regionId}`
    // const consumerUrl = this.consumerUrlFirstPart
    // const source = await this.loadSource(
    //     `${this.consumerUrlFirstPart + regionId}`
    // )
    // if (!source) return
    // console.log('consumer source:', source)
    // await this.addSource(
    //     consumerUrl,
    //     this.featureCollectionType,
    //     this.map.querySourceFeatures('infrastructure', {
    //         sourceLayer: ['consumer'],
    //     }),
    //     this.map,
    //     {
    //         cluster: true,
    //         clusterMaxZoom: 8,
    //         clusterRadius: 70,
    //         clusterMinPoints: 3,
    //         // maxzoom: 9,
    //     }
    // )

    return () => {
        // const getOptions = options => {
        //     return setPartialStateDeep(
        //         {
        //             type: this.layerTypeCircle,
        //             // source: consumerUrl,
        //             source: this.infrastructureTileSourceName,
        //             'source-layer': this.consumerUrlFirstPart,
        //             // filter: ['!has', 'point_count'],
        //
        //             paint: {
        //                 'circle-radius': 4,
        //                 // 'circle-color': this.colors[4],
        //                 'circle-color': [
        //                     'case',
        //                     [
        //                         '==',
        //                         ['get', 'status'],
        //                         CONSUMER_STATUS_OPERATING,
        //                     ],
        //                     this.colors[4],
        //                     [
        //                         '==',
        //                         ['get', 'status'],
        //                         CONSUMER_STATUS_PROJECTED,
        //                     ],
        //                     this.colors[9],
        //                     // this.colors[3],
        //                     'transparent',
        //                 ],
        //                 // 'circle-stroke-color': this.colors[9],
        //                 'circle-stroke-color': [
        //                     'case',
        //                     [
        //                         '==',
        //                         ['get', 'status'],
        //                         CONSUMER_STATUS_OPERATING,
        //                     ],
        //                     this.colors[9],
        //                     [
        //                         '==',
        //                         ['get', 'status'],
        //                         CONSUMER_STATUS_PROJECTED,
        //                     ],
        //                     this.colors[4],
        //                     // this.colors[3],
        //                     'transparent',
        //                 ],
        //                 'circle-stroke-width': 1,
        //                 'circle-opacity': defaultOpacity,
        //             },
        //
        //             // filter: ['==', 'type', 'BIG'],
        //         },
        //         options
        //     )
        // }
        // this.addLayer(
        //     getOptions({
        //         id: this.consumersCircleTypeId,
        //         source: this.infrastructureTileSourceName,
        //         'source-layer': this.consumerUrlFirstPart,
        //         filter: ['all', ['!has', 'point_count'], ['==', 'type', 'BIG']],
        //         minzoom: this.layerMinZoom1,
        //         paint: {
        //             // 'circle-radius': ['step', ['zoom'], 4, minZoom1, 7],
        //             // 'circle-opacity': [
        //             //     'step',
        //             //     ['zoom'],
        //             //     defaultOpacity,
        //             //     minZoom1,
        //             //     1,
        //             // ],
        //         },
        //
        //         // maxzoom: 12,
        //     })
        // )
        //
        // this.addLayer(
        //     getOptions({
        //         id: this.consumersCircleTypeId_small,
        //         source: this.infrastructureTileSourceName,
        //         'source-layer': this.consumerUrlFirstPart,
        //         filter: [
        //             'all',
        //             ['!has', 'point_count'],
        //             ['==', 'type', 'SMALL'],
        //         ],
        //         minzoom: minZoom1,
        //         // maxzoom: 12,
        //     })
        // )
        //
        // this.addLayer(
        //     getOptions({
        //         id: this.consumersCircleTypeId_main_big,
        //         source: this.infrastructureTileSourceName,
        //         'source-layer': this.consumerUrlFirstPart,
        //         filter: [
        //             'all',
        //             ['!has', 'point_count'],
        //             ['==', 'type', 'MAIN_BIG'],
        //         ],
        //         maxzoom: minZoom1,
        //     })
        // )
        // this.addLayer({
        //     id: this.consumersCircleTypeId_cluster,
        //     type: this.layerTypeCircle,
        //     source: consumerUrl,
        //     // source: this.infrastructureTileSourceName,
        //     // 'source-layer': this.consumerUrlFirstPart,
        //     paint: {
        //         'circle-color': this.colors[4],
        //         'circle-radius': 14,
        //         'circle-stroke-color': this.colors[9],
        //         'circle-stroke-width': 1,
        //         'circle-opacity': defaultOpacity,
        //     },
        //     filter: ['has', 'point_count'],
        //     minzoom: this.layerMinZoom1,
        // })
        //
        // this.addLayer({
        //     id: this.consumersCircleTypeId_symbol,
        //     type: this.layerTypeSymbol,
        //     source: consumerUrl,
        //     // source: this.infrastructureTileSourceName,
        //     // 'source-layer': this.consumerUrlFirstPart,
        //     paint: {
        //         'text-color': this.colors[9],
        //     },
        //     layout: {
        //         'text-field': '{point_count}',
        //         'text-font': ['Nunito Bold'],
        //         'text-size': 12,
        //         'text-allow-overlap': false,
        //         'text-ignore-placement': true,
        //     },
        //     minzoom: this.layerMinZoom1,
        // })

        const layersIds = [
            // this.consumersCircleTypeId,
            // this.consumersCircleTypeId_small,
            // this.consumersCircleTypeId_main_big,
            // this.consumersCircleTypeId_cluster,
            // this.consumersCircleTypeId_symbol,
            'consumer',
            'consumer_big',
            'consumer_main_big',
            'consumer_small',
        ]
        // layersIds.forEach(layerId => this.map.moveLayer(layerId))

        if (!watcherInstalled) {
            watchIsShowConsumers.call(this, layersIds)
            watcherInstalled = true
        }
    }
}
