export enum StatTypes {
    default = 'default',
    in_work = 'in_work',
    finished = 'finished',
    rejected = 'rejected',
}

export interface IFocusedProposalsStatsSubject {
    subject: string
    data?: {
        [statType in StatTypes]?: {
            name: string
            value?: number | string
        }
    }
}
