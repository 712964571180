import { htmlToElement } from '@/shared/lib'
export default function (this: any, status: string, size: number) {
    const el = htmlToElement(`
    <svg id="Component_2_1" data-name="Component 2 – 1" xmlns="http://www.w3.org/2000/svg" width="56" height="52" viewBox="0 0 56 52">
  <text id="_86" data-name="86" transform="translate(19 17)" font-size="16" font-family="Noto Sans Bold"><tspan x="0" y="0">86</tspan></text>
  <g id="Ellipse_2" data-name="Ellipse 2" transform="translate(25 26)" fill="#8a8a8a" stroke="#8a8a8a" stroke-width="1">
    <circle cx="2.5" cy="2.5" r="2.5" stroke="none"/>
    <circle cx="2.5" cy="2.5" r="2" fill="none"/>
  </g>
  <text id="xx" transform="translate(0 42)" fill="#ff3b30" font-size="12" font-family="Noto Sans Bold"><tspan x="0" y="0">xx</tspan></text>
  <text id="xx-2" data-name="xx" transform="translate(44 42)" fill="#4cd964" font-size="12" font-family="Noto Sans Bold"><tspan x="0" y="0">xx</tspan></text>
  <text id="xx-3" data-name="xx" transform="translate(22 49)" fill="#ff9500" font-size="12" font-family="Noto Sans Bold"><tspan x="0" y="0">xx</tspan></text>
</svg>
  `)

    return el
}
